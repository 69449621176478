import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { combineLatest, Observable, of, Subject, Subscription, forkJoin } from 'rxjs';
import { defaultIfEmpty, first, switchMap } from 'rxjs/operators';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { Space } from 'src/app/models/esite/Space.class';
import { Subsection } from 'src/app/models/esite/Subsection.class';
import { SubsectionType } from 'src/app/models/esite/SubsectionType.class';
import { SubsectionTypeRelation } from 'src/app/models/esite/SubsectionTypeRelation.enum';
import { EsiteService } from 'src/app/services/backend/esite.service';

import { SpaceUseType } from '../../../../../models/esite/SpaceUseType.enum';
import { Resource } from './../../../../../models/esite/Resource.class';
import { GlobalService } from './../../../../../services/common/global.service';
import { ResourcesService } from '../../../../../services/backend/resources.service';
import { UserPermissions } from 'src/app/models/auth/UserPermissions.enum';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-space-new-space',
  templateUrl: './space-new-space.component.html',
  styleUrls: ['./space-new-space.component.scss'],
})
export class SpaceNewSpaceComponent implements OnInit {
  space: Space;
  spaceFormGroup: UntypedFormGroup;
  spaceTypes = [];
  subsectionTypes = [];
  subsections1: Subsection[] = [];
  subsections2: Subsection[] = [];
  subsections3: Subsection[] = [];
  submitted = false;
  loading = true;
  modalTitle;
  editin = false;
  subsectionInsertError = 0;
  subsectionsToAdd: Subsection[] = [];
  invalidSubsection = false;
  subsection1Loaded = new Subject<boolean>();
  subsection2Loaded = new Subject<boolean>();
  subsection3Loaded = new Subject<boolean>();
  spaceSubsetions = [];
  /** Used as reference for space ID */
  id: number;
  floorID: number;
  canWriteEsiteSpaces = false;
  canReadResources = false;
  canWriteResources = false;
  useTypes = Object.entries(SpaceUseType).map(([type]) => type);
  subcriptions: Subscription[] = [];
  resources: Resource[] = [];
  isMultiSelectFocused = false;
  //Control to style multiselect float label
  selectingResources = false;
  selectFocus = false;
  selectedUseType: string;
  selectedType: number;
  typesForSelect: any;

  constructor(
    private resourceService: ResourcesService,
    private globalService: GlobalService,
    private formBuilder: UntypedFormBuilder,
    private esiteService: EsiteService,
    private dialogRef: MatDialogRef<SpaceNewSpaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    const resourcePermissions = this.globalService
      .getUser()
      ?.permissions.filter((p) => p.name === UserPermissions.Resources);

    if (resourcePermissions) {
      this.canReadResources = true; // as of 2023-12-04, if a permission is of type write, it also includes read permissions
      this.canWriteResources = resourcePermissions.some((p) => p.type === 'WRITE');
    }

    this.editin = !this.data.position && !this.data.floor;
    this.canWriteEsiteSpaces = this.data.canWriteEsiteSpaces;
    this.initForm();
  }

  ngOnInit(): void {
    this.getSpaceTypes();
    if (this.canReadResources) {
      this.subcriptions.push(
        this.resourceService.getResources({ full: true }).subscribe((resources) => {
          this.resources = resources;
          this.loadFormulario();
        })
      );
    } else {
      this.loadFormulario();
    }
  }

  initForm(): void {
    this.spaceFormGroup = this.formBuilder.group({
      name: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.required, Validators.pattern(/^[^,]+$/)]],
      code: [
        { value: '', disabled: !this.canWriteEsiteSpaces || this.editin },
        [Validators.required, Validators.pattern(/^[^,]+$/)],
      ],
      useType: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.required]],
      type: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.required]],
      description: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.required]],
      x: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.required]],
      y: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.required]],
      floor: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.required]],
      capacity: [
        { value: 1, disabled: !this.canWriteEsiteSpaces },
        [Validators.required, Validators.min(1), Validators.max(99999)],
      ],
      subsectionType1: [{ value: '', disabled: !this.canWriteEsiteSpaces }, []],
      subsection1: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.pattern(/^[^,]+$/)]],
      subsectionType2: [{ value: '', disabled: !this.canWriteEsiteSpaces }, []],
      subsection2: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.pattern(/^[^,]+$/)]],
      subsectionType3: [{ value: '', disabled: !this.canWriteEsiteSpaces }, []],
      subsection3: [{ value: '', disabled: !this.canWriteEsiteSpaces }, [Validators.pattern(/^[^,]+$/)]],
      resources: [[{ value: [], disabled: !this.canWriteEsiteSpaces || !this.canWriteResources }]],
    });
  }

  loadFormulario(): void {
    if (!this.editin) {
      this.modalTitle = 'CreateSite';
      this.floorID = this.data.floor.id;
      this.typesForSelect = this.spaceTypes;
      this.spaceFormGroup.patchValue({
        x: this.data.position.x,
        y: this.data.position.y,
        floor: this.data.floor.id,
        resources: [],
      });
      this.getSubsectionTypes();
    } else {
      this.id = this.data.space.id || this.data.space.code;
      this.modalTitle = 'Edit site';
      this.esiteService.getSpace(this.id, { resources: this.canReadResources }).subscribe((space) => {
        this.floorID = space.floor;

        const foundType = this.spaceTypes.find((item) => item.id === space.type);
        space.typeName = foundType.name;
        space.useType = foundType.useType;

        this.selectedType = space.type;
        this.selectedUseType = space.useType;
        this.typesForSelect = this.spaceTypes.filter((type) => type.useType === this.selectedUseType);
        this.esiteService.getSpaceSubsections(this.id).subscribe((subsections: Subsection[]) => {
          subsections.forEach((element) => {
            this.spaceSubsetions.push(element.id);
          });

          this.spaceFormGroup.patchValue({
            x: space.x,
            y: space.y,
            floor: space.floor,
            code: space.code,
            name: space.name,
            useType: space.useType,
            type: space.type,
            description: space.description,
            capacity: space.capacity,
            subsectionType1: subsections[0]?.type.id,
            subsection1: subsections[0]?.id || '',
            subsectionType2: subsections[1]?.type.id,
            subsection2: subsections[1]?.id || '',
            subsectionType3: subsections[2]?.type.id,
            subsection3: subsections[2]?.id || '',
            resources: space?.resources.map((x) => x.id) ?? [],
          });

          this.getSubsectionTypes();
        });
      });
    }
  }

  checkSubsectionsSelects(subsectionTypeModified?: number, avoidSubsectionNullable = false): void {
    if (subsectionTypeModified === 1) {
      if (!avoidSubsectionNullable) {
        this.spaceFormGroup.controls['subsection1'].setValue(null);
      }
      this.subsections1 = [];
      if (this.spaceFormGroup.value.subsectionType1) {
        this.esiteService
          .getSubsections(
            this.isSubsectionTypeOnlyInOneFloor(this.spaceFormGroup.value.subsectionType1) ? this.floorID : null,
            this.spaceFormGroup.value.subsectionType1
          )
          .subscribe((subsections) => {
            this.subsections1 = subsections;
            this.subsection1Loaded.next(true);
          });
      } else {
        this.subsection1Loaded.next(true);
      }
    } else if (subsectionTypeModified === 2) {
      if (!avoidSubsectionNullable) {
        this.spaceFormGroup.controls['subsection2'].setValue(null);
      }
      this.subsections2 = [];
      if (this.spaceFormGroup.value.subsectionType2) {
        this.esiteService
          .getSubsections(
            this.isSubsectionTypeOnlyInOneFloor(this.spaceFormGroup.value.subsectionType2) ? this.floorID : null,
            this.spaceFormGroup.value.subsectionType2
          )
          .subscribe((subsections) => {
            this.subsections2 = subsections;
            this.subsection2Loaded.next(true);
          });
      } else {
        this.subsection2Loaded.next(true);
      }
    } else if (subsectionTypeModified === 3) {
      if (!avoidSubsectionNullable) {
        this.spaceFormGroup.controls['subsection3'].setValue(null);
      }
      this.subsections3 = [];
      if (this.spaceFormGroup.value.subsectionType3) {
        this.esiteService
          .getSubsections(
            this.isSubsectionTypeOnlyInOneFloor(this.spaceFormGroup.value.subsectionType3) ? this.floorID : null,
            this.spaceFormGroup.value.subsectionType3
          )
          .subscribe((subsections) => {
            this.subsections3 = subsections;
            this.subsection3Loaded.next(true);
          });
      } else {
        this.subsection3Loaded.next(true);
      }
    } else {
      combineLatest([this.subsection1Loaded, this.subsection2Loaded, this.subsection3Loaded])
        .pipe(first())
        .subscribe(() => {
          this.loading = false;
        });

      this.checkSubsectionsSelects(1, true);
      this.checkSubsectionsSelects(2, true);
      this.checkSubsectionsSelects(3, true);
    }
  }

  isSubsectionTypeOnlyInOneFloor(subsectionTypeId: number): boolean {
    return (
      this.subsectionTypes.find((subsectionType: SubsectionType) => subsectionType.id === subsectionTypeId)
        ?.maxFloors === SubsectionTypeRelation.ONLY_IN_ONE_FLOOR
    );
  }

  getSpaceTypes(): void {
    this.esiteService.getSpaceTypes().subscribe((types) => {
      this.spaceTypes = types;
    });
  }

  getSubsectionTypes(): void {
    this.esiteService.getSubsectionTypes().subscribe((subsectionTypes) => {
      this.subsectionTypes = subsectionTypes;
      this.checkSubsectionsSelects();
    });
  }

  get f() {
    return this.spaceFormGroup.controls;
  }

  checkSpace(): void {
    this.submitted = true;
    if (this.spaceFormGroup.invalid) {
      this.submitted = false;
      return;
    }

    this.insertSubsections();
  }

  insertSubsections(): void {
    this.invalidSubsection = false;
    if (this.spaceFormGroup.value.subsectionType1 && !this.spaceFormGroup.value.subsection1) {
      setTimeout(() => {
        this.spaceFormGroup.get('subsection1')?.markAsDirty();
        this.spaceFormGroup.get('subsection1')?.markAsTouched();
        this.spaceFormGroup.get('subsection1')?.setErrors({ required: true });
      }, 1);
      this.submitted = false;
      return;
    }
    if (this.spaceFormGroup.value.subsectionType2 && !this.spaceFormGroup.value.subsection2) {
      setTimeout(() => {
        this.spaceFormGroup.get('subsection2')?.markAsDirty();
        this.spaceFormGroup.get('subsection2')?.markAsTouched();
        this.spaceFormGroup.get('subsection2')?.setErrors({ required: true });
      }, 1);
      this.submitted = false;
      return;
    }
    if (this.spaceFormGroup.value.subsectionType3 && !this.spaceFormGroup.value.subsection3) {
      setTimeout(() => {
        this.spaceFormGroup.get('subsection3')?.markAsDirty();
        this.spaceFormGroup.get('subsection3')?.markAsTouched();
        this.spaceFormGroup.get('subsection3')?.setErrors({ required: true });
      }, 1);
      this.submitted = false;
      return;
    }
    this.subsectionsToAdd = [];

    if (this.spaceFormGroup.value.subsection1) {
      this.subsectionsToAdd.push(
        new Subsection({
          id: this.spaceFormGroup.value.subsection1,
          name: this.subsections1.find((item) => item.id === this.spaceFormGroup.value.subsection1)?.name,
          type: this.spaceFormGroup.value.subsectionType1,
        })
      );
    }
    if (this.spaceFormGroup.value.subsection2) {
      this.subsectionsToAdd.push(
        new Subsection({
          id: this.spaceFormGroup.value.subsection2,
          name: this.subsections2.find((item) => item.id === this.spaceFormGroup.value.subsection2)?.name,
          type: this.spaceFormGroup.value.subsectionType2,
        })
      );
    }
    if (this.spaceFormGroup.value.subsection3) {
      this.subsectionsToAdd.push(
        new Subsection({
          id: this.spaceFormGroup.value.subsection3,
          name: this.subsections3.find((item) => item.id === this.spaceFormGroup.value.subsection3)?.name,
          type: this.spaceFormGroup.value.subsectionType3,
        })
      );
    }

    const subsectionsTypesToBeAdded: number[] = this.subsectionsToAdd.map((item) => item.type.id);
    const isSubsectionTypeDuplicated = new Set(subsectionsTypesToBeAdded).size !== subsectionsTypesToBeAdded.length;

    if (isSubsectionTypeDuplicated) {
      this.invalidSubsection = true;
      this.submitted = false;
    } else {
      this.createOrEditSpace();
    }
  }

  createOrEditSpace(): void {
    const space = new Space(this.spaceFormGroup.value);
    if (!this.editin) {
      this.esiteService.insertSpace(space, this.data.floor.id, AuditFunctionalities.CreateIndividualSpace).subscribe({
        next: (createdSpace) => {
          forkJoin([this.linkSubsections(createdSpace), this.editResources(createdSpace)]).subscribe({
            next: () => {
              createdSpace.useType = this.spaceFormGroup.value.useType;
              createdSpace.resources = this.spaceFormGroup.value.resources;
              this.dialogRef.close({
                isCancelled: false,
                space: createdSpace,
              });
              this.submitted = false;
              this.globalService.printMessage('data successfully saved');
            },
            error: () => {
              this.submitted = false;
            },
          });
        },
        error: (error: HttpErrorResponse) => {
          this.submitted = false;
          if (error.status === 409) {
            setTimeout(() => {
              this.spaceFormGroup.get('code')?.markAsDirty();
              this.spaceFormGroup.get('code')?.markAsTouched();
              this.spaceFormGroup.get('code')?.setErrors({ duplicated: true });
            }, 1);
          }
        },
      });
    } else {
      space.id = this.data.space.id;
      space.code = this.data.code;
      if (this.data.space.id === undefined) {
        space.id = this.data.space.code;
      }

      this.esiteService
        .modifySpace(space, this.spaceFormGroup.value.floor, AuditFunctionalities.ModifyIndividualSpace)
        .subscribe({
          next: (modifiedSpace) => {
            if (this.checkSubsectionChange()) {
              this.deleteAllsubsections(space);
            }
            this.editResources(modifiedSpace).subscribe(() => {
              modifiedSpace.useType = this.spaceFormGroup.value.useType;
              modifiedSpace.resources = this.spaceFormGroup.value.resources;
              this.dialogRef.close({
                isCancelled: false,
                floor: this.spaceFormGroup.value.floor,
                space: modifiedSpace,
              });
              this.globalService.printMessage('Space successfully modified');
            });
          },
          error: (error: HttpErrorResponse) => {
            this.submitted = false;
            if (error.status === 409) {
              setTimeout(() => {
                this.spaceFormGroup.get('code')?.markAsDirty();
                this.spaceFormGroup.get('code')?.markAsTouched();
                this.spaceFormGroup.get('code')?.setErrors({ duplicated: true });
              }, 1);
            }
          },
        });
    }
  }

  private editResources(space: { id: number }) {
    if (!this.canWriteResources) return of({});

    const resourceIds = this.spaceFormGroup.value.resources;
    return this.esiteService.setSpaceResources(space.id, resourceIds);
  }

  checkSubsectionChange(): boolean {
    const spaceNewSubsections = [];
    if (this.subsectionsToAdd.length != this.spaceSubsetions.length) {
      return true;
    } else {
      this.subsectionsToAdd.forEach((element) => {
        spaceNewSubsections.push(element.id);
      });

      return this.globalService.hasDifference(this.spaceSubsetions, spaceNewSubsections);
    }
  }

  linkSubsections(space: Space) {
    return forkJoin(
      this.subsectionsToAdd
        .filter((subsection) => subsection.id)
        .map((subsection) =>
          this.esiteService.addSubsection(space.id, subsection.id, AuditFunctionalities.AddIndividualSubsectionToSpace)
        )
    ).pipe(defaultIfEmpty({}));
  }

  deleteAllsubsections(space: Space): void {
    this.esiteService
      .deleteAllSubsectionsFromSpace(space.id, AuditFunctionalities.DeleteIndividualSubsectionFromSpace)
      .pipe(switchMap(() => this.linkSubsections(space)))
      .subscribe();
  }

  cancel(): void {
    this.submitted = false;
    this.dialogRef.close({ isCancelled: true });
  }

  searcherSubsection1 = (search: string, pageNumber: number, pageSize: number): Observable<Subsection[]> => {
    // searcher must return Observable in this method use rxjs of
    const filtered = this.subsections1
      .filter((z) => z.name.toLowerCase().includes(search.toLowerCase()))
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    return of(filtered);
  };

  searcherSubsection2 = (search: string, pageNumber: number, pageSize: number): Observable<Subsection[]> => {
    // searcher must return Observable in this method use rxjs of
    const filtered = this.subsections2
      .filter((z) => z.name.toLowerCase().includes(search.toLowerCase()))
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    return of(filtered);
  };

  searcherSubsection3 = (search: string, pageNumber: number, pageSize: number): Observable<Subsection[]> => {
    // searcher must return Observable in this method use rxjs of
    const filtered = this.subsections3
      .filter((z) => z.name.toLowerCase().includes(search.toLowerCase()))
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    return of(filtered);
  };

  clearField(event: string): void {
    switch (event) {
      case 'subsectionType1': {
        this.spaceFormGroup.controls['subsectionType1'].setValue(null);
        this.spaceFormGroup.controls['subsection1'].setValue(null);

        break;
      }
      case 'subsectionType2': {
        this.spaceFormGroup.controls['subsectionType2'].setValue(null);
        this.spaceFormGroup.controls['subsection2'].setValue(null);

        break;
      }
      case 'subsectionType3': {
        this.spaceFormGroup.controls['subsectionType3'].setValue(null);
        this.spaceFormGroup.controls['subsection3'].setValue(null);

        break;
      }
      case 'subsection1': {
        this.spaceFormGroup.controls['subsection1'].setValue(null);

        break;
      }
      case 'subsection2': {
        this.spaceFormGroup.controls['subsection2'].setValue(null);

        break;
      }
      case 'subsection3': {
        this.spaceFormGroup.controls['subsection3'].setValue(null);

        break;
      }
      case 'useType': {
        this.spaceFormGroup.controls['useType'].setValue(null);
        this.spaceFormGroup.controls['type'].setValue(null);
        this.selectedType = this.selectedUseType = null;
        this.typesForSelect = this.spaceTypes;

        break;
      }
    }
  }

  checkTypeFields(event: MatSelectChange, field: string) {
    if (field === 'type') {
      this.selectedType = event.value;
      const selectedTypeObj = this.spaceTypes.find((type) => type.id === this.selectedType);
      this.selectedUseType = selectedTypeObj.useType;
    } else {
      this.selectedUseType = event.value;
      this.typesForSelect = this.spaceTypes.filter((type) => type.useType === this.selectedUseType);
    }
  }
}
