import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { from, Observable, Subject } from 'rxjs';
import { finalize, mergeMap, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/backend/auth.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { LanguageService } from 'src/app/services/common/language.service';

import { StorageVariables } from './../../../../models/StorageVariables.enum';
import { UserService } from './../../../../services/backend/user.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit, OnDestroy {
  @ViewChild('canvasImg') canvas;
  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;
  lan: any;
  userLogged: any;
  dialogRef: any;
  editUserFormGroup: UntypedFormGroup;
  changeImageFormGroup: UntypedFormGroup;
  loading = false;
  loadingModal = false;
  imageName = '';
  imagesError = true;
  submitted = false;
  imgURL;
  img_to_upload;
  errorImageType = false;
  errorImageSize = false;
  disableSave = false;

  constructor(
    private authService: AuthService,
    private habitatService: HabitatService,
    private dialogService: MatDialog,
    private globalService: GlobalService,
    private formBuilder: UntypedFormBuilder,
    private languageService: LanguageService,
    protected sanitizer: DomSanitizer,
    private userService: UserService
  ) {
    this.userLogged = this.globalService.getUser();
    this.editUserFormGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      surname: ['', Validators.required],
      department: ['', []],
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(): void {
    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
    });

    this.languageService.curLanguage.subscribe((lan: any) => {
      if (lan === 'en') {
        this.lan = 'en';
      } else {
        this.lan = 'esp';
      }
    });

    this.initFormAndUserImage();
  }

  initFormAndUserImage(): void {
    this.habitatService.getUserImage().subscribe({
      next: (userImage) => {
        this.createImageFromBlob(userImage);
        this.setUserForm();
      },
      error: () => {
        this.setUserForm();
      },
    });
  }

  setUserForm(): void {
    this.editUserFormGroup = this.formBuilder.group({
      email: [this.userLogged?.email, [Validators.required, Validators.email]],
      name: [this.userLogged?.name, Validators.required],
      surname: [this.userLogged?.surname, Validators.required],
      department: [this.userLogged?.department || '', []],
    });
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.userLogged.image = reader.result;
        this.globalService.emitUserImageChanged(this.userLogged.image);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  openImageModal(dialog: TemplateRef<any>): void {
    this.submitted = false;
    this.changeImageFormGroup = this.formBuilder.group({
      img: ['', [Validators.required]],
    });
    this.dialogRef = this.dialogService.open(dialog);
  }

  close(): void {
    this.imgURL = null;
    this.dialogRef.close();
  }

  get f(): any {
    return this.editUserFormGroup.controls;
  }

  get q(): any {
    return this.changeImageFormGroup.controls;
  }

  modifyDataUser(): void {
    this.disableSave = true;
    this.loading = true;
    this.submitted = true;
    if (this.editUserFormGroup.invalid) {
      this.loading = false;
      return;
    }
    this.userLogged.name = this.editUserFormGroup.value.name;
    this.userLogged.surname = this.editUserFormGroup.value.surname;
    this.userLogged.department = this.editUserFormGroup.value.department;

    const modifyUserObservables: Observable<unknown>[] = [];
    modifyUserObservables.push(this.userService.modifyUser(this.userLogged.id, this.userLogged));
    modifyUserObservables.push(this.userService.modifyUserExtra(this.userLogged.id, this.userLogged));
    from(modifyUserObservables)
      .pipe(
        mergeMap((obs) => obs.toPromise()),
        finalize(() => {
          this.authService.emitUserInfoChange(this.userLogged);
          this.globalService.setCurrentUser(this.userLogged);
          localStorage.setItem(StorageVariables.LOGGED_USER, JSON.stringify(this.userLogged));
          this.loading = false;
          this.disableSave = false;
          this.globalService.printMessage('User modified successfully');
        })
      )
      .subscribe(() => {
        //console.log(res)
      });
  }

  handleImageInput(files): void {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(files.item(0));
    fileReader.onload = (event: any) => {
      this.errorImageSize = files.item(0).size > 2000000;
      this.errorImageType = files.item(0).type !== 'image/png' && files.item(0).type !== 'image/jpeg';
      if (this.errorImageSize || this.errorImageType) {
        this.imageName = '';
        this.changeImageFormGroup.controls['img']?.setValue('');
        this.img_to_upload = null;
        this.imgURL = null;
        return;
      }
      this.changeImageFormGroup.controls['img'].setValue(files.item(0) ? files.item(0).name : '');
      this.imgURL = event.target.result;
      this.img_to_upload = files.item(0);
      this.imageName = files.item(0).name;
    };
  }

  saveImage(): void {
    if (this.changeImageFormGroup.invalid || this.errorImageSize || this.errorImageType) {
      return;
    }
    this.loadingModal = true;
    this.submitted = true;
    this.habitatService.modifyProfileImage(this.img_to_upload).subscribe(() => {
      this.userLogged = this.globalService.getUser();

      this.userLogged.image = this.habitatService.getHabitatAssetsUrl() + this.userLogged.image;
      this.loadingModal = false;
      this.close();
      this.ngOnInit();
    });
  }
}
