import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/auth/User.class';
import { Zone } from 'src/app/models/conteo/Zone.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { City } from 'src/app/models/habitat/City.class';
import { Languages } from 'src/app/models/Languages.enum';
import { OccupationNotification } from 'src/app/models/notifications/OccupationNotification.class';
import { OccupationNotificationConfig } from 'src/app/models/notifications/OccupationNotificationConfig.class';
import { ProximityNotification } from 'src/app/models/notifications/ProximityNotification.class';
import { ProximityNotificationConfig } from 'src/app/models/notifications/ProximityNotificationConfig.class';
import { WebsocketMsg } from 'src/app/models/websocket/WebsocketMsg.class';
import { WebsocketMsgType } from 'src/app/models/websocket/WebsocketMsgType.enum';
import { AuthService } from 'src/app/services/auth/auth.service';
import { ConteoService } from 'src/app/services/backend/conteo.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { DateService } from 'src/app/services/common/date.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { NotificationsService } from 'src/app/services/pages/notifications.service';

import { Permission, UserBuilding } from './../../models/auth/Role.class';
import { UserPermissions } from './../../models/auth/UserPermissions.enum';
import { InformesService } from './../../services/pages/informes.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PagesComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  notifications$ = new Subject();
  subcriptions: Subscription[] = [];
  userBuilding: UserBuilding;
  buildings: Building[] = [];
  hasEsiteModule = false;
  hasEsiteDevicesModule = false;
  hasConteoModule = false;
  loading = true;
  selectedPageInforme = false;
  userLogged: User;
  private permissions: Permission[] = [];
  activeLeftPanel = true;
  havePermissionIncidents = false;
  private WRITE = 'WRITE';

  constructor(
    private notificationsService: NotificationsService,
    private habitatService: HabitatService,
    private globalService: GlobalService,
    private conteoService: ConteoService,
    private dateService: DateService,
    private translateService: TranslateService,
    private authService: AuthService,
    private informesService: InformesService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasEsiteDevicesModule = this.globalService.getEsiteDevice();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
  }

  ngOnInit(): void {
    this.globalService.setMessagePermission(this.permissions);

    this.setPageStyle();

    this.globalService.userLogged$.subscribe((user: User) => {
      if (user) {
        this.userLogged = user;
        this.permissions = this.userLogged?.permissions;
        this.globalService.setMessagePermission(this.permissions);
        this.setPageStyle();
        this.getBuildingsAsociations();
        this.ckeckPermissionsUser();
      }
    });

    /**
     * Check if there's an already logged user information
     */
    this.authService.canRecoverRegisteredUserInfo$.pipe(takeUntil(this.destroy$)).subscribe((result: boolean) => {
      if (result) {
        this.loading = false;
      }
    });

    this.informesService.selectedPageInforme$.subscribe((value) => {
      this.selectedPageInforme = value;
    });
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }

  ckeckPermissionsUser(): void {
    this.havePermissionIncidents = false;
    if (
      this.permissions?.find(
        (permission) => permission.name === UserPermissions.Incidents && permission.type === this.WRITE
      ) !== undefined
    ) {
      this.havePermissionIncidents = true;
    }
  }

  getBuildingsAsociations(): void {
    this.buildings = this.userLogged?.buildings;
    this.habitatService.getBuildings().subscribe((buildings) => {
      this.buildings = buildings;
      this.getMore();
    });
  }

  getMore(): void {
    this.subcriptions.push(
      this.habitatService.getCities().subscribe((cities: City[]) => {
        const buildingsCity = [];
        this.buildings.forEach((item, index) => {
          const building = new Building(item);

          if (cities?.length > 0) {
            const city = cities.find((item) => item.id == building.city.id);
            building.city = new City(city);
          }

          buildingsCity.push(building);

          if (index === this.buildings.length - 1) {
            this.globalService.setUserBuildings(buildingsCity);
            this.receiveRealTimeNotifications();
          }
        });
      })
    );
  }

  checkProximityAlarmConfig(): void {
    if (this.hasEsiteDevicesModule && !this.globalService.getProximityNotificationConfig()) {
      this.notificationsService.getProximityAlarm().subscribe((config: ProximityNotificationConfig[]) => {
        this.globalService.setProximityNotificationConfig(config);
      });
    }
  }

  checkOccupationAlarmConfig(): void {
    if (this.hasConteoModule && !this.globalService.getOccupationNotifications()) {
      this.conteoService.getConfigAlarms().subscribe((alarms: OccupationNotificationConfig[]) => {
        this.globalService.setOccupationNotifications(alarms);
      });
    }
  }

  receiveRealTimeNotifications(): void {
    // CONTEO Notificatios.
    if (this.hasConteoModule) {
      this.checkOccupationAlarmConfig();
      this.subcriptions.push(
        this.notificationsService.getConteoMessages().subscribe((data) => {
          const msg = new WebsocketMsg(data);
          if (msg && msg.type === WebsocketMsgType.Alarm && this.globalService.getOccupationNotifications()?.length) {
            const index = this.globalService
              .getOccupationNotifications()
              .map((item) => {
                return item.id;
              })
              .indexOf(msg.data.configuredAlarm);
            if (index > -1) {
              const configured = new OccupationNotificationConfig(
                this.globalService.getOccupationNotifications()[index]
              );
              this.subcriptions.push(
                this.notificationsService.getZone(configured.zone).subscribe((zoneItem) => {
                  const zone = new Zone(zoneItem);
                  this.subcriptions.push(
                    this.habitatService.getFloor(zoneItem.floor).subscribe((floor) => {
                      const floorBuilding = this.buildings.find((item) => item.id === floor.building);
                      const notif = new OccupationNotification(configured, msg.data);
                      const type = this.translateService.instant(configured.subtype);
                      zone.occupation = msg.data?.occupation;
                      const occupationRate = zone.getPercentage();
                      notif.subject = type + '. ' + zone.name + ': ' + occupationRate + '%';
                      notif.date = notif.date
                        ? this.dateService.utcToLocalString(notif.date, Languages.es, floorBuilding.city.timezone)
                        : '';
                      this.notificationsService.emitNewNotification(notif);
                    })
                  );
                })
              );
            }
          }
        })
      );
    }

    // Esite Notifications.
    if (this.hasEsiteModule && this.hasEsiteDevicesModule) {
      this.checkProximityAlarmConfig();
      this.subcriptions.push(
        this.notificationsService.getEsiteOcupationMessages().subscribe((data) => {
          const msg = new WebsocketMsg(data);
          if (
            msg &&
            msg.type === WebsocketMsgType.Alarm &&
            this.globalService.getProximityNotificationConfig()?.length
          ) {
            const proximityAlarmConfig = new ProximityNotificationConfig(
              this.globalService.getProximityNotificationConfig()[0]
            );
            const notif = new ProximityNotification(proximityAlarmConfig, msg.data);
            if (notif.space1 && notif.space2) {
              this.notificationsService.getAlarmSpacesName(notif.space1, notif.space2).subscribe((names) => {
                if (names) {
                  this.subcriptions.push(
                    this.habitatService.getFloor(names[0].floor).subscribe((floor) => {
                      const floorBuilding = this.buildings.find((item) => item.id === floor.building);
                      notif.date = notif.date
                        ? this.dateService.utcToLocalString(notif.date, Languages.es, floorBuilding.city.timezone)
                        : '';
                      const siteLabel = this.translateService.instant('Site');
                      notif.subject = siteLabel + ' 1: ' + names[0]?.code + ' ' + siteLabel + ' 2: ' + names[1]?.code;
                      this.notificationsService.emitNewNotification(notif);
                    })
                  );
                }
              });
            }
          }
        })
      );
    }
  }

  setPageStyle(): void {
    // if (
    //   this.globalService.isAdmin(this.permissions?.map((item) => item.name)) ||
    //   this.globalService.isSuperAdmin(this.permissions?.map((item) => item.name))
    // ) {
    document.getElementById('theme').setAttribute('href', './assets/css/manager.css');
    // } else {
    //   document.getElementById('theme').setAttribute('href', './assets/css/general.css');
    // }
  }

  getActiveLeftPanel(evt): void {
    this.activeLeftPanel = evt;
  }
}
