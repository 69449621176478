import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import FileSaver from 'file-saver';
import JSZip from 'jszip';
import { finalize, from, groupBy, mergeMap, of, Subject, Subscription, toArray, zip } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { InfoModalComponent } from 'src/app/components/shared/info-modal/info-modal.component';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { Constants } from 'src/app/models/Constants.const';
import { Zone } from 'src/app/models/conteo/Zone.class';
import { Area } from 'src/app/models/esite/Area.class';
import { Space } from 'src/app/models/esite/Space.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { FilterOptionType } from 'src/app/models/historic/FilterOptionType.enum';
import { InfoModalData } from 'src/app/models/InfoModalData.class';
import { colorPalette } from 'src/app/models/reservation/colorPalette.const';
import { ReservationRolesEnum } from 'src/app/models/reservation/ReservationRoles.enum';
import { ReservationUser } from 'src/app/models/reservation/ReservationUser.class';
import { ReservationUsersFiltered } from 'src/app/models/reservation/ReservationUsersFiltered.class';
import { SvgmapData } from 'src/app/models/svgmap/SvgmapData.class';
import { SvgmapLegendItem } from 'src/app/models/svgmap/SvgmapLegendItem.class';
import { SvgmapPoint } from 'src/app/models/svgmap/SvgmapPoint.class';
import { SvgmapPointReservation } from 'src/app/models/svgmap/SvgmapPointReservation.class';
import { SvgmapPolygon } from 'src/app/models/svgmap/SvgmapPolygon.class';
import { SvgmapProperties } from 'src/app/models/svgmap/SvgmapProperties.class';
import { ConteoService } from 'src/app/services/backend/conteo.service';
import { EsiteService } from 'src/app/services/backend/esite.service';
import { GeneralService } from 'src/app/services/backend/general.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { DateService } from 'src/app/services/common/date.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { SpaceConfigurationService } from 'src/app/services/pages/space-configuration.service';

import { SpaceFormComponent } from '../space-form/space-form.component';
import { SpaceNewSpaceComponent } from '../space-new-space/space-new-space.component';
import { Permission } from './../../../../../models/auth/Role.class';
import { UserPermissions } from './../../../../../models/auth/UserPermissions.enum';
import { SpaceUseType } from 'src/app/models/esite/SpaceUseType.enum';
import { SpaceType } from 'src/app/models/esite/SpaceType.class';

@Component({
  selector: 'app-space-configuration-floor',
  templateUrl: './space-configuration-floor.component.html',
  styleUrls: ['./space-configuration-floor.component.css'],
})
export class SpaceConfigurationFloorComponent implements OnInit, OnDestroy {
  polygonsEvent: Subject<any> = new Subject<any>();
  previousZoneName = '';
  @ViewChild('formModal') modal;
  initalFloorInfoState = true;
  floorDataExpanded = true;
  currentBuilding = new Building('');
  currentFloor = new Floor('');
  selected = FilterOptionType.spaces;
  selectedInSpaces = FilterOptionType.sites;
  optionArea = FilterOptionType.areas;
  optionReservation = FilterOptionType.reservation;
  optionSites = FilterOptionType.sites;
  optionSpaces = FilterOptionType.spaces;
  optionZones = FilterOptionType.zones;
  onlyAreas = true;
  svgProperties = new SvgmapProperties({ id: 41, initialZoom: 1 });
  svgDataMemory = { sites: [], areas: [], zones: [], heatmap: [] };
  svgDataToSend: SvgmapData;
  legendItems = [];
  subcriptions: Subscription[] = [];
  formGroup;
  colorSite = '#0075AA';
  colorSiteReservable = '#36a677';
  isDrawingMode = false;
  isClickMode = false;
  isDeletingAreaMode = false;
  isDeletingZoneMode = false;
  isDeletingSiteMode = false;
  dialogRef: MatDialogRef<any>;
  polygonFormGroup;
  currentPolygon = { id: null, path: '' };
  addButtonTitle = '';
  titleModal = '';
  submitted = false;
  editing = false;
  users;
  reservationGroupMode = false;
  reservationSpaces = [];
  floorReservations = [];
  floorSpaces = [];
  colorPalette = colorPalette;
  userIndex;
  downloadSubscription: Subscription;
  filterReservation = [
    { value: 'reservable', name: 'Reservable', checked: true },
    { value: 'permission', name: 'Reservation permission', checked: false },
    { value: 'approval', name: 'Approval', checked: false },
    { value: 'approvalUsers', name: 'Approval user', checked: false },
    { value: 'rgroup', name: 'Group reservations', checked: false },
  ];
  filterselected = 'reservable';
  isReservationMap = false;
  isSitesMap = false;
  floor;
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  hasfloorImg = false;
  loading = true;
  spaceToFind: string;
  userLogged;
  private permissions: Permission[] = [];
  canWriteEsiteSpaces = false;
  canWriteReservationsSpaces = false;
  canWriteAreas = false;
  canWriteZones = false;
  mapFilterFormGroup: UntypedFormGroup;
  useTypes = Object.entries(SpaceUseType).map(([type]) => type);
  spaceTypes: SpaceType[] = [];
  useTypeFilter = '';
  typeFilter = '';
  typesForSelect;

  constructor(
    private spaceConfigurationService: SpaceConfigurationService,
    private translateService: TranslateService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private esiteService: EsiteService,
    private habitatService: HabitatService,
    private reservationService: ReservationService,
    private conteoService: ConteoService,
    private generalService: GeneralService,
    private dateService: DateService,
    private globalService: GlobalService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.hasReservationsModule = this.globalService.getReservationModule();
    this.selected = !this.hasEsiteModule ? FilterOptionType.zones : FilterOptionType.spaces;

    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
    this.checkPermissionsUser(this.permissions);
  }

  ngOnInit(): void {
    this.initForm();
    this.esiteService.getSpaceTypes().subscribe((types) => {
      this.spaceTypes = types;
      this.typesForSelect = this.spaceTypes;
    });

    this.subcriptions.push(
      this.spaceConfigurationService.buildingChanged.subscribe((building) => {
        this.checkPermissionsUser(
          this.globalService.getPermissionsBuilding(building.id, this.userLogged?.permissionBuilding, this.permissions)
        );
        this.currentBuilding = building;
      })
    );

    // Floor Data
    this.subcriptions.push(
      this.spaceConfigurationService.floorChanged.subscribe((floor) => {
        if (floor) {
          this.floor = floor;
          this.floorReservations = floor.reservations;
          this.floorSpaces = floor.spaces;
          this.svgProperties.offsetX = -floor.mapOffsetX;
          this.svgProperties.offsetY = -floor.mapOffsetY;
          this.svgProperties.scale = floor.scale;
          this.initVariables(floor);
          this.loadMap(this.selected);
        } else {
          this.loading = false;
        }
      })
    );

    this.subcriptions.push(
      this.spaceConfigurationService.editedSpace.subscribe((space: Space) => {
        if (space) {
          const index = this.floorSpaces.findIndex((item) => item.code == space.code);
          const resrvableSpaceIndex = this.floorReservations.findIndex((el) => el.id === space.id);
          if (resrvableSpaceIndex !== -1) {
            this.reservationService.getRservationSpaceInfo(space.id).subscribe((reservationSpace) => {
              reservationSpace.typeName = space.typeName;
              this.floorReservations[resrvableSpaceIndex] = reservationSpace;
            });
          }
          if (index > -1) {
            this.floorSpaces[index] = space;
          } else {
            this.floorSpaces.push(space);
          }
          this.loadMap(this.selected);
        }
      })
    );

    this.subcriptions.push(
      this.spaceConfigurationService.deletedSpace.subscribe((space: Space) => {
        if (space) {
          const index = this.floorSpaces.findIndex((item) => item.id == space.code);
          if (index > -1) {
            this.floorSpaces.splice(index, 1);
            this.loadMap(this.selected);
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  initForm(): void {
    this.mapFilterFormGroup = this.formBuilder.group({
      type: ['', []],
      useType: ['', []],
    });
  }

  checkPermissionsUser(permissions: Permission[]): void {
    this.canWriteEsiteSpaces = permissions?.some((item) => item.name == UserPermissions.Spaces && item.type == 'WRITE');
    this.canWriteAreas = permissions?.some(
      (item) => item.name == UserPermissions.GraphicalAreas && item.type == 'WRITE'
    );
    this.canWriteZones = permissions?.some((item) => item.name == UserPermissions.Zones && item.type == 'WRITE');
    this.canWriteReservationsSpaces = permissions?.some(
      (item) => item.name == UserPermissions.ReservableSpaces && item.type == 'WRITE'
    );
  }

  initVariables(floor): void {
    this.currentFloor = floor;
    this.currentFloor.spaces = null;
    this.currentFloor.areas = null;
    this.currentFloor.zones = null;
    this.svgDataToSend = new SvgmapData();
    this.svgDataToSend.img = floor.img;
    this.hasfloorImg = !!this.svgDataToSend.img;
    this.svgDataMemory = { sites: [], areas: [], zones: [], heatmap: [] };
  }

  loadMap(option: FilterOptionType): void {
    this.typesForSelect = this.spaceTypes;
    this.cancelDraw();
    this.clearField();
    this.isDeletingAreaMode = false;
    this.isDeletingZoneMode = false;
    this.isDeletingSiteMode = false;
    if (option === FilterOptionType.zones) {
      this.selectedInSpaces = FilterOptionType.sites;
      this.onlyAreas = false;
      this.legendItems = [];
      this.addButtonTitle = 'CreateZone';
      this.titleModal = 'CreateZone';
      if (this.hasConteoModule) {
        this.updateConteoData();
      }
      this.polygonFormGroup = this.formBuilder.group({
        name: [{ value: '', disabled: !this.canWriteZones }, [Validators.required]],
        maxOccupation: [
          { value: '', disabled: !this.canWriteZones },
          [Validators.required, Validators.min(1), Validators.max(99999)],
        ],
      });
    } else {
      switch (this.selectedInSpaces) {
        case FilterOptionType.areas: {
          this.onlyAreas = true;
          this.addButtonTitle = 'CreateArea';
          this.titleModal = 'CreateArea';
          this.polygonFormGroup = this.formBuilder.group({
            name: [{ value: '', disabled: !this.canWriteAreas }, [Validators.required]],
            maxOccupation: [{ value: '', disabled: !this.canWriteAreas }, []],
          });
          if (this.hasEsiteModule) {
            this.loadResevationMap(FilterOptionType.areas);
          }
          break;
        }
        case FilterOptionType.reservation: {
          this.onlyAreas = false;
          this.currentFloor.spaces = [];
          if (this.hasReservationsModule) {
            this.loadResevationMap(FilterOptionType.reservation);
          }
          break;
        }
        case FilterOptionType.sites: {
          this.onlyAreas = false;
          this.addButtonTitle = 'CreateSite';
          this.titleModal = 'CreateSite';
          if (this.hasEsiteModule) {
            this.loadResevationMap(FilterOptionType.sites);
          }
          break;
        }
        default: {
          break;
        }
      }
    }
    this.loading = false;
  }

  updateEsiteData(hasAreas: boolean): void {
    // Spaces
    if (!this.currentFloor.spaces) {
      const points = [];
      this.currentFloor.spaces = [];
      this.floorSpaces?.forEach((spaceitem: Space) => {
        const space = new Space(spaceitem);
        this.currentFloor.spaces.push(space);
        const point = new SvgmapPoint(space);
        point.color = this.colorSite;
        point.label3 = '';
        point.building = this.currentBuilding.name;
        point.buildingId = this.currentBuilding.id;
        point.floor = this.currentFloor.name;
        point.typeName = spaceitem.typeName;
        point.useType = spaceitem.useType;
        point.resources = space?.resources ?? [];
        points.push(point);
      });
      this.svgDataMemory.sites = points;
      this.applyFilter();
    } else {
      this.applyFilter();
    }

    // Areas
    if (hasAreas) {
      if (!this.currentFloor.areas) {
        this.esiteService.getAreasByFloor(this.currentFloor.id).subscribe((areas) => {
          this.currentFloor.areas = [];
          areas?.forEach((areaitem: Area) => {
            const area = new Area(areaitem);
            this.addAreaToFloorMap(area);
          });
          this.polygonsEvent.next(this.svgDataMemory.areas);
        });
      } else {
        this.polygonsEvent.next(this.svgDataMemory.areas);
      }
    }
  }

  addAreaToFloorMap(area): void {
    // Remove it if exists
    let index = this.currentFloor.areas
      .map(function (item) {
        return item.id;
      })
      .indexOf(area.id);
    if (index > -1) {
      this.currentFloor.areas.splice(index, 1);
    }
    index = this.svgDataMemory.areas
      .map(function (item) {
        return item.code;
      })
      .indexOf(area.id);
    if (index > -1) {
      this.svgDataMemory.areas.splice(index, 1);
    }
    // Add area to memory and to SVG component
    this.currentFloor.areas.push(area);
    const polygon = new SvgmapPolygon(area);
    polygon.typeName = this.translateService.instant('Area');
    polygon.building = this.currentBuilding.name;
    polygon.floor = this.currentFloor.name;
    this.svgDataMemory.areas.push(polygon);
    this.polygonsEvent.next(this.svgDataMemory.areas);
  }

  updateConteoData(): void {
    if (this.svgDataToSend) {
      this.svgDataToSend.points = [];
      this.resourcesOnPoint();
      if (!this.currentFloor.zones) {
        this.conteoService.getZonesByFloor(this.currentFloor.id).subscribe((zones) => {
          this.currentFloor.zones = [];
          zones?.forEach((zoneitem: Zone) => {
            const zone = new Zone(zoneitem);
            this.addZoneFloorMap(zone);
          });
          this.polygonsEvent.next(this.svgDataMemory.zones);
        });
      } else {
        this.polygonsEvent.next(this.svgDataMemory.zones);
      }
    }
  }

  addZoneFloorMap(zone): void {
    // Remove it if exists
    let index = this.currentFloor.zones
      .map(function (item) {
        return item.id;
      })
      .indexOf(zone.id);
    if (index > -1) {
      this.currentFloor.zones.splice(index, 1);
    }
    index = this.svgDataMemory.zones
      .map(function (item) {
        return item.code;
      })
      .indexOf(zone.id);
    if (index > -1) {
      this.svgDataMemory.zones.splice(index, 1);
    }
    // Add area to memory and to SVG component
    this.currentFloor.zones.push(zone);
    const polygon = new SvgmapPolygon(zone);
    polygon.typeName = this.translateService.instant('Zone');
    polygon.building = this.currentBuilding.name;
    polygon.floor = this.currentFloor.name;
    polygon.occupation = zone.maxOccupation + '';
    this.svgDataMemory.zones.push(polygon);
    this.polygonsEvent.next(this.svgDataMemory.zones);
  }

  activateDraw(): void {
    if (this.selected === FilterOptionType.zones) {
      this.titleModal = 'CreateZone';
    } else if (this.selected === FilterOptionType.spaces) {
      this.titleModal = 'CreateArea';
    }
    this.isDrawingMode = true;
  }

  cancelDraw(): void {
    this.isDeletingAreaMode = false;
    this.isDeletingSiteMode = false;
    this.isDeletingZoneMode = false;
    this.isDrawingMode = false;
    this.isClickMode = false;
  }

  newPolygonDrawn(polygon): void {
    if (this.selected == FilterOptionType.spaces && polygon.reservation) {
      const canvas = <HTMLCanvasElement>document.getElementById('myCanvas');
      const ctx = canvas.getContext('2d');

      const newPolygon = new Path2D();
      const polygonArray = polygon.polygon.split(' ');
      for (let i = 0; i < polygonArray.length; i++) {
        let coor = [];
        coor = polygonArray[i].split(',');
        coor[0] = Number(coor[0]);
        coor[1] = Math.abs(Number(coor[1]));
        if (i == 0) {
          newPolygon.moveTo(coor[0], coor[1]);
        } else {
          newPolygon.lineTo(coor[0], coor[1]);
          if (i === polygonArray.length - 1) {
            newPolygon.closePath();
          }
        }
      }
      const selectPoints = this.findInPolygon(this.floorSpaces, ctx, newPolygon);
      if (selectPoints.length) {
        const dialogRef = this.dialog.open(SpaceFormComponent, {
          data: { space: selectPoints, users: this.users, canWriteReservationsSpaces: this.canWriteReservationsSpaces },
          disableClose: true,
        });
        dialogRef.afterClosed().subscribe((result: { isCancelled: boolean; floor: any }) => {
          if (!result.isCancelled) {
            this.habitatService.getFloor(result.floor).subscribe((floor) => {
              this.spaceConfigurationService.setCurrentFloor(floor);
            });
          }
        });
      }
      this.isDrawingMode = false;
    } else {
      this.isDrawingMode = true;
      this.currentPolygon.path = polygon.polygon;
      this.dialogRef = this.dialog.open(this.modal, { disableClose: true });
    }
  }

  savePolygon(): void {
    this.submitted = true;
    if (this.polygonFormGroup.invalid) {
      return;
    }
    if (this.selected === FilterOptionType.zones) {
      const newzone = this.getZoneFromForm();
      if (this.editing) {
        // Show a warning message to advert the user modifying a zone name may have implications on Advanced Informs section.
        if (this.previousZoneName !== newzone.name) {
          const dialogRef = this.dialog.open(ConfirmModalComponent, {
            data: new ConfirmModalData('', 'WarningZoneNameModification'),
            panelClass: 'custom-dialog',
            disableClose: true,
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result?.confirmed) {
              this.updateZone(newzone);
            }
          });
        } else {
          this.updateZone(newzone);
        }
      } else {
        this.conteoService.insertZone(newzone, this.currentFloor.id).subscribe({
          next: (resp) => {
            const zone = new Zone(resp);
            this.addZoneFloorMap(zone);
            this.isDrawingMode = false;
            this.closeModal('data successfully modified');
          },
          error: (err: HttpErrorResponse) => {
            // Possibly a 'Duplicate zone name' error
            if (err.status === 400) {
              /**
               * Timeout is necessary as errors are wiped out
               * (see https://github.com/angular/angular/issues/19170)
               */
              setTimeout(() => {
                this.polygonFormGroup.get('name').markAsDirty();
                this.polygonFormGroup.get('name').markAsTouched();
                this.polygonFormGroup.get('name').setErrors({ duplicate: true });
              }, 1);
            } else {
              setTimeout(() => {
                this.polygonFormGroup.get('name').markAsDirty();
                this.polygonFormGroup.get('name').markAsTouched();
                this.polygonFormGroup.get('name').setErrors({ unknown: true });
              }, 1);
            }
          },
        });
      }
    } else if (this.selected === FilterOptionType.spaces) {
      const newarea = this.getAreaFromForm();
      if (this.editing) {
        this.esiteService.updateArea(newarea).subscribe(() => {
          const area = this.currentFloor.areas.find((item) => item.id === newarea.id);
          area.name = newarea.name;
          this.addAreaToFloorMap(area);
          this.isDrawingMode = false;
          this.closeModal('data successfully modified');
        });
      } else {
        this.esiteService.insertGraphicalArea(newarea, this.currentFloor.id).subscribe((resp) => {
          const area = new Area(resp);
          this.addAreaToFloorMap(area);
          this.isDrawingMode = false;
          this.closeModal('data successfully modified');
        });
      }
    }
  }

  updateZone(newZone: Zone): void {
    this.conteoService.updateZone(newZone).subscribe(() => {
      const zone = this.currentFloor.zones.find((item) => item.id === newZone.id);
      zone.name = newZone.name;
      zone.maxOccupation = newZone.maxOccupation;
      this.addZoneFloorMap(zone);
      this.isDrawingMode = false;
      this.closeModal('data successfully modified');
    });
  }

  getZoneFromForm(): Zone {
    const newzone = new Zone('');
    newzone.id = this.currentPolygon.id;
    newzone.name = this.polygonFormGroup.value.name;
    newzone.maxOccupation = this.polygonFormGroup.value.maxOccupation;
    newzone.graphicalInfo = this.currentPolygon.path;
    return newzone;
  }

  getAreaFromForm(): Area {
    const newarea = new Area('');
    newarea.id = this.currentPolygon.id;
    newarea.name = this.polygonFormGroup.value.name;
    newarea.path = this.currentPolygon.path;
    return newarea;
  }

  polygonClicked(polygon): void {
    if (this.isDeletingAreaMode) {
      const title = this.translateService.instant('confirmDeleteArea') + ': ' + polygon.name;
      const modalData = new ConfirmModalData(title, 'infoDeleteArea');
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: modalData,
        panelClass: 'custom-dialog',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result?.confirmed) {
          this.esiteService.deleteArea(polygon.code).subscribe({
            next: () => {
              this.isDeletingAreaMode = false;
              const index = this.svgDataMemory.areas.findIndex((item) => item.code == polygon.code);
              this.svgDataMemory.areas.splice(index, 1);
              this.currentFloor.areas = null;
              this.updateEsiteData(true);
            },
            error: () => {
              this.isDeletingAreaMode = false;
            },
          });
        }
      });
    } else if (this.isDeletingZoneMode) {
      let infoModalData = null;
      const title = this.translateService.instant('confirmDeleteZone') + ': ' + polygon.name;
      const modalData = new ConfirmModalData(title, 'infoDeleteZone');
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: modalData,
        panelClass: 'custom-dialog',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result?.confirmed) {
          this.conteoService.deleteZone(polygon.code).subscribe({
            next: () => {
              this.isDeletingZoneMode = false;
              const index = this.svgDataMemory.zones.findIndex((item) => item.code == polygon.code);
              this.svgDataMemory.zones.splice(index, 1);
              this.currentFloor.zones = null;
              this.updateConteoData();
            },
            error: (err: HttpErrorResponse) => {
              this.isDeletingZoneMode = false;

              // Possibly a 'zone in use' error
              if (err.status === 409) {
                infoModalData = new InfoModalData('Deleting error', 'You cannot delete this zone because it is in use');
              } else {
                infoModalData = new InfoModalData(
                  'Deleting error',
                  'An error has occurred, please try again. If it persists contact the administrator.'
                );
              }
              this.dialog.open(InfoModalComponent, {
                data: infoModalData,
                panelClass: 'custom-dialog',
                disableClose: true,
              });
            },
          });
        }
      });
    } else if (!this.isDrawingMode) {
      this.currentPolygon.id = polygon.code;
      this.editing = true;
      if (this.selected === FilterOptionType.zones) {
        this.titleModal = 'EditZone';
        const zone = this.currentFloor.zones.find((item) => item.id === polygon.code);
        this.polygonFormGroup.setValue({
          name: zone.name,
          maxOccupation: zone.maxOccupation,
        });
        this.previousZoneName = zone.name;
      } else if (this.selected === FilterOptionType.spaces) {
        this.titleModal = 'EditArea';
        const area = this.currentFloor.areas.find((item) => item.id === polygon.code);
        this.polygonFormGroup.setValue({
          name: area.name,
          maxOccupation: '',
        });
      }
      this.dialogRef = this.dialog.open(this.modal, { disableClose: true });
    }
  }

  closeModal(messageToClosed?: string): void {
    this.dialogRef.close();
    this.dialogRef.afterClosed().subscribe(() => {
      this.currentPolygon = { id: null, path: '' };
      this.submitted = false;
      this.editing = false;
      // Resets the form, so the second time the form is opened, the errors are reseted
      this.polygonFormGroup.reset({ name: '', maxOccupation: '' });
      this.polygonFormGroup.setValue({
        name: '',
        maxOccupation: '',
      });
    });
    if (messageToClosed) {
      this.globalService.printMessage(messageToClosed);
    }
  }

  collapseAction(state): void {
    this.floorDataExpanded = state;
  }

  loadResevationMap(event): void {
    switch (event) {
      case FilterOptionType.reservation: {
        if (this.hasReservationsModule) {
          this.subcriptions.push(
            this.reservationService
              .getUsersFiltered(1, Constants.maxInt, null, ReservationRolesEnum.Approver, null, null)
              .subscribe({
                next: (users: ReservationUsersFiltered) => {
                  this.users = users.users;
                  this.buildReservationSpacesPoints(this.floorReservations, this.colorSiteReservable);
                  this.isReservationMap = true;
                  this.isSitesMap = false;
                  this.polygonsEvent.next([]);
                  this.changeFilter(this.filterselected);
                },
                error: () => {
                  this.buildReservationSpacesPoints(this.floorReservations, this.colorSiteReservable);
                  this.isReservationMap = true;
                  this.isSitesMap = false;
                  this.polygonsEvent.next([]);
                  this.changeFilter(this.filterselected);
                },
              })
          );
          this.reservationGroupMode = true;
        }
        break;
      }
      case FilterOptionType.areas: {
        if (this.hasEsiteModule) {
          this.legendItems = [];
          this.reservationGroupMode = false;
          this.currentFloor.spaces = null;
          this.currentFloor.areas = null;
          this.isReservationMap = false;
          this.isSitesMap = true;
          this.updateEsiteData(true);
        }
        break;
      }
      case FilterOptionType.sites: {
        if (this.hasEsiteModule) {
          this.legendItems = [];
          this.reservationGroupMode = false;
          this.currentFloor.spaces = null;
          this.currentFloor.areas = null;
          this.isReservationMap = false;
          this.isSitesMap = true;
          this.polygonsEvent.next([]);
          this.updateEsiteData(false);
        }
        break;
      }
    }
  }

  /**
   * Check and returns the spaces that lie inside the given enclosed 2D Path
   * @param points Array of floor's spaces
   * @param ctx Canvas2D
   * @param newPolygon Enclosed Path2D
   * @returns Array of spaces that lie within the given Path
   */
  findInPolygon(points: any[], ctx: CanvasRenderingContext2D, newPolygon: Path2D): Space[] {
    const selectedPoints = [];
    points.forEach((point) => {
      if (ctx.isPointInPath(newPolygon, point.x, point.y)) {
        selectedPoints.push(point);
      }
    });
    return selectedPoints;
  }

  buildReservationSpacesPoints(spaces, color): void {
    const points = [];
    spaces.forEach((spaceitem) => {
      points.push(this.createPoint(spaceitem, color));
    });

    this.svgDataMemory.sites = points;
    this.svgDataToSend.polygons = [];
    this.svgDataToSend.points = this.svgDataMemory.sites;
    this.resourcesOnPoint();
  }

  createPoint(spaceitem, color): SvgmapPointReservation {
    const filledUsers = [];
    if (spaceitem.approvers) {
      spaceitem.approvers.forEach((user) => {
        user = new ReservationUser({ id: user });
        user = this.users.find((usr) => usr.id == user.id);
        filledUsers.push(user);
      });
    }
    const space = new Space(spaceitem);
    this.currentFloor.spaces.push(space);
    const point = new SvgmapPointReservation(space);
    point.color = color;
    point.name = spaceitem.code;
    point.rGroup = spaceitem.groupable ? 'Yes' : 'No';
    point.approval = spaceitem.needsApproval ? 'Yes' : 'No';
    point.usersData = filledUsers;
    point.label3 = spaceitem.role;
    point.titlelabel3 = this.translateService.instant('Permission');
    point.label4 = this.translateService.instant(point.rGroup);
    point.titlelabel4 = this.translateService.instant('R.Group');
    point.label5 = this.translateService.instant(point.approval);
    point.titlelabel5 = this.translateService.instant('Approval');
    point.building = this.currentBuilding.name;
    point.buildingId = this.currentBuilding.id;
    point.floor = this.currentFloor.name;
    point.typeName = spaceitem.typeName;
    return point;
  }

  pointClicked(event): void {
    if (!this.isDeletingSiteMode) {
      const spaces = [];
      if (event instanceof Array) {
        event.forEach((point) => {
          spaces.push({
            id: point.code,
            name: point.name,
            type: point.typeName,
            rGroup: point.rGroup,
            permission: point.label3,
            approval: point.approval,
            usersData: point.usersData,
          });
        });
      } else {
        spaces.push({
          id: event.code,
          name: event.name,
          type: event.typeName,
          rGroup: event.rGroup,
          permission: event.label3,
          approval: event.approval,
          usersData: event.usersData,
        });
      }

      const dialogRef = this.dialog.open(SpaceFormComponent, {
        data: { space: spaces, users: this.users, canWriteReservationsSpaces: this.canWriteReservationsSpaces },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result: { isCancelled: boolean; floor: any }) => {
        if (!result.isCancelled) {
          const floorId = result.floor || this.floor.id;
          this.habitatService.getFloor(floorId).subscribe((floor) => {
            this.spaceConfigurationService.setCurrentFloor(floor);
          });
        }
      });
    } else {
      let title = '';
      if (event instanceof Array) {
        title = this.translateService.instant('confirmDeleteSpace') + ': ' + event.map((item) => item.name).join(', ');
      } else {
        title = this.translateService.instant('confirmDeleteSpace') + ': ' + event.name;
      }

      const modalData = new ConfirmModalData(title, 'infoDeleteSpace');
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: modalData,
        panelClass: 'custom-dialog',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result?.confirmed) {
          if (event instanceof Array) {
            event.forEach((item) => {
              this.esiteService.deleteSpace(item.code).subscribe({
                next: () => {
                  this.spaceConfigurationService.setDeletedSpace(item);
                },
                error: () => {
                  this.isDeletingSiteMode = false;
                },
              });
            });
          } else {
            this.esiteService.deleteSpace(event.code).subscribe({
              next: () => {
                this.spaceConfigurationService.setDeletedSpace(event);
              },
              error: () => {
                this.isDeletingSiteMode = false;
              },
            });
          }
        }
      });
    }
  }

  activateDrawReservation(): void {
    this.isDrawingMode = true;
    this.reservationGroupMode = true;
  }

  changeFilter(value): void {
    this.clearField();
    const filteredSpaces = [];
    this.svgDataMemory.sites = [];
    this.legendItems = [];
    this.filterselected = value;
    switch (this.filterselected) {
      case 'approval': {
        this.floorReservations
          .filter((item) => item.needsApproval === false)
          .forEach((point) => {
            filteredSpaces.push(this.createPoint(point, '#ff3d71'));
          });
        this.legendItems.push(this.createLeyendItem(this.translateService.instant('No needs approval'), '#ff3d71'));
        this.floorReservations
          .filter((item) => item.needsApproval === true)
          .forEach((point) => {
            filteredSpaces.push(this.createPoint(point, '#0075AA'));
          });
        this.legendItems.push(this.createLeyendItem(this.translateService.instant('Needs approval'), '#0075AA'));
        this.legendItems.push(
          this.createLeyendItem(
            this.translateService
              .instant('Needs approval')
              .concat(' / ')
              .concat(this.translateService.instant('No needs approval')),
            '#FFD100'
          )
        );
        break;
      }
      case 'rgroup': {
        this.floorReservations
          .filter((item) => item.groupable === false)
          .forEach((point) => {
            filteredSpaces.push(this.createPoint(point, '#ff3d71'));
          });
        this.legendItems.push(this.createLeyendItem(this.translateService.instant('No group reservable'), '#ff3d71'));
        this.floorReservations
          .filter((item) => item.groupable === true)
          .forEach((point) => {
            filteredSpaces.push(this.createPoint(point, '#0075AA'));
          });
        this.legendItems.push(this.createLeyendItem(this.translateService.instant('Group reservable'), '#0075AA'));
        this.legendItems.push(
          this.createLeyendItem(
            this.translateService
              .instant('Group reservable')
              .concat(' / ')
              .concat(this.translateService.instant('No group reservable')),
            '#FFD100'
          )
        );
        break;
      }
      case 'permission': {
        this.floorReservations
          .filter((item) => item.role === ReservationRolesEnum.Basic)
          .forEach((point) => {
            filteredSpaces.push(this.createPoint(point, '#ff3d71'));
          });
        this.legendItems.push(this.createLeyendItem(ReservationRolesEnum.Basic, '#ff3d71'));

        this.floorReservations
          .filter((item) => item.role === ReservationRolesEnum.Advanced)
          .forEach((point) => {
            filteredSpaces.push(this.createPoint(point, '#0075AA'));
          });
        this.legendItems.push(this.createLeyendItem(ReservationRolesEnum.Advanced, '#0075AA'));

        this.floorReservations
          .filter((item) => item.role === ReservationRolesEnum.Approver)
          .forEach((point) => {
            filteredSpaces.push(this.createPoint(point, '#36a677'));
          });
        this.legendItems.push(this.createLeyendItem(ReservationRolesEnum.Approver, '#36a677'));
        this.legendItems.push(
          this.createLeyendItem(
            ReservationRolesEnum.Basic.concat(' / ')
              .concat(ReservationRolesEnum.Advanced)
              .concat(' / ')
              .concat(ReservationRolesEnum.Approver),
            '#FFD100'
          )
        );
        break;
      }
      case 'approvalUsers': {
        const spacesList = [];
        this.userIndex = 0;
        this.legendItems.push(this.createLeyendItem(this.translateService.instant('Multiple users'), '#ff3d71'));
        this.legendItems.push(this.createLeyendItem(this.translateService.instant('No need approval'), '#36a677'));
        this.legendItems.push(
          this.createLeyendItem(
            this.translateService
              .instant('Multiple users')
              .concat(' / ')
              .concat(this.translateService.instant('No need approval')),
            '#FFD100'
          )
        );
        this.floorReservations.forEach((space) => {
          space.approvers = space.approvers.filter((item) => item !== null);
          if (space.approvers.length > 1) {
            spacesList.push({ color: '#ff3d71', space: [space] });
          }
          if (space.approvers.length === 0) {
            spacesList.push({ color: '#36a677', space: [space] });
          }

          if (space.approvers.length === 1) {
            space.approvers.forEach((user) => {
              const color = this.colorPalette[this.userIndex];
              this.userIndex += 1;
              if (this.userIndex > 20) {
                this.userIndex = 0;
              }
              const userItem = this.users.find((item) => item.id === user);
              if (!spacesList.find((item) => item.user === userItem)) {
                spacesList.push({ color: color, user: userItem, space: [space], legendText: userItem.name });
              } else {
                spacesList.find((item) => item.user === userItem).space.push(space);
              }
            });
          }
        });
        spacesList.forEach((space) => {
          if (space.space.length >= 1 && space.user) {
            space.space.forEach((spaceItem) => {
              filteredSpaces.push(this.createPoint(spaceItem, space.color));
            });
            this.legendItems.push(this.createLeyendItem(space.legendText, space.color));
          } else {
            filteredSpaces.push(this.createPoint(space.space[0], space.color));
          }
        });

        break;
      }
      case 'reservable': {
        this.legendItems.push(this.createLeyendItem(this.translateService.instant('Not reservable'), '#ff3d71'));
        this.legendItems.push(this.createLeyendItem(this.translateService.instant('Reservable'), '#36a677'));
        this.legendItems.push(
          this.createLeyendItem(
            this.translateService
              .instant('Reservable')
              .concat(' / ')
              .concat(this.translateService.instant('Not reservable')),
            '#FFD100'
          )
        );
        this.floorSpaces.forEach((habitatSpace) => {
          const isReservableSpace = this.floorReservations.find((item) => item.code === habitatSpace.code);
          if (isReservableSpace) {
            filteredSpaces.push(this.createPoint(isReservableSpace, '#36a677'));
          } else {
            filteredSpaces.push(this.createPoint(habitatSpace, '#ff3d71'));
          }
        });

        break;
      }
      case 'noFilter': {
        this.floorSpaces.forEach((habitatSpace) => {
          const isReservableSpace = this.floorReservations.find((item) => item.code === habitatSpace.code);
          if (isReservableSpace) {
            filteredSpaces.push(this.createPoint(isReservableSpace, '#36a677'));
          }
        });
        break;
      }
      default: {
        break;
      }
    }
    this.svgDataMemory.sites = filteredSpaces;
    this.applyFilter();
  }

  createLeyendItem(name, color): SvgmapLegendItem {
    const legendItem = new SvgmapLegendItem();
    legendItem.color = color;
    legendItem.name = name;

    return legendItem;
  }

  deletingAreaMode(): void {
    this.isDeletingAreaMode = true;
  }

  deletingZoneMode(): void {
    this.isDeletingZoneMode = true;
  }

  deletingSiteMode(): void {
    this.isDeletingSiteMode = true;
  }

  activateClickSites(): void {
    this.isClickMode = true;
  }

  positionClicked(event): void {
    const dialogRef = this.dialog.open(SpaceNewSpaceComponent, {
      data: { position: event, floor: this.floor, canWriteEsiteSpaces: this.canWriteEsiteSpaces },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: { isCancelled: boolean; space: Space }) => {
      if (!result.isCancelled) {
        this.isClickMode = false;
        this.spaceConfigurationService.setEditedSpace(result.space);
      } else {
        this.isClickMode = true;
      }
    });
  }

  exportfloor(): void {
    const modalData = new ConfirmModalData('DownloadTitle', 'DownloadText', false);
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: { confirmed?: boolean }) => {
      if (!result?.confirmed) {
        this.downloadSubscription.unsubscribe();
      }
    });
    const filesName =
      'Habitat_' +
      this.currentBuilding.name.replace(' ', '') +
      '_' +
      this.floor.name.replace(' ', '') +
      '_' +
      this.dateService.getDateDownload(new Date());

    if (this.svgDataToSend.img && !this.svgDataToSend.img?.type) {
      fetch(this.svgDataToSend.img.toString())
        .then((res) => res.blob())
        .then((blob) => {
          //Obtain floor excel and save zip
          this.downloadZipFile(filesName, blob, dialogRef);
        });
    } else if (this.svgDataToSend.img?.type) {
      //Obtain floor excel and save zip
      this.downloadZipFile(filesName, this.svgDataToSend.img, dialogRef);
    } else {
      this.downloadSubscription = this.generalService.getFloorMetadata(this.floor.id, filesName).subscribe({
        next: (excelFile: Blob) => {
          dialogRef.close();
          FileSaver.saveAs(excelFile, filesName);
        },
        error: () => {
          dialogRef.close({ confirmed: true });
          this.showErrorDownloadingFloorData();
        },
      });
    }
  }

  downloadZipFile(fileName: string, image: Blob, openedDialog: MatDialogRef<ConfirmModalComponent, any>): void {
    let imageFormat = '';
    switch (image.type) {
      case 'image/jpeg':
        imageFormat = 'jpeg';
        break;
      case 'image/png':
        imageFormat = 'png';
        break;
      default:
        console.error(`Received MIME type for the floor image: ${image.type} not supported`);
        openedDialog.close({ confirmed: true });
        this.showErrorDownloadingFloorData();
        return;
    }
    //Obtain floor excel and save zip
    const jszip = new JSZip();
    this.downloadSubscription = this.generalService
      .getFloorMetadata(this.floor.id, fileName + '.' + imageFormat)
      .subscribe({
        next: (excelFile: Blob) => {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onloadend = () => {
            openedDialog.close();
            jszip.file(fileName + '.xlsx', excelFile);
            jszip.file(
              fileName + '.' + imageFormat,
              (reader.result as string).replace('data:image/' + imageFormat + ';base64,', ''),
              {
                base64: true,
              }
            );
            jszip.generateAsync({ type: 'blob' }).then((content) => {
              FileSaver.saveAs(content, fileName);
            });
          };
        },
        error: () => {
          openedDialog.close({ confirmed: true });
          this.showErrorDownloadingFloorData();
        },
      });
  }

  /**
   * Opens a dialog telling the user an error happened while downloading floor data
   */
  showErrorDownloadingFloorData(): void {
    const modalData = new ConfirmModalData('ErrorDownloadTitle', 'ErrorDownloadText');
    this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
  }

  applyFilter(filterType?: string, event?: Event | any): void {
    if (filterType === 'search') {
      this.svgDataToSend.points = this.svgDataMemory.sites.filter((item) => {
        if (this.typeFilter !== '') {
          return item.name.toLowerCase().includes(this.spaceToFind.toLowerCase()) && item.typeName === this.typeFilter;
        } else if (this.useTypeFilter !== '') {
          return (
            item.name.toLowerCase().includes(this.spaceToFind.toLowerCase()) && item.useType === this.useTypeFilter
          );
        } else {
          return item.name.toLowerCase().includes(this.spaceToFind.toLowerCase());
        }
      });
    } else if (filterType === 'useType') {
      this.clearSearch();
      this.useTypeFilter = event != undefined ? event.value : this.useTypeFilter;
      this.typesForSelect = this.spaceTypes.filter((type) => type.useType === this.useTypeFilter);
      this.svgDataToSend.points =
        this.typeFilter === ''
          ? this.svgDataMemory.sites.filter((item) => item.useType === this.useTypeFilter)
          : this.svgDataMemory.sites.filter(
              (item) => item.useType === this.useTypeFilter && item.typeName === this.typeFilter
            );
    } else if (filterType === 'type') {
      this.clearSearch();
      this.typeFilter = event != undefined ? event.value.name : this.typeFilter;
      if (this.selectedInSpaces == this.optionSites || this.selected == this.optionSites) {
        const selectedTypeObj = this.spaceTypes.find((type) => type.name === this.typeFilter);
        this.useTypeFilter = selectedTypeObj.useType;
        this.mapFilterFormGroup.controls['useType'].setValue(selectedTypeObj.useType);
      }
      this.svgDataToSend.points =
        this.useTypeFilter === ''
          ? this.svgDataMemory.sites.filter((item) => item.typeName === this.typeFilter)
          : this.svgDataMemory.sites.filter(
              (item) => item.useType === this.useTypeFilter && item.typeName === this.typeFilter
            );
    } else {
      this.svgDataToSend.points = this.svgDataMemory.sites;
    }
    this.resourcesOnPoint();
  }

  clearSearch(): void {
    this.spaceToFind = '';
    this.svgDataToSend.points = this.svgDataMemory.sites;
    this.resourcesOnPoint();
  }

  clearField(event?: string): void {
    switch (event) {
      case 'useType': {
        this.mapFilterFormGroup.controls['useType'].setValue(null);
        this.mapFilterFormGroup.controls['type'].setValue(null);
        this.useTypeFilter = '';
        this.typeFilter = '';
        this.typesForSelect = this.spaceTypes;
        this.applyFilter();
        break;
      }
      case 'type': {
        this.mapFilterFormGroup.controls['type'].setValue(null);
        this.typeFilter = '';
        this.useTypeFilter !== '' ? this.applyFilter('useType') : this.applyFilter();
        break;
      }
      default: {
        this.mapFilterFormGroup.controls['type'].setValue(null);
        this.mapFilterFormGroup.controls['useType'].setValue(null);
        this.typeFilter = '';
        this.useTypeFilter = '';
        this.applyFilter();
        break;
      }
    }
  }

  resourcesOnPoint(): void {
    this.svgDataToSend.pointsXY = new Map();
    const pointGroup = [];
    from(this.svgDataToSend.points.filter((item) => item).filter((item) => item.positionX && item.positionY))
      .pipe(
        groupBy((point) => point.positionX + '#' + point.positionY),
        mergeMap((group) => zip(of(group.key), group.pipe(toArray()))),
        finalize(() => {
          pointGroup.forEach((pg) => {
            this.svgDataToSend.pointsXY.set(pg[0], pg[1]);
          });
        })
      )
      .subscribe((resp) => pointGroup.push(resp));
  }

  get f(): any {
    return this.polygonFormGroup.controls;
  }
}
