<mat-card>
  <mat-card-header class="p-2">
    <div class="d-flex align-items-center justify-content-between">
      <label class="titleN2 text-primary">{{ 'account' | translate }}</label>
      <div>
        <button class="shadow-none" (click)="closeSession()">
          <span class="bi bi-box-arrow-right text-primary logout-icon"></span>
          <span class="titleN3 closeSession">{{ 'SignOff' | translate }}</span>
        </button>
      </div>
    </div>

    <mat-tab-group animationDuration="0ms">
      <mat-tab
        *ngIf="hasConteoModule || hasEsiteDevicesModule"
        [isActive]="true"
      >
        <ng-template mat-tab-label>
          <span class="bi bi-bell me-2"></span>
          <span class="d-none d-sm-inline">{{ 'Notifications' | translate }}</span>
        </ng-template>
        <app-notifications-config></app-notifications-config>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="bi bi-person me-2"></span>
          <span class="d-none d-sm-inline">{{ 'Profile' | translate }}</span>
        </ng-template>
        <app-user-info></app-user-info>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <span class="bi bi-gear me-2"></span>
          <span class="d-none d-sm-inline">{{ 'General' | translate }}</span>
        </ng-template>
        <app-user-settings></app-user-settings>
      </mat-tab>
    </mat-tab-group>
  </mat-card-header>
</mat-card>
