<div *ngIf="havePermission">
  <app-space-filter
    [canChangeArea]="false"
    (buildingChanged)="buildingChanged($event)"
    (floorChanged)="floorChanged($event)"
    (noBuildings)="noBuildings = $event"
    (noFloors)="noFloors = $event"
    [mostrarBoton]="mostrarBoton"
  >
  </app-space-filter>
  <app-space-configuration-space *ngIf="currentBuilding"></app-space-configuration-space>
  <app-nodata-card [noBuildings]="noBuildings" [noFloors]="noFloors"></app-nodata-card>
  <app-space-configuration-floor *ngIf="currentFloor"></app-space-configuration-floor>
</div>
