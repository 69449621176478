import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SpaceConfigurationService } from 'src/app/services/pages/space-configuration.service';

import { NotPermissionComponent } from '../../not-permission/not-permission.component';
import { User } from './../../../../models/auth/User.class';
import { UserPermissions } from './../../../../models/auth/UserPermissions.enum';
import { StorageVariables } from './../../../../models/StorageVariables.enum';
import { GlobalService } from './../../../../services/common/global.service';
import { AppConstants } from '../../../../shared/AppConstants';

@Component({
  selector: 'app-space-configuration',
  templateUrl: './space-configuration.component.html',
  styles: [],
})
export class SpaceConfigurationComponent implements OnInit, OnDestroy {
  currentBuilding = null;
  currentFloor = null;
  currentArea = null;
  subcriptions: Subscription[] = [];
  noBuildings = false;
  noFloors = false;
  private userLogged: User;
  havePermission = true;
  mostrarBoton = true;

  constructor(
    private spaceConfigurationService: SpaceConfigurationService,
    private globalService: GlobalService,
    private translateService: TranslateService,
    private messageService: MessageService,
    public dialog: MatDialog
  ) {
    this.userLogged = this.globalService.getUser();
  }

  ngOnInit(): void {
    this.buildPermission();
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  buildingChanged($event): void {
    this.currentFloor = null;
    this.spaceConfigurationService.setCurrentBuilding($event);
    this.currentBuilding = $event;
    this.buildPermission();
  }

  floorChanged($event): void {
    this.currentArea = null;
    this.spaceConfigurationService.setCurrentFloor($event);
    this.currentFloor = $event;
  }

  buildPermission(): void {
    const buildingId = this.currentBuilding
      ? this.currentBuilding.id
      : parseInt(localStorage.getItem(StorageVariables.FAVORITE_BUILDING));

    if (
      (buildingId &&
        this.userLogged?.permissionBuilding
          .find((item) => item.buildingId === buildingId)
          ?.permission.find((it) => it.name == UserPermissions.Spaces)) ||
      this.userLogged?.permissionBuilding
        .find((item) => item.buildingId === -1)
        ?.permission.find((it) => it.name == UserPermissions.Spaces)
    ) {
      this.havePermission = true;
    } else {
      this.havePermission = false;
      this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
      this.messageService.add({
        key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
        severity: 'warn',
        summary: this.translateService.instant('Warning'),
        detail: this.translateService.instant('You do not have the Spaces permission'),
      });
      this.dialog.open(NotPermissionComponent, { disableClose: true });
    }
  }
}
