export class Incident {
  title: number;
  descripcion: string;
  categoryId: number;

  constructor(title, descr, categoryId) {
    this.title = title;
    this.descripcion = descr;
    this.categoryId = categoryId;
  }

  toBackenFormat(): any {
    const backendObject = {
      subject: this.title,
      description: this.descripcion,
      scopeId: this.categoryId,
    };
    return backendObject;
  }
}
