<collapse-card title="{{ 'Subsection types' | translate }}">
    <div *ngIf="loading" style="min-height: 350px; display: flex; align-items: center; justify-content: center">
      <app-loading></app-loading>
    </div>
    <div [hidden]="loading">
      <div class="d-flex gap-3">
        <mat-form-field class="flex-grow-1">
          <mat-label class="labelSearch">{{ 'Search' | translate }}</mat-label>
          <div class="d-flex">
            <input
              title="{{ 'Search' | translate }}"
              matInput
              class="filter-input py-1"
              (keyup)="applyFilter($event)"
              #input
              type="text"
            />
            <div class="searchIcon">
              <span class="bi bi-search text-primary"></span>
            </div>
          </div>
        </mat-form-field>
        <div *ngIf="canWriteSubsectionType" class="d-flex justify-content-end align-items-center">
          <button mat-button (click)="createNewType()">
            <span class="smallIcon bi bi-plus-circle"></span>
            <span class="ps-1">{{ 'New Type' | translate }}</span>
          </button>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" style="width: 100%" matSort #sortData="matSort">
        <caption></caption>
        <th id="subsectionsTypesTable"></th>
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3"
            >{{ 'Type name' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let type" style="flex: 1.3">
            <span *ngIf="type.name">
              {{ type.name }}
            </span>
            <span *ngIf="!type.name"> -- </span>
          </mat-cell>
        </ng-container>
  
        <ng-container matColumnDef="maxFloors">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.2">
            {{ 'Relation type' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let type" style="flex: 1.2">
            <span *ngIf="type.maxFloors === subsectionTypeRelation.NO_PHYSICAL_LOCATION">
              {{ 'NoPhysicalLocation' | translate }}
            </span>
            <span *ngIf="type.maxFloors === subsectionTypeRelation.ONLY_IN_ONE_FLOOR">
              {{ 'OnlyOneFloor' | translate }}
            </span>
            <span *ngIf="type.maxFloors === subsectionTypeRelation.IN_MANY_FLOORS">
              {{ 'ManyFloors' | translate }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="action">
          <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.6">
            {{ 'Actions' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let type" style="flex: 0.6" class="d-flex gap-2">
            <span
              *ngIf="canWriteSubsectionType"
              class="smallIcon bi bi-pencil"
              role="button"
              (click)="editSubsectionType(type)"
            ></span>
            <span
              *ngIf="!canWriteSubsectionType"
              class="smallIcon bi bi-eye"
              role="button"
              (click)="editSubsectionType(type)"
            ></span>
            <span
              *ngIf="canWriteSubsectionType"
              class="smallIcon bi bi-trash"
              role="button"
              (click)="deleteSubsectionType(type)"
            ></span>
          </mat-cell>
        </ng-container>
  
        <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
      </table>
      <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
    </div>
  </collapse-card>
  
  <ng-template #newOrEditType>
    <mat-card style="width: 400px">
      <mat-card-content class="marginBodyCard">
        <label class="titleN3 text-primary modalTitle"> {{ modalTitle | translate }}</label>
        <form [formGroup]="typeForm" (ngSubmit)="onSubmit(typeForm)" style="display: flex; flex-wrap: wrap">
          <mat-form-field style="width: 100%; margin-right: 10px">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input
              title="{{ 'Name' | translate }}"
              matInput
              autocomplete="off"
              formControlName="name"
              type="text"
              class="p-1"
              maxlength="45"
              required
            />
            <mat-hint *ngIf="typeForm.get('name')?.value.length" align="end"
              >{{ typeForm.get('name').value.length }}/45</mat-hint
            >
            <mat-error *ngIf="submitted && f.name.errors?.required">{{ 'You must enter a name' | translate }}</mat-error>
  
            <mat-error *ngIf="submitted && f.name.errors?.unknown">{{
              'ErrorCreatingSubsectionTypeGeneric' | translate
            }}</mat-error>
            <mat-error *ngIf="submitted && f.name.errors?.duplicate">{{
              'There is already another subsection type with this name' | translate
            }}</mat-error>
            <mat-error *ngIf="f.name.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
          </mat-form-field>
  
          <mat-form-field style="width: 100%; padding-top: 10px">
            <mat-label> {{ 'Select relation type' | translate }}</mat-label>
            <mat-select class="p-1" style="padding: 0px; width: 100%" formControlName="maxFloors" required>
              <mat-option [value]="subsectionTypeRelation.NO_PHYSICAL_LOCATION">{{
                'NoPhysicalLocation' | translate
              }}</mat-option>
              <mat-option [value]="subsectionTypeRelation.ONLY_IN_ONE_FLOOR">{{ 'OnlyOneFloor' | translate }}</mat-option>
              <mat-option [value]="subsectionTypeRelation.IN_MANY_FLOORS">{{ 'ManyFloors' | translate }}</mat-option>
            </mat-select>
            <mat-error *ngIf="submitted && f.maxFloors.errors?.required">{{
              'You must enter a relation type' | translate
            }}</mat-error>
            <mat-error *ngIf="submitted && f.maxFloors.errors?.typeConflict">{{
              'ErrorMaxFloorsConflict' | translate
            }}</mat-error>
          </mat-form-field>
  
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
            <button mat-button class="modalCancel text-primary" (click)="cancel()" type="button">
              {{ 'Cancel' | translate }}
            </button>
            <button [hidden]="!canWriteSubsectionType" mat-button class="modalButton bg-primary" type="submit">
              {{ 'Save' | translate }}
            </button>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </ng-template>
  