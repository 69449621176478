import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './components/auth/auth.component';
import { PrivacyPolicyComponent } from './components/auth/legal-terms/privacy-policy/privacy-policy.component';
import { LoginErrorComponent } from './components/auth/login-error/login-error.component';
import { NotPermissionAuthComponent } from './components/auth/not-permission-auth/not-permission-auth.component';
import { PagesRoutingModule } from './components/pages/pages-routing.module';

const routes: Routes = [
  { path: 'es/privacy-policy', component: PrivacyPolicyComponent },
  { path: 'en/privacy-policy', component: PrivacyPolicyComponent },
  { path: 'it/privacy-policy', component: PrivacyPolicyComponent },
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: 'autherror', component: LoginErrorComponent },
      { path: 'forbidden', component: LoginErrorComponent },
      { path: 'not-permission', component: NotPermissionAuthComponent },
    ],
  },
  { path: '', redirectTo: '/realtime', pathMatch: 'full' },
  { path: '**', redirectTo: 'realtime' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config), PagesRoutingModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}
