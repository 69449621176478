import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { NotPermissionComponent } from '../../not-permission/not-permission.component';
import { Permission } from './../../../../models/auth/Role.class';
import { User } from './../../../../models/auth/User.class';
import { UserPermissions } from './../../../../models/auth/UserPermissions.enum';
import { GlobalService } from './../../../../services/common/global.service';
import { AppConstants } from '../../../../shared/AppConstants';

@Component({
  selector: 'app-continuous-reservations',
  templateUrl: './continuous-reservations.component.html',
  styleUrls: ['./continuous-reservations.component.css'],
})
export class ContinuousReservationsComponent implements OnInit {
  private userLogged: User;
  private permissions: Permission[] = [];
  havePermission = true;

  constructor(
    private globalService: GlobalService,
    private translateService: TranslateService,
    private messageService: MessageService,
    public dialog: MatDialog
  ) {
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
  }

  ngOnInit(): void {
    if (!this.permissions?.map((permission) => permission.name).includes(UserPermissions.ContinuousReservations)) {
      this.havePermission = false;
      this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
      this.messageService.add({
        key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
        severity: 'warn',
        summary: this.translateService.instant('Warning'),
        detail: this.translateService.instant('You do not have the ContinuousReservations permission'),
      });
      this.dialog.open(NotPermissionComponent, { disableClose: true });
    }
  }
}
