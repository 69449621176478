import { Component, OnInit } from '@angular/core';
import { Languages } from 'src/app/models/Languages.enum';
import { LanguageService } from 'src/app/services/common/language.service';

interface CustomContextMenuItem {
  value: Languages;
  label: string;
}

@Component({
  selector: 'app-login-header',
  templateUrl: './login-header.component.html',
  styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent implements OnInit {
  languages: CustomContextMenuItem[] = [
    { value: Languages.en, label: 'English' },
    { value: Languages.es, label: 'Español' },
    { value: Languages.it, label: 'Italiano' },
  ];

  constructor(private languageService: LanguageService) {}

  ngOnInit(): void {}

  onLanguageItemClick(lang: string) {
    this.languageService.changeCurLanguage(lang);
  }
}
