<div class="modalCard" style="min-width: 700px">
  <div class="marginBodyCard">
    <label class="titleN3 text-primary"> {{ 'Continuous Reservations' | translate }}</label>
    <div style="display: flex; justify-content: flex-end; padding-right: 10px">
      <button
        mat-button
        [disabled]="!canWriteReservations"
        (click)="openNewContinuousReserve()"
        class="mb-2"
      >
        <span class="smallIcon bi bi-plus-circle"></span>
        <span class="ps-1">{{ 'New continuous reservation' | translate }}</span>
      </button>
    </div>

    <app-continuous-reserve-table [reloadTable]="reloadTable" [canWriteReservations]="canWriteReservations"></app-continuous-reserve-table>

    <div style="text-align: center">
      <button mat-button class="modalCancel text-primary" (click)="close()" type="button">
        {{ 'Close' | translate }}
      </button>
    </div>
  </div>
</div>
