import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, finalize, from, groupBy, mergeMap, of, Subscription, toArray, zip } from 'rxjs';
import { ChartOptionData } from 'src/app/models/charts/ChartOptionData.class';
import { Zone } from 'src/app/models/conteo/Zone.class';
import { Area } from 'src/app/models/esite/Area.class';
import { AvailabilityType } from 'src/app/models/esite/AvailabilityType.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { SpacesOccupationData } from 'src/app/models/realtime/SpacesOccupationData.class';
import { SpacesState } from 'src/app/models/realtime/SpacesState.class';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { SvgmapData } from 'src/app/models/svgmap/SvgmapData.class';
import { SvgmapPoint } from 'src/app/models/svgmap/SvgmapPoint.class';
import { SvgmapPolygon } from 'src/app/models/svgmap/SvgmapPolygon.class';
import { SvgmapProperties } from 'src/app/models/svgmap/SvgmapProperties.class';
import { RealtimeService } from 'src/app/services/pages/realtime.service';

import { GlobalService } from './../../../../services/common/global.service';

@Component({
  selector: 'app-realtime-floor',
  templateUrl: './realtime-floor.component.html',
  styleUrls: ['./realtime-floor.component.scss'],
})
export class RealtimeFloorComponent implements OnInit, OnChanges, OnDestroy {
  @Input() spaceEsiteInfoModified: SvgmapPoint;
  @Input() spaceReservationInfoModified: ReservationSpace[];
  @Input() zoneModified: SvgmapPolygon;
  svgPointEsiteInfoModified: SvgmapPoint;
  svgPointReservationInfoModified: ReservationSpace[];
  svgPolygonModified: SvgmapPolygon;
  zones: Zone[];
  initalFloorInfoState = true;
  floorDataExpanded = true;
  currentBuilding = new Building('');
  currentFloor = new Floor('');
  imgMap = '';
  layers = { areas: 'areas', sites: 'sites', zones: 'zones' };
  svgProperties = new SvgmapProperties({ id: 11, initialZoom: 1 });
  svgDataMemory = { sites: [], areas: [], zones: [] };
  svgDataToSend: SvgmapData;
  legendItems = [];
  roomsChart = { id: 13, name: 'Rooms', msgEmpty: 'NoRoomsInFloor', title: '', data: null };
  sitesChart = { id: 14, name: 'Sites', msgEmpty: 'NoPostsInFloor', title: '', data: null };
  areasChart = { id: 15, name: 'Areas', msgEmpty: 'NoAreasInFloor', title: 'Not available sites', data: null };
  zonesChart = { id: 16, name: 'Zones', msgEmpty: 'NoZonesInFloor', title: 'People in the zone', data: null };
  colors = { areasChart: '#0075AA', zonesChart: '#ffd100' };
  sitesCheck = true;
  areasCheck = true;
  zonesCheck = true;
  reservationsCheck = true;
  subcriptions: Subscription[] = [];
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  hasEsiteDevicesModule = false;
  spaceToFind = '';

  constructor(
    private realtimeService: RealtimeService,
    private globalService: GlobalService,
    private translateService: TranslateService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasReservationsModule = this.globalService.getReservationModule();
    this.hasEsiteDevicesModule = this.globalService.getEsiteDevice();
    this.hasConteoModule = this.globalService.getConteoModule();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.spaceEsiteInfoModified && changes.spaceEsiteInfoModified.currentValue) {
      this.svgPointEsiteInfoModified = changes.spaceEsiteInfoModified.currentValue;
    }
    if (changes.zoneModified && changes.zoneModified.currentValue) {
      this.svgPolygonModified = new SvgmapPolygon(changes.zoneModified.currentValue);
      if (!this.zones) {
        this.zones = [];
      }
      const index = this.zones.findIndex((item) => item.id == changes.zoneModified.currentValue.id);
      if (index > -1) {
        this.zones[index] = changes.zoneModified.currentValue;
        this.updateZonesBarChart(this.zones);
      }
    }
    if (changes.spaceReservationInfoModified && changes.spaceReservationInfoModified.currentValue) {
      this.svgPointReservationInfoModified = changes.spaceReservationInfoModified.currentValue;
    }
  }

  ngOnInit(): void {
    this.reservationsCheck = this.hasReservationsModule;

    this.subcriptions.push(
      this.realtimeService.buildingChanged.subscribe((building) => {
        this.currentBuilding = building;
        this.svgDataToSend = null;
        this.initEsiteFloor();
        this.initConteoFloor();
      })
    );

    this.subcriptions.push(
      this.realtimeService.floorChanged.subscribe((floor) => {
        if (floor) {
          this.svgProperties.offsetX = -floor.mapOffsetX;
          this.svgProperties.offsetY = -floor.mapOffsetY;
          this.svgProperties.scale = floor.scale;
          this.initEsiteFloor();
          this.initConteoFloor();
          this.currentFloor = floor;
          if (this.hasConteoModule) {
            this.updateConteoData(floor, this.currentBuilding);
          }
        }
      })
    );

    this.subcriptions.push(
      combineLatest([this.realtimeService.floorChanged, this.realtimeService.conteoBuildingChanged]).subscribe(
        (msg) => {
          if (msg[0]) {
            this.svgProperties.offsetX = -msg[0].mapOffsetX;
            this.svgProperties.offsetY = -msg[0].mapOffsetY;
            this.svgProperties.scale = msg[0].scale;
            this.initConteoFloor();
            this.currentFloor = msg[0];
            if (this.hasConteoModule) {
              this.updateConteoData(msg[0], this.currentBuilding);
            }
          }
        }
      )
    );

    // Esite Data
    this.subcriptions.push(
      combineLatest([this.realtimeService.floorChanged, this.realtimeService.esiteBuildingChanged]).subscribe((msg) => {
        if (msg[0] && msg[1]) {
          if (this.hasEsiteModule || this.hasReservationsModule) {
            this.updateEsiteData(msg[0]);
          }
        }
      })
    );
    this.subcriptions.push(
      combineLatest([this.realtimeService.floorChanged, this.realtimeService.esiteChartsUpdated]).subscribe((msg) => {
        if (msg[0] && msg[1]) {
          if (this.hasEsiteDevicesModule || this.hasConteoModule) {
            if (msg[0].id == this.currentFloor.id) {
              this.updateCharts(msg[0]);
            }
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  initEsiteFloor(): void {
    this.currentFloor = new Floor('');
    this.roomsChart.data = null;
    this.sitesChart.data = null;
    this.areasChart.data = null;
    this.svgDataMemory.sites = [];
    this.svgDataMemory.areas = [];
  }

  initConteoFloor(): void {
    this.zonesChart.data = null;
    this.svgDataMemory.zones = [];
  }

  updateEsiteData(floor: Floor): void {
    this.legendItems = [];
    this.svgDataMemory.areas = [];
    floor.areas.forEach((area: Area) => {
      const polygon = new SvgmapPolygon(area);
      polygon.typeName = this.translateService.instant('Area');
      polygon.building = this.currentBuilding.name;
      polygon.floor = floor.name;
      this.svgDataMemory.areas.push(polygon);
    });
    const realtimeData = this.realtimeService.getRealTimeDataFromSpaces(floor.name, floor.spaces, floor.reservations);

    this.svgDataMemory.sites = realtimeData.points;
    if (this.hasEsiteDevicesModule) {
      this.legendItems = this.realtimeService.createLegendItems();
    }

    if (this.hasReservationsModule) {
      this.legendItems.push({ color: '#ffa900', name: 'Reserved', ring: true });
      this.legendItems.push({ color: '#8f9bb3', name: 'NotReserved', ring: true });
    } // SVG Data
    this.updateLayers();
    this.updateCharts(floor);
  }

  updateCharts(floor): void {
    const spacesState: SpacesState = this.realtimeService.getRealTimeDataFromSpaces(
      floor.name,
      floor.spaces,
      floor.reservations
    ).groupSpacesState;
    // Donut Charts
    this.roomsChart.data = this.realtimeService.getDonutChartData(spacesState.totalRooms, spacesState.roomStates);
    this.sitesChart.data = this.realtimeService.getDonutChartData(spacesState.totalSites, spacesState.siteStates);
    // Bar Charts
    this.updateAreasBarChart(floor);
  }

  updateConteoData(floor: Floor, building: Building): void {
    this.svgDataMemory.zones = [];
    this.zones = building.floorZonesState?.get(floor.id);
    if (!this.zones) {
      this.zones = [];
    }
    this.zones.forEach((zone: Zone) => {
      const polygon = new SvgmapPolygon(zone);
      polygon.typeName = this.translateService.instant('Zone');
      polygon.building = building.name;
      polygon.floor = floor.name;
      polygon.occupation = zone.occupation + '/' + zone.maxOccupation + ' (' + zone.getPercentage() + '%)';
      this.svgDataMemory.zones.push(polygon);
    });
    this.updateLayers();
    this.updateZonesBarChart(this.zones);
  }

  updateLayers(): void {
    this.svgDataToSend = new SvgmapData();
    this.svgDataToSend.img = this.currentFloor.img;
    if (this.sitesCheck && !this.svgDataToSend.points.length) {
      if (this.spaceToFind === '') {
        this.svgDataToSend.points = this.svgDataMemory.sites;
        this.resourcesOnPoint();
      } else {
        this.applyFilter();
      }
    }

    if (this.areasCheck) {
      this.svgDataToSend.polygons = this.svgDataToSend.polygons.concat(this.svgDataMemory.areas);
    }
    if (this.zonesCheck) {
      this.svgDataToSend.polygons = this.svgDataToSend.polygons.concat(this.svgDataMemory.zones);
    }
  }

  updateAreasBarChart(floor: Floor): void {
    const dataAreas = [];
    const areasOccupation = this.getAreasTotalOccupation(floor);
    floor.areas.forEach((area: Area) => {
      const areaStatus = areasOccupation.get(area.id);
      if (areaStatus) {
        const charData = new ChartOptionData();
        charData.title = area.name;
        charData.subtitle = area.name;
        charData.color = this.colors.areasChart;
        const totalSpaces = areaStatus.totalRooms + areaStatus.totalSites;
        const totalOccupiedSpaces = areaStatus.occupiedRooms + areaStatus.occupiedSites;
        charData.value = totalOccupiedSpaces;
        charData.total = totalSpaces;
        charData.inlegend = true;
        dataAreas.push(charData);
      }
    });
    this.areasChart.data = dataAreas;
  }

  getAreasTotalOccupation(floor: Floor): Map<number, SpacesOccupationData> {
    const totalByArea = new Map<number, SpacesOccupationData>();
    floor.areasSpacesState.groupStateMap.forEach((value: SpacesState, key: number) => {
      const areaoccupation = new SpacesOccupationData();
      value.roomStates.forEach((total: number, type: AvailabilityType) => {
        areaoccupation.totalRooms += total;
        if (type === AvailabilityType.Absent || type === AvailabilityType.NotAvailable) {
          areaoccupation.occupiedRooms += total;
        }
      });
      value.siteStates.forEach((total: number, type: AvailabilityType) => {
        areaoccupation.totalSites += total;
        if (type === AvailabilityType.Absent || type === AvailabilityType.NotAvailable) {
          areaoccupation.occupiedSites += total;
        }
      });
      totalByArea.set(key, areaoccupation);
    });
    return totalByArea;
  }

  updateZonesBarChart(zones: Zone[]): void {
    const dataZones = [];
    zones.forEach((zone: Zone) => {
      const charData = new ChartOptionData();
      charData.title = zone.name;
      charData.subtitle = zone.name;
      charData.color = this.colors.zonesChart;
      charData.value = zone.occupation;
      charData.total = zone.maxOccupation;
      charData.inlegend = true;
      dataZones.push(charData);
    });
    this.zonesChart.data = dataZones;
  }

  collapseAction(state): void {
    this.floorDataExpanded = state;
  }

  applyFilter(): void {
    if (this.spaceToFind) {
      this.svgDataToSend.points = this.svgDataMemory.sites.filter((item) =>
        item.name.toLowerCase().includes(this.spaceToFind.toLowerCase())
      );
    } else {
      this.svgDataToSend.points = this.svgDataMemory.sites;
    }
    this.resourcesOnPoint();
  }

  clearSearch(): void {
    this.spaceToFind = '';
    this.svgDataToSend.points = this.svgDataMemory.sites;
    this.resourcesOnPoint();
  }

  resourcesOnPoint(): void {
    this.svgDataToSend.pointsXY = new Map();
    const pointGroup = [];
    from(this.svgDataToSend.points.filter((item) => item).filter((item) => item.positionX && item.positionY))
      .pipe(
        groupBy((point) => point.positionX + '#' + point.positionY),
        mergeMap((group) => zip(of(group.key), group.pipe(toArray()))),
        finalize(() => {
          pointGroup.forEach((pg) => {
            this.svgDataToSend.pointsXY.set(pg[0], pg[1]);
          });
        })
      )
      .subscribe((resp) => pointGroup.push(resp));
  }
}
