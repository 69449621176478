import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { GlobalService } from 'src/app/services/common/global.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent {
  hasReservationModule = false;
  hasConteoModule = false;
  hasEsiteDevicesModule = false;

  constructor(private authService: AuthService, private globalService: GlobalService) {
    this.hasReservationModule = this.globalService.getReservationModule();
    this.hasEsiteDevicesModule = this.globalService.getEsiteDevice();
    this.hasConteoModule = this.globalService.getConteoModule();
  }

  closeSession(): void {
    this.authService.logout();
  }
}
