import { Space } from './Space.class';

export class Area {
  id: number;
  name: string;
  path: string;
  spaces: Space[];

  constructor(msg) {
    this.id = msg.id ? msg.id : 0;
    this.name = msg.name ? msg.name : 0;
    this.path = msg.path ? msg.path : 0;
    this.spaces = [];
  }

  toBackenFormat(floorId): any {
    const backendObject = {
      name: this.name,
      path: this.path,
      floor: floorId,
    };
    return backendObject;
  }
}
