<collapse-card title="{{ 'Data' | translate }} {{ currentBuilding }}">
  <div *ngIf="loading" style="display: flex; align-items: center; justify-content: center; min-height: 500px">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!loading">
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-6 col-lg-4 my-2" *ngIf="building?.floorZonesState && hasConteoModule">
        <app-chart-circleprogress
          [inputChartData]="aforoData"
          [title]="aforoTitle"
          [outerStrokeColor]="'#ffd100'"
          [innerStrokeColor]="'#fffae5'"
        >
        </app-chart-circleprogress>
      </div>
      <div class="col-0 col-md-5 d-lg-none" *ngIf="building?.floorZonesState && hasConteoModule"></div>
      <div
        class="col-12 col-sm-8 col-md-5 col-lg-4 my-2"
        *ngIf="building?.floorSpacesState && hasEsiteModule && hasEsiteDevicesModule"
      >
        <app-chart-donut [chartIdentifier]="roomsChart" [inputChartData]="roomsChartData"> </app-chart-donut>
      </div>
      <div
        class="col-0 col-md-1 d-lg-none"
        *ngIf="building?.floorSpacesState && hasEsiteModule && hasEsiteDevicesModule"
      ></div>
      <div
        *ngIf="building?.floorSpacesState && hasEsiteModule && hasEsiteDevicesModule"
        class="col-12 col-sm-8 col-md-5 col-lg-4 my-2"
      >
        <app-chart-donut [chartIdentifier]="sitesChart" [inputChartData]="sitesChartData"> </app-chart-donut>
      </div>
    </div>
  </div>

  <div *ngIf="!loading && (hasConteoModule || hasEsiteDevicesModule)">
    <div class="row" style="margin-top: 20px; margin-bottom: 5px">
      <div class="col-3 col-md-2 titleN3">
        {{ 'Floor' | translate }}
      </div>
      <div *ngIf="building?.floorZonesState && hasConteoModule" class="col-3 col-md-6 titleN3">
        {{ 'Capacity' | translate }}
      </div>
      <div *ngIf="hasEsiteModule && hasEsiteDevicesModule" class="col-3 col-md-2 titleN3">
        {{ 'Rooms' | translate }}
      </div>
      <div *ngIf="hasEsiteModule && hasEsiteDevicesModule" class="col-3 col-md-2 titleN3">
        {{ 'Sites' | translate }}
      </div>
    </div>
    <mat-divider style="margin-bottom: 10px"> </mat-divider>

    <div class="row my-1" *ngFor="let item of building?.floors">
      <div class="col-3 col-md-2 tableText" style="height: 40px; display: flex; align-items: center">
        {{ item.name }}
      </div>
      <div
        *ngIf="building?.floorZonesState && hasConteoModule"
        class="col-3 col-md-6 tableText"
        style="height: 40px; display: flex; align-items: center"
      >
        <span style="margin-left: 2px; width: 100px">
          {{
            totalFloorZoneOccupation?.get(item.id)?.occupation +
              '/' +
              totalFloorZoneOccupation?.get(item.id)?.maxOccupation
          }}
        </span>
        <div class="d-none d-md-flex flex-grow-1" style="align-items: center">
          <span style="margin-left: 2px; width: 80px" class="align-middle">
            {{ getConteoPercentage(item.id) | number: '1.0-2' }}%
          </span>

          <div class="progress w-100">
            <div
              class="progress-bar bg-danger"
              role="progressbar"
              [style.width]="getConteoPercentage(item.id) + '%'"
              [aria-valuenow]="getConteoPercentage(item.id)"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </div>
      <div
        *ngIf="totalFloorEsiteSpaces && hasEsiteModule && hasEsiteDevicesModule"
        class="col-3 col-md-2 tableText"
        style="height: 40px; display: flex; align-items: center"
      >
        {{ totalFloorEsiteSpaces?.get(item.id)?.occupiedRooms }}/{{
          totalFloorEsiteSpaces?.get(item.id)?.totalRooms
        }}
      </div>
      <div
        *ngIf="totalFloorEsiteSpaces && hasEsiteModule && hasEsiteDevicesModule"
        class="col-3 col-md-2 tableText"
        style="height: 40px; display: flex; align-items: center"
      >
        {{ totalFloorEsiteSpaces?.get(item.id)?.occupiedSites }}/{{
          totalFloorEsiteSpaces?.get(item.id)?.totalSites
        }}
      </div>
    </div>
  </div>
  <div
    *ngIf="loading || reservationLoading"
    style="display: flex; align-items: center; justify-content: center; min-height: 250px"
  >
    <app-loading></app-loading>
  </div>
  <div [hidden]="loading || reservationLoading" *ngIf="hasReservationsModule">
    <div
      class="row justify-content-center"
      [ngStyle]="{ 'padding-top': hasEsiteDevicesModule || hasConteoModule ? '30px' : '0px' }"
    >
      <div class="col-12 col-sm-8 col-md-6 col-lg-4">
        <app-chart-circleprogress
          [inputChartData]="reservationData"
          [title]="reservationTitle"
          [outerStrokeColor]="'#af006e'"
          [innerStrokeColor]="'#af006e2e'"
        >
        </app-chart-circleprogress>
      </div>
      <div class="col-0 col-md-6 d-lg-none"></div>
      <div class="col-12 col-lg-8">
        <div class="row" style="margin-top: 20px; margin-bottom: 5px">
          <div class="col-6 col-md-3 titleN3">
            {{ 'Floor' | translate }}
          </div>
          <div class="col-6 col-md-9 titleN3">
            {{ 'Reserved' | translate }}
          </div>
        </div>
        <mat-divider style="margin-bottom: 10px"></mat-divider>
        <div class="container p-0">
          <div class="row" *ngFor="let item of reservableSpacesByFloor">
            <div class="col-6 col-md-3 tableText" style="height: 40px; display: flex; align-items: center">
              {{ item.floor.name }}
            </div>
            <div class="col-6 col-md-9 tableText" style="height: 40px; display: flex; align-items: center">
              <span style="margin-left: 2px; width: 100px">
                {{ item.reservatedSpaces + '/' + item.reservableSpaces }}
              </span>
              <span *ngIf="item.percent" style="margin-left: 2px; width: 80px">
                {{ item.percent | number: '1.0-2' }}%
              </span>
              <span *ngIf="!item.percent" style="margin-left: 2px; width: 80px"> 0% </span>
              <div class="progress w-100">
                <div
                  class="progress-bar bg-danger"
                  role="progressbar"
                  [style.width]="item.percent + '%'"
                  [aria-valuenow]="item.percent"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</collapse-card>
