export enum ReservationStatus {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  PendingApproval = 'PendingApproval',
  Checked = 'Checked',
  InProgress = 'InProgress',
  Finished = 'Finished',
  Reserved = 'Reserved',
  NotReserved = 'NotReserved',
}

export enum continuousReservationStatus {
  Approved = 'Approved',
  Cancelled = 'Cancelled',
  Finished = 'Finished',
}
