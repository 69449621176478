import { Component, Input, SimpleChanges } from '@angular/core';
import { ProgressData } from 'src/app/models/charts/ProgressData.class';

@Component({
  selector: 'app-chart-circleprogress',
  templateUrl: './chart-circleprogress.component.html',
  styles: [],
})
export class ChartCircleprogressComponent {
  loading = true;
  @Input() inputChartData: ProgressData;
  @Input() outerStrokeColor;
  @Input() innerStrokeColor;
  @Input() title;
  percentage = 0;
  subtitleString = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputChartData) {
      const data = changes.inputChartData.currentValue;
      if (data) {
        this.percentage = data.getPercentage();
        this.subtitleString = data.value + '/' + data.total;
        this.loading = false;
      } else {
        this.loading = true;
      }
    }
  }
}
