<div class="container">
  <div class="w-75 mx-auto alert alert-danger mt-5">
    <div class="titleN2 mb-3">{{ 'Log-in error' | translate }}</div>

    <div class="text-center">
      <p *ngIf="!forbidden">{{ 'There was an error in the log-in process. Please try again' | translate }}</p>
      <div *ngIf="forbidden">
        <p>
          {{
            'You do not have access to the Habitat management website. If this is an error, contact an administrator.'
              | translate
          }}
        </p>
        <p *ngIf="hasReservationWeb">
          {{ 'Perhaps what you were looking for is the Habitat reservations portal:' | translate }}
        </p>
      </div>
      <div class="text-center">
        <button *ngIf="!forbidden" mat-button class="modalButton bg-primary" (click)="login()">
          {{ 'Log-in' | translate }}
        </button>
        <button
          *ngIf="forbidden && hasReservationWeb"
          mat-button
          class="modalButton bg-primary"
          (click)="goToReservations()"
        >
          {{ 'Habitat Reservations' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
