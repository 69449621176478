<mat-card>
  <mat-card-content>
    <div *ngIf="loading" style="min-height: 240px; display: flex; align-items: center; justify-content: center">
      <app-loading></app-loading>
    </div>
    <div
      *ngIf="!loading && noData && !instructions"
      style="min-height: 220px; display: flex; align-items: center; justify-content: center"
    >
      {{ 'No results!' | translate }}
    </div>
    <div
      *ngIf="!loading && instructions"
      style="min-height: 220px; display: flex; align-items: center; justify-content: center"
    >
      {{ 'Select parameters' | translate }}
    </div>
    <div [hidden]="loading || noData">
      <div class="d-flex justify-content-between my-3">
        <button
          mat-button
          color="primary"
          (click)="exportAsExcel()"
          title="{{ 'ExcelExport' | translate }}"
          [disabled]="downloadingExcel"
        >
          <span class="bi bi-cloud-download"></span>
          <span class="px-2">{{ (responsive ? 'ExcelExportResponsive' : 'ExcelExport' ) | translate }}</span>
          <span class="loader" *ngIf="downloadingExcel"></span>
        </button>
      </div>
      <div style="margin: -5px">
        <table
          *ngIf="responsive"
          mat-table
          [dataSource]="dataSource"
          style="width: 100%"
          matSort
          (matSortChange)="sortData($event)"
        >
          <caption></caption>
          <th id="continuousReservationsTable"></th>

          <ng-container #user matColumnDef="user">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              class="none text-primary"
              style="flex: 0.7; visibility: hidden; display: none"
              >{{ 'User' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let reservasData" style="flex: 0.7">
              {{ 'User' | translate }} :
              <span style="font-weight: bold">
                <span *ngIf="reservasData.reservationusersName" class="none shortText">
                  {{ reservasData.reservationusersName }}
                </span>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container #reservablespacesidMobile matColumnDef="spaceCode">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              class="none text-primary"
              style="flex: 0.7; visibility: hidden; display: none"
              >{{ 'Code' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let reservasData" style="flex: 0.7">
              {{ 'Code' | translate }} :
              <span style="font-weight: bold">
                <span *ngIf="reservasData.reservablespacesid" class="none shortText">
                  {{ reservasData.spaceCode }}
                </span>
              </span>
            </mat-cell>
          </ng-container>
          <ng-container #locationMobile matColumnDef="location">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              class="none text-primary"
              style="flex: 0.7; visibility: hidden; display: none"
              >{{ 'Location' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let reservasData" style="flex: 0.7">
              {{ 'Location' | translate }} :
              <span style="font-weight: bold">
                <span *ngIf="reservasData.location" class="none shortText">
                  {{ reservasData.location }}
                </span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container #startDateMobile matColumnDef="startDate">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="none text-primary"
              style="flex: 1.2; visibility: hidden; display: none"
            >
              {{ 'Start Date' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let reservasData" style="flex: 1.2">
              <div class="none shortText">
                {{ 'Start Date' | translate }} :
                {{ reservasData.startDate | date: 'd/M/yyyy' }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container #endDateMobile matColumnDef="endDate">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="none text-primary"
              style="flex: 1.2; visibility: hidden; display: none"
              >{{ 'End Date' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let reservasData" style="flex: 1.2">
              {{ 'End Date' | translate }} :
              <span style="font-weight: bold">
                <span *ngIf="reservasData.endDate" class="none shortText">
                  {{ reservasData.endDate | date: 'd/M/yyyy' }}
                </span>
              </span>
            </mat-cell>
          </ng-container>

          <ng-container #startTimeMobile matColumnDef="startTime">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="none text-primary"
              style="flex: 1.2; visibility: hidden; display: none"
            >
              {{ 'Start Time' | translate }}</mat-header-cell
            >

            <mat-cell *matCellDef="let reservasData" style="flex: 1.2">
              <div class="none shortText">
                {{ 'Start Time' | translate }} :
                {{ reservasData.startTime }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container #endTimeMobile matColumnDef="endTime">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="none text-primary"
              style="flex: 1.2; visibility: hidden; display: none"
            >
              {{ 'End Time' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let reservasData" style="flex: 1.2">
              <div class="none shortText">{{ 'End Time' | translate }} : {{ reservasData.endTime }}</div>
            </mat-cell>
          </ng-container>

          <ng-container #actionsMobile matColumnDef="actions">
            <mat-header-cell
              *matHeaderCellDef
              class="none text-primary"
              style="flex: 0.6; visibility: hidden; display: none"
            >
              {{ 'Actions' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let reservasData" style="flex: 0.6;" class="d-flex gap-2 justify-content-center">
              <span
                *ngIf="reservasData.iconEdit  && reservasData.status === 'Approved'"
                [title]="'Edit continuous reservation' | translate"
                class="smallIcon bi bi-pencil"
                role="button"
                (click)="editContinuousReservations(reservasData)"
              ></span>
              <span
                *ngIf="reservasData.iconCross && canWriteReservations && reservasData.status === 'Approved'"
                [title]="'Cancel continuous reservation' | translate"
                class="smallIcon bi bi-x-circle"
                role="button"
                (click)="finishContinuousReservations(reservasData)"
              ></span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
        </table>
      </div>

      <table
        *ngIf="!responsive"
        mat-table
        [dataSource]="dataSource"
        style="width: 100%"
        matSort
        (matSortChange)="sortData($event)"
      >
        <caption></caption>
        <th id="continuousReservationsTable"></th>

        <ng-container #reservablespacesid matColumnDef="spaceCode" >
          <mat-header-cell *matHeaderCellDef mat-sort-header class="none text-primary" style="flex: 2"
            >{{ 'Code' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let reservasData" style="flex: 2">
            <span *ngIf="reservasData.reservablespacesid">
              {{ reservasData.spaceCode }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container #status matColumnDef="status">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="none text-primary" style="flex: 2"
            >{{ 'Status' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let reservasData" style="flex: 2">
            <span *ngIf="reservasData.status">
              {{ reservasData.status | translate }}
            </span>
          </mat-cell>
        </ng-container>
        <ng-container #user matColumnDef="user">
          <mat-header-cell *matHeaderCellDef  class="none text-primary" style="flex: 2"
            >{{ 'User' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let reservasData" style="flex: 2">
            <span *ngIf="reservasData.reservationusersName">
              {{ reservasData.reservationusersName }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container #location matColumnDef="location">
          <mat-header-cell *matHeaderCellDef  class="none text-primary" style="flex: 2">
            {{ 'Location' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let reservasData" style="flex: 2">
            {{ reservasData.location }}
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="startDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="none text-primary" style="flex: 1.7">
            {{ 'Start Date' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let reservasData" style="flex: 1.7">
            {{ reservasData.startDate | date: 'd/M/yyyy' }}
          </mat-cell>
        </ng-container>

        <ng-container #endDate matColumnDef="endDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="none text-primary" style="flex: 1.7">
            {{ 'End Date' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let reservasData" style="flex: 1.7">
            <span *ngIf="reservasData.endDate">
              {{ reservasData.endDate | date: 'd/M/yyyy' }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container #startTime matColumnDef="startTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="none text-primary" style="flex: 1">
            {{ 'Start Time' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let reservasData" style="flex: 1">{{ reservasData.startTime }} </mat-cell>
        </ng-container>

        <ng-container #endTime matColumnDef="endTime">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="none text-primary" style="flex: 1">
            {{ 'End Time' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let reservasData" style="flex: 1">
            {{ reservasData.endTime }}
          </mat-cell>
        </ng-container>

        <ng-container #actions matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="none text-primary" style="flex: 1">
            {{ 'Actions' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let reservasData" style="flex: 1; justify-content: center">
            <div class="d-flex gap-2">
              <span
                *ngIf="reservasData.iconEdit && reservasData.status === 'Approved'"
                [title]="'Edit continuous reservation' | translate"
                class="smallIcon bi bi-pencil"
                role="button"
                (click)="editContinuousReservations(reservasData)"
              ></span>
              <span
                *ngIf="reservasData.iconCross && canWriteReservations && reservasData.status === 'Approved'"
                [title]="'Cancel continuous reservation' | translate"
                class="smallIcon bi bi-x-circle"
                role="button"
                (click)="finishContinuousReservations(reservasData)"
              ></span>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
      </table>
      <div class="none line"></div>
      <div class="d-flex justify-content-end p-1">
        <span style="font-size: 12px; padding-right: 20px; color: #525252"
          >{{ initPaginatorText }}&nbsp;-&nbsp;{{ endPaginatorText }}&nbsp;{{ 'of' | translate }}&nbsp;{{
            totalReservasData
          }}</span
        >
        <span style="display: inline-flex">
          <button
            title="{{ 'Previous page' | translate }}"
            (click)="goPreviousPage()"
            style="background: transparent; border: none; width: 40px; height: 40px; margin-right: 0px"
            [hidden]="pageIndex == 1"
          >
            <svg viewBox="0 0 24 24" focusable="false" class="none mat-paginator-icon">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
          </button>
          <button
            title="{{ 'Next page' | translate }}"
            *ngIf="haveNextPage"
            (click)="goNextPage()"
            style="background: transparent; border: none; width: 40px; height: 40px"
          >
            <svg viewBox="0 0 24 24" focusable="false" class="none mat-paginator-icon">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            </svg>
          </button>
        </span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
