export enum FilterOptionType {
  floors = 'floors',
  areas = 'areas',
  sites = 'sites',
  rooms = 'rooms',
  spaces = 'spaces',
  zones = 'zones',
  heatmap = 'heatmap',
  reservation = 'reservation',
}
