<mat-card class="card-container-parent">
  <mat-card-content class="marginBodyCard card-container p-0">
    <div *ngIf="loading" class="d-flex flex-column w-100" style="min-height: 250px">
      <div class="d-flex justify-content-end">
        <button (click)="cancel()" type="button" class="btn-close shadow-none">
          <span aria-label="Close">&times;</span>
        </button>
      </div>
      <div class="d-flex flex-grow-1">
        <app-loading class="m-auto"></app-loading>
      </div>
    </div>
    <div [hidden]="loading" class="w-100 card-form-container">
      <div class="titleN3 text-primary modalTitle">{{ modalTitle | translate }}</div>

      <form class="row p-3" [formGroup]="spaceFormGroup" (ngSubmit)="checkSpace()">
        <div class="row">
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'Code' | translate }}</mat-label>
              <input
                title="{{ 'Code' | translate }}"
                matInput
                autocomplete="off"
                formControlName="code"
                type="text"
                class="p-1"
                maxlength="45"
                required
                spellcheck="false"
              />
              <mat-hint *ngIf="spaceFormGroup.get('code')?.value.length" align="end"
                >{{ spaceFormGroup.get('code').value.length }}/45</mat-hint
              >
              <mat-error *ngIf="f.code.errors?.required">{{ 'Code is required' | translate }}</mat-error>
              <mat-error *ngIf="f.code.errors?.duplicated">{{ 'Duplicated space code' | translate }}</mat-error>
              <mat-error *ngIf="f.code.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field>
              <mat-label>{{ 'Name' | translate }}</mat-label>
              <input
                title="{{ 'Name' | translate }}"
                matInput
                autocomplete="off"
                formControlName="name"
                type="text"
                class="p-1"
                maxlength="60"
                spellcheck="false"
                required
              />
              <mat-hint *ngIf="spaceFormGroup.get('name')?.value.length" align="end"
                >{{ spaceFormGroup.get('name').value.length }}/60</mat-hint
              >
              <mat-error *ngIf="f.name.errors?.required">{{ 'Name is required' | translate }}</mat-error>
              <mat-error *ngIf="f.name.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-12">
            <mat-form-field>
              <mat-label>{{ 'Description' | translate }}</mat-label>
              <input
                title="{{ 'Description' | translate }}"
                matInput
                autocomplete="off"
                formControlName="description"
                type="text"
                class="p-1"
                maxlength="100"
                spellcheck="false"
                required
              />
              <mat-hint *ngIf="spaceFormGroup.get('description')?.value.length" align="end"
                >{{ spaceFormGroup.get('description').value.length }}/100</mat-hint
              >
              <mat-error *ngIf="f.description.errors?.required">{{ 'Description is required' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row pt-3">
          <div class="col-4 d-flex align-items-center" *ngIf="canReadResources">
            <div class="resources-form-field d-block w-100">
              <span class="p-float-label">
                <p-multiSelect
                  [options]="resources"
                  inputId="ms-resources"
                  [showToggleAll]="true"
                  formControlName="resources"
                  optionLabel="name"
                  optionValue="id"
                  defaultLabel="{{ 'Resources' | translate }}"
                  title="{{ 'Resources' | translate }}"
                  [virtualScroll]="true"
                  [filter]="true"
                  [virtualScrollItemSize]="30"
                  class="multiselect-custom-virtual-scroll"
                  dropdownIcon="mat-select-arrow"
                  [ngClass]="{ 'ms-selected': spaceFormGroup.value.resources.length > 0 }"
                  (onPanelShow)="selectingResources = true"
                  (onPanelHide)="selectingResources = false"
                  (onFocus)="selectFocus = true"
                  (onBlur)="selectFocus = false"
                  [readonly]="!canWriteResources"
                >
                  <ng-template let-resource pTemplate="item">
                    <div class="d-flex align-items-center gap-2">
                      <div *ngIf="resource.type.icon">
                        <span class="bi-{{ resource.type.icon }}"></span>
                      </div>
                      <div>{{ resource.name }}</div>
                    </div>
                  </ng-template>
                </p-multiSelect>
                <label
                  for="ms-resources"
                  class="ms-label"
                  [ngClass]="{
                    'ms-selected': spaceFormGroup.value.resources.length > 0,
                    'ms-selecting': selectingResources,
                    'ms-focus': selectFocus
                  }"
                >
                  {{ 'Resources' | translate }}
                </label>
              </span>
            </div>
          </div>
          <div class="col">
            <mat-form-field>
              <mat-label>{{ 'Use type' | translate }}</mat-label>
              <mat-select [(value)]="selectedUseType"  formControlName="useType" class="p-1 input-form-special" required (selectionChange)="checkTypeFields($event, 'useType')">
                <mat-option *ngFor="let useType of useTypes" [value]="useType">
                  {{ useType | translate }}
                </mat-option>

              </mat-select>
              <mat-error *ngIf="f.useType.errors?.required">{{ 'Use type is required' | translate }}</mat-error>
              <span
                *ngIf="selectedUseType !== null"
                [title]="'Clear selection' | translate"
                class="btn-clear-select close-circle-outline bi bi-x-circle"
                (click)="clearField('useType')"
              ></span>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field>
              <mat-label>{{ 'Type' | translate }}</mat-label>
              <mat-select [(value)]="selectedType" formControlName="type" class="p-1 input-form-special" required (selectionChange)="checkTypeFields($event, 'type')">
                <mat-option *ngFor="let type of typesForSelect" [value]="type.id">
                  {{ type.name | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f.type.errors?.required">{{ 'Type is required' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col-6 col-sm-4 d-flex align-items-center">
            <mat-form-field>
              <mat-label>{{ 'Subsection type' | translate }}</mat-label>
              <mat-select
                formControlName="subsectionType1"
                class="p-1 input-form-special"
                (selectionChange)="checkSubsectionsSelects(1)"
              >
                <mat-option *ngFor="let type of subsectionTypes" [value]="type.id">
                  {{ type.name }}
                </mat-option>
              </mat-select>
              <span
                *ngIf="spaceFormGroup.value.subsectionType1"
                [title]="'Clear selection' | translate"
                class="btn-clear-select close-circle-outline bi bi-x-circle"
                (click)="clearField('subsectionType1')"
              ></span>
            </mat-form-field>
          </div>
          <div class="col-6 col-sm-4 d-flex align-items-center">
            <mat-form-field>
              <mat-label>{{ 'Subsection type' | translate }}</mat-label>
              <mat-select
                formControlName="subsectionType2"
                class="p-1 input-form-special"
                (selectionChange)="checkSubsectionsSelects(2)"
              >
                <mat-option *ngFor="let type of subsectionTypes" [value]="type.id">
                  {{ type.name }}
                </mat-option>
              </mat-select>
              <span
                *ngIf="spaceFormGroup.value.subsectionType2"
                [title]="'Clear selection' | translate"
                class="btn-clear-select close-circle-outline bi bi-x-circle"
                (click)="clearField('subsectionType2')"
              ></span>
            </mat-form-field>
          </div>
          <div class="col-6 col-sm-4 d-flex align-items-center">
            <mat-form-field>
              <mat-label>{{ 'Subsection type' | translate }}</mat-label>
              <mat-select
                formControlName="subsectionType3"
                class="p-1 input-form-special"
                (selectionChange)="checkSubsectionsSelects(3)"
              >
                <mat-option *ngFor="let type of subsectionTypes" [value]="type.id">
                  {{ type.name }}
                </mat-option>
              </mat-select>
              <span
                *ngIf="spaceFormGroup.value.subsectionType3"
                [title]="'Clear selection' | translate"
                class="btn-clear-select close-circle-outline bi bi-x-circle"
                (click)="clearField('subsectionType3')"
              ></span>
            </mat-form-field>
          </div>
        </div>

        <div class="row pt-3">
          <div class="col-6 col-sm-4 d-flex align-items-center">
            <mat-form-field>
              <mat-label>{{ 'Subsection' | translate }}</mat-label>
              <mat-select
                id="subsection"
                formControlName="subsection1"
                class="p-1 input-form-special"
                ngxMatSelect
                #ms="ngxMatSelect"
                [searcher]="searcherSubsection1"
                [displayMember]="'name'"
                [valueMember]="'id'"
                [useInfiniteScroll]="true"
                [lazyLoad]="true"
                [source]="subsections1"
                searchBoxPlaceholder="{{ 'Search' | translate }}"
                emptyLabel="{{ 'No subsections found' | translate }}"
                noMoreResultLabel="{{ 'No more found' | translate }}"
                [hasSearchBox]="true"
              >
                <mat-option *ngFor="let subsection of ms.filteredSource" [value]="subsection.id">
                  {{ subsection.name | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f.subsection1.errors && !invalidSubsection">{{
                'Subsection is required' | translate
              }}</mat-error>
              <span
                *ngIf="spaceFormGroup.value.subsection1"
                [title]="'Clear selection' | translate"
                class="btn-clear-select close-circle-outline bi bi-x-circle"
                (click)="clearField('subsection1')"
              ></span>
            </mat-form-field>
          </div>
          <div class="col-6 col-sm-4 d-flex align-items-center">
            <mat-form-field>
              <mat-label>{{ 'Subsection' | translate }}</mat-label>
              <mat-select
                id="subsection"
                formControlName="subsection2"
                class="p-1 input-form-special"
                ngxMatSelect
                #sf="ngxMatSelect"
                [searcher]="searcherSubsection2"
                [displayMember]="'name'"
                [valueMember]="'id'"
                [useInfiniteScroll]="true"
                [source]="subsections2"
                [lazyLoad]="true"
                searchBoxPlaceholder="{{ 'Search' | translate }}"
                emptyLabel="{{ 'No subsections found' | translate }}"
                noMoreResultLabel="{{ 'No more found' | translate }}"
                [hasSearchBox]="true"
              >
                <mat-option *ngFor="let subsection of sf.filteredSource" [value]="subsection.id">
                  {{ subsection.name | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f.subsection2.errors && !invalidSubsection">{{
                'Subsection is required' | translate
              }}</mat-error>
              <span
                *ngIf="spaceFormGroup.value.subsection2"
                [title]="'Clear selection' | translate"
                class="btn-clear-select close-circle-outline bi bi-x-circle"
                (click)="clearField('subsection2')"
              ></span>
            </mat-form-field>
          </div>
          <div class="col-6 col-sm-4 d-flex align-items-center">
            <mat-form-field>
              <mat-label>{{ 'Subsection' | translate }}</mat-label>
              <mat-select
                id="subsection"
                formControlName="subsection3"
                class="p-1 input-form-special"
                ngxMatSelect
                #mf="ngxMatSelect"
                [searcher]="searcherSubsection3"
                [displayMember]="'name'"
                [valueMember]="'id'"
                [useInfiniteScroll]="true"
                [source]="subsections3"
                [lazyLoad]="true"
                searchBoxPlaceholder="{{ 'Search' | translate }}"
                emptyLabel="{{ 'No subsections found' | translate }}"
                noMoreResultLabel="{{ 'No more found' | translate }}"
                [hasSearchBox]="true"
              >
                <mat-option *ngFor="let subsection of mf.filteredSource" [value]="subsection.id">
                  {{ subsection.name | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="f.subsection3.errors && !invalidSubsection">{{
                'Subsection is required' | translate
              }}</mat-error>
              <span
                *ngIf="spaceFormGroup.value.subsection3"
                [title]="'Clear selection' | translate"
                class="btn-clear-select close-circle-outline bi bi-x-circle"
                (click)="clearField('subsection3')"
              ></span>
            </mat-form-field>
          </div>
        </div>

        <div
          *ngIf="submitted && invalidSubsection"
          class="invalid-feedback"
          style="padding-left: 17px; place-content: center; margin-top: -20px; padding-bottom: 10px"
        >
          <div>{{ 'invalidSubsectionError' | translate }}</div>
        </div>
        <div class="row pt-3">
          <div class="col-6 col-sm-4">
            <mat-form-field>
              <mat-label>{{ 'spaceCapacity' | translate }}</mat-label>
              <input
                title="{{ 'spaceCapacity' | translate }}"
                matInput
                autocomplete="off"
                formControlName="capacity"
                type="number"
                onkeydown="return event.keyCode !== 69"
                class="py-1"
                min="1"
                max="99999"
                step="1"
                spellcheck="false"
                required
              />
              <mat-error *ngIf="f.capacity.errors?.required">{{ 'Space capacity is required' | translate }}</mat-error>
              <mat-error *ngIf="f.capacity.errors?.min">{{ 'Capacity should be higher than 0' | translate }}</mat-error>
              <mat-error *ngIf="f.capacity.errors?.max">{{
                'Value is too high. Please use a lower number' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-6 col-sm-4">
            <mat-form-field>
              <mat-label>{{ 'Position' | translate }} {{ 'X' | translate }}</mat-label>
              <input
                title="{{ 'Position' | translate }} {{ 'X' | translate }}"
                matInput
                autocomplete="off"
                formControlName="x"
                type="number"
                onkeydown="return event.keyCode !== 69"
                step="0.1"
                class="p-1"
                required
                spellcheck="false"
              />
              <mat-error *ngIf="f.x.errors?.required">{{ 'Position X is required' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-6 col-sm-4">
            <mat-form-field>
              <mat-label>{{ 'Position' | translate }} {{ 'Y' | translate }}</mat-label>
              <input
                title="{{ 'Position' | translate }} {{ 'Y' | translate }}"
                matInput
                autocomplete="off"
                formControlName="y"
                type="number"
                onkeydown="return event.keyCode !== 69"
                step="0.1"
                class="p-1"
                required
                spellcheck="false"
              />
              <mat-error *ngIf="f.y.errors?.required">{{ 'Position Y is required' | translate }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="cancel()" type="button">
            {{ 'Cancel' | translate }}
          </button>
          <button
            *ngIf="canWriteEsiteSpaces"
            mat-button
            class="modalButton bg-primary"
            type="submit"
            [disabled]="!spaceFormGroup.valid || submitted"
          >
            <span>{{ 'Save' | translate }}</span>
            <span class="loader ms-1" *ngIf="submitted"></span>
          </button>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
