export class ResourceType {
  id?: number;
  code: string;
  name: string;
  icon: string;

  constructor(msg?: Partial<ResourceType>) {
    this.id = msg?.id;
    this.code = msg?.code ?? '';
    this.name = msg?.name ?? '';
    this.icon = msg?.icon ?? '';
  }

  static fromBackendFormat(data: APIResourceType): ResourceType {
    return new ResourceType(data);
  }

  toBackendFormat(): APIResourceType {
    return {
      id: this.id,
      code: this.code,
      name: this.name,
      icon: this.icon,
    };
  }

  setExcelData(parsedRow) {
    this.id = parsedRow.Id || parsedRow.id;
    this.code = parsedRow.Código || parsedRow.code;
    this.name = parsedRow.Nombre || parsedRow.name;
    this.icon = parsedRow.Icono || parsedRow.icon;
  }
}

export type APIResourceType = {
  id?: number;
  code: string;
  name: string;
  icon: string;
};
