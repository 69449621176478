<collapse-card title="{{ 'Subsections' | translate }}">
  <div *ngIf="loading" style="min-height: 350px; display: flex; align-items: center; justify-content: center">
    <app-loading></app-loading>
  </div>
  <div [hidden]="loading">
    <div class="d-flex gap-3">
      <mat-form-field class="flex-grow-1">
        <mat-label class="labelSearch">{{ 'Search' | translate }}</mat-label>
        <div class="d-flex">
          <input
            title="{{ 'Search' | translate }}"
            matInput
            class="filter-input py-1"
            (keyup)="applyFilter($event)"
            #input
            type="text"
          />
          <div class="searchIcon">
            <span class="bi bi-search text-primary"></span>
          </div>
        </div>
      </mat-form-field>
      <div *ngIf="canWriteSubsection" class="d-flex justify-content-end align-items-center">
        <button mat-button (click)="createNewSubsection()">
          <span class="smallIcon bi bi-plus-circle"></span>
          <span class="ps-1">{{ 'New subsection' | translate }}</span>
        </button>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" style="width: 100%" matSort #sortData="matSort">
      <caption></caption>
      <th id="subsectionsTable"></th>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3"
          >{{ 'Subsection' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let subsection" style="flex: 1.3">
          <span *ngIf="subsection.name">
            {{ subsection.name }}
          </span>
          <span *ngIf="!subsection.name"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="displayTypeName">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.2">
          {{ 'Subsection type' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let subsection" style="flex: 1.3">
          <span *ngIf="subsection.displayTypeName">
            {{ subsection.displayTypeName }}
          </span>
          <span *ngIf="!subsection.displayTypeName"> -- </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.6">
          {{ 'Actions' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let subsection" style="flex: 0.6">
          <span
            *ngIf="canWriteSubsection"
            class="smallIcon me-2 bi bi-pencil"
            role="button"
            (click)="editSubsection(subsection)"
          ></span>
          <span
            *ngIf="!canWriteSubsection"
            class="smallIcon me-2 bi bi-eye"
            role="button"
            (click)="editSubsection(subsection)"
          ></span>
          <span
            *ngIf="canWriteSubsection"
            class="smallIcon me-2 bi bi-trash"
            role="button"
            (click)="deleteSubsection(subsection)"
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
    </table>
    <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
  </div>
</collapse-card>

<ng-template #newOrEditType>
  <mat-card style="width: 400px">
    <mat-card-content class="marginBodyCard">
      <label class="titleN3 text-primary modalTitle"> {{ modalTitle | translate }}</label>
      <form [formGroup]="subsectionForm" (ngSubmit)="onSubmit(subsectionForm)" style="display: flex; flex-wrap: wrap">
        <mat-form-field style="width: 100%; margin-right: 10px">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input
            title="{{ 'Name' | translate }}"
            matInput
            autocomplete="off"
            formControlName="name"
            type="text"
            class="p-1"
            maxlength="45"
            required
          />
          <mat-hint *ngIf="subsectionForm.get('name')?.value.length" align="end"
            >{{ subsectionForm.get('name').value.length }}/45</mat-hint
          >
          <mat-error *ngIf="submitted && f.name.errors?.required">{{ 'You must enter a name' | translate }}</mat-error>
          <mat-error *ngIf="submitted && f.name.errors?.duplicate">{{
            'There is already another subsection with this name' | translate
          }}</mat-error>
          <mat-error *ngIf="submitted && f.name.errors?.unknown">{{
            'An error happened when creating the subsection' | translate
          }}</mat-error>
          <mat-error *ngIf="f.name.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field style="width: 100%; padding-top: 10px">
          <mat-label> {{ 'Select subsection type' | translate }}</mat-label>
          <mat-select class="p-1" style="padding: 0px; width: 100%" formControlName="type" required>
            <mat-option *ngFor="let type of subsectionTypes" [value]="type.id">
              {{ type.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="submitted && f.type.errors?.required">{{
            'You must enter a subsection type' | translate
          }}</mat-error>
          <mat-error *ngIf="submitted && f.type.errors?.typeConflict">{{
            'ErrorSubsectionTypeConflict' | translate
          }}</mat-error>
        </mat-form-field>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="cancel()" type="button">
            {{ 'Cancel' | translate }}
          </button>
          <button [hidden]="!canWriteSubsection" mat-button class="modalButton bg-primary" type="submit">
            {{ 'Save' | translate }}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</ng-template>
