import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class MatPaginatorIntlLan extends MatPaginatorIntl {
  constructor(private translateService: TranslateService) {
    super();
  }
  itemsPerPageLabel = '';
  nextPageLabel = '';
  previousPageLabel = '';

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    this.itemsPerPageLabel = this.translateService.instant('itemsPerPage');
    this.nextPageLabel = this.translateService.instant('nextPage');
    this.previousPageLabel = this.translateService.instant('previousPage');
    return (
      page * pageSize +
      1 +
      ' - ' +
      (page * pageSize + pageSize) +
      ' ' +
      this.translateService.instant('of') +
      ' ' +
      length
    );
  };
}
