import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, from, fromEvent, Observable, of, Subject, zip } from 'rxjs';
import { finalize, groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import { User } from 'src/app/models/auth/User.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { OccupationNotificationConfig } from 'src/app/models/notifications/OccupationNotificationConfig.class';
import { ProximityNotificationConfig } from 'src/app/models/notifications/ProximityNotificationConfig.class';
import { environment } from 'src/environments/environment';

import { AuditService } from '../backend/audit.service';
import { AuthService } from '../backend/auth.service';
import { HabitatService } from '../backend/habitat.service';
import { UserService } from '../backend/user.service';
import { Permission, PermissionBuilding, Role, UserBuilding } from './../../models/auth/Role.class';
import { City } from './../../models/habitat/City.class';
import { Modules } from './../../models/Modules.enum';
import { StorageVariables } from './../../models/StorageVariables.enum';
import { AppConstants } from '../../shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  private user: User = null;
  userLogged$ = new BehaviorSubject<User>(this.user);
  userBuildingsChanged = new Subject<boolean>();
  userImageChanged = new Subject<boolean>();

  isResponsive$ = new BehaviorSubject<boolean>(false);

  private buildings: Building[] = [];
  userBuildings$ = new BehaviorSubject<Building[]>(this.buildings);

  proximityAlarmConfig: ProximityNotificationConfig[];
  occupationNotifications: OccupationNotificationConfig[];

  private CReservationsFilterParameters: any = {};
  private isCReservationsResultsTableLoading = false;
  CReservationsFilterParameters$ = new BehaviorSubject<any>(this.CReservationsFilterParameters);
  isCReservationsResultsTableLoading$ = new BehaviorSubject(this.isCReservationsResultsTableLoading);

  constructor(
    private authService: AuthService,
    private auditService: AuditService,
    private userService: UserService,
    private habitatService: HabitatService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
    const mobileBreakpoint = 768; // mobile breakpoint
    this.isResponsive$.next(window.innerWidth < mobileBreakpoint);
    fromEvent(window, 'resize').subscribe(() => {
      this.isResponsive$.next(window.innerWidth < mobileBreakpoint);
    });
  }

  emitUserBuildingsChanged(): void {
    this.userBuildingsChanged.next(true);
  }

  getUserInfo(): Observable<User> {
    return this.authService.getUserInfo();
  }

  setCurrentUser(user: User): void {
    this.user = user;
    this.getAndSaveFunctionalities();
    this.userLogged$.next(user);
  }

  setUserBuildings(buildings: Building[]): void {
    this.buildings = buildings;
    this.userBuildings$.next(buildings);
  }

  getCurrentUser(): User {
    return this.user;
  }

  emitUserImageChanged(image): void {
    this.userImageChanged.next(image);
  }

  setProximityNotificationConfig(ProximityNotificationConfig: ProximityNotificationConfig[]): void {
    this.proximityAlarmConfig = ProximityNotificationConfig;
  }

  getProximityNotificationConfig(): ProximityNotificationConfig[] {
    return this.proximityAlarmConfig;
  }

  setOccupationNotifications(occupationNotifications: OccupationNotificationConfig[]): void {
    this.occupationNotifications = occupationNotifications;
  }

  addOccupationNotification(occupationNotifications: OccupationNotificationConfig): void {
    this.occupationNotifications.push(occupationNotifications);
  }

  getOccupationNotifications(): OccupationNotificationConfig[] {
    return this.occupationNotifications;
  }

  getAndSaveFunctionalities(): void {
    if (!JSON.parse(sessionStorage.getItem(StorageVariables.FUNCTIONALITIES))) {
      this.auditService.getFunctionalities().subscribe((fun) => {
        sessionStorage.setItem(StorageVariables.FUNCTIONALITIES, JSON.stringify(fun));
      });
    }
  }

  setCReservationsFilterPArameters(CReservationsFilterParameters: any): void {
    this.CReservationsFilterParameters = CReservationsFilterParameters;
    this.CReservationsFilterParameters$.next(CReservationsFilterParameters);
  }

  isCReservationTableLoading(CReservationsresultsTableLoading: boolean): void {
    this.isCReservationsResultsTableLoading = CReservationsresultsTableLoading;
    this.isCReservationsResultsTableLoading$.next(CReservationsresultsTableLoading);
  }

  buildLoggedUser(idLoggedUser: number): void {
    const loggedUser = new User();
    loggedUser.id = idLoggedUser;
    this.userService.getUser(idLoggedUser).subscribe((userBuilding: UserBuilding) => {
      loggedUser.employeeNumber = userBuilding.userInfo.employeeNumber;
      loggedUser.sso = userBuilding.userInfo.employeeNumber;
      loggedUser.name = userBuilding.userInfo.name;
      loggedUser.surname = userBuilding.userInfo.surname;
      loggedUser.nameComplete =
        userBuilding.userInfo.nameComplete ||
        userBuilding.userInfo.name.concat(' ').concat(userBuilding.userInfo.surname);
      loggedUser.email = userBuilding.userInfo.email;
      loggedUser.roles = userBuilding.roles;
      loggedUser.rolesName = [...new Set(userBuilding.roles?.map((item) => item.role.name))];
      loggedUser.permissions = [];
      loggedUser.permissionBuilding = [];

      this.userService.getUserExtra(loggedUser.id).subscribe((user: User) => {
        loggedUser.department = user.department;
        loggedUser.mainGroup = user.mainGroup;
        loggedUser.mainBuilding = user.mainBuilding;
        loggedUser.mainCity = user.mainCity;
        loggedUser.mainFloor = user.mainFloor;
        this.rolesLoggedUser(loggedUser);
      });
    });
  }

  rolesLoggedUser(loggedUser: User): void {
    const duplas = loggedUser.roles.map((item) => ({ idRole: item.role.id, idBuilding: item.building }));

    from(duplas)
      .pipe(
        mergeMap((dupla) => {
          return this.userService.getRole(dupla.idRole).pipe(
            map((rolex: Role) => {
              return { dupla: dupla, rolex: rolex };
            })
          );
        }),
        map(({ dupla, rolex }) => {
          loggedUser.permissions.push(...rolex.permissions); // Permission's building

          const permissionBuilding = new PermissionBuilding();
          permissionBuilding.buildingId = dupla.idBuilding;
          permissionBuilding.permission = rolex.permissions;
          loggedUser.permissionBuilding.push(permissionBuilding);
        }),
        finalize(() => {
          // agrupo por edificio y escritura sobre lectura
          const arrayGroup = [];
          from(loggedUser.permissionBuilding)
            .pipe(
              groupBy((item) => item.buildingId),
              mergeMap((item) => zip(of(item.key), item.pipe(toArray()))),
              finalize(() => {
                let permissionBuilding: PermissionBuilding = null;
                loggedUser.permissionBuilding = [];
                for (const item of arrayGroup) {
                  const buildingId = item[0];
                  const pBuilding = item[1] as PermissionBuilding[];
                  const allPermission = [];
                  pBuilding
                    .map((m) => m.permission)
                    .forEach((c) => {
                      allPermission.push(...c);
                    });
                  permissionBuilding = new PermissionBuilding();
                  permissionBuilding.permission = allPermission;
                  permissionBuilding.buildingId = buildingId;
                  loggedUser.permissionBuilding.push(permissionBuilding);
                }

                loggedUser.permissionBuilding.forEach((itemP) => {
                  itemP.type = [];
                  const arrayFinal = [];
                  from(itemP.permission)
                    .pipe(
                      groupBy((item) => item.type), // WRITE/READ
                      mergeMap((item) => zip(of(item.key), item.pipe(toArray()))),
                      finalize(() => {
                        itemP.type.push(arrayFinal[0] as string, arrayFinal[1] as Permission[]);
                      })
                    )
                    .subscribe((pSub) => arrayFinal.push(pSub));

                  this.setFinalUser(loggedUser);
                });
              })
            )
            .subscribe((resp) => arrayGroup.push(resp));
        })
      )
      .subscribe();
  }

  setFinalUser(loggedUser: User): void {
    this.buildings = [];
    this.habitatService.getBuildings().subscribe((buildings) => {
      this.buildings = buildings;

      if (this.buildings?.length === 0) {
        loggedUser.buildings = [];
        this.setCurrentUser(loggedUser); // EMIT EVENT userLogged$
        localStorage.setItem(StorageVariables.LOGGED_USER, JSON.stringify(loggedUser));
      } else {
        this.habitatService.getCities().subscribe((cities: City[]) => {
          const buildingsCity: Building[] = [];
          this.buildings.forEach((item, index) => {
            const building = new Building(item);

            if (cities?.length > 0) {
              const city = cities.find((item) => item.id == building.city.id);
              building.city = new City(city);
            }

            buildingsCity.push(building);

            if (index === this.buildings.length - 1) {
              loggedUser.buildings = buildingsCity;
              this.setCurrentUser(loggedUser); // EMIT EVENT userLogged$
              localStorage.setItem(StorageVariables.LOGGED_USER, JSON.stringify(loggedUser));
            }
          });
        });
      }
    });
  }

  getReservationModule(): boolean {
    return this.isModuleEnabled(Modules.reservations) && this.getEsiteModule();
  }

  getEsiteModule(): boolean {
    return this.isModuleEnabled(Modules.esite);
  }

  getConteoModule(): boolean {
    return this.isModuleEnabled(Modules.conteo);
  }

  getEsiteDevice(): boolean {
    return this.isModuleEnabled(Modules.esitedevices);
  }

  isModuleEnabled(moduleId: Modules): boolean {
    return environment.modules.includes(moduleId);
  }

  getModules(): boolean[] {
    const hasReservationModule = this.getReservationModule();
    const hasEsiteDevicesModule = this.getEsiteDevice();
    const hasConteoModule = this.getConteoModule();
    return [hasReservationModule, hasEsiteDevicesModule, hasConteoModule];
  }

  getUser(): User {
    let userLogged = this.getCurrentUser();
    if (!userLogged) {
      const userLoggedStorage = localStorage.getItem(StorageVariables.LOGGED_USER);
      if (userLoggedStorage !== null && userLoggedStorage !== undefined) {
        userLogged = JSON.parse(userLoggedStorage) as User;
      }
    }
    return userLogged;
  }

  getPermissionsBuilding(
    buildingId: number,
    permissionBuilding: PermissionBuilding[],
    permissions: Permission[]
  ): Permission[] {
    const isAllBuilding = permissionBuilding?.find((item) => item.buildingId === -1);
    if (!isAllBuilding) {
      return permissionBuilding?.find((item) => item.buildingId === buildingId)?.permission;
    }
    return permissions;
  }

  setMessagePermission(permission: Permission[]): void {
    this.messageService.clear(AppConstants.MESSAGE_POSITION_CENTER);
    if (permission?.length > 0) {
      this.messageService.add({
        key: AppConstants.MESSAGE_POSITION_CENTER,
        sticky: true,
        severity: 'info',
        summary: this.translateService.instant('permissions'),
        detail: permission?.map((item) => item.name.concat(' - ').concat(item.type)).join(','),
      });
    }
  }

  /** Outer Join */
  getDifference<T>(a: T[], b: T[]): T[] {
    return a.filter((element) => {
      return !b.includes(element);
    });
  }

  hasDifference<T>(a: T[], b: T[]): boolean {
    const difference = this.getDifference(a, b);
    return difference.length > 0;
  }

  printMessage(messageToPrint: string, severity?: MessageType, summary?: string): void {
    this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
    this.messageService.add({
      key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
      severity: severity || 'success',
      summary: summary || 'Message',
      detail: this.translateService.instant(messageToPrint),
    });
  }
}

export type MessageType = 'info' | 'success' | 'warn' | 'error';
