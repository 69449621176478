import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subject, Subscription } from 'rxjs';
import { expand, reduce } from 'rxjs/operators';
import { ChartOptionData } from 'src/app/models/charts/ChartOptionData.class';
import { Zone } from 'src/app/models/conteo/Zone.class';
import { Area } from 'src/app/models/esite/Area.class';
import { AvailabilityType } from 'src/app/models/esite/AvailabilityType.class';
import { Space } from 'src/app/models/esite/Space.class';
import { SpaceType } from 'src/app/models/esite/SpaceType.class';
import { SpaceUseType } from 'src/app/models/esite/SpaceUseType.enum';
import { Building } from 'src/app/models/habitat/Building.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { Languages } from 'src/app/models/Languages.enum';
import { ChartAvailabilityType } from 'src/app/models/realtime/ChartAvailabilityType.class';
import { GroupSpacesState } from 'src/app/models/realtime/GroupSpacesState.class';
import { SpacesState } from 'src/app/models/realtime/SpacesState.class';
import { SpacesStructure } from 'src/app/models/realtime/SpacesStructure.class';
import { Reservation } from 'src/app/models/reservation/Reservation.class';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { ReservationStatus } from 'src/app/models/reservation/ReservationStatus.enum';
import { SvgmapLegendItem } from 'src/app/models/svgmap/SvgmapLegendItem.class';
import { SvgmapPoint } from 'src/app/models/svgmap/SvgmapPoint.class';

import { ConteoService } from '../backend/conteo.service';
import { EsiteService } from '../backend/esite.service';
import { HabitatService } from '../backend/habitat.service';
import { ReservationService } from '../backend/reservation.service';
import { DateService } from '../common/date.service';
import { GlobalService } from '../common/global.service';
import { StorageVariables } from './../../models/StorageVariables.enum';
import { WebsocketMsg } from './../../models/websocket/WebsocketMsg.class';

@Injectable({
  providedIn: 'root',
})
export class RealtimeService {
  building: Building = new Building({});
  buildingChanged = new BehaviorSubject<Building>(this.building);
  esiteBuildingChanged = new Subject<Building>();
  esiteChartsUpdated = new Subject<Building>();
  esiteSpaceUpdated = new Subject<{ spaceId: number; building: Building }>();
  conteoZoneUpdated = new Subject<Building>();
  conteoBuildingChanged = new Subject<Building>();
  reservationBuildingChanged = new Subject<any>();

  floor: Floor = null;
  floorChanged = new BehaviorSubject<Floor>(this.floor);

  area: Area = null;
  areaChanged = new Subject<Area>();
  reservableSpacesByFloor = [];
  reservations: Reservation[];
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  spaceTypes: SpaceType[];
  subcriptions: Subscription[] = [];
  private floorAreasLoaded = new Subject<boolean>();
  private floorSpacesLoaded = new Subject<boolean>();
  private floorImageLoaded = new Subject<boolean>();

  constructor(
    private globalService: GlobalService,
    private esiteService: EsiteService,
    private conteoService: ConteoService,
    private reservationService: ReservationService,
    private habitatService: HabitatService,
    private dateService: DateService,
    private translateService: TranslateService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.hasReservationsModule = this.globalService.getReservationModule();
  }

  getCurrentBuilding(): Building {
    return this.building;
  }

  setCurrentBuilding(building: Building): void {
    if (building) {
      if (this.subcriptions) {
        this.subcriptions.forEach((subs) => subs.unsubscribe());
      }
      this.building = building;
      this.buildingChanged.next(building);
      this.floor = null;
      this.reservableSpacesByFloor = [];

      if (this.hasEsiteModule) {
        this.getEsiteFloorState(building);
      }

      if (this.hasConteoModule) {
        this.getConteoFloorState(building);
      }
      if (this.hasReservationsModule) {
        this.getreservationState(building.floors, 0);
      }
    }
  }

  getEsiteFloorState(building: Building): void {
    if (building) {
      const floors = building.floors;
      const spacesFloorLoaded = [];
      this.building.floorSpacesState = new GroupSpacesState();
      this.subcriptions.push(
        this.esiteService.getSpaceTypes().subscribe((types) => {
          floors.forEach((floor) => {
            const floorState = new SpacesState();
            const floorStructure = new SpacesStructure();
            this.subcriptions.push(
              this.esiteService.getSpacesByFloor(floor.id).subscribe((spaces) => {
                spaces.forEach((spaceitem) => {
                  const space = new Space(spaceitem);
                  const type = types.find((item) => item.id === space.type);
                  space.typeName = type.name;
                  space.useType = SpaceUseType[type.useType];

                  floorStructure.setSpaceToStructure(space);
                  floorState.updateAvailabilityTypeStatus(space.availabilityType, null, space.useType);
                });
                this.building.floorSpacesState.groupStateMap.set(floor.id, floorState);
                this.building.floorSpacesState.groupStructureMap.set(floor.id, floorStructure);
                spacesFloorLoaded.push(floor.id);
                // Check if the all floor spaces has been loaded
                if (spacesFloorLoaded.length === floors.length) {
                  this.esiteBuildingChanged.next(this.building);
                }
              })
            );
          });
        })
      );
    }
  }

  getConteoFloorState(building: Building): void {
    if (building) {
      const floors = building.floors;
      const zonesFloorLoaded = [];
      const floorZonesState = new Map();
      floors.forEach((floor) => {
        this.subcriptions.push(
          this.conteoService.getZonesByFloor(floor.id).subscribe((zones) => {
            const floorZones = [];
            zones.forEach((zoneitem) => {
              const zone = new Zone(zoneitem);
              if (zone.graphicalInfo) {
                floorZones.push(zone);
              }
            });
            floorZonesState.set(floor.id, floorZones);
            zonesFloorLoaded.push(floor.id);
            // Check if the all floor spaces has been loaded
            if (zonesFloorLoaded.length === floors.length) {
              this.building.floorZonesState = floorZonesState;
              this.conteoBuildingChanged.next(this.building);
            }
          })
        );
      });
    }
  }

  getreservationState(floors, index): void {
    if (floors && floors.length > 0) {
      const now = new Date(this.dateService.getUTCDate(new Date())).getTime();
      this.subcriptions.push(
        this.reservationService
          .getReservableSpaces(floors[index].id)
          .subscribe((reservableSpaces: ReservationSpace[]) => {
            const floorspaces = reservableSpaces.length;
            let page = 1;
            const perPage = 100;
            this.subcriptions.push(
              this.reservationService
                .getReservations(floors[index].id, page, perPage, ReservationStatus.InProgress)
                .pipe(
                  expand((result) => {
                    return result.reservations.length < perPage
                      ? EMPTY
                      : this.reservationService.getReservations(
                          floors[index].id,
                          ++page,
                          perPage,
                          ReservationStatus.InProgress
                        );
                  }),
                  reduce((acc, data) => {
                    if (Array.isArray(acc)) {
                      return acc.concat(data);
                    } else {
                      console.log('Given data is not an array');
                      return [acc].concat(data);
                    }
                  })
                )
                .subscribe({
                  next: (res: any) => {
                    let approbedSpacesByFloor = 0;
                    const arrayReservas = Array.isArray(res) ? res : [res];
                    arrayReservas.forEach((itemReserva) => {
                      itemReserva?.reservations?.forEach((reservation) => {
                        const startDate = new Date(reservation.startDate).getTime();
                        const endDate = new Date(reservation.endDate).getTime();
                        if (now >= startDate && now <= endDate) {
                          reservation.spaces.forEach(() => {
                            approbedSpacesByFloor += 1;
                          });
                        }
                      });
                    });
                    let percent = (approbedSpacesByFloor / floorspaces) * 100;
                    if (isNaN(percent)) {
                      percent = 0;
                    }

                    this.reservableSpacesByFloor.push({
                      floor: floors[index],
                      reservableSpaces: floorspaces,
                      reservatedSpaces: approbedSpacesByFloor,
                      percent: percent,
                    });
                    if (index === floors.length - 1) {
                      this.reservationBuildingChanged.next(this.reservableSpacesByFloor);
                    } else {
                      this.getreservationState(floors, index + 1);
                    }
                  },
                  error: (error) => {
                    console.log(error);
                  },
                })
            );
          })
      );
    }
  }

  getImageFloor(floor, floorsImages): void {
    this.habitatService.getFloorImage(floor.id).subscribe({
      next: (floorImage) => {
        const reader = new FileReader();
        reader.addEventListener(
          'load',
          () => {
            const floorImageItem = {
              floor: floor.id,
              image: reader.result,
            };
            if (floorsImages.length > 2) {
              floorsImages.shift();
            }
            floorsImages.push(floorImageItem);
            sessionStorage.setItem(StorageVariables.FLOOR_IMAGE, JSON.stringify(floorsImages));
          },
          false
        );
        if (floorImage) {
          reader.readAsDataURL(floorImage);
        }
        floor.img = floorImage;
        this.floorImageLoaded.next(true);
      },
      error: () => {
        floor.img = null;
        this.floorImageLoaded.next(true);
      },
    });
  }

  setCurrentFloor(floor: Floor): void {
    floor.areasSpacesState = new GroupSpacesState();
    // Wait for all the areas and spaces to be loaded in the areasSpacesState before emit currentFloor.
    const combine = combineLatest([this.floorSpacesLoaded, this.floorAreasLoaded, this.floorImageLoaded]).subscribe(
      () => {
        this.floor = floor;
        this.floorChanged.next(floor);
        combine.unsubscribe();
      }
    );
    this.area = null;
    floor.spaces = [];
    if (floor) {
      const floorsImages = JSON.parse(sessionStorage.getItem(StorageVariables.FLOOR_IMAGE)) || [];
      const thisfloorData = floorsImages?.find((item) => item.floor == floor.id);
      if (thisfloorData) {
        floor.img = thisfloorData.image;
        this.floorImageLoaded.next(true);
      } else {
        this.getImageFloor(floor, floorsImages);
      }

      if (this.hasEsiteModule) {
        this.subcriptions.push(
          this.esiteService.getSpaceTypes().subscribe((types) => {
            this.spaceTypes = types;
            this.subcriptions.push(
              this.esiteService.getSpacesByFloor(floor.id).subscribe((resp) => {
                if (this.hasReservationsModule) {
                  this.subcriptions.push(
                    this.reservationService
                      .getReservableSpaces(floor.id)
                      .subscribe((reservationsSpaces: ReservationSpace[]) => {
                        let page = 1;
                        const perPage = 100;
                        this.subcriptions.push(
                          this.reservationService
                            .getReservations(floor.id, page, perPage, ReservationStatus.InProgress)
                            .pipe(
                              expand((result) => {
                                return result.reservations.length < perPage
                                  ? EMPTY
                                  : this.reservationService.getReservations(
                                      floor.id,
                                      ++page,
                                      perPage,
                                      ReservationStatus.InProgress
                                    );
                              }),
                              reduce((acc, data) => {
                                if (Array.isArray(acc)) {
                                  return acc.concat(data);
                                } else {
                                  console.log('Given data is not an array');
                                  return [acc].concat(data);
                                }
                              })
                            )
                            .subscribe((res: any) => {
                              //load floor Reservations
                              floor.reservations = reservationsSpaces;
                              floor.reservations.forEach((reservationSpace) => {
                                reservationSpace.typeName = types.find(
                                  (item) => item.id === reservationSpace.type
                                ).name;
                              });
                              const now = new Date(this.dateService.getUTCDate(new Date())).getTime();
                              const arrayReservas = Array.isArray(res) ? res : [res];
                              arrayReservas.forEach((itemReserva) => {
                                itemReserva?.reservations?.forEach((reservation) => {
                                  const startDate = new Date(reservation.startDate).getTime();
                                  const endDate = new Date(reservation.endDate).getTime();
                                  //Check if is currently reserved
                                  if (now >= startDate && now <= endDate) {
                                    reservation.spaces.forEach((space) => {
                                      const index = floor.reservations.findIndex((item) => item.code === space.code);
                                      if (floor.reservations[index]) {
                                        floor.reservations[index].status = ReservationStatus.Reserved;
                                        floor.reservations[index].reservationOwner = reservation.owner.name;
                                        floor.reservations[index].reservationDate = reservation.startDate;
                                      } else {
                                        space.status = ReservationStatus.Reserved;
                                        space.reservationOwner = reservation.owner.name;
                                        space.reservationDate = reservation.startDate;
                                        floor.reservations.push(space);
                                      }
                                    });
                                  }
                                });
                              });

                              // Load floor Spaces
                              resp.forEach((item) => {
                                const space = new Space(item);
                                const type = types.find((elem) => elem.id === space.type);
                                space.typeName = type.name;
                                space.useType = SpaceUseType[type.useType];
                                floor.spaces.push(space);
                              });
                              this.floorSpacesLoaded.next(true);
                              // Load areas Status
                              if (floor.areas.length > 0) {
                                floor.areas.forEach((area) => {
                                  this.getAreaOccupation(floor, area);
                                });
                              } else {
                                this.floorAreasLoaded.next(true);
                              }
                              this.floor = floor;
                              this.reservations = res.reservations;
                            })
                        );
                      })
                  );
                } else {
                  resp.forEach((item) => {
                    const space = new Space(item);
                    const type = types.find((elem) => elem.id === space.type);
                    space.typeName = type.name;
                    space.useType = SpaceUseType[type.useType];
                    floor.spaces.push(space);
                  });
                  this.floorSpacesLoaded.next(true);
                  // Load areas Status
                  if (floor.areas.length > 0) {
                    floor.areas.forEach((area) => {
                      this.getAreaOccupation(floor, area);
                    });
                  } else {
                    this.floorAreasLoaded.next(true);
                  }
                  this.floor = floor;
                }
              })
            );
          })
        );
      } else if (this.hasConteoModule) {
        this.reservations = [];
        this.floor = floor;
        this.floorAreasLoaded.next(true);
        this.floorSpacesLoaded.next(true);
      }
    }
  }

  getAreaOccupation(floor: Floor, area: Area): any {
    if (area) {
      const areaState = new SpacesState();
      const areaStructure = new SpacesStructure();
      this.subcriptions.push(
        this.esiteService.getSpacesByArea(area.id).subscribe((spaces) => {
          spaces.forEach((spaceitem) => {
            const space = new Space(spaceitem);
            const type = this.spaceTypes.find((elem) => elem.id === space.type);
            space.typeName = type.name;
            space.useType = SpaceUseType[type.useType];
            areaStructure.setSpaceToStructure(space);
            areaState.updateAvailabilityTypeStatus(space.availabilityType, null, space.useType);
          });
          if (floor) {
            floor.areasSpacesState.groupStateMap.set(area.id, areaState);
            floor.areasSpacesState.groupStructureMap.set(area.id, areaStructure);
            // Check if the all the areas has been loaded in the areasSpacesState.
            if (floor.areasSpacesState.groupStructureMap.size === floor.areas.length) {
              this.floorAreasLoaded.next(true);
            }
          }
        })
      );
    }
  }

  setCurrentArea(area: Area): void {
    this.area = area;
    if (area) {
      this.subcriptions.push(
        this.esiteService.getSpaceTypes().subscribe((types) => {
          this.subcriptions.push(
            this.esiteService.getSpacesByArea(area.id).subscribe((resp) => {
              resp.forEach((item) => {
                const space = new Space(item);
                const type = types.find((elem) => elem.id === space.type);
                space.typeName = type.name;
                space.useType = SpaceUseType[type.useType];
                this.area.spaces.push(space);
              });
              this.areaChanged.next(area);
            })
          );
        })
      );
    }
  }

  getEsiteOcupationMessages(): Observable<WebsocketMsg> {
    return this.esiteService.connectToWebsocket();
  }

  getConteoMessages(): Observable<WebsocketMsg> {
    return this.conteoService.connectToWebsocket();
  }

  getReservationMessages(): Observable<WebsocketMsg> {
    return this.reservationService.connectToWebsocket();
  }

  updatereservation(msg): ReservationSpace[] {
    const now = new Date(this.dateService.getUTCDate(new Date())).getTime();
    const spacesToReturn = [];
    msg.current.spaces.forEach((space) => {
      const index = this.floor.reservations.findIndex((item) => item.code == space.code);
      const startDate = new Date(msg.current.startDate).getTime();
      const endDate = new Date(msg.current.endDate).getTime();
      //Check if is currently reserved
      if (now >= startDate && now <= endDate && msg.current.status == ReservationStatus.InProgress) {
        space.status = ReservationStatus.Reserved;
        space.reservationOwner = msg.current.owner.name;
        space.reservationDate = msg.current.startDate;

        if (msg.previous.status !== ReservationStatus.InProgress) {
          this.reservableSpacesByFloor.forEach((floor) => {
            if (floor.floor.id == space.floor) {
              floor.reservatedSpaces += 1;
              const percent = (floor.reservatedSpaces / floor.reservableSpaces) * 100;
              floor.percent = percent;
            }
          });
        }
      } else if (msg.previous.status == ReservationStatus.InProgress) {
        this.reservableSpacesByFloor.forEach((floor) => {
          if (floor.floor.id == space.floor) {
            floor.reservatedSpaces -= 1;
            const percent = (floor.reservatedSpaces / floor.reservableSpaces) * 100;
            floor.percent = percent;
          }
        });
      }
      this.floor.reservations[index] = space;
      spacesToReturn.push(space);
    });

    this.reservationBuildingChanged.next(this.reservableSpacesByFloor);
    return spacesToReturn;
  }

  updateZoneOccupation(floorId: number, zoneId: number, newOccupation: number): Zone {
    if (this.building?.floorZonesState) {
      const dataZonesFloor = this.building.floorZonesState.get(floorId);
      if (dataZonesFloor) {
        const zone = dataZonesFloor.find((item) => item.id === zoneId);
        if (zone) {
          zone.updateOccupation(newOccupation);
          this.conteoZoneUpdated.next(this.building);
          return zone;
        }
      }
    }
  }

  updateBuildingFloorSpacesState(
    spaceId: number,
    newAvailabilityType: AvailabilityType,
    oldAvailability: AvailabilityType
  ): void {
    this.building.floorSpacesState.updateGroupSpacesState(spaceId, newAvailabilityType, oldAvailability);

    this.esiteChartsUpdated.next(this.building);
    this.esiteSpaceUpdated.next({ spaceId: spaceId, building: this.building });
  }

  updateSpaceInSelectedFloor(
    spaceId: number,
    newAvailabilityType: AvailabilityType,
    oldAvailability: AvailabilityType,
    availabilityDate: string
  ): SvgmapPoint {
    if (this.floor) {
      this.floor.areasSpacesState.updateGroupSpacesState(spaceId, newAvailabilityType, oldAvailability);
      const spaceChanged = this.updateSpaceinParent(this.floor.spaces, spaceId, newAvailabilityType, availabilityDate);
      if (spaceChanged) {
        return this.buildSvgmapPoint(spaceChanged, this.floor.name, this.floor.reservations);
      }
    }
  }

  updateSpaceinParent(
    spaces: Space[],
    spaceId: number,
    newAvailabilityType: AvailabilityType,
    availabilityDate: string
  ): Space {
    const spaceIndex = spaces.findIndex((item) => item.id == spaceId);
    if (spaceIndex > -1) {
      spaces[spaceIndex].availabilityType = newAvailabilityType;
      spaces[spaceIndex].availabilityDate = availabilityDate;
      return spaces[spaceIndex];
    }
  }

  getDonutChartData(total: number, mapTotalByStates: Map<AvailabilityType, number>): ChartOptionData[] {
    const dataRooms: ChartOptionData[] = [];
    if (total > 0) {
      mapTotalByStates.forEach((value: number, key: AvailabilityType) => {
        const charData = new ChartOptionData();
        charData.title = this.translateService.instant(AvailabilityType[key]);
        charData.subtitle = value + '/' + total;
        const chartAvailabilityType = new ChartAvailabilityType(key);
        charData.color = chartAvailabilityType.color;
        charData.value = value;
        charData.total = total;
        charData.inlegend = chartAvailabilityType.inlegend;
        dataRooms.push(charData);
      });
    }
    return dataRooms;
  }

  createLegendItems(): SvgmapLegendItem[] {
    const legenItems = [];
    Object.keys(AvailabilityType).forEach((type, index) => {
      const availabilyType = AvailabilityType[type];
      const chartAvailabilityType = new ChartAvailabilityType(availabilyType);
      if (chartAvailabilityType.inlegend) {
        const legendItem = new SvgmapLegendItem();
        legendItem.color = chartAvailabilityType.color;
        legendItem.name = AvailabilityType[availabilyType];
        legenItems.push(legendItem);
      }
      if (index === Object.keys(AvailabilityType).length - 1) {
        const legendItem = new SvgmapLegendItem();
        legendItem.color = '#8e2ea0';
        legendItem.name = this.translateService.instant('Various states');
        legenItems.push(legendItem);
      }
    });

    return legenItems;
  }

  // Group the spaces by spaceType and AvailabilityType and get totals.
  /* Returns:
    SpacesState: For rooms and sites, get total and group by AvailabilityType
    points: SvgPoints[] for the spaces.
  */
  getRealTimeDataFromSpaces(floorname: string, spaces: Space[], reservations: ReservationSpace[]): any {
    const points: SvgmapPoint[] = [];
    const groupSpacesState: SpacesState = new SpacesState();
    spaces.forEach((space: Space) => {
      points.push(this.buildSvgmapPoint(space, floorname, reservations));
      if (space.useType === SpaceUseType.Desk) {
        const current = groupSpacesState.siteStates.get(space.availabilityType);
        groupSpacesState.siteStates.set(space.availabilityType, current + 1);
        groupSpacesState.totalSites++;
      } else if (space.useType === SpaceUseType.Room) {
        const current = groupSpacesState.roomStates.get(space.availabilityType);
        groupSpacesState.roomStates.set(space.availabilityType, current + 1);
        groupSpacesState.totalRooms++;
      }
    });
    return { groupSpacesState, points };
  }

  buildSvgmapPoint(space: Space, floorname: string, reservations: ReservationSpace[]): SvgmapPoint {
    const point = new SvgmapPoint(space);
    point.statusName = this.translateService.instant(point.statusName);
    point.label3 = this.dateService.utcToLocalString(point.label3, Languages.es, this.building.city.timezone);
    point.titlelabel3 = this.translateService.instant('Date');
    point.building = this.building.name;
    point.buildingId = this.building.id;
    point.floor = floorname;
    if (reservations.find((item) => item.code === space.code)) {
      const reservation = reservations.find((item) => item.code === space.code);
      point.reservationStatus = reservation.status;
      point.label5 = reservation.reservationDate
        ? this.dateService.utcToLocalString(
            this.dateService.getUTCDate(reservation.reservationDate),
            Languages.es,
            this.building.city.timezone
          )
        : undefined;
      point.titlelabel5 = this.translateService.instant('Init');
      point.label4 = reservation.reservationOwner;
      point.titlelabel4 = this.translateService.instant('By');
    }
    return point;
  }
}
