<mat-card>
  <div *ngIf="loading" class="p-5 d-flex align-items-center justify-content-center">
    <app-loading></app-loading>
  </div>
  <mat-card-content [hidden]="loading">
    <div
      *ngIf="!loading && noData"
      class="d-flex align-items-center justify-content-center p-5 fw-bold fs-4"
    >
      {{ 'No results!' | translate }}
    </div>
    <div [hidden]="loading || noData">
      <div class="py-3">
        <button mat-button color="primary" (click)="exportAsExcel()" title="{{ 'ExcelExport' | translate }}" [disabled]="downloadingExcel">
          <span class="bi bi-cloud-download"></span>
          <span class="p-2">{{ (responsive ? 'ExcelExportResponsive' : 'ExcelExport') | translate }}</span>
          <div class="loader" *ngIf="downloadingExcel"></div>
        </button>
      </div>

      <div *ngIf="responsive">
        <table mat-table [dataSource]="dataSource" class="w-100">
          <caption></caption>
          <th id="auditoriaTable"></th>

          <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">{{ 'ID' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let auditData">
              <div class="shortText">ID: {{ auditData.id ?? '-' }}</div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="user">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">
              {{ 'User' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'User' | translate }} :
                <span *ngIf="auditData.user">
                  {{ auditData.user }}
                </span>
                <span *ngIf="!auditData.user">
                  {{ '-' }}
                </span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="space">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">
              {{ 'Space' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Space' | translate }} :
                {{ auditData.space | translate }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">
              {{ 'Location' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Location' | translate }} :
                {{ auditData.location | translate }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="reservationStartTime">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">
              {{ 'Start Date' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Start Date' | translate }} :
                {{ auditData.reservationStartTime | date : 'dd/M/yyyy HH:mm' }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="reservationEndTime">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">
              {{ 'End Date' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'End Date' | translate }} :
                {{ auditData.reservationEndTime | date : 'dd/M/yyyy HH:mm' }}
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="reservationTimeZone">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">
              {{ 'End' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Time zone' | translate }} :
                {{ auditData.reservationTimeZone }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="reservationStatus">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">
              {{ 'Status' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Status' | translate }} :
                {{ auditData.reservationStatus | translate }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="reservationStatusDate">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">
              {{ 'Status Date' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Status Date' | translate }} :
                {{ auditData.reservationStatusDate | date : 'dd/M/yyyy HH:mm:ss' }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="reservationStatusResponsible">
            <mat-header-cell *matHeaderCellDef class="text-primary d-none">
              {{ 'Status Responsible' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Status Responsible' | translate }} :
                {{ auditData.reservationStatusResponsible | translate }}
              </div>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
        </table>
      </div>

      <table *ngIf="!responsive" mat-table [dataSource]="dataSource" class="w-100">
        <caption></caption>
        <th id="auditoriaTable"></th>

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef class="text-primary">{{ 'ID' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let auditData">
            <span>
              {{ auditData.id ?? '-' }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="user">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'User' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData">
            <span *ngIf="auditData.user">
              {{ auditData.user }}
            </span>
            <span *ngIf="!auditData.user">
              {{ '-' }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="space">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'Space' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ auditData.space | translate }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="location">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'Location' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ auditData.location | translate }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="reservationStartTime">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'Start Date' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ auditData.reservationStartTime | date : 'dd/M/yyyy HH:mm' }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reservationEndTime">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'End Date' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ auditData.reservationEndTime | date : 'dd/M/yyyy HH:mm' }}
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="reservationTimeZone">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'Time zone' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ auditData.reservationTimeZone }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reservationStatus">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'Status' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ auditData.reservationStatus | translate }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reservationStatusDate">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'Status Date' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ auditData.reservationStatusDate | date : 'dd/M/yyyy HH:mm:ss' }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="reservationStatusResponsible">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'Status Responsible' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ auditData.reservationStatusResponsible | translate }}
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
      </table>
      <div class="line"></div>
      <div class="d-flex justify-content-end align-items-center p-2">
        <small class="text-muted pe-3"
          >{{ initPaginatorText }}&nbsp;-&nbsp;{{ endPaginatorText }}&nbsp;{{ 'of' | translate }}&nbsp;{{
            totalAuditData
          }}</small>
        <span class="d-inline-flex gap-2">
          <button
            title="{{ 'PreviousPage' | translate }}"
            (click)="goPreviousPage()"
            class="paginator-button"
            [hidden]="pageIndex == 1"
          >
            <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
          </button>
          <button
            title="{{ 'NextPage' | translate }}"
            (click)="goNextPage()"
            class="paginator-button"
            [hidden]="haveNextPage != true"
          >
            <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            </svg>
          </button>
        </span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
