import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin } from 'rxjs';
import { Building } from 'src/app/models/habitat/Building.class';
import { City } from 'src/app/models/habitat/City.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { Reservation } from 'src/app/models/reservation/Reservation.class';
import { ReservationStatus } from 'src/app/models/reservation/ReservationStatus.enum';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { DateService } from 'src/app/services/common/date.service';

@Component({
  selector: 'app-reserve-table',
  templateUrl: './reserve-table.component.html',
  styleUrls: ['./reserve-table.component.css'],
})
export class ReserveTableComponent implements OnInit {
  tableElements = [];
  loading = true;
  columnsToDisplay = ['code', 'location', 'date', 'startHour', 'endHour', 'status'];
  noData = false;
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  userID: number;
  floors: Floor[];
  buildings: Building[];
  cities: City[];
  reservationStatus = [
    { name: 'Approved', status: ReservationStatus.Approved, checked: true },
    { name: 'Checked', status: ReservationStatus.Checked, checked: true },
    { name: 'In progress', status: ReservationStatus.InProgress, checked: true },
    { name: 'Pending approval', status: ReservationStatus.PendingApproval, checked: true },
    { name: 'Cancelled', status: ReservationStatus.Cancelled, checked: true },
    { name: 'Finished', status: ReservationStatus.Finished, checked: true },
  ];
  listStatusToSend: string;
  now = new Date();

  startDate = new Date(this.dateService.getDateDiffMonth(this.now, -1)).getTime();
  endDate = new Date(this.dateService.getDateDiffDays(this.now, 7)).getTime();
  constructor(
    private habitatService: HabitatService,
    private reservationService: ReservationService,
    private dateService: DateService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<ReserveTableComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.userID = data.user;
  }

  ngOnInit(): void {
    this.listStatusToSend = this.buildStatusList();

    const floors = this.habitatService.getFloors();
    const buildings = this.habitatService.getBuildings();
    const cities = this.habitatService.getCities();

    if (this.listStatusToSend.length) {
      if (!this.floors || !this.buildings || !this.cities) {
        forkJoin([floors, buildings, cities]).subscribe((data) => {
          this.floors = data[0];
          this.buildings = data[1];
          this.cities = data[2];
          this.getReservations(this.floors, this.buildings, this.cities);
        });
      } else {
        this.getReservations(this.floors, this.buildings, this.cities);
      }
    } else {
      this.noData = true;
    }
  }

  getReservations(floors: Floor[], buildings: Building[], cities): void {
    const notKnowValue = '--';
    this.tableElements = [];
    this.reservationService
      .getReservationsByUser(
        this.userID,
        this.listStatusToSend,
        this.formatDate(this.startDate),
        this.formatDate(this.endDate)
      )
      .subscribe((reservations: Reservation[]) => {
        this.noData = reservations.length == 0;
        reservations.forEach((reservation) => {
          let code = reservation.spaces ? '' : notKnowValue;

          if (reservation.spaces) {
            // Format code string (if the reservation is for more than one site, split using commas)
            for (let i = 0; i < reservation.spaces.length; i++) {
              if (i === reservation.spaces.length - 1) {
                code += reservation.spaces[i].code || '';
              } else {
                if (reservation.spaces[i].code) {
                  code += reservation.spaces[i].code;
                  if (reservation.spaces[i + 1].code) {
                    code += ', ';
                  }
                }
              }
            }
          }
          // Format Dates
          const startDate = new Date(this.dateService.formatDateAslocal(reservation.startDate));
          const endDate = new Date(this.dateService.formatDateAslocal(reservation.endDate));
          const untranslatedstatus = reservation.status;
          const status = this.translateService.instant(reservation.status);

          // Format location string
          const spaceFloor = reservation.spaces.find((space) => space.floor)?.floor || -1;
          const floor = floors.find((floor) => floor.id === spaceFloor);
          const building = buildings.find((building) => building.id === floor?.building);
          const city = cities.find((city) => city.id === building?.city);

          if (city && building && floor) {
            const location = `${this.translateService.instant(city.name)} - ${building.name} - ${floor.name}`;

            this.tableElements.push({
              id: reservation.id,
              name: reservation.name,
              description: reservation.description,
              code: code,
              date: startDate,
              location: location,
              startHour: startDate,
              endHour: endDate,
              status: status,
              untranslatedstatus: untranslatedstatus,
              owner: reservation.owner.name,
              isGrupal: reservation.spaces.length > 1,
            });
          }
        });
        this.dataSource.data = this.tableElements;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      });
  }

  buildStatusList(): string {
    let listStatusToSend = '';
    this.reservationStatus.forEach((status) => {
      if (status.checked === true) {
        listStatusToSend += status.status + ',';
      }
    });
    return listStatusToSend.substring(0, listStatusToSend.length - 1);
  }

  formatDate(date: number): string {
    const timezone = new City(this.cities[0]).timezone;
    return this.dateService.setTimeZone(date, timezone);
  }

  close(): void {
    this.dialogRef.close();
  }
}
