<div class="container h-100 w-100 d-flex justify-content-center align-items-center">
  <div class="row">
    <div class="col-10 mx-auto">
      <h2 class="titleN4 pt-3">{{ 'Session is about to expire' | translate }}</h2>
      <p>
        {{
          'Your session expires soon. In order to continue using this site, please click the button below'
            | translate
        }}
      </p>
      <div class="text-center">
        <button
          class="modalButton habitat-button-blue bg-primary mb-3"
          (click)="onClick()"
          style="width: 250px"
        >
          {{ 'Continue session' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
