import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { VersionsService } from '../services/backend/versions.service';

/**
 * Interceptor that, for all request to the different habitat modules,
 * tries to read the response header X-Implementation-Version and update the different
 * modules version
 * */
@Injectable()
export class VersionsInterceptor implements HttpInterceptor {
  constructor(private versionsService: VersionsService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      request.url.includes(environment.habitat_backend_url) ||
      request.url.includes(environment.conteo_backend_url) ||
      request.url.includes(environment.esite_backend_url) ||
      request.url.includes(environment.reservations_backend_url)
    ) {
      return next.handle(request).pipe(
        tap((response) => {
          if (response instanceof HttpResponse) {
            if (response.headers.has('X-Implementation-Version')) {
              if (request.url.includes(environment.habitat_backend_url)) {
                this.versionsService.setHabitatVersion(response.headers.get('X-Implementation-Version'));
              } else if (request.url.includes(environment.conteo_backend_url)) {
                this.versionsService.setConteoVersion(response.headers.get('X-Implementation-Version'));
              } else if (request.url.includes(environment.esite_backend_url)) {
                this.versionsService.setEsiteVersion(response.headers.get('X-Implementation-Version'));
              } else if (request.url.includes(environment.reservations_backend_url)) {
                this.versionsService.setReservationsVersion(response.headers.get('X-Implementation-Version'));
              }
            }
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
