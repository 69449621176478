import { AvailabilityType } from '../esite/AvailabilityType.class';
import { SpaceUseType } from '../esite/SpaceUseType.enum';

export class SpacesState {
  siteStates = new Map<AvailabilityType, number>();
  roomStates = new Map<AvailabilityType, number>();
  totalSites = 0;
  totalRooms = 0;

  constructor() {
    for (const type of Object.keys(AvailabilityType)) {
      const availabilyType = AvailabilityType[type];
      this.siteStates.set(availabilyType, 0);
      this.roomStates.set(availabilyType, 0);
    }
  }

  updateAvailabilityTypeStatus(
    availabilityType: AvailabilityType,
    oldavailabilityType: AvailabilityType,
    spaceType: SpaceUseType
  ): void {
    if (spaceType === SpaceUseType.Desk) {
      this.updateAvailabilityForMap(availabilityType, oldavailabilityType, this.siteStates);
    } else if (spaceType === SpaceUseType.Room) {
      this.updateAvailabilityForMap(availabilityType, oldavailabilityType, this.roomStates);
    }
  }

  updateAvailabilityForMap(
    availabilityType: AvailabilityType,
    oldavailabilityType: AvailabilityType,
    map: Map<AvailabilityType, number>
  ): void {
    const currentroomsAvailability = map.get(availabilityType);
    map.set(availabilityType, currentroomsAvailability + 1);
    if (oldavailabilityType) {
      const oldroomsAvailability = map.get(oldavailabilityType);
      map.set(oldavailabilityType, oldroomsAvailability - 1);
    }
  }
}
