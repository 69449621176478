<div class="titleN3" style="margin-bottom: 10px; margin-left: 20px">{{ chartIdentifier.name | translate }}</div>
<div *ngIf="loading" style="display: flex; align-items: center; justify-content: center; min-height: 184px">
  <app-loading></app-loading>
</div>
<div [hidden]="loading">
  <div *ngIf="sizeData == 0" class="row" style="padding-left: 40px">
    {{ chartIdentifier.msgEmpty | translate }}
  </div>
  <div class="row" [hidden]="sizeData == 0">
    <div class="chart-container" style="width: 98%; height: 150px; padding: 10px">
      <canvas id="{{ 'chartCanvas' + chartIdentifier.id }}" width="250" height="250"></canvas>
    </div>
  </div>
</div>
