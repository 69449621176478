import { Resource } from '../esite/Resource.class';
import { Space } from '../esite/Space.class';
import { ChartAvailabilityType } from '../realtime/ChartAvailabilityType.class';

export class SvgmapPoint {
  code: string;
  name: string;
  positionX: number;
  positionY: number;
  typeName: string;
  useType: string;
  statusName: string;
  color: string;
  building: string;
  buildingId?: number;
  floor: string;
  label3: string;
  titlelabel3: string;
  label4: string;
  titlelabel4: string;
  label5: string;
  titlelabel5: string;
  reservationStatus: string;
  resources: Resource[] = [];

  constructor(space: Space) {
    this.code = space ? space.id + '' : '';
    this.name = space ? space.name : '';
    this.positionX = space ? space.positionX : null;
    this.positionY = space ? space.positionY : null;
    this.statusName = space ? space.availabilityType : '';
    this.typeName = space ? space.typeName : '';
    this.useType = space ? space.useType : '';
    this.color = space ? new ChartAvailabilityType(space.availabilityType).color : '';
    this.label3 = space ? space.availabilityDate : '';
  }

  setExcelData(parsedRow): void {
    this.name = parsedRow['Código'];
    let positionX = null;
    if (!isNaN(Number(parsedRow['Posición X']))) {
      positionX = Number(parsedRow['Posición X']);
    } else {
      positionX = Number(parsedRow['Posición X'].replace(',', '.'));
    }

    let positionY = null;
    if (!isNaN(Number(parsedRow['Posición Y']))) {
      positionY = Number(parsedRow['Posición Y']);
    } else {
      positionY = Number(parsedRow['Posición Y'].replace(',', '.'));
    }
    this.positionX = positionX;
    this.positionY = positionY;
    this.typeName = parsedRow['Tipo de Espacio'];
    this.useType = parsedRow['Tipo de uso'];
  }
}
