import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Constants } from 'src/app/models/Constants.const';
import { SpaceUseType } from 'src/app/models/esite/SpaceUseType.enum';
import { Building } from 'src/app/models/habitat/Building.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { ReservationUser } from 'src/app/models/reservation/ReservationUser.class';
import { ReservationUsersFiltered } from 'src/app/models/reservation/ReservationUsersFiltered.class';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { AuditPagesService } from 'src/app/services/pages/audit.service';

@Component({
  selector: 'app-reservation-audit-filter',
  templateUrl: './reservation-audit-filter.component.html',
  styleUrls: ['./reservation-audit-filter.component.scss'],
})
export class ReservationAuditFilterComponent implements OnInit {
  @Output('search') onSearchEmitter = new EventEmitter<any>();

  filterFormGroup: UntypedFormGroup;
  users: ReservationUser[] = [];
  startTime: Date = new Date();
  endTime: Date = new Date();
  buildings: Building[] = [];
  floors: Floor[] = [];
  useTypes = Object.entries(SpaceUseType).map(([type]) => type);
  status = ['PendingApproval', 'Approved', 'Checked', 'InProgress', 'Finished', 'Cancelled'];
  resultsTableLoading: boolean;

  startDateText: Date;
  endDateText: Date;
  showRangefilter = false;
  isValidDateForm = true;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private reservationService: ReservationService,
    private globalService: GlobalService,
    private habitatService: HabitatService,
    private auditService: AuditPagesService,
    private datepipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.cleanRangeField();
    this.createForm();
    this.getUsers();
    this.getBuildings();
  }

  createForm(): void {
    this.filterFormGroup = this.formBuilder.group({
      reservationsbuilding: ['', []],
      reservationsuser: ['', []],
      reservationsfloor: ['', []],
      reservationsuseType: ['', []],
      reservationsstatus: ['', []],
      reservationsdateRange: ['', []],
      reservationsspace: ['', []],
      reservationsstartDateText: ['', []],
      reservationsendDateText: ['', []],
      reservationsLocalHour: [false, []],
    });
  }

  getUsers(): void {
    this.reservationService
      .getUsersFiltered(1, Constants.maxInt, null, null, null, null)
      .subscribe((res: ReservationUsersFiltered) => {
        this.users = res.users
          .map((user) => ({ ...user, nameComplete: user.name.concat(' ').concat(user.surname) }))
          .sort((a, b) => (a.nameComplete > b.nameComplete ? 1 : b.nameComplete > a.nameComplete ? -1 : 0));
      });
  }

  getBuildings(): void {
    this.globalService.userBuildings$.subscribe((buildings: Building[]) => {
      this.buildings = buildings;
    });
  }

  getBuildingFloors(): void {
    this.filterFormGroup.controls['reservationsfloor'].setValue(null);
    this.habitatService
      .getFloorsByBuilding(this.filterFormGroup.value.reservationsbuilding)
      .subscribe((floors: Floor[]) => {
        this.floors = floors;
      });
  }

  clearField(event: any): void {
    switch (event) {
      case 'building': {
        this.floors = [];
        this.filterFormGroup.controls['reservationsbuilding'].setValue(null);
        this.filterFormGroup.controls['reservationsfloor'].setValue(null);
        break;
      }
      case 'floor': {
        this.filterFormGroup.controls['reservationsfloor'].setValue(null);
        break;
      }
      case 'user': {
        this.filterFormGroup.controls['reservationsuser'].setValue(null);
        break;
      }
      case 'useType': {
        this.filterFormGroup.controls['reservationsuseType'].setValue(null);
        break;
      }
      case 'status': {
        this.filterFormGroup.controls['reservationsstatus'].setValue(null);
        break;
      }
      case 'space': {
        this.filterFormGroup.controls['reservationsspace'].setValue(null);
        break;
      }

      case 'dateRange': {
        this.isValidDateForm = true;
        this.filterFormGroup.controls['reservationsstartDateText'].setValue(null);
        this.filterFormGroup.controls['reservationsendDateText'].setValue(null);
        this.filterFormGroup.controls['reservationsdateRange'].setValue(null);
        this.startDateText = undefined;
        this.endDateText = undefined;
        this.cleanRangeField();

        break;
      }
    }
  }

  cleanRangeField(): void {
    this.startTime = new Date(new Date().setHours(0, 0, 0));
    this.endTime = new Date(new Date().setHours(23, 59, 0));
  }

  get f() {
    return this.filterFormGroup.controls;
  }

  confirmRange(): void {
    this.filterFormGroup.controls['reservationsstartDateText'].setValue(this.startTime);
    this.filterFormGroup.controls['reservationsendDateText'].setValue(this.endTime);
    this.endTime.setHours(23, 59, 0);
    this.filterFormGroup.controls['reservationsdateRange'].setValue(
      this.datepipe.transform(this.startTime, 'dd/MM/yyyy') +
        ' - ' +
        this.datepipe.transform(this.endTime, 'dd/MM/yyyy')
    );
  }

  searcher = (search: string, pageNumber: number, pageSize: number): Observable<ReservationUser[]> => {
    // searcher must return Observable in this method use rxjs of

    const filteredusers = this.users
      .filter((z) =>
        z.nameComplete
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            search
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      )
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    if (!filteredusers.length) {
      this.filterFormGroup.controls['reservationsuser'].setValue(null);
    }
    return of(filteredusers);
  };

  onSearch(): void {
    this.auditService.isResultsTableLoading$.subscribe(
      (resultsTableLoading) => (this.resultsTableLoading = resultsTableLoading)
    );
    this.auditService.setAudirFilterPArameters(this.filterFormGroup.value);
    this.onSearchEmitter.emit();
  }
}
