import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { ProgressData } from 'src/app/models/charts/ProgressData.class';
import { Zone } from 'src/app/models/conteo/Zone.class';
import { AvailabilityType } from 'src/app/models/esite/AvailabilityType.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { SpacesOccupationData } from 'src/app/models/realtime/SpacesOccupationData.class';
import { SpacesState } from 'src/app/models/realtime/SpacesState.class';
import { ZoneOccupationData } from 'src/app/models/realtime/ZoneOccupationData.class';
import { GlobalService } from 'src/app/services/common/global.service';
import { RealtimeService } from '../../../../services/pages/realtime.service';

@Component({
  selector: 'app-realtime-space',
  templateUrl: './realtime-space.component.html',
  styleUrls: ['./realtime-space.component.scss'],
})
export class RealtimeSpaceComponent implements OnInit, OnDestroy {
  currentBuilding = null;

  initalSpaceInfoState = true;
  spaceDataExpanded = true;
  loading = true;
  building: Building = new Building('');
  roomsChart = { id: 1, name: 'Rooms', msgEmpty: 'NoRoomsInBuilding' };
  sitesChart = { id: 2, name: 'Sites', msgEmpty: 'NoPostsInBuilding' };
  roomsChartData;
  sitesChartData;
  aforoData;
  aforoTitle = 'Capacity';
  reservationTitle = 'Reservation';
  reservationData;
  reservableSpacesByFloor = [];
  totalBuildingEsiteSpaces = { rooms: 0, sites: 0 };
  totalFloorEsiteSpaces: Map<number, SpacesOccupationData>;
  totalFloorZoneOccupation: Map<number, ZoneOccupationData>;
  subcriptions: Subscription[] = [];
  currentReservationsCount = 0;
  reservableSpacesCount = 0;
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  hasEsiteDevicesModule = false;
  reservationLoading = true;
  constructor(private realtimeService: RealtimeService, private globalService: GlobalService) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    const modules = this.globalService.getModules();
    this.hasReservationsModule = modules[0];
    this.hasEsiteDevicesModule = modules[1];
    this.hasConteoModule = modules[2];
  }

  ngOnInit(): void {
    if (this.hasEsiteDevicesModule && this.hasReservationsModule && this.hasConteoModule) {
      combineLatest([
        this.realtimeService.esiteBuildingChanged,
        this.realtimeService.conteoBuildingChanged,
        this.realtimeService.reservationBuildingChanged,
      ]).subscribe(() => {
        this.loading = false;
      });
    }
    if (this.hasConteoModule && this.hasEsiteDevicesModule && !this.hasReservationsModule) {
      combineLatest([this.realtimeService.esiteBuildingChanged, this.realtimeService.conteoBuildingChanged]).subscribe(
        () => (this.loading = false)
      );
    }
    if (this.hasConteoModule && this.hasReservationsModule && !this.hasEsiteDevicesModule) {
      combineLatest([
        this.realtimeService.reservationBuildingChanged,
        this.realtimeService.conteoBuildingChanged,
      ]).subscribe(() => (this.loading = false));
    }
    if (this.hasConteoModule && !this.hasReservationsModule && !this.hasEsiteDevicesModule) {
      combineLatest([this.realtimeService.conteoBuildingChanged]).subscribe(() => (this.loading = false));
    }
    if (this.hasReservationsModule && this.hasEsiteDevicesModule && !this.hasConteoModule) {
      combineLatest([
        this.realtimeService.esiteBuildingChanged,
        this.realtimeService.reservationBuildingChanged,
      ]).subscribe(() => (this.loading = false));
    }
    if (this.hasReservationsModule && !this.hasConteoModule && !this.hasEsiteDevicesModule) {
      combineLatest([this.realtimeService.reservationBuildingChanged]).subscribe(() => (this.loading = false));
    }
    if (this.hasEsiteDevicesModule && !this.hasReservationsModule && !this.hasConteoModule) {
      combineLatest([this.realtimeService.esiteBuildingChanged]).subscribe(() => (this.loading = false));
    }

    this.subcriptions.push(
      this.realtimeService.buildingChanged.subscribe((building: Building) => {
        this.initBuilding(building);
      })
    );

    this.subcriptions.push(
      this.realtimeService.esiteChartsUpdated.subscribe((building: Building) => {
        this.updateEsiteData(building);
      })
    );

    this.subcriptions.push(
      this.realtimeService.esiteBuildingChanged.subscribe((building: Building) => {
        this.updateEsiteData(building);
      })
    );

    this.subcriptions.push(
      this.realtimeService.conteoZoneUpdated.subscribe((building: Building) => {
        this.updateConteoData(building);
      })
    );

    this.subcriptions.push(
      this.realtimeService.conteoBuildingChanged.subscribe((building: Building) => {
        this.updateConteoData(building);
      })
    );

    this.subcriptions.push(
      this.realtimeService.reservationBuildingChanged.subscribe((building: Building) => {
        this.updateReservationData(building);
      })
    );
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  initBuilding(building): void {
    this.building = building;
    if (this.building) {
      this.currentBuilding = this.building.name;
    }
    this.roomsChartData = null;
    this.sitesChartData = null;
    this.aforoData = null;
    this.reservationData = null;
    this.currentReservationsCount = 0;
    this.reservableSpacesCount = 0;
    this.reservableSpacesByFloor = [];
    this.loading = true;
    this.reservationLoading = true;
  }

  updateEsiteData(building: Building): void {
    this.building.floorSpacesState = building.floorSpacesState;
    this.totalBuildingEsiteSpaces.rooms = 0;
    this.totalBuildingEsiteSpaces.sites = 0;
    const roomsTotalByStatus = new Map<AvailabilityType, number>();
    const sitesTotalByStatus = new Map<AvailabilityType, number>();
    this.totalFloorEsiteSpaces = new Map<number, SpacesOccupationData>();

    // Init Map for each state at 0.
    for (const type of Object.keys(AvailabilityType)) {
      const availabilyType = AvailabilityType[type];
      roomsTotalByStatus.set(availabilyType, 0);
      sitesTotalByStatus.set(availabilyType, 0);
    }
    // From the building occupation state, loop the info to get total of rooms/sites for each AvailabilyType and total.
    building.floorSpacesState.groupStateMap.forEach((floorState: SpacesState, floorId: number) => {
      const floorOccupation = new SpacesOccupationData();
      floorState.roomStates.forEach((value: number, key: AvailabilityType) => {
        const current = roomsTotalByStatus.get(key);
        roomsTotalByStatus.set(key, current + value);
        this.totalBuildingEsiteSpaces.rooms += value;
        floorOccupation.totalRooms += value;
        if (key === AvailabilityType.NotAvailable || key === AvailabilityType.Absent) {
          floorOccupation.occupiedRooms += value;
        }
      });
      floorOccupation.occupiedRooms = floorOccupation.occupiedRooms < 0 ? 0 : floorOccupation.occupiedRooms;

      floorState.siteStates.forEach((value: number, key: AvailabilityType) => {
        const current = sitesTotalByStatus.get(key);
        sitesTotalByStatus.set(key, current + value);
        this.totalBuildingEsiteSpaces.sites += value;
        floorOccupation.totalSites += value;
        if (key === AvailabilityType.NotAvailable || key === AvailabilityType.Absent) {
          floorOccupation.occupiedSites += value;
        }
        floorOccupation.occupiedSites = floorOccupation.occupiedSites < 0 ? 0 : floorOccupation.occupiedSites;
      });
      // Store for each floor the total occupation group by Sites and Rooms. For the floor list div.
      this.totalFloorEsiteSpaces.set(floorId, floorOccupation);
    });
    // Send data to Donut chart.
    this.roomsChartData = this.realtimeService.getDonutChartData(
      this.totalBuildingEsiteSpaces.rooms,
      roomsTotalByStatus
    );
    this.sitesChartData = this.realtimeService.getDonutChartData(
      this.totalBuildingEsiteSpaces.sites,
      sitesTotalByStatus
    );
  }

  updateReservationData(reservationSpaces): void {
    let floorCount = 0;
    this.currentReservationsCount = 0;
    this.reservableSpacesCount = 0;

    reservationSpaces.forEach((floor) => {
      floor.reservatedSpaces = floor.reservatedSpaces < 0 ? 0 : floor.reservatedSpaces;
      floor.percentage = floor.percentage < 0 ? 0 : floor.percentage;
      this.currentReservationsCount += floor.reservatedSpaces;
      this.reservableSpacesCount += floor.reservableSpaces;

      floorCount += 1;
      if (this.building.floors.length == floorCount) {
        this.reservableSpacesByFloor = reservationSpaces;
        this.reservationData = new ProgressData(this.currentReservationsCount, this.reservableSpacesCount);
        this.reservationLoading = false;
      }
    });
  }

  updateConteoData(building: Building): void {
    this.building.floorZonesState = building.floorZonesState;
    this.totalFloorZoneOccupation = new Map();
    let totalBuildingCapacity = 0;
    let totalBuildingOccupation = 0;
    building.floorZonesState.forEach((zones: Zone[], floorId: number) => {
      const floorData = new ZoneOccupationData();
      zones.forEach((zone) => {
        totalBuildingCapacity += zone.maxOccupation;
        totalBuildingOccupation += zone.occupation;
        floorData.maxOccupation += zone.maxOccupation;
        floorData.occupation += zone.occupation;
      });
      floorData.occupation = floorData.occupation < 0 ? 0 : floorData.occupation;
      this.totalFloorZoneOccupation.set(floorId, floorData);
    });
    this.aforoData = new ProgressData(totalBuildingOccupation, totalBuildingCapacity);
  }

  getConteoPercentage(floorId: number): number {
    let percentage = 0;
    if (this.totalFloorZoneOccupation) {
      const data = this.totalFloorZoneOccupation.get(floorId);
      if (data) {
        if (data.occupation && data.maxOccupation && data.maxOccupation !== 0) {
          percentage = (data.occupation / data.maxOccupation) * 100;
        }
        if (percentage < 0) {
          percentage = 0;
        }
        if (percentage > 100) {
          percentage = 100;
        }
      }
    }
    return percentage;
  }

  getConteoDataFloor(floorId: number): ProgressData {
    let totalFloorCapacity = 0;
    let totalFloorOccupation = 0;
    if (this.building) {
      const floorZonesState = this.building.floorZonesState.get(floorId);
      floorZonesState.forEach((zone: Zone) => {
        totalFloorCapacity += zone.maxOccupation;
        totalFloorOccupation += zone.occupation;
      });
    }
    return new ProgressData(totalFloorOccupation, totalFloorCapacity);
  }

  collapseAction(state): void {
    this.spaceDataExpanded = state;
  }
}
