<collapse-card title="{{ 'Data' | translate }} {{ currentFloor.name }}">
  <div
    *ngIf="loading"
    style="text-align: -webkit-center; text-align: -moz-center; padding-top: 15%; min-height: 390px"
  >
    <app-loading></app-loading>
  </div>
  <div *ngIf="!loading">
    <canvas id="myCanvas" style="display: none"></canvas>
    <div style="width: 100%" [ngStyle]="{ 'margin-bottom': selected == optionZones ? '80px' : '25px' }">
      <div class="float-right">
        <div class="d-block mb-3 text-end">
          <button mat-button color="primary" (click)="exportfloor()" title="{{ 'Download floor' | translate }}">
            <span class="bi bi-cloud-download"></span>
            <span class="px-2">{{ 'Download floor' | translate }}</span>
          </button>
        </div>
        <div class="d-flex mb-3 justify-content-end">
          <div *ngIf="selected == optionZones">
            <button
              mat-button
              *ngIf="!isDrawingMode && !isDeletingZoneMode && canWriteZones"
              (click)="activateDraw()"
            >
            <span class="smallIcon bi bi-plus-circle"></span>
            <span class="ps-1">{{ addButtonTitle | translate }}</span>
            </button>
            <button
              mat-button
              *ngIf="!isDrawingMode && !isDeletingZoneMode && canWriteZones"
              class="ms-3"
              (click)="deletingZoneMode()"
            >
              <span class="smallIcon bi bi-trash"></span>
              <span class="ps-1">{{ 'Delete zone' | translate }}</span>
            </button>
          </div>
          <div *ngIf="selected == optionSpaces">
            <button
              mat-button
              *ngIf="
                !isClickMode && !isDeletingSiteMode && selectedInSpaces == optionSites && canWriteEsiteSpaces
              "
              (click)="activateClickSites()"
            >
            <span class="smallIcon bi bi-plus-circle"></span>
            <span class="ps-1">{{ addButtonTitle | translate }}</span>
            </button>
            <button
              mat-button
              *ngIf="!isDrawingMode && !isDeletingAreaMode && selectedInSpaces == optionArea && canWriteAreas"
              (click)="activateDraw()"
            >
              <span class="smallIcon bi bi-plus-circle"></span>
              <span class="ps-1">{{ addButtonTitle | translate }}</span>
            </button>
            <button
              mat-button
              *ngIf="
                !isDrawingMode &&
                !isDeletingAreaMode &&
                selectedInSpaces === optionArea &&
                onlyAreas &&
                canWriteAreas
              "
              class="ms-3"
              (click)="deletingAreaMode()"
            >
              <span class="smallIcon bi bi-trash"></span>
              <span class="ps-1">{{ 'Delete area' | translate }}</span>
            </button>
            <button
              mat-button
              *ngIf="
                !isDrawingMode &&
                !isDeletingSiteMode &&
                !isClickMode &&
                selectedInSpaces === optionSites &&
                canWriteEsiteSpaces
              "
              class="ms-3"
              (click)="deletingSiteMode()"
            >
              <span class="smallIcon bi bi-trash"></span>
              <span class="ps-1">{{ 'Delete site' | translate }}</span>
            </button>
            <button
              mat-button
              *ngIf="
                !isDrawingMode &&
                !isDeletingAreaMode &&
                !isDeletingSiteMode &&
                selectedInSpaces === optionReservation &&
                canWriteReservationsSpaces
              "
              (click)="activateDrawReservation()"
            >
              <span class="smallIcon bi bi-plus-circle"></span>
              <span class="ps-1">{{ 'Multiple selection' | translate }}</span>
            </button>
          </div>

          <button
            mat-button
            *ngIf="
              isDrawingMode || isDeletingAreaMode || isDeletingSiteMode || isClickMode || isDeletingZoneMode
            "
            (click)="cancelDraw()"
          >
            <span class="smallIcon bi bi-x-circle"></span>
            <span class="ps-1">{{ 'Cancel' | translate }}</span>
          </button>
        </div>
      </div>
      <mat-radio-group [(ngModel)]="selected" (change)="loadMap($event.value)">
        <mat-radio-button *ngIf="hasEsiteModule" [value]="optionSpaces" checked style="margin-right: 20px"
          >{{ 'Workstations' | translate }}
        </mat-radio-button>
        <mat-radio-button *ngIf="hasConteoModule" [value]="optionZones"
          >{{ 'Zones' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div
      *ngIf="optionSpaces == selected"
      style="
        border-left: 3px solid #0075aa;
        display: table-caption;
        padding-left: 17px;
        margin: -12px 0px 15px 8px;
      "
    >
      <mat-radio-group [(ngModel)]="selectedInSpaces" (change)="loadMap(optionSpaces)">
        <mat-radio-button [value]="optionSites" checked style="margin-right: 20px"
          >{{ 'Edition' | translate }}
        </mat-radio-button>
        <mat-radio-button [value]="optionArea">{{ 'Areas' | translate }} </mat-radio-button>
        <mat-radio-button *ngIf="hasReservationsModule" [value]="optionReservation"
          >{{ 'Reservation' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>
    <div
      *ngIf="selectedInSpaces == optionReservation && optionSpaces == selected"
      class="d-inline-block"
      style="place-content: space-evenly"
    >
      <mat-radio-group style="display: contents">
        <mat-radio-button
          *ngFor="let filter of filterReservation"
          class="mx-2"
          [value]="filter"
          [checked]="filter.value == filterselected"
          (change)="changeFilter(filter.value)"
        >
          {{ filter.name | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <div [formGroup] = "mapFilterFormGroup" class="row pb-3 p-md-1 justify-content-end" *ngIf="selected == optionSpaces" >
      <div  *ngIf="selectedInSpaces == optionSites" class="col-2">
        <mat-form-field>
        <mat-label>{{ 'Use type' | translate }}</mat-label>
        <mat-select formControlName="useType" (selectionChange)="applyFilter('useType',$event)" formControlName="useType">
          <mat-option *ngFor="let useType of useTypes" [value]="useType" >
            {{ useType | translate }}
          </mat-option>
        </mat-select>
        <mat-icon matSuffix class="icon-button" 
        *ngIf="mapFilterFormGroup.value.useType"
        [title]="'Clear selection' | translate"
        (click)="clearField('useType')">
          highlight_off
        </mat-icon>
        </mat-form-field>
      </div>
      <div  *ngIf="selectedInSpaces == optionReservation || selectedInSpaces == optionSites" class="col-2">
        <mat-form-field>
          <mat-label>{{ 'Type' | translate }}</mat-label>
          <mat-select formControlName="type" (selectionChange)="applyFilter('type', $event)" formControlName="type" >
            <mat-option *ngFor="let type of typesForSelect" [value]="type" >
              {{ type.name | translate }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix class="icon-button" 
          *ngIf="mapFilterFormGroup.value.type"
          [title]="'Clear selection' | translate"
          (click)="clearField('type')">
            highlight_off
          </mat-icon>
        </mat-form-field>
      </div>
   </div>

    <div *ngIf="selected == optionSpaces" class="d-flex justify-content-end col-12 col-md-6 offset-md-6 py-1">
      <input
        [(ngModel)]="spaceToFind"
        #ctrl="ngModel"
        (keyup.enter)="applyFilter('search')"
        class="input-form"
        placeholder="{{ 'Search' | translate }}"
        maxlength="45"
        type="text"
      />
      <span
        (click)="applyFilter('search')"
        [title]="'Search' | translate"
        class="bi bi-search text-primary px-2"
        role="button"
      ></span>
      <span
        [title]="'Refresh' | translate"
        class="bi bi-arrow-clockwise px-2 text-primary"
        role="button"
        (click)="clearSearch()"
      ></span>
    </div>
    
    <app-svg-map
      style="width: 100%"
      [inputData]="svgDataToSend"
      [svgMapProperties]="svgProperties"
      [reservationMap]="isReservationMap"
      [isSitesMap]="isSitesMap"
      [legendItems]="legendItems"
      [isDrawingMode]="isDrawingMode"
      [isClickMode]="isClickMode"
      (positionClicked)="positionClicked($event)"
      [reservationGroupMode]="reservationGroupMode"
      (pointClicked)="pointClicked($event)"
      (newPolygonDrawn)="newPolygonDrawn($event)"
      (polygonClicked)="polygonClicked($event)"
      [polygonsEvent]="polygonsEvent.asObservable()"
      [isDeletingAreaMode]="isDeletingAreaMode"
      [isDeletingSiteMode]="isDeletingSiteMode"
      [filterselected]="filterselected"
    >
    </app-svg-map>
  </div>
</collapse-card>

<ng-template #formModal>
  <mat-card>
    <mat-card-content style="max-width: 750px" class="marginBodyCard">
      <label class="titleN3 text-primary modalTitle"> {{ titleModal | translate }}</label>
      <form
        class="example-form row"
        [formGroup]="polygonFormGroup"
        style="padding-left: 40px"
        (ngSubmit)="savePolygon()"
      >
        <mat-form-field class="col-xl-6 col-lg-6 col-md-6 col-sm-6 firstDiv pLeft">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input
            title="{{ 'Name' | translate }}"
            matInput
            autocomplete="off"
            formControlName="name"
            type="text"
            class="p-1"
            maxlength="50"
            required
            spellcheck="false"
          />
          <mat-hint *ngIf="polygonFormGroup.get('name')?.value.length" align="end"
            >{{ polygonFormGroup.get('name').value.length }}/50</mat-hint
          >
          <mat-error *ngIf="f.name.errors?.required">{{ 'Name is required' | translate }}</mat-error>
          <mat-error *ngIf="f.name.errors?.duplicate">{{
            'There is already another zone with this name' | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="selected === optionZones" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 firstDiv pLeft">
          <mat-label>{{ 'MaxOccupation' | translate }}</mat-label>
          <input
            title="{{ 'MaxOccupation' | translate }}"
            matInput
            autocomplete="off"
            formControlName="maxOccupation"
            type="number"
            onkeydown="return event.keyCode !== 69"
            min="1"
            max="99999"
            step="1"
            class="p-1"
            spellcheck="false"
            required
          />
          <mat-error *ngIf="f.maxOccupation.errors?.required">{{ 'maxOccupancy is required' | translate }}</mat-error>
          <mat-error *ngIf="f.maxOccupation.errors?.min">{{
            'Max. occupation should be higher than 0' | translate
          }}</mat-error>
          <mat-error *ngIf="f.maxOccupation.errors?.max">{{
            'Value is too high. Please use a lower number' | translate
          }}</mat-error>
        </mat-form-field>
        <div
          *ngIf="selected === optionSpaces && !editing"
          style="float: right; font-weight: bolder; padding-left: 1%; padding-right: 10%"
        >
          {{ 'CreateAreaInstructions' | translate }}
        </div>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
          <button
            mat-button
            class="modalCancel text-primary"
            style="text-decoration: none !important"
            (click)="closeModal()"
            type="button"
          >
            {{ 'Cancel' | translate }}
          </button>
          <button *ngIf="canWriteAreas || canWriteZones" mat-button class="modalButton bg-primary" type="submit">
            {{ 'Save' | translate }}
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</ng-template>
