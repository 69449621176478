type MeasureType = 'co2' | 'humidity' | 'temperature';

export function isMeasureType(option: string): option is MeasureType {
  return ['co2', 'humidity', 'temperature'].includes(option);
}

type MeasureStatus = 'none' | 'warning' | 'alarm' | 'ok';
type MeasureRange = {
  id?: number;
  monthStart: number;
  monthEnd: number;
  min: number;
  max: number;
  status: MeasureStatus;
};

const ranges: Record<MeasureType, MeasureRange[]> = {
  // NOTE: Value ranges are left-inclusive and right exclusive (min <= x < max)
  //       Date ranges are left-right-inclusive (min <= x <= max)
  co2: [
    { monthStart: 1, monthEnd: 12, min: 400, max: 700, status: 'ok' },
    { monthStart: 1, monthEnd: 12, min: 700, max: 800, status: 'warning' },
    { monthStart: 1, monthEnd: 12, min: 800, max: Infinity, status: 'alarm' },
  ],
  humidity: [
    // Summer
    { id: 0, monthStart: 4, monthEnd: 9, min: 0.0, max: 40.0, status: 'alarm' },
    { id: 1, monthStart: 4, monthEnd: 9, min: 40.0, max: 45.0, status: 'warning' },
    { id: 2, monthStart: 4, monthEnd: 9, min: 45.0, max: 60.0, status: 'ok' },
    { id: 3, monthStart: 4, monthEnd: 9, min: 60.0, max: 65.0, status: 'warning' },
    { id: 4, monthStart: 4, monthEnd: 9, min: 65.0, max: 100, status: 'alarm' },
    // Winter
    { id: 0, monthStart: 10, monthEnd: 3, min: 0.0, max: 35.0, status: 'alarm' },
    { id: 1, monthStart: 10, monthEnd: 3, min: 35.0, max: 40.0, status: 'warning' },
    { id: 2, monthStart: 10, monthEnd: 3, min: 40.0, max: 50.0, status: 'ok' },
    { id: 3, monthStart: 10, monthEnd: 3, min: 50.0, max: 55.0, status: 'warning' },
    { id: 4, monthStart: 10, monthEnd: 3, min: 55.0, max: 100.0, status: 'alarm' },
  ],
  temperature: [
    // Summer
    { monthStart: 4, monthEnd: 9, min: -273.0, max: 21.0, status: 'alarm' },
    { monthStart: 4, monthEnd: 9, min: 21.0, max: 23.0, status: 'warning' },
    { monthStart: 4, monthEnd: 9, min: 23.0, max: 25.0, status: 'ok' },
    { monthStart: 4, monthEnd: 9, min: 25.0, max: 26.0, status: 'warning' },
    { monthStart: 4, monthEnd: 9, min: 26.0, max: Infinity, status: 'ok' },
    // Winter
    { monthStart: 10, monthEnd: 3, min: 0.0, max: 19.0, status: 'alarm' },
    { monthStart: 10, monthEnd: 3, min: 19.0, max: 21.0, status: 'warning' },
    { monthStart: 10, monthEnd: 3, min: 21.0, max: 23.0, status: 'ok' },
    { monthStart: 10, monthEnd: 3, min: 23.0, max: 25.0, status: 'warning' },
    { monthStart: 10, monthEnd: 3, min: 25.0, max: Infinity, status: 'alarm' },
  ],
};

function getRangeByType(rangeType: string): MeasureRange[] | null {
  return ranges[rangeType] ?? null;
}

export class Co2Measure {
  value: number;
  unit: string;
  type: MeasureType;
  label: string;

  getStatus(time = new Date()): MeasureStatus {
    const ranges = getRangeByType(this.type);
    if (!ranges) {
      return 'none';
    }

    const range = ranges.find((r) => {
      if (this.value >= r.min && this.value < r.max) {
        const month = time.getMonth();
        if (r.monthStart < r.monthEnd) {
          return month >= r.monthStart && month <= r.monthEnd;
        }
        return month >= r.monthStart || month <= r.monthEnd;
      }
      return false;
    });

    return range?.status ?? 'none';
  }

  constructor(_value: number, _unit: string, _type: MeasureType) {
    this.value = _value;
    this.unit = _unit;
    this.type = _type;
    this.label = _type;
  }
}
