import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { webSocket, WebSocketSubject, WebSocketSubjectConfig } from 'rxjs/webSocket';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { Constants } from 'src/app/models/Constants.const';
import { ContinuousReservation } from 'src/app/models/reservation/ContinuousReservation';
import { Reservation } from 'src/app/models/reservation/Reservation.class';
import { ReservationRegistry } from 'src/app/models/reservation/ReservationRegistry.class';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { ReservationUsersFiltered } from 'src/app/models/reservation/ReservationUsersFiltered.class';
import { WebsocketMsg } from 'src/app/models/websocket/WebsocketMsg.class';

import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { HttpService } from '../common/http.service';
import { HttpMethods } from './../../models/HttpMethods.enum';
import { ReservationConfigurationByBuilding } from './../../models/reservation/ReservationConfigurationByBuilding.class';
import { AppConstants } from 'src/app/shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class ReservationService {
  private socket: WebSocketSubject<WebsocketMsg>;
  keepaliveTimerId: NodeJS.Timeout;

  constructor(private httpService: HttpService, private authService: AuthService) {
    this.socket = webSocket(this.getWebsocketConfiguration());
  }

  private getWebsocketConfiguration(): WebSocketSubjectConfig<WebsocketMsg> {
    const token = this.authService.getAccessToken();
    const ssoId = this.authService.getUnregisteredUser().ssoId;

    const url = `${environment.websocket_protocol}${environment.reservations_backend_url}v1/realTime?token=${token}&sso_id=${ssoId}`;
    return environment.websocket_keepalive
      ? {
          url: url,
          openObserver: {
            next: () => {
              this.keepaliveTimerId = setInterval(() => {
                this.socket.next({
                  type: Constants.WEBSOCKET_KEEPALIVE_TYPE,
                  data: Constants.WEBSOCKET_KEEPALIVE_MESSAGE,
                });
              }, environment.websocket_keepalive_interval);
            },
          },
          closeObserver: {
            next() {
              clearInterval(this.keepaliveTimerId);
            },
          },
        }
      : { url: url };
  }

  requestToReservations(type, method, body?, headers?): Observable<any> {
    const url = `${environment.reservations_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequest(protocol + url, type, method, body, headers);
  }

  private requestToHabitatExcel(method: string): Observable<Blob> {
    const url = `${environment.reservations_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequestExcel(protocol + url, method);
  }

  connectToWebsocket(): Observable<WebsocketMsg> {
    return this.socket.pipe(retry(5));
  }

  getUsersWithoutPage(): Observable<any[]> {
    const method = 'v2/reservationUsers';
    return this.requestToReservations(HttpMethods.GET, method, {});
  }

  getUsers(page: number, perPage: number): Observable<any> {
    const method = 'v2/reservationUsers?page=' + page + '&perPage=' + perPage;
    return this.requestToReservations(HttpMethods.GET, method, {});
  }

  getUsersFiltered(
    page: number,
    perPage: number,
    searchValue: any,
    roles: any,
    sortColumns: any,
    sortOrder: any,
    count = true
  ): Observable<ReservationUsersFiltered> {
    let method = 'v2/reservationUsers/filtered?count=' + count + '&page=' + page + '&perPage=' + perPage;
    if (searchValue) {
      method = method + '&term=' + searchValue;
    }
    if (roles?.length) {
      method = method + '&roles=' + roles;
    }
    if (sortColumns) {
      method = method + '&sortColumn=' + sortColumns;
    }
    if (sortOrder) {
      method = method + '&sortOrder=' + sortOrder;
    }

    return this.requestToReservations(HttpMethods.GET, method, {});
  }

  /**
   * Gets the configuration of the reservation module All buildings (morning-afternoon start hours, checkin window...)
   * @returns Configuration of reservation's module All buildings
   */
  getModuleConfigAllBuildings(): Observable<ReservationConfigurationByBuilding[]> {
    return this.requestToReservations(HttpMethods.GET, `v1/buildingConfig`, {});
  }

  /**
   * Gets the configuration of the reservation module by building (morning-afternoon start hours, checkin window...)
   * @returns Configuration of reservation's module by building
   */
  getModuleConfigByBuilding(buildingId: string): Observable<ReservationConfigurationByBuilding> {
    const method = `v1/buildingConfig/` + buildingId;
    return this.requestToReservations(HttpMethods.GET, method, {});
  }

  /**
   * Modify the configuration of the reservation module by building (morning-afternoon start hours, checkin window...)
   * @returns Configuration of reservation's module by building modified
   */
  modifyModuleConfigByBuilding(reservationModuleConfigByBuilding: ReservationConfigurationByBuilding): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, AuditFunctionalities.ModifyReservationsModuleConfig);
    return this.requestToReservations(
      HttpMethods.PATCH,
      `v1/buildingConfig`,
      reservationModuleConfigByBuilding,
      headers
    );
  }

  getReservableSpaces(floor: number, subsectionID?: number, areaID?: number): Observable<ReservationSpace[]> {
    let method = 'v1/reservableSpaces?floor=' + floor;

    if (subsectionID) {
      method += '&subsection=' + subsectionID;
    }
    if (areaID) {
      method += '&area=' + areaID;
    }
    return this.requestToReservations(HttpMethods.GET, method).pipe(
      map((res) => {
        const spaces = [];
        res.forEach((element) => {
          const space = new ReservationSpace(element);
          spaces.push(space);
        });
        return spaces;
      })
    );
  }

  modifyReservableSpace(space, spaceId, functionality: string): Observable<any> {
    let method = 'v1/reservableSpaces/' + spaceId;
    if (functionality == AuditFunctionalities.ModifyMasiveReservableSpace) {
      method = method + '?refresh=' + false;
    }
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.PATCH, method, space, headers);
  }

  modifyReservationUser(
    employeeNumber,
    role,
    functionality: string = AuditFunctionalities.ModifyReservationUser
  ): Observable<any> {
    const method = 'v2/reservationUsers/' + employeeNumber;
    const body = { role: role };
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.PATCH, method, body, headers);
  }

  getReservations(
    floorID?: number,
    page = 1,
    perPage = 100,
    status?: string,
    useType?: string,
    owner?: string,
    startDate?: string,
    endDate?: string,
    buildingID?: number,
    spaceCode?: string,
    local?: boolean,
    count = true
  ) {
    let method = `v1/reservations?page=${page}&perPage=${perPage}&count=${count}`;
    if (buildingID) {
      method = method + '&building=' + buildingID;
    }
    if (spaceCode) {
      method = method + '&spaceCode=' + spaceCode;
    }
    if (local) {
      method = method + '&local=' + local;
    }

    if (floorID) {
      method = method + '&floor=' + floorID;
    }
    if (status) {
      method = method + '&status=' + status;
    }
    if (useType) {
      method = method + '&useType=' + useType;
    }
    if (owner) {
      method = method + '&owner=' + owner;
    }
    if (startDate) {
      method = method + '&from=' + encodeURIComponent(startDate);
    }
    if (endDate) {
      method = method + '&to=' + encodeURIComponent(endDate);
    }

    return this.requestToReservations(HttpMethods.GET, method).pipe(
      map((res: Array<any> | { total: number; reservations: Array<any> }) => {
        const reservations = (Array.isArray(res) ? res : res.reservations).map((element) => {
          return new Reservation(element);
        });
        const total = Array.isArray(res) ? res.length : res.total;
        return { total, reservations };
      })
    );
  }

  getReservationRegistry(reservation: number, local?: boolean): Observable<ReservationRegistry[]> {
    let method = `v1/reservations/${reservation}/registry`;
    if (local) {
      method = method + '?local=' + local;
    }
    return this.requestToReservations(HttpMethods.GET, method, {});
  }

  getReservationsByUser(
    userID: number,
    status: string,
    startDate: string,
    endDate: string,
    page = 1,
    perPage = 100
  ): Observable<Reservation[]> {
    const method = `v1/reservations?page=${page}&perPage=${perPage}&owner=${userID}&status=${status}&from=${encodeURIComponent(
      startDate
    )}&to=${encodeURIComponent(endDate)}`;
    return this.requestToReservations(HttpMethods.GET, method).pipe(
      map((res: Array<any> | { count: number; reservations: Array<any> }) => {
        return (Array.isArray(res) ? res : res.reservations).map((element) => {
          return new Reservation(element);
        });
      })
    );
  }

  getRservationSpaceInfo(spaceID): Observable<ReservationSpace> {
    const method = 'v1/reservableSpaces/' + spaceID;
    return this.requestToReservations(HttpMethods.GET, method).pipe(map((res) => new ReservationSpace(res)));
  }

  getSpaceReservation(spaceID, status): Observable<Reservation[]> {
    const method = 'v1/reservableSpaces/' + spaceID + '/reservations?status=' + status;
    return this.requestToReservations(HttpMethods.GET, method).pipe(
      map((res) => {
        const reservations = [];
        res.forEach((element) => {
          const reservation = new Reservation(element);
          reservations.push(reservation);
        });
        return reservations;
      })
    );
  }

  getApproverResevationSpaces(userID): Observable<ReservationSpace[]> {
    const method = 'v1/reservableSpaces?approver=' + userID;
    return this.requestToReservations(HttpMethods.GET, method).pipe(
      map((res) => {
        const spaces = [];
        res.forEach((element) => {
          const space = new ReservationSpace(element);
          spaces.push(space);
        });
        return spaces;
      })
    );
  }

  createReservationSpace(reservationSpace, functionality: string): Observable<any> {
    let method = 'v1/reservableSpaces';
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.CreateMasiveReservableSpaces) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.POST, method, reservationSpace, headers);
  }

  deleteReservationSpace(spaceID, functionality: string): Observable<any> {
    let method = 'v1/reservableSpaces/' + spaceID;
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.DeleteMasiveReservableSpace) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.DELETE, method, {}, headers);
  }

  getNttdataUsers(userName): Observable<any> {
    const method = 'v1/everis/searchEmployees?query=' + userName;
    return this.requestToReservations(HttpMethods.GET, method, {});
  }

  getReservationUser(employeeNumber): Observable<any> {
    const method = 'v2/reservationUsers/user?userId=' + employeeNumber;
    return this.requestToReservations(HttpMethods.GET, method, {});
  }

  createReservationUser(user, functionality: string = AuditFunctionalities.CreateReservationUser): Observable<any> {
    const method = 'v2/reservationUsers';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.POST, method, user, headers);
  }

  addExchangeEmail(spaceID, exchangeEmail, functionality: string): Observable<any> {
    let method = 'v1/reservableSpaces/exchange/' + spaceID;
    const body = { emailAddress: exchangeEmail };
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.AddMasiveReservableSpaceExchangeEmail) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.PUT, method, body, headers);
  }

  deleteExchangeEmail(spaceID: number, functionality: string): Observable<any> {
    let method = 'v1/reservableSpaces/exchange/' + spaceID;
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.DeleteMasiveReservableSpaceExchangeEmail) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.DELETE, method, {}, headers).pipe(
      // On error 404, just ignore it and return an empty observable (we tried to delete an exchange email for a reservable space which doesn't have an associate email)
      catchError((error: HttpErrorResponse) => {
        if (error.status === 404) {
          return of({});
        } else {
          return throwError(error);
        }
      })
    );
  }

  getExchangeEmail(spaceID): Observable<any> {
    const method = 'v1/reservableSpaces/exchange/' + spaceID;
    return this.requestToReservations(HttpMethods.GET, method, {});
  }

  createContinuousReservation(
    ContinuousReservation: ContinuousReservation,
    functionality: string = AuditFunctionalities.AddContinuousReservation
  ): Observable<any> {
    const method = 'v2/continuousReservations';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.POST, method, ContinuousReservation.toBackenFormat(), headers);
  }

  modifyContinuousReservation(
    ContinuousReservation: ContinuousReservation,
    functionality: string = AuditFunctionalities.ModifyContinuousReservation
  ): Observable<any> {
    const method = 'v2/continuousReservations/' + ContinuousReservation.id;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.PATCH, method, ContinuousReservation.toBackenModifyFormat(), headers);
  }

  deleteContinuousReservation(
    ContinuousReservationID: number,
    functionality: string = AuditFunctionalities.DeleteContinuousReservation
  ): Observable<any> {
    const method = 'v2/continuousReservations/' + ContinuousReservationID;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToReservations(HttpMethods.DELETE, method, {}, headers);
  }

  getContinuousReservation(
    page: number,
    perPage?: number,
    sortOrder?: string,
    sortColumn?: string,
    userID?: number,
    code?: number,
    status?: string,
    from?: number,
    to?: number,
    count = true
  ): Observable<any> {
    let method = 'v2/continuousReservations' + '?page=' + page + '&perPage=' + perPage;
    if (sortColumn) {
      method = method + '&sortColumn=' + sortColumn;
    }
    if (sortOrder) {
      method = method + '&sortOrder=' + sortOrder;
    }
    if (userID) {
      method = method + '&user=' + userID;
    }
    if (code) {
      method = method + '&spaceCode=' + code;
    }
    if (status) {
      method = method + '&status=' + status;
    }
    if (from) {
      method = method + '&from=' + from;
    }
    if (to) {
      method = method + '&to=' + to;
    }
    if (count) {
      method = method + '&count=' + count;
    }
    return this.requestToReservations(HttpMethods.GET, method, {});
  }

  getReservationsAuditExcelExport(
    lang = 'en',
    floor?: any,
    useType?: any,
    user?: any,
    status?: any,
    from?: any,
    to?: any,
    spaceCode?: string,
    local?: boolean
  ): Observable<any> {
    let method = 'v1/excelReservations' + '?language=' + lang;

    if (floor) {
      method = method + '&floor=' + floor;
    }
    if (useType) {
      method = method + '&useType=' + useType;
    }
    if (user) {
      method = method + '&userId=' + user;
    }
    if (status) {
      method = method + '&status=' + status;
    }
    if (from) {
      method = method + '&from=' + encodeURIComponent(from);
    }
    if (to) {
      method = method + '&to=' + encodeURIComponent(to);
    }
    if (spaceCode) {
      method = method + '&spaceCode=' + spaceCode;
    }
    if (local) {
      method = method + '&local=' + local;
    }
    return this.requestToHabitatExcel(method);
  }

  getCReservationsExcelExport(
    lang = 'en',
    userID?: number,
    code?: number,
    from?: number,
    to?: number
  ): Observable<any> {
    let method = 'v1/excelContinuousReservations' + '?lenguage=' + lang;

    if (userID) {
      method = method + '&userId=' + userID;
    }
    if (code) {
      method = method + '&spaceCode=' + code;
    }

    if (from) {
      method = method + '&from=' + encodeURIComponent(from);
    }
    if (to) {
      method = method + '&to=' + encodeURIComponent(to);
    }

    return this.requestToHabitatExcel(method);
  }
}
