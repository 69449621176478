<div class="co2-panel">
  <div class="row pb-3 m-0">
    <div class="col-md-8 col-sm-12 col-12">
      <mat-card class="title-panel">
        <mat-card-content>
          <div class="row">
            <div class="col-2 mx-0 my-auto px-0">
              <a routerLink="/">
                <img src="assets/images/logo_reducido.png" alt="habität" class="img-fluid" />
              </a>
            </div>
            <h1 class="col-10 col-md-9 text-center my-auto">{{ 'Calidad del aire interior' | translate }}</h1>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="col-md-4 col-sm-12 col-12 date-panel">
      <mat-card class="time-panel">
        <mat-card-content class="text-center">
          <time [attr.datetime]="now$ | async">
            <div class="time">{{ now$ | async | date : 'hh:mm:ss a' }}</div>
            <div class="date">{{ now$ | async | date : 'dd/MM/yyyy' }}</div>
          </time>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-md-8 col-sm-12 col-12">
      <mat-card class="text-center p-3">
        <mat-form-field>
          <mat-label>
            {{ 'Selecciona zona' | translate }}
          </mat-label>
          <mat-select
            [(ngModel)]="selectedAreaId"
            (ngModelChange)="handleAreaSelected($event)"
            id="area-selector"
            name="area"
            class="p-1 text-center"
          >
            <mat-option *ngFor="let area of areas$ | async" [value]="area.id">
              {{ area.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card>
      <mat-card class="p-3">
        <div class="indicators-panel">
          <div *ngIf="!selectedAreaId; else selectedAreaBlock">
            {{ 'Please select an area from the box above' | translate }}
          </div>
          <ng-template #selectedAreaBlock>
            <div *ngFor="let measure of measures | async" class="indicator">
              <div class="indicator__circle" [ngClass]="indicatorClass(measure)">
                <div class="indicator__value">{{ measure.value }}</div>
                <div class="indicator__unit">{{ measure.unit }}</div>
              </div>
              <div class="indicator__label">{{ measure.label | translate }}</div>
            </div>
          </ng-template>
        </div>
      </mat-card>
    </div>
    <div class="col-md-4 col-sm-12 col-12">
      <mat-card class="p-3 col-12">
        <form class="date-form">
          <mat-label>{{ 'Historical readings' | translate }}</mat-label>
          <app-select-dates
            (startDateSelected)="startDateSelected($event)"
            (endDateSelected)="endDateSelected($event)"
          ></app-select-dates>
          <button
            *ngIf="!downloadingExcel"
            mat-button
            type="button"
            (click)="handleExportButtonClick()"
            [disabled]="!canExportData"
          >
            {{ 'ExcelExport' | translate }}
          </button>
          <div class="loader mx-auto" *ngIf="downloadingExcel"></div>
        </form>
      </mat-card>
    </div>
  </div>
</div>
