import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { OAuthModuleConfig, OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthService } from '../services/auth/auth.service';
import { StorageVariables } from './../models/StorageVariables.enum';
import { AppConstants } from '../shared/AppConstants';

@Injectable()
export class CustomHeadersInterceptor implements HttpInterceptor {
  enviroment = environment;
  constructor(
    private errorHandler: OAuthResourceServerErrorHandler,
    private authService: AuthService,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {}

  private checkUrl(url: string): boolean {
    return !!this.moduleConfig.resourceServer.allowedUrls?.some((u) => url.startsWith(u));
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    if (!this.moduleConfig) return next.handle(req);
    if (!this.moduleConfig.resourceServer) return next.handle(req);
    if (!this.moduleConfig.resourceServer.allowedUrls) return next.handle(req);
    if (!this.checkUrl(url)) return next.handle(req);
    if (!this.authService.getUnregisteredUser()) return next.handle(req);

    const unregisteredUserInfo = this.authService.getUnregisteredUser();

    let headers = req.headers.set('X-Name', unregisteredUserInfo.name);
    headers = headers.set('X-Email', unregisteredUserInfo.email);
    headers = headers.set('X-SSO-Id', unregisteredUserInfo.ssoId);
    if (this.enviroment.collectAuditData && headers.getAll(AppConstants.AUDIT_FUNCTION_HEADER)) {
      const functionalities = tryJSON(sessionStorage.getItem(StorageVariables.FUNCTIONALITIES), []);
      const functionalityID = functionalities?.find(
        (item) => item.name == headers.getAll(AppConstants.AUDIT_FUNCTION_HEADER)
      )?.id;
      if (functionalityID) {
        headers = headers.delete(
          AppConstants.AUDIT_FUNCTION_HEADER,
          headers.getAll(AppConstants.AUDIT_FUNCTION_HEADER)
        );
        headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionalityID.toString());
      }
      const now = new Date().getTime();
      headers = headers.set('x-audit-user', unregisteredUserInfo.ssoId);
      headers = headers.set('x-audit-timestamp', now.toString());
    }

    req = req.clone({ headers });
    return next.handle(req).pipe(catchError((err) => this.errorHandler.handleError(err)));
  }
}

function tryJSON<T>(data: string, fallback?: T): T {
  try {
    return JSON.parse(data);
  } catch (err) {
    console.error(err);
    return fallback;
  }
}
