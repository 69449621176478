import { OccupationNotificationType } from './OccupationNotificationType.enum';
import { OccupationNotificationConfig } from './OccupationNotificationConfig.class';
import { SidebarNotification } from './SidebarNotification.interface';

export class OccupationNotification implements SidebarNotification {
  constructor(configured: OccupationNotificationConfig, msgTriggered) {
    this.id = msgTriggered.id;
    this.name = configured.name;
    this.date = msgTriggered?.date;
    this.notificationConfig = configured;
    if (configured.subtype) {
      this.icon = OccupationNotification.occupationtypeIcon.find(
        (item) => item.type === OccupationNotificationType[configured.subtype]
      ).icon;
    }
    if (configured.lastReadDate) {
      const notifDate = new Date(this.date);
      const lastRead = new Date(configured.lastReadDate);
      this.read = notifDate <= lastRead;
    }
  }

  static occupationtypeIcon = [
    { type: OccupationNotificationType.HigherCapacity, icon: 'arrowhead-up-outline' },
    { type: OccupationNotificationType.LowerCapacity, icon: 'arrowhead-down-outline' },
  ];
  id: number;
  name: string;
  subject = '';
  icon: string;
  read = false;
  date: string;
  notificationConfig: OccupationNotificationConfig;
}
