export class ChartOptionData {
  title: string;
  subtitle: string;
  value: number;
  total: number;
  color: string;
  inlegend: boolean;

  getPercentage(): number {
    let percentage = 0;
    if (this.value && this.total && this.total !== 0) {
      percentage = (this.value / this.total) * 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    if (percentage > 100) {
      percentage = 100;
    }
    return percentage;
  }
}
