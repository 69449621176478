import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '../auth/auth.guard';
import { AdvancedReportsComponent } from './advanced-reports/advanced-reports.component';
import { AuditComponent } from './audit/changes-audit/audit.component';
import { ReservationAuditComponent } from './audit/reservation-audit/reservation-audit.component';
import { Co2Component } from './co2/co2.component';
import { co2CanActivateFn } from './co2/co2.guard';
import { ContinuousReservationsComponent } from './configuration/continuous-reservations/continuous-reservations.component';
import { GroupConfigurationComponent } from './configuration/group-configuration/group-configuration.component';
import { ResourcesConfigurationComponent } from './configuration/resources-configuration/resources-configuration.component';
import { RolesConfigurationComponent } from './configuration/roles-configuration/roles-configuration.component';
import { SpaceConfigurationComponent } from './configuration/space-configuration/space-configuration.component';
import { SubsectionsConfigurationComponent } from './configuration/subsections-configuration/subsections-configuration.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PagesComponent } from './pages.component';
import { ProfileComponent } from './profile/profile.component';
import { RealtimeComponent } from './realtime/realtime.component';
import { UsersComponent } from './users/users.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'realtime',
        component: RealtimeComponent,
        data: { module: 1 },
      },
      {
        path: 'advancedreport',
        component: AdvancedReportsComponent,
        data: { module: 3 },
      },
      {
        path: 'spaceconfiguration',
        component: SpaceConfigurationComponent,
        data: { module: 4 },
      },
      {
        path: 'subsectionconfiguration',
        component: SubsectionsConfigurationComponent,
        data: { module: 4 },
      },
      {
        path: 'continuousreservations',
        component: ContinuousReservationsComponent,
        data: { module: 4 },
      },
      {
        path: 'groupconfiguration',
        component: GroupConfigurationComponent,
        data: { module: 4 },
      },
      {
        path: 'rolesconfiguration',
        component: RolesConfigurationComponent,
        data: { module: 4 },
      },
      {
        path: 'resourcesconfiguration',
        component: ResourcesConfigurationComponent,
        data: { module: 4 },
      },
      {
        path: 'users',
        component: UsersComponent,
        data: { module: 5 },
      },
      {
        path: 'audit',
        component: AuditComponent,
        data: { module: 6 },
      },
      {
        path: 'reservationaudit',
        component: ReservationAuditComponent,
        data: { module: 6 },
      },
      { path: 'profile', component: ProfileComponent },
      { path: 'notifications', component: NotificationsComponent },
      { path: '', redirectTo: '/realtime', pathMatch: 'full' },
    ],
  },
  {
    path: 'co2',
    component: Co2Component,
    canActivate: [co2CanActivateFn],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
