import { Injectable } from '@angular/core';

/**
 * Service that stores the different modules versions and provide methods to recover and update the version values
 * */
@Injectable({
  providedIn: 'root',
})
export class VersionsService {
  habitatVersion: string;
  conteoVersion: string;
  esiteVersion: string;
  reservationsVersion: string;

  constructor() {
    this.habitatVersion = null;
    this.conteoVersion = null;
    this.esiteVersion = null;
    this.reservationsVersion = null;
  }

  setHabitatVersion(habitatVersion: string): void {
    this.habitatVersion = habitatVersion;
  }

  getHabitatVersion(): string {
    return this.habitatVersion;
  }

  setConteoVersion(conteoVersion: string): void {
    this.conteoVersion = conteoVersion;
  }

  getConteoVersion(): string {
    return this.conteoVersion;
  }

  setEsiteVersion(esiteVersion: string): void {
    this.esiteVersion = esiteVersion;
  }

  getEsiteVersion(): string {
    return this.esiteVersion;
  }

  setReservationsVersion(reservationsVersion: string): void {
    this.reservationsVersion = reservationsVersion;
  }

  getReservationsVersion(): string {
    return this.reservationsVersion;
  }
}
