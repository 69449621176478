export enum StorageVariables {
  SESSION_CHANGE = 'hb_hf_sess',
  LOGGED_USER = 'hb_loggedUser',
  FUNCTIONALITIES = 'hb_functionalities',
  FLOOR_IMAGE = 'hb_floorImage',
  FAVORITE_TIMEZONE = 'hb_selectedBuildingTimezone',
  FAVORITE_GROUP = 'hb_selectedGroup',
  FAVORITE_BUILDING = 'hb_selectedBuilding',
  FAVORITE_FLOOR = 'hb_selectedFloor',
  FAVORITE_AREA = 'hb_selectedArea',
}
