import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ResourceIcon } from '../../models/esite/ResourceIcon.class';

@Injectable({
  providedIn: 'root',
})
export class ResourceIconsService {
  getResourceIcons(): Observable<ResourceIcon[]> {
    return of([
      {
        id: 1,
        code: 'hdd',
        name: 'HDD',
      },
      {
        id: 2,
        code: 'display',
        name: 'Display',
      },
      {
        id: 3,
        code: 'laptop',
        name: 'Laptop',
      },
      {
        id: 4,
        code: 'lamp',
        name: 'Lamp',
      },
      {
        id: 5,
        code: 'lightbulb',
        name: 'Lightbulb',
      },
      {
        id: 6,
        code: 'key',
        name: 'Key',
      },
      {
        id: 7,
        code: 'tablet',
        name: 'Tablet',
      },
      {
        id: 8,
        code: 'tv',
        name: 'TV',
      },
      {
        id: 9,
        code: 'camera',
        name: 'Camera',
      },
      {
        id: 10,
        code: 'phone',
        name: 'Phone',
      },
      {
        id: 11,
        code: 'joystick',
        name: 'Joystick',
      },
      {
        id: 12,
        code: 'headset-vr',
        name: 'Headset VR',
      },
      {
        id: 13,
        code: 'mouse',
        name: 'Mouse',
      },
      {
        id: 14,
        code: 'printer',
        name: 'Printer',
      },
      {
        id: 15,
        code: 'ubuntu',
        name: 'Ubuntu',
      },
      {
        id: 16,
        code: 'server',
        name: 'Server',
      },
      {
        id: 17,
        code: 'usb-drive',
        name: 'USB Drive',
      },
      {
        id: 18,
        code: 'webcam',
        name: 'Webcam',
      },
      {
        id: 19,
        code: 'windows',
        name: 'Windows',
      },
      {
        id: 20,
        code: 'speaker',
        name: 'Speaker',
      },
      {
        id: 21,
        code: 'credit-card',
        name: 'Credit Card',
      },
      {
        id: 22,
        code: 'keyboard',
        name: 'Keyboard',
      },
      {
        id: 23,
        code: 'sim',
        name: 'Sim',
      },
      {
        id: 24,
        code: 'router',
        name: 'Router',
      },
      {
        id: 25,
        code: 'qr-code-scan',
        name: 'QR Code Scan',
      },
      {
        id: 26,
        code: 'device-ssd',
        name: 'Device SSD',
      },
      {
        id: 27,
        code: 'safe2',
        name: 'Safe',
      },
      {
        id: 28,
        code: 'projector',
        name: 'Projector',
      },
      {
        id: 29,
        code: 'hdmi',
        name: 'HDMI',
      },
      {
        id: 30,
        code: 'headphones',
        name: 'Headphones',
      },
      {
        id: 31,
        code: 'cup-hot',
        name: 'Cup Hot',
      },
      {
        id: 32,
        code: 'fuel-pump',
        name: 'Fuel Pump',
      },
      {
        id: 33,
        code: 'fan',
        name: 'Fan',
      },
      {
        id: 34,
        code: 'ev-front',
        name: 'EV Front',
      },
      {
        id: 35,
        code: 'ethernet',
        name: 'Ethernet',
      },
      {
        id: 36,
        code: 'easel',
        name: 'Easel',
      },
      {
        id: 37,
        code: 'device-hdd',
        name: 'Device HDD',
      },
      {
        id: 38,
        code: 'controller',
        name: 'Controller',
      },
      {
        id: 39,
        code: 'clipboard',
        name: 'Clipboard',
      },
      {
        id: 40,
        code: 'badge-4k',
        name: 'Badge 4K',
      },
      {
        id: 41,
        code: 'pc-display-horizontal',
        name: 'PC Display Horizontal',
      },
      {
        id: 42,
        code: 'pc-display',
        name: 'PC Display',
      },
      {
        id: 43,
        code: 'pc',
        name: 'PC',
      },
      {
        id: 44,
        code: 'camera-video',
        name: 'Video',
      },
      {
        id: 45,
        code: 'microsoft',
        name: 'Microsoft',
      },
      {
        id: 46,
        code: 'book',
        name: 'Book',
      },
      {
        id: 47,
        code: 'apple',
        name: 'Apple',
      },
      {
        id: 48,
        code: 'android',
        name: 'Android',
      },
      {
        id: 49,
        code: 'qr-code',
        name: 'QR Code',
      },
      {
        id: 50,
        code: 'box',
        name: 'Box',
      },
      {
        id: 51,
        code: 'pencil',
        name: 'Pencil',
      },
      {
        id: 52,
        code: 'mic',
        name: 'Microphone',
      },
      {
        id: 53,
        code: 'volume-up',
        name: 'Volume Up',
      },
      {
        id: 54,
        code: 'car-front',
        name: 'Car',
      },
      {
        id: 55,
        code: 'lightning-charge',
        name: 'Lightning Charge',
      },
      {
        id: 56,
        code: 'wifi',
        name: 'Wifi',
      },
      {
        id: 57,
        code: 'globe',
        name: 'Globe',
      },
      {
        id: 58,
        code: 'thermometer-half',
        name: 'Thermometer',
      },
      {
        id: 59,
        code: 'trash2',
        name: 'Trash',
      },
    ]);
  }
}
