import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Building } from 'src/app/models/habitat/Building.class';
import { Group } from 'src/app/models/habitat/Group.class';
import { AgrupacionService } from 'src/app/services/backend/agrupacion.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { GroupService } from 'src/app/services/pages/group.service';

@Component({
  selector: 'app-group-configuration-filter',
  templateUrl: './group-configuration-filter.component.html',
  styleUrls: ['./group-configuration-filter.component.css'],
})
export class GroupConfigurationFilterComponent implements OnInit {
  groups: Group[];
  buildings: Building[] = [];
  buildingsCheckbox: { id: any; name: string; checked: boolean; data: any; disabled: boolean }[] = [];
  selectedGroup: Group;
  selectedGroupCombo: Group;
  selectedGroup2: Group;
  selectedGroup2Combo: Group;
  selectedGroup3: Group;
  selectedGroup3Combo: Group;
  showNextCombo = false;
  showNextCombo2 = false;
  groups2: Group[];
  groups3: Group[];
  combo2 = '';
  combo3 = '';
  auxBuildCheck: number[];
  deleteBuildingsChecks = new Subject<any>();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private habitatService: HabitatService,
    private agrupacionBackService: AgrupacionService,
    public groupService: GroupService
  ) {
    this.agrupacionBackService.getGroupList().subscribe((response) => {
      this.groups = response;
    });
  }

  ngOnInit(): void {
    this.initFilter();
    this.groupService.curReloadGroupList.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.agrupacionBackService.getGroupList().subscribe((response) => {
        this.groups = response;
        this.initFilter();
        this.showNextCombo = false;
        this.showNextCombo2 = false;
      });
    });
  }

  initFilter(): void {
    this.habitatService.getBuildings().subscribe((buildings: Building[]) => {
      this.buildings = buildings;
      this.buildingsCheckbox = [];
      this.buildings.forEach((building) => {
        this.buildingsCheckbox.push({
          id: building.id,
          name: building.name,
          checked: false,
          disabled: false,
          data: building,
        });
      });
    });
  }

  changeValues(): void {
    this.auxBuildCheck = [];
    this.buildingsCheckbox = [];
    this.showNextCombo = false;
    this.showNextCombo2 = false;
    this.agrupacionBackService.getGroupById(this.selectedGroupCombo.id).subscribe((response) => {
      this.selectedGroup = response;
      if (this.selectedGroup.final) {
        this.buildingsCheckbox = [];
        this.buildings.forEach((building) => {
          const included = this.selectedGroup.children.includes(building.id);
          this.buildingsCheckbox.push({
            id: building.id,
            name: building.name,
            checked: false,
            disabled: !included,
            data: building,
          });
        });
        this.setSelectedBuildings();
      } else {
        this.combo2 = this.selectedGroup.subgroupTitle;
        this.showNextCombo = true;
        this.groups2 = [];
        this.groups.forEach((group) => {
          if (this.selectedGroup.children.includes(Number(group.id))) {
            this.groups2.push(group);
          }
        });
        this.auxBuildCheck = [];
        this.buildingsCheckbox = [];
        this.getBuildingsBySons(this.selectedGroup.children, 1);
        this.deleteBuildingsChecks.subscribe((item) => {
          if (item == 1) {
            this.buildingsCheckbox = [];
            this.buildings.forEach((build) => {
              const included = this.auxBuildCheck.includes(build.id);
              this.buildingsCheckbox.push({
                id: build.id,
                name: build.name,
                checked: false,
                disabled: !included,
                data: build,
              });
            });
            this.setSelectedBuildings();
          }
        });
      }
    });
  }

  changeValues2(): void {
    this.auxBuildCheck = [];
    this.buildingsCheckbox = [];
    this.showNextCombo2 = false;
    this.agrupacionBackService.getGroupById(this.selectedGroup2Combo.id).subscribe((response) => {
      this.selectedGroup2 = response;
      if (this.selectedGroup2.final) {
        this.buildingsCheckbox = [];
        this.buildings.forEach((building) => {
          const included = this.selectedGroup2.children.includes(building.id);
          this.buildingsCheckbox.push({
            id: building.id,
            name: building.name,
            checked: false,
            disabled: !included,
            data: building,
          });
        });
        this.setSelectedBuildings();
      } else {
        this.combo3 = this.selectedGroup2.subgroupTitle;
        this.showNextCombo2 = true;
        this.groups3 = [];
        this.groups.forEach((group) => {
          if (this.selectedGroup2.children.includes(Number(group.id))) {
            this.groups3.push(group);
          }
        });
        this.auxBuildCheck = [];
        this.buildingsCheckbox = [];
        this.getBuildingsBySons(this.selectedGroup2.children, 1);
        this.deleteBuildingsChecks.subscribe((item) => {
          if (item == 2) {
            this.buildingsCheckbox = [];
            this.buildings.forEach((build) => {
              const included = this.auxBuildCheck.includes(build.id);
              this.buildingsCheckbox.push({
                id: build.id,
                name: build.name,
                checked: false,
                disabled: !included,
                data: build,
              });
            });
            this.setSelectedBuildings();
          }
        });
      }
    });
  }

  changeValues3(): void {
    this.auxBuildCheck = [];
    this.buildingsCheckbox = [];
    this.agrupacionBackService.getGroupById(this.selectedGroup3Combo.id).subscribe((response) => {
      this.selectedGroup3 = response;
      if (this.selectedGroup3.final) {
        this.buildingsCheckbox = [];
        this.buildings.forEach((building) => {
          const included = this.selectedGroup3.children.includes(building.id);
          this.buildingsCheckbox.push({
            id: building.id,
            name: building.name,
            checked: false,
            disabled: !included,
            data: building,
          });
        });
        this.setSelectedBuildings();
      }
    });
  }

  getBuildingsBySons(children: number[], value: number): void {
    children.forEach((id) => {
      this.agrupacionBackService.getGroupById(id.toString()).subscribe((res) => {
        if (res.final) {
          this.buildings.forEach((building) => {
            if (res.children.includes(building.id)) {
              this.auxBuildCheck.push(building.id);
            }
            if (
              this.buildings.indexOf(building) == this.buildings.length - 1 &&
              children.indexOf(id) == children.length - 1
            ) {
              this.deleteBuildingsChecks.next(value);
            }
          });
        } else {
          this.getBuildingsBySons(res.children, value + 1);
          this.deleteBuildingsChecks.subscribe((response) => {
            if (response == value + 1 && children.indexOf(id) == children.length - 1) {
              this.deleteBuildingsChecks.next(value);
            }
          });
        }
      });
    });
  }

  get selectedBuildings() {
    return this.buildingsCheckbox.filter((item) => item.checked).map((item) => item.data);
  }

  setSelectedBuildings(): void {
    this.groupService.changeCurSelectedBuildings(this.selectedBuildings);
  }

  clearField() {
    this.selectedGroupCombo = null;
    this.initFilter();
  }
}
