import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationStart, Router } from '@angular/router';
import { MatNativeDateModule } from '@angular/material/core';
import { NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { OAuthModule, OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { LoggerModule } from 'ngx-logger';
import { MessageService } from 'primeng/api';
import { filter } from 'rxjs';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { authConfig } from './auth.config';
import { AuthModule } from './components/auth/auth.module';
import { PagesModule } from './components/pages/pages.module';
import { SharedModule } from './components/shared/shared.module';
import { CustomHeadersInterceptor } from './interceptors/custom-headers.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { SplitStrPipe } from './shared/pipes/split-str.pipe';

// ngx
// OAuth
// Material
// PrimeNG
// App
@NgModule({
  declarations: [AppComponent, SplitStrPipe],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AuthModule,
    PagesModule,
    SharedModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatNativeDateModule,
    /**
     * Auth library
     */
    OAuthModule.forRoot({
      resourceServer: {
        allowedUrls: [
          environment.backend_protocol + environment.habitat_backend_url,
          environment.backend_protocol + environment.conteo_backend_url,
          environment.backend_protocol + environment.esite_backend_url,
          environment.backend_protocol + environment.reservations_backend_url,
          environment.backend_protocol + environment.reservations_events_backend_url,
          environment.backend_protocol + environment.general_backend_url,
          environment.backend_protocol + environment.audit_backend_url,
        ],
        sendAccessToken: true,
      },
    }),
    LoggerModule.forRoot({
      serverLoggingUrl: `${environment.apiUrl}`,
      level: environment.logLevel,
      serverLogLevel: environment.serverLogLevel,
      disableConsoleLogging: environment.disableConsoleLogging,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: CustomHeadersInterceptor, multi: true },
    MessageService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
})
export class AppModule {
  /**
   * Configure Authentication Library object
   * @param oauthService angular-oauth2-oidc OAuthService
   */
  constructor(private oauthService: OAuthService, private router: Router) {
    this.configure();

    // this converts old hash based routing (#/) to normal URLs, this is for backward compatibility
    this.router.events
      .pipe(filter((event) => event instanceof NavigationStart && /^\/#/.test(event.url)))
      .subscribe((event: NavigationStart) => this.router.navigateByUrl(event.url.replace('/#', '')));
  }

  /**
   * Configures Authentication Library parameters
   */
  private configure(): void {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  }
}
