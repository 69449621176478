import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as pbi from 'powerbi-client';
import { MessageService } from 'primeng/api';
import { Building } from 'src/app/models/habitat/Building.class';

import { NotPermissionComponent } from '../not-permission/not-permission.component';
import { PowerBI } from './../../../models/auth/PowerBI.class';
import { Permission } from './../../../models/auth/Role.class';
import { User } from './../../../models/auth/User.class';
import { UserPermissions } from './../../../models/auth/UserPermissions.enum';
import { PowerBiService } from './../../../services/backend/powerbi.service';
import { GlobalService } from './../../../services/common/global.service';
import { InformesService } from './../../../services/pages/informes.service';
import { ScreenSizeService } from './../../../services/pages/screensize.service';
import { AppConstants } from '../../../shared/AppConstants';

// import { DateService } from './../../../services/common/date.service'; // TODO: JavaScript API PowerBi
declare let powerbi: any;

@Component({
  selector: 'app-advanced-reports',
  templateUrl: './advanced-reports.component.html',
  styleUrls: [],
})
export class AdvancedReportsComponent implements OnInit, OnDestroy {
  //declare powerbi: any;

  // Modules
  private readonly PUESTOS = 'Puestos';
  private readonly RESERVAS = 'Reservas';
  private readonly AFORO = 'Aforo';

  // // Filters
  private readonly BUILDING = 'Edificio';
  // private readonly PERIODO = 'Periodo'; // TODO: JavaScript API PowerBi
  // private readonly HORARIO = 'Horario';
  // private readonly FLOOR = 'Planta';
  // private readonly AREA = 'Área';
  // private readonly MODULE = 'Módulo';
  // private readonly ZONE = 'Zona';
  // private readonly CITY = 'Ciudad';
  // private readonly TOWER = 'Torre';
  // private readonly TYPE_SEAT = 'Tipo de Espacio';
  // private readonly CODE_SEAT = 'Código de Puesto';
  // private readonly TYPE_USE = 'Tipo de uso';
  // private readonly MINIMUM_TIME_OCUPACION = 'Tiempo mínimo ocupación';
  // private readonly MINIMUM_TIME_RESERVA = 'Duración Reserva';
  // private readonly DAY_WEEK = 'Día de la semana';

  // // Operators
  // private readonly SLICER = 'slicer';
  private readonly IN = 'In';
  // private readonly AND = 'And';
  // private readonly GREATER_THAN = 'GreaterThan';
  // private readonly GREATER_THAN_OR_EQUAL = 'GreaterThanOrEqual';
  // private readonly LESS_THAN = 'LessThan';
  // private readonly LESS_THAN_OR_EQUAL = 'LessThanOrEqual';
  private readonly BASIC_SCHEMA = 'https://powerbi.com/product/schema#basic';
  // private readonly ADVANCED_SCHEMA = 'https://powerbi.com/product/schema#advanced';

  // // Tables PUESTOS
  private readonly FT_PUESTOS_MAESTRA = 'FT_PUESTOS_MAESTRA';
  private readonly FT_PUESTOS_AGG = 'FT_PUESTOS_AGG';
  private readonly FT_PUESTOS_AGG_DAY = 'FT_PUESTOS_AGG_DAY';
  private readonly FT_PUESTOS_S_EDIFICIO = 'FT_PUESTOS_S_EDIFICIO';
  // private readonly FT_PUESTOS_S_PLANTA = 'FT_PUESTOS_S_PLANTA';
  // private readonly FT_PUESTOS_S_AREA = 'FT_PUESTOS_S_AREA';
  // private readonly FT_PUESTOS_S_MODULO = 'FT_PUESTOS_S_MODULO';
  // private readonly FT_PUESTOS_S_ZONA = 'FT_PUESTOS_S_ZONA';
  // private readonly FT_PUESTOS_S_TORRE = 'FT_PUESTOS_S_TORRE';
  // private readonly FT_PUESTOS_S_TIPO_PUESTO = 'FT_PUESTOS_S_TIPO';
  // private readonly FT_PUESTOS_S_CODIGO_PUESTO = 'FT_PUESTOS_S_CODIGO';
  // private readonly FT_PUESTOS_S_TIPO_USO = 'FT_PUESTOS_S_TIPO_DE_USO';
  // private readonly FT_PUESTOS_S_TIEMPO_MINIMO_OCUPACION = 'SelectorDuracion';
  // private readonly FT_PUESTOS_S_DIA_SEMANA = 'FT_PUESTOS_AGG';
  // private readonly FT_PUESTOS_DATE = 'FT_PUESTOS_DATE';
  // private readonly FT_PUESTOS_TIME_24h = 'FT_PUESTOS_TIME_24h';

  // // Tables RESERVAS
  private readonly FT_RESERVAS_S_EDIFICIO = 'FT_RESERVAS_S_EDIFICIO';
  // private readonly FT_RESERVAS_S_FLOOR = 'FT_RESERVAS_S_PLANTA';
  // private readonly FT_RESERVAS_S_AREA = 'FT_RESERVAS_S_AREA';
  // private readonly FT_RESERVAS_S_MODULO = 'FT_RESERVAS_S_MODULO';
  // private readonly FT_RESERVAS_S_ZONA = 'FT_RESERVAS_S_ZONA';
  // private readonly FT_RESERVAS_S_TORRE = 'FT_RESERVAS_S_TORRE';
  // private readonly FT_RESERVAS_S_TIPO_PUESTO = 'FT_RESERVAS_S_TIPO';
  // private readonly FT_RESERVAS_S_CODIGO_PUESTO = 'FT_RESERVAS_S_CODIGO';
  // private readonly FT_RESERVAS_S_TIEMPO_MINIMO_OCUPACION = 'SelectorDuracion';
  // private readonly FT_RESERVAS_S_DIA_SEMANA = 'FT_RESERVAS_AGG';
  // private readonly FT_RESERVAS_DATE = 'FT_RESERVAS_DATE';
  // private readonly FT_RESERVAS_TIME_24h = 'FT_RESERVAS_TIME_24h';

  // // Tables AFORO
  private readonly FT_AFORO_EDIFICIO = 'FT_AFORO_EDIFICIO';
  // private readonly FT_AFORO_CIUDAD = 'FT_AFORO_CIUDAD';
  // private readonly FT_AFORO_ZONA = 'FT_AFORO_ZONA';
  // //private readonly FT_AFORO_PLANTA = 'FT_AFORO_PLANTA';
  // private readonly FT_AFORO_PLANTA_EDIFICIO_CIUDAD = 'FT_AFORO_FLOOR_CITY_BUILDING';
  // private readonly FT_AFORO_DIA_SEMANA = 'FT_AFORO_WEEKDAY';
  // private readonly FT_AFORO_DATE = 'FT_AFORO_DATE';
  // private readonly FT_AFORO_TIME_24h = 'FT_AFORO_TIME_24h';

  // // Columns
  // private readonly FECHA = 'Fecha';
  // private readonly HORA_EN_NUMERO = 'Hora en número';
  // private readonly CODIGO_ESPACIO = 'Código de Espacio';
  // private readonly TIPO_USO_ESPACIO = 'Tipo de Uso de Espacio';
  // private readonly TIEMPO_DURACION = 'TiempoDuracion';
  // private readonly DIA_SEMANA = 'Día Semana';

  private userLogged: User;
  private permissions: Permission[] = [];
  havePermission = true;
  screenHeight = 541.25;
  screenWidth = 1140;
  informPbiSelected = this.RESERVAS;
  selectedPageInforme = true;
  @ViewChild('embeddedReport')
  embeddedReport: ElementRef;
  config: any;
  // private report: any = null; // TODO: JavaScript API PowerBi
  private embeddedReporModel = pbi.models;

  refreshButton = false;
  firstLoad = true;

  // Data for filters
  buildings: string[] = [];
  // cities: string[] = []; // TODO: JavaScript API PowerBi
  // floors: string[] = [];
  // areas: string[] = [];
  // modules: string[] = [];
  // zones: string[] = [];
  // towers: string[] = [];
  // typeSeats: string[] = [];
  // codeSeats: string[] = [];
  // typeUses: string[] = [];
  // //minimumTimes: string[] = [];
  // dayWeeks: string[] = [];
  // //dates: string[] = [];
  // //times: string[] = [];
  // private fromDate: string = this.dateService.getDateDiffDays(new Date(), -7).concat('T00:00:00.000Z'); // 7 days ago (default)
  // private toDate: string = this.dateService.getDateDiffDays(new Date(), -1).concat('T23:00:00.000Z'); // 1 days ago (default) // new Date().toISOString(); // Today default
  // private fromTime = 7; // 7:00 default
  // private toTime = 18; // 18:00 default
  // private fromMinTime = 0; // 0:00 default

  // Default tables
  // private tableMaestra = this.FT_PUESTOS_MAESTRA;
  // private tableAGG = this.FT_PUESTOS_AGG;
  // private tableAGGDay = this.FT_PUESTOS_AGG_DAY;
  private tableBuilding = this.FT_PUESTOS_S_EDIFICIO;
  // private tableCity = this.FT_AFORO_CIUDAD;
  // private tableFloor = this.FT_PUESTOS_S_PLANTA;
  // private tableArea = this.FT_PUESTOS_S_AREA;
  // private tableModule = this.FT_PUESTOS_S_MODULO;
  // private tableZone = this.FT_PUESTOS_S_ZONA;
  // private tableTower = this.FT_PUESTOS_S_TORRE;
  // private tableTypeSeat = this.FT_PUESTOS_S_TIPO_PUESTO;
  // private tableCodeSeat = this.FT_PUESTOS_S_CODIGO_PUESTO;
  // private tableTypeUse = this.FT_PUESTOS_S_TIPO_USO;
  // private tableMinimumTime = this.FT_PUESTOS_S_TIEMPO_MINIMO_OCUPACION;
  // private tableDayWeek = this.FT_PUESTOS_S_DIA_SEMANA;
  // private tableDate = this.FT_PUESTOS_DATE;
  // private tableTime = this.FT_PUESTOS_TIME_24h;

  // Default columns
  private columnBuilding = this.BUILDING;
  // private columnCity = this.CITY;
  // private columnFloor = this.FLOOR;
  // private columnArea = this.AREA;
  // private columnModule = this.MODULE;
  // private columnZone = this.ZONE;
  // private columnTower = this.TOWER;
  // private columnTypeSeat = this.TYPE_SEAT;
  // private columnCodeSeat = this.CODE_SEAT;
  // private columnTypeUse = this.TYPE_USE;
  // private columnMinimumTimeOcupacion = this.MINIMUM_TIME_OCUPACION;
  // private columnMinimumTimeReserva = this.MINIMUM_TIME_RESERVA;
  // private columnMinimumTime = this.MINIMUM_TIME_OCUPACION;
  // private columnDayWeek = this.DAY_WEEK;
  // private columnDate = this.FECHA;
  // private columnTime = this.HORA_EN_NUMERO;

  constructor(
    // private dateService: DateService, // TODO: JavaScript API PowerBi
    private powerBiService: PowerBiService,
    private informesService: InformesService,
    private screenSizeService: ScreenSizeService,
    private globalService: GlobalService,
    private translateService: TranslateService,
    private messageService: MessageService,
    public dialog: MatDialog
  ) {
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
  }

  ngOnInit(): void {
    if (!this.permissions?.map((permission) => permission.name).includes(UserPermissions.AdvancedReports)) {
      this.havePermission = false;
      this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
      this.messageService.add({
        key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
        severity: 'warn',
        summary: this.translateService.instant('Warning'),
        detail: this.translateService.instant('You do not have the AdvancedReports permission'),
      });
      this.dialog.open(NotPermissionComponent, { disableClose: true });
    } else {
      this.getScreenSize();
      this.informesService.setPageInforme(this.selectedPageInforme);
      this.initializeReportPbi(this.informPbiSelected);
      this.informesServiceSubscriptions();
    }
  }

  ngOnDestroy(): void {
    this.informesService.setPageInforme(false);
    this.informesService.setInformeType(this.RESERVAS);
    this.informesService.setRefresh(false);

    // if (this.report) { // TODO: JavaScript API PowerBi
    //   this.report.off('loaded');
    //   this.report.off('rendered');
    //   this.report.off('error');
    //   this.report.off('saved');
    //   this.report.off('dataSelected');
    // }

    // this.report.getPages().then((pages) => {
    //   pages.forEach((page) => {
    //     page.getVisuals().then((visuals) => {
    //       visuals.forEach((visual) => {
    //         if (visual.type === 'slicer') {
    //           visual.off('loaded');
    //           visual.off('rendered');
    //           visual.off('error');
    //           visual.off('saved');
    //           visual.off('dataSelected');
    //         }
    //       });
    //     });
    //   });
    // });
    //powerbi?.unsubscribe(this.report);
    // this.report = null; // TODO: JavaScript API PowerBi
    //this.powerbi = null;
    // this.globalService.userBuildings$.unsubscribe();
  }

  informesServiceSubscriptions(): void {
    this.informesService.informPbiSelected$.subscribe((value) => {
      this.informPbiSelected = value;
      this.initializeReportPbi(this.informPbiSelected);
    });

    this.informesService.refreshSelected$.subscribe((value) => {
      this.refreshButton = value;
      if (this.refreshButton) {
        this.initializeReportPbi(this.informPbiSelected);
      }
    });
  }

  initializeReportPbi(reportType: string): void {
    this.powerBiService.getPowerBiEmbedUrl(reportType.toLowerCase()).subscribe({
      next: (reportConfig: PowerBI) => {
        this.getBuildings(reportConfig);
      },
      error: () => {
        this.refreshButton = false;
      },
    });
  }

  getBuildings(reportConfig: PowerBI): void {
    this.globalService.userBuildings$.subscribe({
      next: (buildings: Building[]) => {
        this.buildings = [...new Set(buildings?.map((building) => building.name))];
        this.refreshButton = false;
        this.buildEmbedConfig(reportConfig);
      },
      error: () => {
        this.refreshButton = false;
      },
    });
  }

  // /**
  //  * Example: reportConfig.url = reportConfig.url?.concat('&filter=FT_AFORO_FLOOR_CITY_BUILDING/building eq "'Novus"'');
  //  * @param reportConfig
  //  * @returns
  //  */
  // filterBuildingByUserLogged(reportConfig): any {
  //   if (this.informPbiSelected === this.AFORO) {
  //     this.tableMaestra = null;
  //     this.tableBuilding = this.FT_AFORO_EDIFICIO;
  //     this.tableAGG = null;
  //     this.tableAGGDay = null;
  //   } else if (this.informPbiSelected === this.RESERVAS) {
  //     this.tableMaestra = null;
  //     this.tableBuilding = this.FT_RESERVAS_S_EDIFICIO;
  //     this.tableAGG = null;
  //     this.tableAGGDay = null;
  //   } else if (this.informPbiSelected === this.PUESTOS) {
  //     this.tableMaestra = this.FT_PUESTOS_MAESTRA;
  //     this.tableAGG = this.FT_PUESTOS_AGG;
  //     this.tableAGGDay = this.FT_PUESTOS_AGG_DAY;
  //     this.tableBuilding = this.FT_PUESTOS_S_EDIFICIO;
  //   }

  //   const buildingNames = this.buildings.join().split(' ').join('%20').split(',').join('%27,%27');
  //   console.log(buildingNames);
  //   reportConfig.url = reportConfig.url
  //     ?.concat('&filter=' + this.tableMaestra + '%2F' + this.BUILDING + '%20in%20(%27' + buildingNames + '%27)')
  //     .concat('&' + this.tableAGG + '%2F' + this.BUILDING + '%20in%20(%27' + buildingNames + '%27)')
  //     .concat('&' + this.tableAGGDay + '%2F' + this.BUILDING + '%20in%20(%27' + buildingNames + '%27)')
  //     .concat('&' + this.tableBuilding + '%2F' + this.BUILDING + '%20in%20(%27' + buildingNames + '%27)');
  //   console.log(reportConfig.url);
  //   return reportConfig;
  // }

  /**
   * Set up the configuration object that determines what to embed and how to embed it.
   */
  buildEmbedConfig(reportConfig: PowerBI): void {
    this.initializeTablesColumns();
    // reportConfig = this.filterBuildingByUserLogged(reportConfig);
    // console.log(reportConfig.url);
    const embedConfig = {
      type: 'report',
      tokenType: this.embeddedReporModel.TokenType.Embed,
      accessToken: reportConfig.token,
      embedUrl: reportConfig.url,
      permissions: this.embeddedReporModel.Permissions.All,
      settings: {
        filterPaneEnabled: false,
        navContentPaneEnabled: false,
        //actionBarEnabled: true,
        background: this.embeddedReporModel.BackgroundType.Transparent, // BACKGROUND PBIX
        panes: {
          filters: {
            expanded: false,
            visible: false,
          },
        },
      },
      filters: [this.getBuildingFilter()],
    };
    const reportClass = 'reportClass';
    const phasedEmbedding = false;

    // try { // TODO: JavaScript API PowerBi
    //powerbi.reset(this.embeddedReport?.nativeElement);
    // this.report =
    powerbi?.embed(this.embeddedReport?.nativeElement, embedConfig, reportClass, phasedEmbedding);
    // } catch (error) {
    //   console.log(error);
    // }

    const iFrameElement = document.getElementsByTagName('iframe');
    if (iFrameElement[0]) {
      iFrameElement[0].style.borderWidth = '0px';
    }
    // this.getEventListener(); // TODO: JavaScript API PowerBi
  }

  initializeTablesColumns(): void {
    this.setColumns();
    switch (this.informPbiSelected) {
      case this.PUESTOS:
        this.initializePuestosTablesColumns();
        break;
      case this.RESERVAS:
        this.initializeReservasTablesColumns();
        break;
      case this.AFORO:
        this.initializeAforoTablesColumns();
        break;
      default:
        break;
    }
  }

  setColumns(): void {
    this.columnBuilding = this.BUILDING;
    // this.columnFloor = this.FLOOR;
    // this.columnArea = this.AREA;
    // this.columnModule = this.MODULE;
    // this.columnZone = this.ZONE;
    // this.columnTower = this.TOWER;
    // this.columnTypeSeat = this.TYPE_SEAT;
    // this.columnCodeSeat = this.CODIGO_ESPACIO;
    // this.columnTypeUse = this.TIPO_USO_ESPACIO;
    // this.columnDayWeek = this.DIA_SEMANA;
    // this.columnDate = this.FECHA;
    // this.columnTime = this.HORA_EN_NUMERO;

    // switch (this.informPbiSelected) {
    //   case this.PUESTOS:
    //     this.columnMinimumTime = this.columnMinimumTimeOcupacion;
    //     break;
    //   case this.RESERVAS:
    //     this.columnMinimumTime = this.columnMinimumTimeReserva;
    //     break;
    //   case this.AFORO:
    //     break;
    //   default:
    //     break;
    // }
  }

  initializePuestosTablesColumns(): void {
    this.tableBuilding = this.FT_PUESTOS_S_EDIFICIO;
    // this.tableFloor = this.FT_PUESTOS_S_PLANTA;
    // this.tableArea = this.FT_PUESTOS_S_AREA;
    // this.tableModule = this.FT_PUESTOS_S_MODULO;
    // this.tableZone = this.FT_PUESTOS_S_ZONA;
    // this.tableTower = this.FT_PUESTOS_S_TORRE;
    // this.tableTypeSeat = this.FT_PUESTOS_S_TIPO_PUESTO;
    // this.tableCodeSeat = this.FT_PUESTOS_S_CODIGO_PUESTO;
    // this.tableTypeUse = this.FT_PUESTOS_S_TIPO_USO;
    // this.tableMinimumTime = this.FT_PUESTOS_S_TIEMPO_MINIMO_OCUPACION;
    // this.tableDayWeek = this.FT_PUESTOS_S_DIA_SEMANA;
    // this.tableDate = this.FT_PUESTOS_DATE;
    // this.tableTime = this.FT_PUESTOS_TIME_24h;
  }

  initializeReservasTablesColumns(): void {
    this.tableBuilding = this.FT_RESERVAS_S_EDIFICIO;
    // this.tableFloor = this.FT_RESERVAS_S_FLOOR;
    // this.tableArea = this.FT_RESERVAS_S_AREA;
    // this.tableModule = this.FT_RESERVAS_S_MODULO;
    // this.tableZone = this.FT_RESERVAS_S_ZONA;
    // this.tableTower = this.FT_RESERVAS_S_TORRE;
    // this.tableTypeSeat = this.FT_RESERVAS_S_TIPO_PUESTO;
    // this.tableCodeSeat = this.FT_RESERVAS_S_CODIGO_PUESTO;
    // this.tableMinimumTime = this.FT_RESERVAS_S_TIEMPO_MINIMO_OCUPACION;
    // this.tableDayWeek = this.FT_RESERVAS_S_DIA_SEMANA;
    // this.tableDate = this.FT_RESERVAS_DATE;
    // this.tableTime = this.FT_RESERVAS_TIME_24h;
  }

  initializeAforoTablesColumns(): void {
    this.tableBuilding = this.FT_AFORO_EDIFICIO;
    // this.tableFloor = this.FT_AFORO_PLANTA_EDIFICIO_CIUDAD;
    // this.tableZone = this.FT_AFORO_ZONA;
    // this.tableDayWeek = this.FT_AFORO_DIA_SEMANA;
    // this.tableDate = this.FT_AFORO_DATE;
    // this.tableTime = this.FT_AFORO_TIME_24h;
  }

  getBuildingFilter(): any {
    return {
      $schema: this.BASIC_SCHEMA,
      target: {
        table: this.tableBuilding,
        column: this.columnBuilding,
      },
      operator: this.IN,
      values: this.buildings,
      filterType: this.embeddedReporModel.FilterType.Basic,
    };
  }

  // getCityFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableCity,
  //       column: this.columnCity,
  //     },
  //     operator: this.IN,
  //     values: this.cities,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getFloorFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableFloor,
  //       column: this.columnFloor,
  //     },
  //     operator: this.IN,
  //     values: this.floors,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getAreaFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableArea,
  //       column: this.columnArea,
  //     },
  //     operator: this.IN,
  //     values: this.areas,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getModuleFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableModule,
  //       column: this.columnModule,
  //     },
  //     operator: this.IN,
  //     values: this.modules,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getZoneFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableZone,
  //       column: this.columnZone,
  //     },
  //     operator: this.IN,
  //     values: this.zones,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getTowerFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableTower,
  //       column: this.columnTower,
  //     },
  //     operator: this.IN,
  //     values: this.towers,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getTypeSeatFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableTypeSeat,
  //       column: this.columnTypeSeat,
  //     },
  //     operator: this.IN,
  //     values: this.typeSeats,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getCodeSeatFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableCodeSeat,
  //       column: this.columnCodeSeat,
  //     },
  //     operator: this.IN,
  //     values: this.codeSeats,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getTypeUseFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableTypeUse,
  //       column: this.columnTypeUse,
  //     },
  //     operator: this.IN,
  //     values: this.typeUses,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getMinimumTimeFilter(): any {
  //   return {
  //     $schema: this.ADVANCED_SCHEMA,
  //     target: {
  //       table: this.tableMinimumTime,
  //       column: this.columnMinimumTime,
  //     },
  //     logicalOperator: this.IN,
  //     conditions: [
  //       {
  //         operator: this.GREATER_THAN_OR_EQUAL,
  //         value: this.fromMinTime, // 0:00
  //       },
  //       {
  //         operator: this.LESS_THAN_OR_EQUAL,
  //         value: 23, // 23:00
  //       },
  //     ],
  //     filterType: this.embeddedReporModel.FilterType.Advanced,
  //   };
  // }

  // getDayWeekFilter(): any {
  //   return {
  //     $schema: this.BASIC_SCHEMA,
  //     target: {
  //       table: this.tableDayWeek,
  //       column: this.columnDayWeek,
  //     },
  //     operator: this.IN,
  //     values: this.dayWeeks,
  //     filterType: this.embeddedReporModel.FilterType.Basic,
  //   };
  // }

  // getDatesFilter(): any {
  //   return {
  //     $schema: this.ADVANCED_SCHEMA,
  //     target: {
  //       table: this.tableDate,
  //       column: this.columnDate,
  //     },
  //     logicalOperator: this.AND,
  //     conditions: [
  //       {
  //         operator: this.GREATER_THAN_OR_EQUAL,
  //         value: this.fromDate, // Format '2023-06-01T00:00:00.000Z',
  //       },
  //       {
  //         operator: this.LESS_THAN,
  //         value: this.toDate, // Format'2023-06-08T00:00:00.000Z',
  //       },
  //     ],
  //     filterType: this.embeddedReporModel.FilterType.Advanced,
  //   };
  // }

  // getTimesFilter(): any {
  //   return {
  //     $schema: this.ADVANCED_SCHEMA,
  //     target: {
  //       table: this.tableTime,
  //       column: this.columnTime,
  //     },
  //     logicalOperator: this.IN,
  //     conditions: [
  //       {
  //         operator: this.GREATER_THAN_OR_EQUAL,
  //         value: this.fromTime, // 7:00
  //       },
  //       {
  //         operator: this.LESS_THAN_OR_EQUAL,
  //         value: this.toTime, // 18:00
  //       },
  //     ],
  //     filterType: this.embeddedReporModel.FilterType.Advanced,
  //   };
  // }

  // onLoadedEvent(): void {
  //   this.report.getPages().then((pages) => {
  //     if (pages.length > 0) {
  //       const activePage = pages.filter(this.isPageActive)[0];

  //       activePage.getVisuals().then(async (visuals) => {
  //         const slicesVisual = visuals.filter(this.isSlicer);
  //         //console.log(slicesVisual);
  //         const buildingSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.BUILDING)[0];
  //         const citySlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.CITY)[0];
  //         const timesSlice = slicesVisual.filter((slicerItem) => slicerItem.title.includes(this.HORARIO))[0];
  //         const datesSlice = slicesVisual.filter((slicerItem) => slicerItem.title.includes(this.PERIODO))[0];
  //         const plantaSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.FLOOR)[0];
  //         const areaSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.AREA)[0];
  //         const moduloSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.MODULE)[0];
  //         const zonaSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.ZONE)[0];
  //         const torreSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.TOWER)[0];
  //         const tipoPuestoSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.TYPE_SEAT)[0];
  //         const codigoSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.CODE_SEAT)[0];
  //         const tipoUsoSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.TYPE_USE)[0];
  //         const tiempoMinimoSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.columnMinimumTime)[0];
  //         const diaSemanaSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.DAY_WEEK)[0];

  //         if (buildingSlice?.type === this.SLICER && buildingSlice?.title === this.BUILDING) {
  //           await buildingSlice.setFilters([this.getBuildingFilter()]);
  //         }

  //         if (datesSlice?.type === this.SLICER && datesSlice?.title.includes(this.PERIODO)) {
  //           await datesSlice.setFilters([this.getDatesFilter()]);
  //         }

  //         if (timesSlice?.type === this.SLICER && timesSlice?.title.includes(this.HORARIO)) {
  //           await timesSlice.setFilters([this.getTimesFilter()]);
  //         }

  //         if (!this.firstLoad) {
  //           if (citySlice?.type === this.SLICER && citySlice?.title === this.CITY) {
  //             await citySlice.setFilters([this.getCityFilter()]);
  //           }

  //           if (plantaSlice?.type === this.SLICER && plantaSlice?.title === this.FLOOR) {
  //             //const state = await plantaSlice.getSlicerState();
  //             //console.log(state[0]);
  //             await plantaSlice.setFilters([this.getFloorFilter()]);
  //           }

  //           if (areaSlice?.type === this.SLICER && areaSlice?.title === this.AREA) {
  //             await areaSlice.setFilters([this.getAreaFilter()]);
  //           }

  //           if (moduloSlice?.type === this.SLICER && moduloSlice?.title === this.MODULE) {
  //             await moduloSlice.setFilters([this.getModuleFilter()]);
  //           }

  //           if (zonaSlice?.type === this.SLICER && zonaSlice?.title === this.ZONE) {
  //             //const state = await plantaSlice.getSlicerState();
  //             //console.log(state[0]);
  //             await zonaSlice.setFilters([this.getZoneFilter()]);
  //           }

  //           if (torreSlice?.type === this.SLICER && torreSlice?.title === this.TOWER) {
  //             await torreSlice.setFilters([this.getTowerFilter()]);
  //           }

  //           if (tipoPuestoSlice?.type === this.SLICER && tipoPuestoSlice?.title === this.TYPE_SEAT) {
  //             await tipoPuestoSlice.setFilters([this.getTypeSeatFilter()]);
  //           }

  //           if (codigoSlice?.type === this.SLICER && codigoSlice?.title === this.CODE_SEAT) {
  //             await codigoSlice.setFilters([this.getCodeSeatFilter()]);
  //           }

  //           if (tipoUsoSlice?.type === this.SLICER && tipoUsoSlice?.title === this.TYPE_USE) {
  //             await tipoUsoSlice.setFilters([this.getTypeUseFilter()]);
  //           }

  //           if (tiempoMinimoSlice?.type === this.SLICER && tiempoMinimoSlice?.title === this.columnMinimumTime) {
  //             await tiempoMinimoSlice.setFilters([this.getMinimumTimeFilter()]);
  //           }

  //           if (diaSemanaSlice?.type === this.SLICER && diaSemanaSlice?.title === this.DAY_WEEK) {
  //             await diaSemanaSlice.setFilters([this.getDayWeekFilter()]);
  //           }
  //         }
  //       });
  //     }
  //   });
  // }

  // onRenderedEvent(): void {
  //   this.report.getPages().then((pages) => {
  //     if (pages.length > 0) {
  //       const activePage = pages.filter(this.isPageActive)[0];

  //       activePage.getVisuals().then(async (visuals) => {
  //         const slicesVisual = visuals.filter(this.isSlicer);
  //         const buildingSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.BUILDING)[0];
  //         const citySlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.CITY)[0];
  //         const timesSlice = slicesVisual.filter((slicerItem) => slicerItem.title.includes(this.HORARIO))[0];
  //         const datesSlice = slicesVisual.filter((slicerItem) => slicerItem.title.includes(this.PERIODO))[0];
  //         const plantaSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.FLOOR)[0];
  //         const areaSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.AREA)[0];
  //         const moduloSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.MODULE)[0];
  //         const zonaSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.ZONE)[0];
  //         const torreSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.TOWER)[0];
  //         const tipoPuestoSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.TYPE_SEAT)[0];
  //         const codigoSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.CODE_SEAT)[0];
  //         const tipoUsoSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.TYPE_USE)[0];
  //         const tiempoMinimoSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.columnMinimumTime)[0];
  //         const diaSemanaSlice = slicesVisual.filter((slicerItem) => slicerItem.title === this.DAY_WEEK)[0];

  //         if (buildingSlice?.type === this.SLICER && buildingSlice?.title === this.BUILDING) {
  //           const state = await buildingSlice.getSlicerState();
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.buildings = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.buildings = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (citySlice?.type === this.SLICER && citySlice?.title === this.CITY) {
  //           const state = await citySlice.getSlicerState();
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.cities = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.cities = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (datesSlice?.type === this.SLICER && datesSlice?.title.includes(this.PERIODO)) {
  //           const state = await datesSlice.getSlicerState();
  //           if (state?.filters && state?.filters[0]?.conditions) {
  //             const conditions = state.filters[0].conditions;
  //             conditions.forEach((condition) => {
  //               if (condition.operator === this.GREATER_THAN_OR_EQUAL) {
  //                 this.fromDate = condition.value;
  //               } else if (condition.operator === this.LESS_THAN) {
  //                 this.toDate = condition.value;
  //               }
  //             });
  //           }
  //         }

  //         if (timesSlice?.type === this.SLICER && timesSlice?.title.includes(this.HORARIO)) {
  //           const state = await timesSlice.getSlicerState();
  //           console.log(state);
  //           if (state?.filters && state?.filters[0]?.conditions) {
  //             const conditions = state.filters[0].conditions;
  //             conditions.forEach((condition) => {
  //               if (condition.operator === this.GREATER_THAN_OR_EQUAL) {
  //                 this.fromTime = condition.value;
  //               } else if (condition.operator === this.LESS_THAN_OR_EQUAL) {
  //                 this.toTime = condition.value;
  //               }
  //             });
  //           }
  //         }

  //         if (plantaSlice?.type === this.SLICER && plantaSlice?.title === this.FLOOR) {
  //           const state = await plantaSlice.getSlicerState();
  //           console.log(state);
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.floors = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.floors = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (areaSlice?.type === this.SLICER && areaSlice?.title === this.AREA) {
  //           const state = await areaSlice.getSlicerState();
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.areas = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.areas = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (moduloSlice?.type === this.SLICER && moduloSlice?.title === this.MODULE) {
  //           const state = await moduloSlice.getSlicerState();
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.modules = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.modules = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (zonaSlice?.type === this.SLICER && zonaSlice?.title === this.ZONE) {
  //           const state = await zonaSlice.getSlicerState();
  //           console.log(state);
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.zones = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.zones = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (torreSlice?.type === this.SLICER && torreSlice?.title === this.TOWER) {
  //           const state = await torreSlice.getSlicerState();
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.towers = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.towers = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (tipoPuestoSlice?.type === this.SLICER && tipoPuestoSlice?.title === this.TYPE_SEAT) {
  //           const state = await tipoPuestoSlice.getSlicerState();
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.typeSeats = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.typeSeats = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (codigoSlice?.type === this.SLICER && codigoSlice?.title === this.CODE_SEAT) {
  //           const state = await codigoSlice.getSlicerState();
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.codeSeats = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.codeSeats = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (tipoUsoSlice?.type === this.SLICER && tipoUsoSlice?.title === this.TYPE_USE) {
  //           const state = await tipoUsoSlice.getSlicerState();
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.typeUses = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.typeUses = filter.values;
  //               }
  //             });
  //           }
  //         }

  //         if (tiempoMinimoSlice?.type === this.SLICER && tiempoMinimoSlice?.title === this.columnMinimumTime) {
  //           const state = await tiempoMinimoSlice.getSlicerState();
  //           if (state?.filters) {
  //             const filters = state.filters;
  //             this.fromMinTime = 0; // Default value 0:00
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.fromMinTime = filter.values[0];
  //               }
  //             });
  //           }
  //         }

  //         if (diaSemanaSlice?.type === this.SLICER && diaSemanaSlice?.title === this.DAY_WEEK) {
  //           const state = await diaSemanaSlice.getSlicerState();
  //           if (diaSemanaSlice?.filters) {
  //             const filters = state.filters;
  //             this.dayWeeks = [];
  //             filters.forEach((filter) => {
  //               if (filter.values) {
  //                 this.dayWeeks = filter.values;
  //               }
  //             });
  //           }
  //         }
  //       });
  //     }
  //   });
  // }

  // private isSlicer = (visual) => visual.type === this.SLICER;
  // private isPageActive = (page) => page.isActive;
  // private isPageVisible = (page) => page.isVisible;
  // private isPageVisibleAndActive = (page) => page.isVisible && page.isActive;
  // private isPageHidden = (page) => !page.isVisible;
  // private isPageHiddenAndActive = (page) => !page.isVisible && page.isActive;
  // private isPageHiddenAndInactive = (page) => !page.isVisible && !page.isActive;
  // private ispageReady = (page) => page.isReady;
  // private isVisualReady = (visual) => visual.isReady;

  // getEventListener(): void {
  //   this.report?.on('loaded', () => {
  //     this.onLoadedEvent();
  //   });

  //   this.report?.on('rendered', () => {
  //     this.onRenderedEvent();
  //     this.firstLoad = false;
  //   });

  //   this.report?.on('error', (event) => {
  //     console.log('Report error');
  //     console.log(event.detail);
  //   });
  // }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?): void {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth * 0.75;
    this.screenSizeService.setHeight(this.screenHeight);
    this.screenSizeService.setWidth(this.screenWidth);
  }
}
