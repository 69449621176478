<div class="titleN3" style="margin-bottom: 10px">{{ chartIdentifier.name | translate }}</div>
<div *ngIf="loading" style="display: flex; align-items: center; justify-content: center; min-height: 184px">
  <app-loading></app-loading>
</div>
<div [hidden]="loading">
  <div *ngIf="sizeData == 0" class="row" style="padding-left: 16px" class="responsiveText">
    {{ chartIdentifier.msgEmpty | translate }}
  </div>
  <div class="row" [hidden]="sizeData == 0">
    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
      <canvas id="{{ 'chartCanvas' + chartIdentifier.id }}" width="250" height="250"></canvas>
    </div>
    <div *ngIf="sizeData > 0" class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6" style="padding: unset">
      <div
        *ngFor="let item of legendItems"
        [ngStyle]="{ width: '100%', 'margin-bottom': '20px', 'border-left': '4px solid ' + item.color }"
      >
        <div class="subtext">{{ item.subtitle }}</div>
        <div class="text">{{ item.title | translate }} - {{ item.getPercentage() | number: '1.0-1' }}%</div>
      </div>
    </div>
  </div>
</div>
