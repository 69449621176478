<div class="container">
  <div class="w-75 mx-auto alert alert-danger mt-5">
    <div class="titleN2 mb-3">{{ 'You do not have permission to the Habitat management website' | translate }}</div>
    <div>
      <p>{{ 'You do not have permission to the Habitat management website. If this is an error, contact an administrator.' | translate }}</p>
      <div class="text-center">
        <button mat-button class="modalButton bg-primary" (click)="logout()">
          {{ 'SignOff' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
