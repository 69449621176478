import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Event, NavigationEnd, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'src/app/models/auth/User.class';
import { CustomMenuItem } from 'src/app/models/CustomMenuItem.interface';
import { MenuItemParentResponsive } from 'src/app/models/menu/MenuItemParent.type';
import { Modules } from 'src/app/models/Modules.enum';
import { AuthService } from 'src/app/services/backend/auth.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { GlobalService } from 'src/app/services/common/global.service';

import { Permission } from './../../../models/auth/Role.class';
import { UserPermissions } from './../../../models/auth/UserPermissions.enum';
import { GroupService } from './../../../services/pages/group.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly = false;
  responsive = false;
  userLogged: User;
  private WRITE = 'WRITE';
  private permissions: Permission[] = [];
  webMenu: CustomMenuItem[] = [
    this.buildRealTimeMenu(),
    {
      id: 3,
      title: 'Informes avänzados',
      link: '/advancedreport',
      hidden: true,
      active: false,
      open: false,
      configMenuItems: [],
    },
    {
      id: 4,
      title: 'Configuración',
      target: 'configuration',
      hidden: true,
      active: false,
      open: false,
      configMenuItems: [
        {
          type: 'spaceConfiguration',
          title: 'Configurar espäcios',
          target: 'spaceconfiguration',
          link: '/spaceconfiguration',
          hidden: false,
        },
        {
          type: 'subsectionconfiguration',
          title: 'Configurar subsecciones',
          target: 'subsectionconfiguration',
          link: '/subsectionconfiguration',
          hidden: false,
        },
        {
          type: 'continuousreservations',
          title: 'Reservas continuas',
          target: 'continuousreservations',
          link: '/continuousreservations',
          hidden: false,
        },
        {
          type: 'groupConfiguration',
          title: 'Configurar Agrupaciones',
          target: 'groupconfiguration',
          link: '/groupconfiguration',
          hidden: false,
        },
        {
          type: 'resourcesConfiguration',
          title: 'Configurar Recursos',
          target: 'resourcesconfiguration',
          link: '/resourcesconfiguration',
          hidden: false,
        },
        {
          type: 'rolesConfiguration',
          title: 'Configurar Roles',
          target: 'rolesconfiguration',
          link: '/rolesconfiguration',
          hidden: false,
        },
      ],
    },
    { id: 5, title: 'Usuärios', link: '/users', hidden: true, active: false, open: false, configMenuItems: [] },
    {
      id: 6,
      title: 'Auditoría',
      target: 'audit',
      hidden: true,
      active: false,
      open: false,
      configMenuItems: [
        {
          type: 'changesAudit',
          title: 'Auditoría de cambios',
          target: 'changesAudit',
          link: '/audit',
          hidden: false,
        },
        {
          type: 'reservationAudit',
          title: 'Auditoría de reservas',
          target: 'reservationAudit',
          link: '/reservationaudit',
          hidden: false,
        },
      ],
    },
  ];

  webMenuUntranslatedTitles = ['Tiempo reäl', 'Informes avänzados', 'Configuración', 'Usuärios', 'Auditoría'];
  webMenuConfigurationUntranslatedTitles = [
    'Configurar espäcios',
    'Configurar subsecciones',
    'Reservas continuas',
    'Configurar Agrupaciones',
    'Configurar Recursos',
    'Configurar Roles',
  ];
  webMenuAuditTitles = ['Auditoría de cambios', 'Auditoría de reservas'];
  webMenuRealTimeTitles = ['Visuälización de espacios', 'Cälidad del aire'];
  nameUser = '';
  userInitials = '';
  grouping = '';
  userImage;
  hasEsiteDevicesModule = false;
  hasEsiteModule = false;
  webMenuResponsive = [];
  activeLeftPanel = true;
  @Output() activeLeftPanelOutput: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private habitatService: HabitatService,
    private globalService: GlobalService,
    private authService: AuthService,
    private groupService: GroupService
  ) {
    this.hasEsiteDevicesModule = this.globalService.getEsiteDevice();
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;

    this.buildItemsMenuResponsive();
    this.initMenu(); // PERMISSIONS
    // Set selected every time the route changes.
    this.router.events.subscribe((event: Event) => {
      this.configMenuItem(event);
      this.configMenuItemResponsive(event);
    });

    this.groupService.curSelectedGroupUser.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.grouping = value;
    });
  }

  get imgWindow() {
    return this.activeLeftPanel ? 'bi-arrow-left-square' : 'bi-arrow-right-square';
  }

  matchesRoute(path: string): boolean {
    return window.location.pathname.startsWith(path);
  }

  ngOnInit(): void {
    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
      this.userPictureOnly = isResponsive;
    });

    this.authService.userInfoChanged.subscribe((user: User) => {
      this.nameUser = user.surname ? user.name.concat(' ').concat(user.surname) : user.name;
      this.userInitials = user.name.charAt(0) + user.surname.charAt(0);
    });

    this.globalService.userLogged$.subscribe((user: User) => {
      // SET MENU
      if (user) {
        this.userLogged = user;
        this.permissions = this.userLogged?.permissions;
        this.loadAndSetHeader();
      }
    });

    this.globalService.userImageChanged.subscribe((image) => {
      this.userImage = image;
    });
    this.getProfileImage();

    this.translate.onLangChange.subscribe(() => {
      this.translateMenuItems();
      this.translateResponsiveMenuItems();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  buildItemsMenuResponsive(): void {
    this.webMenuResponsive = [
      this.buildRealTimeHeaderResponsive(),
      {
        id: '3',
        label: 'Informes avänzados',
        routerLink: '/advancedreport',
        visible: false,
        disabled: true,
      },
      {
        id: '4',
        label: 'Configuración',
        visible: false,
        disabled: true,
        items: [
          {
            label: 'Configurar espäcios',
            noTarget: 'spaceconfiguration',
            routerLink: '/spaceconfiguration',
            visible: true,
          },
          {
            label: 'Configurar subsecciones',
            noTarget: 'subsectionconfiguration',
            routerLink: '/subsectionconfiguration',
            visible: true,
          },
          {
            label: 'Reservas continuas',
            noTarget: 'continuousreservations',
            routerLink: '/continuousreservations',
            visible: true,
          },
          {
            label: 'Configurar Agrupaciones',
            noTarget: 'groupconfiguration',
            routerLink: '/groupconfiguration',
            visible: true,
          },
          {
            label: 'Configurar Recursos',
            noTarget: 'resourcesconfiguration',
            routerLink: '/resourcesconfiguration',
            visible: true,
          },
          {
            label: 'Configurar Roles',
            noTarget: 'rolesconfiguration',
            routerLink: '/rolesconfiguration',
            visible: true,
          },
        ],
      },
      {
        id: '5',
        label: 'Usuärios',
        routerLink: '/users',
        visible: false,
        disabled: true,
      },
      {
        id: '6',
        label: 'Auditoría',
        target: 'audit',
        visible: false,
        disabled: true,
        items: [
          {
            label: 'Auditoría de cambios',
            noTarget: 'changesAudit',
            routerLink: '/audit',
            visible: true,
          },
          {
            label: 'Auditoría de reservas',
            noTarget: 'reservationAudit',
            routerLink: '/reservationaudit',
            visible: true,
          },
        ],
      },
    ];
  }

  private buildRealTimeMenu(): CustomMenuItem {
    const hasCo2Module = this.globalService.isModuleEnabled(Modules.co2);

    return {
      id: 1,
      title: 'Tiempo reäl',
      target: 'realtime',
      link: !hasCo2Module ? '/realtime' : undefined,
      hidden: true,
      active: !hasCo2Module,
      open: false,
      configMenuItems: !hasCo2Module
        ? []
        : [
            {
              title: 'Visuälización de espacios',
              target: 'realtime',
              link: '/realtime',
              hidden: false,
            },
            {
              title: 'Cälidad del aire',
              target: 'co2',
              link: '/co2',
              hidden: false,
            },
          ],
    };
  }

  private buildRealTimeHeaderResponsive() {
    const realTime: MenuItemParentResponsive = {
      id: '1',
      label: 'Tiempo reäl',
      visible: false,
      disabled: true,
      // icon: 'pi pi-fw pi-power-off',
    };
    if (this.globalService.isModuleEnabled(Modules.co2)) {
      realTime.items = [
        {
          label: 'Visuälización de espacios',
          noTarget: 'realtime',
          routerLink: '/realtime',
          visible: true,
          // icon: 'pi pi-fw pi-plus',
        },
        {
          label: 'Cälidad del aire',
          target: 'co2',
          routerLink: '/co2',
          visible: true,
          // icon: 'pi pi-fw pi-trash',
        },
      ];
    } else {
      realTime.routerLink = '/realtime';
    }

    return realTime;
  }

  configMenuItem(event: Event): void {
    if (event instanceof NavigationEnd) {
      this.setActiveMenu(event.url);
    }
  }

  private setActiveMenu(_url: string) {
    for (const c of this.webMenu) {
      c.active = false;
      if (_url.indexOf(c.link) !== -1) {
        c.active = true;
      } else {
        c.configMenuItems.forEach((item) => {
          if (_url.indexOf(item.link) !== -1) {
            c.active = true;
          }
        });
      }
    }
  }

  configMenuItemResponsive(event: Event): void {
    if (event instanceof NavigationEnd) {
      for (const c of this.webMenuResponsive) {
        c.disabled = false;
        c.items?.forEach((item) => {
          if (event.url.indexOf(item.routerLink) !== -1) {
            c.disabled = false;
          }
        });

        if (event.url.indexOf(c.routerLink) !== -1) {
          c.disabled = false;
        } else {
          c.items?.forEach((item) => {
            if (event.url.indexOf(item.routerLink) !== -1) {
              c.disabled = false;
            }
          });
        }
      }
    }
  }

  initMenu(): void {
    if (this.userLogged) {
      this.loadAndSetHeader();
    } else {
      this.authService.getUserInfo().subscribe((user) => {
        this.userLogged = new User(user);
      });
    }
  }

  hasSubMenu(item: CustomMenuItem): boolean {
    return item.configMenuItems.some((el) => el.link?.length > 0);
  }

  /**
   * Translate Context Menu Items, as automatic translation of angular pipe doesn't work propertly on them
   */
  translateMenuItems(): void {
    this.webMenu.forEach((item, idx) => {
      item.title = this.translate.instant(this.webMenuUntranslatedTitles[idx]);
      if (item.id == 1 && item.configMenuItems) {
        item.configMenuItems.forEach((element, i) => {
          element.title = this.translate.instant(this.webMenuRealTimeTitles[i]);
        });
      }
      if (item.id == 4 && item.configMenuItems) {
        item.configMenuItems.forEach((element, i) => {
          element.title = this.translate.instant(this.webMenuConfigurationUntranslatedTitles[i]);
        });
      }
      if (item.id == 6 && item.configMenuItems) {
        item.configMenuItems.forEach((element, i) => {
          element.title = this.translate.instant(this.webMenuAuditTitles[i]);
        });
      }
    });
  }

  translateResponsiveMenuItems(): void {
    this.webMenuResponsive.forEach((item, idx) => {
      item.label = this.translate.instant(this.webMenuUntranslatedTitles[idx]);
      if (parseInt(item.id) == 1 && item.items) {
        item.items.forEach((element, i) => {
          element.label = this.translate.instant(this.webMenuRealTimeTitles[i]);
        });
      }
      if (parseInt(item.id) == 4 && item.items) {
        item.items.forEach((element, i) => {
          element.label = this.translate.instant(this.webMenuConfigurationUntranslatedTitles[i]);
        });
      }
      if (parseInt(item.id) == 6 && item.items) {
        item.items.forEach((element, i) => {
          element.label = this.translate.instant(this.webMenuAuditTitles[i]);
        });
      }
    });
  }

  loadAndSetHeader(): void {
    this.setUserMenu();
    this.translateMenuItems();
    this.translateResponsiveMenuItems();
  }

  setUserMenu(): void {
    if (this.userLogged) {
      this.nameUser = this.userLogged.name + ' ' + this.userLogged.surname;
      this.userInitials = this.userLogged.name.charAt(0) + this.userLogged.surname.charAt(0);

      if (this.userLogged.mainGroup != null) {
        this.groupService.getNameGroupSelected(this.userLogged.mainGroup.toString()).subscribe((group) => {
          this.grouping = group.name;
        });
      }
    }

    const url = window.location.href;
    this.setMenu(url);
    this.setMenuResponsive(url);
  }

  setMenu(url: string): void {
    for (const c of this.webMenu) {
      c.active = false;
      c.hidden = false;

      // Hide "Configure subsections" if there is no esite module
      if (c.id === 4 && !c.hidden) {
        if (!this.hasEsiteModule) {
          c.configMenuItems = c.configMenuItems.filter((configMenuItem) => {
            return configMenuItem.target !== 'subsectionconfiguration';
          });
        }
      }

      this.setActiveMenu(url);

      // PERMISSION CONDUCE MENU HIDDEN
      const permissionStr = this.permissions.map((permission) => permission.name);

      if (c.id === 3) {
        // REPORTES AVANZADOS
        c.hidden = !permissionStr.includes(UserPermissions.AdvancedReports);
      }

      if (c.id === 4) {
        let count = 0;
        c.configMenuItems.forEach((item) => {
          if (item.target === 'spaceconfiguration') {
            // CONFIGURACION ESPACIOS
            item.hidden = !permissionStr.includes(UserPermissions.Spaces);
          } else if (item.target === 'subsectionconfiguration') {
            // CONFIGURACION SUBSECCIONES
            item.hidden =
              !this.permissions?.find(
                (permission) => permission.name == UserPermissions.Subsections && permission.type == this.WRITE
              ) && !permissionStr.includes(UserPermissions.SubsectionTypes);
          } else if (item.target === 'continuousreservations') {
            // RESERVAS CONTINUAS
            item.hidden = !this.permissions
              .map((permission) => permission.name)
              .includes(UserPermissions.ContinuousReservations);
          } else if (item.target === 'groupconfiguration') {
            // CONFIGURACION AGRUPACIONES
            item.hidden =
              !permissionStr.includes(UserPermissions.Groups) ||
              !permissionStr.includes(UserPermissions.BuildingConfig);
          } else if (item.target === 'rolesconfiguration') {
            // CONFIGURACION ROLES
            item.hidden = !permissionStr.includes(UserPermissions.Roles);
          } else if (item.target === 'resourcesconfiguration') {
            // CONFIGURACION RESOURCES
            item.hidden = !permissionStr.includes(UserPermissions.Resources);
          }
          count = count + 1;
          if (count === c.configMenuItems.length) {
            c.hidden = c.configMenuItems.map((item) => item.hidden).every((v) => v === true);
          }
        });
      }

      if (c.id === 5) {
        // USUARIOS
        c.hidden = !permissionStr.includes(UserPermissions.Users);
      }

      if (c.id === 6) {
        // AUDITORIA
        c.configMenuItems.forEach((item) => {
          if (item.target === 'reservationAudit') {
            // AUDITORIA DE RESERVAS
            item.hidden =
              !permissionStr.includes(UserPermissions.Audit) &&
              !permissionStr.includes(UserPermissions.DelegatedReservations);
          } else {
            // AUDITORIA DE CAMBIOS
            item.hidden = !permissionStr.includes(UserPermissions.Audit);
          }
        });
        c.hidden = !permissionStr.includes(UserPermissions.Audit);
      }
    }
  }

  setMenuResponsive(url: string): void {
    for (const c of this.webMenuResponsive) {
      c.disabled = false;
      c.visible = true;
      // Hide "Configure subsections" if there is no esite module
      if (parseInt(c.id) === 4 && c.visible) {
        if (!this.hasEsiteModule) {
          c.items = c.items.filter((configMenuItem) => {
            return configMenuItem.noTarget !== 'subsectionconfiguration';
          });
        }
      }
      if (url.indexOf(c.routerLink) !== -1) {
        c.disabled = false;
      }

      // PERMISSION CONDUCE MENU HIDDEN
      const permissionStr = this.permissions.map((permission) => permission.name);

      if (parseInt(c.id) === 3) {
        // REPORTES AVANZADOS
        c.visible = permissionStr.includes(UserPermissions.AdvancedReports);
      }

      if (parseInt(c.id) === 4) {
        let count = 0;
        c.items.forEach((item) => {
          if (item.noTarget === 'spaceconfiguration') {
            // CONFIGURACION ESPACIOS
            item.visible = permissionStr.includes(UserPermissions.Spaces);
          } else if (item.noTarget === 'subsectionconfiguration') {
            // CONFIGURACION SUBSECCIONES
            item.visible =
              permissionStr.includes(UserPermissions.Subsections) &&
              permissionStr.includes(UserPermissions.SubsectionTypes);
          } else if (item.noTarget === 'continuousreservations') {
            // RESERVAS CONTINUAS
            item.visible = this.permissions
              .map((permission) => permission.name)
              .includes(UserPermissions.ContinuousReservations);
          } else if (item.noTarget === 'groupconfiguration') {
            // CONFIGURACION AGRUPACIONES
            item.visible =
              permissionStr.includes(UserPermissions.Groups) || permissionStr.includes(UserPermissions.BuildingConfig);
          } else if (item.noTarget === 'rolesconfiguration') {
            // CONFIGURACION ROLES
            item.visible = permissionStr.includes(UserPermissions.Roles);
          } else if (item.noTarget === 'resourcesconfiguration') {
            // CONFIGURACION RESOURCES
            item.visible = permissionStr.includes(UserPermissions.Resources);
          }
        });
        count = count + 1;
        if (count === c.items.length) {
          c.visible = c.items.map((item) => item.visible).every((v) => v === true);
        }
      }

      if (parseInt(c.id) === 5) {
        // USUARIOS
        c.visible = permissionStr.includes(UserPermissions.Users);
      }

      if (parseInt(c.id) === 6) {
        // AUDITORIA
        c.items.forEach((item) => {
          if (item.noTarget === 'reservationAudit') {
            // AUDITORIA DE RESERVAS
            item.visible =
              permissionStr.includes(UserPermissions.Audit) &&
              permissionStr.includes(UserPermissions.DelegatedReservations);
          } else {
            // AUDITORIA DE CAMBIOS
            item.visible = permissionStr.includes(UserPermissions.Audit);
          }
        });
        c.visible = permissionStr.includes(UserPermissions.Audit);
      }
    }
  }

  getProfileImage(): void {
    this.habitatService.getUserImage().subscribe((userImage) => {
      if (userImage) {
        this.createImageFromBlob(userImage);
      }
    });
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.userImage = reader.result;
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  leftPanelClick(): void {
    this.activeLeftPanel = !this.activeLeftPanel;
    this.activeLeftPanelOutput.emit(this.activeLeftPanel);
  }
}
