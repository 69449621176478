import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PrimeIcons, TreeNode } from 'primeng/api';
import { BehaviorSubject, from } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';

import { Group } from './../../../models/habitat/Group.class';
import { AgrupacionService } from './../../../services/backend/agrupacion.service';
import { HabitatService } from './../../../services/backend/habitat.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.css'],
})
export class InfoModalComponent implements OnInit {
  private treeNivelSubject_0: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private treeNivelSubject_1: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private treeNivelSubject_2: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  private treeNivelSubject_3: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  title = '';
  message = '';
  hierarchy = false;
  treeNode: TreeNode[];
  selectedItemTree: TreeNode;
  group: Group;
  loading = false;

  constructor(
    private dialogRef: MatDialogRef<InfoModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private groupService: AgrupacionService,
    private habitatService: HabitatService
  ) {
    this.title = data?.title;
    this.message = data?.message;
    this.hierarchy = data?.hierarchy;
    this.group = data?.group;
  }

  ngOnInit(): void {
    if (this.group?.id) {
      this.groupService.getGroupById(this.group.id.toString()).subscribe((groupBase) => {
        this.buildTreeNode(groupBase);
      });
    }
  }

  cancel(): void {
    this.dialogRef.close();
  }

  buildTreeNode(group_0: Group): void {
    this.loading = true;
    this.treeNode = [];
    let treeNodeNivel_1;

    if (group_0.final) {
      // TO BE BUILDINGS !!!
      this.treeNivelSubject_0.subscribe((subject_1) => {
        treeNodeNivel_1 = subject_1;
        if (treeNodeNivel_1.length > 0) {
          this.waitPromise(group_0.name, this.treeNode, treeNodeNivel_1);
          this.loading = false;
        }
      });
      this.getFinalBuildings(group_0.children, group_0.name, this.treeNivelSubject_0, true); // invoke
    } else {
      // NIVEL 1

      this.buildLabel_1(group_0);
    }
  }

  buildLabel_1(group_0): void {
    let tmp;
    let treeNodeNivel_1 = [];

    if (group_0.children.length > 0) {
      group_0.children.forEach((id) => {
        // TO BE GROUPS !!!
        this.groupService.getGroupById(id.toString()).subscribe((subGroup_0) => {
          if (subGroup_0.final) {
            // TO BE BUILDINGS !!!
            this.treeNivelSubject_1.subscribe((subject_1) => {
              if (subject_1[0]?.label != tmp) {
                treeNodeNivel_1.push(...subject_1);
                treeNodeNivel_1 = [...new Set(treeNodeNivel_1)];
              }
              tmp = subject_1[0]?.label;

              if (treeNodeNivel_1.length > 0 && treeNodeNivel_1.length === group_0.children.length) {
                this.waitPromise(group_0.name, this.treeNode, treeNodeNivel_1);
                this.loading = false;
              }
            });
            this.getFinalBuildings(subGroup_0.children, subGroup_0.name, this.treeNivelSubject_1); // invoke
          } else {
            // NIVEL 2

            this.buildLabel_2(group_0, subGroup_0, treeNodeNivel_1);
          }
        });
      });
    } else {
      this.loading = false;
    }
  }

  buildLabel_2(group_0, subGroup_0, treeNodeNivel_1): void {
    let tmp;
    let treeNodeNivel_2 = [];

    if (subGroup_0.children.length > 0) {
      subGroup_0.children.forEach((id) => {
        // TO BE GROUPS !!!
        this.groupService.getGroupById(id.toString()).subscribe((subGroup_1) => {
          if (subGroup_1.final) {
            // TO BE BUILDINGS !!!
            this.treeNivelSubject_2.subscribe((subject_2) => {
              if (subject_2[0]?.label != tmp) {
                treeNodeNivel_2.push(...subject_2);
                treeNodeNivel_2 = [...new Set(treeNodeNivel_2)];
              }
              tmp = subject_2[0]?.label;

              if (treeNodeNivel_2.length > 0 && treeNodeNivel_2.length === subGroup_0.children.length) {
                treeNodeNivel_1.push({
                  label: subGroup_0.name,
                  children: treeNodeNivel_2,
                  expanded: true,
                  expandedIcon: null,
                  collapsedIcon: null,
                });

                this.waitPromise(group_0.name, this.treeNode, treeNodeNivel_1);
                this.treeNivelSubject_2.complete();
                this.loading = false;
              }
            });
            this.getFinalBuildings(subGroup_1.children, subGroup_1.name, this.treeNivelSubject_2); // invoke
          } else {
            // NIVEL 3

            this.buildLabel_3(group_0, subGroup_1, treeNodeNivel_2);
          }
        });
      });
    } else {
      this.loading = false;
    }
  }

  buildLabel_3(group_0, subGroup_1, treeNodeNivel_2): void {
    let tmp;
    let treeNodeNivel_3 = [];

    if (subGroup_1.children.length > 0) {
      subGroup_1.children.forEach((id) => {
        // TO BE GROUPS !!!
        this.groupService.getGroupById(id.toString()).subscribe((subGroup_2) => {
          if (subGroup_2.final) {
            // TO BE BUILDINGS !!!
            this.treeNivelSubject_3.subscribe((subject_3) => {
              if (subject_3[0]?.label != tmp) {
                treeNodeNivel_3.push(...subject_3);
                treeNodeNivel_3 = [...new Set(treeNodeNivel_3)];
              }
              tmp = subject_3[0]?.label;

              if (treeNodeNivel_3.length > 0 && treeNodeNivel_3.length === subGroup_1.children.length) {
                treeNodeNivel_2.push({
                  label: subGroup_1.name,
                  children: treeNodeNivel_3,
                  expanded: true,
                  expandedIcon: null,
                  collapsedIcon: null,
                });

                this.waitPromise(group_0.name, this.treeNode, treeNodeNivel_2);
                this.treeNivelSubject_3.complete();
                this.loading = false;
              }
            });
            this.getFinalBuildings(subGroup_2.children, subGroup_2.name, this.treeNivelSubject_3); // invoke
          } else {
            // other level
            console.log('many levels'); // TODO
            this.loading = false;
          }
        });
      });
    } else {
      this.loading = false;
    }
  }

  waitPromise(groupName: string, treeNode: any, treeNodeChildren: any): void {
    treeNode.push({
      label: groupName,
      children: treeNodeChildren,
      expanded: true,
      expandedIcon: null,
      collapsedIcon: null,
    });
  }

  getFinalBuildings(
    idBuildings: number[],
    groupName: string,
    subjectItem: BehaviorSubject<any>,
    justBuilding?: boolean
  ): any {
    let buildings;
    let treeNodeNivel;
    let childItems;

    from(idBuildings.filter((idBuilding) => idBuilding > 0))
      .pipe(
        mergeMap((id) => this.habitatService.getBuilding(id)),
        finalize(() => {
          childItems = [];
          buildings.forEach((buildingItem) => {
            childItems.push({
              label: buildingItem.name,
              icon: PrimeIcons.HOME,
            });
          });
          if (justBuilding) {
            subjectItem.next(childItems);
          } else {
            if (treeNodeNivel === undefined) {
              treeNodeNivel = [];
            }
            treeNodeNivel.push({
              label: groupName,
              children: childItems,
              expanded: true,
              expandedIcon: null,
              collapsedIcon: null,
            });
            subjectItem.next(treeNodeNivel);
          }
        }) // Execute when the observable completes
      )
      .subscribe({
        next: (building) => {
          if (buildings === undefined) {
            buildings = [];
          }
          buildings.push(building);
        },
        error: (error) => {
          if (error.status === 404) {
            // el usuario no tiene permisos para ver el edificio
            this.loading = false;
          }
        },
      });
  }
}
