import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription, combineLatest } from 'rxjs';
import { Area } from 'src/app/models/esite/Area.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { SpacesState } from 'src/app/models/realtime/SpacesState.class';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { SvgmapData } from 'src/app/models/svgmap/SvgmapData.class';
import { SvgmapPoint } from 'src/app/models/svgmap/SvgmapPoint.class';
import { SvgmapProperties } from 'src/app/models/svgmap/SvgmapProperties.class';
import { RealtimeService } from 'src/app/services/pages/realtime.service';
import { GlobalService } from './../../../../services/common/global.service';

@Component({
  selector: 'app-realtime-area',
  templateUrl: './realtime-area.component.html',
  styleUrls: ['./realtime-area.component.scss'],
})
export class RealtimeAreaComponent implements OnInit, OnChanges, OnDestroy {
  @Input() spaceEsiteInfoModified: SvgmapPoint;
  @Input() spaceReservationInfoModified: ReservationSpace[];
  svgPointReservationInfoModified: ReservationSpace[];
  svgPointEsiteInfoModified: SvgmapPoint;
  initalAreaInfoState = true;
  areaDataExpanded = true;
  svgProperties = new SvgmapProperties({ id: 21, initialZoom: 1 });
  svgDataMemory = { sites: [], areas: [] };
  svgDataToSend: SvgmapData;
  legendItems = [];
  currentFloor = new Floor('');
  currentArea = new Area('');
  currentAreaSpaceIds: number[];
  roomsChart = { id: 5, name: 'Rooms', msgEmpty: 'NoRoomsInArea' };
  sitesChart = { id: 6, name: 'Sites', msgEmpty: 'NoPostsInArea' };
  roomsChartData;
  sitesChartData;
  subcriptions: Subscription[] = [];
  hasReservationsModule = false;
  hasEsiteModule = false;
  hasEsiteDevicesModule = false;

  constructor(private realtimeService: RealtimeService, private globalService: GlobalService) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasReservationsModule = this.globalService.getReservationModule();
    this.hasEsiteDevicesModule = this.globalService.getEsiteDevice();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.spaceEsiteInfoModified && changes.spaceEsiteInfoModified.currentValue) {
      this.svgPointEsiteInfoModified = changes.spaceEsiteInfoModified.currentValue;
    }
    if (changes.spaceReservationInfoModified && changes.spaceReservationInfoModified.currentValue) {
      this.svgPointReservationInfoModified = changes.spaceReservationInfoModified.currentValue;
    }
  }

  ngOnInit(): void {
    combineLatest([this.realtimeService.floorChanged, this.realtimeService.areaChanged]).subscribe((msg) => {
      if (msg[0] && msg[1]) {
        this.currentFloor = msg[0];
        this.svgProperties.offsetX = -this.currentFloor.mapOffsetX;
        this.svgProperties.offsetY = -this.currentFloor.mapOffsetY;
        this.svgProperties.scale = this.currentFloor.scale;
        this.areaChanged(msg[1]);
      }
    });

    combineLatest([this.realtimeService.floorChanged, this.realtimeService.esiteSpaceUpdated]).subscribe((msg) => {
      if (msg[0] && msg[1]) {
        if (this.hasEsiteDevicesModule) {
          if (msg[0].id == this.currentFloor.id) {
            this.updateCharts(msg[0], msg[1]);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  updateCharts(floor: Floor, chartInfo: { spaceId: number; building: Building }): void {
    if (this.currentArea?.spaces) {
      if (
        this.currentArea.spaces.some((space) => {
          return space.id === chartInfo.spaceId;
        })
      ) {
        const spacesState: SpacesState = this.realtimeService.getRealTimeDataFromSpaces(
          floor.name,
          floor.spaces.filter((space) => {
            return this.currentAreaSpaceIds.includes(space.id);
          }),
          floor.reservations
        ).groupSpacesState;
        this.roomsChartData = this.realtimeService.getDonutChartData(spacesState.totalRooms, spacesState.roomStates);
        this.sitesChartData = this.realtimeService.getDonutChartData(spacesState.totalSites, spacesState.siteStates);
      }
    }
  }

  areaChanged(area: Area): void {
    this.svgDataToSend = new SvgmapData();
    if (area) {
      this.currentArea = area;
      this.currentAreaSpaceIds = this.currentArea.spaces.map((el) => {
        return el.id;
      });
      const realtimeData = this.realtimeService.getRealTimeDataFromSpaces(
        this.currentFloor.name,
        area.spaces,
        this.currentFloor.reservations
      );

      const spacesState: SpacesState = realtimeData.groupSpacesState;
      const points = realtimeData.points;
      if (this.hasEsiteDevicesModule) {
        this.legendItems = this.realtimeService.createLegendItems();
      }
      if (this.hasReservationsModule) {
        this.legendItems.push({ color: '#ffa900', name: 'Reserved', ring: true });
        this.legendItems.push({ color: '#8f9bb3', name: 'NotReserved', ring: true });
      } // SVG Data
      // SVG Data
      this.svgDataToSend.img = this.currentFloor.img;
      this.svgDataMemory.sites = points;
      this.svgDataToSend.points = points;
      // Donut Charts
      this.roomsChartData = this.realtimeService.getDonutChartData(spacesState.totalRooms, spacesState.roomStates);
      this.sitesChartData = this.realtimeService.getDonutChartData(spacesState.totalSites, spacesState.siteStates);
    }
  }

  collapseAction(state): void {
    this.areaDataExpanded = state;
  }
}
