<mat-card class="p-4">
  <mat-card-header>
    <div class="fs-4 text-primary">{{ 'Continuous Reservations' | translate }}</div>
    <div class="fs-10 text-secondary mb-3">*{{'One field required' | translate}}</div>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="filterFormGroup">
      <div class="row">
        <div class="col">
          <mat-form-field #user class="form-group col-12">
            <mat-label>{{ 'User' | translate }}</mat-label>
            <mat-select
              id="user"
              formControlName="CRuser"
              class="input-form-special py-1"
              [ngClass]="{ 'is-invalid': f.CRuser.errors }"
              ngxMatSelect
              #ms="ngxMatSelect"
              [searcher]="searcher"
              [displayMember]="'nameComplete'"
              [valueMember]="'employeeNumber'"
              [useInfiniteScroll]="true"
              [source]="users"
              [lazyLoad]="true"
              searchBoxPlaceholder="{{ 'Search' | translate }}"
              emptyLabel="{{ 'No user found' | translate }}"
              noMoreResultLabel="{{ 'No more found' | translate }}"
              [hasSearchBox]="true"
              (ngModelChange)="clearOneFieldRequired()"
            >
              <mat-option *ngFor="let user of ms.filteredSource" [value]="user.employeeNumber">
                {{ user.nameComplete | translate }}
              </mat-option>
            </mat-select>
            <mat-error id="oneFieldRequired" *ngIf="!isValidFunctionForm">{{
              'One field required' | translate
            }}</mat-error>
          </mat-form-field>
          <span
            *ngIf="filterFormGroup.value.CRuser"
            [title]="'Clear selection' | translate"
            class="close-circle-outline bi bi-x-circle"
            (click)="clearField('user')"
          ></span>
        </div>
        <div class="col">
          <mat-form-field #status class="form-group col-12">
            <mat-label>{{ 'Status' | translate }}</mat-label>
            <mat-select
              id="status"
              formControlName="CRstatus"
              class="input-form-special py-1"
              [ngClass]="{ 'is-invalid': f.CRuser.errors }"
              (ngModelChange)="clearOneFieldRequired()"
            >
              <mat-option *ngFor="let status of statusOptions" [value]="status">
                {{ status | translate }}
              </mat-option>
            </mat-select>
            <mat-error id="oneFieldRequired" *ngIf="!isValidFunctionForm">{{
              'One field required' | translate
            }}</mat-error>
          </mat-form-field>
          <span
            *ngIf="filterFormGroup.value.CRstatus"
            [title]="'Clear selection' | translate"
            class="close-circle-outline bi bi-x-circle"
            (click)="clearField('status')"
          ></span>
        </div>
        <div class="col">
          <mat-form-field #spaceCode class="form-group col-12">
            <mat-label>{{ 'Space code' | translate }}</mat-label>
            <input
              title="{{ 'Space code' | translate }}"
              matInput
              autocomplete="off"
              formControlName="CRspaceCode"
              type="text"
              class="p-1"
              spellcheck="false"
              (change)="validForm()"
            />
            <mat-error id="oneFieldRequired" *ngIf="!isValidFunctionForm">{{
              'One field required' | translate
            }}</mat-error>
          </mat-form-field>
          <span
            *ngIf="filterFormGroup.value.CRspaceCode"
            [title]="'Clear selection' | translate"
            class="close-circle-outline bi bi-x-circle"
            (click)="clearField('spaceCode')"
          ></span>
        </div>
        <div class="col">
          <mat-form-field #dateRange class="form-group col-12">
            <mat-label>{{ 'Date Range' | translate }}</mat-label>
            <input
              title="{{ 'Date Range' | translate }}"
              matInput
              autocomplete="off"
              formControlName="CRdateRange"
              type="text"
              class="p-1"
              spellcheck="false"
              (click)="op1.toggle($event)"
              readonly
              (ngModelChange)="clearOneFieldRequired()"
            />
            <mat-error id="oneFieldRequired" *ngIf="!isValidFunctionForm">{{
              'One field required' | translate
            }}</mat-error>
            <mat-error *ngIf="!isValidDateForm">{{ 'Date range is not valid' | translate }}</mat-error>
          </mat-form-field>
          <span
            *ngIf="filterFormGroup.value.CRdateRange"
            [title]="'Clear selection' | translate"
            class="close-circle-outline bi bi-x-circle"
            (click)="clearField('dateRange')"
          ></span>
        </div>
      </div>
      <div class="row justify-content-center">
        <button
          mat-button
          class="modalButton bg-primary"
          (click)="onSearch()"
          [disabled]="CReservationsresultsTableLoading || isValidFunctionForm === false"
        >
          {{ 'Search' | translate }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<p-overlayPanel #op1>
  <ng-template pTemplate>
    <div class="row" id="calendario" class="p-calendar-padding">
      <div class="calendar-header">
        <div class="flex-grow-1 text-center">
          <div class="fw-bold"> {{ 'Start' | translate }}</div>
          <small>{{ startTime | date: 'dd/MM/yyyy' }}</small>
        </div>

        <div class="flex-grow-1 text-center">
          <div class="fw-bold"> {{ 'End' | translate }}</div>
          <small>{{ endTime | date: 'dd/MM/yyyy' }}</small>
        </div>
      </div>
      <div class="d-flex">
        <div class="px-1">
          <p-calendar
            [(ngModel)]="startTime"
            [showTime]="false"
            inputId="time"
            [inline]="true"
            [maxDate]="endTime"
            [showOtherMonths]="false"
          ></p-calendar>
        </div>

        <div class="px-1">
          <p-calendar
            [(ngModel)]="endTime"
            [showTime]="false"
            inputId="time"
            [inline]="true"
            [minDate]="startTime"
            [showOtherMonths]="false"
          ></p-calendar>
        </div>
      </div>
    </div>

    <div class="buttonsRow">
      <button mat-button class="modalCancel text-primary" (click)="op1.hide($event)" (click)="cleanRangeField()">
        {{ 'Cancel' | translate }}
      </button>
      <button mat-button class="modalButton bg-primary" (click)="confirmRange()" (click)="op1.hide($event)">
        {{ 'accept' | translate }}
      </button>
    </div>
  </ng-template>
</p-overlayPanel>
