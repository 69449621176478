import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { throwError, forkJoin, from, of, Subscription, zip } from 'rxjs';
import {
  catchError,
  concatMap,
  defaultIfEmpty,
  finalize,
  groupBy,
  map,
  mergeMap,
  switchMap,
  toArray,
} from 'rxjs/operators';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { Constants } from 'src/app/models/Constants.const';
import { Space } from 'src/app/models/esite/Space.class';
import { SpaceType } from 'src/app/models/esite/SpaceType.class';
import { SpaceUseType, spaceUseTypeFromExcelValue } from 'src/app/models/esite/SpaceUseType.enum';
import { Subsection } from 'src/app/models/esite/Subsection.class';
import { SubsectionType } from 'src/app/models/esite/SubsectionType.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { SvgmapData } from 'src/app/models/svgmap/SvgmapData.class';
import { SvgmapLegendItem } from 'src/app/models/svgmap/SvgmapLegendItem.class';
import { SvgmapPoint } from 'src/app/models/svgmap/SvgmapPoint.class';
import { SvgmapProperties } from 'src/app/models/svgmap/SvgmapProperties.class';
import { EsiteService } from 'src/app/services/backend/esite.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { ReservationEventService } from 'src/app/services/backend/reservationEvents.service';
import { GlobalService } from 'src/app/services/common/global.service';
import * as XLSX from 'xlsx';

import { StorageVariables } from './../../../../../models/StorageVariables.enum';
import { ResourceType } from 'src/app/models/esite/ResourceType.class';
import { Resource } from 'src/app/models/esite/Resource.class';
import { ResourceTypesService } from 'src/app/services/backend/resourceTypes.service';
import { ResourcesService } from 'src/app/services/backend/resources.service';

/**
 * Auxiliar class to model excel's row for sheet `Tipo de Espacios`
 */
class ExcelSpaceType {
  code: string;
  name: string;
  useType: SpaceUseType;

  constructor(excelRow: ExcelSpaceTypeRow) {
    this.code = excelRow['Código'];
    this.name = excelRow['Nombre'];
    this.useType = spaceUseTypeFromExcelValue(excelRow['Tipo de Uso']);
  }
}

/**
 * Auxiliar interface to model excel's table row for sheet `Tipo de Espacios`
 */
interface ExcelSpaceTypeRow {
  Código: string;
  Nombre: string;
  'Tipo de Uso': string;
}

/**
 * Auxiliar class to model excel's row for sheet `Ajustes Espacio-Planta`
 */
class ExcelSpaceFloorConfig {
  CP: number;
  city: string;
  address: string;
  building: string;
  latitude?: number;
  longitude?: number;
  mapOffsetX: number;
  mapOffsetY: number;
  imageName?: string;
  floorName: string;
  scale: number;

  constructor(msg: ExcelSpaceFloorConfigRow) {
    this.CP = Number(msg.CP);
    this.city = msg.Ciudad;
    this.address = msg.Dirección;
    this.building = msg.Espacio;
    this.latitude = msg.Latitud ? Number(msg.Latitud) : undefined;
    this.longitude = msg.Longitud ? Number(msg.Longitud) : undefined;
    this.mapOffsetX = msg.MapOffsetX ? Number(msg.MapOffsetX) : 0;
    this.mapOffsetY = msg.MapOffsetY ? Number(msg.MapOffsetY) : 0;
    this.imageName = msg.Nombre_imagen;
    this.floorName = msg.Nombre_planta;
    this.scale = msg.Scale ? Number(msg.Scale) : 1;
  }
}

/**
 * Auxiliar interface to model excel's table row for sheet `Ajustes Espacio-Planta`
 */
interface ExcelSpaceFloorConfigRow {
  Espacio: string;
  Ciudad: string;
  Dirección: string;
  CP: string;
  Latitud?: string;
  Longitud?: string;
  Nombre_planta: string;
  Scale?: string;
  MapOffsetX?: string;
  MapOffsetY?: string;
  Nombre_imagen: string;
}

@Component({
  selector: 'app-floor-form',
  templateUrl: './floor-form.component.html',
  styleUrls: ['./floor-form.component.css'],
})
export class FloorFormComponent implements OnInit {
  @ViewChild('excelFile') excelFile: ElementRef;
  floorFormGroup: UntypedFormGroup;
  submitted = false;
  showErrors = false;
  loading = false;
  floor: Floor | null;
  building;
  modalTitle = '';
  imageName = '';
  excelImageName = '';
  excelName = '';
  svgProperties: SvgmapProperties;
  svgDataToSend: SvgmapData;
  excelPoints: SvgmapPoint[];
  legendItems: SvgmapLegendItem[];
  arrayBuffer;
  parsedTypeSpaces = [];
  parsedSubsectiontypes = [];
  parsedSubsection = [];
  parsedNewSpaces = [];
  parsedModifySpaces = [];
  colorSite = '#0075AA';
  colorExists = '#eb7100';
  spaceTypes = [];
  subsectionTypes = [];
  subsections = [];
  resourceTypes: ResourceType[] = [];
  resourcesToAdd: Resource[] = [];
  allResources: Resource[] = [];
  subcriptions: Subscription[] = [];
  img_to_upload;
  img_to_upload_Base64: string;
  rowsWithErrors: Array<{ row: string | number; code: string; msg: string }> = [];
  rowsWithReservationErrors: Array<{ row: string | number; code: string; msg: string }> = [];
  subsectionNames = [];
  floorProperties;
  typeList: ExcelSpaceType[] = [];
  hasEsiteModule = false;
  errorImageType = false;
  errorImageSize = false;
  errorExcelType = false;
  errorExcelFile = false;
  errorExcelVersion = false;
  errorExcelSpaceType = false;
  errorExcelConfig = false;
  errorExcelBuilding = false;
  errorImageNameMatch = false;
  isEditingFloor = false;
  progressBarMode = 'indeterminate';
  progressBarValue = 0;
  loadingMessage = 'Saving Floor...';
  readonly excelMimeFiles = [
    'application/vnd.ms-Excel',
    'application/msexcel',
    'application/x-msexcel',
    'application/x-ms-Excel',
    'application/x-Excel',
    'application/x-dos_ms_Excel',
    'application/xls',
    'application/x-xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ];
  excelfile: File;

  totalSpacesModified = 0;

  constructor(
    private globalService: GlobalService,
    private formBuilder: UntypedFormBuilder,
    private habitatService: HabitatService,
    private esiteService: EsiteService,
    private reservationService: ReservationService,
    private reservationEventService: ReservationEventService,
    private resourceTypesService: ResourceTypesService,
    private resourcesService: ResourcesService,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<FloorFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();

    if (data?.building) {
      this.building = data.building;
    }
  }

  get isFormValid() {
    return !(
      this.floorFormGroup.invalid ||
      this.errorImageSize ||
      this.errorImageType ||
      this.errorExcelType ||
      this.errorExcelVersion ||
      this.errorExcelConfig ||
      this.errorExcelBuilding ||
      this.errorExcelFile ||
      this.errorImageNameMatch
    );
  }

  newFloorProperties(properties): void {
    this.floorProperties = properties;
  }

  ngOnInit(): void {
    this.modalTitle = 'Create floor';
    this.getSpaceTypes();
    this.getSubsectionTypes();
    this.getSubsections();

    if (this.hasEsiteModule) {
      this.floorFormGroup = this.formBuilder.group({
        name: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]],
        img: ['', [Validators.required]],
        excel: ['', [Validators.required]],
      });
    } else {
      this.floorFormGroup = this.formBuilder.group({
        name: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]],
        img: ['', [Validators.required]],
        excel: ['', []],
      });
    }
  }

  getSpaceTypes(): void {
    this.subcriptions.push(
      this.esiteService.getSpaceTypes().subscribe((types: SpaceType[]) => {
        this.spaceTypes = types;
      })
    );
  }

  getSubsectionTypes(): void {
    this.subcriptions.push(
      this.esiteService.getSubsectionTypes().subscribe((subsectionTypes: SubsectionType[]) => {
        this.subsectionTypes = subsectionTypes;
      })
    );
  }

  getSubsections(): void {
    this.subcriptions.push(
      this.esiteService.getSubsections().subscribe((subsections: Subsection[]) => {
        this.subsections = subsections;
      })
    );
  }

  handleSubmit() {
    if (!this.isFormValid) {
      return;
    }
    this.submitted = true;

    this.loadingMessage = 'Saving resources and resource types...';
    this.saveResources().subscribe({
      next: () => {
        this.saveFloorInfo();
      },
      error: (err) => {
        console.error('saveResources error', err);
        this.rowsWithErrors.push({
          row: '',
          code: '',
          msg: 'An error occurred while saving resources:' + err,
        });
        this.submitted = false;
      },
    });
  }

  private saveResources() {
    // save resource types one by one...
    return forkJoin(
      this.resourceTypes.map((x) =>
        this.resourceTypesService.saveResourceType(x).pipe(
          catchError((err: HttpErrorResponse) => {
            this.rowsWithErrors.push({
              row: '',
              code: '',
              msg: 'An error occurred while saving resource type:' + err,
            });
            console.error('save resourcetype error', err);
            return throwError(() => err);
          })
        )
      )
    ).pipe(
      defaultIfEmpty([]),
      switchMap(() => {
        return this.resourceTypesService.fetchResourceTypes();
      }),
      switchMap((resourceTypes) => {
        // replace resource.type.code with corresponding type.id
        this.resourcesToAdd.forEach((res) => {
          res.typeId = resourceTypes.find((type) => type.code === res.type.code).id;
        });

        // 4. save excel resources one by one...
        return forkJoin(this.resourcesToAdd.map((res) => this.resourcesService.saveResource(res))).pipe(
          defaultIfEmpty([]),
          catchError((err) => {
            console.error('save resource error', err);
            this.rowsWithErrors.push({
              row: '',
              code: '',
              msg: 'An error occurred while saving a resource:' + err,
            });
            return throwError(() => err);
          })
        );
      }),
      switchMap(() => {
        return this.resourcesService.getResources({ full: true });
      }),
      map((resources) => {
        this.allResources = resources;
        return true;
      })
    );
  }

  private saveFloorInfo() {
    const floorToInsert = new Floor(this.floorFormGroup.value);
    floorToInsert.img = this.img_to_upload;
    floorToInsert.mapOffsetX = Math.abs(this.floorProperties.offsetX);
    floorToInsert.mapOffsetY = Math.abs(this.floorProperties.offsetY);
    floorToInsert.scale = this.floorProperties.scale;
    if (!this.isEditingFloor) {
      this.habitatService.insertFloor(floorToInsert, this.building.id).subscribe((floor) => {
        this.floor = new Floor(floor);
        if (this.hasEsiteModule) {
          this.parseAndSaveSpaceTypes(this.parsedTypeSpaces);
        } else {
          this.close();
        }
      });
    } else {
      floorToInsert.id = this.floor.id;
      this.habitatService.modifyFloor(floorToInsert).subscribe((floor) => {
        const sessionFloors: { floor: number; image: string }[] =
          JSON.parse(sessionStorage.getItem(StorageVariables.FLOOR_IMAGE)) || [];
        const index = sessionFloors.findIndex((item) => item.floor === this.floor.id);
        if (index !== -1) {
          sessionFloors[index].image = this.img_to_upload_Base64;
          sessionStorage.setItem(StorageVariables.FLOOR_IMAGE, JSON.stringify(sessionFloors));
        }

        this.floor = new Floor(floor);
        if (this.hasEsiteModule) {
          this.parseAndSaveSpaceTypes(this.parsedTypeSpaces);
        } else {
          this.close();
        }
      });
    }
  }

  handleImageInput(files: FileList): void {
    this.errorImageNameMatch = false;

    if (files.item(0)) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files.item(0));
      fileReader.onload = (event: any) => {
        this.errorImageSize = files.item(0).size > 2000000;
        this.errorImageType = files.item(0).type !== 'image/png' && files.item(0).type !== 'image/jpeg';
        this.floorFormGroup.controls['img'].setValue(files.item(0) ? files.item(0).name : '');
        this.img_to_upload = files.item(0);
        this.imageName = files.item(0).name;
        this.img_to_upload_Base64 = fileReader.result as string;

        if (!this.doesImageNameMatchsExcelName()) {
          this.errorImageNameMatch = true;
        }

        if (this.errorImageSize || this.errorImageType || this.errorImageNameMatch) {
          this.svgDataToSend = new SvgmapData();
          this.svgDataToSend.img = null;
          this.svgDataToSend.points = this.excelPoints || [];
        } else {
          this.svgDataToSend = new SvgmapData();
          this.svgDataToSend.img = event.target.result;
          this.svgDataToSend.points = this.excelPoints || [];
        }
        this.resourcesOnPoint();
      };
    } else {
      if (!this.doesImageNameMatchsExcelName()) {
        this.errorImageNameMatch = true;
      }
    }
  }

  handleExcelFile(event) {
    // Clear 'name' pattern validator, as name is readed is a posterior step
    this.floorFormGroup.controls['name'].setErrors({ pattern: null });
    this.excelImageName = null;
    this.rowsWithErrors = [];
    this.rowsWithReservationErrors = [];
    this.floor = null;
    this.errorImageNameMatch = false;
    this.isEditingFloor = false;
    this.errorExcelVersion = false;
    this.errorExcelFile = false;
    this.errorExcelSpaceType = false;
    this.errorExcelConfig = false;
    this.errorExcelBuilding = false;
    const reader = new FileReader();
    if (event.target.files[0]) {
      this.excelfile = event.target.files[0];
      this.errorExcelType = !this.excelMimeFiles.includes(this.excelfile.type);
      this.excelName = this.excelfile.name;
      this.floorFormGroup.controls['excel'].setValue(this.excelfile.name);
      if (this.errorExcelType) {
        this.legendItems = null;
        this.excelPoints = null;
        this.svgDataToSend.points = null;
        return;
      }
    }

    reader.onload = () => {
      const data = reader.result;
      const workBook = XLSX.read(data, { type: 'binary' });
      // Before moving on, make sure the excel at least contains a valid SheetNames
      if (
        workBook.SheetNames.indexOf('Tipo de Espacios') == -1 ||
        workBook.SheetNames.indexOf('Espacios') == -1 ||
        workBook.SheetNames.indexOf('Instrucciones') == -1 ||
        workBook.SheetNames.indexOf('Ajustes Espacio-Planta') == -1
      ) {
        this.errorExcelFile = true;
        this.legendItems = null;
        this.excelPoints = null;
        this.svgDataToSend.points = null;
        return;
      }
      this.readInstructions(workBook.Sheets);
    };
    reader.readAsBinaryString(this.excelfile);
  }

  //Read instruction excel sheet
  readInstructions(sheet: XLSX.WorkSheet): void {
    try {
      if (!sheet['Instrucciones']['F9']?.w || sheet['Instrucciones']['F9'].w !== Constants.templateVersion) {
        this.errorExcelVersion = true;
        this.legendItems = null;
        this.excelPoints = null;
        this.svgDataToSend.points = null;
      } else {
        this.readResources(sheet);
        this.readSpaceFloorConfig(sheet);
      }
    } catch (error) {
      console.error(error);
      this.rowsWithErrors.push({
        row: '',
        code: '',
        msg: error,
      });
    }
  }

  readResources(sheet: XLSX.WorkSheet) {
    const resourceTypes = XLSX.utils.sheet_to_json(sheet['Tipo de Recursos']).map((row) => {
      const t = new ResourceType({});
      t.code = row['Código'];
      t.name = row['Nombre'];
      t.icon = row['Icono'];
      return t;
    });

    const resources = XLSX.utils.sheet_to_json(sheet['Recursos']).map((row) => {
      const r = new Resource();
      r.code = row['Código'];
      r.name = row['Nombre'];
      const resType = resourceTypes.find((x) => x.code === row['Tipo']);
      if (!resType) {
        console.warn("can't find resource type", row['Tipo']);
        throw new Error(`Resource type "${row['Tipo']}" is not defined for resource at row ${row['rowNumber']}`);
      }
      r.type = resType;
      return r;
    });

    this.resourceTypes = resourceTypes;
    this.resourcesToAdd = resources;
  }

  //Read Space Floor Config excel sheet
  readSpaceFloorConfig(sheet: XLSX.WorkSheet): void {
    const uploadConfig = new ExcelSpaceFloorConfig(
      XLSX.utils.sheet_to_json(sheet['Ajustes Espacio-Planta'], { raw: false })[0] as ExcelSpaceFloorConfigRow
    );
    this.excelImageName = uploadConfig?.imageName;
    if (
      !uploadConfig.building ||
      !uploadConfig.city ||
      !uploadConfig.address ||
      !uploadConfig.CP ||
      !uploadConfig.floorName ||
      !uploadConfig.imageName
    ) {
      this.errorExcelConfig = true;
      return;
    }
    if (uploadConfig.building !== this.building.name) {
      this.errorExcelBuilding = true;
      return;
    }

    if (!this.doesImageNameMatchsExcelName()) {
      this.errorImageNameMatch = true;
    }

    this.floorFormGroup.patchValue({
      name: uploadConfig.floorName,
    });
    this.svgProperties = new SvgmapProperties({
      id: 42,
      initialZoom: 1,
      offsetX: uploadConfig.mapOffsetX,
      offsetY: uploadConfig.mapOffsetY,
      scale: uploadConfig.scale,
    });
    this.invertOffsetValues();
    this.subcriptions.push(
      this.habitatService.getFloorsByBuilding(this.building.id).subscribe((resp) => {
        const currentFloor = resp.find((item) => item.name === uploadConfig.floorName);
        if (currentFloor) {
          this.modalTitle = 'Edit floor';
          this.floor = new Floor(currentFloor);
          this.isEditingFloor = true;
          this.subcriptions.push(
            this.esiteService.getSpacesByFloor(currentFloor.id).subscribe((spaces) => {
              this.floor.spaces = spaces;
              this.checkColor();
              this.readSpaceTypes(sheet);
            })
          );
        }
        if (!this.floor) {
          this.modalTitle = 'Create floor';
          this.checkColor();
          this.readSpaceTypes(sheet);

          this.floor = new Floor({ name: uploadConfig.floorName });
        }
      })
    );
  }

  //Invert map offset Values
  invertOffsetValues(): void {
    this.svgProperties.offsetX *= -1;
    this.svgProperties.offsetY *= -1;
  }

  //Read Space types excel sheet
  readSpaceTypes(sheet: XLSX.WorkSheet): void {
    this.parsedTypeSpaces = [];
    this.typeList = XLSX.utils
      .sheet_to_json(sheet['Tipo de Espacios'], { raw: false })
      .map((row: ExcelSpaceTypeRow) => {
        return new ExcelSpaceType(row);
      });
    this.typeList.forEach((type: ExcelSpaceType) => {
      if (
        !this.spaceTypes.some((item) => {
          return item.code === type.code;
        })
      ) {
        this.parsedTypeSpaces.push(type);
      }
    });
    this.readSpaces(sheet);
  }

  //Read Space excel sheet
  readSpaces(sheet: XLSX.WorkSheet): void {
    const spaces = XLSX.utils.sheet_to_json(sheet['Espacios'], { raw: false });
    let spaceTypeCodes = [];
    if (this.typeList) {
      spaceTypeCodes = this.typeList.map((type: ExcelSpaceType) => {
        return type.code;
      });
    }
    spaces.forEach((typeOfSpace) => {
      if (typeOfSpace['Tipo de Espacio'].includes(',') || !spaceTypeCodes.includes(typeOfSpace['Tipo de Espacio'])) {
        this.errorExcelSpaceType = true;
      }
    });

    this.subsectionNames = [];

    if (sheet['Espacios']['E1']?.w) {
      this.subsectionNames.push(sheet['Espacios']['E1'].w);
    }
    if (sheet['Espacios']['F1']?.w) {
      this.subsectionNames.push(sheet['Espacios']['F1'].w);
    }
    if (sheet['Espacios']['G1']?.w) {
      this.subsectionNames.push(sheet['Espacios']['G1'].w);
    }
    this.subsectionNames.forEach((type) => {
      const exists = this.subsectionTypes.find((item) => item.name === type);
      if (!exists) {
        this.parsedSubsectiontypes.push(type);
      }
    });
    this.previewExcelData(spaces);
  }

  previewExcelData(parsedSpaces): void {
    if (!this.svgDataToSend) {
      this.svgDataToSend = new SvgmapData();
    }
    if (this.floor) {
      this.parseSpaces(parsedSpaces, this.floor.spaces);
    } else {
      this.parseSpaces(parsedSpaces, []);
    }
  }

  checkColor(): void {
    if (this.svgDataToSend?.points) {
      this.svgDataToSend.points.forEach((point) => {
        if (this.floor) {
          if (this.floor.spaces.find((item) => item.code == point.code)) {
            point.color = this.colorExists;
          }
        } else {
          point.color = this.colorSite;
        }
      });
      this.resourcesOnPoint();
    }
  }

  parseSpaces(newSpaces, existingSpaces): void {
    this.parsedNewSpaces = [];
    this.parsedModifySpaces = [];
    const points: SvgmapPoint[] = [];
    let rowNumber = 2;
    newSpaces.forEach((parsedSpace) => {
      parsedSpace['rowNumber'] = rowNumber;
      const point = new SvgmapPoint(null);
      point.setExcelData(parsedSpace);
      const exists = existingSpaces.find((space) => space.code === parsedSpace['Código']);
      if (exists) {
        parsedSpace['id'] = exists.id;
        this.parsedModifySpaces.push(parsedSpace);
        point.color = this.colorExists;
      } else {
        this.parsedNewSpaces.push(parsedSpace);
        point.color = this.colorSite;
      }
      point.label3 = '';
      point.code = parsedSpace['Código'];
      point.building = this.building.name;
      point.buildingId = this.building.id;
      point.floor = this.floorFormGroup.value.name;

      point.resources = (parsedSpace['Recursos'] ?? '')
        .split(',')
        .map((x) => x.trim())
        .filter(Boolean)
        .map((code) => this.resourcesToAdd.find((res) => res.code === code))
        .filter(Boolean);

      points.push(point);
      rowNumber++;
    });
    this.legendItems = this.createLegendItems();
    points.forEach((item) => {
      item.typeName = (
        this.spaceTypes.find((type) => type.code == item.typeName) ||
        this.parsedTypeSpaces.find((type) => type.code == item.typeName)
      ).name;
    });
    this.excelPoints = points;
    this.svgDataToSend.points = points;
    this.resourcesOnPoint();
  }

  createLegendItems(): SvgmapLegendItem[] {
    const legenItems = [];
    const legendItemNew = new SvgmapLegendItem();
    legendItemNew.color = this.colorSite;
    legendItemNew.name = this.translateService.instant('Create');
    legenItems.push(legendItemNew);
    const legendItemExists = new SvgmapLegendItem();
    legendItemExists.color = this.colorExists;
    legendItemExists.name = this.translateService.instant('Modify');
    legenItems.push(legendItemExists);
    return legenItems;
  }

  parseAndSaveSpaceTypes(parsedTypeSpaces): void {
    this.progressBarMode = 'determinate';
    this.loadingMessage = 'Saving space types...';
    let totalSpaceTypesLoaded = 0;
    if (parsedTypeSpaces.length === 0) {
      this.parseSubsectionTypes();
    }

    this.subcriptions.push(
      from(parsedTypeSpaces)
        .pipe(
          concatMap((type) => {
            const spaceType = new SpaceType('');
            spaceType.setExcelData(type);
            return this.esiteService.insertSpaceType(spaceType, AuditFunctionalities.CreateMasiveSpaceType);
          })
        )
        .subscribe((newtype) => {
          const spaceType = new SpaceType(newtype);
          this.spaceTypes.push(spaceType);
          totalSpaceTypesLoaded = totalSpaceTypesLoaded + 1;
          if (totalSpaceTypesLoaded === parsedTypeSpaces.length) {
            this.parseSubsectionTypes();
          }
        })
    );
  }

  parseSubsectionTypes(): void {
    this.progressBarValue = 5;
    this.loadingMessage = 'Reading space subsections types...';
    if (this.parsedSubsectiontypes.length === 0) {
      this.parseSubsections();
    } else {
      this.rowsWithErrors.push({
        row: '',
        code: '',
        msg: 'ErrorSubsectionTypeNotExist',
      });
      this.close();
    }
  }

  parseSubsections(): void {
    this.progressBarValue = 10;
    this.loadingMessage = 'Reading space subsections...';
    let loadSpaces = 0;
    this.parsedNewSpaces.forEach((parsedSpace) => {
      loadSpaces += 1;
      this.prepareSubsections(parsedSpace);
      if (this.parsedNewSpaces.length + this.parsedModifySpaces.length == loadSpaces) {
        this.saveSubsections();
      }
    });

    this.parsedModifySpaces.forEach((parsedSpace) => {
      loadSpaces += 1;
      this.prepareSubsections(parsedSpace);
      if (this.parsedNewSpaces.length + this.parsedModifySpaces.length == loadSpaces) {
        this.saveSubsections();
      }
    });

    if (!this.parsedModifySpaces.length && !this.parsedNewSpaces.length) {
      this.close();
    }
  }

  prepareSubsections(parsedSpace): void {
    this.subsectionNames.forEach((name) => {
      const subsectionType = this.subsectionTypes.find((item) => item.name == name);
      const exists = this.subsections.find(
        (item) => item.name == parsedSpace[name] && item.type.id == subsectionType.id
      );
      if (!exists && parsedSpace[name]) {
        this.parsedSubsection.push({
          name: parsedSpace[name],
          type: subsectionType.id,
        });
      }
    });
  }

  saveSubsections(): void {
    this.progressBarValue = 15;
    this.loadingMessage = 'Saving space subsections...';
    let savedSubsection = 0;

    if (this.parsedSubsection.length === 0) {
      this.parseAndSaveSpaces();
      return;
    }

    const deleteArrayDuplicates = (arr) => {
      const arrMap = arr.map((el) => {
        return [JSON.stringify(el), el];
      });
      return [...new Map(arrMap).values()];
    };
    this.parsedSubsection = deleteArrayDuplicates(this.parsedSubsection);

    this.subcriptions.push(
      from(this.parsedSubsection)
        .pipe(
          concatMap((subsection) => {
            savedSubsection += 1;
            return this.esiteService.insertSubsection(subsection, AuditFunctionalities.CreateMasiveSubsection);
          })
        )
        .subscribe({
          next: (newSubsection) => {
            this.subsections.push(new Subsection(newSubsection));
            if (this.parsedSubsection.length === savedSubsection) {
              this.parseAndSaveSpaces();
            }
          },
          error: () => {
            this.rowsWithErrors.push({
              row: '',
              code: '',
              msg: 'ErrorCreatingSubsection',
            });
            this.close();
          },
        })
    );
  }

  parseAndSaveSpaces(): void {
    if (this.parsedNewSpaces.length === 0 && this.parsedModifySpaces.length === 0) {
      this.close();
    }

    let newSpacesIteration = 0;
    let modifySpacesIteration = 0;

    this.subcriptions.push(
      from(this.parsedNewSpaces)
        .pipe(
          concatMap((parsedSpace) => {
            this.loadingMessage = 'Saving new spaces...';
            this.progressBarValue = 20 + Math.floor((newSpacesIteration / this.parsedNewSpaces.length) * 40);
            newSpacesIteration++;
            return this.insertOrModifySpace(parsedSpace, true, false);
          })
        )
        .subscribe((result) => {
          // There is an error
          if (result[0]) {
            this.loadInitialSpace(result);
            return;
          }

          const space = new Space(result[1]);

          this.loadingMessage = 'Adding resources to spaces...';
          const resourceIds = this.mapResourceCodesToIds(result[2]?.['Recursos'] ?? '');
          this.esiteService.addResourcesToSpace(space.id, resourceIds).subscribe({
            next: () => {
              this.loadMoreSpace(result, space, true, false);
            },
            error: (err) => {
              console.error(`error while adding resources to space ${space.id}`, err);
              this.rowsWithErrors.push({
                row: '',
                code: '',
                msg: `Error while adding resources to space with id ${space.id}`,
              });
              this.close();
            },
          });
        })
    );

    this.subcriptions.push(
      from(this.parsedModifySpaces)
        .pipe(
          concatMap((parsedSpace) => {
            this.loadingMessage = 'Modifying existing spaces...';
            this.progressBarValue = 60 + Math.floor((modifySpacesIteration / this.parsedModifySpaces.length) * 40);
            modifySpacesIteration++;
            return this.insertOrModifySpace(parsedSpace, false, true);
          })
        )
        .subscribe((result) => {
          // Error happened
          if (result[0]) {
            this.loadInitialSpace(result);
            return;
          }

          // Successful request
          const space = new Space(result[1]);

          this.loadingMessage = 'Adding resources to spaces...';
          const resourceIds = this.mapResourceCodesToIds(result[2]?.['Recursos'] ?? '');
          this.esiteService.addResourcesToSpace(space.id, resourceIds).subscribe({
            next: () => {
              this.subcriptions.push(
                this.esiteService
                  .deleteAllSubsectionsFromSpace(space.id, AuditFunctionalities.DeleteMasiveSubsectionFromSpace)
                  .subscribe(() => {
                    this.loadMoreSpace(result, space, false, true);
                  })
              );
            },
            error: (err) => {
              console.error(`error while adding resources to space ${space.id}`, err);
              this.rowsWithErrors.push({
                row: '',
                code: '',
                msg: `Error while adding resources to space with id ${space.id}`,
              });
              this.close();
            },
          });
        })
    );
  }

  /**
   * it takes a comma separated string of resource codes and returns
   * an array of resource ids
   */
  private mapResourceCodesToIds(resourceCodes: string): number[] {
    return resourceCodes
      .split(',')
      .map((x) => x.trim())
      .filter(Boolean)
      .map((code) => this.allResources.find((res) => res.code === code)?.id)
      .filter(Boolean);
  }

  createReservationSpace(result, space): void {
    if (result[2]['Reservable'] == 'SI') {
      const reservationSpace = this.createObjectSpace(space, result);
      this.subcriptions.push(
        this.reservationService
          .createReservationSpace(reservationSpace, AuditFunctionalities.CreateMasiveReservableSpaces)
          .subscribe({
            next: (reservableSpace) => {
              if (result[4].useType == SpaceUseType.Room && result[3].exchangeEmail) {
                this.addExchangeEmailDuplicated(reservableSpace, result);
              } else {
                this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
              }
            },
            error: (error) => {
              this.rowsWithReservationErrors.push({
                row: result[2]['rowNumber'],
                code: result[2]['Código'],
                msg: error.error.error,
              });
              this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
            },
          })
      );
    } else {
      this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
    }
  }

  modifyReservationSpace(result, space): void {
    if (result[2]['Reservable'] === 'SI') {
      const reservationSpace = {
        id: null,
        role: result[3].role,
        needsApproval: result[3].needsApproval,
        approvers: result[3].approvers,
        groupable: result[3].groupable,
      };

      this.subcriptions.push(
        this.reservationService
          .modifyReservableSpace(reservationSpace, space.id, AuditFunctionalities.ModifyMasiveReservableSpace)
          .subscribe({
            next: (reservableSpace) => {
              if (result[4].useType == SpaceUseType.Room && result[3].exchangeEmail) {
                this.addExchangeEmailDuplicated(reservableSpace, result);
              } else if (result[4].useType == SpaceUseType.Room && !result[3].exchangeEmail) {
                this.reservationService
                  .deleteExchangeEmail(
                    reservableSpace.id,
                    AuditFunctionalities.DeleteMasiveReservableSpaceExchangeEmail
                  )
                  .subscribe(() => {
                    this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
                  });
              } else {
                this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
              }
            },
            error: (error) => {
              this.createReservableSpaceFromModify(error, space, result);
            },
          })
      );
    } else {
      this.reservationService
        .deleteReservationSpace(space.id, AuditFunctionalities.DeleteMasiveReservableSpace)
        .subscribe({
          next: () => {
            this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
          },
          error: (error) => {
            if (error.status !== Constants.httpStatusNotFound) {
              this.rowsWithReservationErrors.push({
                row: result[2]['rowNumber'],
                code: result[2]['Código'],
                msg: error.error.error,
              });
            }
            this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
          },
        });
    }
  }

  createReservableSpaceFromModify(error, space, result): void {
    if (error.status === Constants.httpStatusNotFound) {
      const reservationSpace = this.createObjectSpace(space, result);
      this.subcriptions.push(
        this.reservationService
          .createReservationSpace(reservationSpace, AuditFunctionalities.CreateMasiveReservableSpaces)
          .subscribe({
            next: (reservableSpace) => {
              if (result[4].useType == SpaceUseType.Room && result[3].exchangeEmail) {
                this.reservationService
                  .addExchangeEmail(
                    reservableSpace.id,
                    result[3].exchangeEmail,
                    AuditFunctionalities.AddMasiveReservableSpaceExchangeEmail
                  )
                  .subscribe({
                    next: () => {
                      this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
                    },
                    error: (errorOnAddExchangeEmail) => {
                      if (errorOnAddExchangeEmail.status === Constants.httpStatusConflict) {
                        this.rowsWithReservationErrors.push({
                          row: result[2]['rowNumber'],
                          code: result[2]['Código'],
                          msg: 'EmailErrorDuplicated',
                        });
                      } else if (
                        errorOnAddExchangeEmail.status == Constants.httpStatusBadRequest &&
                        errorOnAddExchangeEmail.error.reason
                      ) {
                        this.rowsWithReservationErrors.push({
                          row: result[2]['rowNumber'],
                          code: result[2]['Código'],
                          msg: errorOnAddExchangeEmail.error.reason,
                        });
                      } else {
                        this.rowsWithReservationErrors.push({
                          row: result[2]['rowNumber'],
                          code: result[2]['Código'],
                          msg: 'EmailError',
                        });
                      }
                      this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
                    },
                  });
              } else {
                this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
              }
            },
            error: (errorOnCreateReservationSpace) => {
              this.rowsWithReservationErrors.push({
                row: result[2]['rowNumber'],
                code: result[2]['Código'],
                msg: errorOnCreateReservationSpace.error.error,
              });
              this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
            },
          })
      );
    } else {
      this.rowsWithReservationErrors.push({
        row: result[2]['rowNumber'],
        code: result[2]['Código'],
        msg: error.error.error,
      });
      this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
    }
  }

  incrementSpaceCount(index: number): number {
    index += 1;
    if (index === this.parsedNewSpaces.length + this.parsedModifySpaces.length) {
      this.close();
    }
    return index;
  }

  checkErrorCodes(error: HttpErrorResponse, result: any): void {
    if (error.status === Constants.httpStatusConflict) {
      this.rowsWithReservationErrors.push({
        row: result['rowNumber'],
        code: result['Código'],
        msg: 'EmailErrorDuplicated',
      });
    } else if (error.status === Constants.httpStatusBadRequest && error.error.reason) {
      this.rowsWithReservationErrors.push({
        row: result['rowNumber'],
        code: result['Código'],
        msg: error.error.reason,
      });
    } else {
      this.rowsWithReservationErrors.push({
        row: result['rowNumber'],
        code: result['Código'],
        msg: 'EmailError',
      });
    }
  }

  /**
   * Checks if excel floor image name match uploades image name. If excel or image are not yet uploaded, it returns true
   */
  doesImageNameMatchsExcelName(): boolean {
    if (this.imageName && this.excelImageName) {
      return this.imageName === this.excelImageName;
    } else {
      return true;
    }
  }

  hasCommasInExcelValues(space: Space, spaceType: SpaceType, parsedSpace: Record<string, string>): boolean {
    if (
      space.name.includes(',') ||
      parsedSpace['Tipo de Espacio'].includes(',') ||
      spaceType.name.toString().includes(',') ||
      spaceType.useType.toString().includes(',') ||
      this.checkForCommasInSubsections(parsedSpace)
    ) {
      this.rowsWithErrors.push({
        row: parsedSpace['rowNumber'],
        code: parsedSpace['Código'],
        msg: 'Commas not allowed',
      });
      return true;
    }
    return false;
  }

  checkForCommasInSubsections(parsedSpace: any): boolean {
    return this.subsectionNames.some((subsectionType) => {
      if (!parsedSpace[subsectionType]) {
        parsedSpace[subsectionType] = '';
      }
      return parsedSpace[subsectionType] && parsedSpace[subsectionType].toString().includes(',');
    });
  }

  close(): void {
    if (this.rowsWithErrors.length === 0 && this.rowsWithReservationErrors.length === 0) {
      this.reservationEventService.refreshReservatonEvents().subscribe(() => {
        console.log('refresh');
      });
      this.dialogRef.close({ data: this.floor });
    } else {
      this.reservationEventService.refreshReservatonEvents().subscribe(() => {
        console.log('refresh');
      });
      this.showErrors = true;
    }
    this.submitted = false;
    this.loading = false;
    this.progressBarMode = 'indeterminate';
    this.progressBarValue = 0;
    this.loadingMessage = 'Saving Floor...';
  }

  get allErrors() {
    const errors = this.rowsWithErrors.concat(this.rowsWithReservationErrors);
    errors.sort((a, b) => Number(a.row) - Number(b.row));

    return errors;
  }

  cancel(): void {
    this.submitted = false;
    this.dialogRef.close({ data: this.floor });
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  get f() {
    return this.floorFormGroup.controls;
  }

  insertOrModifySpace(parsedSpace, insertSpace: boolean, modifySpace: boolean): any {
    const space = new Space('');
    space.setExcelData(parsedSpace);
    const reservationSpace = new ReservationSpace('');
    reservationSpace.setExcelReservationData(parsedSpace);
    const spaceType = this.spaceTypes.find((item) => item.code === parsedSpace['Tipo de Espacio']);
    if (!spaceType) {
      this.rowsWithErrors.push({
        row: parsedSpace['rowNumber'],
        code: parsedSpace['Código'],
        msg: 'SpaceTypeEmpty',
      });
      this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
      return;
    }

    space.type = spaceType.id;
    if (this.hasCommasInExcelValues(space, spaceType, parsedSpace)) {
      this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
      return [];
    }

    if (insertSpace) {
      return this.esiteService.insertSpace(space, this.floor.id, AuditFunctionalities.CreateMasiveSpaces).pipe(
        map((result) => [false, result, parsedSpace, reservationSpace, spaceType]),
        catchError((err) => of([true, err, parsedSpace]))
      );
    }
    if (modifySpace) {
      return this.esiteService.modifySpace(space, this.floor.id, AuditFunctionalities.ModifyMasiveSpaces).pipe(
        map((result) => [false, result, parsedSpace, reservationSpace, spaceType]),
        catchError((err) => of([true, err, parsedSpace]))
      );
    }
  }

  loadInitialSpace(result): void {
    if (result[1].status === Constants.httpStatusConflict) {
      this.rowsWithErrors.push({
        row: result[2]['rowNumber'],
        code: result[2]['Código'],
        msg: 'httpStatusConflict',
      });
    } else {
      this.rowsWithErrors.push({ row: result[2]['rowNumber'], code: result[2]['Código'], msg: 'GenericError' });
    }
    this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
  }

  loadMoreSpace(result, space, createSpace: boolean, modifySpace: boolean) {
    const addSubsectionObservables = [];
    this.subsectionNames.forEach((name) => {
      if (result[2][name]) {
        const subsectionTypeID = this.subsectionTypes.find((item) => item.name == name).id;
        const subsection = this.subsections.find(
          (item) => item.name == result[2][name] && item.type.id == subsectionTypeID
        );
        if (subsection) {
          addSubsectionObservables.push(
            this.esiteService
              .addSubsection(space.id, subsection.id, AuditFunctionalities.AddMasiveSubsectionToSpace)
              .pipe(
                catchError((error) => {
                  if (error.status === Constants.httpStatusConflict) {
                    this.rowsWithErrors.push({
                      row: result[2]['rowNumber'],
                      code: result[2]['Código'],
                      msg: error.error.reason,
                    });
                  } else {
                    this.rowsWithErrors.push({
                      row: result[2]['rowNumber'],
                      code: result[2]['Código'],
                      msg: 'An error happened when creating the subsection',
                    });
                  }
                  return of([]);
                })
              )
          );
        }
      }
    });

    if (createSpace) {
      if (addSubsectionObservables.length) {
        this.subcriptions.push(
          forkJoin(addSubsectionObservables).subscribe(() => {
            this.createReservationSpace(result, space);
          })
        );
      } else {
        this.createReservationSpace(result, space);
      }
    } else if (modifySpace) {
      if (addSubsectionObservables.length) {
        this.subcriptions.push(
          forkJoin(addSubsectionObservables).subscribe(() => {
            this.modifyReservationSpace(result, space);
          })
        );
      } else {
        this.modifyReservationSpace(result, space);
      }
    }
  }

  createObjectSpace(space, result) {
    return {
      id: space.id,
      role: result[3].role,
      needsApproval: result[3].needsApproval,
      approvers: result[3].approvers,
      groupable: result[3].groupable,
    };
  }

  addExchangeEmailDuplicated(reservableSpace, result): void {
    this.reservationService
      .addExchangeEmail(
        reservableSpace.id,
        result[3].exchangeEmail,
        AuditFunctionalities.AddMasiveReservableSpaceExchangeEmail
      )
      .subscribe({
        next: () => {
          this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
        },
        error: (error: HttpErrorResponse) => {
          this.checkErrorCodes(error, result[2]);
          this.totalSpacesModified = this.incrementSpaceCount(this.totalSpacesModified);
        },
      });
  }

  resourcesOnPoint(): void {
    this.svgDataToSend.pointsXY = new Map();
    const pointGroup = [];
    from(this.svgDataToSend.points.filter((item) => item).filter((item) => item.positionX && item.positionY))
      .pipe(
        groupBy((point) => point.positionX + '#' + point.positionY),
        mergeMap((group) => zip(of(group.key), group.pipe(toArray()))),
        finalize(() => {
          pointGroup.forEach((pg) => {
            this.svgDataToSend.pointsXY.set(pg[0], pg[1]);
          });
        })
      )
      .subscribe((resp) => pointGroup.push(resp));
  }
}
