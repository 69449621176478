import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { User } from 'src/app/models/auth/User.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { Incident } from 'src/app/models/habitat/Incident.class';
import { environment } from '../../../environments/environment';
import { HttpService } from '../common/http.service';
import { HttpMethods } from './../../models/HttpMethods.enum';
import { AppConstants } from 'src/app/shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class HabitatService {
  private url = `${environment.habitat_backend_url}`;
  private protocol = `${environment.backend_protocol}`;

  constructor(private httpService: HttpService) {}

  requestToHabitat(type, method, body?, headers?): Observable<any> {
    return this.httpService.httpRequest(this.protocol + this.url, type, method, body, headers);
  }

  requestToHabitatMultipart(type, method, body?, headers?): Observable<any> {
    return this.httpService.httpRequestMultipart(this.protocol + this.url, type, method, body, headers);
  }
  requestToHabitatImage(method): Observable<any> {
    return this.httpService.httpRequestImage(this.protocol + this.url, method);
  }

  getHabitatAssetsUrl(): string {
    const protocol = `${environment.backend_protocol}`;
    const url = `${environment.habitat_backend_url}`;
    return protocol + url + 'v2/assets/';
  }

  getFloors(): Observable<any> {
    const method = 'v2/floors';
    return this.requestToHabitat(HttpMethods.GET, method);
  }

  getFloor(floorId: number): Observable<any> {
    const method = 'v2/floors/' + floorId;
    return this.requestToHabitat(HttpMethods.GET, method);
  }

  getFloorsByBuilding(idBuilding: number): Observable<any> {
    const method = 'v2/floors?building=' + idBuilding;
    return this.requestToHabitat(HttpMethods.GET, method);
  }

  getBuildings(): Observable<any> {
    const method = 'v2/buildings';
    return this.requestToHabitat(HttpMethods.GET, method);
  }

  getBuilding(buildingId): Observable<any> {
    if (buildingId > 0) {
      const method = 'v2/buildings/' + buildingId;
      return this.requestToHabitat(HttpMethods.GET, method);
    }
  }

  getCities(): Observable<any> {
    const method = 'v2/cities';
    return this.requestToHabitat(HttpMethods.GET, method);
  }

  getCity(cityId): Observable<any> {
    const method = 'v2/cities/' + cityId;
    return this.requestToHabitat(HttpMethods.GET, method);
  }

  getBuildingsByCity(city): Observable<any> {
    const method = 'v2/buildings?city=' + city;
    return this.requestToHabitat(HttpMethods.GET, method);
  }

  /**
   *
   * @param groupID Id of the group from which recover buildings
   * @returns
   */
  getBuildingsByGroup(groupID: number): Observable<any> {
    const method = 'v2/buildings?group=' + groupID;
    return this.requestToHabitat(HttpMethods.GET, method);
  }

  getIncidentsCategories(): Observable<any> {
    const method = 'v2/incidents/subjects';
    return this.requestToHabitat(HttpMethods.GET, method);
  }

  confirmBuilding(buildingId, functionality: string = AuditFunctionalities.ConfirmBuilding): Observable<any> {
    const method = 'v2/buildings/' + buildingId;
    const body = { confirmed: true };
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToHabitat(HttpMethods.PATCH, method, body, headers);
  }

  insertIncident(incident: Incident): Observable<any> {
    const method = 'v2/incidents';
    return this.requestToHabitat(HttpMethods.POST, method, incident);
  }

  insertBuilding(building: Building, functionality: string = AuditFunctionalities.CreateBuilding): Observable<any> {
    const method = 'v2/buildings';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToHabitat(HttpMethods.POST, method, building.toBackenFormat(), headers);
  }

  insertFloor(
    floor: Floor,
    buildingId: number,
    functionality: string = AuditFunctionalities.CreateFloor
  ): Observable<any> {
    const method = 'v2/floors';
    const formData = new FormData();
    formData.append('img', floor.img);
    formData.append('name', floor.name.toString());
    formData.append('mapOffsetX', floor.mapOffsetX.toString());
    formData.append('mapOffsetY', floor.mapOffsetY.toString());
    formData.append('scale', floor.scale.toString());
    formData.append('building', buildingId.toString());
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToHabitatMultipart(HttpMethods.POST, method, formData, headers);
  }

  insertRelationUserCity(userId, cityId): Observable<any> {
    const method = 'v2/user/' + userId + '/cityRelation/' + cityId;
    return this.requestToHabitat(HttpMethods.POST, method, {});
  }

  modifyProfileImage(image, functionality: string = AuditFunctionalities.ModifyUser): Observable<any> {
    const method = 'v2/user/image';
    const formData = new FormData();
    formData.append('img', image);
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToHabitatMultipart(HttpMethods.PUT, method, formData, headers);
  }

  modifyFloor(floor: Floor, functionality: string = AuditFunctionalities.ModifyFloor): Observable<any> {
    const method = 'v2/floors/' + floor.id;
    const formData = new FormData();
    formData.append('img', floor.img);
    formData.append('scale', floor.scale.toString());
    formData.append('mapOffsetX', floor.mapOffsetX.toString());
    formData.append('mapOffsetY', floor.mapOffsetY.toString());
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToHabitatMultipart(HttpMethods.PATCH, method, formData, headers);
  }

  getFloorImage(floorID): Observable<any> {
    const method = 'v2/floors/' + floorID + '/image';
    return this.requestToHabitatImage(method);
  }
  getUserImage(): Observable<any> {
    const method = 'v2/user/image';
    return this.requestToHabitatImage(method);
  }

  getUser(): Observable<User> {
    const method = 'v2/user';
    return this.requestToHabitat(HttpMethods.GET, method);
  }
  getUserbyId(UserID: number): Observable<User> {
    const method = 'v2/user/' + UserID;
    return this.requestToHabitat(HttpMethods.GET, method);
  }
}
