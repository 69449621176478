import { ResourceType } from './ResourceType.class';

export class Resource {
  id?: number;
  code: string;
  name: string;
  typeId: number;
  type?: ResourceType;
  icon?: string;

  constructor(msg?: Partial<Resource>) {
    this.id = msg?.id;
    this.code = msg?.code ?? '';
    this.name = msg?.name ?? '';
    this.typeId = msg?.typeId ?? 0;
    this.type = msg?.type;
  }

  static fromBackendFormat(data: APIResource): Resource {
    return new Resource({
      id: data.id,
      code: data.code,
      name: data.name,
      typeId: typeof data.type === 'number' ? data.type : data.type.id,
      type: typeof data.type !== 'number' ? data.type : undefined,
      icon: typeof data.type !== 'number' ? data.type.icon : undefined,
    });
  }

  toBackendFormat(): APIResource {
    return {
      id: this.id,
      code: this.code,
      name: this.name,
      type: this.typeId,
    };
  }
}

export type APIResource = {
  id?: number;
  code: string;
  name: string;
  type: number | ResourceType; // type_id
};
