import { Injectable } from '@angular/core';
import { ChartOptionData } from 'src/app/models/charts/ChartOptionData.class';

@Injectable({
  providedIn: 'root',
})
export class ChartService {
  creataDataEstructure(data: ChartOptionData[]): any {
    const dataset = { data: [], backgroundColor: [], weight: 4 };
    const labels = [];
    const legendItems = [];
    data.forEach((item) => {
      if (item.inlegend) {
        legendItems.push(item);
      }
      const percentage = item.getPercentage();
      dataset.data.push(percentage.toFixed(2));
      dataset.backgroundColor.push(item.color);
      dataset.weight = 4;
      labels.push(item.title);
    });
    return { datasets: [dataset], labels, legend: legendItems };
  }
}
