import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Constants } from 'src/app/models/Constants.const';
import { ReservationUser } from 'src/app/models/reservation/ReservationUser.class';
import { ReservationUsersFiltered } from 'src/app/models/reservation/ReservationUsersFiltered.class';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { continuousReservationStatus } from 'src/app/models/reservation/ReservationStatus.enum';
import { LanguageService } from 'src/app/services/common/language.service';

@Component({
  selector: 'app-continuous-reservations-filter',
  templateUrl: './continuous-reservations-filter.component.html',
  styleUrls: ['./continuous-reservations-filter.component.css'],
})
export class ContinuousReservationsFilterComponent implements OnInit {
  filterFormGroup: UntypedFormGroup;
  startTime: Date = new Date();
  endTime: Date = new Date();
  users: ReservationUser[] = [];
  startDateText: Date;
  endDateText: Date;
  isValidFunctionForm = false;
  isValidDateForm = true;
  CReservationsresultsTableLoading: boolean;
  statusOptions = Object.values(continuousReservationStatus);

  constructor(
    private formBuilder: UntypedFormBuilder,
    private reservationService: ReservationService,
    private datepipe: DatePipe,
    private globalService: GlobalService
  ) {
    this.getUsers();
  }

  ngOnInit(): void {
    this.cleanRangeField();
    this.createForm();
  }

  createForm(): void {
    this.filterFormGroup = this.formBuilder.group({
      CRuser: ['', []],
      CRspaceCode: ['', []],
      CRstatus: ['', []],
      CRdateRange: ['', []],
      CRstartDateText: ['', []],
      CRendDateText: ['', []],
    });
  }

  getUsers(): void {
    this.reservationService
      .getUsersFiltered(1, Constants.maxInt, null, null, null, null)
      .subscribe((res: ReservationUsersFiltered) => {
        this.users = res.users
          .map((user) => ({ ...user, nameComplete: user.name.concat(' ').concat(user.surname) }))
          .sort((a, b) => (a.nameComplete > b.nameComplete ? 1 : b.nameComplete > a.nameComplete ? -1 : 0));
      });
  }

  get f() {
    return this.filterFormGroup.controls;
  }

  searcher = (search: string, pageNumber: number, pageSize: number): Observable<ReservationUser[]> => {
    // searcher must return Observable in this method use rxjs of

    const filteredUsers1 = this.users
      .filter((z) =>
        z.nameComplete
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            search
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      )
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    if (!filteredUsers1.length) {
      this.filterFormGroup.controls['CRuser'].setValue(null);
    }
    return of(filteredUsers1);
  };

  clearField(event: any): void {
    switch (event) {
      case 'user': {
        this.filterFormGroup.controls['CRuser'].setValue(null);
        this.validForm();
        break;
      }
      case 'status': {
        this.filterFormGroup.controls['CRstatus'].setValue(null);
        this.validForm();
        break;
      }
      case 'spaceCode': {
        this.filterFormGroup.controls['CRspaceCode'].setValue(null);
        this.validForm();
        break;
      }

      case 'dateRange': {
        this.filterFormGroup.controls['CRstartDateText'].setValue(null);
        this.filterFormGroup.controls['CRendDateText'].setValue(null);
        this.filterFormGroup.controls['CRdateRange'].setValue(null);
        this.startDateText = undefined;
        this.endDateText = undefined;
        this.cleanRangeField();
        this.validForm();
        break;
      }
    }
  }

  cleanRangeField(): void {
    this.startTime = new Date(new Date().setHours(0, 0, 0));
    this.endTime = new Date(new Date().setHours(23, 59, 0));
  }

  confirmRange(): void {
    this.filterFormGroup.controls['CRstartDateText'].setValue(this.startTime);
    this.filterFormGroup.controls['CRendDateText'].setValue(this.endTime);
    this.endTime.setHours(23, 59, 0);
    this.filterFormGroup.controls['CRdateRange'].setValue(
      this.datepipe.transform(this.startTime, 'dd/MM/yyyy') +
        ' - ' +
        this.datepipe.transform(this.endTime, 'dd/MM/yyyy')
    );
  }

  validForm(): boolean {
    this.isValidFunctionForm = true;
    if (
      (this.f.CRuser.value == '' || this.f.CRuser.value == null) &&
      (this.f.CRspaceCode.value == '' || this.f.CRspaceCode.value == null) &&
      (this.f.CRdateRange.value == '' || this.f.CRdateRange.value == null) &&
      (this.f.CRstatus.value == '' || this.f.CRstatus.value == null)
    ) {
      this.isValidFunctionForm = false;
      return false;
    } else if (!this.isValidDateForm) {
      return false;
    }

    return true;
  }

  clearOneFieldRequired(): void {
    this.isValidFunctionForm = true;
  }

  onSearch(): void {
    this.globalService.isCReservationsResultsTableLoading$.subscribe(
      (CReservationsresultsTableLoading) => (this.CReservationsresultsTableLoading = CReservationsresultsTableLoading)
    );
    if (this.validForm()) {
      this.globalService.setCReservationsFilterPArameters(this.filterFormGroup.value);
    }
  }
}
