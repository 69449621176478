import { SubsectionType } from './SubsectionType.class';

export class Subsection {
  id: number;
  name: string;
  type: SubsectionType;
  floors: [number];

  constructor(msg) {
    this.id = msg.id || null;
    this.name = msg.name || '';
    this.type = msg.type ? new SubsectionType({ id: msg.type }) : new SubsectionType('');
    this.floors = msg.floors ? msg.floors : [];
  }

  toBackenFormat(msg): any {
    const backendObject = {
      name: msg.name,
      type: msg.type.id || msg.type,
    };
    return backendObject;
  }
}
