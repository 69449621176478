export function groupBy<T>(list: Array<T>, selector: (t: T) => any): Record<any, Array<T>> {
  const groups: Record<any, Array<T>> = {} as Record<any, Array<T>>;
  list.forEach((item) => {
    const key = selector(item);
    if (!groups[key]) groups[key] = [];
    groups[key].push(item);
  });
  return groups;
}

/**
 * returns a list of unique items by which the uniqueness is determined by the selector function,
 *
 * @param list a list of items to filter
 * @param selector The function to determine the uniqueness of the item
 * @returns a list of unique items filtered by the selector function
 */
export function uniqueBy<T>(list: Array<T>, selector: (t: T) => any): Array<T> {
  const items = new Map<any, T>();
  list.forEach((item) => {
    const key = selector(item);
    items.set(key, item);
  });
  return [...items.values()];
}
