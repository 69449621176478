export enum UserPermissions {
  AdvancedReports = 'AdvancedReports', //  Permiso para obtener la url y token de informes avanzados (PowerBI)
  Users = 'Users', //  Permiso para leer/escribir usuarios
  Buildings = 'Buildings', //  Permiso para leer/escribir edificios
  HiddenBuildings = 'HiddenBuildings', //  Permiso para leer/escribir edificios sin confirmar
  Cities = 'Cities', //  Permiso para leer/escribir ciudades
  Floors = 'Floors', //  Permiso para leer/escribir plantas
  Incidents = 'Incidents', //  Permiso para generar incidencias
  Tags = 'Tags', //  Permiso para leer/escribir Tags
  Groups = 'Groups', //  Permiso para leer/escribir grupos
  Roles = 'Roles', //  Permiso para leer/escribir Roles

  RealTimeReservations = 'RealTimeReservations', //  Permiso para acceso al websocket de tiempoReal de Reservas
  BuildingConfig = 'BuildingConfig', //  Permiso leer/escribir la configuración por edificio de Reservas
  ContinuousReservations = 'ContinuousReservations', //  Permiso para leer/escribir reservas continuas
  ReservableSpaces = 'ReservableSpaces', //  Permiso para leer/escribir espacios reservables
  OwnReservations = 'OwnReservations', //  Permiso para leer/escribir reservas propias // TODO si está a FALSE no deja hacer reserva
  DelegatedReservations = 'DelegatedReservations', //  Permiso para leer/escribir reservas delegadas

  Alarms = 'Alarms', //  Permiso para leer/escribir alarmas en Esite
  DevicesEsite = 'DevicesEsite', //  Permiso para leer/escribir dispositivos de Esite
  Devices = 'Devices', //  Permiso para leer/escribir dispositivos de Esite
  GraphicalAreas = 'GraphicalAreas', //  Permiso para leer/escribir áreas gráficas
  Historic = 'Historic', //  Permiso para el historico de Esite
  Spaces = 'Spaces', //  Permiso para leer/escribir espacios
  SpaceTypes = 'SpaceTypes', //  Permiso para leer/escribir tipos de espacio
  Subsections = 'Subsections', //  Permiso para leer/escribir subsecciones
  SubsectionTypes = 'SubsectionTypes', //  Permiso para leer/escribir tipos de subsecciones
  Resources = 'Resources', //  Permiso para leer/escribir recursos

  Central = 'Central', //  Acceso a todo lo que tenga que ver con central

  RealTime = 'RealTime', //  Permiso para el acceso al websocket de tiempo real de Conteo
  ConfiguredAlarms = 'ConfiguredAlarms', //  Permiso para leer/escribir alarmas configuradas en Conteo
  OccupationAlarms = 'OccupationAlarms', //  Permiso para leer/escribir alarmas de ocupacion en Conteo
  Cameras = 'Cameras', //  Permiso para leer/escribir en las camaras (dispositivos) de Conteo
  Locations = 'Locations', //  Permiso para leer/escribir localizaciones en Conteo
  Zones = 'Zones', //  Permiso para leer/escribir zonas en Conteo

  Metadata = 'Metadata', //  Permiso sobre General-Api para la carga del excel de metadatos

  Audit = 'Audit', //  Permiso para leer/escribir auditorías
  Entities = 'Entities', //  Permiso para leer/escribir Entidades de Audit
  Functionalities = 'Functionalities', //  Permiso para leer/escribir Funcionalidades en Audit

  HumanTrackingData = 'HumanTrackingData',
  WebAdministration = 'WebAdministration', // Permiso para acceder a WebAdministration
  WebReservations = 'WebReservations', // Permiso para acceder a WebReservations
  WebCentral = 'WebCentral', // Permiso para acceder a Web Central
}
