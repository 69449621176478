import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { NGXLogger } from 'ngx-logger';
import { MessageService } from 'primeng/api';
import { AuthService } from './services/auth/auth.service';
import { DeviceService } from './services/common/device.service';
import { AppConstants } from './shared/AppConstants';
import { LanguageService } from './services/common/language.service';
import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  languages = ['es', 'en', 'it'];
  /**
   * Check if device is of type mobile (mobile - table) or desktop via User Agent and Information of Browser.
   *
   * Run initial login sequence (check if there is a valid access and id token in session storage)
   * Update isAuthenticated and isDone observables
   * If redirect from AD FS, process access_token
   * @param authService AuthService
   */
  constructor(
    private authService: AuthService,
    private deviceDetectorService: DeviceDetectorService,
    private deviceService: DeviceService,
    private messageService: MessageService,
    private logger: NGXLogger,
    private languageService: LanguageService,
    private adapter: DateAdapter<Date>
  ) {
    this.deviceService.setMobile(this.deviceDetectorService.isMobile());
    this.deviceService.setTablet(this.deviceDetectorService.isTablet());
    this.deviceService.setDesktop(this.deviceDetectorService.isDesktop());
    this.deviceService.setDeviceInfo(this.deviceDetectorService.getDeviceInfo());

    this.authService.runInitialLoginSequence();

    // Disable double click
    document.addEventListener(
      'dblclick',
      function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
      },
      true
    );

    this.messageService.messageObserver.subscribe((messageObs) => {
      this.logger.info(messageObs);
    });

    this.languageService.curLanguage.subscribe((lan) => {
      this.adapter.setLocale(lan);
    });
  }

  ngOnInit(): void {
    registerLocaleData(localeEs, 'es');
    registerLocaleData(localeEn, 'en');
    registerLocaleData(localeIt, 'it');
    this.setLanguage();
  }

  onReject(): void {
    this.messageService.clear(AppConstants.MESSAGE_POSITION_CENTER);
  }

  setLanguage(): void {
    if (!localStorage.getItem('lan')) {
      localStorage.setItem('lan', 'es');
    }
    const lan = localStorage.getItem('lan');
    if (this.languages.includes(lan)) {
      this.languageService.changeCurLanguage(lan);
    }
  }
}
