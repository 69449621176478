import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { GlobalService } from 'src/app/services/common/global.service';
import { Permission } from './../../models/auth/Role.class';
import { User } from './../../models/auth/User.class';
import { UserPermissions } from './../../models/auth/UserPermissions.enum';
import { AuthService } from './../../services/auth/auth.service';

/**
 * Check if a protected URL can be accessed.
 * Permission'user to web access
 */
@Injectable({
  providedIn: 'root',
})
export class ConditionalGuard implements CanActivate {
  private userLogged: User = null;
  private permissions: Permission[] = [];

  constructor(private authService: AuthService, private globalService: GlobalService) {
    this.globalService.userLogged$.subscribe((user: User) => {
      if (user) {
        this.userLogged = user;
        this.messageAndRedirect();
      }
    });
  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): any {
    if (this.userLogged) {
      this.messageAndRedirect();
      return false;
    }
    return true;
  }

  messageAndRedirect(): void {
    this.permissions = this.userLogged?.permissions;
    if (!this.permissions?.map((permission) => permission.name).includes(UserPermissions.WebAdministration)) {
      this.authService.redirectToNotPermission();
    }
  }
}
