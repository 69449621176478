import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Building } from 'src/app/models/habitat/Building.class';
import { HabitatService } from 'src/app/services/backend/habitat.service';

import { City } from './../../../../../models/habitat/City.class';

@Component({
  selector: 'app-building-form',
  templateUrl: './building-form.component.html',
  styleUrls: ['./building-form.component.css'],
})
export class BuildingFormComponent implements OnInit {
  buildingFormGroup: UntypedFormGroup;
  submitted = false;
  edit = false;
  loading = false;
  building;
  modalTitle = '';
  buildingCity;
  selectedGroup;
  cities = [];
  duplicateName = false;
  disabledSave = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private habitatService: HabitatService,
    private dialogRef: MatDialogRef<BuildingFormComponent>
  ) {
    this.habitatService.getCities().subscribe((cities) => {
      cities.forEach((element) => {
        const city = new City(element);
        this.cities.push(city);
      });
    });
  }

  ngOnInit(): void {
    this.modalTitle = 'Create space';
    this.buildingFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]], //Avoid the input of commas.
      city: ['', [Validators.required]],
      address: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      latitude: ['', [Validators.min(-90), Validators.max(90)]],
      longitude: ['', [Validators.min(-180), Validators.max(180)]],
    });
  }

  insertBuilding(): void {
    this.duplicateName = false;
    this.submitted = true;
    /**
     * If user specify a value for latitude and doesn't set a value for longitude, or vice versa, show an error
     */
    if (this.buildingFormGroup.get('longitude')?.value && !this.buildingFormGroup.get('latitude')?.value) {
      setTimeout(() => {
        this.buildingFormGroup.get('latitude')?.markAsDirty();
        this.buildingFormGroup.get('latitude')?.markAsTouched();
        this.buildingFormGroup.get('latitude')?.setErrors({ requiredCoordinate: true });
      }, 1);
      return;
    }
    this.buildingFormGroup.get('latitude')?.setErrors({ requiredCoordinate: null });
    this.buildingFormGroup.get('latitude')?.updateValueAndValidity();
    if (this.buildingFormGroup.get('latitude')?.value && !this.buildingFormGroup.get('longitude')?.value) {
      setTimeout(() => {
        this.buildingFormGroup.get('longitude')?.markAsDirty();
        this.buildingFormGroup.get('longitude')?.markAsTouched();
        this.buildingFormGroup.get('longitude')?.setErrors({ requiredCoordinate: true });
      }, 1);
      return;
    }
    this.buildingFormGroup.get('longitude')?.setErrors({ requiredCoordinate: null });
    this.buildingFormGroup.get('longitude')?.updateValueAndValidity();
    if (this.buildingFormGroup.invalid) {
      return;
    }
    this.disabledSave = true;
    this.loading = true;
    const buildingToInsert = new Building(this.buildingFormGroup.value);
    buildingToInsert.city = this.buildingFormGroup.value.city;
    this.habitatService.insertBuilding(buildingToInsert).subscribe({
      next: (building) => {
        const newBuilding = new Building(building);
        this.close(newBuilding);
      },
      error: () => {
        this.duplicateName = true;
        this.loading = false;
        this.disabledSave = false;

        /**
         * Timeout is necessary as errors are wiped out
         * (see https://github.com/angular/angular/issues/19170)
         */
        setTimeout(() => {
          this.buildingFormGroup.get('name')?.markAsDirty();
          this.buildingFormGroup.get('name')?.markAsTouched();
          this.buildingFormGroup.get('name')?.setErrors({ duplicate: true });
        }, 1);
      },
    });
  }

  close(data): void {
    this.submitted = false;
    this.edit = false;
    this.loading = false;
    this.cities = [];
    this.dialogRef.close({ data });
  }

  get f() {
    return this.buildingFormGroup.controls;
  }
}
