import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, Subscription } from 'rxjs';
import { InfoModalComponent } from 'src/app/components/shared/info-modal/info-modal.component';
import { Area } from 'src/app/models/esite/Area.class';
import { Subsection } from 'src/app/models/esite/Subsection.class';
import { SubsectionType } from 'src/app/models/esite/SubsectionType.class';
import { City } from 'src/app/models/habitat/City.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { InfoModalData } from 'src/app/models/InfoModalData.class';
import { ContinuousReservation } from 'src/app/models/reservation/ContinuousReservation';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { EsiteService } from 'src/app/services/backend/esite.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { DateService } from 'src/app/services/common/date.service';

import { Building } from './../../../../../models/habitat/Building.class';

@Component({
  selector: 'app-new-continuous-reserve',
  templateUrl: './new-continuous-reserve.component.html',
  styleUrls: ['./new-continuous-reserve.component.scss'],
  providers: [],
})
export class NewContinuousReserveComponent implements OnInit, OnDestroy {
  userID: number;
  filterFormGroup!: UntypedFormGroup;
  selectedStartDate: any;
  selectedEndDate: any;
  cities: City[] = [];
  buildings!: Building[];
  floors!: Floor[];
  submitted = false;
  selectedCity: City | any;
  selectedFloor: any | null;
  selectedSearchCriteria = 0;
  selectedBuilding: Building | any;
  selectedOption: any = 0;
  selectedSpace;
  options: Subsection[] = [];
  searchCriterias!: SubsectionType[];
  reservableSpaces: ReservationSpace[] = [];
  disableEndDate = true;
  allDay = false;
  startTime: '';
  endTime: '';
  modalTitle = 'New continuous reservation';
  isEditing = false;
  canChangeEndDate = false;
  canWriteReservations = false;

  continuousReservationInfo: any;
  loading = true;
  subcriptions: Subscription[] = [];
  initEndTime = '';
  endStartTime = '';
  minStartDate = this.dateService.addDays(new Date(), 1);
  minEndDate = this.minStartDate;
  utcActive = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private habitatService: HabitatService,
    private translateService: TranslateService,
    private esiteService: EsiteService,
    private reservationService: ReservationService,
    private dateService: DateService,
    public datepipe: DatePipe,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<NewContinuousReserveComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.userID = data.user;
    this.continuousReservationInfo = data.continuousReservationInfo;
    this.canWriteReservations = this.data.canWriteReservations;
  }

  ngOnInit(): void {
    this.getCities();
    this.filterFormGroup = this.formBuilder.group({
      city: [{ value: '', disabled: !this.canWriteReservations }, [Validators.required]],
      building: [{ value: '', disabled: true }, Validators.required],
      floor: [{ value: '', disabled: true }, Validators.required],
      searchCriteria: { value: '', disabled: true },
      option: { value: '', disabled: true },
      space: [{ value: '', disabled: true }, [Validators.required]],
      startDate: [{ value: this.selectedStartDate, disabled: !this.canWriteReservations }, [Validators.required]],
      startTime: { value: this.startTime, disabled: !this.canWriteReservations },
      endTime: { value: this.endTime, disabled: !this.canWriteReservations },
      endDate: { value: this.selectedEndDate, disabled: !this.canWriteReservations || this.disableEndDate },
      disableEndDate: [{ value: this.disableEndDate, disabled: !this.canWriteReservations }, [Validators.required]],
      allDay: [{ value: this.allDay, disabled: !this.canWriteReservations }, [Validators.required]],
      utc: [{ value: this.utcActive, disabled: !this.canWriteReservations }],
    });
    if (this.continuousReservationInfo) {
      this.modalTitle = 'Edit continuous reservation';
      this.isEditing = true;
      this.getSpaceInfo();
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  weekendFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  };

  close(cancel): void {
    this.dialogRef.close(cancel);
  }

  getCities(): void {
    this.subcriptions.push(
      this.habitatService.getCities().subscribe((cities) => {
        this.cities = cities;
      })
    );
  }

  getSpaceInfo(): void {
    this.subcriptions.push(
      this.reservationService.getRservationSpaceInfo(this.continuousReservationInfo.reservablespacesid).subscribe({
        next: (spaceInfo: ReservationSpace) => {
          this.selectedSpace = spaceInfo;
          this.subcriptions.push(
            this.habitatService.getFloor(spaceInfo.floor).subscribe({
              next: (floor: Floor) => {
                this.selectedFloor = floor;
                this.subcriptions.push(
                  this.habitatService.getBuilding(floor.building).subscribe((building) => {
                    this.selectedBuilding = building;
                    this.selectedCity = this.cities.find((item) => item.id == building.city);
                    this.filterFormGroup.controls['city'].setValue(this.selectedCity);
                    this.filterFormGroup.controls['startTime'].setValue(this.continuousReservationInfo.startTime);
                    this.filterFormGroup.controls['endTime'].setValue(this.continuousReservationInfo.endTime);
                    this.changeValues({ source: { _id: 'city' } });

                    this.filterFormGroup.controls['startDate'].setValue(
                      new Date(this.continuousReservationInfo.startDate)
                    );

                    this.canChangeEndDate = true;
                    if (this.continuousReservationInfo.endDate) {
                      this.disableEndDate = false;
                      this.filterFormGroup.controls['disableEndDate'].setValue(false);
                      this.filterFormGroup.controls['endDate'].enable();
                      this.filterFormGroup.controls['endDate'].setValue(
                        new Date(this.continuousReservationInfo.endDate)
                      );
                    }
                  })
                );
              },
              error: () => {
                this.loading = false;
              },
            })
          );
        },
        error: () => {
          this.loading = false;
        },
      })
    );
  }

  setDisabledFields(): void {
    this.filterFormGroup.controls['city'].disable();
    this.filterFormGroup.controls['building'].disable();
    this.filterFormGroup.controls['floor'].disable();
    this.filterFormGroup.controls['searchCriteria'].disable();
    this.filterFormGroup.controls['option'].disable();
    this.filterFormGroup.controls['space'].disable();
    this.filterFormGroup.controls['startDate'].disable();
    this.filterFormGroup.controls['allDay'].disable();
    this.minEndDate = this.dateService.addDays(this.filterFormGroup.controls['startDate'].value, 0);
  }

  changeValues(event: any): void {
    if (this.filterFormGroup.value.city && event.source._id == 'city') {
      this.subcriptions.push(
        this.habitatService
          .getBuildingsByCity(this.filterFormGroup.value.city.id)
          .subscribe((buildings: Building[]) => {
            this.floors = [];
            this.buildings = buildings;

            this.filterFormGroup.controls['building'].enable();
            if (this.selectedBuilding) {
              this.filterFormGroup.controls['building'].setValue(
                this.buildings.find((item) => item.id == this.selectedBuilding.id)
              );
              this.changeValues({ source: { _id: 'building' } });
            }
          })
      );
      // this.clearField('building');
      this.options = [];
      this.selectedOption = 0;
      this.filterFormGroup.value.option = null;
    }
    if (this.filterFormGroup.value.building && event.source?._id == 'building') {
      this.subcriptions.push(
        this.habitatService.getFloorsByBuilding(this.filterFormGroup.value.building.id).subscribe((floors: Floor[]) => {
          this.floors = floors;
          this.filterFormGroup.controls['floor'].enable();
          if (this.selectedFloor) {
            this.filterFormGroup.controls['floor'].setValue(
              this.floors.find((item) => item.id == this.selectedFloor.id)
            );
            this.changeValues({ source: { _id: 'floor' } });
          }
        })
      );
      // this.clearField('floor');
      this.options = [];
      this.selectedOption = 0;
      this.filterFormGroup.value.option = null;
    }
    if (this.filterFormGroup.value.floor && event.source._id == 'floor') {
      this.getReservableSpaces();
      if (!this.searchCriterias?.length) {
        this.subcriptions.push(
          this.esiteService.getSubsectionTypes().subscribe((subsectionTypes: SubsectionType[]) => {
            this.searchCriterias = subsectionTypes;
            this.searchCriterias.push({ id: -1, name: this.translateService.instant('Area'), maxFloors: 1 });
            if (this.isEditing) {
              this.filterFormGroup.controls['searchCriteria'].disable();
            } else {
              this.filterFormGroup.controls['searchCriteria'].enable();
            }
            if (this.selectedSearchCriteria) {
              this.filterFormGroup.controls['searchCriteria'].setValue(this.selectedSearchCriteria);
              this.changeValues({ source: { _id: 'searchCriteria' } });
            }
          })
        );
      } else {
        if (this.isEditing) {
          this.filterFormGroup.controls['searchCriteria'].disable();
        } else {
          this.filterFormGroup.controls['searchCriteria'].enable();
        }
        if (this.selectedSearchCriteria) {
          this.filterFormGroup.controls['searchCriteria'].setValue(this.selectedSearchCriteria);
          this.changeValues({ source: { _id: 'searchCriteria' } });
        }
      }
      this.clearField('subsectionType');
      this.options = [];
      this.selectedOption = 0;
      this.filterFormGroup.value.option = null;
    }
    if (this.filterFormGroup.value.searchCriteria && event.source._id == 'searchCriteria') {
      this.options = [];
      this.selectedOption = 0;
      this.selectedSpace = null;
      this.filterFormGroup.value.option = null;
      if (!this.options?.length) {
        const floorId = this.filterFormGroup.value.floor.id;
        if (this.filterFormGroup.value.searchCriteria == -1) {
          this.subcriptions.push(
            this.esiteService.getAreasByFloor(floorId).subscribe((areasItem: Area[]) => {
              this.options = [];
              areasItem.forEach((areaItem) => {
                const item = new Subsection({
                  id: areaItem.id,
                  name: areaItem.name,
                  type: new SubsectionType({ id: -1 }),
                  floors: [floorId],
                });
                this.options.push(item);
              });
              this.filterFormGroup.controls['option'].enable();
              if (this.selectedOption) {
                this.filterFormGroup.controls['option'].setValue(this.selectedOption);
              }
            })
          );
        } else {
          this.subcriptions.push(
            this.esiteService
              .getSubsections(floorId, this.filterFormGroup.value.searchCriteria)
              .subscribe((subsections: Subsection[]) => {
                this.options = subsections.filter((item) => item.floors[0] != null);
                this.filterFormGroup.controls['option'].enable();
                if (this.selectedOption) {
                  this.filterFormGroup.controls['option'].setValue(this.selectedOption);
                }
              })
          );
        }
      } else {
        this.filterFormGroup.controls['option'].enable();
        if (this.selectedOption) {
          this.filterFormGroup.controls['option'].setValue(this.selectedOption);
        }
      }
      this.clearField('subsection');
    }
    if (this.filterFormGroup.value.option && event.source._id == 'option') {
      this.selectedSpace = null;
      this.getReservableSpaces();
    }
    if (this.isEditing) {
      this.setDisabledFields();
    }
  }

  clearField(event: any): void {
    switch (event) {
      case 'city': {
        this.floors = [];
        this.buildings = [];
        this.filterFormGroup.value.city = null;
        this.filterFormGroup.value.building = null;
        this.filterFormGroup.value.floor = null;
        this.filterFormGroup.value.searchCriteria = null;
        this.filterFormGroup.value.option = null;
        this.selectedCity = null;
        this.selectedBuilding = null;
        this.selectedFloor = null;
        this.selectedSearchCriteria = 0;
        this.selectedOption = 0;
        this.selectedSpace = null;
        this.reservableSpaces = [];

        this.filterFormGroup.controls['building'].disable();
        this.filterFormGroup.controls['floor'].disable();
        this.filterFormGroup.controls['searchCriteria'].disable();
        this.filterFormGroup.controls['option'].disable();
        this.filterFormGroup.controls['space'].disable();

        break;
      }
      case 'building': {
        this.filterFormGroup.value.building = null;
        this.filterFormGroup.value.floor = null;
        this.filterFormGroup.value.searchCriteria = null;
        this.filterFormGroup.value.option = null;
        this.selectedBuilding = null;
        this.selectedFloor = null;
        this.selectedSearchCriteria = 0;
        this.selectedOption = 0;
        this.selectedSpace = null;

        this.floors = [];
        this.reservableSpaces = [];
        this.filterFormGroup.controls['floor'].disable();
        this.filterFormGroup.controls['searchCriteria'].disable();
        this.filterFormGroup.controls['option'].disable();
        this.filterFormGroup.controls['space'].disable();

        break;
      }
      case 'floor': {
        this.filterFormGroup.value.floor = null;
        this.filterFormGroup.value.searchCriteria = null;
        this.filterFormGroup.value.option = null;
        this.selectedFloor = null;
        this.selectedSearchCriteria = 0;
        this.selectedOption = 0;
        this.selectedSpace = null;

        this.reservableSpaces = [];

        this.filterFormGroup.controls['searchCriteria'].disable();
        this.filterFormGroup.controls['option'].disable();
        this.filterFormGroup.controls['space'].disable();
        break;
      }
      case 'searchCriteria': {
        this.filterFormGroup.value.searchCriteria = null;
        this.filterFormGroup.value.option = null;
        this.selectedSearchCriteria = 0;
        this.selectedOption = 0;
        this.filterFormGroup.controls['option'].disable();

        break;
      }
      case 'option': {
        this.filterFormGroup.value.option = null;
        this.selectedOption = 0;
        this.selectedSpace = null;
        this.getReservableSpaces();
        break;
      }
    }
  }

  searcherOption = (search: string, pageNumber: number, pageSize: number): Observable<Subsection[]> => {
    // searcher must return Observable in this method use rxjs of
    const filtered = this.options
      .filter((z) => z.name.toLowerCase().includes(search.toLowerCase()))
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    return of(filtered);
  };

  searcherSpace = (search: string, pageNumber: number, pageSize: number): Observable<ReservationSpace[]> => {
    // searcher must return Observable in this method use rxjs of
    const filteredSpaces = this.reservableSpaces
      .filter((z) => z.code.toLowerCase().includes(search.toLowerCase()))
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    return of(filteredSpaces);
  };

  getReservableSpaces(): void {
    this.filterFormGroup.controls['space'].enable();
    let subsectionID = null;
    let areaID = null;
    if (this.selectedOption?.type?.id > 0) {
      subsectionID = this.selectedOption.id;
    } else {
      areaID = this.selectedOption.id;
    }

    this.subcriptions.push(
      this.reservationService
        .getReservableSpaces(this.selectedFloor.id, subsectionID, areaID)
        .subscribe((reservableSpaces: ReservationSpace[]) => {
          this.reservableSpaces = reservableSpaces.filter((space) => space.useType !== 'Room');
          if (this.selectedSpace) {
            this.filterFormGroup.controls['space'].setValue(
              this.reservableSpaces.find((item) => item.id == this.selectedSpace.id)?.id
            );
            this.loading = false;
          }
        })
    );
  }

  createContinuousReservation(): void {
    if (!this.filterFormGroup.value.allDay) {
      this.validateTimeError();
    }
    this.submitted = true;
    if (this.filterFormGroup.invalid) {
      this.submitted = false;
      return;
    }

    const backTimes = this.setBackDateTimeFormat();

    const continuousReservation = new ContinuousReservation({
      id: this.continuousReservationInfo?.id ? this.continuousReservationInfo.id : undefined,
      reservablespacesid: this.selectedSpace,
      reservationusersid: this.userID,
      startDate: backTimes.backStartDate,
      endDate: this.filterFormGroup.value?.endDate ? backTimes.backEndDate : undefined,
      startTime: backTimes.backStartTime,
      endTime: backTimes.backEndTime,
      timezone: this.checkUTCValidators(),
    });

    this.loading = true;
    if (this.continuousReservationInfo) {
      this.subcriptions.push(
        this.reservationService.modifyContinuousReservation(continuousReservation).subscribe({
          next: () => {
            const infoModalData = new InfoModalData('', 'Continuous reservation modified successfully');

            const dialogRef = this.dialog.open(InfoModalComponent, {
              data: infoModalData,
              panelClass: 'custom-dialog',
              disableClose: true,
            });

            dialogRef.afterClosed().subscribe(() => {
              this.close(false);
            });
          },
          error: (error: HttpErrorResponse) => {
            this.submitted = false;
            this.loading = false;
            this.dialog.open(InfoModalComponent, {
              data: new InfoModalData('Modifying error', error!.toString()),
              panelClass: 'custom-dialog',
              disableClose: true,
            });
          },
        })
      );
    } else {
      this.subcriptions.push(
        this.reservationService.createContinuousReservation(continuousReservation).subscribe({
          next: () => {
            const infoModalData = new InfoModalData('', 'Continuous reservation created successfully');

            const dialogRef = this.dialog.open(InfoModalComponent, {
              data: infoModalData,
              panelClass: 'custom-dialog',
              disableClose: true,
            });

            dialogRef.afterClosed().subscribe(() => {
              this.close(false);
            });
          },
          error: (error: HttpErrorResponse) => {
            this.submitted = false;
            this.loading = false;
            this.dialog.open(InfoModalComponent, {
              data: new InfoModalData('Creating error', error!.toString()),
              panelClass: 'custom-dialog',
              disableClose: true,
            });
          },
        })
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get f() {
    return this.filterFormGroup?.controls;
  }

  validateTimeError(): void {
    this.filterFormGroup.controls['startTime'].addValidators([Validators.required]);
    this.filterFormGroup.controls['endTime'].addValidators([Validators.required]);
    if (!this.filterFormGroup.value.startTime) {
      this.filterFormGroup.controls['startTime']?.setErrors({ invalid: true });
    }
    if (!this.filterFormGroup.value.endTime) {
      this.filterFormGroup.controls['endTime']?.setErrors({ invalid: true });
    }
  }

  checkAllDayValidators(): void {
    if (this.filterFormGroup.value.allDay) {
      this.filterFormGroup.controls['startTime'].removeValidators([Validators.required]);
      this.filterFormGroup.controls['endTime'].removeValidators([Validators.required]);
      this.filterFormGroup.controls['endTime'].reset();
      this.filterFormGroup.controls['startTime'].reset();
    }
  }

  utcState(event): void {
    this.utcActive = event.checked;
  }

  checkUTCValidators(): string {
    if (this.utcActive) {
      return 'UTC';
    } else {
      return this.selectedCity.timezone;
    }
  }

  setBackDateTimeFormat() {
    let startTime = null;
    let endTime = null;
    if (this.filterFormGroup.value.allDay === false) {
      startTime = this.filterFormGroup.value.startTime;
      endTime = this.filterFormGroup.value.endTime;
    } else {
      startTime = '00:00:00';
      endTime = '23:59:59';
    }

    const times = {
      backStartTime: startTime,
      backEndTime: endTime,
      backStartDate: this.datepipe.transform(this.filterFormGroup.value.startDate, 'yyyy-MM-dd'),
      backEndDate: null,
    };

    if (this.filterFormGroup.value?.endDate) {
      times.backEndDate = this.datepipe.transform(this.filterFormGroup.value?.endDate, 'yyyy-MM-dd');
    }
    return times;
  }

  timeSet(id: string, event: string): void {
    if (id == 'init') {
      this.initEndTime = this.dateService.addMinutes(event, 5);
    }
    if (id == 'end') {
      this.endStartTime = this.dateService.addMinutes(event, -5);
    }
  }

  startDateChanged(event): void {
    this.canChangeEndDate = true;
    this.minEndDate = this.dateService.addDays(event.value, 0);
  }

  resetEndDate(): void {
    this.disableEndDate = !this.disableEndDate;
    if (this.disableEndDate) {
      this.filterFormGroup.controls['endDate'].setValue(null);
    } else {
      this.filterFormGroup.controls['endDate'].enable();
    }
  }
}
