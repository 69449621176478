<mat-card class="modalCard">
  <mat-card-content class="marginBodyCard p-0">
    <div *ngIf="loading" class="p-5 d-flex justify-content-center align-items-center">
      <app-loading></app-loading>
    </div>
    <div *ngIf="!loading" class="p-3">
      <label class="titleN3 text-primary modalTitle"> {{ titleModal | translate }}</label>
      <form class="row form p-2" [formGroup]="createNotificationFormGroup" (ngSubmit)="createOrEditNotification()">
        <mat-form-field>
          <mat-label>{{ 'NameNotification' | translate }}</mat-label>
          <input
            title="{{ 'NameNotification' | translate }}"
            matInput
            autocomplete="off"
            formControlName="name"
            type="text"
            class="p-1"
            maxlength="50"
            required
            spellcheck="false"
          />
          <mat-hint *ngIf="createNotificationFormGroup.get('name')?.value.length" align="end"
            >{{ createNotificationFormGroup.get('name').value.length }}/50</mat-hint
          >
          <mat-error *ngIf="f.name.errors?.required">{{ 'Name is required' | translate }}</mat-error>
          <mat-error *ngIf="f.name.errors?.duplicate">{{
            'There is already another notification with this name' | translate
          }}</mat-error>
          <mat-error *ngIf="f.name.errors?.unknown">{{
            'An error happened when creating the notification' | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'SelectZone' | translate }}</mat-label>
          <mat-select class="p-1" formControlName="zone" required>
            <mat-option *ngFor="let zone of zones" [value]="zone.id">
              {{ zone.name | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.zone.invalid">{{ 'Zone is required' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'SelectAction' | translate }}</mat-label>
          <mat-select class="p-1" formControlName="type" required>
            <mat-option *ngFor="let type of alarmSubtypes" [value]="type">
              {{ getalarmSubtypesAsString(type) | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.type.invalid">{{ 'Action is required' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'Limit' | translate }} (%)</mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="limit"
            type="number"
            onkeydown="return event.keyCode !== 69"
            min="0"
            max="100"
            step="1"
            class="p-1"
            placeholder="50"
            spellcheck="false"
            required
          />
          <span matSuffix>%</span>
          <mat-error *ngIf="f.limit.invalid">{{ getThresholdErrorMsg() | translate }}</mat-error>
        </mat-form-field>
        <div class="col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="cancel()" type="button">
            {{ 'Cancel' | translate }}
          </button>
          <button mat-button class="modalButton bg-primary" type="submit">
            {{ 'Save' | translate }}
          </button>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
