import { SubsectionTypeRelation } from './SubsectionTypeRelation.enum';

export class SubsectionType {
  id: number;
  name: string;
  maxFloors: SubsectionTypeRelation;

  constructor(msg: any) {
    this.id = msg.id || null;
    this.name = msg.name || '';
    this.maxFloors = msg.maxFloors || SubsectionTypeRelation.NO_PHYSICAL_LOCATION;
  }
}
