import { User } from './User.class';

export class Permission {
  id?: number;
  name?: string;
  type?: string;
  module?: string;
}

export class Role {
  id?: number;
  name: string;
  reservationsDefault?: boolean;
  hidden?: boolean;
  permissions?: Permission[]; // Permission's role
}

export class RolePermissionBuilding {
  role?: Role; // Role's building
  building?: number; // Building ID
  buildingName?: string;
  permissions?: Permission[]; // Permission's building
}

export class UserBuilding {
  userInfo?: User;
  roles: RolePermissionBuilding[];
}

export class RoleBuilding {
  role: number;
  roleName?: string;
  building: number;
  buildName?: string;
}

export class PermissionBuilding {
  buildingId: number;
  permission: Permission[];
  type?: any;
}
