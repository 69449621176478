<div class="header-container d-flex justify-content-between">
  <a routerLink="/">
    <img src="./assets/images/Habitat_Logo_reducido_200px.png" alt="Habität" />
  </a>

  <input alt="{{ 'Language' | translate }}" type="image" src="./assets/images/idioma.png" width="30" [matMenuTriggerFor]="languageMenu" />
</div>

<mat-menu #languageMenu="matMenu">
  <button *ngFor="let lang of languages" class="shadow-none rounded-0" mat-menu-item (click)="onLanguageItemClick(lang.value)">
    {{ lang.label }}
  </button>
</mat-menu>