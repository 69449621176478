<mat-dialog-content class="overflow-x-hidden m-0 p-0">
  <mat-card style="max-width: 100%; margin-bottom: 0px">
    <mat-card-content class="marginBodyCard" style="overflow-x: hidden; padding-right: 48px">
      <div *ngIf="!showErrors">
        <div *ngIf="submitted" style="min-height: 200px" class="d-flex text-center">
          <div class="my-auto mx-auto">
            <p class="titleN3 d-block">{{ loadingMessage | translate }}</p>
            <mat-progress-bar
              [mode]="progressBarMode"
              [value]="progressBarValue"
              aria-label="Progress of saving floor data"
              style="min-width: 300px"
            ></mat-progress-bar>
          </div>
        </div>
        <div [hidden]="submitted" style="min-height: 200px">
          <label class="titleN3 modalTitle" [ngStyle]="{ color: !isEditingFloor ? '#0075aa' : '#eb7100' }">
            {{ modalTitle | translate }}<span *ngIf="floor">: {{ floor.name }} </span></label
          >
          <form class="row" [formGroup]="floorFormGroup" (ngSubmit)="handleSubmit()">
            <div class="col-sm-6 pb-3">
              <mat-label>{{ 'PNG file' | translate }}*</mat-label>
              <div class="form-group custom-file pt-1" style="cursor: pointer">
                <input
                  type="file"
                  id="imageFile"
                  required
                  ngModel
                  accept=".png, .jpg"
                  (change)="handleImageInput($event.target.files)"
                  class="form-control custom-file-input"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="submitted"
                />
                <input type="hidden" name="img" id="imageFileValidator" formControlName="img" />
                <label class="custom-file-label d-flex pt-1" for="imageFile" data-browse="">
                  <span class="flex-grow-1 text-truncate pe-1">{{ imageName }}</span>
                  <span class="smallIcon flex-shrink-0 p-0 bi bi-cloud-upload"></span>
                </label>
              </div>
              <div
                class="invalid-feedback"
                *ngIf="f.img.touched && f.img.errors?.required"
                [ngClass]="{ 'd-block': f.img.errors }"
              >
                {{ 'Required field' | translate }}
              </div>
              <div *ngIf="errorImageSize || errorImageType" class="invalid-feedback-custome">
                <div *ngIf="errorImageSize">
                  {{ 'Image size must be less than 2MB' | translate }}
                </div>
                <div *ngIf="errorImageType">
                  {{ 'Image type must be .PNG or .JPG' | translate }}
                </div>
              </div>
              <div *ngIf="errorImageNameMatch && !(errorImageSize || errorImageType)" class="invalid-feedback-custome">
                {{ 'Image name does not match with excel data' | translate }}
              </div>
            </div>
            <div *ngIf="hasEsiteModule" class="col-sm-6 pb-3">
              <mat-label>{{ 'Excel file' | translate }}*</mat-label>
              <div class="form-group custom-file pt-1" style="cursor: pointer">
                <input
                  #excelFile
                  type="file"
                  id="excelFile"
                  required
                  ngModel
                  accept=".xlsx"
                  (change)="handleExcelFile($event)"
                  class="form-control custom-file-input"
                  [ngModelOptions]="{ standalone: true }"
                  [disabled]="submitted"
                />
                <input type="hidden" name="excel" id="excelFileValidator" formControlName="excel" />
                <label class="custom-file-label d-flex pt-1" for="excelFile" data-browse="">
                  <span class="flex-grow-1 text-truncate pe-1">{{ excelName }}</span>
                  <span class="smallIcon flex-shrink-0 p-0 bi bi-cloud-upload"></span>
                </label>
              </div>
              <mat-error *ngIf="f.name.errors?.pattern" class="invalid-feedback-custome">{{
                'Commas not allowed in floor name' | translate
              }}</mat-error>
              <div *ngIf="!f.name.errors?.pattern">
                <div *ngIf="errorExcelType" class="invalid-feedback-custome">
                  {{ 'Excel File type must be a valid file, like .xlsx' | translate }}
                </div>
                <div *ngIf="errorExcelFile" class="invalid-feedback-custome">
                  {{ 'Unable to parse file, please make sure you are uploading a valid data file' | translate }}
                </div>
                <mat-error *ngIf="errorExcelBuilding" class="invalid-feedback-custome">{{
                  'Wrong building' | translate
                }}</mat-error>
                <div *ngIf="errorExcelSpaceType" class="invalid-feedback-custome">
                  {{ 'Excel File Space type must be a valid space type' | translate }}
                </div>
                <div *ngIf="errorExcelVersion" class="invalid-feedback-custome">
                  {{ 'Invalid template version, please make sure you are uploading a valid data file' | translate }}
                </div>
                <div *ngIf="errorExcelConfig" class="invalid-feedback-custome">
                  {{ 'Data is missing in the excel configuration sheet' | translate }}
                </div>
                <div
                  class="invalid-feedback"
                  *ngIf="f.excel.touched && f.excel.errors?.required"
                  [ngClass]="{ 'd-block': f.excel.errors }"
                >
                  {{ 'Required field' | translate }}
                </div>
              </div>
            </div>
            <div *ngIf="svgDataToSend && svgProperties" class="col-sm-12" style="width: 100%">
              <mat-label>{{ 'Preview' | translate }}</mat-label>
              <app-svg-map
                style="width: 100%"
                [inputData]="svgDataToSend"
                [newFloor]="true"
                [legendItems]="legendItems"
                (newFloorProperties)="newFloorProperties($event)"
                [svgMapProperties]="svgProperties"
              >
              </app-svg-map>
            </div>

            <details *ngIf="resourceTypes.length > 0" class="border-bottom">
              <summary class="border-bottom">
                <strong class="me-1">{{ 'Resource types' | translate }}:</strong>
                <span>{{ resourceTypes.length }}</span>
              </summary>
              <table>
                <caption hidden>
                  {{
                    'Resource types' | translate
                  }}
                </caption>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ 'Code' | translate }}</th>
                    <th>{{ 'Resource type' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let type of resourceTypes; index as i">
                    <td>{{ i + 1 }}</td>
                    <td class="px-2">
                      <span class="bi bi-{{ type.icon }} me-2"></span>
                      <span class="font-monospace">{{ type.code }}</span>
                    </td>
                    <td>{{ type.name }}</td>
                  </tr>
                </tbody>
              </table>
            </details>

            <details *ngIf="resourcesToAdd.length > 0" class="border-bottom">
              <summary class="border-bottom">
                <strong class="me-1">{{ 'Resources' | translate }}:</strong>
                <span>{{ resourcesToAdd.length }}</span>
              </summary>
              <table>
                <caption hidden>
                  {{
                    'Resources' | translate
                  }}
                </caption>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{{ 'Code' | translate }}</th>
                    <th>{{ 'Resource' | translate }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let res of resourcesToAdd; index as i">
                    <td>{{ i + 1 }}</td>
                    <td class="px-2">
                      <span class="bi bi-{{ res.type.icon }} me-2"></span>
                      <span class="font-monospace">{{ res.code }}</span>
                    </td>
                    <td>{{ res.name }}</td>
                  </tr>
                </tbody>
              </table>
            </details>

            <div class="col-sm-12 buttonsRow">
              <button mat-button class="modalCancel text-primary" (click)="cancel()" type="button">
                {{ 'Cancel' | translate }}
              </button>
              <button
                mat-button
                class="modalButton bg-primary"
                type="submit"
                [disabled]="!isFormValid || submitted"
              >
                <span>{{ 'Save' | translate }}</span>
                <span class="loader ms-2" *ngIf="submitted"></span>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div *ngIf="showErrors">
        <label class="titleN3 text-primary modalTitle"> {{ 'Errors' | translate }}</label>
        <div *ngIf="rowsWithErrors.length > 0" role="alert" class="alert alert-warning">
          {{
            'The floor data has been saved except for the following spaces that have errors in the template, change it and upload again'
              | translate
          }}
        </div>
        <div class="row">
          <div class="col-1">
            <strong>{{ 'Row' | translate }} </strong>
          </div>
          <div class="col-3">
            <strong>{{ 'Code' | translate }} </strong>
          </div>
          <div class="col-8">
            <strong>{{ 'Reason' | translate }}</strong>
          </div>
        </div>
        <div class="table-container">
          <div *ngFor="let error of allErrors">
            <div class="row">
              <div class="col-1">{{ error.row }}</div>
              <div class="col-3">{{ error.code }}</div>
              <div class="col-8">{{ error.msg | translate }}</div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="cancel()">{{ 'Close' | translate }}</button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>
