import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { forwardRef, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// ngx
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

// Material
import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { NgCircleProgressModule } from 'ng-circle-progress';

// PowerBI
import { PowerBIEmbedModule } from 'powerbi-client-angular';

// App
import { VersionsInterceptor } from 'src/app/interceptors/versions.interceptor';
import { MatPaginatorIntlLan } from 'src/app/services/common/lanRangeLabel.service';
import { SharedModule } from '../shared/shared.module';
import { AdvancedReportsComponent } from './advanced-reports/advanced-reports.component';
import { AuditFilterComponent } from './audit/changes-audit/audit-filter/audit-filter.component';
import { AuditResultsDetailComponent } from './audit/changes-audit/audit-results/audit-results-detail/audit-results-detail.component';
import { AuditResultsComponent } from './audit/changes-audit/audit-results/audit-results.component';
import { AuditComponent } from './audit/changes-audit/audit.component';
import { ReservationAuditFilterComponent } from './audit/reservation-audit/reservation-audit-filter/reservation-audit-filter.component';
import { ReservationAuditResultsComponent } from './audit/reservation-audit/reservation-audit-results/reservation-audit-results.component';
import { ReservationAuditComponent } from './audit/reservation-audit/reservation-audit.component';
import { ContinuousReservationsFilterComponent } from './configuration/continuous-reservations/continuous-reservations-filter/continuous-reservations-filter.component';
import { ContinuousReservationsResultsComponent } from './configuration/continuous-reservations/continuous-reservations-results/continuous-reservations-results.component';
import { ContinuousReservationsComponent } from './configuration/continuous-reservations/continuous-reservations.component';
import { GroupConfigurationFilterComponent } from './configuration/group-configuration/group-configuration-filter/group-configuration-filter.component';
import { GroupConfigurationListComponent } from './configuration/group-configuration/group-configuration-list/group-configuration-list.component';
import { GroupConfigurationComponent } from './configuration/group-configuration/group-configuration.component';
import { RolesConfigurationFormComponent } from './configuration/roles-configuration/roles-configuration-form/roles-configuration-form.component';
import { RolesConfigurationMatrixComponent } from './configuration/roles-configuration/roles-configuration-matrix/roles-configuration-matrix.component';
import { RolesConfigurationComponent } from './configuration/roles-configuration/roles-configuration.component';
import { BuildingFormComponent } from './configuration/space-configuration/building-form/building-form.component';
import { FloorFormComponent } from './configuration/space-configuration/floor-form/floor-form.component';
import { SpaceConfigurationFloorComponent } from './configuration/space-configuration/space-configuration-floor/space-configuration-floor.component';
import { SpaceConfigurationSpaceComponent } from './configuration/space-configuration/space-configuration-space/space-configuration-space.component';
import { SpaceConfigurationComponent } from './configuration/space-configuration/space-configuration.component';
import { SpaceFormComponent } from './configuration/space-configuration/space-form/space-form.component';
import { SpaceInfoComponent } from './configuration/space-configuration/space-info/space-info.component';
import { SpaceNewSpaceComponent } from './configuration/space-configuration/space-new-space/space-new-space.component';
import { SubsectionConfigurationComponent } from './configuration/subsections-configuration/subsection-configuration/subsection-configuration.component';
import { SubsectionTypeConfigurationComponent } from './configuration/subsections-configuration/subsection-type-configuration/subsection-type-configuration.component';
import { SubsectionsConfigurationComponent } from './configuration/subsections-configuration/subsections-configuration.component';
import { NotPermissionComponent } from './not-permission/not-permission.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { PagesRoutingModule } from './pages-routing.module';
import { PagesComponent } from './pages.component';
import { NotificationFormComponent } from './profile/notifications-config/notification-form/notification-form.component';
import { NotificationsConfigComponent } from './profile/notifications-config/notifications-config.component';
import { ProfileComponent } from './profile/profile.component';
import { GroupConfigFormComponent } from './configuration/group-configuration/group-config-form/group-config-form';
import { UserInfoComponent } from './profile/user-info/user-info.component';
import { UserSettingsComponent } from './profile/user-settings/user-settings.component';
import { RealtimeAreaComponent } from './realtime/realtime-area/realtime-area.component';
import { RealtimeFloorComponent } from './realtime/realtime-floor/realtime-floor.component';
import { RealtimeSpaceComponent } from './realtime/realtime-space/realtime-space.component';
import { RealtimeComponent } from './realtime/realtime.component';
import { AddReservationUserFormComponent } from './users/add-reservation-user-form/add-reservation-user-form.component';
import { ContinuousReserveTableComponent } from './users/continuous-reserve/continuous-reserve-table/continuous-reserve-table.component';
import { ContinuousReserveComponent } from './users/continuous-reserve/continuous-reserve.component';
import { NewContinuousReserveComponent } from './users/continuous-reserve/new-continuous-reserve/new-continuous-reserve.component';
import { ReserveTableComponent } from './users/reserve-table/reserve-table.component';
import { UserFormComponent } from './users/user-form/user-form.component';
import { UsersComponent } from './users/users.component';
import { ResourcesConfigurationComponent } from './configuration/resources-configuration/resources-configuration.component';
import { ResourceConfigurationComponent } from './configuration/resources-configuration/resource-configuration/resource-configuration.component';
import { ResourceTypeConfigurationComponent } from './configuration/resources-configuration/resource-type-configuration/resource-type-configuration.component';
import { Co2Component } from './co2/co2.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    PagesComponent,
    RealtimeComponent,
    RealtimeSpaceComponent,
    RealtimeFloorComponent,
    RealtimeAreaComponent,
    AdvancedReportsComponent,
    SpaceConfigurationComponent,
    UsersComponent,
    UserFormComponent,
    ProfileComponent,
    UserInfoComponent,
    UserSettingsComponent,
    NotificationsConfigComponent,
    SpaceConfigurationSpaceComponent,
    SpaceConfigurationFloorComponent,
    NotificationFormComponent,
    NotificationsComponent,
    BuildingFormComponent,
    FloorFormComponent,
    GroupConfigFormComponent,
    SpaceFormComponent,
    SpaceInfoComponent,
    AddReservationUserFormComponent,
    SpaceNewSpaceComponent,
    ReserveTableComponent,
    SubsectionsConfigurationComponent,
    SubsectionTypeConfigurationComponent,
    SubsectionConfigurationComponent,
    AuditComponent,
    AuditFilterComponent,
    AuditResultsComponent,
    AuditResultsDetailComponent,
    ContinuousReserveComponent,
    NewContinuousReserveComponent,
    ContinuousReserveTableComponent,
    ReservationAuditComponent,
    ReservationAuditFilterComponent,
    ReservationAuditResultsComponent,
    ContinuousReservationsComponent,
    ContinuousReservationsFilterComponent,
    ContinuousReservationsResultsComponent,
    GroupConfigurationComponent,
    GroupConfigurationFilterComponent,
    GroupConfigurationListComponent,
    RolesConfigurationComponent,
    RolesConfigurationFormComponent,
    RolesConfigurationMatrixComponent,
    NotPermissionComponent,
    ResourcesConfigurationComponent,
    ResourceConfigurationComponent,
    ResourceTypeConfigurationComponent,
    Co2Component,
  ],
  imports: [
    CommonModule,
    PagesRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgCircleProgressModule,
    NgxMatDatetimePickerModule,
    NgxMatMomentModule,
    MatDatepickerModule,
    MatDividerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatFormFieldModule,
    MatTableModule,
    MatTabsModule,
    MatPaginatorModule,
    MatSortModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatSlideToggleModule,
    TranslateModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxMaterialTimepickerModule,
    PowerBIEmbedModule,
  ],
  exports: [
    PagesComponent,
    RealtimeComponent,
    SpaceConfigurationComponent,
    ProfileComponent,
    ContinuousReserveTableComponent,
    ContinuousReservationsResultsComponent,
  ],
  providers: [
    { provide: MatPaginatorIntl, useClass: forwardRef(() => MatPaginatorIntlLan) },
    { provide: HTTP_INTERCEPTORS, useClass: VersionsInterceptor, multi: true },
  ],
})
export class PagesModule {}
