<div class="titleN3">{{ title | translate }}</div>
<div *ngIf="loading" style="display: flex; align-items: center; justify-content: center; min-height: 184px">
  <app-loading></app-loading>
</div>
<div *ngIf="!loading" style="max-width: 53%; margin-left: 25%">
  <circle-progress
    [percent]="percentage"
    [radius]="80"
    [maxPercent]="100"
    [outerStrokeWidth]="16"
    [innerStrokeWidth]="16"
    [space]="-16"
    [outerStrokeColor]="outerStrokeColor"
    [innerStrokeColor]="innerStrokeColor"
    [animation]="true"
    [animationDuration]="0"
    [titleFontSize]="40"
    [unitsFontSize]="40"
    [subtitleFontSize]="15"
    [subtitle]="subtitleString"
    [responsive]="true"
    [imageHeight]="50"
  >
  </circle-progress>
</div>
