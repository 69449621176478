<app-space-filter
  (buildingChanged)="buildingChanged($event)"
  (floorChanged)="floorChanged($event)"
  (areaChanged)="areaChanged($event)"
  (noBuildings)="noBuildings = $event"
  (noFloors)="noFloors = $event"
  [mostrarBoton]="mostrarBoton"
>
</app-space-filter>
<app-nodata-card [noBuildings]="noBuildings" [noFloors]="noFloors"></app-nodata-card>
<div *ngIf="havePermission">
  <!-- REALTIME SPACE-->
  <app-realtime-space
    *ngIf="currentBuilding?.floors.length > 0 && (hasEsiteDevicesModule || hasConteoModule || hasReservationsModule)"
  ></app-realtime-space>
  <!-- REALTIME FLOOR -->
  <app-realtime-floor
    *ngIf="currentFloor"
    [spaceEsiteInfoModified]="spaceEsiteInfoModified"
    [zoneModified]="zoneModified"
    [spaceReservationInfoModified]="spaceReservationInfoModified"
  ></app-realtime-floor>
  <!-- REALTIME AREA -->
  <app-realtime-area
    *ngIf="currentArea"
    [spaceModified]="spaceModified"
    [spaceReservationInfoModified]="spaceReservationInfoModified"
  ></app-realtime-area>
</div>
