import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpMethods } from 'src/app/models/HttpMethods.enum';

/**
 * HTTP Request utilities
 */
@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  private setJsonContentTypeHeader(headers = new HttpHeaders()): HttpHeaders {
    return headers.set('Content-Type', 'application/json');
  }

  private makeRequest<T>(
    headers: HttpHeaders,
    url: string,
    type: HttpMethods,
    method: string,
    body?: unknown,
    options?: Record<string, unknown>
  ): Observable<T> {
    let result: Observable<T>;
    if (type === HttpMethods.GET) {
      result = this.httpClient.get<T>(url + method, { headers, ...options });
    } else if (type === HttpMethods.POST) {
      result = this.httpClient.post<T>(url + method, body, { headers, ...options });
    } else if (type === HttpMethods.PUT) {
      result = this.httpClient.put<T>(url + method, body, { headers, ...options });
    } else if (type === HttpMethods.DELETE) {
      result = this.httpClient.delete<T>(url + method, { headers, ...options });
    } else if (type === HttpMethods.PATCH) {
      result = this.httpClient.patch<T>(url + method, body, { headers, ...options });
    }
    return result;
  }

  /**
   * Makes an standard HTTP request with application/json content type
   * @param url String URL where the API is located
   * @param type HTTP Method to use (GET, POST...)
   * @param method String API endpoint (Ex: /v1/getUser)
   * @param body Request body to send, optional
   * @param options angular httpclient options
   * @returns Observable of provided generic <T>
   */
  httpRequest<T>(
    url: string,
    type: HttpMethods,
    method: string,
    body?: unknown,
    headers?: HttpHeaders,
    options?: Record<string, unknown>
  ): Observable<T> {
    if (!headers) {
      headers = this.setJsonContentTypeHeader();
    }
    return this.makeRequest<T>(headers, url, type, method, body, options);
  }

  /**
   * Makes an standard HTTP request with multipart/form-data content type
   * @param url String URL where the API is located
   * @param type HTTP Method to use (GET, POST...)
   * @param method String API endpoint (Ex: /v1/getUser)
   * @param body Request body to send, optional
   * @returns Observable of provided generic <T>
   */
  httpRequestMultipart<T>(
    url: string,
    type: HttpMethods,
    method: string,
    body?: unknown,
    headers?: HttpHeaders
  ): Observable<T> {
    if (!headers) {
      headers = new HttpHeaders();
    }
    return this.makeRequest<T>(headers, url, type, method, body);
  }

  /**
   * Makes a HTTP request for an image
   * @param url String URL where the API is located
   * @param method String API endpoint (Ex: /v1/getUser)
   * @returns Observable of Blob with the image requested
   */
  httpRequestImage(url: string, method: string): Observable<Blob> {
    const headers = new HttpHeaders();
    return this.httpClient.get<Blob>(url + method, { headers, responseType: 'blob' as 'json' });
  }

  /**
   * Makes a request for downloading an excel file
   * @param url String URL where the API is located
   * @param method String API endpoint (Ex: /v1/getUser)
   * @returns Observable of Blob with the excel data
   */
  httpRequestExcel(url: string, method: string): Observable<Blob> {
    const headers = new HttpHeaders();
    return this.httpClient.get<Blob>(url + method, { headers, responseType: 'blob' as 'json' });
  }
}
