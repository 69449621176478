import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Zone } from 'src/app/models/conteo/Zone.class';
import { getRealAvalabilityType } from 'src/app/models/esite/AvailabilityType.class';
import { Languages } from 'src/app/models/Languages.enum';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { SvgmapPoint } from 'src/app/models/svgmap/SvgmapPoint.class';
import { WebsocketMsg } from 'src/app/models/websocket/WebsocketMsg.class';
import { WebsocketMsgType } from 'src/app/models/websocket/WebsocketMsgType.enum';
import { WebsocketReservation } from 'src/app/models/websocket/WebSocketReservation.class';
import { DateService } from 'src/app/services/common/date.service';

import { RealtimeService } from '../../../services/pages/realtime.service';
import { Permission } from './../../../models/auth/Role.class';
import { User } from './../../../models/auth/User.class';
import { UserPermissions } from './../../../models/auth/UserPermissions.enum';
import { GlobalService } from './../../../services/common/global.service';

@Component({
  selector: 'app-realtime',
  templateUrl: './realtime.component.html',
})
export class RealtimeComponent implements OnInit, OnDestroy {
  currentBuilding = null;
  currentFloor = null;
  currentArea = null;
  subcriptions: Subscription[] = [];
  noBuildings = false;
  noFloors = false;
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  hasEsiteDevicesModule = false;
  spaceEsiteInfoModified: SvgmapPoint;
  spaceReservationInfoModified: ReservationSpace[];
  zoneModified: Zone;
  private userLogged: User;
  private permissions: Permission[] = [];
  havePermission = true;
  mostrarBoton = false;

  constructor(
    private globalService: GlobalService,
    private realtimeService: RealtimeService,
    private dateService: DateService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    const modules = this.globalService.getModules();
    this.hasReservationsModule = modules[0];
    this.hasEsiteDevicesModule = modules[1];
    this.hasConteoModule = modules[2];

    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
  }

  ngOnInit(): void {
    if (this.userLogged) {
      this.ckeckPermissionsUser(this.permissions);
    } else {
      this.globalService.userLogged$.subscribe((user: User) => {
        if (user) {
          this.userLogged = user;
          this.ckeckPermissionsUser(this.userLogged?.permissions);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  buildSockets(permissions: Permission[]): void {
    // Esite realtime data
    if (this.hasEsiteModule && this.hasEsiteDevicesModule) {
      this.webSocketEsite();
      localStorage.setItem('realtime Esite', 'true');
    } else {
      localStorage.setItem('realtime Esite', 'false');
    }

    // Conteo realtime data
    if (this.hasConteoModule && permissions?.map((permission) => permission.name)?.includes(UserPermissions.RealTime)) {
      this.webSocketConteo();
      localStorage.setItem('realtime Conteo', 'true');
    } else {
      localStorage.setItem('realtime Conteo', 'false');
    }

    // Reservas realtime data
    if (
      this.hasReservationsModule &&
      permissions?.map((permission) => permission.name)?.includes(UserPermissions.RealTimeReservations)
    ) {
      this.webSocketReservations();
      localStorage.setItem('realtime Reservations', 'true');
    } else {
      localStorage.setItem('realtime Reservations', 'false');
    }
  }

  webSocketEsite(): void {
    this.subcriptions.push(
      this.realtimeService.getEsiteOcupationMessages().subscribe((response) => {
        const msg = new WebsocketMsg(response);
        if (msg && msg.type === WebsocketMsgType.Site) {
          const data = msg.data;
          const spaceId = data.space;
          const availabilityType = getRealAvalabilityType(data.availabilityType);
          const oldAvailabilityType = getRealAvalabilityType(data.previousAvailabilityType);
          const availabilityDate = data.availabilityDate;
          // Update specific space, used on the svg maps.
          const spaceModified = this.realtimeService.updateSpaceInSelectedFloor(
            spaceId,
            availabilityType,
            oldAvailabilityType,
            availabilityDate
          );
          if (spaceModified) {
            this.spaceEsiteInfoModified = spaceModified;
          }
          // Update status of the building occupancy stored in memory
          this.realtimeService.updateBuildingFloorSpacesState(spaceId, availabilityType, oldAvailabilityType);
        }
      })
    );
  }

  webSocketConteo(): void {
    this.subcriptions.push(
      this.realtimeService.getConteoMessages().subscribe((response) => {
        const msg = new WebsocketMsg(response);
        if (msg && msg.type === WebsocketMsgType.Zone) {
          const data = msg.data;
          const floorId = Number(data.floor);
          const zoneId = Number(data.id);
          const newOccupation = Number(data.occupation);
          // Update specific zone, used on the svg maps.
          if (this.currentFloor?.id == floorId) {
            this.zoneModified = this.realtimeService.updateZoneOccupation(floorId, zoneId, newOccupation);
          } else {
            // We don't care about result, as we do not want to modify floor's card
            this.realtimeService.updateZoneOccupation(floorId, zoneId, newOccupation);
          }
        }
      })
    );
  }

  webSocketReservations(): void {
    this.subcriptions.push(
      this.realtimeService.getReservationMessages().subscribe((response: WebsocketMsg) => {
        const msg = new WebsocketReservation(response);
        const reservationSpaces = this.realtimeService.updatereservation(msg);
        this.uptateReservationSpace(reservationSpaces);
      })
    );
  }

  uptateReservationSpace(reservationSpaces: ReservationSpace[]): void {
    reservationSpaces.forEach((reservationSpace: ReservationSpace) => {
      reservationSpace.reservationDateString = reservationSpace.reservationDate
        ? this.dateService.utcToLocalString(
            this.dateService.getUTCDate(reservationSpace.reservationDate),
            Languages.es,
            this.currentBuilding.city.timezone
          )
        : undefined;
    });
    this.spaceReservationInfoModified = reservationSpaces;
  }

  buildingChanged($event): void {
    this.currentFloor = null;
    this.realtimeService.setCurrentBuilding($event);
    this.currentBuilding = $event;
    this.ckeckPermissionsUser(
      this.globalService.getPermissionsBuilding(
        this.currentBuilding.id,
        this.userLogged?.permissionBuilding,
        this.permissions
      )
    );
  }

  floorChanged($event): void {
    this.currentArea = null;
    this.realtimeService.setCurrentFloor($event);
    this.currentFloor = $event;
  }

  areaChanged($event): void {
    this.realtimeService.setCurrentArea($event);
    this.currentArea = $event;
  }

  ckeckPermissionsUser(permissions: Permission[]): void {
    if (permissions?.map((permission) => permission.name)?.includes(UserPermissions.RealTime)) {
      this.havePermission = true;
      this.buildSockets(permissions);
    }
  }
}
