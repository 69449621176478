export const AppConstants = {
  /** Size table paginator */
  SIZE_TABLE_PAGINATOR: 10,
  /** Initial table page */
  INITIAL_TABLE_PAGE: 1,

  /** MESSAGES */
  MESSAGE_POSITION_CENTER: 'bl',
  MESSAGE_POSITION_BOTTOM_CENTER: 'bc',

  /** OTHER */
  AUDIT_FUNCTION_HEADER: 'x-audit-functionality',
};
