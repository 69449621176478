import { Zone } from '../conteo/Zone.class';
import { Area } from '../esite/Area.class';
import { Space } from '../esite/Space.class';
import { GroupSpacesState } from '../realtime/GroupSpacesState.class';
import { ReservationSpace } from '../reservation/ReservationSpace.class';

export class Floor {
  id: number;
  name: string;
  img: Blob;
  scale: number;
  mapOffsetX: number;
  mapOffsetY: number;
  spaces: Space[];
  reservations: ReservationSpace[];
  areas: Area[];
  zones: Zone[];
  areasSpacesState: GroupSpacesState;
  building: number;
  constructor(msg) {
    this.id = msg?.id;
    this.name = msg?.name;
    this.img = msg?.img;
    this.scale = msg?.scale;
    this.mapOffsetX = msg?.mapOffsetX;
    this.mapOffsetY = msg?.mapOffsetY;
    this.spaces = [];
    this.reservations = [];
    this.areas = [];
    this.zones = [];
    this.building = msg?.building;
  }

  toBackendFormat(buildingId: number) {
    return {
      name: this.name,
      building: buildingId,
      img: this.img,
    };
  }
}
