<mat-card style="width: 375px">
  <div *ngIf="loading" style="text-align: -webkit-center; text-align: -moz-center; padding-top: 20%; min-height: 300px">
    <app-loading></app-loading>
  </div>
  <div *ngIf="!loading" style="display: contents">
    <mat-card-header>
      <div *ngIf="space" class="titleN3 text-primary">{{ 'Info space' | translate }} {{ space.code }}</div>
    </mat-card-header>
    <mat-card-content *ngIf="space">
      <div *ngIf="reserved" class="row">
        <div class="col-6 rowPadding">{{ 'Status' | translate }}:</div>
        <div class="col-6 rowPadding">
          {{ reservation.status | translate }}
        </div>

        <div *ngIf="reservable" style="display: contents">
          <div class="col-6 rowPadding">{{ 'User' | translate }}:</div>
          <div class="col-6 rowPadding">
            {{ reservation.owner.name }}
          </div>
          <div class="col-6 rowPadding">{{ 'From' | translate }}:</div>
          <div class="col-6 rowPadding">
            {{ reservationStartDate }}
          </div>
          <div *ngIf="reservationEndDate" class="col-6 rowPadding">{{ 'To' | translate }}:</div>
          <div *ngIf="reservationEndDate" class="col-6 rowPadding">
            {{ reservationEndDate }}
          </div>
        </div>
      </div>

      <div class="row" style="padding-top: 20px; border-top: 1px solid #edf1f7; margin-top: 15px">
        <div class="col-6 rowPadding">{{ 'Reservable' | translate }}:</div>
        <div class="col-6 rowPadding">
          {{ reservableText | translate }}
        </div>

        <div *ngIf="reservable && space.role" style="display: contents">
          <div class="col-6 rowPadding">{{ 'Group reservable' | translate }}:</div>
          <div class="col-6 rowPadding">
            {{ spaceGroup | translate }}
          </div>
          <div class="col-6 rowPadding">{{ 'Permission' | translate }}:</div>
          <div class="col-6 rowPadding">
            {{ space.role | translate }}
          </div>
          <div class="col-6 rowPadding">{{ 'Needs approval' | translate }}:</div>
          <div class="col-6 rowPadding">
            {{ spaceApproval | translate }}
          </div>
          <div *ngIf="space.needsApproval">
            <div class="col-6 rowPadding">{{ 'Approval user' | translate }}:</div>
            <div class="col-6 rowPadding">
              <div class="row" *ngFor="let user of approvers">
                <div class="col-12">
                  {{ user.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 100%; padding-right: 15px; padding-left: 15px; margin-top: 40px; text-align-last: center">
        <button
          mat-button
          class="modalCancel text-primary"
          style="text-decoration: none !important"
          (click)="closeModal()"
        >
          {{ 'Close' | translate }}
        </button>
      </div>
    </mat-card-content>
  </div>
</mat-card>
