export class City {
  id: number;
  name: string;
  timezone: string;

  constructor(msg) {
    this.id = msg?.id;
    this.name = msg?.name;
    this.timezone = msg?.timezone ? msg?.timezone : 'Europe/Madrid';
  }
}
