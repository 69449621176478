import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { retry, map } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';

import { WebsocketMsg } from 'src/app/models/websocket/WebsocketMsg.class';
import { environment as env } from 'src/environments/environment';
import { HttpService } from '../common/http.service';
import { HttpMethods } from 'src/app/models/HttpMethods.enum';
import { Constants } from 'src/app/models/Constants.const';
import { ZoneCo2 } from 'src/app/models/co2/ZoneCo2.class';

@Injectable({
  providedIn: 'root',
})
export class Co2Service {
  private co2Endpoint = 'v2/zones';
  private socket: WebSocketSubject<WebsocketMsg>;
  keepaliveTimerId: NodeJS.Timeout;
  areas$ = new BehaviorSubject<Array<ZoneCo2>>([]);

  constructor(private httpService: HttpService) {}

  requestToCo2(type, method, body?, headers?): Observable<any> {
    const url = `${env.co2_backend_url}`;
    const protocol = `${env.backend_protocol}`;
    return this.httpService.httpRequest(protocol + url, type, method, body, headers);
  }

  requestToCo2Excel(method: string): Observable<Blob> {
    const url = `${env.co2_backend_url}`;
    const protocol = `${env.backend_protocol}`;
    return this.httpService.httpRequestExcel(protocol + url, method);
  }

  fetchAreas() {
    this.requestToCo2(HttpMethods.GET, this.co2Endpoint).subscribe((areas) => {
      this.areas$.next(areas);
    });
  }

  fetchSingleArea(id: number): Observable<ZoneCo2> {
    const method = `${this.co2Endpoint}/${id}`;
    return this.requestToCo2(HttpMethods.GET, method);
  }

  fetchMeasures(id: number): Observable<any> {
    const url = `${env.websocket_protocol}${env.esite_backend_url}${this.co2Endpoint}/${id}/realTime`;
    const websocketSubjectConfig = env.websocket_keepalive
      ? {
          url: url,
          openObserver: {
            next: () => {
              this.keepaliveTimerId = setInterval(() => {
                this.socket.next({
                  type: Constants.WEBSOCKET_KEEPALIVE_TYPE,
                  data: Constants.WEBSOCKET_KEEPALIVE_MESSAGE,
                });
              }, env.websocket_keepalive_interval);
            },
          },
          closeObserver: {
            next() {
              clearInterval(this.keepaliveTimerId);
            },
          },
        }
      : { url: url };

    if (this.socket && !this.socket?.closed) {
      this.socket.complete();
    }
    this.socket = webSocket(websocketSubjectConfig);
    return this.socket.pipe(retry(5));
  }

  downloadExcel(zoneID?: number, startDate?: string, endDate?: string): Observable<Blob> {
    const excelEndpoint = `${this.co2Endpoint}/exportHistoric`;
    let method = `${excelEndpoint}?`;
    if (zoneID) {
      method += `zones=${zoneID}`;
    }
    if (startDate) {
      method += `&from=${startDate}`;
    }
    if (endDate) {
      method += `&to=${endDate}`;
    }
    return this.requestToCo2Excel(method);
  }
}
