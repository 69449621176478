<mat-card>
  <mat-card-content>
    <div class="container h-100 w-100 d-flex justify-content-center align-items-center">
      <div class="row">
        <div class="col-10 mx-auto">
          <h2 class="titleN4 pt-3">{{ 'Permissions' | translate }}</h2>
          <p>
            {{
              'You do not have the corresponding permissions'
                | translate
            }}
          </p>
          <div class="text-center">
            <button
              matDialogClose
              class="modalButton habitat-button-blue bg-primary mb-3"
              (click)="onClick()"
              style="width: 250px"
            >
              {{ 'Continue session' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
  