export class Group {
  id?: string;
  code?: string;
  name: string;
  subgroupTitle: string;
  final: boolean;
  parents?: number[];
  children?: number[];

  constructor(msg) {
    this.id = msg?.id;
    this.code = msg?.code;
    this.name = msg?.name;
    this.subgroupTitle = msg?.subgroupTitle;
    this.final = msg?.final;
    this.parents = msg?.parents;
    this.children = msg?.children;
  }
}
