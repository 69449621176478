<collapse-card title="{{ 'Configurar Edificios' | translate }}">
  <div class="row">
    <div class="col-md-5 col-9">
       <mat-form-field class="col-11">
        <mat-label>{{ 'Agrupacion' | translate }}</mat-label>
        <mat-select
          id="group"
          class="input-form-special p-1"
          (selectionChange)="changeValues()"
          [(ngModel)]="selectedGroupCombo"
        >
          <mat-option *ngFor="let group of groups" [value]="group">
            {{ group.name }}
          </mat-option>  
        </mat-select>
       
        </mat-form-field> 
        <span
          *ngIf="selectedGroupCombo"
          [title]="'Clear selection' | translate"
          class="btn-clear-select close-circle-outline bi bi-x-circle col-1 align-center"
          style="cursor: pointer;"
          (click)="clearField()"
         ></span>
      <mat-form-field class="col-12" *ngIf="showNextCombo">
        <mat-label>{{ combo2 | translate }}</mat-label>
        <mat-select
          id="group2"
          class="input-form-special"
          (selectionChange)="changeValues2()"
          [(ngModel)]="selectedGroup2Combo"
        >
          <mat-option *ngFor="let group of groups2" [value]="group">
            {{ group.name | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-12" *ngIf="showNextCombo2">
        <mat-label>{{ combo3 | translate }}</mat-label>
        <mat-select
          id="group3"
          class="input-form-special"
          (selectionChange)="changeValues3()"
          [(ngModel)]="selectedGroup3Combo"
        >
          <mat-option *ngFor="let group of groups3" [value]="group">
            {{ group.name | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-md-1"></div>

    <div class="col-md-6 col-12">
      <ul class="item-container">
        <li class="w-100 me-3" *ngFor="let item of buildingsCheckbox">
          <mat-checkbox
            [(ngModel)]="item.checked"
            (change)="setSelectedBuildings()"
            [ngModelOptions]="{ standalone: true }"
            [disabled]="item.disabled"
          >
            {{ item.data.name }}
          </mat-checkbox>
        </li>
      </ul>
    </div>
  </div>

  <hr />
  <app-group-config-form
    [disabled]="selectedBuildings.length === 0"
    [selectedBuildings$]="groupService.curSelectedBuildings"
  ></app-group-config-form>
</collapse-card>
