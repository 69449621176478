<collapse-card title="{{ 'Agrupaciones' | translate }}">
  <div *ngIf="loading" style="min-height: 350px; display: flex; align-items: center; justify-content: center">
    <app-loading></app-loading>
  </div>
  <div [hidden]="loading">
    <div class="d-flex justify-content-end mb-3">
      <button mat-button *ngIf="canWriteGroup" (click)="createNewGroup()">
        <span class="smallIcon bi bi-plus-circle"></span>
        <span class="ps-1">{{ 'New Group' | translate }}</span>
      </button>
    </div>
    <table mat-table [dataSource]="dataSource" style="width: 100%" matSort (matSortChange)="sortData($event)">
      <caption></caption>
      <th id="GroupsTable"></th>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3"
          >{{ 'Name' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let group" style="flex: 1.3">
          <span *ngIf="group.name">
            {{ group.name }}
          </span>
          <span *ngIf="!group.name"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3"
          >{{ 'Code' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let group" style="flex: 1.3">
          <span *ngIf="group.code">
            {{ group.code }}
          </span>
          <span *ngIf="!group.code"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="subgroupTitle">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.2">
          {{ 'Titulo Subagrupacion' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let group" style="flex: 1.2">
          <span *ngIf="group.subgroupTitle">
            {{ group.subgroupTitle }}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.6">
          {{ 'Actions' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let group" style="flex: 0.6" class="d-flex gap-2">
          <span
            class="smallIcon bi bi-pencil"
            role="button"
            (click)="editGroup(group)"
          ></span>
          <span
            *ngIf="canWriteGroup"
            class="smallIcon bi bi-trash"
            role="button"
            (click)="deleteGroup(group)"
          ></span>
          <span
            class="smallIcon bi bi-eye"
            role="button"
            (click)="viewGroupJerarchy(group)"
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
    </table>
    <mat-paginator
      #pag
      [(pageIndex)]="pageIndex"
      [disabled]="disabledPaginator"
      [length]="operationLength"
      [pageSize]="pageSize"
      (page)="pageEvent = getPagination($event)"
      [hidePageSize]="hidePageSize"
    ></mat-paginator>
  </div>
</collapse-card>

<ng-template #newOrEditGroup>
  <mat-card style="width: 500px">
    <mat-card-content class="marginBodyCard">
      <div class="titleN3 text-primary modalTitle">{{ modalTitle | translate }}</div>
      
      <form [formGroup]="groupForm" (ngSubmit)="onSubmit()" style="display: flex; flex-wrap: wrap">
        <!-- code -->
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <mat-form-field style="width: 100%; margin-right: 10px">
            <mat-label>{{ 'Code' | translate }}</mat-label>
            <input
              title="{{ 'Code' | translate }}"
              matInput
              autocomplete="off"
              formControlName="code"
              (keyup)="onKeyUpEventCode()"
              type="text"
              class="p-1"
              maxlength="45"
              required
              spellcheck="false"
            />
            <mat-hint *ngIf="groupForm.get('code')?.value?.length" align="end">
              {{ groupForm.get('code').value.length }}/45
            </mat-hint>
            <mat-error *ngIf="f.code.errors?.required">{{ 'Code is required' | translate }}</mat-error>
            <mat-error *ngIf="f.code.errors?.duplicate">{{ 'Duplicated space code short' | translate }}</mat-error>
            <mat-error *ngIf="f.code.errors?.pattern">{{ 'CaracteresNoPermitidos' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <!-- group name -->
        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
          <mat-form-field style="width: 100%; margin-right: 10px">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input
              title="{{ 'Name' | translate }}"
              matInput
              autocomplete="off"
              formControlName="groupName"
              (keyup)="onKeyUpEventGroupName()"
              type="text"
              class="p-1"
              maxlength="45"
              required
            />
            <mat-hint *ngIf="groupForm.get('groupName')?.value.length" align="end">
              {{ groupForm.get('groupName').value.length }}/45
            </mat-hint>
            <mat-error *ngIf="f.groupName.errors?.required">{{ 'Pool name is required' | translate }}</mat-error>
            <mat-error *ngIf="f.groupName.errors?.duplicate">{{
              'There is already a space with this name, enter a new one' | translate
            }}</mat-error>
            <mat-error *ngIf="f.groupName.errors?.chooseDuplicate">{{
              'A group with this name has been selected, deselect or create with a new name' | translate
            }}</mat-error>
            <mat-error *ngIf="f.groupName.errors?.chooseOne">{{
              'You must choose at least one group or building' | translate
            }}</mat-error>
            <mat-error *ngIf="f.groupName.errors?.pattern">{{ 'CaracteresNoPermitidos' | translate }}</mat-error>
          </mat-form-field>
        </div>
        <div style="width: 100%; margin-right: 10px">
          <mat-radio-group
            [(ngModel)]="this.radioSelection"
            (change)="changeRadios($event)"
            [ngModelOptions]="{ standalone: true }"
          >
            <div>
              <mat-radio-button style="margin-right: 20px; width: 100%" value="Agrupaciones">
                {{ 'Agrupaciones' | translate }}
              </mat-radio-button>
              <mat-error *ngIf="f.errors?.required">{{ 'Pool name is required' | translate }}</mat-error>
            </div>
            <div class="row"></div>
            <div style="margin-left: 10%" *ngIf="!hideGroupsCheckboxs">
              <mat-form-field style="width: 100%; margin-right: 20px">
                <mat-label>{{ 'Titulo Subagrupacion' | translate }}</mat-label>
                <input
                  title="{{ 'Titulo Subagrupacion' | translate }}"
                  matInput
                  autocomplete="off"
                  formControlName="subgroupTitle"
                  (keyup)="onKeyUpEventSubGroupName()"
                  type="text"
                  class="p-1"
                  maxlength="45"
                  required
                  [ngModelOptions]="{ standalone: true }"
                />
                <mat-hint *ngIf="groupForm.get('subgroupTitle')?.value.length" align="end">
                  {{ groupForm.get('subgroupTitle').value.length }}/45
                </mat-hint>
                <mat-error *ngIf="f.subgroupTitle.errors?.required">{{
                  'Subgroup title is required' | translate
                }}</mat-error>
                <mat-error *ngIf="f.subgroupTitle.errors?.pattern">{{
                  'CaracteresNoPermitidos' | translate
                }}</mat-error>
              </mat-form-field>
              <mat-form-field class="input-mat">
                <mat-label class="labelSearch">{{ 'Search' | translate }}</mat-label>
                <div class="d-flex">
                  <input
                    title="{{ 'Search' | translate }}"
                    matInput
                    class="filter-input py-1"
                    (keyup)="applyFilterGroups($event)"
                    #input
                    type="text"
                  />
                  <div class="searchIcon">
                    <span class="bi bi-search"></span>
                  </div>
                </div>
              </mat-form-field>
              <div class="data-container" style="border-style: solid; border-color: #0075aa">
                <div style="width: 100%; margin-right: 20px; padding: 10px" *ngFor="let item of groupCheckbox">
                  <mat-checkbox
                    [disabled]="!canWriteGroup"
                    [(ngModel)]="item.checked"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onChangeGroupCheckbox($event, item)"
                    >{{ item.data.name }}</mat-checkbox
                  >
                </div>
              </div>
            </div>
            <div>
              <mat-radio-button style="margin-right: 20px; width: 100%" value="Edificios">
                {{ 'Edificios' | translate }}
                <div class="row"></div>
              </mat-radio-button>
            </div>
            <div class="row"></div>
            <div style="margin-left: 10%" *ngIf="!hideBuildingsCheckboxs">
              <mat-form-field class="input-mat">
                <mat-label class="labelSearch">{{ 'Search' | translate }}</mat-label>
                <div class="d-flex">
                  <input
                    title="{{ 'Search' | translate }}"
                    matInput
                    class="filter-input py-1"
                    (keyup)="applyFilterBuildings($event)"
                    #input
                    type="text"
                  />
                  <div class="searchIcon">
                    <span class="bi bi-search"></span>
                  </div>
                </div>
              </mat-form-field>
              <div class="data-container" style="border-style: solid; border-color: #0075aa">
                <div style="width: 100%; margin-right: 20px; padding: 10px" *ngFor="let item of buildingsCheckbox">
                  <mat-checkbox
                    [disabled]="!canWriteGroup"
                    [(ngModel)]="item.checked"
                    [ngModelOptions]="{ standalone: true }"
                    (change)="onChangeBuildingCheckbox($event, item)"
                    >{{ item.data.name }}</mat-checkbox
                  >
                </div>
              </div>
            </div>
          </mat-radio-group>
        </div>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="close()" type="button">
            {{ 'Cancel' | translate }}
          </button>
          <button
            *ngIf="canWriteGroup"
            mat-button
            class="modalButton bg-primary"
            type="submit"
            [disabled]="disabledSave"
          >
            {{ 'Save' | translate }}
          </button>
        </div>
      </form>
      <div *ngIf="loading" style="display: flex; align-items: center; justify-content: center">
        <app-loading></app-loading>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
