<collapse-card title="{{ 'Resource types' | translate }}">
  <div *ngIf="loading" class="d-flex align-items-center justify-content-center" style="min-height: 350px">
    <app-loading></app-loading>
  </div>
  <div [hidden]="loading">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <mat-form-field class="flex-grow-1">
        <mat-label class="labelSearch">{{ 'Search' | translate }}</mat-label>
        <div class="d-flex">
          <input
            title="{{ 'Search' | translate }}"
            matInput
            class="filter-input py-1"
            (keyup)="applyFilter($event)"
            #input
            type="text"
          />
          <div class="searchIcon">
            <span class="bi bi-search"></span>
          </div>
        </div>
      </mat-form-field>
      <div *ngIf="canWriteResourceType" class="ps-3">
        <button mat-button (click)="createNewType()">
          <span class="smallIcon bi bi-plus-circle"></span>
          <span class="ps-1">{{ 'New Resource Type' | translate }}</span>
        </button>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="w-100" matSort #sortData="matSort">
      <caption></caption>
      <th id="resourceTypesTable"></th>

      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.9"
          >{{ 'code' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let type" style="flex: 0.9">
          <span *ngIf="type.code">
            {{ type.code }}
          </span>
          <span *ngIf="!type.code"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="icon">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.6"
          >{{ 'Icon' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let type" style="flex: 0.6">
          <span *ngIf="type.icon">
            <i class="bi-{{ type.icon }}"></i>
          </span>
          <span *ngIf="!type.icon"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3"
          >{{ 'Name' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let type" style="flex: 1.3">
          <span *ngIf="type.name">
            {{ type.name }}
          </span>
          <span *ngIf="!type.name"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.6">
          {{ 'Actions' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let type" class="d-flex gap-2" style="flex: 0.6">
          <span
            *ngIf="canWriteResourceType"
            class="smallIcon bi bi-pencil"
            role="button"
            (click)="editResourceType(type)"
          ></span>
          <span
            *ngIf="!canWriteResourceType"
            class="smallIcon bi bi-eye"
            role="button"
            (click)="editResourceType(type)"
          ></span>
          <span
            *ngIf="canWriteResourceType"
            class="smallIcon bi bi-trash"
            role="button"
            (click)="deleteResourceType(type)"
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
    </table>
    <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
  </div>
</collapse-card>

<ng-template #newOrEditType>
  <mat-card style="width: 400px">
    <mat-card-content class="marginBodyCard">
      <label class="titleN3 text-primary modalTitle"> {{ modalTitle | translate }}</label>
      <form [formGroup]="typeForm" (ngSubmit)="onSubmit(typeForm)" class="d-flex flex-column gap-2">
        <div class="w-100">
          <label class="form-label">{{ 'Icon' | translate }}</label>
          <p-dropdown
            placeholder="{{ 'Select a option resoruce' | translate }}"
            formControlName="icon"
            [ngModelOptions]="{ standalone: true }"
            [name]="code"
            [options]="resourceIcons"
            optionLabel="name"
            [filter]="true"
            filterBy="name"
            [showClear]="true"
            [style]="{ width: '100%' }"
          >
            <ng-template pTemplate="selectedItem">
              <div *ngIf="f.icon.value" class="d-flex align-items-center gap-2">
                <div *ngIf="f.icon.value.code">
                  <i class="bi-{{ f.icon.value.code }}"></i>
                </div>
                <div>{{ f.icon.value.name }}</div>
              </div>
            </ng-template>
            <ng-template let-resource pTemplate="item">
              <div class="d-flex align-items-center gap-2">
                <div *ngIf="resource.code">
                  <i class="bi-{{ resource.code }}"></i>
                </div>
                <div>{{ resource.name }}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <small *ngIf="f.icon.touched && !f.icon.value" id="icon-help" class="p-error block">{{
            'You must select a resource option' | translate
          }}</small>
        </div>

        <mat-form-field>
          <mat-label>{{ 'Code' | translate }}</mat-label>
          <input
            title="{{ 'Code' | translate }}"
            matInput
            autocomplete="off"
            formControlName="code"
            type="text"
            class="py-1"
            maxlength="45"
            required
          />
          <mat-hint *ngIf="typeForm.get('code')?.value.length" align="end"
            >{{ typeForm.get('code').value.length }}/45</mat-hint
          >
          <mat-error *ngIf="f.code.errors?.required">{{ 'You must enter a code' | translate }}</mat-error>

          <mat-error *ngIf="f.code.errors?.unknown">{{ 'ErrorCreatingSubsectionTypeGeneric' | translate }}</mat-error>
          <mat-error *ngIf="f.code.errors?.duplicate">{{
            'There is already another resource type with this code' | translate
          }}</mat-error>
          <mat-error *ngIf="f.code.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input
            title="{{ 'Name' | translate }}"
            matInput
            autocomplete="off"
            formControlName="name"
            type="text"
            class="py-1"
            maxlength="45"
            required
          />
          <mat-hint *ngIf="typeForm.get('name')?.value.length" align="end"
            >{{ typeForm.get('name').value.length }}/45</mat-hint
          >
          <mat-error *ngIf="f.name.errors?.required">{{ 'You must enter a name' | translate }}</mat-error>

          <mat-error *ngIf="f.name.errors?.unknown">{{ 'ErrorCreatingSubsectionTypeGeneric' | translate }}</mat-error>
          <mat-error *ngIf="f.name.errors?.duplicate">{{
            'There is already another resource type with this name' | translate
          }}</mat-error>
          <mat-error *ngIf="f.name.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
        </mat-form-field>

        <div class="col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="cancel()" type="button">
            {{ 'Cancel' | translate }}
          </button>
          <button
            [hidden]="!canWriteResourceType"
            [disabled]="!typeForm.valid || submitted"
            mat-button
            class="modalButton bg-primary"
            type="submit"
          >
            <span>{{ 'Save' | translate }}</span>
            <span class="loader ms-1" [hidden]="!submitted"></span>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</ng-template>
