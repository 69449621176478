<body class="imgBackground">
  <!-- Spanish Version -->
  <div *ngIf="lang == 'es'" [lang]="lang" class="p-3 p-md-5">
    <mat-card class="d-grid">
      <mat-card-header>
        <h1 class="titleN1">Política de Privacidad</h1>
      </mat-card-header>
      <mat-card-content class="py-3 py-md-5 px-4 px-md-5" style="text-align: justify">
        <p>
          Esta política te informa sobre los tratamientos de datos que se realizan, a través de la App móvil o web
          Häbitat (en adelante, la “App”), aunque es posible que ésta enlace o redirija a otros sitios web y/o
          plataformas titularidad de la Compañia o de terceros de contenido especializado (como por ejemplo servicios
          sectoriales), con secciones o formularios que regulan de forma específica el tratamiento de información
          obtenida a través de los mismos.
        </p>
        <p>
          Puede encontrar información sobre los tratamientos de datos de dichas webs y/o plataformas en sus respectivas
          páginas de privacidad y/o junto al formulario o canal de contacto con la Compañia, en su caso.
        </p>

        <h6 class="titleN4">1. ¿Quién es el Responsable del tratamiento?</h6>

        <div class="container p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div><strong>Identidad:</strong>NTT DATA SPAIN, S.L.U (“NTT DATA Spain” o la “Compañía”)</div>
              <div><strong>CIF:</strong> B-82387770</div>
              <div><strong>Dirección postal:</strong> Camino Fuente de la Mora, 1, 28050 Madrid, España.</div>
              <div><strong>Teléfono:</strong> 917 490 000</div>
              <div>
                <strong>Contacto del Delegado de Protección de Datos:</strong>
                <a href="mailto:data.protection.office@nttdata.com" style="word-wrap: break-word"
                  >data.protection.office@nttdata.com</a
                >
              </div>
            </div>
          </div>
        </div>

        <h6 class="titleN4">2. ¿Qué datos tratamos?</h6>

        <p>
          Los datos personales que tratamos proceden de la información que como Usuario (“Usuario”) nos facilites al
          rellenar los campos que encontrarás en el Registro de la App (web o móvil), y/o al mandarnos tus consultas o
          peticiones de información. Asimismo, Häbitat recopila la siguiente información:
        </p>
        <ul>
          <li>Puestos flexibles reservados por el usuario (oficina, puesto, fecha, etc.)</li>
        </ul>

        <p>
          Solo recabamos los datos necesarios para cada finalidad. Ten en cuenta que, si no facilitas la información
          obligatoria, es posible que no puedas registrarte en la App, o que no podamos atender tu consulta o petición
          y/o gestionar la reserva de puestos.
        </p>

        <p>
          Häbitat también recibe información anonimizada a través de los sensores a que está conectada sobre el uso y
          ocupación de los puestos y de las salas de los edificios en que está en funcionamiento la App. Los sensores
          únicamente se encuentran ubicados en las oficinas habilitadas. Esa información no contiene datos personales y
          no va a ser utilizada para la identificación de los empleados. Los tratamientos de datos realizados se
          circunscriben únicamente al uso de la App para la reserva de puestos con fines profesionales, y no se realiza
          ninguna clase de asociación o monitorización de los datos con fines de control laboral.
        </p>

        <h6 class="titleN4">3. ¿Con qué finalidad tratamos tus datos y cual es la legitimación para el tratamiento?</h6>
        <p>Los datos personales serán tratados para:</p>
        <ul>
          <li>
            Gestionar el acceso y uso de Häbitat para facilitarte la reserva de los puestos flexibles (indicando
            oficina, puesto, y fecha) en función de su disponibilidad, en base a la ejecución del contrato de trabajo;
          </li>
          <li>
            Gestionar las consultas o peticiones recibidas a través de la App, en base al consentimiento prestado al
            formular la consulta o petición.
          </li>
          <li>
            • Análisis del comportamiento de navegación dentro de la App con fines estadísticos; en base al interés
            legítimo de la Compañía para analizar el comportamiento de navegación dentro de la App con fines
            estadísticos (como estudiar la utilidad de la App en la Compañía, su grado de uso, etc.), y de mejora de las
            funcionalidades que la App ofrece (mejorar la experiencia de usuario (UX) o/y ofrecer nuevas
            funcionalidades).
          </li>
        </ul>
        <p>
          La presente Política no regula el tratamiento de tus datos en el marco de la relación laboral o profesional
          que mantengas con la empresa que corresponda dentro del grupo empresarial NTT DATA Spain.
        </p>

        <h6 class="titleN4">4. ¿Por cuánto tiempo conservaremos tus datos?</h6>
        <p>
          Tus datos se conservarán mientras seas Usuario de la App, para permitirte realizar las reservas de puestos, si
          bien posteriormente podrán conservarse bloqueados durante los plazos de prescripción legalmente aplicables.
        </p>
        <p>
          Respecto a la gestión de las consultas o peticiones recibidas a través de la App, tus datos personales se
          conservarán durante el plazo necesario para responder las consultas o peticiones formuladas. En relación con
          el análisis del comportamiento de navegación, los datos serán tratados durante el mínimo tiempo imprescindible
          para obtener la información sobre la que se puedan extraer los fines estadísticos.
        </p>

        <h6 class="titleN4">5. ¿Quiénes son los destinatarios de tus datos?</h6>
        <p>
          Los datos personales que nos facilites podrán ser comunicados a las empresas del Grupo NTT DATA y fundaciones
          vinculadas, con fines administrativos internos. Algunas de las empresas del Grupo NTT DATA y fundaciones
          vinculadas se encuentran ubicadas fuera del Espacio Económico Europeo.
        </p>
        <p>
          Asimismo, tus datos podrían ser comunicados a Encargados del Tratamiento (proveedores tecnológicos), algunos
          de los cuales disponen de centros de proceso y/o soporte ubicados fuera Espacio Económico Europeo.
        </p>

        <p>
          Cuando tus datos pudieran salir del Espacio Económico Europeo, mantendrán el mismo nivel de protección en base
          al cumplimiento de las disposiciones previstas en la normativa europea de protección de datos. En este
          sentido, las transferencias internacionales de datos se realizarán (i) a países con un adecuado nivel de
          protección declarado por la Comisión Europea (ii) en base a la aportación de garantías adecuadas (cláusulas
          contractuales tipo o normas corporativas vinculantes), o (iii) en virtud de la autorización de la autoridad de
          control competente u otros supuestos previstos en la normativa.
        </p>
        <p>
          Para recibir más información sobre las transferencias internacionales de datos u obtener confirmación o copia
          de las garantías empleadas, puedes ponerse en contacto con el Delegado de Protección de Datos.
        </p>

        <h6 class="titleN4">6. ¿Cómo se protegen tus datos personales?</h6>
        <p>
          Nos comprometemos a mantener seguros tus datos personales, adoptando todas las precauciones razonables para
          hacerlo. Implementamos todas las medidas organizativas y técnicas necesarias de acuerdo con la normativa
          vigente contra cualquier acceso no autorizado y modificación, divulgación, pérdida o destrucción. Exigimos e
          imponemos contractualmente a los proveedores de servicios que manejan tus datos personales que hagan lo mismo.
        </p>
        <h6 class="titleN4">7. ¿Cuáles son tus derechos?</h6>
        <p>
          Puedes solicitar a la Compañia confirmación sobre si tus datos personales están siendo tratados y, en caso
          afirmativo, acceder a los mismos. También, puedes solicitar la rectificación de los datos inexactos o, en su
          caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines para
          los que se recogieron. En determinadas circunstancias, es posible solicitar la limitación del tratamiento de
          tus datos, en cuyo caso únicamente los conservaremos para el ejercicio o la defensa de reclamaciones.
        </p>
        <p>
          Puedes revocar en cualquier momento los consentimientos prestados. Ejercida la revocación, que no tendrá
          carácter retroactivo salvo disposición legal que imponga dicho carácter, tus datos podrán conservarse
          bloqueados durante los plazos de prescripción establecidos por las normas aplicables.
        </p>

        <p>
          Recuerda que también podrás oponerte al tratamiento de tus datos, en cuyo caso no trataremos los datos, salvo
          por motivos legítimos imperiosos o para el ejercicio o la defensa de posibles reclamaciones.
        </p>
        <p>
          Para ejercitar los citados derechos, puedes escribir al Delegado de Protección de Datos, adjuntando copia de
          documento acreditativo de tu identidad, a cualquiera de las siguientes direcciones:
        </p>

        <ul>
          <li>Correo Postal: Camino Fuente de la Mora, 1, 28050 Madrid, España</li>
          <li>
            Correo electrónico:
            <a href="mailto:data.protection.office@nttdata.com">data.protection.office@nttdata.com</a>
          </li>
        </ul>
        <p>Para ayudarnos a tramitar tu solicitud, te rogamos que indiques en el asunto “Protección de Datos”.</p>
        <p>
          Tienes derecho a presentar una reclamación ante la autoridad competente en materia de protección de datos (en
          España, <a href="https://www.aepd.es">www.aepd.es</a>) en caso de que tengas conocimiento o consideres que un
          hecho pueda suponer un incumplimiento de la normativa aplicable en materia de protección de datos.
        </p>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- English Version -->
  <div *ngIf="lang == 'en'" [lang]="lang" class="p-3 p-md-5">
    <mat-card class="d-grid">
      <mat-card-header>
        <h1 class="titleN1">Privacy Policy</h1>
      </mat-card-header>
      <mat-card-content class="py-3 py-md-5 px-4 px-md-5" style="text-align: justify">
        <p>
          This policy informs you about the data processing that is carried out, through the mobile or web App Häbitat
          (hereinafter, the "App"), although it is possible that this link or redirect to other websites and / or
          platforms owned by the Company or third parties with specialized content (such as services sector), with
          sections or forms that specifically regulate the processing of information obtained through them.
        </p>
        <p>
          You can find information about the data processing of said websites and / or platforms in their respective
          privacy pages and / or next to the form or contact channel with the Company, if applicable.
        </p>

        <h6 class="titleN4">1. Who is the Data Controller?</h6>

        <div class="container p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div><strong> Identity: </strong> NTT DATA SPAIN, S.L.U (“NTT DATA Spain” or the “Company”)</div>
              <div><strong> CIF: </strong> B-82387770</div>
              <div><strong> Postal address: </strong> Camino Fuente de la Mora, 1, 28050 Madrid, Spain.</div>
              <div><strong> Telephone: </strong> 917 490 000</div>
              <div>
                <strong> Contact of the Data Protection Officer: </strong>
                <a href="mailto: data.protection.office@nttdata.com" style="word-wrap: break-word">
                  data.protection.office@nttdata.com
                </a>
              </div>
            </div>
          </div>
        </div>

        <h6 class="titleN4">2. What data do we process?</h6>

        <p>
          The personal data that we process comes from the information that as a User ("User") you provide us with fill
          in the fields that you will find in the App Registry (web or mobile), and / or when sending us your queries or
          requests for information. Likewise, Habitat collects the following information:
        </p>
        <ul>
          <li>Flexible positions reserved by the user (office, position, date, etc.)</li>
        </ul>

        <p>
          We only collect the necessary data for each purpose. Keep in mind that, if you do not provide the information
          mandatory, it is possible that you cannot register in the App, or that we cannot answer your query or request
          and / or manage the reservation of positions.
        </p>

        <p>
          Habitat also receives anonymized information through the sensors to which it is connected about the use and
          occupation of positions and rooms in the buildings where the App is operating. The sensors they are only
          located in authorized offices. This information does not contain personal data and it will not be used for
          employee identification. The data processing carried out is limit only to the use of the App for the
          reservation of positions for professional purposes, and it is not carried out any kind of association or
          monitoring of data for labor control purposes.
        </p>

        <h6 class="titleN4">
          3. For what purpose do we process your data and what is the legitimacy for the treatment?
        </h6>
        <p>Personal data will be processed to:</p>
        <ul>
          <li>
            Manage the access and use of Häbitat to facilitate the reservation of flexible seats (indicating office,
            position, and date) depending on their availability, based on the execution of the employment contract;
          </li>
          <li>
            Manage queries or requests received through the App, based on the consent given to the formulate the query
            or request.
          </li>
          <li>
            • Analysis of browsing behavior within the App for statistical purposes; based on interest legitimate
            company to analyze browsing behavior within the App for purposes statistics (such as studying the usefulness
            of the App in the Company, its degree of use, etc.), and improvement of the functionalities that the App
            offers (improve the user experience (UX) or / and offer new functionalities).
          </li>
        </ul>
        <p>
          This Policy does not regulate the processing of your data within the framework of the employment or
          professional relationship. that you maintain with the corresponding company within the NTT DATA Spain business
          group.
        </p>

        <h6 class="titleN4">4. How long will we keep your data?</h6>
        <p>
          Your data will be kept as long as you are a User of the App, to allow you to make seat reservations, if or
          later, they may be kept blocked during the legally applicable limitation periods.
        </p>
        <p>
          Regarding the management of queries or requests received through the App, your personal data will be They will
          be kept for the period necessary to answer the queries or requests made. Regarding analysis of browsing
          behavior, the data will be processed for the minimum essential time to obtain the information on which
          statistical purposes can be extracted.
        </p>

        <h6 class="titleN4">5. Who are the recipients of your data?</h6>
        <p>
          The personal data that you provide us may be communicated to the companies of the NTT DATA Group and
          foundations linked, for internal administrative purposes. Some of the NTT DATA Group companies and foundations
          related parties are located outside the European Economic Area.
        </p>
        <p>
          Likewise, your data could be communicated to Treatment Managers (technology providers), some of which have
          process and / or support centers located outside the European Economic Area.
        </p>

        <p>
          When your data could leave the European Economic Area, they will maintain the same level of protection based
          on compliance with the provisions set forth in European data protection regulations. In this In this sense,
          international data transfers will be made (i) to countries with an adequate level of protection declared by
          the European Commission (ii) based on the provision of adequate guarantees (clauses contractual standard or
          binding corporate rules), or (iii) by virtue of the authorization of the authority of competent control or
          other assumptions provided for in the regulations.
        </p>
        <p>
          To receive more information about international data transfers or to obtain confirmation or copy of the
          guarantees used, you can contact the Data Protection Delegate.
        </p>

        <h6 class="titleN4">6. How is your personal data protected?</h6>
        <p>
          We are committed to keeping your personal data safe, taking all reasonable precautions to do it. We implement
          all the necessary organizational and technical measures in accordance with the regulations effective against
          any unauthorized access and modification, disclosure, loss or destruction. We demand e We contractually force
          service providers that handle your personal data to do the same.
        </p>
        <h6 class="titleN4">7. What are your rights?</h6>
        <p>
          You can ask the Company for confirmation about whether your personal data is being processed and, in case
          affirmative, access them. Also, you can request the rectification of inaccurate data or, in its case, request
          its deletion when, among other reasons, the data is no longer necessary for the purposes for those that were
          collected. In certain circumstances, it is possible to request the limitation of the treatment of your data,
          in which case we will only keep it for the exercise or defense of claims.
        </p>
        <p>
          You can revoke the consents given at any time. Exercised the revocation, which will not have retroactive
          character except legal provision that imposes such character, your data may be kept blocked during the
          limitation periods established by the applicable regulations.
        </p>

        <p>
          Remember that you can also oppose the processing of your data, in which case we will not process the data,
          except for compelling legitimate reasons or for the exercise or defense of possible claims.
        </p>
        <p>
          To exercise the aforementioned rights, you can write to the Data Protection Delegate, attaching a copy of
          document proving your identity, to any of the following addresses:
        </p>

        <ul>
          <li>Postal Mail: Camino Fuente de la Mora, 1, 28050 Madrid, Spain</li>
          <li>
            Email:
            <a href="mailto:data.protection.office@nttdata.com"> data.protection.office@nttdata.com </a>
          </li>
        </ul>
        <p>To help us process your request, please indicate in the subject "Data Protection".</p>
        <p>
          You have the right to file a claim with the competent authority on data protection (in Spain,
          <a href="https://www.aepd.es"> www.aepd.es </a>) in case you have knowledge or consider that a This fact may
          imply a breach of the applicable regulations on data protection.
        </p>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- Italian Version -->
  <div *ngIf="lang == 'it'" [lang]="lang" class="p-3 p-md-5">
    <mat-card class="d-grid">
      <mat-card-header>
        <h1 class="titleN1">Informativa sulla privacy</h1>
      </mat-card-header>
      <mat-card-content class="py-3 py-md-5 px-4 px-md-5">
        <p>
          La presente informativa ti informa sul trattamento dei dati che viene effettuato, tramite l'App mobile o web
          Häbitat (di seguito, l'"App"), sebbene sia possibile che questo link o reindirizzi ad altri siti web e/o
          piattaforme di proprietà della Società o di terzi con contenuti specializzati (come i servizi settore), con
          sezioni o moduli che disciplinano specificamente il trattamento delle informazioni ottenuto per loro tramite.
        </p>
        <p>
          È possibile trovare informazioni sul trattamento dei dati di detti siti Web e/o piattaforme nei rispettivi
          pagine privacy e/o accanto al form o al canale di contatto con la Società, se applicabile.
        </p>

        <h6 class="titleN4">1. Chi è il Titolare del trattamento?</h6>

        <div class="container p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div><strong> Identità: </strong> NTT DATA SPAIN, S.L.U (“NTT DATA Spain" o la "Società")</div>
              <div><strong> CIF: </strong> B-82387770</div>
              <div><strong> Indirizzo postale: </strong> Camino Fuente de la Mora, 1, 28050 Madrid, Spagna.</div>
              <div><strong> Telefono: </strong> 917 490 000</div>
              <div>
                <strong> Contatto del responsabile della protezione dei dati: </strong>
                <a href="mailto:data.protection.office@nttdata.com" style="word-wrap: break-word">
                  data.protection.office@nttdata.com
                </a>
              </div>
            </div>
          </div>
        </div>

        <h6 class="titleN4">2. Quali dati elaboriamo?</h6>

        <p>
          I dati personali che elaboriamo provengono dalle informazioni che come Utente ("Utente") ci fornite compila i
          campi che troverai nel Registro App (web o mobile), e/o quando ci invii le tue domande o richieste di
          informazioni. Allo stesso modo, Habitat raccoglie le seguenti informazioni:
        </p>
        <ul>
          <li>Posizioni flessibili riservate dall'utente (ufficio, posizione, data, ecc.)</li>
        </ul>

        <p>
          Raccogliamo solo i dati necessari per ogni scopo. Tieni presente che, se non fornisci le informazioni
          obbligatorio, è possibile che tu non possa registrarti nell'App o che non possiamo rispondere alla tua domanda
          o richiesta e/o gestire la prenotazione delle posizioni.
        </p>

        <p>
          Habitat riceve anche informazioni anonime attraverso i sensori a cui è collegato sull'uso e occupazione di
          postazioni e locali negli edifici in cui opera l'App. I sensori si trovano solo negli uffici autorizzati.
          Queste informazioni non contengono dati personali e non sarà utilizzato per l'identificazione dei dipendenti.
          Il trattamento dei dati effettuato è limitarsi al solo utilizzo dell'App per la prenotazione di posti per
          scopi professionali, e non viene effettuato qualsiasi tipo di associazione o monitoraggio dei dati ai fini del
          controllo del lavoro.
        </p>

        <h6 class="titleN4">3. Per quali finalità trattiamo i tuoi dati e qual è la liceità del trattamento?</h6>
        <p>I dati personali saranno trattati per:</p>
        <ul>
          <li>
            Gestire l'accesso e l'utilizzo di Häbitat per facilitare la prenotazione di posti flessibili (indicando
            carica, carica e data) a seconda della loro disponibilità, in base all'esecuzione del contratto di lavoro;
          </li>
          <li>
            Gestire quesiti o richieste pervenute tramite l'App, sulla base del consenso prestato al formulare la
            domanda o la richiesta.
          </li>
          <li>
            • Analisi del comportamento di navigazione all'interno dell'App a fini statistici; in base agli interessi
            società legittima per analizzare il comportamento di navigazione all'interno dell'App per scopi statistiche
            (come lo studio dell'utilità dell'App in Azienda, il suo grado di utilizzo, ecc.) e il miglioramento della
            funzionalità che l'App offre (migliorare l'esperienza dell'utente (UX) o/e offrire nuove funzionalità).
          </li>
        </ul>
        <p>
          La presente Informativa non disciplina il trattamento dei dati nell'ambito del rapporto di lavoro o
          professionale. che mantieni con la società corrispondente all'interno del gruppo aziendale NTT DATA Spagna.
        </p>

        <h6 class="titleN4">4. Per quanto tempo conserveremo i tuoi dati?</h6>
        <p>
          I tuoi dati saranno conservati finché sarai un Utente dell'App, per consentirti di effettuare prenotazioni di
          posti, se o successivamente, possono essere mantenuti bloccati durante i termini di prescrizione legalmente
          applicabili.
        </p>
        <p>
          Per quanto riguarda la gestione dei quesiti o richieste pervenute tramite l'App, i tuoi dati personali saranno
          Saranno conservati per il periodo necessario a rispondere ai quesiti o alle richieste formulate. Per quanto
          riguarda analisi del comportamento di navigazione, i dati saranno trattati per il tempo minimo indispensabile
          ottenere le informazioni sulle quali si possono estrarre a fini statistici.
        </p>

        <h6 class="titleN4">5. Chi sono i destinatari dei tuoi dati?</h6>
        <p>
          I dati personali che ci fornite potranno essere comunicati alle società del Gruppo NTT DATA e fondazioni
          collegati, per finalità amministrative interne. Alcune società e fondazioni del Gruppo NTT DATA parti
          correlate si trovano al di fuori dello Spazio economico europeo.
        </p>
        <p>
          Parimenti, i suoi dati potranno essere comunicati a Responsabili del trattamento (fornitori di tecnologia),
          alcuni di cui hanno centri di processo e/o di supporto situati al di fuori dello Spazio Economico Europeo.
        </p>

        <p>
          Quando i tuoi dati potrebbero lasciare lo Spazio economico europeo, manterranno lo stesso livello di
          protezione basato su rispetto di quanto previsto dalla normativa europea in materia di protezione dei dati. In
          questo In questo senso, i trasferimenti internazionali di dati saranno effettuati (i) verso paesi con un
          livello adeguato di tutela dichiarata dalla Commissione Europea (ii) sulla base della prestazione di adeguate
          garanzie (clausole standard contrattuale o norme vincolanti d'impresa), o (iii) in virtù dell'autorizzazione
          dell'autorità di controllo competente o altre ipotesi previste dalla normativa.
        </p>
        <p>
          Per ricevere maggiori informazioni sui trasferimenti internazionali di dati o per ottenere conferma o copia
          delle garanzie utilizzate, puoi rivolgerti al Delegato alla Protezione dei Dati.
        </p>

        <h6 class="titleN4">6. Come vengono protetti i tuoi dati personali?</h6>
        <p>
          Ci impegniamo a mantenere i tuoi dati personali al sicuro, prendendo tutte le precauzioni ragionevoli per
          fallo. Adottiamo tutte le misure organizzative e tecniche necessarie in conformità con le normative efficace
          contro qualsiasi accesso e modifica, divulgazione, perdita o distruzione non autorizzati. Chiediamo e
          Obblighiamo contrattualmente i fornitori di servizi che gestiscono i tuoi dati personali a fare lo stesso.
        </p>
        <h6 class="titleN4">7. Quali sono i tuoi diritti?</h6>
        <p>
          Puoi chiedere alla Società conferma che sia o meno in corso un trattamento dei tuoi dati personali e, in caso
          affermativo, accedervi. Inoltre, puoi richiedere la rettifica dei dati inesatti o, nella sua caso, chiederne
          la cancellazione quando, tra l'altro, i dati non siano più necessari per le finalità per quelli che sono stati
          raccolti. In determinate circostanze, è possibile richiedere la limitazione del trattamento di i tuoi dati,
          nel qual caso li conserveremo solo per l'esercizio o la difesa di reclami.
        </p>
        <p>
          Puoi revocare i consensi prestati in qualsiasi momento. Esercitata la revoca, che non avrà carattere
          retroattivo salvo disposizione di legge che imponga tale carattere, i tuoi dati potranno essere conservati
          bloccati durante i termini di prescrizione stabiliti dalla normativa applicabile.
        </p>

        <p>
          Ricorda che puoi anche opporti al trattamento dei tuoi dati, nel qual caso non tratteremo i dati, salvo per
          motivi legittimi impellenti o per l'esercizio o la difesa di eventuali pretese.
        </p>
        <p>
          Per esercitare i suddetti diritti, può scrivere al Responsabile della Protezione dei Dati, allegando copia del
          documento comprovante la tua identità, a uno dei seguenti indirizzi:
        </p>

        <ul>
          <li>Posta: Camino Fuente de la Mora, 1, 28050 Madrid, Spagna</li>
          <li>
            E-mail:
            <a href="mailto:data.protection.office@nttdata.com"> data.protection.office@nttdata.com </a>
          </li>
        </ul>
        <p>Per aiutarci a elaborare la tua richiesta, indica nell'oggetto "Protezione dei dati".</p>
        <p>
          Hai il diritto di presentare un reclamo all'autorità competente in materia di protezione dei dati (in Spagna,
          <a href="https://www.aepd.es"> www.aepd.es </a>) nel caso in cui siate a conoscenza o riteniate che un Questo
          fatto può implicare una violazione della normativa applicabile in materia di protezione dei dati.
        </p>
      </mat-card-content>
    </mat-card>
  </div>
</body>
