<mat-card>
  <mat-card-content class="marginBodyCard">
    <label class="titleN3 text-primary modalTitle"> {{ modalTitle | translate }}</label>
    <div *ngIf="loading" style="min-height: 250px; display: flex; align-items: center; justify-content: center">
      <app-loading></app-loading>
    </div>
    <div *ngIf="!loading" style="min-height: 250px">
      <form class="row" [formGroup]="buildingFormGroup" (ngSubmit)="insertBuilding()">
        <mat-form-field class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-6 paddingOdd firstDiv">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input
            title="{{ 'Name' | translate }}"
            matInput
            autocomplete="off"
            formControlName="name"
            type="text"
            class="p-1"
            maxlength="45"
            required
          />
          <mat-hint *ngIf="buildingFormGroup.get('name')?.value.length" align="end"
            >{{ buildingFormGroup.get('name').value.length }}/45</mat-hint
          >
          <mat-error *ngIf="f.name.errors?.required">{{ 'Name is required' | translate }}</mat-error>
          <mat-error *ngIf="f.name.errors?.duplicate">{{
            'There is already a space with this name, enter a new one' | translate
          }}</mat-error>
          <mat-error *ngIf="f.name.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-6 pRight">
          <mat-label>{{ 'City' | translate }}</mat-label>
          <mat-select class="p-1" formControlName="city" [(ngModel)]="buildingCity" required>
            <mat-option *ngFor="let city of cities" [value]="city">
              {{ city.name | translate }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="f.city.errors?.required">{{ 'City is required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-6 pRight firstDiv">
          <mat-label>{{ 'Address' | translate }}</mat-label>
          <input
            title="{{ 'Address' | translate }}"
            matInput
            autocomplete="off"
            formControlName="address"
            type="text"
            class="p-1"
            maxlength="80"
            required
          />
          <mat-hint *ngIf="buildingFormGroup.get('address')?.value.length" align="end"
            >{{ buildingFormGroup.get('address').value.length }}/80</mat-hint
          >
          <mat-error *ngIf="f.address.errors?.required">{{ 'Address is required' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-6 paddingOdd">
          <mat-label>{{ 'Zip code' | translate }}</mat-label>
          <input
            title="{{ 'Zip code' | translate }}"
            matInput
            autocomplete="off"
            formControlName="postalCode"
            type="text"
            class="p-1"
            maxlength="20"
            required
          />
          <mat-hint *ngIf="buildingFormGroup.get('postalCode')?.value.length" align="end"
            >{{ buildingFormGroup.get('postalCode').value.length }}/20</mat-hint
          >
          <mat-error *ngIf="f.postalCode.errors?.required">{{ 'PostalCode is required' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-6 paddingOdd">
          <mat-label>{{ 'Latitude' | translate }}</mat-label>
          <input
            title="{{ 'Latitude' | translate }}"
            matInput
            autocomplete="off"
            formControlName="latitude"
            type="number"
            onkeydown="return event.keyCode !== 69"
            class="p-1"
            min="-90"
            max="90"
            spellcheck="false"
          />
          <mat-error *ngIf="f.latitude.errors?.min">{{
            'Latitude should be higher or equal to -90 degrees' | translate
          }}</mat-error>
          <mat-error *ngIf="f.latitude.errors?.max">{{
            'Latitude should be lower or equal to 90 degrees' | translate
          }}</mat-error>
          <mat-error *ngIf="f.latitude.errors?.requiredCoordinate">{{
            'Please set a value for both latitude and lontigude, or do not set a value for coordinates at all'
              | translate
          }}</mat-error>
        </mat-form-field>
        <mat-form-field class="form-group col-xl-6 col-lg-6 col-md-6 col-sm-6 paddingOdd">
          <mat-label>{{ 'Longitude' | translate }}</mat-label>
          <input
            title="{{ 'Longitude' | translate }}"
            matInput
            autocomplete="off"
            formControlName="longitude"
            type="number"
            onkeydown="return event.keyCode !== 69"
            class="p-1"
            min="-180"
            max="180"
            spellcheck="false"
          />
          <mat-error *ngIf="f.longitude.errors?.min">{{
            'Longitude should be higher or equal to -180 degrees' | translate
          }}</mat-error>
          <mat-error *ngIf="f.longitude.errors?.max">{{
            'Longitude should be lower or equal to 180 degrees' | translate
          }}</mat-error>
          <mat-error *ngIf="f.longitude.errors?.requiredCoordinate">{{
            'Please set a value for both latitude and lontigude, or do not set a value for coordinates at all'
              | translate
          }}</mat-error>
        </mat-form-field>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="close(null)" type="button">
            {{ 'Cancel' | translate }}
          </button>
          <button mat-button class="modalButton bg-primary" type="submit" [disabled]="disabledSave">
            {{ 'Save' | translate }}
          </button>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
