<div *ngIf="havePermissionSubsection">
  <app-subsection-type-configuration
    [subsectionTypes]="subsectionTypes"
    (newType)="newType($event)"
    (modifiedType)="modifiedType($event)"
    (deletedType)="deletedType($event)"
  ></app-subsection-type-configuration>
  <app-subsection-configuration [subsectionTypes]="subsectionTypes">
  </app-subsection-configuration>
</div>

