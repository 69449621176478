import { ProximityNotificationConfig } from './ProximityNotificationConfig.class';
import { SidebarNotification } from './SidebarNotification.interface';

export class ProximityNotification implements SidebarNotification {
  id: number;
  name: string;
  icon = 'collapse-outline';
  date: string;
  subject = '';
  read = false;
  space1;
  space2;
  notificationConfig: ProximityNotificationConfig;

  constructor(configured: ProximityNotificationConfig, msg) {
    this.id = msg?.id;
    this.name = 'ProximityAlarm';
    this.date = msg?.date;
    this.space1 = msg?.space1;
    this.space2 = msg?.space2;
    if (configured.lastReadDate) {
      const notifDate = new Date(this.date);
      const lastRead = new Date(configured.lastReadDate);
      this.read = notifDate <= lastRead;
    }
    this.notificationConfig = configured;
  }
}
