<mat-card class="sticky-top filter">
  <mat-card-header class="border-0 p-0">
    <div class="row">
      <div class="col-4">
        <div
          [ngClass]="canChangeBuilding !== null ? 'justBorder' : 'justBorderDisabled'"
          (click)="openfilter(canChangeBuilding, filterContentOptions.building)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g fill="none" fill-rule="evenodd">
              <path
                [ngClass]="canChangeBuilding ? 'svgStroke' : 'svgStrokeDisable'"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.4"
                d="M25 4L25 24 8 24 8 4"
                transform="rotate(-180 16.5 14)"
              />
              <g [ngClass]="canChangeArea ? 'svgStroke' : 'svgStrokeDisable'">
                <path
                  d="M0 5H3V8H0zM0 10H3V13H0zM0 0H3V3H0zM6 5H9V8H6zM6 10H9V13H6zM6 0H9V3H6z"
                  transform="translate(12 8)"
                  [ngClass]="selectedBuilding !== null ? 'svgStroke' : 'svgStrokeDisabled'"
                />
              </g>
              <path
                [ngClass]="selectedBuilding !== null ? 'svgStroke' : 'svgStrokeDisable'"
                stroke="#0075AA"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.4"
                d="M25 24L25 29 18 29 18 25.667 14.375 25.667 14.376 29 8 29 8 24"
              />
            </g>
          </svg>
          <span class="titleN3 currentOption" *ngIf="selectedBuilding">
            {{ this.selectedBuilding.shownName }}
          </span>
        </div>
      </div>
      <div class="col-4">
        <div
          [ngClass]="canChangeFloor ? 'justBorder' : 'justBorderDisabled'"
          (click)="openfilter(canChangeFloor, filterContentOptions.floor)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g
              fill="none"
              fill-rule="evenodd"
              [ngClass]="canChangeFloor ? 'svgStroke' : 'svgStrokeDisabled'"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.4"
            >
              <path
                d="M19.473 26.97L27.541 26.97 27.541 5.637 4 5.637 4 26.97 15.77 26.97M13.037 6.051L13.037 17.551M13.037 15.28L15.626 15.28M27.541 15.28L19.045 15.28M21.505 15.28L21.505 17.551M12.848 25.941L12.848 22.387M21.505 25.941L21.505 22.387"
              />
            </g>
          </svg>
          <span class="titleN3 currentOption" *ngIf="selectedFloor">{{ this.selectedFloor.name }}</span>
        </div>
      </div>
      <div *ngIf="hasEsiteModule || hasReservationsModule" class="col-4">
        <div
          [ngClass]="canChangeArea ? 'justBorder' : 'justBorderDisabled'"
          (click)="openfilter(canChangeArea, filterContentOptions.area)"
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
            <g
              fill="none"
              fill-rule="evenodd"
              [ngClass]="canChangeArea ? 'svgStroke' : 'svgStrokeDisabled'"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.4"
            >
              <rect width="4" height="4" x="6" y="5.333" rx="1.5" />
              <rect width="4" height="4" x="23.333" y="5.333" rx="1.5" />
              <path d="M10.611 7.333L22.667 7.333" />
              <rect width="4" height="4" x="6" y="22.667" rx="1.5" />
              <rect width="4" height="4" x="23.333" y="22.667" rx="1.5" />
              <path d="M10.611 24.667L22.667 24.667" />
              <path d="M2 16L14 16" transform="rotate(90 8 16)" />
              <path d="M19.333 16L31.333 16" transform="rotate(90 25.333 16)" />
            </g>
          </svg>
          <span class="titleN3 currentOption" *ngIf="selectedArea">{{ this.selectedArea.name }}</span>
        </div>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content *ngIf="filterOpened" class="filterBox p-2">
    <div class="row scroll my-3 mx-0">
      <div class="row centerVertical">
        <div class="titleN3">
          {{ filterContent.title | translate }}
        </div>
        <div class="titleN31">
          {{ filterContent.title | translate }}
        </div>
        <div *ngIf="mostrarBoton" class="d-flex justify-content-end">
          <div *ngIf="filterContent.button == 'New space' && isBuildingWrite">
            <button
              mat-button
              *ngIf="filterContent.button"
              class="mobile-hidden"
              (click)="open(filterContent.modal)"
            >
              <span class="smallIcon bi bi-plus-circle"></span>
              <span class="ps-1">{{ filterContent.button | translate }}</span>
            </button>
          </div>
          <div *ngIf="filterContent.button == 'New floor' && isFloorWrite">
            <button
              mat-button
              *ngIf="filterContent.button"
              class="mobile-hidden"
              (click)="open(filterContent.modal)"
            >
              <span class="smallIcon bi bi-plus-circle"></span>
              <span class="ps-1">{{ filterContent.button | translate }}</span>
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="filterContent.options.length == 0" class="centerVertical px-3">
        {{ filterContent.msgEmpty | translate }}
      </div>
      <div class="d-flex flex-wrap justify-content-start">
        <div *ngFor="let item of filterContent.options" class="containerItem">
          <div
            class="divSelect"
            [ngClass]="item.id == filterSelected ? 'boxSelected' : 'justText'"
            (click)="changeSelected(item, filterContent.onchange)"
            [class.forbidenFilter]="!item.confirmed && item.floors && !isHiddenBuildingWrite"
          >
            <span class="ps-2" *ngIf="item.shownName != null; else elseName">
              {{ item.shownName }}
            </span>
            <ng-template #elseName>
            <span class="ps-2">
              {{ item.name }}
            </span>
            </ng-template>

            <span class="ejeHide">
              <span class="bi bi-eye"></span>
            </span>
          </div>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
