import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { ResourceIconsService } from 'src/app/services/backend/resourceIcons.service';

import { Permission } from '../../../../../models/auth/Role.class';
import { UserPermissions } from '../../../../../models/auth/UserPermissions.enum';
import { GlobalService } from '../../../../../services/common/global.service';
import { ResourceTypesService } from '../../../../../services/backend/resourceTypes.service';
import { ResourceIcon } from './../../../../../models/esite/ResourceIcon.class';
import { ResourceType } from './../../../../../models/esite/ResourceType.class';
import { User } from 'src/app/models/auth/User.class';

@Component({
  selector: 'app-resource-type-configuration',
  templateUrl: './resource-type-configuration.component.html',
  styleUrls: ['../resources-configuration.component.css'],
})
export class ResourceTypeConfigurationComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('newOrEditType') newOrEditType;
  @Input() resourceTypes: ResourceType[] = [];
  typeForm: UntypedFormGroup;
  loading = true;
  dataSource = new MatTableDataSource([]);
  displayedColumnsTable = ['code', 'icon', 'name', 'action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  modalTitle = 'New Resource Type';
  dialogRef: MatDialogRef<any>;
  submitted = false;
  subcriptions: Subscription[] = [];
  itsEditing = false;
  canWriteResourceType = false;
  userLogged: User;
  messageErrorResource = null;
  resourceIcons = [];

  constructor(
    private resourceIconsService: ResourceIconsService,
    private resourceTypesService: ResourceTypesService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private globalService: GlobalService
  ) {
    this.userLogged = this.globalService.getUser();
    this.checkPermissionsUser(this.userLogged.permissions);

    this.resourceIconsService.getResourceIcons().subscribe((res: ResourceIcon[]) => {
      this.resourceIcons = res;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resourceTypes.currentValue) {
      this.dataSource.data = changes.resourceTypes.currentValue;
      this.loading = false;
    }
  }

  ngOnInit(): void {
    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  checkPermissionsUser(permissions: Permission[]): void {
    this.canWriteResourceType =
      permissions?.find((item) => item.name === UserPermissions.Resources && item.type == 'WRITE') !== undefined;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createNewType(): void {
    this.itsEditing = false;
    this.modalTitle = 'New Resource Type';
    this.buildForm();
    this.openModal();
  }

  openModal(): void {
    this.dialogRef = this.dialog.open(this.newOrEditType, { panelClass: 'custom-dialog', disableClose: true });
  }

  buildForm(): void {
    this.typeForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]],
      name: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]],
      icon: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]],
    });
  }

  cancel(): void {
    this.dialogRef.close();
    this.submitted = false;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.typeForm.invalid) {
      this.submitted = false;
      return;
    }

    if (
      this.resourceTypes.find((item) => item.code === this.typeForm.value.code && item.id !== this.typeForm.value.id)
    ) {
      setTimeout(() => {
        this.typeForm.get('code').markAsDirty();
        this.typeForm.get('code').markAsTouched();
        this.typeForm.get('code').setErrors({ duplicate: true });
      }, 1);
      this.submitted = false;
      return;
    }

    const resourceType: ResourceType = new ResourceType({
      id: this.typeForm.value.id,
      code: this.typeForm.value.code,
      name: this.typeForm.value.name,
      icon: this.typeForm.value.icon.code,
    });

    this.subcriptions.push(
      this.resourceTypesService.saveResourceType(resourceType).subscribe({
        next: () => {
          this.globalService.printMessage('Resource type saved', 'success');
          this.cancel();
        },
        error: (err: HttpErrorResponse) => {
          this.setFormErrors(err);
          this.globalService.printMessage('Resource type could not be saved', 'error');
          this.submitted = false;
        },
      })
    );
  }

  setFormErrors(err: HttpErrorResponse): void {
    if (err.status === 409) {
      this.messageErrorResource = err.error.message;
      this.typeForm.setErrors({ error: err });
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
    } else {
      setTimeout(() => {
        this.typeForm.get('name').markAsDirty();
        this.typeForm.get('name').markAsTouched();
        this.typeForm.get('name').setErrors({ unknown: true });

        this.typeForm.get('code').markAsDirty();
        this.typeForm.get('code').markAsTouched();
        this.typeForm.get('code').setErrors({ unknown: true });
      }, 1);
    }
  }

  deleteResourceType(type: ResourceType): void {
    const modalData = new ConfirmModalData('DeleteResourceType', 'InfoDeleteResourceType');
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        this.subcriptions.push(
          this.resourceTypesService.deleteResourceType(type.id).subscribe({
            error: () => {
              this.globalService.printMessage('could not delete resource type, please try again', 'error');
            },
            complete: () => {
              this.globalService.printMessage('resource type deleted successfully', 'success');
            },
          })
        );
      }
    });
  }

  editResourceType(type: ResourceType): void {
    this.modalTitle = 'Edit Resource Type';
    this.itsEditing = true;

    const icon = this.resourceIcons.find((item) => item.code === type.icon);
    this.typeForm = this.formBuilder.group({
      id: type.id,
      code: [type.code, [Validators.required, Validators.pattern(/^[^,]+$/)]],
      name: [type.name, [Validators.required, Validators.pattern(/^[^,]+$/)]],
      icon: [icon, [Validators.required, Validators.pattern(/^[^,]+$/)]],
    });
    this.openModal();
  }
  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.typeForm.controls;
  }
}
