<div [ngClass]="{'is-sidebar-open': activeLeftPanel}">
  <!-- LOADING SPINNER -->
  <div *ngIf="loading" class="h-100 d-flex justify-content-center align-items-center">
    <app-loading></app-loading>
  </div>

  <!-- HEADER -->
  <ng-container *ngIf="!loading">
    <div class="app-header">
      <app-header style="width: 100%" (activeLeftPanelOutput)="getActiveLeftPanel($event)"></app-header>
    </div>

    <div class="app-body">
      <div class="app-body-wrapper gap-3">
        <aside *ngIf="activeLeftPanel" class="app-sidebar d-none d-md-block">
          <div class="sticky-top">
            <app-notificationbar *ngIf="hasEsiteDevicesModule || hasConteoModule"></app-notificationbar>
            <app-createincident *ngIf="havePermissionIncidents"></app-createincident>
            <app-menu-informes *ngIf="selectedPageInforme"></app-menu-informes>
          </div>
        </aside>
        <main class="app-content">
          <div class="d-flex d-md-none flex-column" style="margin-top: -45px">
            <div class="row">
              <div [class]="havePermissionIncidents ? 'col-6' : 'col-12'" *ngIf="hasEsiteDevicesModule || hasConteoModule">
                <app-notificationbar></app-notificationbar>
              </div>
              <div class="col-6">
                <app-createincident *ngIf="havePermissionIncidents"></app-createincident>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <app-menu-informes *ngIf="selectedPageInforme"></app-menu-informes>
              </div>
            </div>
          </div>
          <div class="pb-3">
            <router-outlet></router-outlet>
          </div>
        </main>
      </div>
    </div>
  </ng-container>
</div>
