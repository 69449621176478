import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { from, of } from 'rxjs';
import { distinct, finalize, mergeMap } from 'rxjs/operators';
import { User } from 'src/app/models/auth/User.class';
import { Area } from 'src/app/models/esite/Area.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { City } from 'src/app/models/habitat/City.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { EsiteService } from 'src/app/services/backend/esite.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { GlobalService } from 'src/app/services/common/global.service';

import { BuildingFormComponent } from '../../pages/configuration/space-configuration/building-form/building-form.component';
import { FloorFormComponent } from '../../pages/configuration/space-configuration/floor-form/floor-form.component';
import { Permission, UserBuilding } from './../../../models/auth/Role.class';
import { UserPermissions } from './../../../models/auth/UserPermissions.enum';
import { StorageVariables } from './../../../models/StorageVariables.enum';
import { UserService } from './../../../services/backend/user.service';

@Component({
  selector: 'app-space-filter',
  templateUrl: './space-filter.component.html',
  styleUrls: ['./space-filter.component.scss'],
})
export class SpaceFilterComponent implements OnInit {
  @Input() canChangeBuilding = true;
  @Input() canChangeFloor = true;
  @Input() canChangeArea = true;
  @Input() mostrarBoton = false;
  @Output() noBuildings: EventEmitter<boolean> = new EventEmitter();
  @Output() buildingChanged: EventEmitter<Building> = new EventEmitter();
  @Output() noFloors: EventEmitter<boolean> = new EventEmitter();
  @Output() floorChanged: EventEmitter<Floor> = new EventEmitter();
  @Output() areaChanged: EventEmitter<Area> = new EventEmitter();

  userLogged: User;
  private permissions: Permission[] = [];
  userBuilding: UserBuilding;
  buildings: Building[] = [];
  // Selected options
  selectedBuilding: Building = new Building('');
  selectedFloor: Floor;
  selectedArea: Area;
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  // Filter definition
  filterContentOptions = {
    empty: { title: '', button: '', options: [], onchange: '', modal: null, msgEmpty: '' },
    building: {
      title: 'Spaces',
      button: 'New space',
      options: [],
      onchange: (item): void => this.changeBuildingSelected(item),
      selected: 0,
      modal: BuildingFormComponent,
      msgEmpty: 'NoBuildingYet',
    },
    floor: {
      title: 'SpaceFloors',
      button: 'New floor',
      options: [],
      onchange: (item): void => this.changeFloorSelected(item),
      selected: 0,
      modal: FloorFormComponent,
      msgEmpty: 'NoFloorsInBuilding',
    },
    area: {
      title: 'PlanAreas',
      button: null,
      options: [],
      onchange: (item): void => this.changeAreaSelected(item),
      selected: 0,
      modal: null,
      msgEmpty: 'NoAreasInFloor',
    },
  };

  // Filter
  filterOpened = false;
  filterSelected = 0;
  filterContent = this.filterContentOptions.empty;
  isBuildingWrite = false;
  isFloorWrite = false;
  isHiddenBuildingWrite = false;

  constructor(
    private habitatService: HabitatService,
    private esiteService: EsiteService,
    private globalService: GlobalService,
    private dialog: MatDialog,
    private userService: UserService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.hasReservationsModule = this.globalService.getReservationModule();

    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;

    this.ckeckPermissionsNewBuilding(this.permissions);
    this.ckeckPermissionsUser(this.permissions);
  }

  ngOnInit(): void {
    if (this.userLogged) {
      this.reloadBuildings();
      this.globalService.userBuildingsChanged.subscribe(() => {
        this.initFilter();
      });
    } else {
      this.globalService.userLogged$.subscribe((user) => {
        if (user) {
          this.userLogged = new User(user);
          this.reloadBuildings();
          this.globalService.userBuildingsChanged.subscribe(() => {
            this.initFilter();
          });
        }
      });
    }
  }

  managerPermissions(): void {
    // Check if user has permissions to create buildings and floors, and also if we should or not display the buttons
    if (!this.permissions?.map((permission) => permission.name).includes(UserPermissions.Spaces)) {
      this.filterContentOptions.building.button = null;
    }
    if (!this.permissions?.map((permission) => permission.name).includes(UserPermissions.Metadata)) {
      this.filterContentOptions.floor.button = null;
    }
  }

  getBuildingId(): number {
    return this.userLogged?.mainBuilding
      ? this.userLogged?.mainBuilding
      : parseInt(localStorage.getItem(StorageVariables.FAVORITE_BUILDING));
  }

  initFilter(): void {
    this.managerPermissions();
    this.filterContentOptions.building.options = [];

    this.globalService.userBuildings$.subscribe((resp) => {
      this.filterContentOptions.building.options = [];
      const storedBuilding = this.getBuildingId();

      if (resp.length === 0) {
        this.canChangeFloor = false;
      }
      if (!this.canChangeFloor) {
        this.canChangeArea = false;
      }
      resp.forEach((item) => {
        const building = new Building(item);

        if (!building.confirmed && this.isHiddenBuildingWrite) {
          building.shownName = '! ' + building.name;
        }
        this.filterContentOptions.building.options.push(building);
        this.canChangeArea = this.canChangeArea && this.canChangeFloor;
      });

      let defaultBuilding = this.filterContentOptions.building.options.find((item) => item.id === storedBuilding);
      if ((!storedBuilding || !defaultBuilding) && this.filterContentOptions.building.options.length > 0) {
        defaultBuilding = this.filterContentOptions.building.options[0];
      }

      if (defaultBuilding) {
        this.changeBuildingSelected(defaultBuilding);
      } else {
        this.noBuildings.emit(true);
        this.canChangeArea = false;
        this.canChangeFloor = false;
      }
    });
  }

  ckeckPermissionsNewBuilding(permissions: Permission[]): void {
    this.isBuildingWrite =
      permissions?.find((permission) => permission.name == UserPermissions.Buildings && permission.type == 'WRITE') !==
      undefined;
  }

  ckeckPermissionsUser(permissions: Permission[]): void {
    this.isFloorWrite =
      permissions?.find((permission) => permission.name == UserPermissions.Floors && permission.type == 'WRITE') !==
      undefined;

    this.isHiddenBuildingWrite =
      this.permissions?.find(
        (permission) => permission.name == UserPermissions.HiddenBuildings && permission.type == 'WRITE'
      ) !== undefined;
  }

  changeBuildingSelected(building: Building): void {
    if (building.id !== this.selectedBuilding?.id) {
      this.selectedFloor = null;
      this.selectedBuilding = building;
      this.ckeckPermissionsNewBuilding(this.permissions);
      this.ckeckPermissionsUser(
        this.globalService.getPermissionsBuilding(
          this.selectedBuilding.id,
          this.userLogged?.permissionBuilding,
          this.permissions
        )
      );
      this.filterContentOptions.building.selected = building.id;
      this.filterSelected = building.id;
      if (this.canChangeFloor) {
        this.getBuildingFloors(this.selectedBuilding);
      } else {
        this.emitChangeBuilding(building);
      }
    } else {
      this.filterOpened = false;
    }
  }

  emitChangeBuilding(building: Building): void {
    localStorage.setItem(StorageVariables.FAVORITE_BUILDING, building.id.toString());
    localStorage.setItem(StorageVariables.FAVORITE_TIMEZONE, building.city.timezone);
    this.buildingChanged.emit(building);
    this.filterOpened = false;
  }

  getBuildingFloors(building: Building): void {
    building.floors = [];
    const storedFloor = parseInt(localStorage.getItem(StorageVariables.FAVORITE_FLOOR));
    this.habitatService.getFloorsByBuilding(building.id).subscribe((resp) => {
      resp.forEach((item) => {
        const floor = new Floor(item);
        building.floors.push(floor);
      });
      let defaultFloor = building.floors.find((item) => item.id === storedFloor);
      if ((!storedFloor || !defaultFloor) && resp.length > 0) {
        defaultFloor = resp[0];
      }
      if (defaultFloor) {
        this.noFloors.emit(false);
        this.changeFloorSelected(defaultFloor);
      } else {
        this.filterOpened = false;
        this.noFloors.emit(true);
      }
      building.floors.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      this.filterContentOptions.floor.options = building.floors;
      this.emitChangeBuilding(building);
    });
  }

  changeFloorSelected(floor): void {
    if (floor !== this.selectedFloor) {
      this.selectedArea = null;
      this.selectedFloor = floor;
      this.filterContentOptions.floor.selected = floor.id;
      this.filterSelected = floor.id;
      if (this.hasEsiteModule) {
        this.getFloorAreas(this.selectedFloor);
      } else {
        localStorage.setItem(StorageVariables.FAVORITE_FLOOR, floor.id);

        setTimeout(() => {
          this.floorChanged.emit(floor);
        }, 100);
        this.filterOpened = false;
      }
    } else {
      this.filterOpened = false;
    }
  }

  open(template): void {
    const dialogRef = this.dialog.open(template, { data: { building: this.selectedBuilding }, disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data) {
        this.selectedBuilding = null;
        this.reloadBuildings();
      }
    });
  }

  getFloorAreas(floor): void {
    floor.areas = [];
    this.esiteService.getAreasByFloor(floor.id).subscribe((resp) => {
      resp.forEach((item) => {
        const area = new Area(item);
        floor.areas.push(area);
      });
      floor.areas.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      this.filterContentOptions.area.options = floor.areas;
      localStorage.setItem(StorageVariables.FAVORITE_FLOOR, floor.id);

      this.floorChanged.emit(floor);
      this.filterOpened = false;
    });
  }

  changeAreaSelected(area): void {
    if (area !== this.selectedArea) {
      this.selectedArea = area;
      localStorage.setItem(StorageVariables.FAVORITE_AREA, area.id);
      this.filterContentOptions.area.selected = area.id;
      this.filterSelected = area.id;
      this.areaChanged.emit(area);
      this.filterOpened = false;
    }
  }

  openfilter(canOpen, option): void {
    if (canOpen) {
      if (this.filterOpened && this.filterContent === option) {
        this.filterOpened = false;
      } else {
        this.filterOpened = true;
      }
      const uniqueOptions = option.options.reduce((unique, option) => {
        const exists = unique.find((item) => item.id === option.id);
        if (!exists) {
          unique.push(option);
        }
        return unique;
      }, []);
      option.options = uniqueOptions;
      this.filterContent = option;
      this.filterSelected = option.selected;
    }
  }

  changeSelected(item, onchangemethod: VoidFunction): void {
    onchangemethod.call(this, item);
  }

  reloadBuildings(): void {
    this.userService.getUser(this.userLogged?.id).subscribe((userBuilding: UserBuilding) => {
      this.userBuilding = userBuilding;
      this.getBuildingsAsociations();
    });
  }

  getBuildingsAsociations(): void {
    this.buildings = this.userLogged?.buildings;
    const buildingIds = this.userBuilding.roles.map((role) => role.building).filter((idBuilding) => idBuilding > -1);
    of(buildingIds)
      .pipe(distinct())
      .subscribe((ids) => {
        from(ids)
          .pipe(
            mergeMap((idBuilding) => this.habitatService.getBuilding(idBuilding)),
            finalize(() => {
              this.getMore();
            })
          )
          .subscribe((building) => {
            this.buildings.push(building);
          });
      });
  }

  getMore(): void {
    this.habitatService.getCities().subscribe((cities: City[]) => {
      let citiesLoaded = 0;
      const buildingsArray = [];
      this.buildings.forEach((item) => {
        const building = new Building(item);
        const city = cities.find((item) => item.id == building.city.id);
        building.city = new City(city);
        buildingsArray.push(building);
        citiesLoaded = citiesLoaded + 1;
        if (citiesLoaded === this.buildings.length) {
          this.globalService.setUserBuildings(buildingsArray);
          this.initFilter();
        }
      });
    });
  }
}
