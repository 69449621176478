import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Permission } from './../../../../models/auth/Role.class';
import { User } from './../../../../models/auth/User.class';
import { UserPermissions } from './../../../../models/auth/UserPermissions.enum';
import { GlobalService } from './../../../../services/common/global.service';
import { NewContinuousReserveComponent } from './new-continuous-reserve/new-continuous-reserve.component';

@Component({
  selector: 'app-continuous-reserve',
  templateUrl: './continuous-reserve.component.html',
  styleUrls: ['./continuous-reserve.component.css'],
})
export class ContinuousReserveComponent implements OnInit {
  userID: number;
  reloadTable = false;
  userLogged: User = null;
  canWriteReservations = false;
  private permissions: Permission[] = [];

  constructor(
    private globalService: GlobalService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<ContinuousReserveComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.userID = data.user;
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
  }

  ngOnInit(): void {
    this.canWriteReservations =
      this.permissions?.find(
        (permission) => permission.name == UserPermissions.ContinuousReservations && permission.type == 'WRITE'
      ) !== undefined;
  }

  close(): void {
    this.dialogRef.close();
  }

  openNewContinuousReserve(): void {
    const dialogRef = this.dialog.open(NewContinuousReserveComponent, {
      data: { user: this.userID, canWriteReservations: this.canWriteReservations },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((cancel) => {
      if (!cancel) {
        this.reloadTable = true;
        setTimeout(() => {
          this.reloadTable = false;
        }, 1);
      }
    });
  }
}
