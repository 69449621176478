import { AvailabilityType } from '../esite/AvailabilityType.class';
import { SpaceUseType } from '../esite/SpaceUseType.enum';
import { SpacesState } from './SpacesState.class';
import { SpacesStructure } from './SpacesStructure.class';

export class GroupSpacesState {
  groupStateMap = new Map<number, SpacesState>();
  groupStructureMap = new Map<number, SpacesStructure>();

  updateGroupSpacesState(
    spaceId: number,
    newAvailabilityType: AvailabilityType,
    oldAvailabilityType: AvailabilityType
  ): void {
    let floorId;
    let spaceType: SpaceUseType;

    for (const [k, v] of this.groupStructureMap) {
      spaceType = v.getSpaceType(spaceId);
      if (spaceType) {
        floorId = k;
        break;
      }
    }
    this.updateFloorState(floorId, spaceType, newAvailabilityType, oldAvailabilityType);
  }

  updateFloorState(
    floorId,
    spaceType: SpaceUseType,
    newAvailabilityType: AvailabilityType,
    oldAvailabilityType: AvailabilityType
  ): void {
    const state: SpacesState = this.groupStateMap.get(floorId);
    if (state) {
      state.updateAvailabilityTypeStatus(newAvailabilityType, oldAvailabilityType, spaceType);
    }
  }
}
