import { Component, OnInit } from '@angular/core';
import { OccupationNotification } from 'src/app/models/notifications/OccupationNotification.class';
import { ProximityNotification } from 'src/app/models/notifications/ProximityNotification.class';
import { SidebarNotification } from 'src/app/models/notifications/SidebarNotification.interface';
import { NotificationsService } from 'src/app/services/pages/notifications.service';

@Component({
  selector: 'app-notificationbar',
  templateUrl: './notificationbar.component.html',
  styleUrls: ['./notificationbar.component.scss'],
})
export class NotificationbarComponent implements OnInit {
  // @Input() notifications$: Observable<SidebarNotification> = new Observable();
  notificationsQueu: SidebarNotification[] = [];
  badgeNumber = '0';
  unreadNotifs = this.notificationsQueu.length > 0 ? this.notificationsQueu.length : null;
  LIMIT_QUEUE = 3;

  constructor(private notificationsService: NotificationsService) {}

  ngOnInit(): void {
    this.notificationsService.newNotification$.subscribe((notification: SidebarNotification) => {
      if (notification) {
        this.manageQueue(notification);
      }
    });
    this.notificationsService.emptyNotifications$.subscribe((type: string) => {
      if (type) {
        this.clearQueue(type);
      }
    });
  }

  getNumberToDisplay(): void {
    if (this.unreadNotifs) {
      this.badgeNumber = null;
      if (this.unreadNotifs > 99) {
        this.badgeNumber = '+99';
      } else {
        this.badgeNumber = '' + this.unreadNotifs;
      }
    } else {
      this.badgeNumber = '0';
    }
  }

  manageQueue(notification: SidebarNotification): void {
    if (this.notificationsQueu.length > this.LIMIT_QUEUE) {
      this.notificationsQueu.shift();
    }
    this.notificationsQueu.push(notification);
    this.notificationsQueu.reverse();
    this.unreadNotifs = this.unreadNotifs ? this.unreadNotifs + 1 : 1;
    this.getNumberToDisplay();
  }

  clearQueue(type: string): void {
    const indexToRemove = [];
    if (type === 'ProximityNotification') {
      this.notificationsQueu.forEach((item, index) => {
        if (item instanceof ProximityNotification) {
          indexToRemove.push(index);
        }
      });
    } else if (type === 'OccupationNotification') {
      this.notificationsQueu.forEach((item, index) => {
        if (item instanceof OccupationNotification) {
          indexToRemove.push(index);
        }
      });
    }
    let offset = 0;
    indexToRemove.forEach((index) => {
      this.notificationsQueu.splice(index - offset, 1);
      offset++;
    });
    this.unreadNotifs = this.notificationsQueu.length;
    this.getNumberToDisplay();
  }
}
