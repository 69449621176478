import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from '../common/http.service';
import { HttpMethods } from './../../models/HttpMethods.enum';

@Injectable({
  providedIn: 'root',
})
export class ReservationEventService {
  constructor(private httpService: HttpService) {}

  requestToReservationsEvents(type, method, body?, headers?): Observable<any> {
    const url = `${environment.reservations_events_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequest(protocol + url, type, method, body, headers);
  }

  refreshReservatonEvents(): Observable<any> {
    const method = 'v1/refresh';
    return this.requestToReservationsEvents(HttpMethods.POST, method, {});
  }
}
