<div class="row m-3">
  <div class="col-md-5 col-9">
    <div class="title mt-md-2 mt-3">
      {{ 'Language' | translate }}
    </div>
    <div class="line"></div>
    <div class="pt-3">
      <mat-form-field class="separateDiv">
        <mat-label [className]="isClicker === 'language' && inputSelected ? 'active' : 'inactive'">
          {{ 'selectLenguage' | translate }}</mat-label
        >
        <mat-select [(ngModel)]="selectedLanguage" (selectionChange)="handleLanguageSelect($event.value)" class="p-1">
          <mat-option value="es">{{ 'Spanish' | translate }}</mat-option>
          <mat-option value="en">{{ 'English' | translate }}</mat-option>
          <mat-option value="it">{{ 'Italian' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="col-md-1"></div>

  <div class="col-md-6 col-12">
    <!-- Privacy Policy -->
    <div class="title mt-md-2 mt-4">
      {{ 'Privacy' | translate }}
    </div>
    <div class="line"></div>
    <div class="pt-3">
      <div>
        <span>
          <a [routerLink]="privacyPolicyUrl" target="_blank" class="text-primary link">{{
            'Privacy Policy' | translate
          }}</a>
        </span>
      </div>
    </div>

    <!-- WEB, BACK && API Version -->
    <div class="title">
      {{ 'Versions' | translate }}
    </div>
    <div class="line"></div>
    <div class="pt-3">
      <div class="justText">
        <span class="text-primary version">Website: </span> <span class="version">{{ webVersion }}</span> <br />
        <div *ngIf="habitatVersion">
          <span class="text-primary version">Habität: </span> <span class="version">{{ habitatVersion }}</span> <br />
        </div>
        <div *ngIf="hasEsiteModule && esiteVersion">
          <span class="text-primary version">Esite: </span> <span class="version">{{ esiteVersion }}</span> <br />
        </div>
        <div *ngIf="hasConteoModule && conteoVersion">
          <span class="text-primary version">Conteo: </span> <span class="version">{{ conteoVersion }}</span> <br />
        </div>
        <div *ngIf="hasReservationsModule && reservationsVersion">
          <span class="text-primary version">Reservations: </span><span class="version">{{ reservationsVersion }}</span>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>
