import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.issuer,
  loginUrl: environment.loginUrl,
  logoutUrl: environment.logoutUrl,
  responseType: environment.responseType,
  // URL of the SPA to redirect the user to after login
  redirectUri: environment.web_protocol + environment.redirectionURI,
  postLogoutRedirectUri: environment.logoutRedirectionURI
    ? environment.web_protocol + environment.logoutRedirectionURI
    : null,

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: environment.ADClientID,

  // set the scope for the permissions the client should request
  strictDiscoveryDocumentValidation: environment.strictDiscoveryDocumentValidation,

  // set the scope for the permissions the client should request
  scope: environment.scope,

  clearHashAfterLogin: false,
};
