import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login-error',
  templateUrl: './login-error.component.html',
  styleUrls: ['../auth.component.scss'],
})
export class LoginErrorComponent {
  forbidden = true;
  hasReservationWeb = '';
  constructor(private authService: AuthService, private router: Router) {
    this.forbidden = router.url === '/forbidden';
    this.hasReservationWeb = environment.reservations_frontend_url;
  }

  /**
   * Forze new login process
   */
  login(): void {
    this.authService.login('/realtime');
  }

  goToReservations(): void {
    window.location.href = environment.reservations_frontend_url;
  }
}
