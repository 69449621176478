import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpMethods } from './../../models/HttpMethods.enum';
import { HabitatService } from './habitat.service';

@Injectable({
  providedIn: 'root',
})
export class PowerBiService {
  constructor(private habitatService: HabitatService) {}

  getPowerBiEmbedUrl(typeReport: string): Observable<any> {
    const method = 'v2/powerbi/url';
    let headers = new HttpHeaders();
    headers = headers.set('x-powerbi-type', typeReport);
    return this.habitatService.requestToHabitat(HttpMethods.GET, method, null, headers);
  }
}
