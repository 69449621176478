import { ReservationRoles } from './ReservationRoles.const';
import { ReservationStatus } from './ReservationStatus.enum';
import { ReservationUser } from './ReservationUser.class';

export class ReservationSpace {
  id: number;
  code: string;
  type: number;
  typeName: string;
  useType: string;
  description: string;
  entryDate: Date;
  x: number;
  y: number;
  floor: number;
  capacity: number;
  availabilityType: string;
  availabilityDate: Date;
  role: string;
  needsApproval: boolean;
  approvers: ReservationUser[];
  groupable: boolean;
  status: string = ReservationStatus.NotReserved;
  reservationOwner: string;
  reservationDate: Date;
  reservationDateString: string;
  exchangeEmail: string;

  constructor(msg) {
    this.id = msg.id;
    this.code = msg.code;
    this.type = msg.type;
    this.typeName = msg.typeName;
    this.useType = msg.useType;
    this.description = msg.description;
    this.entryDate = msg.entryDate;
    this.x = msg.x;
    this.y = msg.y;
    this.floor = msg.floor;
    this.capacity = msg.capacity;
    this.availabilityType = msg.availabilityType;
    this.availabilityDate = msg.availabilityDate;
    this.role = msg.role;
    this.needsApproval = msg.needsApproval;
    msg.approvers?.forEach((approver) => {
      approver = new ReservationUser({ id: approver });
    });
    this.approvers = msg.approvers;
    this.groupable = msg.groupable;
  }

  setExcelReservationData(parsedRow): void {
    const approversArray = [];
    this.id = 0;
    this.role = ReservationRoles[parsedRow['Nivel de Permiso de Reserva']];
    this.needsApproval = parsedRow['Aprobacion'] === 'SI';
    const approversString = parsedRow['Numero Empleado Aprobador'];
    if (approversString) {
      if (approversString.indexOf(',') !== -1) {
        approversString
          .replace(' ', '')
          .split(',')
          .forEach((element) => {
            approversArray.push(Number(element));
          });
      } else {
        approversArray.push(Number(approversString));
      }
      this.approvers = approversArray;
    } else {
      this.approvers = [];
    }
    this.groupable = parsedRow['Reserva Grupal'] === 'SI';
    this.exchangeEmail = parsedRow['Email Exchange'];
  }
}
