import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-token-expiration-alert',
  templateUrl: './token-expiration-alert.component.html',
  styleUrls: [],
})
export class TokenExpirationAlertComponent {
  constructor(private authService: AuthService) {}

  /**
   * On button click refresh the session
   */
  onClick(): void {
    this.authService.refresh();
  }
}
