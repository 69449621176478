import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

import { Permission, Role } from './../../../../../models/auth/Role.class';
import { UserService } from './../../../../../services/backend/user.service';
import { AppConstants } from '../../../../../shared/AppConstants';

@Component({
  selector: 'app-roles-configuration-form',
  templateUrl: './roles-configuration-form.component.html',
  styleUrls: ['./roles-configuration-form.component.css'],
})
export class RolesConfigurationFormComponent implements OnInit {
  @Output() saveNewRole: EventEmitter<number> = new EventEmitter();
  submitted = false;
  loading = false;
  permissions: Permission[] = [];
  roleFormGroup: UntypedFormGroup;
  allSelected = false;
  permissionsChk = [];
  disabledSave = false;
  permissionsSelected: Permission[] = [];
  checkedWriteRead = true; // true = write, false = read
  checkedDefault = false; // true = default reservation role, false = not default reservation role
  private WRITE = 'WRITE';
  private READ = 'READ';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {
    this.getPermissions();
  }

  ngOnInit(): void {
    this.roleFormGroup = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]], //Avoid the input of commas.
    });
  }

  getPermissions(): void {
    this.permissionsChk = [];
    this.userService.getPermissions().subscribe((permissions: Permission[]) => {
      this.permissions = permissions;
      permissions.forEach((permission) => {
        this.permissionsChk.push({
          id: permission.id,
          name: permission.name,
          checked: false,
          data: permission,
          disabled: false,
        });
      });
    });
  }

  insertRole(): void {
    this.submitted = true;
    this.disabledSave = true;

    if (this.roleFormGroup.get('name')?.value.length === 0) {
      this.disabledSave = false;
      this.submitted = false;
      return;
    }

    this.permissionsSelected?.forEach((permission) => {
      permission.type = this.checkedWriteRead ? this.WRITE : this.READ;
    });

    this.loading = true;
    const roleToInsert = new Role();
    roleToInsert.name = this.roleFormGroup.value.name;
    roleToInsert.reservationsDefault = this.checkedDefault;
    roleToInsert.permissions = this.permissionsSelected;

    const roleToBackend = {
      name: roleToInsert.name,
      reservationsDefault: roleToInsert.reservationsDefault,
      permissions: roleToInsert.permissions.map((pm) => ({
        permission: pm.name,
        type: pm.type,
      })),
    };

    this.userService.createNewRole(roleToBackend).subscribe({
      next: (role) => {
        this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
        this.messageService.add({
          key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
          severity: 'success',
          summary: this.translateService.instant('Success'),
          detail: this.translateService.instant('Role created successfully'),
        });
        this.loading = false;
        this.disabledSave = false;
        this.submitted = false;
        this.saveNewRole.emit(role.id);
      },
      error: () => {
        this.loading = false;
        this.disabledSave = false;
        this.submitted = false;

        /**
         * Timeout is necessary as errors are wiped out
         * (see https://github.com/angular/angular/issues/19170)
         */
        setTimeout(() => {
          this.roleFormGroup.get('name')?.markAsDirty();
          this.roleFormGroup.get('name')?.markAsTouched();
          this.roleFormGroup.get('name')?.setErrors({ duplicate: true });
        }, 1);
      },
    });
  }

  checkAllSelected(event): void {
    this.permissionsChk = [];
    this.permissions.forEach((permission) => {
      this.permissionsChk.push({
        id: permission.id,
        name: permission.name,
        checked: event.checked,
        data: permission,
        disabled: false,
      });
    });
    this.setSelectedPermissions();
  }

  changeAllSelected(): void {
    this.allSelected = false;
    this.setSelectedPermissions();
  }

  setSelectedPermissions(): void {
    this.permissionsSelected = [];
    this.permissionsChk.forEach((permissionChk) => {
      if (permissionChk.checked) {
        this.permissionsSelected.push(permissionChk.data);
      }
    });
  }

  get f() {
    return this.roleFormGroup.controls;
  }
}
