/**
 * This guard is almost the same guard provided in
 * Community-provided sample implementation
 * of angular-oauth2-oidc implicit flow
 * https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/tree/implicit-flow
 *
 * Minor changes have been made to the previous open-source implementation
 */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth/auth.service';

import { ConditionalGuard } from './conditional.guard';

/**
 * Check if a protected URL can be accessed.
 * If not, triggers the login process to obtain access_tokens for user
 */
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private isAuthenticated = false;
  private routeI = null;
  private stateI = null;

  constructor(private authService: AuthService, private conditionalGuard: ConditionalGuard) {
    this.authService.isAuthenticated$.subscribe((i) => {
      this.isAuthenticated = i;
      if (this.isAuthenticated) {
        this.conditionalGuard.canActivate(this.routeI, this.stateI);
      }
    });
  }

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean> {
    this.routeI = _route;
    this.stateI = _state;
    return this.authService.isDoneLoading$
      .pipe(filter((isDone) => isDone))
      .pipe(tap(() => this.isAuthenticated || this.authService.login(_state.url)))
      .pipe(map(() => this.isAuthenticated));
  }
}
