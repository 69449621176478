import { Injectable } from '@angular/core';
import { GlobalService } from '../common/global.service';
import { RoleBuilding, RolePermissionBuilding } from './../../models/auth/Role.class';
import { UserService } from './../backend/user.service';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  constructor(private userService: UserService, private globalService: GlobalService) {}

  /**
   *
   * @param userId
   * @param roles
   */
  updateUserBuildings(userId: number, roles: RolePermissionBuilding[]): void {
    this.userService.modifyRolesToUser(userId, this.buildRoleList(roles)).subscribe(() => {
      this.globalService.printMessage('User modified successfully');
    });
  }

  buildRoleList(rolePermissionBuilding: RolePermissionBuilding[]): RoleBuilding[] {
    const roleBuilding = [];
    rolePermissionBuilding?.forEach((element) => {
      roleBuilding.push({
        role: element.role.id,
        building: element.building,
      });
    });
    return roleBuilding;
  }
}
