import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { from, Observable, of, Subject, zip } from 'rxjs';
import { finalize, groupBy, mergeMap, takeUntil, toArray } from 'rxjs/operators';
import { User } from 'src/app/models/auth/User.class';
import { Area } from 'src/app/models/esite/Area.class';
import { Space } from 'src/app/models/esite/Space.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { ReservationStatus } from 'src/app/models/reservation/ReservationStatus.enum';
import { SvgmapData } from 'src/app/models/svgmap/SvgmapData.class';
import { SvgmapLegendItem } from 'src/app/models/svgmap/SvgmapLegendItem.class';
import { SvgmapPoint } from 'src/app/models/svgmap/SvgmapPoint.class';
import { SvgmapPointReservation } from 'src/app/models/svgmap/SvgmapPointReservation.class';
import { SvgmapPolygon } from 'src/app/models/svgmap/SvgmapPolygon.class';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { SpaceConfigurationService } from 'src/app/services/pages/space-configuration.service';
import * as SvgPanZoom from 'svg-pan-zoom';

import { SpaceFormComponent } from '../../pages/configuration/space-configuration/space-form/space-form.component';
import { SpaceInfoComponent } from '../../pages/configuration/space-configuration/space-info/space-info.component';
import { SpaceNewSpaceComponent } from '../../pages/configuration/space-configuration/space-new-space/space-new-space.component';
import { Permission } from './../../../models/auth/Role.class';
import { UserPermissions } from './../../../models/auth/UserPermissions.enum';
import { ReservationOwner } from './../../../models/reservation/ReservationOwner.class';
import { ReservationService } from './../../../services/backend/reservation.service';
import { DateService } from './../../../services/common/date.service';

@Component({
  selector: 'app-svg-map',
  templateUrl: './svg-map.component.html',
  styleUrls: ['./svg-map.component.scss'],
})
export class SvgMapComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() svgMapProperties;
  @Input() svgPointEsiteInfoModified: SvgmapPoint;
  @Input() svgPointReservationInfoModified: ReservationSpace[];
  @Input() svgPolygonModified: SvgmapPolygon;
  @Input() newFloor = false;
  @Input() inputData: SvgmapData;
  @Input() legendItems: SvgmapLegendItem[] = [];
  @Input() isDrawingMode = false;
  @Input() isClickMode = false;
  @Input() reservationMap = false;
  @Input() isDeletingAreaMode = false;
  @Input() isDeletingSiteMode = false;
  @Input() isSitesMap = false;
  @Input() realtimeReservation = false;
  @Input() reservationGroupMode = false;
  @Input() colorDrawing = '#525252';
  @Input() polygonsEvent: Observable<any[]>;
  @Input() floor: Floor;
  @Input() area: Area;
  @Input() filterselected = 'reservable'; // 'reservable', 'permission', 'approval', 'approvalUsers', 'rgroup'
  @Output() newPolygonDrawn: EventEmitter<any> = new EventEmitter();
  @Output() polygonClicked: EventEmitter<any> = new EventEmitter();
  @Output() pointClicked: EventEmitter<any> = new EventEmitter();
  @Output() positionClicked: EventEmitter<any> = new EventEmitter();
  @Output() newFloorProperties: EventEmitter<any> = new EventEmitter();

  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;

  loading = false;
  data = new SvgmapData();
  svgId;
  tooltip;
  svg;
  svgPanZoom;
  cursor = 'default';
  zoomActualSqrt = Math.sqrt(4.2087542087542085);
  zoomActual = 4.2087542087542085;
  displaceX;
  displaceY;
  mouseX;
  mouseY;
  polygonSize = 2;
  pointSize = 3;
  pointRingSize = 4;
  lineSize = 3;
  offsetValue = 10;
  newPoints = [];
  newLines = [];
  elementsWithTriggers: HTMLCollectionOf<Element>;
  offsetFormGroup: UntypedFormGroup;
  mapImage;
  viewbox = '-263.84505 -3738.3491 6623 4678';
  scalatedPolygons = [];
  canWriteReservationsSpaces = false;
  canWriteEsiteSpaces = false;
  canReadReservation = false;
  isPolygon = false;
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  hasEsiteDevicesModule = false;
  tooltipData: SvgmapPoint[] | SvgmapPolygon[];
  isTooltipVisible = false;
  openedTooltip: string | null = null;
  private userLogged: User;
  private permissions: Permission[] = [];
  private WRITE = 'WRITE';
  private activatedRouteUrl = 'realtime';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private spaceConfigurationService: SpaceConfigurationService,
    private habitatService: HabitatService,
    private reservationService: ReservationService,
    private dateService: DateService,
    private globalService: GlobalService,
    private activatedRoute: ActivatedRoute
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasReservationsModule = this.globalService.getReservationModule();
    this.hasEsiteDevicesModule = this.globalService.getEsiteDevice();
    this.hasConteoModule = this.globalService.getConteoModule();

    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
    this.ckeckPermissionsUser(this.permissions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.pointSize = this.realtimeReservation ? 2 : 4;
    if (changes.inputData) {
      this.data = new SvgmapData();
      const currentData = changes.inputData.currentValue;
      this.scalatedPolygons = [];
      if (currentData) {
        this.loading = false;
        this.data = currentData;
        this.resourcesOnPoint();
        this.scalePolygons(this.data.polygons);
        if (this.data.img instanceof Blob) {
          this.createImageFromBlob(this.data.img);
        } else {
          this.mapImage = this.data.img;
          this.getImgSize(this.mapImage);
        }
        if (!this.svgPanZoom) {
          this.initZoomPan();
        }
      } else {
        this.loading = true;
        this.data = new SvgmapData();
      }
    }
    if (changes.isDrawingMode) {
      if (!changes.isDrawingMode.currentValue) {
        this.cancelDraw();
      }
    }
    if (changes.floor || changes.area) {
      this.hideTooltip();
    }
    if (changes.svgPointEsiteInfoModified) {
      if (changes.svgPointEsiteInfoModified.currentValue) {
        this.updatePointEsiteInfoInMap(changes.svgPointEsiteInfoModified.currentValue);
      }
    }
    if (changes.svgPolygonModified) {
      if (changes.svgPolygonModified.currentValue) {
        this.updatePolygonInMap(changes.svgPolygonModified.currentValue);
      }
    }
    if (changes.svgPointReservationInfoModified) {
      if (changes.svgPointReservationInfoModified.currentValue) {
        changes.svgPointReservationInfoModified.currentValue.forEach((space: ReservationSpace) => {
          this.updatePointReservationInfoInMap(space);
        });
      }
    }
  }

  ngOnInit(): void {
    this.globalService.userLogged$.subscribe((user: User) => {
      if (user) {
        this.userLogged = user;
        this.permissions = this.userLogged?.permissions;
        this.ckeckPermissionsUser(this.permissions);
      }
    });

    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
    });

    this.svgId = this.svgMapProperties.svgid;
    this.buildOffsetForm();
    this?.polygonsEvent?.subscribe((polygons) => {
      this.scalePolygons(polygons);
    });

    this.activatedRoute.url.subscribe((url) => {
      if (url?.length > 0) {
        this.activatedRouteUrl = url[0]?.path;
      }
    });
  }

  ngAfterViewInit(): void {
    if (!this.svgPanZoom) {
      this.initZoomPan();
    }
  }

  ckeckPermissionsUser(permissions: Permission[]): void {
    this.canWriteEsiteSpaces =
      permissions?.find((item) => item.name === UserPermissions.Spaces && item.type === this.WRITE) !== undefined;
    if (this.hasReservationsModule) {
      this.canReadReservation =
        permissions?.find((item) => item.name === UserPermissions.OwnReservations) !== undefined;
      this.canWriteReservationsSpaces =
        permissions?.find((item) => item.name === UserPermissions.ReservableSpaces && item.type === this.WRITE) !==
        undefined;
    } else {
      this.canWriteReservationsSpaces = false;
    }
  }

  resourcesOnPoint(): void {
    this.data.pointsXY = new Map();
    const pointGroup = [];
    from(this.data.points.filter((item) => item).filter((item) => item.positionX && item.positionY))
      .pipe(
        groupBy((point) => point.positionX + '#' + point.positionY),
        mergeMap((group) => zip(of(group.key), group.pipe(toArray()))),
        finalize(() => {
          pointGroup.forEach((pg) => {
            this.data.pointsXY.set(pg[0], pg[1]);
          });
        })
      )
      .subscribe((resp) => pointGroup.push(resp));
  }

  buildOffsetForm(): void {
    this.offsetFormGroup = this.formBuilder.group({
      offsetX: [this.svgMapProperties.offsetX, [Validators.required]],
      offsetY: [this.svgMapProperties.offsetY, [Validators.required]],
      scale: [this.svgMapProperties.scale, [Validators.required]],
    });
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  setOffsetX(): void {
    this.svgMapProperties.offsetX = this.offsetFormGroup.value.offsetX;
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  setOffsetY(): void {
    this.svgMapProperties.offsetY = this.offsetFormGroup.value.offsetY;
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  setScale(): void {
    this.svgMapProperties.scale = this.offsetFormGroup.value.scale;
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  offsetRight(): void {
    this.svgMapProperties.offsetX += this.offsetValue;
    this.offsetFormGroup.patchValue({
      offsetX: Math.round(this.svgMapProperties.offsetX * 1000) / 1000,
    });
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  offsetLeft(): void {
    this.svgMapProperties.offsetX -= this.offsetValue;
    this.offsetFormGroup.patchValue({
      offsetX: Math.round(this.svgMapProperties.offsetX * 1000) / 1000,
    });
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  offsetUp(): void {
    this.svgMapProperties.offsetY += this.offsetValue;
    this.offsetFormGroup.patchValue({
      offsetY: Math.round(this.svgMapProperties.offsetY * 1000) / 1000,
    });
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  offsetDown(): void {
    this.svgMapProperties.offsetY -= this.offsetValue;
    this.offsetFormGroup.patchValue({
      offsetY: Math.round(this.svgMapProperties.offsetY * 1000) / 1000,
    });
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  mapScaleUp(): void {
    this.svgMapProperties.scale += this.offsetValue;
    this.offsetFormGroup.patchValue({
      scale: Math.round(this.svgMapProperties.scale * 1000) / 1000,
    });
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  mapScaleDown(): void {
    this.svgMapProperties.scale -= this.offsetValue;
    this.offsetFormGroup.patchValue({
      scale: Math.round(this.svgMapProperties.scale * 1000) / 1000,
    });
    this.newFloorProperties.emit(this.offsetFormGroup.value);
  }

  updatePointEsiteInfoInMap(point: SvgmapPoint | SvgmapPointReservation): void {
    const index = this.data.points.findIndex((el) => el.code === point.code);
    if (index !== -1) {
      this.data.points[index].statusName = point.statusName;
      this.data.points[index].color = point.color;
      this.data.points[index].label3 = point.label3;
    }
  }

  updatePolygonInMap(polygon: SvgmapPolygon): void {
    const index = this.scalatedPolygons.findIndex((el) => el.code === polygon.code);
    if (index !== -1) {
      this.scalatedPolygons[index].occupation = polygon.occupation;
    }
  }

  updatePointReservationInfoInMap(reservationSpace: ReservationSpace): void {
    const index = this.data.points.findIndex((el) => el.name === reservationSpace.code);
    if (index !== -1) {
      this.data.points[index].reservationStatus = reservationSpace.status;
      this.data.points[index].label4 = reservationSpace.reservationOwner;
      this.data.points[index].label5 = reservationSpace.reservationDateString;
    }
  }

  myTrackByFunction(index, item) {
    return item.key;
  }

  createImageFromBlob(image: Blob): void {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        this.mapImage = reader.result;
        this.getImgSize(this.mapImage);
      },
      false
    );
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  scalePolygons(polygons): void {
    this.scalatedPolygons = [];
    polygons.forEach((polygon) => {
      if (polygon.position) {
        const scalePoly = [];
        const coordenatesArray = polygon.position.split(' ');
        coordenatesArray.forEach((point) => {
          const coordenate = point.split(',');
          const scaleCoordenates = [];
          coordenate.forEach((coor) => {
            const scaleCoor = Number(coor) * this.svgMapProperties.scale;
            coor = scaleCoor.toString();
            scaleCoordenates.push(coor);
          });
          scalePoly.push(scaleCoordenates.map((x) => x).join(','));
        });
        const newPolygon = new SvgmapPolygon({});
        newPolygon.name = polygon.name;
        newPolygon.code = polygon.code;
        newPolygon.position = scalePoly.map((x) => x).join(' ');
        newPolygon.typeName = polygon.typeName;
        newPolygon.color = polygon.color;
        newPolygon.building = polygon.building;
        newPolygon.floor = polygon.floor;
        newPolygon.occupation = polygon.occupation;
        this.scalatedPolygons.push(newPolygon);
      }
    });
  }

  setViewbox(): void {
    this.viewbox =
      this.svgMapProperties.offsetX +
      ' ' +
      -(this.svgMapProperties.height + this.svgMapProperties.offsetY) +
      ' ' +
      this.svgMapProperties.width +
      ' ' +
      this.svgMapProperties.height;
  }

  getImgSize(imgSrc): void {
    const newImg = new Image();
    newImg.onload = () => {
      this.svgMapProperties.height = newImg.height;
      this.svgMapProperties.width = newImg.width;
      // this.setViewbox();
    };
    newImg.src = imgSrc; // this must be done AFTER setting onload
  }

  @HostListener('mousemove', ['$event']) mousemove(event): void {
    if (this.svg) {
      const CTM = this.svg.getScreenCTM();
      this.mouseX = ((event.clientX - CTM.e) / CTM.a - this.displaceX) / this.zoomActual;
      this.mouseY = ((event.clientY - CTM.f) / CTM.d - this.displaceY) / this.zoomActual;
    }
  }

  initZoomPan(): void {
    this.svg = document.getElementById('svg' + this.svgId);
    if (this.svg && !this.svgPanZoom) {
      this.svgPanZoom = SvgPanZoom('#svg' + this.svgId, {
        viewportSelector: '.svg-pan-zoom_viewport',
        zoomEnabled: true,
        controlIconsEnabled: true,
        zoomScaleSensitivity: 0.3,
        dblClickZoomEnabled: false,
        contain: true,
        // maxZoom: 100,
        minZoom: 0,
        onZoom: () => this.setCurrentPanZoom(),
        onPan: () => this.setCurrentPanZoom(),
        eventsListenerElement: document.querySelector('#svg' + this.svgId + ' .svg-pan-zoom_viewport'),
      });
      this.svgPanZoom.zoom(this.svgMapProperties.initialZoom);
      this.setCurrentPanZoom();
    }
  }

  polygonclick(polygon): void {
    this.polygonClicked.emit(polygon);
  }

  setCurrentPanZoom(): void {
    if (this.svgPanZoom) {
      this.displaceX = Number(this.svgPanZoom.getPan().x);
      this.displaceY = Number(this.svgPanZoom.getPan().y);
      this.zoomActual = Number(this.svgPanZoom.getSizes().realZoom);
      this.zoomActualSqrt = Math.sqrt(this.zoomActual);
    }
  }

  hideTooltip(): void {
    this.isTooltipVisible = false;
    this.openedTooltip = null;
    if (this.tooltip) {
      this.tooltip.setAttributeNS(null, 'visibility', 'hidden');
    }
  }

  clicked(evt): void {
    const CTM = this.svg.getScreenCTM();
    if (this.svg && this.isDrawingMode) {
      this.newPoints.push({
        x: ((evt.clientX - CTM.e) / CTM.a - this.displaceX) / this.zoomActual,
        y: ((evt.clientY - CTM.f) / CTM.d - this.displaceY) / this.zoomActual,
      });
      const lenghtPoints = this.newPoints.length;
      if (lenghtPoints > 1) {
        this.newLines.push({
          x1: this.newPoints[lenghtPoints - 1].x,
          y1: this.newPoints[lenghtPoints - 1].y,
          x2: this.newPoints[lenghtPoints - 2].x,
          y2: this.newPoints[lenghtPoints - 2].y,
        });
      }
    } else if (this.svg && this.isClickMode) {
      const newPoint = {
        x: ((evt.clientX - CTM.e) / CTM.a - this.displaceX) / this.zoomActual / this.svgMapProperties.scale,
        y: -(((evt.clientY - CTM.f) / CTM.d - this.displaceY) / this.zoomActual) / this.svgMapProperties.scale,
      };
      this.positionClicked.next(newPoint);
    }
  }

  endDraw(): void {
    if (this.newPoints.length > 2) {
      let polygon = '';
      for (const newPoint of this.newPoints) {
        polygon += newPoint.x / this.svgMapProperties.scale + ',' + newPoint.y / this.svgMapProperties.scale + ' ';
      }

      polygon = polygon.substring(0, polygon.length - 1);
      this.newPolygonDrawn.emit({ polygon: polygon, reservation: this.reservationGroupMode });
      this.cancelDraw();
    }
  }

  cancelDraw(): void {
    this.newPoints = [];
    this.newLines = [];
  }

  pointClick(points: SvgmapPoint[] | SvgmapPointReservation[]): void {
    points.forEach((point) => {
      if (point.reservationStatus === ReservationStatus.Reserved) {
        this.buildSpaceInfo(point);
      }
    });

    if (!this.isDrawingMode && this.isDeletingSiteMode) {
      this.pointClicked.emit(points);
    }
  }

  buildSpaceInfo(point: SvgmapPoint | SvgmapPointReservation): void {
    this.reservationService.getRservationSpaceInfo(point.code).subscribe((space: ReservationSpace) => {
      this.reservationService.getSpaceReservation(space.id, ReservationStatus.InProgress).subscribe((reservations) => {
        const now = new Date(this.dateService.getUTCDate(new Date())).getTime();
        const reservation = reservations.find(
          (item) => new Date(item.startDate).getTime() <= now && new Date(item.endDate).getTime() >= now
        );
        this.getCompleteNameOwner(reservation.owner, point);
      });
    });
  }

  getCompleteNameOwner(owner: ReservationOwner, point: SvgmapPoint | SvgmapPointReservation): void {
    if (owner && owner instanceof Object) {
      this.reservationService.getReservationUser(owner.employeeNumber).subscribe((item) => {
        owner.name = item?.name?.concat(' ').concat(item?.surname);
        point.label4 = owner.name;
      });
    }
  }

  openConfig(point: SvgmapPoint | SvgmapPointReservation): void {
    const dialogRef = this.dialog.open(SpaceFormComponent, {
      data: { point: point, canWriteReservationsSpaces: this.canWriteReservationsSpaces },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: { isCancelled: boolean; floor: any }) => {
      if (!result.isCancelled) {
        this.habitatService.getFloor(result.floor || this.floor.id).subscribe((floor) => {
          this.spaceConfigurationService.setCurrentFloor(floor);
        });
      }
    });
  }

  openInfo(point: SvgmapPoint | SvgmapPointReservation): void {
    const dialogRef = this.dialog.open(SpaceInfoComponent, {
      data: { point: point },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe();
  }

  editSpace(point: SvgmapPoint | SvgmapPointReservation): void {
    const dialogRef = this.dialog.open(SpaceNewSpaceComponent, {
      data: { space: point, canWriteEsiteSpaces: this.canWriteEsiteSpaces },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result: { isCancelled: boolean; floor?: any; space?: Space }) => {
      this.hideTooltip();
      if (!result.isCancelled) {
        this.spaceConfigurationService.setEditedSpace(result.space);
      }
    });
  }

  onShownTooltip(points: SvgmapPolygon[] | SvgmapPoint[] | SvgmapPointReservation[], openedTooltip: string): void {
    /**
     * To prevent opening another tooltip before closing the preious one
     */
    if (this.openedTooltip && this.openedTooltip !== openedTooltip) {
      return;
    }
    /**
     * User tries to open another tooltip before closing the previous one. Close it
     */
    if (this.isTooltipVisible) {
      this.hideTooltip();
      return;
    }
    this.isTooltipVisible = true;
    this.openedTooltip = openedTooltip;
    this.tooltipData = points;
    this.isPolygon = points[0] instanceof SvgmapPolygon;

    if (
      this.tooltipData instanceof Array &&
      this.tooltipData.length > 0 &&
      this.tooltipData[0] instanceof SvgmapPoint &&
      this.tooltipData[0].buildingId
    ) {
      this.ckeckPermissionsUser(
        this.globalService.getPermissionsBuilding(
          this.tooltipData[0].buildingId,
          this.userLogged?.permissionBuilding,
          this.permissions
        )
      );
    }
  }

  onHiddenPopper(): void {
    this.isTooltipVisible = false;
    this.openedTooltip = null;
  }

  getTooltipStyles(points: SvgmapPoint[] | SvgmapPointReservation[]) {
    points = points instanceof Array ? points : [points];
    const point = points[0];
    const lengthRepeted = [...new Set(points.map((p) => p.color))].length;
    if (lengthRepeted > 1) {
      return { fill: this.activatedRouteUrl === 'realtime' ? '#8e2ea0' : '#FFD100' };
    } else {
      return { fill: point?.color };
    }
  }

  getTooltipRealtimeReservationStyles(points: SvgmapPoint[] | SvgmapPointReservation[]) {
    points = points.filter((p) => p.reservationStatus !== undefined);
    points = points instanceof Array ? points : [points];
    const point = points[0];
    const lengthRepeted = [...new Set(points.map((p) => p.reservationStatus))].length;
    if (lengthRepeted > 1) {
      return { fill: '#8e2ea0' };
    } else {
      return { fill: point?.reservationStatus === 'Reserved' ? '#ffa900' : '#8f9bb3' };
    }
  }

  getTooltipPoint3Styles(points: SvgmapPoint[] | SvgmapPointReservation[]) {
    points = points.filter((p) => p.reservationStatus !== undefined);
    points = points instanceof Array ? points : [points];
    const point = points[0];
    const lengthRepeted = [...new Set(points.map((p) => p.reservationStatus))].length;
    if (lengthRepeted > 1) {
      return { fill: !this.realtimeReservation ? '#0075aa' : '#f29dff' };
    } else {
      return {
        fill: !this.realtimeReservation ? '#0075aa' : point?.reservationStatus === 'Reserved' ? '#ffa900' : '#8f9bb3',
      };
    }
  }
}
