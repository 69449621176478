import { Component, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { Incident } from 'src/app/models/habitat/Incident.class';
import { HabitatService } from 'src/app/services/backend/habitat.service';

@Component({
  selector: 'app-createincident',
  templateUrl: './createincident.component.html',
  styleUrls: ['./createincident.component.scss'],
})
export class CreateincidentComponent {
  incidentFormGroup: UntypedFormGroup;
  submitted = false;
  title = '';
  categories = [];
  description = '';
  selectedCategory: string;
  dialogRef: MatDialogRef<any>;
  suscriptions: Subscription[] = [];
  loading = false;
  sendingError = false;

  constructor(
    private dialog: MatDialog,
    private habitatService: HabitatService,
    private formBuilder: UntypedFormBuilder
  ) {}

  open(incidentForm: TemplateRef<any>): void {
    this.incidentFormGroup = this.formBuilder.group({
      title: ['', Validators.required],
      category: ['', Validators.required],
      description: ['', Validators.required],
    });
    this.suscriptions.push(
      this.habitatService.getIncidentsCategories().subscribe((response) => {
        this.categories = response;
      })
    );
    this.dialogRef = this.dialog.open(incidentForm, { disableClose: true });
  }

  close(): void {
    this.loading = false;
    this.submitted = false;
    this.dialogRef.close();
  }

  confirmRequest(): void {
    this.submitted = true;
    if (this.incidentFormGroup.invalid) {
      return;
    }
    this.loading = true;
    const title = this.incidentFormGroup.value.title;
    const descr = this.incidentFormGroup.value.description;
    const categoryId = this.incidentFormGroup.value.category;
    const newIncident = new Incident(title, descr, categoryId);
    this.habitatService.insertIncident(newIncident.toBackenFormat()).subscribe({
      next: () => {
        this.close();
      },
      error: () => {
        this.sendingError = true;
      },
    });
  }

  get f(): any {
    return this.incidentFormGroup.controls;
  }
}
