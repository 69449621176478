<div class="mainContainer">
  <div class="firstSelectDate">
    <mat-form-field>
      <input
        matInput
        [ngxMatDatetimePicker]="picker"
        placeholder="{{ 'Choose a init date' | translate }}"
        (ngModelChange)="startDateChanged($event)"
        (click)="picker.open()"
        [formControl]="dateControlStart"
        [min]="minDate"
        [max]="maxDateForStartControl"
        type="time-24"
        readonly
        required
        class="date-input py-1"
      />
      <mat-datepicker-toggle id="calendarStyle" matSuffix [for]="picker"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #picker
        [showSpinners]="showSpinners"
        [showSeconds]="showSeconds"
        [stepHour]="stepHour"
        [stepMinute]="stepMinute"
        [stepSecond]="stepSecond"
        [touchUi]="touchUi"
        [color]="color"
        [enableMeridian]="false"
      >
      </ngx-mat-datetime-picker>
    </mat-form-field>
  </div>
  <div>
    <mat-form-field>
      <input
        matInput
        [ngxMatDatetimePicker]="picker2"
        placeholder="{{ 'Choose a finish date' | translate }}"
        (ngModelChange)="endDateChanged($event)"
        (click)="picker2.open()"
        [formControl]="dateControlEnd"
        [min]="minDateForEndControl"
        [max]="maxDate"
        type="time-24"
        readonly
        required
        class="date-input py-1"
      />
      <mat-datepicker-toggle id="calendarStyle" matSuffix [for]="picker2"></mat-datepicker-toggle>
      <ngx-mat-datetime-picker
        #picker2
        [showSpinners]="showSpinners"
        [showSeconds]="showSeconds"
        [stepHour]="stepHour"
        [stepMinute]="stepMinute"
        [stepSecond]="stepSecond"
        [touchUi]="touchUi"
        [color]="color"
        [enableMeridian]="false"
      >
      </ngx-mat-datetime-picker>
    </mat-form-field>
  </div>
</div>
