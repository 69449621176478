import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BuildingFormComponent } from '../../pages/configuration/space-configuration/building-form/building-form.component';
import { FloorFormComponent } from '../../pages/configuration/space-configuration/floor-form/floor-form.component';

@Component({
  selector: 'app-nodata-card',
  templateUrl: './nodata-card.component.html',
  styles: [],
})
export class NodataCardComponent implements OnInit {
  @Input() noBuildings = false;
  @Input() noFloors = false;
  nodata = false;
  buttonTitle = '';
  messageOptions = {
    empty: { button: '', title: '', subtitle: '', modal: null },
    building: {
      button: 'New space',
      title: 'Aún no hay níngún espacio creado',
      // subtitle: '¿Quiere crear un espacio ahora?',
      subtitle:
        'Acceda al módulo de configurar espacios para crear un espacio o solicite ayuda al administrador del sistema.',
      modal: BuildingFormComponent,
    },
    floor: {
      button: 'New floor',
      title: 'Aún no hay nínguna planta creada',
      // subtitle: '¿Quiere crear una planta ahora?',
      subtitle:
        'Acceda al módulo de configurar espacios para crear una planta o solicite ayuda al administrador del sistema.',
      modal: FloorFormComponent,
    },
  };
  optionSelected = this.messageOptions.empty;

  constructor(private dialog: MatDialog) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.noBuildings || changes.noFloors) {
      this.initData();
    }
  }

  ngOnInit(): void {
    this.initData();
  }

  initData() {
    this.nodata = this.noBuildings || this.noFloors;
    if (this.noBuildings) {
      this.buttonTitle = 'New space';
      this.optionSelected = this.messageOptions.building;
    } else {
      this.buttonTitle = 'New floor';
      this.optionSelected = this.messageOptions.floor;
    }
  }

  open(template): void {
    this.dialog.open(template, { disableClose: true });
  }
}
