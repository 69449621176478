<div #mydiv>
  <div class="modalCard">
    <div class="marginBodyCard">
      <div class="titleN3 text-primary modalTitle"> {{ modalTitle | translate }}</div>
      <div
        *ngIf="loading"
        style="
          text-align: -webkit-center;
          text-align: -moz-center;
          min-height: 400px;
          display: flex;
          align-items: center;
          justify-content: space-around;
          min-width: 650px;
        "
      >
        <app-loading style="width: 100%"></app-loading>
      </div>
      <form
        [hidden]="loading"
        [formGroup]="filterFormGroup"
        style="margin-left: 0px"
        (ngSubmit)="createContinuousReservation()"
      >
        <div class="titleN31" style="margin-bottom: 15px">
          {{ 'Location' | translate }}
        </div>
        <div class="row">
          <div style="position: relative" class="col-4">
            <mat-form-field>
              <mat-label>{{ 'City' | translate }}</mat-label>
              <mat-select
                id="city"
                formControlName="city"
                class="input-form-special"
                [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                (selectionChange)="changeValues($event)"
                [(ngModel)]="selectedCity"
                required
              >
                <mat-option *ngFor="let city of cities" [value]="city">
                  {{ city.name | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="filterFormGroup.get('city')?.invalid || (submitted && f.city.errors)">{{
                'City is required' | translate
              }}</mat-error>
            </mat-form-field>
            <span
              *ngIf="filterFormGroup.value.city"
              [title]="'Clear selection' | translate"
              class="close-circle-outline bi bi-x-circle"
              style="
                position: absolute;
                margin-left: -35px;
                z-index: 20000;
                margin-top: 15px;
                cursor: pointer;
                color: grey;
              "
              (click)="clearField('city')"
            ></span>
          </div>
          <div style="position: relative" class="col-4">
            <mat-form-field>
              <mat-label>{{ 'Building' | translate }}</mat-label>
              <mat-select
                id="building"
                formControlName="building"
                class="input-form-special"
                [ngClass]="{ 'is-invalid': submitted && f.building.errors }"
                (selectionChange)="changeValues($event)"
                [(ngModel)]="selectedBuilding"
                required
              >
                <mat-option *ngFor="let building of buildings" [value]="building">
                  {{ building.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="filterFormGroup.get('building')?.invalid || (submitted && f.building.errors)">{{
                'Building is required' | translate
              }}</mat-error>
            </mat-form-field>
            <span
              *ngIf="filterFormGroup.value.building"
              [title]="'Clear selection' | translate"
              class="close-circle-outline bi bi-x-circle"
              style="
                position: absolute;
                margin-left: -35px;
                z-index: 20000;
                margin-top: 15px;
                cursor: pointer;
                color: grey;
              "
              (click)="clearField('building')"
            ></span>
          </div>
          <div style="position: relative" class="col-4">
            <mat-form-field style="padding-left: 0; vertical-align: bottom">
              <mat-label>{{ 'Floor' | translate }}</mat-label>
              <mat-select
                id="floor"
                formControlName="floor"
                class="input-form-special"
                [ngClass]="{ 'is-invalid': submitted && f.floor.errors }"
                (selectionChange)="changeValues($event)"
                [(ngModel)]="selectedFloor"
                required
              >
                <mat-option *ngFor="let floor of floors" [value]="floor">
                  {{ floor.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="filterFormGroup.get('floor')?.invalid || (submitted && f.floor.errors?.required)">{{
                'Floor is required' | translate
              }}</mat-error>
            </mat-form-field>
            <span
              *ngIf="filterFormGroup.value.floor"
              [title]="'Clear selection' | translate"
              class="close-circle-outline bi bi-x-circle"
              style="
                position: absolute;
                margin-left: -35px;
                z-index: 20000;
                margin-top: 15px;
                cursor: pointer;
                color: grey;
              "
              (click)="clearField('floor')"
            ></span>
          </div>
        </div>
        <div class="row">
          <div style="position: relative" class="col-4">
            <mat-form-field style="padding-left: 0; vertical-align: bottom">
              <mat-label>{{ 'Search Criteria' | translate }}</mat-label>
              <mat-select
                id="searchCriteria"
                formControlName="searchCriteria"
                class="input-form-special"
                [ngClass]="{ 'is-invalid': submitted && f.searchCriteria.errors }"
                (selectionChange)="changeValues($event)"
                [(ngModel)]="selectedSearchCriteria"
              >
                <mat-option *ngFor="let searchCriteria of searchCriterias" [value]="searchCriteria.id">
                  {{ searchCriteria.name | translate }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="filterFormGroup.get('searchCriteria')?.invalid || (submitted && f.searchCriteria.errors)"
                >{{ 'Search Criteria is required' | translate }}</mat-error
              >
            </mat-form-field>
            <span
              *ngIf="filterFormGroup.value.searchCriteria"
              [title]="'Clear selection' | translate"
              class="close-circle-outline bi bi-x-circle"
              style="
                position: absolute;
                margin-left: -36px;
                z-index: 20000;
                margin-top: 15px;
                cursor: pointer;
                color: grey;
              "
              (click)="clearField('searchCriteria')"
            ></span>
          </div>
          <div style="position: relative" class="col-4">
            <mat-form-field style="padding-left: 0; vertical-align: bottom">
              <mat-label>{{ 'Option' | translate }}</mat-label>
              <mat-select
                id="option"
                formControlName="option"
                class="input-form-special"
                [ngClass]="{ 'is-invalid': submitted && f.option.errors }"
                [(ngModel)]="selectedOption"
                ngxMatSelect
                #ms="ngxMatSelect"
                [searcher]="searcherOption"
                [displayMember]="'name'"
                [valueMember]="'id'"
                [useInfiniteScroll]="true"
                [source]="options"
                [lazyLoad]="true"
                searchBoxPlaceholder="{{ 'Search' | translate }}"
                emptyLabel="{{ 'No options found' | translate }}"
                noMoreResultLabel="{{ 'No more found' | translate }}"
                (selectionChange)="changeValues($event)"
                [hasSearchBox]="true"
              >
                <mat-option *ngFor="let option of ms.filteredSource" [value]="option">
                  {{ option.name | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="filterFormGroup.get('option')?.invalid || (submitted && f.option.errors)">{{
                'Option is required' | translate
              }}</mat-error>
            </mat-form-field>
            <span
              *ngIf="filterFormGroup.value.option"
              [title]="'Clear selection' | translate"
              class="close-circle-outline bi bi-x-circle"
              style="
                position: absolute;
                margin-left: -36px;
                z-index: 20000;
                margin-top: 15px;
                cursor: pointer;
                color: grey;
              "
              (click)="clearField('option')"
            ></span>
          </div>
          <div style="position: relative" class="col-4">
            <mat-form-field style="padding-left: 0; vertical-align: bottom">
              <mat-label>{{ 'Space' | translate }}</mat-label>
              <mat-select
                id="space"
                formControlName="space"
                class="input-form-special"
                [ngClass]="{ 'is-invalid': submitted && f.space.errors }"
                [(ngModel)]="selectedSpace"
                ngxMatSelect
                #mst="ngxMatSelect"
                [searcher]="searcherSpace"
                [displayMember]="'code'"
                [valueMember]="'id'"
                [useInfiniteScroll]="true"
                [source]="reservableSpaces"
                [lazyLoad]="true"
                searchBoxPlaceholder="{{ 'Search' | translate }}"
                emptyLabel="{{ 'No spaces found' | translate }}"
                noMoreResultLabel="{{ 'No more found' | translate }}"
                [hasSearchBox]="true"
              >
                <mat-option *ngFor="let space of mst.filteredSource" [value]="space.id">
                  {{ space.code | translate }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="filterFormGroup.get('space')?.invalid || (submitted && f.space.errors)">{{
                'Space is required' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="titleN31" style="margin-top: 15px; margin-bottom: 15px">
          {{ 'Date and duration of the reservation' | translate }}
        </div>

        <div class="row" style="margin-top: 30px">
          <div class="col-4">
            <mat-form-field appearance="">
              <mat-label>{{ 'Start date' | translate }}</mat-label>
              <input
                title="{{ 'Start date' | translate }}"
                id="start-date"
                class="p-1"
                matInput
                [matDatepicker]="pickerStart"
                [matDatepickerFilter]="weekendFilter"
                (click)="pickerStart.open()"
                formControlName="startDate"
                (dateChange)="startDateChanged($event)"
                [min]="isEditing ? false : minStartDate"
                [max]="filterFormGroup.value.endDate ? filterFormGroup.value.endDate : false"
                style="cursor: pointer"
                required
                readonly
              />

              <mat-datepicker #pickerStart></mat-datepicker>
              <span
                class="responsiveCalendar bi bi-calendar3"
                [ngStyle]="{
                  cursor: !isEditing ? 'pointer' : 'Unset',
                  color: !isEditing ? '#0075aa' : '#d4d4d4'
                }"
                (click)="!isEditing && pickerStart.open()"
              ></span>
              <mat-error *ngIf="filterFormGroup.get('startDate')?.invalid || (submitted && f.startDate.errors)">{{
                'Start date is required' | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-4">
            <mat-form-field appearance="">
              <mat-label>{{ 'End date' | translate }}</mat-label>
              <input
                title="{{ 'End date' | translate }}"
                id="end-date"
                class="p-1"
                matInput
                [matDatepicker]="pickerEnd"
                [matDatepickerFilter]="weekendFilter"
                (click)="pickerEnd.open()"
                formControlName="endDate"
                [required]="!filterFormGroup.value.disableEndDate"
                [disabled]="filterFormGroup.value.disableEndDate || !canChangeEndDate"
                [min]="minEndDate"
                style="cursor: pointer"
                readonly
              />

              <mat-datepicker #pickerEnd></mat-datepicker>
              <span
                class="responsiveCalendar bi bi-calendar3"
                [ngStyle]="{
                  cursor: !filterFormGroup.value.disableEndDate && canChangeEndDate ? 'pointer' : 'Unset',
                  color: !filterFormGroup.value.disableEndDate && canChangeEndDate ? '#0075aa' : '#d4d4d4'
                }"
                (click)="(!filterFormGroup.value.disableEndDate || canChangeEndDate) && pickerEnd.open()"
              ></span>

              <mat-error
                *ngIf="
                  (!filterFormGroup.value.disableEndDate && filterFormGroup.get('endDate')?.invalid) ||
                  (submitted && f.endDate.errors)
                "
                >{{ 'End date is required' | translate }}</mat-error>
            </mat-form-field>
          </div>
          <div class="col-4 alignSlideToggle">
            <mat-label class="hasValue">{{ 'Unset end date' | translate }}</mat-label>
            <mat-slide-toggle formControlName="disableEndDate" (toggleChange)="resetEndDate()"></mat-slide-toggle>
          </div>
        </div>
        <div class="col-4 alignSlideToggle" style="float: right; padding-right: 0px; padding-left: 20px">
          <mat-label class="hasValue">{{ 'All day' | translate }}</mat-label>
          <mat-slide-toggle formControlName="allDay" (change)="checkAllDayValidators()"></mat-slide-toggle>
        </div>
        <div class="row" style="margin-top: 30px">
          <div class="col-4">
            <mat-form-field appearance="">
              <mat-label class="hasValue">{{ 'Start time' | translate }}</mat-label>
              <input
                title="{{ 'Start time' | translate }}"
                id="start-time"
                class="p-1"
                matInput
                formControlName="startTime"
                [ngxTimepicker]="startTime"
                [format]="24"
                [value]="filterFormGroup.value.startTime"
                [max]="isEditing ? false : filterFormGroup.value.endTime ?  endStartTime : false"
                style="cursor: pointer"
                [required]="!filterFormGroup.value.allDay"
                readonly
                [disabled]="filterFormGroup.value.allDay || isEditing"
              />
              <ngx-material-timepicker
                #startTime
                [minutesGap]="isEditing ? false : 5"
                [cancelBtnTmpl]="cancelBtn"
                [confirmBtnTmpl]="confirmBtn"
                [format]="24"
                (timeSet)="timeSet('init', $event)"
              >
              </ngx-material-timepicker>

              <span
                class="responsiveClock bi bi-clock"
                [ngStyle]="{
                  cursor: filterFormGroup.value.allDay || isEditing ? 'Unset' : 'Pointer',
                  color: filterFormGroup.value.allDay || isEditing ? '#d4d4d4 ' : '#0075aa'
                }"
                (click)="!isEditing && !filterFormGroup.value.allDay && startTime.open()"
              ></span>
              <mat-error *ngIf="filterFormGroup.get('startTime')?.invalid || (submitted && f.startTime.errors)">{{
                'Start time is required' | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <div class="col-4">
            <mat-form-field appearance="">
              <mat-label class="hasValue">{{ 'End Time' | translate }}</mat-label>
              <input
                title="{{ 'End Time' | translate }}"
                id="end-time"
                class="p-1"
                matInput
                formControlName="endTime"
                [ngxTimepicker]="endTime"
                [min]="isEditing ? false : initEndTime"
                [format]="24"
                [value]="filterFormGroup.value.endTime"
                style="cursor: pointer"
                [required]="!filterFormGroup.value.allDay"
                readonly
                [disabled]="filterFormGroup.value.allDay || isEditing || !filterFormGroup.value.startTime"
              />
              <ngx-material-timepicker
                #endTime
                [minutesGap]="isEditing ? false : 5"
                [cancelBtnTmpl]="cancelBtn"
                [confirmBtnTmpl]="confirmBtn"
                [format]="24"
                (timeSet)="timeSet('end', $event)"
              >
              </ngx-material-timepicker>
              <span
                class="responsiveClock bi bi-clock"
                [ngStyle]="{
                  cursor:
                    filterFormGroup.value.allDay || isEditing || !filterFormGroup.value.startTime ? 'Unset' : 'Pointer',
                  color:
                    filterFormGroup.value.allDay || isEditing || !filterFormGroup.value.startTime
                      ? '#d4d4d4 '
                      : '#0075aa'
                }"
                (click)="
                  !isEditing && filterFormGroup.value.startTime && !filterFormGroup.value.allDay && endTime.open()
                "
              ></span>
              <mat-error *ngIf="filterFormGroup.get('endTime')?.invalid || (submitted && f.endTime.errors)">{{
                'End time is required' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="col-4 alignSlideToggle">
            <mat-label class="hasValue">{{ 'UTC' }}</mat-label>
            <mat-slide-toggle formControlName="utc" (change)="utcState($event)"></mat-slide-toggle>
          </div> -->
        </div>

        <div style="padding-top: 60px; display: flex; justify-content: space-evenly">
          <button mat-button class="modalCancel text-primary" (click)="close(true)" type="button">
            {{ 'Close' | translate }}
          </button>
          <button
            *ngIf="canWriteReservations"
            mat-button
            class="modalButton bg-primary"
            type="submit"
            [disabled]="submitted"
          >
            {{ 'Save' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #cancelBtn>
  <button class="modalButton text-primary w-100">{{ 'Cancel' | translate }}</button>
</ng-template>
<ng-template #confirmBtn>
  <button *ngIf="canWriteReservations" class="modalButton bg-primary w-100 ms-3">
    {{ 'Confirm' | translate }}
  </button>
</ng-template>
