<div *ngIf="havePermission">
  <mat-card>
    <mat-card-content id="card">
      <!-- Power BI -->
      <div *ngIf="selectedPageInforme">
        <div [ngStyle]="{ height: screenHeight - 150 + 'px' }" #embeddedReport></div>
      </div>
    </mat-card-content>
  </mat-card>
</div>
