import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NGXLogger } from 'ngx-logger';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AppConstants } from '../shared/AppConstants';

/**
 * Interceptor that, for all responses to the different habitat modules,
 * tries catch Error on read the response
 * */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private logger: NGXLogger,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (
      (request.url.includes(environment.habitat_backend_url) ||
        request.url.includes(environment.conteo_backend_url) ||
        request.url.includes(environment.esite_backend_url) ||
        request.url.includes(environment.reservations_backend_url) ||
        request.url.includes(environment.developer_server_json_url)) &&
      !request.url.includes('v1/reservableSpaces') && // No mostrar mensaje de error en reservableSpaces se gestiona en el componente
      !request.url.includes('v2/user/image')
    ) {
      return next.handle(request).pipe(
        tap((response) => {
          this.logger.info(request);

          if (response instanceof HttpResponse) {
            this.logger.warn(response);
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.logger.error(error);
          console.log(error);

          let errorMessage = null;
          if (!error) {
            errorMessage = 'An error has occurred, please try again. If it persists contact the administrator';
          } else {
            if (error.error?.reason?.reason) {
              errorMessage = error.error?.reason?.reason;
            } else if (error.error?.reason) {
              errorMessage = error.error?.reason;
            } else if (error.error?.message) {
              errorMessage = error.error?.message;
            } else {
              errorMessage =
                error.error instanceof String === true || typeof error.error === 'string'
                  ? error.error
                  : 'An error has occurred, please try again. If it persists contact the administrator';
            }
          }

          errorMessage = this.traduceErrorMessage(errorMessage);
          this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
          this.messageService.add({
            key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
            severity: 'error',
            summary: 'Error',
            detail: this.translateService.instant(errorMessage),
          });

          //return throwError(() => new Error(errorMessage));
          return throwError(errorMessage);
        })
      );
    } else {
      return next.handle(request);
    }
  }

  traduceErrorMessage(errorMessage: string): string {
    if (errorMessage.includes('Groups_name_unique')) {
      return 'Groups_name_unique';
    } else if (errorMessage.includes('Loops are not permited')) {
      return 'Loops are not permited';
    } else if (errorMessage.includes('Groups_code_unique')) {
      return 'Groups_code_unique';
    }
    return errorMessage;
  }
}
