import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuditPagesService {
  private auditFilterParameters: any = {};
  private isResultsTableLoading = false;
  auditFilterParameters$ = new BehaviorSubject<any>(this.auditFilterParameters);
  isResultsTableLoading$ = new BehaviorSubject<any>(this.isResultsTableLoading);

  setAudirFilterPArameters(auditFilterParameters: any): void {
    this.auditFilterParameters = auditFilterParameters;
    this.auditFilterParameters$.next(auditFilterParameters);
  }

  isReservationTableLoading(resultsTableLoading: any): void {
    this.isResultsTableLoading = resultsTableLoading;
    this.isResultsTableLoading$.next(resultsTableLoading);
  }
}
