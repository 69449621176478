import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import FileSaver from 'file-saver';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InfoModalComponent } from 'src/app/components/shared/info-modal/info-modal.component';
import { City } from 'src/app/models/habitat/City.class';
import { InfoModalData } from 'src/app/models/InfoModalData.class';
import { AuditService } from 'src/app/services/backend/audit.service';
import { DateService } from 'src/app/services/common/date.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { LanguageService } from 'src/app/services/common/language.service';
import { AuditPagesService } from 'src/app/services/pages/audit.service';

import { StorageVariables } from './../../../../../models/StorageVariables.enum';
import { AuditResultsDetailComponent } from './audit-results-detail/audit-results-detail.component';

@Component({
  selector: 'app-audit-results',
  templateUrl: './audit-results.component.html',
  styleUrls: ['./audit-results.component.scss'],
})
export class AuditResultsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;
  loading = false;
  displayedColumnsTable: string[] = [
    'user',
    'functionalityName',
    'entityName',
    'id',
    'objectName',
    'date',
    'actionAuditoria',
  ];
  dataSource = new MatTableDataSource([]);
  subcriptions: Subscription[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sortDataAuditoria', { static: false }) set content(sortData: MatSort) {
    // this.dataSource.sort = sortData;
  }

  auditData = [];
  INITIAL_PAGE = 1;
  INITIAL_PAGESIZE = 10;
  pageSize = this.INITIAL_PAGESIZE;
  pageIndex: number = this.INITIAL_PAGE;
  sortColumn: any;
  sortOrder: any;
  haveNextPage: boolean = null;
  initPaginatorText: number;
  endPaginatorText: number;
  totalAuditData: number;
  originFuntions = JSON.parse(sessionStorage.getItem(StorageVariables.FUNCTIONALITIES));
  filterparameters;
  fromDate: any;
  toDate: any;
  downloadingExcel = false;
  noData = false;
  changingPage = false;
  lang: string;

  constructor(
    private dialog: MatDialog,
    private auditBackendService: AuditService,
    private auditService: AuditPagesService,
    private globalService: GlobalService,
    private languageService: LanguageService,
    private dateService: DateService
  ) {
    this.languageService.curLanguage.subscribe((lan: any) => {
      this.lang = lan;
    });
  }

  ngOnInit(): void {
    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
    });
    this.auditService.auditFilterParameters$.subscribe((filterparameters) => {
      if (!this.changingPage) {
        this.pageIndex = this.INITIAL_PAGE;
        this.filterparameters = filterparameters;
        this.loadAuditoria();
      }
    });
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  loadAuditoria(): void {
    this.auditData = [];
    this.resultsLoading(true);
    this.noData = false;
    this.fromDate = null;
    this.toDate = null;
    if (this.filterparameters.dateRange) {
      const timeZone = new City('').timezone;
      const startMoment = new Date(this.filterparameters.startDateText).getTime();
      const endMoment = new Date(this.filterparameters.endDateText).getTime();
      this.fromDate = this.dateService.setTimeZone(startMoment, timeZone);
      this.toDate = this.dateService.setTimeZone(endMoment, timeZone);
    }

    this.auditBackendService
      .getAudit(
        this.pageIndex,
        this.pageSize,
        this.filterparameters.user,
        this.sortColumn,
        this.sortOrder,
        this.filterparameters.originFunction?.id,
        this.fromDate,
        this.toDate,
        this.filterparameters.lastValue,
        this.filterparameters.newValue,
        this.filterparameters.field
      )
      .subscribe({
        next: (auditResults) => {
          auditResults.auditsInformation.forEach((audit) => {
            const tableItem = {
              id: audit.auditId,
              objectId: audit.id ? audit.id : '-',
              objectName: audit.name ? audit.name : '-',
              user: audit.userName ? audit.userName : '-',
              functionalityName: audit.functionalityName,
              entityName: audit.entityName,
              date: this.dateService.formatDateAslocal(audit.timestamp),
              originFunction: this.originFuntions.find((item) => item.id == audit.functionalityId)?.name,
            };
            this.auditData.push(tableItem);
          });

          this.haveNextPage = this.pageSize * this.pageIndex < auditResults.totalAudits;
          this.totalAuditData = auditResults.totalAudits;
          this.initPaginatorText = 1 + this.pageSize * this.pageIndex - this.pageSize;
          this.endPaginatorText =
            this.pageSize * this.pageIndex > this.totalAuditData ? this.totalAuditData : this.pageSize * this.pageIndex;
          this.updateTable();
        },
        error: () => {
          this.resultsLoading(false);
          this.noData = true;
          this.changingPage = false;
        },
      });
  }

  updateTable(): void {
    this.dataSource.data = this.auditData;
    this.noData = this.dataSource.data.length == 0;

    this.resultsLoading(false);
    this.changingPage = false;
  }

  goNextPage(): void {
    if (this.haveNextPage) {
      this.changingPage = true;
      this.pageIndex = this.pageIndex + 1;
      this.loadAuditoria();
    }
  }

  goPreviousPage(): void {
    this.changingPage = true;
    this.pageIndex = this.pageIndex - 1;
    this.loadAuditoria();
  }

  sortData(event: any): void {
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.pageIndex = this.INITIAL_PAGE;
    this.loadAuditoria();
  }

  exportAsExcel(): void {
    this.downloadingExcel = true;
    const filesName = 'Habitat_Audit' + '_' + this.dateService.getDateDownload(new Date());
    this.auditBackendService
      .getAuditExcelExport(
        this.filterparameters.user,
        this.filterparameters.originFunction?.id,
        this.fromDate,
        this.toDate,
        this.filterparameters.lastValue,
        this.filterparameters.newValue,
        this.lang,
        this.filterparameters.field
      )
      .subscribe({
        next: (excelFile: Blob) => {
          this.downloadingExcel = false;

          FileSaver.saveAs(excelFile, filesName);
        },
        error: (error) => {
          this.downloadingExcel = false;

          let infoModalData = null;
          this.downloadingExcel = false;
          if (error.status === 409) {
            infoModalData = new InfoModalData('Downloading error', 'Too many records. Please refine your search.');
          } else {
            infoModalData = new InfoModalData(
              'Downloading error',
              'An error has occurred, please try again. If it persists contact the administrator.'
            );
          }
          this.dialog.open(InfoModalComponent, {
            data: infoModalData,
            panelClass: 'custom-dialog',
            disableClose: true,
          });
        },
      });
  }

  showAuditDetail(auditId: any): void {
    this.dialog.open(AuditResultsDetailComponent, { data: { auditId: auditId }, disableClose: true });
  }

  resultsLoading(isLoading: boolean): void {
    this.loading = isLoading;
    this.auditService.isReservationTableLoading(isLoading);
  }
}
