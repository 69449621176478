<div class="table-container">
  <div
    *ngIf="loading"
    style="
      text-align: -webkit-center;
      text-align: -moz-center;
      min-height: 200px;
      min-width: 650px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    "
  >
    <app-loading style="width: 100%"></app-loading>
  </div>

  <div
    *ngIf="!loading && reservasData.length == 0"
    style="
      text-align: -webkit-center;
      text-align: -moz-center;
      min-height: 200px;
      min-width: 650px;
      display: flex;
      align-items: center;
      justify-content: space-around;
    "
  >
    {{ 'There are no data for the indicated options' | translate }}
  </div>

  <div
    [ngStyle]="{ display: !loading && reservasData.length > 0 ? 'flow-root' : 'none' }"
    style="padding-top: 15px; min-height: 200px"
  >
    <div style="margin: -5px">
      <table
        *ngIf="responsive"
        mat-table
        [dataSource]="dataSource"
        style="width: 100%"
        matSort
        (matSortChange)="sortData($event)"
        #sortDataContinuousReservations="matSort"
      >
        <caption></caption>
        <th id="continuousReservationsTable"></th>

        <ng-container matColumnDef="spaceCode">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary"
            style="flex: 0.7; visibility: hidden; display: none"
            >{{ 'Code' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let reservasData" style="flex: 0.7">
            {{ 'Code' | translate }} :
            <span style="font-weight: bold">
              <span *ngIf="reservasData.reservablespacesid" class="shortText">
                {{ reservasData.spaceCode }}
              </span>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="status">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary"
            style="flex: 0.7; visibility: hidden; display: none"
            >{{ 'Status' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let reservasData" style="flex: 0.7">
            {{ 'status' | translate }} :
            <span style="font-weight: bold">
              <span *ngIf="reservasData.status" class="shortText">
                {{ reservasData.status }}
              </span>
            </span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
          <mat-header-cell
            *matHeaderCellDef
            class="text-primary"
            style="flex: 0.7; visibility: hidden; display: none"
            >{{ 'Location' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let reservasData" style="flex: 0.7">
            {{ 'Location' | translate }} :
            <span style="font-weight: bold">
              <span *ngIf="reservasData.location" class="shortText">
                {{ reservasData.location }}
              </span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary"
            style="flex: 1.2; visibility: hidden; display: none"
          >
            {{ 'Start Date' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let reservasData" style="flex: 1.2">
            <div class="shortText">
              {{ 'Start Date' | translate }} :
              {{ reservasData.startDate | date: 'd/M/yyyy' }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary"
            style="flex: 1.2; visibility: hidden; display: none"
            >{{ 'End Date' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let reservasData" style="flex: 1.2">
            {{ 'End Date' | translate }} :
            <span style="font-weight: bold">
              <span *ngIf="reservasData.endDate" class="shortText">
                {{ reservasData.endDate | date: 'd/M/yyyy' }}
              </span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="startTime">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary"
            style="flex: 1.2; visibility: hidden; display: none"
          >
            {{ 'Start Time' | translate }}</mat-header-cell
          >

          <mat-cell *matCellDef="let reservasData" style="flex: 1.2">
            <div class="shortText">
              {{ 'Start Time' | translate }} :
              {{ reservasData.startTime }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="endTime">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary"
            style="flex: 1.2; visibility: hidden; display: none"
          >
            {{ 'End Time' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let reservasData" style="flex: 1.2">
            <div class="shortText">{{ 'End Time' | translate }} : {{ reservasData.endTime }}</div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 1.2; visibility: hidden; display: none">
            {{ 'Actions' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let reservasData" style="flex: 0.6; justify-content: center">
            <div class="d-flex gap-2">
              <span
                *ngIf="reservasData.iconEdit && reservasData.status === 'Approved'"
                [title]="'Edit continuous reservation' | translate"
                class="smallIcon bi bi-pencil"
                role="button"
                (click)="editContinuousReservation(reservasData)"
              ></span>
              <span
                *ngIf="reservasData.iconCross && canWriteReservations && reservasData.status === 'Approved'"
                [title]="'Cancel continuous reservation' | translate"
                class="smallIcon bi bi-x-circle"
                role="button"
                (click)="finishContinuousReservation(reservasData)"
              ></span>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
      </table>
    </div>

    <table
      *ngIf="!responsive"
      mat-table
      [dataSource]="dataSource"
      style="width: 100%"
      matSort
      (matSortChange)="sortData($event)"
      #sortDataContinuousReservations="matSort"
    >
      <caption></caption>
      <th id="continuousReservationsTable"></th>

      <ng-container matColumnDef="spaceCode">
        <mat-header-cell *matHeaderCellDef  mat-sort-header class="text-primary" style="flex: 2"
          >{{ 'Code' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let reservasData" style="flex: 2">
          <span *ngIf="reservasData.reservablespacesid">
            {{ reservasData.spaceCode }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 2"
          >{{ 'Status' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let reservasData" style="flex: 2">
          <span *ngIf="reservasData.status">
            {{ reservasData.status  | translate}}
          </span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="location">
        <mat-header-cell *matHeaderCellDef  class="text-primary" style="flex: 2.5">
          {{ 'Location' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let reservasData" style="flex: 2.5">
          {{ reservasData.location }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="startDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.5">
          {{ 'Start Date' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let reservasData" style="flex: 1.5">
          {{ reservasData.startDate | date: 'd/M/yyyy' }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.5">
          {{ 'End Date' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let reservasData" style="flex: 1.5">
          <span *ngIf="reservasData.endDate">
            {{ reservasData.endDate | date: 'd/M/yyyy' }}
          </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="startTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1">
          {{ 'Start Time' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let reservasData" style="flex: 1">{{ reservasData.startTime }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="endTime">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1">
          {{ 'End Time' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let reservasData" style="flex: 1">
          {{ reservasData.endTime }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 1.5">
          {{ 'Actions' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let reservasData" style="flex: 1.5; justify-content: center">
          <div class="ps-4 d-flex gap-2">
            <span
              *ngIf="reservasData.iconEdit && reservasData.status === 'Approved'"
              [title]="'Edit continuous reservation' | translate"
              class="smallIcon bi bi-pencil"
              role="button"
              (click)="editContinuousReservation(reservasData)"
            ></span>
            <span
              *ngIf="reservasData.iconCross && canWriteReservations && reservasData.status === 'Approved'"
              [title]="'Cancel continuous reservation' | translate"
              class="smallIcon bi bi-x-circle"
              role="button"
              (click)="finishContinuousReservation(reservasData)"
            ></span>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
    </table>

    <div class="line"></div>
    <div style="float: right; padding: 2px">
      <span style="font-size: 12px; padding-right: 20px; color: #525252"
        >{{ initPaginatorText }}&nbsp;-&nbsp;{{ endPaginatorText }}&nbsp;{{ 'of' | translate }}&nbsp;{{
          totalReservasData
        }}</span
      >
      <span style="display: inline-flex">
        <button
          title="{{ 'Previous page' | translate }}"
          (click)="goPreviousPage()"
          style="background: transparent; border: none; width: 40px; height: 40px; margin-right: 0px"
          [hidden]="pageIndex == 1"
        >
          <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
          </svg>
        </button>
        <button
          title="{{ 'Next page' | translate }}"
          *ngIf="haveNextPage"
          (click)="goNextPage()"
          style="background: transparent; border: none; width: 40px; height: 40px"
        >
          <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
          </svg>
        </button>
      </span>
    </div>
  </div>
</div>
