<div class="marginBodyCard" style="max-width: 660px">
  <label class="titleN3 text-primary modalTitle uf-label-1"> {{ modalTitle | translate }}</label>
  <div *ngIf="loading">
    <app-loading></app-loading>
  </div>
  <div [hidden]="loading">
    <!-- NEW HABITAT MANADGEMENT USER FORM -->
    <form
      *ngIf="!isReservationUser"
      class="row"
      [formGroup]="insertUserFormGroup"
      (ngSubmit)="insertOrUpdateUser()"
      style="padding: 1rem 1.5rem"
    >
      <p class="uf-p-1">{{ 'General Information' | translate }}</p>
      <mat-form-field>
        <mat-label>{{ 'User name' | translate }}</mat-label>
        <input
          title="{{ 'User name' | translate }}"
          matInput
          autocomplete="off"
          formControlName="name"
          type="text"
          class="p-1"
          maxlength="45"
          spellcheck="false"
          required
          [data-value]="f.name.value"
        />
        <mat-hint *ngIf="insertUserFormGroup.get('name')?.value.length" align="end"
          >{{ insertUserFormGroup.get('name').value.length }}/45</mat-hint
        >
        <mat-error *ngIf="f.name.errors?.required">{{ 'Name is required' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Surnames' | translate }}</mat-label>
        <input
          title="{{ 'Surnames' | translate }}"
          matInput
          autocomplete="off"
          formControlName="surname"
          type="text"
          class="p-1"
          maxlength="45"
          spellcheck="false"
          required
        />
        <mat-hint *ngIf="insertUserFormGroup.get('surname')?.value.length" align="end"
          >{{ insertUserFormGroup.get('surname').value.length }}/45</mat-hint
        >
        <mat-error *ngIf="f.surname.errors?.required">{{ 'Surname is required' | translate }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'Email' | translate }}</mat-label>
        <input
          title="{{ 'Email' | translate }}"
          matInput
          autocomplete="off"
          formControlName="email"
          type="email"
          class="p-1"
          maxlength="254"
          spellcheck="false"
          required
        />
        <mat-error *ngIf="f.email.errors?.required">{{ 'Email is required' | translate }}</mat-error>
        <mat-error *ngIf="f.email.errors?.email && !f.email.errors?.required">{{
          'Email must be a valid email address' | translate
        }}</mat-error>
        <mat-error *ngIf="f.email.errors?.emailAlreadyExists">{{
          'Email already asigned to an user' | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ sso_identifier | translate }}</mat-label>
        <input
          title="{{ sso_identifier | translate }}"
          matInput
          autocomplete="off"
          formControlName="sso"
          type="text"
          class="p-1"
          maxlength="50"
          spellcheck="false"
          required
        />
        <mat-hint *ngIf="insertUserFormGroup.get('sso')?.value.length" align="end"
          >{{ insertUserFormGroup.get('sso').value.length }}/50</mat-hint
        >
        <mat-error *ngIf="f.sso.errors?.required"
          >{{ sso_identifier | translate }} {{ 'is required' | translate }}</mat-error
        >
        <mat-error *ngIf="f.sso.errors?.ssoAlreadyExists">{{
          'SSO already asigned to an user' | translate
        }}</mat-error>
      </mat-form-field>
      <!-- PERMISSION -->
      <div class="col-12 mt-2">
        <p class="uf-p-2">{{ 'Permissions' | translate }}</p>
        <p class="uf-p-3">
          {{ 'Add buildings to the user and the roles they will have in them:' | translate }}
        </p>
      </div>
      <div class="col-12 col-md-4">
        <mat-form-field>
          <mat-label>{{ 'Building' | translate }}</mat-label>
          <mat-select
            class="p-1"
            formControlName="buildings"
            [(ngModel)]="selectedBuildings"
            (selectionChange)="onBuildingsChange($event)"
            multiple
            required
          >
            <mat-option *ngFor="let building of buildings" [value]="building">
              {{ building.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userBuilding?.roles === undefined || userBuilding?.roles?.length < 1">{{
            'Select a building' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-5">
        <mat-form-field>
          <mat-label>{{ 'Role' | translate }}</mat-label>
          <mat-select class="p-1" formControlName="roleByBuilding" required>
            <mat-option *ngFor="let rolPerf of roles" [value]="rolPerf">
              {{ rolPerf.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="userBuilding?.roles === undefined || userBuilding?.roles?.length < 1">{{
            'Select a role' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
      <div class="col-12 col-md-3 d-flex align-items-center">
        <button mat-button class="modalCancel text-primary" type="button" (click)="addRoleClick()">
          <span class="smallIcon bi bi-plus-circle"></span>
          {{ 'Add' | translate }}
        </button>
      </div>
      <mat-error *ngIf="userBuilding?.roles === undefined || userBuilding?.roles?.length < 1">{{
        'At least one building and one role must be selected' | translate
      }}</mat-error>
      <div class="uf-div-3">
        <!-- AQUI CARGO LA LISTA DE AL MENOS UN EDIFICIO OBLIGATORIO SELECCIONADO CON SU ROL A APLICAR -->
        <div *ngFor="let item of userBuilding?.roles" class="row">
          <div class="col-12 col-md-4">
            <mat-form-field>
              <mat-label>{{ 'Building' | translate }}</mat-label>
              <input matInput class="py-1" placeholder="Building" [value]="item.buildingName" readonly />
            </mat-form-field>
          </div>
          <div class="col-12 col-md-5">
            <mat-form-field>
              <mat-label>{{ 'Role' | translate }}</mat-label>
              <input matInput class="py-1" placeholder="Role" [value]="item.role.name" readonly />
            </mat-form-field>
          </div>
          <div class="col-12 col-md-3 d-flex align-items-center">
            <button mat-button class="modalCancel uf-button-2" type="button" (click)="deleteRoleClick(item)">
              <span class="smallIcon bi bi-dash-circle"></span>
              {{ 'Delete' | translate }}
            </button>
          </div>
        </div>
      </div>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
        <button mat-button class="modalCancel text-primary" (click)="close(null)" type="button">
          {{ 'Cancel' | translate }}
        </button>
        <button
          title="{{ 'Save' | translate }}"
          [hidden]="!canWriteUser"
          mat-button
          class="modalButton bg-primary"
          type="submit"
        >
          {{ 'Save' | translate }}
        </button>
      </div>
    </form>
    <form *ngIf="isReservationUser" class="row" [formGroup]="reservationUserFormGroup" style="padding: 1rem 1.5rem">
      <mat-form-field>
        <mat-label>{{ 'Role' | translate }}</mat-label>
        <mat-select [(value)]="reservationRole">
          <mat-option [value]="role" *ngFor="let role of reservationRoles">{{ role | translate }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
        <button type="button" mat-button class="modalCancel text-primary" (click)="close(null)">
          {{ 'Cancel' | translate }}
        </button>
        <button
          title="{{ 'Save' | translate }}"
          [hidden]="!canWriteUser"
          type="button"
          mat-button
          class="modalButton bg-primary"
          (click)="updateReservationUserClick()"
        >
          {{ 'Save' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>