import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Functionality } from 'src/app/models/audit/Functionality';
import { AuditService } from 'src/app/services/backend/audit.service';
import { AuditPagesService } from 'src/app/services/pages/audit.service';

import { User } from './../../../../../models/auth/User.class';
import { UserService } from './../../../../../services/backend/user.service';
import { Entity } from 'src/app/models/audit/Entity';

@Component({
  selector: 'app-audit-filter',
  templateUrl: './audit-filter.component.html',
  styleUrls: ['./audit-filter.component.scss'],
})
export class AuditFilterComponent implements OnInit {
  @Output('search') onSearchEmitter = new EventEmitter<any>();

  filterFormGroup: UntypedFormGroup;
  originFunctions: Functionality[] = [];
  entities: Entity[] = [];
  users: User[] = [];
  startTime;
  endTime: Date = new Date();
  hasStartTime = false;
  hasEndTime = false;
  startDateText: Date;
  endDateText: Date;
  showRangefilter = false;
  isValidFunctionForm = true;
  resultsTableLoading: boolean;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private datepipe: DatePipe,
    private auditBackendService: AuditService,
    private auditService: AuditPagesService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.initForm();

    this.startTime = new Date(new Date().setHours(0, 0, 0));
    this.endTime = new Date(new Date().setHours(23, 59, 0));

    this.getUsers();
    this.getEntities();
  }

  initForm(): void {
    this.filterFormGroup = this.formBuilder.group({
      entity: ['', [Validators.required]],
      originFunction: [{ value: '', disabled: true }, [Validators.required]],
      user: ['', []],
      field: ['', []],
      lastValue: ['', []],
      newValue: ['', []],
      dateRange: ['', []],
      startDateText: ['', []],
      endDateText: ['', []],
    });
  }

  onSearch(): void {
    this.auditService.isResultsTableLoading$.subscribe(
      (resultsTableLoading) => (this.resultsTableLoading = resultsTableLoading)
    );
    this.auditService.setAudirFilterPArameters(this.filterFormGroup.value);
    this.onSearchEmitter.emit(true);
  }

  get f() {
    return this.filterFormGroup.controls;
  }

  getUsers(): void {
    this.userService.getUsers().subscribe((users) => {
      this.users = users.map((user) => new User(user.userInfo));
    });
  }

  searcher = (search: string, pageNumber: number, pageSize: number): Observable<User[]> => {
    // searcher must return Observable in this method use rxjs of

    const filteredUsers = this.users
      .filter((z) =>
        z.nameComplete
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            search
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
          )
      )
      .slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
    if (!filteredUsers.length) {
      this.filterFormGroup.controls['user'].setValue(null);
    }
    return of(filteredUsers);
  };

  confirmRange(): void {
    this.filterFormGroup.controls['startDateText'].setValue(this.startTime);
    this.filterFormGroup.controls['endDateText'].setValue(this.endTime);

    this.filterFormGroup.controls['dateRange'].setValue(
      this.datepipe.transform(this.startTime, 'dd/MM/yyyy HH:mm') +
        ' - ' +
        this.datepipe.transform(this.endTime, 'dd/MM/yyyy HH:mm')
    );
  }

  getEntities(): void {
    this.entities = [];
    this.auditBackendService.getEntities().subscribe({
      next: (entities) => {
        this.entities = entities;
      },
    });
  }

  getSelectedEntityFunctionalities(): void {
    this.clearField('originFunction');
    this.originFunctions = [];

    this.auditBackendService.getFunctionalitiesByEntity(this.filterFormGroup.value.entity).subscribe({
      next: (funtionalities) => {
        this.originFunctions = funtionalities;
        this.filterFormGroup.get('originFunction').enable();
      },
    });
  }

  clearField(event: string): void {
    switch (event) {
      case 'entity': {
        this.isValidFunctionForm = true;
        this.originFunctions = [];
        this.filterFormGroup.controls['entity'].setValue(null);
        this.filterFormGroup.controls['originFunction'].setValue(null);
        this.filterFormGroup.get('originFunction').disable();
        break;
      }
      case 'originFunction': {
        this.filterFormGroup.controls['originFunction'].setValue(null);
        break;
      }
      case 'user': {
        this.filterFormGroup.controls['user'].setValue(null);
        break;
      }
      case 'lastValue': {
        this.filterFormGroup.controls['lastValue'].setValue(null);
        break;
      }
      case 'field': {
        this.filterFormGroup.controls['field'].setValue(null);
        break;
      }
      case 'newValue': {
        this.filterFormGroup.controls['newValue'].setValue(null);
        break;
      }
      case 'dateRange': {
        this.filterFormGroup.controls['startDateText'].setValue(null);
        this.filterFormGroup.controls['endDateText'].setValue(null);
        this.filterFormGroup.controls['dateRange'].setValue(null);
        this.startDateText = undefined;
        this.endDateText = undefined;
        this.cleanRangeField();

        break;
      }
    }
  }

  cleanRangeField(): void {
    this.startTime = new Date(new Date().setHours(0, 0, 0));
    this.endTime = new Date(new Date().setHours(23, 59, 0));
  }
}
