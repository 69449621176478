<mat-card>
  <mat-card-content>
    <div style="display: inline;">
      <div>
        <p-tree
        [value]="screenSizeWidth > 1100 ? informes : informesMin"
        selectionMode="single"
        [(selection)]="selectedInforme"
        dataKey="label"
        (onNodeSelect)="nodeSelect($event)"
      ></p-tree>
      </div>
      <div style="text-align: center">
        <p-button label="Refresh" icon="pi pi-refresh" styleClass="p-button-sm" (onClick)="refresh()"></p-button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
