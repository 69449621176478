export class ProgressData {
  value: number;
  total: number;

  constructor(value: number, total: number) {
    this.value = value ? value : 0;
    this.total = total ? total : 0;
  }

  getPercentage(): number {
    let percentage = 0;
    if (this.value && this.total && this.total !== 0) {
      percentage = (this.value / this.total) * 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    if (percentage > 100) {
      percentage = 100;
    }
    return percentage;
  }
}
