import { OccupationNotificationType } from './OccupationNotificationType.enum';

export class OccupationNotificationConfig {
  id: number;
  name: string;
  description: string;
  delay: number;
  subtype: OccupationNotificationType;
  zone: number;
  validityStart: string;
  validityEnd: string;
  occupancyRate: number;
  active: boolean;
  creationDate: string;
  lastReadDate: string;

  constructor(msg) {
    this.id = msg?.id;
    this.name = msg?.name;
    this.description = msg?.description;
    this.delay = msg?.delay;
    this.creationDate = msg?.creationDate;
    this.lastReadDate = msg?.lastReadDate;
    this.active = msg?.active;
    this.zone = msg?.zone;
    this.validityStart = msg?.validityStart;
    this.validityEnd = msg?.validityEnd;
    this.subtype = msg.subType ? OccupationNotificationType[msg.subType] : null;
    this.occupancyRate = msg?.occupancyRate;
  }

  toBackenFormat(): any {
    const backendObject = {
      name: this.name,
      description: this.name,
      delay: 0,
      subType: OccupationNotificationType[this.subtype],
      zone: this.zone,
      validityStart: '05:00',
      validityEnd: '22:00',
      occupancyRate: this.occupancyRate,
    };
    return backendObject;
  }
}
