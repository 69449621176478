export class SvgmapProperties {
  svgid: number;
  initialZoom: number;
  width = 1;
  height = 1;
  offsetX: number;
  offsetY: number;
  scale: number;
  widthTooltip = 30;
  heightTooltip = 6;
  zoomEnabled = true;

  constructor(msg) {
    this.svgid = msg?.id;
    this.initialZoom = msg?.initialZoom;
    this.offsetX = msg?.offsetX || 0;
    this.offsetY = msg?.offsetY || 0;
    this.scale = msg?.scale || 1;
  }
}
