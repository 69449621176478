<mat-card>
  <mat-card-content>
    <div *ngIf="loading" class="p-5 d-flex align-items-center justify-content-center">
      <app-loading></app-loading>
    </div>
    <div
      *ngIf="!loading && noData"
      class="d-flex align-items-center justify-content-center p-5 fw-bold fs-4"
    >
      {{ 'No results!' | translate }}
    </div>
    <div [hidden]="loading || noData">
      <div class="py-2">
        <button mat-button color="primary" (click)="exportAsExcel()" title="{{ 'ExcelExport' | translate }}" [disabled]="downloadingExcel">
          <span class="bi bi-cloud-download"></span>
          <span class="p-2">{{ (responsive ? 'ExcelExportResponsive' : 'ExcelExport') | translate }}</span>
          <div class="loader" *ngIf="downloadingExcel"></div>
        </button>
      </div>

      <div *ngIf="responsive">
        <table
          mat-table
          [dataSource]="dataSource"
          class="w-100"
          matSort
          (matSortChange)="sortData($event)"
        >
          <caption></caption>
          <th id="auditoriaTable"></th>

          <ng-container #user matColumnDef="user">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="text-primary d-none"
            >
              {{ 'User' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'User' | translate }} :
                <span *ngIf="auditData.user">
                  {{ auditData.user }}
                </span>
                <span *ngIf="!auditData.user">
                  {{ '-' }}
                </span>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container #functionalityName matColumnDef="functionalityName">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="text-primary d-none"
            >
              {{ 'Functionality Name' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Functionality Name' | translate }} :
                {{ auditData.functionalityName | translate }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container #entityName matColumnDef="entityName">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="text-primary d-none"
            >
              {{ 'Entity Name' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Entity Name' | translate }} :
                {{ auditData.entityName }}
              </div>
            </mat-cell>
          </ng-container>
          <ng-container #id matColumnDef="id">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="text-primary d-none"
            >
              {{ 'id' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'id' | translate }} :
                {{ auditData.objectId }}
              </div>
            </mat-cell>
          </ng-container>
          <ng-container #objectName matColumnDef="objectName">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="text-primary d-none"
            >
              {{ 'Name' | translate }}
            </mat-header-cell>

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Name' | translate }} :
                {{ auditData.objectName }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container #date matColumnDef="date">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="text-primary d-none"
            >
              {{ 'Date/Hour' | translate }}</mat-header-cell
            >

            <mat-cell *matCellDef="let auditData">
              <div class="shortText">
                {{ 'Date/Hour' | translate }} :
                {{ auditData.date | date : 'dd/M/yyyy HH:mm:ss' }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container #actionAuditoria matColumnDef="actionAuditoria">
            <mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="text-primary d-none"
            >
              {{ 'accion' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let auditData">
              <span
                class="smallIcon bi bi-eye"
                role="button"
                (click)="showAuditDetail(auditData.id)"
              ></span>
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>

          <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
        </table>
      </div>

      <table
        *ngIf="!responsive"
        mat-table
        [dataSource]="dataSource"
        class="w-100"
        matSort
        (matSortChange)="sortData($event)"
        #sortDataAuditoria="matSort"
      >
        <caption></caption>
        <th id="auditoriaTable"></th>

        <ng-container #user matColumnDef="user">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">
            {{ 'User' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData">
            <span *ngIf="auditData.user">
              {{ auditData.user }}
            </span>
            <span *ngIf="!auditData.user">
              {{ '-' }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container #functionalityName matColumnDef="functionalityName">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">
            {{ 'Functionality Name' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData">
            {{ auditData.functionalityName | translate }}
          </mat-cell>
        </ng-container>

        <ng-container #entityName matColumnDef="entityName">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">
            {{ 'Entity Name' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData">{{ auditData.entityName | translate }} </mat-cell>
        </ng-container>
        <ng-container #id matColumnDef="id">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">
            {{ 'Id' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData">{{ auditData.objectId | translate }} </mat-cell>
        </ng-container>
        <ng-container #objectName matColumnDef="objectName">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">
            {{ 'Name' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData">{{ auditData.objectName | translate }} </mat-cell>
        </ng-container>

        <ng-container #date matColumnDef="date">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">
            {{ 'Date/Hour' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData">
            {{ auditData.date | date : 'dd/M/yyyy HH:mm:ss' }}
          </mat-cell>
        </ng-container>

        <ng-container #actionAuditoria matColumnDef="actionAuditoria">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary"> </mat-header-cell>
          <mat-cell *matCellDef="let auditData">
            <span
              class="smallIcon bi bi-eye"
              role="button"
              (click)="showAuditDetail(auditData.id)"
            ></span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
      </table>

      <div class="line"></div>
      <div class="d-flex justify-content-end align-items-center p-2">
        <small class="text-muted pe-3"
          >{{ initPaginatorText }}&nbsp;-&nbsp;{{ endPaginatorText }}&nbsp;{{ 'of' | translate }}&nbsp;{{
            totalAuditData
          }}</small>
        <span class="d-inline-flex gap-2">
          <button
            title="{{ 'PreviousPage' | translate }}"
            (click)="goPreviousPage()"
            class="paginator-button"
            [hidden]="pageIndex == 1"
          >
            <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
              <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
            </svg>
          </button>
          <button
            title="{{ 'NextPage' | translate }}"
            (click)="goNextPage()"
            class="paginator-button"
          >
            <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
              <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
            </svg>
          </button>
        </span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
