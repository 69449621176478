export class ProximityNotificationConfig {
  id: number;
  type: string;
  creationDate: string;
  lastReadDate: string;
  active = false;

  constructor(msg) {
    this.id = msg?.id;
    this.type = msg?.alarmType;
    this.creationDate = msg?.creationDate;
    this.lastReadDate = msg?.lastReadDate;
    this.active = msg.creationDate;
  }
}
