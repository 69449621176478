import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Constants } from 'src/app/models/Constants.const';
import { Building } from 'src/app/models/habitat/Building.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { Languages } from 'src/app/models/Languages.enum';
import { ReservationOwner } from 'src/app/models/reservation/ReservationOwner.class';
import { ReservationRolesEnum } from 'src/app/models/reservation/ReservationRoles.enum';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { ReservationStatus } from 'src/app/models/reservation/ReservationStatus.enum';
import { ReservationUser } from 'src/app/models/reservation/ReservationUser.class';
import { ReservationUsersFiltered } from 'src/app/models/reservation/ReservationUsersFiltered.class';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { DateService } from 'src/app/services/common/date.service';

@Component({
  selector: 'app-space-info',
  templateUrl: './space-info.component.html',
  styleUrls: ['./space-info.component.css'],
})
export class SpaceInfoComponent implements OnInit {
  space: ReservationSpace;
  allUsers;
  reserved = true;
  spaceGroup;
  spaceApproval;
  approvers = [];
  reservation: any;
  reservable = false;
  reservableText;
  reservationStartDate;
  reservationEndDate;
  loading = true;

  constructor(
    private reservationService: ReservationService,
    private habitatService: HabitatService,
    private dateService: DateService,
    private dialogRef: MatDialogRef<SpaceInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.approvers = [];
    this.reservationService
      .getUsersFiltered(1, Constants.maxInt, null, ReservationRolesEnum.Approver, null, null)
      .subscribe((users: ReservationUsersFiltered) => {
        this.buildSpaceInfo(users);
      });
  }

  buildSpaceInfo(users: ReservationUsersFiltered): void {
    this.reservationService.getRservationSpaceInfo(this.data.point.code).subscribe({
      next: (space: ReservationSpace) => {
        this.reservationService.getSpaceReservation(space.id, ReservationStatus.InProgress).subscribe({
          next: (reservations) => {
            const now = new Date(this.dateService.getUTCDate(new Date())).getTime();
            this.reservation = reservations.find(
              (item) => new Date(item.startDate).getTime() <= now && new Date(item.endDate).getTime() >= now
            );
            this.getCompleteNameOwner(this.reservation.owner);
            this.habitatService.getFloor(space.floor).subscribe((floor: Floor) => {
              this.habitatService.getBuilding(floor.building).subscribe((building: Building) => {
                this.habitatService.getCity(building.city).subscribe((city) => {
                  this.reservationStartDate = this.dateService.utcToLocalString(
                    this.dateService.getUTCDate(this.reservation.startDate),
                    Languages.es,
                    city.timezone
                  );
                  this.reservationEndDate = this.dateService.utcToLocalString(
                    this.dateService.getUTCDate(this.reservation.endDate),
                    Languages.es,
                    city.timezone
                  );
                  this.reservableText = 'Yes';
                  this.reservable = true;
                  space.approvers.forEach((user) => {
                    user = new ReservationUser({ id: user });
                    const userData = users.users.find((usr) => usr.id == user.id);
                    this.approvers.push(userData);
                  });

                  this.space = space;

                  this.spaceGroup = space.groupable ? 'Yes' : 'No';
                  this.spaceApproval = space.needsApproval ? 'Yes' : 'No';
                  this.loading = false;
                });
              });
            });
          },
          error: () => {
            this.loading = false;
          },
        });
      },
      error: () => {
        this.space = new ReservationSpace('');
        this.reservation = {
          owner: new ReservationOwner({ name: this.data.point.label4 }),
          status: ReservationStatus.InProgress,
        };

        this.reservationStartDate = this.data.point.label5;

        this.reservableText = 'Yes';
        this.reservable = true;

        this.loading = false;
        this.reservableText = 'No';
        this.loading = false;
      },
    });
  }

  getCompleteNameOwner(owner: ReservationOwner): void {
    if (owner && owner instanceof Object) {
      this.reservationService.getReservationUser(owner.employeeNumber).subscribe((item) => {
        owner.name = item?.surname ? item?.name?.concat(' ').concat(item?.surname) : item?.name;
      });
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
