import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpService } from '../common/http.service';
import { AuditFunctionalities } from './../../models/AuditFunctionalities.enum';
import { HttpMethods } from './../../models/HttpMethods.enum';
import { Permission, Role, UserBuilding } from './../../models/auth/Role.class';
import { User } from './../../models/auth/User.class';
import { AppConstants } from 'src/app/shared/AppConstants';
import { UsersFiltered } from 'src/app/models/auth/UsersFiltered.class';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = `${environment.habitat_backend_url}`;
  private protocol = `${environment.backend_protocol}`;

  constructor(private httpService: HttpService) {}

  requestToBack(type, method, body?, headers?, options?): Observable<any> {
    return this.httpService.httpRequest(this.protocol + this.url, type, method, body, headers, options);
  }

  /**
   *
   * Obtiene una lista de todos los permisos que existen.
   *
   * @returns
   */
  getPermissions(): Observable<Permission[]> {
    const method = 'v3/permissions';
    return this.requestToBack(HttpMethods.GET, method, {});
  }

  /**
   *
   * Obtiene la información de un único permiso.
   *
   * @param id
   * @param name
   * @returns
   */
  getPermission(id?: number, name?: string): Observable<Permission> {
    let method = 'v3/permissions/permission';

    if (id) {
      method = method + '?id=' + id;
    } else if (name) {
      method = method + '?name=' + name;
    }

    return this.requestToBack(HttpMethods.GET, method, {});
  }

  /**
   *
   * Obtiene la lista de todos los Roles que existen.
   *
   * @returns
   */
  getRoles(): Observable<Role[]> {
    const method = 'v3/roles';
    return this.requestToBack(HttpMethods.GET, method, {});
  }

  /**
   *
   * Obtiene la información de un único Rol.
   *
   * @param id
   * @param name
   * @returns
   */
  getRole(id?: any, name?: string): Observable<Role> {
    let method = 'v3/roles/role';

    if (id) {
      method = method + '?id=' + id;
    } else if (name) {
      method = method + '?name=' + name;
    }

    return this.requestToBack(HttpMethods.GET, method, {});
  }

  /**
   *
   * Obtiene la información de los roles que tienen asignados un permiso.
   *
   * @param permission que tiene asignado los roles
   * @returns roles con el permiso del parámetro
   */
  getRolesByPermission(permission: string): Observable<Role[]> {
    const method = 'v3/roles?permissions=' + permission;
    return this.requestToBack(HttpMethods.GET, method, {});
  }

  /**
   *
   * Crea un nuevo Rol.
   *
   * @param role
   * @returns
   */
  createNewRole(role: Role, functionality: string = AuditFunctionalities.CreateRole): Observable<any> {
    const method = 'v3/roles';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.POST, method, role, headers);
  }

  /**
   *
   * Elimina un Rol.
   *
   * @param id
   * @returns
   */
  deleteRole(id: number, functionality: string = AuditFunctionalities.DeleteRole): Observable<any> {
    const method = 'v3/roles/' + id;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.DELETE, method, {}, headers);
  }

  /**
   *
   * Modifica un nuevo Rol.
   *
   * @param role
   * @returns
   */
  modifyRole(
    roleID: number,
    permissions: any[],
    functionality: string = AuditFunctionalities.ModifyRole
  ): Observable<any> {
    const method = 'v3/roles/' + roleID;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.PATCH, method, { permissions: permissions }, headers);
  }

  /**
   *
   * @returns
   */
  getUsers(): Observable<UserBuilding[]> {
    const method = 'v3/users';
    return this.requestToBack(HttpMethods.GET, method, {});
  }

  getUsersFiltered(
    page: number,
    perPage: number,
    searchValue: any,
    roles: any,
    sortColumns: any,
    sortOrder: any,
    count = true
  ): Observable<UsersFiltered> {
    let method = 'v3/users?count=' + count + '&page=' + page + '&perPage=' + perPage;
    if (searchValue) {
      method = method + '&term=' + searchValue;
    }
    if (roles?.length) {
      method = method + '&roles=' + roles;
    }
    if (sortColumns) {
      method = method + '&sortColumn=' + sortColumns;
    }
    if (sortOrder) {
      method = method + '&sortOrder=' + sortOrder;
    }

    return this.requestToBack(HttpMethods.GET, method, {});
  }

  /**
   * @param id
   * @returns
   */
  getUser(id: number): Observable<UserBuilding> {
    const method = 'v3/users/user?userId=' + id;
    return this.requestToBack(HttpMethods.GET, method, {});
  }

  /**
   *
   * @param id
   * @returns
   */
  getUserExtra(id: number): Observable<User> {
    const method = 'v3/users/' + id + '/extra';
    return this.requestToBack(HttpMethods.GET, method, {});
  }

  /**
   *
   * @param user
   * @returns
   */
  createNewUser(user: User, functionality: string = AuditFunctionalities.CreateUserCore): Observable<User> {
    const method = 'v3/users';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.POST, method, new User(user).toProfileObject(), headers);
  }

  /**
   *
   * @param user
   * @returns
   */
  createNewUserExtra(
    id: number,
    user: User,
    functionality: string = AuditFunctionalities.CreateUserExtra
  ): Observable<User> {
    const method = 'v3/users/' + id + '/extra';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.POST, method, new User(user).toPreferencesObject(), headers);
  }

  /**
   *
   * @param user
   * @returns
   */
  modifyUser(id: number, user: User, functionality: string = AuditFunctionalities.ModifyUserCore): Observable<User> {
    const method = 'v3/users/' + id;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.PATCH, method, new User(user).toProfileObject(), headers);
  }

  /**
   *
   * @param id
   * @param user
   * @returns
   */
  modifyUserExtra(
    id: number,
    user: User,
    functionality: string = AuditFunctionalities.ModifyUserExtra
  ): Observable<User> {
    const method = 'v3/users/' + id + '/extra';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.PATCH, method, new User(user).toPreferencesObject(), headers);
  }

  /**
   *
   * @param id
   * @returns
   */
  deletePhysicalUser(id: number, functionality: string = AuditFunctionalities.DeleteUser): Observable<any> {
    const method = 'v3/users/' + id + '/physical';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.DELETE, method, {}, headers);
  }

  /**
   *
   * @param id
   * @returns
   */
  deleteLogicalUser(id: number, functionality: string = AuditFunctionalities.DeleteLogicUser): Observable<any> {
    const method = 'v3/users/' + id + '/logical';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.DELETE, method, {}, headers);
  }

  /**
   *
   * @param id the user id
   * @returns
   */
  reactivateUser(id: number): Observable<unknown> {
    const method = `v3/users/reactivate?userId=${id}`;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, AuditFunctionalities.ModifyUser);
    return this.requestToBack(HttpMethods.POST, method, {}, headers, { responseType: 'text' });
  }

  /**
   *
   * @param userId
   * @param roles
   * @returns
   */
  modifyRolesToUser(
    userId: number,
    roles: any,
    functionality: string = AuditFunctionalities.ModifyUserRoles
  ): Observable<UserBuilding> {
    const method = 'v3/users/' + userId + '/roles';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToBack(HttpMethods.PATCH, method, { roles: roles }, headers);
  }
}
