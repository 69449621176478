<ng-container *ngIf="havePermission">
  <mat-card class="p-4">
    <div
      #loading
      *ngIf="loadingFull"
      style="min-height: 350px; display: flex; align-items: center; justify-content: center"
    >
      <app-loading></app-loading>
    </div>
    <mat-card-header *ngIf="!loadingFull" class="border-0">
      <div class="fs-4 text-primary">
        {{ 'Users' | translate }}
      </div>
      <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
        <mat-tab
          *ngIf="canEditUsers"
          bodyClass="js-habitat-users-tab"
          label="{{ habitatUsersTitle }}"
        ></mat-tab>
        <mat-tab
          *ngIf="hasReservationsModule"
          bodyClass="js-reservation-users-tab"
          label="{{ reservationUsersTitle }}"
        ></mat-tab>
      </mat-tab-group>
    </mat-card-header>
    <mat-card-content [hidden]="loadingFull">
      <div *ngIf="loading" style="min-height: 350px; display: flex; align-items: center; justify-content: center">
        <app-loading></app-loading>
      </div>
      <div [hidden]="loading">
        <div *ngIf="habitatTab && canWriteUser" class="d-flex justify-content-end my-3">
          <button mat-button (click)="openModal(null)">
            <span class="smallIcon bi bi-plus-circle"></span>
            <span class="ps-1">{{ 'New user' | translate }}</span>
          </button>
        </div>
        <div
          #canEditUsers
          *ngIf="!habitatTab && nttdata && canEditUsers"
          class="d-flex justify-content-end my-3"
        >
          <button mat-button (click)="OpenAddNttdatauser()">
            <span class="smallIcon bi bi-plus-circle"></span>
            <span class="ps-1">{{ 'Add user' | translate }}</span>
          </button>
        </div>
        <mat-form-field class="search-field">
          <mat-label class="labelSearch">{{ 'Search' | translate }}</mat-label>
          <div class="d-flex">
            <input
              title="{{ 'Search' | translate }}"
              matInput
              class="filter-input py-1"
              (keyup.enter)="triggerSearch()"
              (keyup)="applyFilter($event)"
              #input
              type="text"
              [(ngModel)]="searchValue"
            />
            <div class="searchIcon">
              <span class="bi bi-search" (click)="triggerSearch()" role="button"></span>
            </div>
          </div>
        </mat-form-field>
        <div class="d-flex flex-column mb-2">
          
          <div
            style="
              display: grid;
              grid-template-columns: 25% 25% 25% 25%;
              justify-items: start;
              grid-gap: 5px;
              margin-top: 5px;
            "
            *ngIf="habitatRoles && habitatTab"
          >
            <div *ngFor="let item of habitatRoles">
              <mat-checkbox [checked]="true" #checkBox name="{{ item }}" (change)="filterUsers()" [disabled]="roles === item && rolesArray.length == 1">
                {{ item | translate }}
              </mat-checkbox>
            </div>
          </div>
          <div class="d-flex" *ngIf="reservationRoles && !habitatTab">
            <div *ngFor="let item of reservationRoles">
              <mat-checkbox [checked]="true" #checkBox name="{{ item }}" (change)="filterUsers()" [disabled]="roles === item && rolesArray.length == 1">
                {{ item | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>
        <div style="margin: -5px">
          <table
            *ngIf="responsive"
            style="width: 100%"
            mat-table
            [dataSource]="dataSource"
            matSort
            (matSortChange)="sortData($event)"
            matSortActive="name"
            matSortDirection="asc"
            #tableUsers
          >
            <caption></caption>
            <th id="usersTableResponsive"></th>
            <ng-container matColumnDef="name">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 1.3; visibility: hidden; display: none">
                {{ 'Name' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 1.3">
                {{ 'Name' | translate }}:
                <span style="font-weight: bold">
                  <span *ngIf="user.nameComplete" class="shortText">
                    {{ user.nameComplete }}
                  </span>
                  <span *ngIf="!user.nameComplete" class="shortText">
                    {{ user.name }}
                  </span>
                </span></mat-cell
              >
            </ng-container>
            <ng-container matColumnDef="role">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.7; visibility: hidden; display: none"
                >{{ 'Role' | translate }}
              </mat-header-cell>
              <mat-cell *matCellDef="let user" style="flex: 0.7"
                >{{ 'Role' | translate }}:

                <span *ngIf="user.rolesName">
                  {{ user.rolesName }}
                </span>
                <span *ngIf="!user.rolesName">
                  {{ user.role }}
                </span>
              </mat-cell>
            </ng-container>

            <ng-container matColumnDef="email">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 1.2; visibility: hidden; display: none">
                {{ 'Email' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 1.2">
                <div class="shortText">
                  {{ 'Email' | translate }}:
                  {{ user.email }}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="ownReservation">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 1.2; visibility: hidden; display: none">
                {{ 'Be able to reserve' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 1.2">
                <div class="shortText" style="display: flex">
                  <div style="max-width: 60px">{{ 'Be able to reserve' | translate }}:</div>

                  <div *ngIf="user.ownReservation" class="circle colorGreen" style="margin-left: 15%"></div>
                  <div *ngIf="!user.ownReservation" class="circle colorRed" style="margin-left: 15%"></div>
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.6; visibility: hidden; display: none">
                {{ 'Actions' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 0.6" class="d-flex gap-2">
                <span
                  #editUs
                  [title]="'Edit user' | translate"
                  class="smallIcon bi bi-pencil"
                  role="button"
                  (click)="editUser(user.id)"
                ></span>
                <span
                  #delete
                  class="smallIcon bi bi-trash"
                  role="button"
                  [title]="'Delete user' | translate"
                  (click)="deleteUser(user.id)"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="actionReservation">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.6; visibility: hidden; display: none">
                {{ 'Actions' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 0.6" class="d-flex gap-2">
                <span
                  #editUserRole
                  [title]="'Edit user role' | translate"
                  *ngIf="canEditReservationRole"
                  class="smallIcon bi bi-pencil"
                  role="button"
                  (click)="editUserReservation(user.employeeNumber)"
                ></span>
                <span
                  #showUserReservations
                  [title]="'Show user reservations' | translate"
                  class="smallIcon bi bi-eye"
                  role="button"
                  (click)="showReservations(user.employeeNumber)"
                ></span>

                <span
                  #showUserCReservations
                  [title]="'Show user continuous reservations' | translate"
                  (click)="showContinuousReservations(user.employeeNumber)"
                  class="smallIcon bi bi-calendar3"
                  role="button"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="office">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 1.2; visibility: hidden; display: none">
                {{ 'Office' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 1.2">
                <div class="shortText">
                  {{ 'Office' | translate }}:
                  {{ user.office }}
                </div>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="area">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 1.2; visibility: hidden; display: none">
                {{ 'Area' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 1.2; text-align-last: auto">
                <div class="shortText">{{ 'Area' | translate }}: {{ user.area }}</div>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
          </table>
        </div>
        <table
          *ngIf="!responsive"
          style="width: 100%"
          mat-table
          [dataSource]="dataSource"
          matSort
          (matSortChange)="sortData($event)"
          matSortActive="name"
          matSortDirection="asc"
          #tableUsersMobile
        >
          <caption></caption>
          <th id="usersTable"></th>
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3"
              >{{ 'Name' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user" style="flex: 1.3">
              <span [ngClass]="user.hidden && 'text-decoration-line-through'">
                {{ user.nameComplete ? user.nameComplete : user.name }}
              </span>
              <strong *ngIf="user.hidden" class="ms-1 text-uppercase">
                [{{'inactive' | translate}}]
              </strong>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.7"
              >{{ 'Role' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let user" style="flex: 0.7">
              <span
                *ngIf="user.rolesName"
                style="
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  margin-right: 10px;
                  cursor: pointer;
                "
                title="{{ user.rolesName }}"
              >
                {{ user.rolesName }}
              </span>
              <span
                *ngIf="!user.rolesName"
                style="
                  white-space: nowrap;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  margin-right: 10px;
                  cursor: pointer;
                "
                title="{{ user.role }}"
              >
                {{ user.role }}
              </span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.2">
              {{ 'Email' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let user" style="flex: 1.2">{{ user.email }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="ownReservation">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.2">
              {{ 'Be able to reserve' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let user">
              <div *ngIf="user.ownReservation" class="circle colorGreen" style="margin-left: 15%"></div>
              <div *ngIf="!user.ownReservation" class="circle colorRed" style="margin-left: 15%"></div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.6; visibility: hidden">
              {{ 'Actions' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let user" style="flex: 0.6" class="d-flex gap-2">
              <span
              *ngIf="!user.hidden"
                #editUserMobile
                [title]="'Edit user' | translate"
                class="smallIcon bi bi-pencil"
                role="button"
                (click)="editUser(user.id)"
              ></span>
              <span
                #deleteMobile
                *ngIf="!user.hidden"
                class="smallIcon bi bi-trash"
                role="button"
                [title]="'Delete user' | translate"
                (click)="deleteUser(user.id)"
              ></span>
              <span
                *ngIf="user.hidden"
                class="smallIcon bi bi-power"
                role="button"
                [title]="'ActivateUser' | translate"
                [attr.aria-label]="'ActivateUser' | translate"
                (click)="reactivateUser(user.id)"
              ></span>

            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="actionReservation">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.6"> </mat-header-cell>
            <mat-cell *matCellDef="let user" style="flex: 0.6" class="d-flex gap-2">
              <span
                #editUserRoleMobile
                [title]="'Edit user role' | translate"
                *ngIf="canEditReservationRole"
                class="smallIcon bi bi-pencil"
                role="button"
                (click)="editUserReservation(user.employeeNumber)"
              ></span>
              <span
                #showUserReservationsMobile
                [title]="'Show user reservations' | translate"
                class="smallIcon bi bi-eye"
                role="button"
                (click)="showReservations(user.employeeNumber)"
              ></span>
              <span
                #showUserCReservationsMobile
                [title]="'Show user continuous reservations' | translate"
                (click)="showContinuousReservations(user.employeeNumber)"
                class="smallIcon bi bi-calendar3"
                role="button"
              ></span>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="office">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.75; padding-left: 10px">
              {{ 'Office' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let user" style="flex: 0.75; padding-left: 10px">{{ user.office }} </mat-cell>
          </ng-container>
          <ng-container matColumnDef="area">
            <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.75">
              {{ 'Area' | translate }}</mat-header-cell
            >
            <mat-cell *matCellDef="let user" style="flex: 0.75">{{ user.area }} </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
        </table>
        <div  class="line"></div>
        <div class="d-flex justify-content-end align-items-center p-1">
          <span style="font-size: 12px; padding-right: 20px; color: #525252"
            >{{ initPaginatorText }}&nbsp;-&nbsp;{{ endPaginatorText }}&nbsp;{{ 'of' | translate }}&nbsp;{{
              totalUsers
            }}</span
          >
          <span class="d-inline-flex gap-2">
            <button
              title="Previous page"
              (click)="goPreviousPage()"
              style="background: transparent; border: none; width: 40px; height: 40px; margin-right: 0px"
              [hidden]="pageIndex == 1"
            >
              <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path>
              </svg>
            </button>
            <button
              title="Next page"
              (click)="goNextPage()"
              style="background: transparent; border: none; width: 40px; height: 40px"
            >
              <svg viewBox="0 0 24 24" focusable="false" class="mat-paginator-icon">
                <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path>
              </svg>
            </button>
          </span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
