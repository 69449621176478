import { AvailabilityType } from '../esite/AvailabilityType.class';

export class ChartAvailabilityType {
  type: AvailabilityType;
  color = '#ededed';
  inlegend = false;

  constructor(availabilityType: AvailabilityType) {
    this.type = availabilityType;
    switch (availabilityType) {
      case AvailabilityType.Available:
        this.color = '#36a677';
        this.inlegend = true;
        break;
      case AvailabilityType.AvailablePna:
        this.color = '#36a677';
        this.inlegend = false;
        break;
      case AvailabilityType.Absent:
        this.color = '#ffd100';
        this.inlegend = true;
        break;
      case AvailabilityType.AbsentPna:
        this.color = '#ffd100';
        this.inlegend = false;
        break;
      case AvailabilityType.NoSensor:
        this.color = '#aaaaaa';
        this.inlegend = false;
        break;
      case AvailabilityType.NotAvailable:
        this.color = '#eb7100';
        this.inlegend = true;
        break;
    }
  }
}
