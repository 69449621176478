<mat-card class="modalCard">
  <mat-card-content class="marginBodyCard">
    <div class="table-container">
      <div
        *ngIf="loading"
        class="p-5 d-flex justify-content-center align-items-center"
      >
        <app-loading class="w-100"></app-loading>
      </div>

      <div *ngIf="!loading" class="titleN3 text-primary modalTitle">
        {{ 'Audit Detail' | translate }}: <span>{{ auditObjet }}</span>
      </div>

      <div
        *ngIf="!loading && auditData.length == 0"
        class="p-5 d-flex justify-content-center align-items-center"
      >
        {{ 'There are no data for the indicated options' | translate }}
      </div>

      <table
        *ngIf="responsive"
        mat-table
        [dataSource]="dataSource"
        class="w-100"
        matSort
        (matSortChange)="sortData($event)"
      >
        <caption></caption>
        <th id="auditoriaDetailTable"></th>

        <ng-container matColumnDef="field">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary d-none"
          >
            {{ 'Field Name' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ 'Field Name' | translate }} :
              {{ auditData.field | translate }}
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="table">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary d-none"
            >{{ 'Table' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            {{ 'Table' | translate }} :
            <span class="fw-bold">
              <span *ngIf="auditData.table" class="shortText">
                {{ auditData.table | translate }}
              </span>
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="previousValue">
          <mat-header-cell *matHeaderCellDef class="text-primary d-none">
            {{ 'Previous value' | translate }}</mat-header-cell
          >

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ 'Previous value' | translate }} :
              <span *ngIf="auditData.previousValue">
                {{ auditData.previousValue }}
              </span>
              <span *ngIf="!auditData.previousValue">
                {{ '-' }}
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actualValue">
          <mat-header-cell *matHeaderCellDef class="text-primary d-none">
            {{ 'New value' | translate }}
          </mat-header-cell>

          <mat-cell *matCellDef="let auditData">
            <div class="shortText">
              {{ 'New value' | translate }} :
              <span *ngIf="auditData.actualValue">
                {{ auditData.actualValue }}
              </span>
              <span *ngIf="!auditData.actualValue">
                {{ 'X' }}
              </span>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
      </table>

      <table
        *ngIf="!responsive"
        mat-table
        [dataSource]="dataSource"
        class="w-100"
        matSort
        (matSortChange)="sortData($event)"
        #sortDataAuditoriaDetail="matSort"
      >
        <caption></caption>
        <th id="auditoriaDetailTable"></th>

        <ng-container matColumnDef="field">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary">
            {{ 'Field Name' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData" >{{ auditData.field | translate }} </mat-cell>
        </ng-container>

        <ng-container matColumnDef="table">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" 
            >{{ 'Table' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let auditData">
            <span *ngIf="auditData.table">
              {{ auditData.table | translate }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="previousValue">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'Previous value' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData">
            <span *ngIf="auditData.previousValue">
              {{ auditData.previousValue }}
            </span>
            <span *ngIf="!auditData.previousValue">
              {{ '-' }}
            </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actualValue">
          <mat-header-cell *matHeaderCellDef class="text-primary">
            {{ 'New value' | translate }}</mat-header-cell
          >
          <mat-cell *matCellDef="let auditData">
            <span *ngIf="auditData.actualValue">
              {{ auditData.actualValue }}
            </span>
            <span *ngIf="!auditData.actualValue">
              {{ 'X' }}
            </span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
      </table>

      <mat-paginator [pageSize]="pageSize" (page)="paginator = $event" [hidePageSize]="true"></mat-paginator>

      <div class="text-center">
        <button mat-button class="modalCancel text-primary" (click)="close()" type="button">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
