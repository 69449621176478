import { Component, OnInit } from '@angular/core';
import { VersionsService } from 'src/app/services/backend/versions.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { LanguageService } from 'src/app/services/common/language.service';
import { environment } from 'src/environments/environment';
import { User } from 'src/app/models/auth/User.class';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  selectedLanguage: string;
  isClicker = '';
  inputSelected = false;
  userLogged: User;
  // Modules of habitat
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;

  // Version of different components
  webVersion = environment.version;
  habitatVersion: string;
  esiteVersion: string;
  conteoVersion: string;
  reservationsVersion: string;

  constructor(
    private globalService: GlobalService,
    private languageService: LanguageService,
    private versionsService: VersionsService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.hasReservationsModule = this.globalService.getReservationModule();
    this.userLogged = this.globalService.getUser();
  }

  ngOnInit(): void {
    this.loadVersions();
    this.languageService.curLanguage.subscribe((lan) => {
      this.selectedLanguage = lan;
    });
  }

  get privacyPolicyUrl() {
    return `/${this.selectedLanguage}/privacy-policy`;
  }

  handleLanguageSelect(lang: string): void {
    this.languageService.changeCurLanguage(lang);
  }

  /**
   * Initializes the different module versions according to the values stored in the service VersionService
   * */
  private loadVersions(): void {
    this.habitatVersion = this.versionsService.getHabitatVersion();
    this.conteoVersion = this.versionsService.getConteoVersion();
    this.esiteVersion = this.versionsService.getEsiteVersion();
    this.reservationsVersion = this.versionsService.getReservationsVersion();
  }
}
