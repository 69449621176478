import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import FileSaver from 'file-saver';
import { forkJoin, Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { InfoModalComponent } from 'src/app/components/shared/info-modal/info-modal.component';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { Constants } from 'src/app/models/Constants.const';
import { Building } from 'src/app/models/habitat/Building.class';
import { City } from 'src/app/models/habitat/City.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { InfoModalData } from 'src/app/models/InfoModalData.class';
import { ContinuousReservation } from 'src/app/models/reservation/ContinuousReservation';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { ReservationUser } from 'src/app/models/reservation/ReservationUser.class';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { DateService } from 'src/app/services/common/date.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { LanguageService } from 'src/app/services/common/language.service';

import { NewContinuousReserveComponent } from '../../../users/continuous-reserve/new-continuous-reserve/new-continuous-reserve.component';
import { Permission } from './../../../../../models/auth/Role.class';
import { User } from './../../../../../models/auth/User.class';
import { UserPermissions } from './../../../../../models/auth/UserPermissions.enum';

@Component({
  selector: 'app-continuous-reservations-results',
  templateUrl: './continuous-reservations-results.component.html',
  styleUrls: ['./continuous-reservations-results.component.css'],
})
export class ContinuousReservationsResultsComponent implements OnInit {
  filterParameters: any;
  INITIAL_PAGE = 1;
  INITIAL_PAGESIZE = 10;
  pageSize = this.INITIAL_PAGESIZE;
  pageIndex: number = this.INITIAL_PAGE;
  sortColumn: any;
  sortOrder: any;
  responsive = false;
  loading = false;
  reservasData = [];
  floors: Floor[];
  buildings: Building[];
  cities: City[];
  haveNextPage: boolean = null;
  dataSource = new MatTableDataSource([]);
  subcriptions: Subscription[] = [];
  searchValue: any;
  initPaginatorText: number;
  endPaginatorText: number;
  totalReservasData: number;
  userID: number;
  code: number;
  status: string;
  fromDate: any;
  toDate: any;
  noData = true;
  instructions = true;
  downloadingExcel = false;
  users: ReservationUser[];
  lang: string;
  displayedColumnsTable: string[] = [
    'user',
    'spaceCode',
    'status',
    'location',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'actions',
  ];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  canWriteReservations = false;
  userLogged: User = null;
  private permissions: Permission[] = [];

  constructor(
    private globalService: GlobalService,
    private reservationService: ReservationService,
    private translateService: TranslateService,
    private dateService: DateService,
    private habitatService: HabitatService,
    private dialog: MatDialog,
    private datepipe: DatePipe,
    private languageService: LanguageService
  ) {
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
    this.languageService.curLanguage.subscribe((lan: any) => {
      this.lang = lan;
    });
  }

  ngOnInit(): void {
    this.canWriteReservations =
      this.permissions?.find(
        (permission) => permission.name == UserPermissions.ContinuousReservations && permission.type == 'WRITE'
      ) !== undefined;

    this.globalService.CReservationsFilterParameters$.subscribe((filterParameters) => {
      if (!(Object.keys(filterParameters).length === 0 && filterParameters.constructor === Object)) {
        this.instructions = false;
        this.resultsLoading(true);
        this.userID = filterParameters.CRuser;
        this.code = filterParameters.CRspaceCode;
        this.status = filterParameters.CRstatus;
        this.filterParameters = filterParameters;
        if (filterParameters.CRdateRange) {
          const startMoment = new Date(filterParameters.CRstartDateText);
          const endMoment = new Date(filterParameters.CRendDateText);
          this.fromDate = this.datepipe.transform(startMoment, 'yyyy-MM-dd');
          this.toDate = this.datepipe.transform(endMoment, 'yyyy-MM-dd');
        } else {
          this.fromDate = null;
          this.toDate = null;
        }
        const floors = this.habitatService.getFloors();
        const buildings = this.habitatService.getBuildings();
        const cities = this.habitatService.getCities();
        const users = this.reservationService.getUsers(1, Constants.maxInt);

        if (!this.floors || !this.buildings || !this.cities) {
          forkJoin([floors, buildings, cities, users]).subscribe((data) => {
            this.floors = data[0];
            this.buildings = data[1];
            this.cities = data[2];
            this.users = data[3];
            this.loadContinuousReservation();
          });
        } else {
          this.loadContinuousReservation();
        }
      }
    });
  }

  loadContinuousReservation(): void {
    this.reservasData = [];
    this.resultsLoading(true);
    this.reservationService
      .getContinuousReservation(
        this.pageIndex,
        this.pageSize,
        this.sortOrder,
        this.sortColumn,
        this.userID,
        this.code,
        this.status,
        this.fromDate,
        this.toDate,
        true
      )
      .subscribe({
        next: (reservas: any) => {
          if (reservas.continuousReservations.length) {
            this.noData = false;
            reservas.continuousReservations.forEach((reservaItem: any) => {
              // Format Dates
              const startDate = this.buildDateUTC(reservaItem.startDate, reservaItem.startTime);
              const endDate = this.buildDateUTC(reservaItem.endDate, reservaItem.endTime);

              this.reservationService.getRservationSpaceInfo(reservaItem.reservablespacesid).subscribe({
                next: (space: ReservationSpace) => {
                  const floor = this.floors.find((item) => item.id == space.floor);
                  const building = this.buildings.find((item) => item.id == floor?.building);
                  const city = this.cities.find((item) => item.id == Number(building?.city));
                  const location = city
                    ? `${this.translateService.instant(city.name)} - ${building?.name} - ${floor?.name}`
                    : this.translateService.instant('Reservable space not found');

                  const diffStartDays = this.dateService.daysDifference(new Date().toDateString(), startDate);
                  const diffEndDays = this.dateService.daysDifference(
                    new Date().toDateString(),
                    endDate?.substring(0, 10)
                  );
                  let iconCross = false;
                  if (diffStartDays > 0) {
                    iconCross = true;
                  } else {
                    iconCross = diffEndDays <= 0 ? (endDate ? false : true) : true;
                  }
                  const iconEdit = diffEndDays <= 0 ? (endDate ? false : true) : true;

                  const reservationUser = this.users.find(
                    (item) => item.employeeNumber == reservaItem.reservationusersid
                  );
                  const userName = reservationUser?.surname
                    ? reservationUser?.name?.concat(' ').concat(reservationUser?.surname)
                    : reservationUser?.name;

                  const tableItem = new ContinuousReservation({
                    id: reservaItem.id,
                    spaceCode: space.code,
                    status: reservaItem.status,
                    location: location,
                    reservablespacesid: reservaItem.reservablespacesid,
                    reservationusersid: reservaItem.reservationusersid,
                    reservationusersName: userName,
                    startDate: startDate,
                    endDate: endDate,
                    startTime: reservaItem.startTime.substring(0, 5),
                    endTime: reservaItem.endTime.substring(0, 5),
                    iconCross: iconCross,
                    iconEdit: iconEdit,
                  });

                  this.reservasData.push(tableItem);
                  if (this.reservasData.length == reservas.continuousReservations.length) {
                    this.paginateTable(reservas.total);
                  }
                },
                error: () => {
                  const reservationUser = this.users.find(
                    (item) => item.employeeNumber == reservaItem.reservationusersid
                  );
                  const userName = reservationUser?.surname
                    ? reservationUser?.name?.concat(' ').concat(reservationUser?.surname)
                    : reservationUser?.name;

                  const tableItem = new ContinuousReservation({
                    id: reservaItem.id,
                    spaceCode: '--',
                    status: reservaItem.status,
                    reservablespacesid: reservaItem.reservablespacesid,
                    reservationusersid: reservaItem.reservationusersid,
                    reservationusersName: userName,
                    startDate: reservaItem.startDate,
                    endDate: reservaItem.endDate,
                    startTime: reservaItem.startTime.substring(0, 5),
                    endTime: reservaItem.endTime.substring(0, 5),
                    iconCross: false,
                    iconDelete: false,
                    iconEdit: false,
                    location: this.translateService.instant('Reservable space not found'),
                  });

                  this.reservasData.push(tableItem);
                  if (this.reservasData.length == reservas.continuousReservations.length) {
                    this.paginateTable(reservas.total);
                  }
                },
              });
            });
          } else {
            this.resultsLoading(true);
            this.updateTable();
          }
        },
        error: (error) => {
          const infoModalData = new InfoModalData('Error', error.error.reason);
          this.dialog.open(InfoModalComponent, {
            data: infoModalData,
            panelClass: 'custom-dialog',
            disableClose: true,
          });
          this.resultsLoading(false);
          this.noData = true;
          this.haveNextPage = false;
        },
      });
  }
  buildDateUTC(itemDate: string, itemTime: string): string {
    if (itemDate) {
      return itemDate + 'T' + itemTime;
    }
    return null;
  }

  formatDate(date: number): string {
    const timezone = new City(this.cities[0]).timezone;
    return this.dateService.setTimeZone(date, timezone);
  }

  updateTable(): void {
    this.dataSource.data = this.reservasData;
    if (this.searchValue) {
      this.dataSource.filter = this.searchValue;
    }
    this.resultsLoading(false);
  }

  paginateTable(reservasTotal: number): void {
    this.haveNextPage = this.pageSize * this.pageIndex < reservasTotal;
    this.totalReservasData = reservasTotal;
    this.initPaginatorText = 1 + this.pageSize * this.pageIndex - this.pageSize;
    this.endPaginatorText = Math.min(this.totalReservasData, this.pageSize * this.pageIndex);
    this.updateTable();
  }

  goNextPage(): void {
    if (this.haveNextPage) {
      this.pageIndex = this.pageIndex + 1;
      this.loadContinuousReservation();
    }
  }

  goPreviousPage(): void {
    this.pageIndex = this.pageIndex - 1;
    this.loadContinuousReservation();
  }
  resultsLoading(isLoading: boolean): void {
    this.loading = isLoading;
    this.globalService.isCReservationTableLoading(isLoading);
  }

  exportAsExcel(): void {
    this.downloadingExcel = true;
    const filesName = 'Habitat_Continuous_Reservations' + '_' + this.dateService.getDateDownload(new Date());
    this.reservationService
      .getCReservationsExcelExport(this.lang, this.userID, this.code, this.fromDate, this.toDate)
      .subscribe({
        next: (excelFile: Blob) => {
          this.downloadingExcel = false;

          FileSaver.saveAs(excelFile, filesName);
        },
        error: (error) => {
          this.downloadingExcel = false;

          let infoModalData = null;
          this.downloadingExcel = false;
          if (error.status === 409) {
            infoModalData = new InfoModalData('Downloading error', 'Too many records. Please refine your search.');
          } else {
            infoModalData = new InfoModalData(
              'Downloading error',
              'An error has occurred, please try again. If it persists contact the administrator.'
            );
          }
          this.dialog.open(InfoModalComponent, {
            data: infoModalData,
            panelClass: 'custom-dialog',
            disableClose: true,
          });
        },
      });
  }

  editContinuousReservations(continuousReservation): void {
    const dialogRef = this.dialog.open(NewContinuousReserveComponent, {
      data: {
        user: this.userID,
        continuousReservationInfo: continuousReservation,
        canWriteReservations: this.canWriteReservations,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((cancel) => {
      if (!cancel) {
        this.loadContinuousReservation();
      }
    });
  }

  finishContinuousReservations(reservation): void {
    const modalData = new ConfirmModalData(
      'Cancel continuous reservation',
      'Are you sure that you want to cancel this continuous reservation?'
    );
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        this.resultsLoading(true);
        const diffDays = this.dateService.daysDifference(new Date().toDateString(), reservation.startDate);

        if (diffDays <= 0) {
          const continuousReservation = new ContinuousReservation({
            id: reservation.id,
            endDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
          });
          this.reservationService.deleteContinuousReservation(continuousReservation.id).subscribe(
            () => {
              const infoModalData2 = new InfoModalData('', 'Continuous reservation cancel successfully');

              const dialogRef = this.dialog.open(InfoModalComponent, {
                data: infoModalData2,
                panelClass: 'custom-dialog',
                disableClose: true,
              });

              dialogRef.afterClosed().subscribe(() => {
                this.loadContinuousReservation();
              });
            },
            (error: HttpErrorResponse) => {
              let infoModalData2 = new InfoModalData('', '');
              if (error.status == 409) {
                infoModalData2 = new InfoModalData('Canceling error', error.error.reason);
              } else {
                infoModalData2 = new InfoModalData(
                  'Canceling error',
                  'An error has occurred, please try again. If it persists contact the administrator.'
                );
              }

              this.dialog.open(InfoModalComponent, {
                data: infoModalData2,
                panelClass: 'custom-dialog',
                disableClose: true,
              });
            }
          );
        } else {
          this.reservationService.deleteContinuousReservation(reservation.id).subscribe(
            () => {
              const infoModalData2 = new InfoModalData(
                '',
                reservation.iconDelete
                  ? 'Continuous reservation deleted successfully'
                  : 'Continuous reservation cancel successfully'
              );

              const dialogRef = this.dialog.open(InfoModalComponent, {
                data: infoModalData2,
                panelClass: 'custom-dialog',
                disableClose: true,
              });

              dialogRef.afterClosed().subscribe(() => {
                this.loadContinuousReservation();
              });
            },
            (error: HttpErrorResponse) => {
              let infoModalData2 = new InfoModalData('', '');
              if (error.status == 409) {
                infoModalData2 = new InfoModalData('Canceling error', error.error.reason);
              } else {
                infoModalData2 = new InfoModalData(
                  'Canceling error',
                  'An error has occurred, please try again. If it persists contact the administrator.'
                );
              }

              this.dialog.open(InfoModalComponent, {
                data: infoModalData2,
                panelClass: 'custom-dialog',
                disableClose: true,
              });
            }
          );
        }
      }
    });
  }
  sortData(event: Sort) {
    this.sortOrder = event.direction === 'desc' ? 'DESC' : 'ASC';
    this.sortColumn = event.active;
    this.pageIndex = this.INITIAL_PAGE;
    this.loadContinuousReservation();
  }
}
