import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-permission',
  templateUrl: './not-permission.component.html',
  styleUrls: ['./not-permission.component.css'],
})
export class NotPermissionComponent {
  private enableNavigate = true;

  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data) {
    if (data?.enableNavigate !== undefined) {
      this.enableNavigate = data.enableNavigate;
    }
  }

  /**
   * On button click redirect the /realtime (inicio)
   */
  onClick(): void {
    if (this.enableNavigate) {
      this.router.navigate(['/realtime']);
    }
  }
}
