<mat-card class="p-4">
  <mat-card-header>
    <div class="fs-4 text-primary mb-3">{{ 'Auditoría de cambios' | translate }}</div>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="filterFormGroup">
      <!-- FIRST ROW -->
      <div class="row">
        <!-- ENTITY -->
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Entity Name' | translate }}</mat-label>
            <mat-select
              class="py-1"
              id="entity"
              formControlName="entity"
              (selectionChange)="getSelectedEntityFunctionalities()"
            >
              <mat-option *ngFor="let entity of entities" [value]="entity.id">
                {{ entity.name | translate }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="icon-button icon-position" 
            *ngIf="filterFormGroup.value.entity"
            [title]="'Clear selection' | translate"
            (click)="clearField('entity')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
        <!-- FUNCTIONALITY -->
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Functionality Name' | translate }}</mat-label>
            <mat-select class="py-1" id="originFunction" formControlName="originFunction">
              <mat-option *ngFor="let function of originFunctions" [value]="function">
                {{ function.name | translate }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="!isValidFunctionForm">{{ 'Functionality Name is required' | translate }}</mat-error>
            <mat-icon matSuffix class="icon-button icon-position" 
            *ngIf="filterFormGroup.value.originFunction"
            [title]="'Clear selection' | translate"
            (click)="clearField('originFunction')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
        <!-- NAME -->
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input
              title="{{ 'Name' | translate }}"
              matInput
              autocomplete="off"
              id="field"
              formControlName="field"
              type="text"
              class="py-1"
              spellcheck="false"
            />
            <mat-icon matSuffix class="icon-button" 
            *ngIf="filterFormGroup.value.field"
            [title]="'Clear selection' | translate"
            (click)="clearField('field')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
      </div>

      <!-- SECOND ROW -->
      <div class="row">
        <!-- FILTER USER INPUT NGX-->
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'User' | translate }}</mat-label>
            <mat-select
              id="user"
              formControlName="user"
              class="input-form-special py-1"
              [ngClass]="{ 'is-invalid': f.user.errors }"
              ngxMatSelect
              #ms="ngxMatSelect"
              [searcher]="searcher"
              [displayMember]="'nameComplete'"
              [valueMember]="'sso'"
              [useInfiniteScroll]="true"
              [source]="users"
              [lazyLoad]="true"
              searchBoxPlaceholder="{{ 'Search' | translate }}"
              emptyLabel="{{ 'No user found' | translate }}"
              noMoreResultLabel="{{ 'No more found' | translate }}"
              [hasSearchBox]="true"
            >
              <mat-option *ngFor="let user of ms.filteredSource" [value]="user.sso">
                {{ user.nameComplete | translate }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="icon-button icon-position" 
            *ngIf="filterFormGroup.value.user"
            [title]="'Clear selection' | translate"
            (click)="clearField('user')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
        <!-- PREVIOUS VALUE -->
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Previous value' | translate }}</mat-label>
            <input
              title="{{ 'Previous value' | translate }}"
              matInput
              autocomplete="off"
              id="lastValue"
              formControlName="lastValue"
              type="text"
              class="py-1"
              spellcheck="false"
            />
            <mat-icon matSuffix class="icon-button " 
            *ngIf="filterFormGroup.value.lastValue"
            [title]="'Clear selection' | translate"
            (click)="clearField('lastValue')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
        <!-- NEW VALUE -->
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'New value' | translate }}</mat-label>
            <input
              title="{{ 'New value' | translate }}"
              matInput
              autocomplete="off"
              id="newValue"
              formControlName="newValue"
              type="text"
              class="py-1"
              spellcheck="false"
            />
            <mat-icon matSuffix class="icon-button " 
            *ngIf="filterFormGroup.value.newValue"
            [title]="'Clear selection' | translate"
            (click)="clearField('newValue')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
      </div>

      <!--DATE RANGE-->
      <div class="row">
        <div class="col-4">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Date Range' | translate }}</mat-label>
            <input
              title="{{ 'Date Range' | translate }}"
              matInput
              autocomplete="off"
              id="dateRange"
              formControlName="dateRange"
              type="text"
              class="py-1"
              spellcheck="false"
              (click)="op.toggle($event)"
              readonly
            />
            <mat-icon matSuffix class="icon-button" 
            *ngIf="filterFormGroup.value.dateRange"
            [title]="'Clear selection' | translate"
            (click)="clearField('dateRange')">
            highlight_off
          </mat-icon>
          </mat-form-field>
          
        </div>
      </div>

      <!-- SEARCH BUTTON -->
      <div class="d-flex justify-content-center">
        <button
          mat-button
          color="primary"
          (click)="onSearch()"
          [disabled]="!filterFormGroup.valid || resultsTableLoading"
        >
          {{ 'Search' | translate }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>

<!-- CALENDAR PANEL -->
<p-overlayPanel #op>
  <ng-template pTemplate>
    <div class="row" id="calendario" class="p-calendar-padding">
      <div class="calendar-header">
        <div class="d-flex flex-column align-items-center p-1 col">
          <strong> {{ 'Start' | translate }}</strong>
          <small>{{ startTime | date : 'dd/MM/yyyy HH:mm' }}</small>
        </div>

        <div class="d-flex flex-column align-items-center p-1 col">
          <strong> {{ 'End' | translate }}</strong>
          <small>{{ endTime | date : 'dd/MM/yyyy HH:mm' }}</small>
        </div>
      </div>
      <div class="d-flex">
        <div class="px-1">
          <p-calendar
            [(ngModel)]="startTime"
            [showTime]="true"
            inputId="time"
            [inline]="true"
            [maxDate]="endTime"
            [showOtherMonths]="false"
          ></p-calendar>
        </div>

        <div class="px-1">
          <p-calendar
            [(ngModel)]="endTime"
            [showTime]="true"
            inputId="time"
            [inline]="true"
            [minDate]="startTime"
            [showOtherMonths]="false"
          ></p-calendar>
        </div>
      </div>
    </div>

    <div class="buttonsRow">
      <button mat-button class="modalCancel text-primary" (click)="op.hide($event)" (click)="cleanRangeField()">
        {{ 'Cancel' | translate }}
      </button>
      <button mat-button class="modalButton bg-primary" (click)="confirmRange()" (click)="op.hide($event)">
        {{ 'accept' | translate }}
      </button>
    </div>
  </ng-template>
</p-overlayPanel>
