import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Space } from 'src/app/models/esite/Space.class';
import { SpaceType } from 'src/app/models/esite/SpaceType.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { GlobalService } from 'src/app/services/common/global.service';

import { EsiteService } from '../backend/esite.service';
import { HabitatService } from '../backend/habitat.service';
import { ReservationService } from '../backend/reservation.service';
import { StorageVariables } from './../../models/StorageVariables.enum';

@Injectable({
  providedIn: 'root',
})
export class SpaceConfigurationService {
  building: Building = null;
  buildingChanged = new BehaviorSubject<Building>(this.building);

  floor: Floor = null;
  space: Space = null;
  floorChanged = new BehaviorSubject<Floor>(this.floor);
  editedSpace = new BehaviorSubject<Space>(this.space);
  deletedSpace = new BehaviorSubject<Space>(this.space);
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  spaceTypes: SpaceType[] = [];
  constructor(
    private habitatService: HabitatService,
    private esiteService: EsiteService,
    private reservationService: ReservationService,
    private globalService: GlobalService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.hasReservationsModule = this.globalService.getReservationModule();
  }

  setCurrentBuilding(building: Building): void {
    this.building = building;
    this.buildingChanged.next(building);
    this.floor = null;
  }

  setCurrentFloor(floor: Floor): void {
    this.floor = floor;
    if (this.hasEsiteModule) {
      this.esiteService.getSpaceTypes().subscribe((types) => {
        this.spaceTypes = types;
        if (this.floor) {
          this.esiteService.getSpacesByFloor(this.floor.id, { resources: true }).subscribe((spaces) => {
            if (this.hasReservationsModule && this.floor) {
              this.reservationService
                .getReservableSpaces(this.floor.id)
                .subscribe((reservations: ReservationSpace[]) => {
                  this.setFloorSpaces(spaces, types);
                  if (this.floor) {
                    this.floor.reservations = reservations;
                    this.floor.reservations.forEach((reservation) => {
                      reservation.typeName = types.find((item) => item.id === reservation.type).name;
                    });
                    this.setFloorImage(floor);
                  }
                });
            } else {
              this.setFloorSpaces(spaces, types);
              this.setFloorImage(floor);
            }
          });
        }
      });
    } else {
      this.setFloorImage(floor);
    }
  }

  getCurrentFloor(): Floor {
    return this.floor;
  }

  setEditedSpace(space: Space): void {
    space.typeName = this.spaceTypes.find((item) => item.id === space.type).name;
    this.editedSpace.next(space);
  }
  setDeletedSpace(space: Space): void {
    this.deletedSpace.next(space);
  }

  setFloorSpaces(spaces, types): void {
    if (this.floor) {
      this.floor.spaces = spaces;
      this.floor.spaces.forEach((space) => {
        const foundType = types.find((item) => item.id === space.type);
        space.typeName = foundType.name;
        space.useType = foundType.useType;
        space.typeName = types.find((item) => item.id === space.type).name;
      });
    }
  }

  setFloorImage(floor): void {
    const floorsImages = JSON.parse(sessionStorage.getItem(StorageVariables.FLOOR_IMAGE)) || [];
    const thisfloorData = floorsImages?.find((item) => item.floor === floor.id);
    if (thisfloorData) {
      floor.img = thisfloorData.image;
      this.floorChanged.next(floor);
    } else {
      this.habitatService.getFloorImage(floor.id).subscribe({
        next: (floorImage) => {
          const reader = new FileReader();
          reader.addEventListener(
            'load',
            () => {
              const floorImageItem = {
                floor: floor.id,
                image: reader.result,
              };
              if (floorsImages.length > 2) {
                floorsImages.shift();
              }
              floorsImages.push(floorImageItem);
              sessionStorage.setItem(StorageVariables.FLOOR_IMAGE, JSON.stringify(floorsImages));
            },
            false
          );
          if (floorImage) {
            reader.readAsDataURL(floorImage);
          }
          floor.img = floorImage;
          this.floorChanged.next(floor);
        },
        error: () => {
          floor.img = null;
          this.floorChanged.next(floor);
        },
      });
    }
  }
}
