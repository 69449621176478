import { SvgmapHeatMapPoint } from './SvgmapHeatMapPoint.class';
import { SvgmapPoint } from './SvgmapPoint.class';
import { SvgmapPolygon } from './SvgmapPolygon.class';

export class SvgmapData {
  img: Blob;
  points: SvgmapPoint[];
  polygons: SvgmapPolygon[];
  heatMapPoints: SvgmapHeatMapPoint[];
  pointsXY: Map<string, SvgmapPoint[]>; // points same coordinates (x,y)

  constructor() {
    this.points = [];
    this.polygons = [];
    this.heatMapPoints = [];
    this.pointsXY = new Map<string, SvgmapPoint[]>();
  }
}
