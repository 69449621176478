import { Permission, PermissionBuilding, RolePermissionBuilding } from './Role.class';

export class User {
  id?: number;
  name: string;
  surname: string;
  nameComplete?: string;
  email: string;
  roles?: RolePermissionBuilding[]; // Inside RolePermissionBuilding Object, Role's and Permission's building.
  rolesName?: string[];
  permissions?: Permission[]; // Default permissions that apply to all the buildings of the logged in user.
  permissionBuilding?: PermissionBuilding[]; // Permission's each building
  hidden?: boolean;
  employeeNumber?: string;
  mainGroup?: number = null;
  mainBuilding?: number = null;
  mainFloor?: number = null;
  mainCity?: number = null;
  department?: string = null;
  buildings? = [];
  sso?: string;

  constructor(msg?) {
    this.id = msg?.id;
    this.name = msg?.name;
    this.surname = msg?.surname;
    this.nameComplete = msg?.surname ? msg?.name.concat(' ').concat(msg?.surname) : msg?.name;
    this.email = msg?.email;
    this.hidden = msg?.hidden;
    this.employeeNumber = msg?.employeeNumber || msg?.sso;
    this.mainGroup = msg?.mainGroup;
    this.mainBuilding = msg?.mainBuilding;
    this.mainFloor = msg?.mainFloor;
    this.mainCity = msg?.mainCity;
    this.roles = msg?.roles;
    this.rolesName = [...new Set(msg?.roles?.map((item) => item.role.name) as string[])];
    this.permissions = msg?.permissions || [];
    this.buildings = msg?.buildings || [];
    this.department = msg?.department || null;
    this.sso = msg?.sso || msg?.employeeNumber || null;
  }

  toBackendFormat() {
    return {
      name: this.name,
      surname: this.surname,
      email: this.email,
      employeeNumber: this.employeeNumber,
      department: this.department,
      mainGroup: this.mainGroup,
      mainCity: this.mainCity,
      mainBuilding: this.mainBuilding,
      mainFloor: this.mainFloor,
      sso: this.sso,
    };
  }

  toBackenEditFormat() {
    return {
      name: this.name,
      surname: this.surname,
      email: this.email,
      employeeNumber: this.employeeNumber,
      department: this.department,
      mainGroup: this.mainGroup,
      mainCity: this.mainCity, // TODO: Check if this is necessary
      mainBuilding: this.mainBuilding,
      mainFloor: this.mainFloor,
      sso: this.sso,
    };
  }

  toProfileObject() {
    return {
      name: this.name,
      surname: this.surname,
      email: this.email,
      employeeNumber: this.employeeNumber || this.sso,
    };
  }

  toPreferencesObject() {
    return {
      mainGroup: this.mainGroup,
      mainCity: this.mainCity,
      mainBuilding: this.mainBuilding,
      mainFloor: this.mainFloor,
      department: this.department,
    };
  }
}
