import { AfterViewInit, Component, Input, SimpleChanges } from '@angular/core';
//FIXME: Avoid registering of every single element of Chart.js. Change npm-integration to angular integration ng2-charts library
import { Chart, ChartItem, registerables } from 'chart.js';
import { ChartOptionData } from 'src/app/models/charts/ChartOptionData.class';
import { ChartService } from 'src/app/services/common/chart.service';
Chart.register(...registerables);

@Component({
  selector: 'app-chart-donut',
  templateUrl: './chart-donut.component.html',
  styleUrls: ['./chart-donut.component.scss'],
})
export class ChartDonutComponent implements AfterViewInit {
  @Input() chartIdentifier = { id: 0, name: '', msgEmpty: '' };
  @Input() inputChartData: ChartOptionData[];
  loading = true;
  legendItems: ChartOptionData[] = [];
  chart: Chart;
  chartData = { datasets: [], labels: [] };
  sizeData = 0;

  constructor(private chartService: ChartService) {}

  ngAfterViewInit(): void {
    if (!this.chart) {
      this.chart = this.createChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputChartData) {
      const data = changes.inputChartData.currentValue;
      if (data) {
        this.loading = false;
        this.sizeData = data.length;
        const creataDataEstructure = this.chartService.creataDataEstructure(data);
        this.chartData.datasets = creataDataEstructure.datasets;
        this.chartData.labels = creataDataEstructure.labels;
        this.legendItems = creataDataEstructure.legend;
        if (this.chart) {
          this.chart.update();
        } else {
          this.chart = this.createChart();
        }
      } else {
        this.loading = true;
      }
    }
  }

  createChart(): any {
    const ctx = document.getElementById('chartCanvas' + this.chartIdentifier.id) as ChartItem;
    if (ctx) {
      return new Chart(ctx, {
        type: 'doughnut',
        data: this.chartData,
        options: {
          animation: { duration: 0 },
          responsive: true,
          hover: { mode: null },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: (context) => {
                  let label = context.label || '';

                  if (label) {
                    label += ':';
                  }

                  return label;
                },
                afterLabel: (context) => {
                  let label = context.raw.toString() || '';

                  if (label) {
                    label += '%';
                  }

                  return label;
                },
              },
            },
          },
        },
      });
    } else {
      return null;
    }
  }
}
