import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { OccupationNotificationConfig } from 'src/app/models/notifications/OccupationNotificationConfig.class';
import { ProximityNotificationConfig } from 'src/app/models/notifications/ProximityNotificationConfig.class';
import { ConteoService } from 'src/app/services/backend/conteo.service';
import { EsiteService } from 'src/app/services/backend/esite.service';
import { GlobalService } from 'src/app/services/common/global.service';

import { NotificationFormComponent } from './notification-form/notification-form.component';

@Component({
  selector: 'app-notifications-config',
  templateUrl: './notifications-config.component.html',
  styleUrls: ['../profile.component.scss'],
})
export class NotificationsConfigComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;
  loading = true;
  @ViewChild('alarmDetail') alarmDetail;
  isShowForm = false;
  zones = [];
  hours = [];
  alarmSubtypes = [];
  userNotificationsData = [];
  currentAlarm;
  subcriptions: Subscription[] = [];
  dialogRef: MatDialogRef<any>;
  proximityAlarm: ProximityNotificationConfig = new ProximityNotificationConfig('');
  hasEsiteModule = false;
  hasConteoModule = false;
  hasEsiteDevicesModule = false;
  disabledDetail = false;

  constructor(
    private conteoService: ConteoService,
    private esiteService: EsiteService,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.hasEsiteDevicesModule = this.globalService.getEsiteDevice();
  }

  ngOnInit(): void {
    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
    });

    if (this.hasEsiteDevicesModule && this.hasConteoModule) {
      this.subcriptions.push(
        forkJoin({
          esite: this.esiteService.getProximityAlarm(),
          conteo: this.conteoService.getConfigAlarms(),
        }).subscribe((result) => {
          if (result.esite?.length > 0) {
            this.proximityAlarm = new ProximityNotificationConfig(result.esite[0]);
          }
          result.conteo.forEach((config) => {
            const configAlarm = new OccupationNotificationConfig(config);
            this.userNotificationsData.push(configAlarm);
          });
          this.loading = false;
        })
      );
    } else if (this.hasEsiteDevicesModule) {
      this.subcriptions.push(
        this.esiteService.getProximityAlarm().subscribe((response) => {
          if (response?.length > 0) {
            this.proximityAlarm = new ProximityNotificationConfig(response[0]);
          }
          this.loading = false;
        })
      );
    } else if (this.hasConteoModule) {
      this.subcriptions.push(
        this.conteoService.getConfigAlarms().subscribe((response) => {
          response.forEach((config) => {
            const configAlarm = new OccupationNotificationConfig(config);
            this.userNotificationsData.push(configAlarm);
          });
          this.loading = false;
        })
      );
    }
  }

  toggleNotification(notificationId, checked): void {
    this.subcriptions.push(this.conteoService.modifyActiveFlagConfigAlarm(notificationId, checked).subscribe());
  }

  toggleProximityAlarm(checked): void {
    if (!checked && this.proximityAlarm.id) {
      this.subcriptions.push(this.esiteService.deleteProximityAlarm(this.proximityAlarm.id).subscribe());
      this.globalService.setProximityNotificationConfig([]);
    } else {
      this.subcriptions.push(
        this.esiteService.addProximityAlarm().subscribe((alarm) => {
          this.proximityAlarm.id = alarm.id;
          this.globalService.setProximityNotificationConfig([alarm]);
        })
      );
    }
  }

  deleteAlarm(notificationId): void {
    const modalData = new ConfirmModalData('DeleteNotification', 'InfoDeleteNotification');
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        this.subcriptions.push(
          this.conteoService.deleteConfigAlarm(notificationId).subscribe((resp) => {
            const index = this.userNotificationsData
              .map(function (item) {
                return item.id;
              })
              .indexOf(notificationId);
            if (index > -1) {
              this.userNotificationsData.splice(index, 1);
            }
          })
        );
      }
    });
  }

  openModal(): void {
    const dialogRef = this.dialog.open(NotificationFormComponent, { panelClass: 'custom-dialog', disableClose: true });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.userNotificationsData.push(result.data);
      }
    });
  }

  openAlarmDetail(notification): void {
    if (!this.disabledDetail) {
      this.disabledDetail = true;
      this.currentAlarm = notification;
      this.subcriptions.push(
        this.conteoService.getZone(notification.zone).subscribe((zone) => {
          this.currentAlarm.zoneName = zone?.name;
          this.dialogRef = this.dialog.open(this.alarmDetail, { panelClass: 'custom-dialog', disableClose: true });
          this.dialogRef.afterClosed().subscribe(() => {
            this.disabledDetail = false;
          });
        })
      );
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }
}
