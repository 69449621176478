<mat-card
  [ngStyle]="{
    width: hierarchy ? 'auto' : '450px',
    'padding-left': hierarchy ? '24px' : '0px'
  }"
>
  <mat-card-content class="marginBodyCard" style="padding-left: 0px">
    <label class="titleN3 text-primary modalTitle" style="padding-left: 1.25rem">{{ title | translate }}</label>
    <div style="text-align: center">
      <label>{{ message | translate }}</label>
    </div>

    <!-- TREE NODE-->
    <div *ngIf="hierarchy">
      <div *ngIf="!loading">
        <p-tree [value]="treeNode" layout="horizontal" selectionMode="single" [(selection)]="selectedItemTree"></p-tree>
      </div>
      <div *ngIf="loading">
        <app-loading></app-loading>
      </div>
    </div>

    <div class="buttonsRow">
      <button mat-button class="modalCancel text-primary" (click)="cancel()">{{ 'Aceptar' | translate }}</button>
    </div>
  </mat-card-content>
</mat-card>
