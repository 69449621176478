import { Zone } from '../conteo/Zone.class';
import { Area } from '../esite/Area.class';

export class SvgmapPolygon {
  code: number;
  name: string;
  position: string;
  typeName: string;
  color: string;
  building: string;
  floor: string;
  occupation: string;

  constructor(data) {
    if (data instanceof Area) {
      this.code = data.id;
      this.name = data.name;
      this.position = data.path;
      this.color = '#0075AA';
    } else if (data instanceof Zone) {
      this.code = data.id;
      this.name = data.name;
      this.position = data.graphicalInfo;
      this.color = '#ffd100';
      this.occupation = data.occupation + '/' + data.maxOccupation + ' (' + data.getPercentage() + '%)';
    }
  }
}
