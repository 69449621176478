import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { retry } from 'rxjs/operators';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { Constants } from 'src/app/models/Constants.const';
import { Zone } from 'src/app/models/conteo/Zone.class';
import { HttpMethods } from 'src/app/models/HttpMethods.enum';
import { WebsocketMsg } from 'src/app/models/websocket/WebsocketMsg.class';

import { environment } from '../../../environments/environment';
import { HttpService } from '../common/http.service';

@Injectable({
  providedIn: 'root',
})
export class ConteoService {
  private socket: WebSocketSubject<WebsocketMsg>;
  keepaliveTimerId: NodeJS.Timeout;

  constructor(private httpService: HttpService) {
    const url = `${environment.websocket_protocol}${environment.conteo_backend_url}v2/realTime`;
    const websocketSubjectConfig = environment.websocket_keepalive
      ? {
          url: url,
          openObserver: {
            next: () => {
              this.keepaliveTimerId = setInterval(() => {
                this.socket.next({
                  type: Constants.WEBSOCKET_KEEPALIVE_TYPE,
                  data: Constants.WEBSOCKET_KEEPALIVE_MESSAGE,
                });
              }, environment.websocket_keepalive_interval);
            },
          },
          closeObserver: {
            next() {
              clearInterval(this.keepaliveTimerId);
            },
          },
        }
      : { url: url };

    this.socket = webSocket(websocketSubjectConfig);
  }

  isConteoDeployed(): boolean {
    const url = `${environment.conteo_backend_url}`;
    return url.length > 0;
  }

  requestToConteo(type, method, body?, headers?): Observable<any> {
    const url = `${environment.conteo_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequest(protocol + url, type, method, body, headers);
  }

  connectToWebsocket() {
    return this.socket.pipe(retry(5));
  }

  getZones(): Observable<any> {
    const method = 'v2/zones';
    return this.requestToConteo(HttpMethods.GET, method);
  }

  getZone(zoneId: number): Observable<any> {
    const method = 'v2/zones/' + zoneId;
    return this.requestToConteo(HttpMethods.GET, method);
  }

  getZonesByFloor(floorID: number): Observable<any> {
    const method = 'v2/zones?floor=' + floorID;
    return this.requestToConteo(HttpMethods.GET, method);
  }

  insertZone(zone: Zone, floorId: number, functionality: string = AuditFunctionalities.CreateZone): Observable<any> {
    const method = 'v2/zones';
    const headers = new HttpHeaders();
    // headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToConteo(HttpMethods.POST, method, zone.toBackenFormat(floorId), headers);
  }

  updateZone(zone: Zone, functionality: string = AuditFunctionalities.ModifyZone): Observable<any> {
    const method = 'v2/zones/' + zone.id;
    const body = { name: zone.name, maxOccupation: zone.maxOccupation };
    const headers = new HttpHeaders();
    // headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToConteo(HttpMethods.PATCH, method, body, headers);
  }

  getTriggeredAlarms(date, page, perPage): Observable<any> {
    const method = 'v2/user/occupationAlarms?start=' + date + '&orderBy=date&page=' + page + '&perPage=' + perPage;
    return this.requestToConteo(HttpMethods.GET, method);
  }

  getConfigAlarms(): Observable<any> {
    const method = 'v2/user/configuredAlarms';
    return this.requestToConteo(HttpMethods.GET, method);
  }

  getConteoAlarm(configAlarmId: string): Observable<any> {
    const method = 'v2/user/configuredAlarms/' + configAlarmId;
    return this.requestToConteo(HttpMethods.GET, method);
  }

  insertConfigAlarm(config, functionality: string = AuditFunctionalities.CreateOccupancyAlarm): Observable<any> {
    const method = 'v2/user/configuredAlarms';
    const headers = new HttpHeaders();
    // headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToConteo(HttpMethods.POST, method, config, headers);
  }

  modifyActiveFlagConfigAlarm(alarmId: number, flag: boolean): Observable<any> {
    const method = 'v2/user/configuredAlarms/' + alarmId;
    const body = { active: flag };
    return this.requestToConteo(HttpMethods.PATCH, method, body);
  }

  updateLastDisplayDate(configId, date): Observable<any> {
    const method = 'v2/user/configuredAlarms/' + configId;
    const body = { lastReadDate: date };
    return this.requestToConteo(HttpMethods.PATCH, method, body);
  }

  deleteConfigAlarm(
    alarmId: number,
    functionality: string = AuditFunctionalities.DeleteOccupancyAlarm
  ): Observable<any> {
    const method = 'v2/user/configuredAlarms/' + alarmId;
    const headers = new HttpHeaders();
    //  headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToConteo(HttpMethods.DELETE, method, {}, headers);
  }

  getHumanTrackingCoordinates(floorId: number, startDate, endDate): Observable<any> {
    const method = 'v2/humanTrackingData?floor=' + floorId + '&start=' + startDate + '&end=' + endDate;
    return this.requestToConteo(HttpMethods.GET, method);
  }

  deleteZone(zoneId: number, functionality: string = AuditFunctionalities.DeleteZone): Observable<void> {
    const method = 'v2/zones/' + zoneId;
    const headers = new HttpHeaders();
    // headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToConteo(HttpMethods.DELETE, method, {}, headers);
  }
}
