<div class="m-3">
  <div class="d-flex justify-content-between align-items-center">
    <div class="titleN3">
      {{ 'NotificationsConfigured' | translate }}
    </div>
    <button mat-button *ngIf="hasConteoModule" (click)="openModal()">
      <span class="smallIcon bi bi-plus-circle"></span>
      <span class="buttonLabel">{{ 'NewNotification' | translate }}</span>
    </button>
  </div>
  <div class="line mt-2 mb-4"></div>
  <div
    *ngIf="loading"
    [ngStyle]="{ height: !hasConteoModule ? '56px' : '200px' }"
    class="d-flex align-items-center justify-content-center"
  >
    <app-loading></app-loading>
  </div>
  <div *ngIf="!loading">
    <div *ngIf="!responsive">
      <div *ngIf="hasEsiteModule && hasEsiteDevicesModule" class="row separateDiv">
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 row" style="height: 56px">
          <div class="col-md-1"><img src="./assets/images/bars.svg" alt="" /></div>
          <div class="col-md-7">
            <span>{{ 'ProximityAlarm' | translate }} </span>
          </div>
          <div class="col-md-1">
            <mat-slide-toggle
              style="float: left"
              [(checked)]="proximityAlarm.active"
              (change)="toggleProximityAlarm($event.checked)"
            >
            </mat-slide-toggle>
          </div>
          <div class="col-md-3"></div>
        </div>
      </div>
      <div *ngIf="hasConteoModule">
        <div *ngFor="let notification of userNotificationsData" class="row separateDiv">
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 row" style="height: 56px">
            <div class="col-md-1"><img src="./assets/images/bars.svg" alt="" /></div>
            <div class="col-md-7" style="overflow-wrap: break-word">
              <span>{{ notification?.name }} </span>
            </div>
            <div class="col-md-1">
              <mat-slide-toggle
                style="float: left"
                [(checked)]="notification.active"
                (change)="toggleNotification(notification.id, $event.checked)"
              >
              </mat-slide-toggle>
            </div>
            <div class="col-md-3">
              <span
                class="smallIcon bi bi-eye me-2"
                role="button"
                (click)="openAlarmDetail(notification)"
              ></span>
              <span
                class="smallIcon bi bi-trash"
                role="button"
                (click)="deleteAlarm(notification.id)"
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="responsive">
      <div *ngIf="hasEsiteModule && hasEsiteDevicesModule" class="row separateDiv">
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 row" style="height: 56px">
          <div class="col-1"><img src="./assets/images/bars.svg" alt="" /></div>
          <div class="col-9">
            <span>{{ 'ProximityAlarm' | translate }} </span>
          </div>
          <div class="col-1">
            <mat-slide-toggle
              style="float: left"
              [(checked)]="proximityAlarm.active"
              (change)="toggleProximityAlarm($event.checked)"
            >
            </mat-slide-toggle>
          </div>
        </div>
      </div>
      <div *ngIf="hasConteoModule">
        <div *ngFor="let notification of userNotificationsData" class="row separateDiv">
          <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 row" style="height: 56px">
            <div class="col-1"><img src="./assets/images/bars.svg" alt="" /></div>
            <div class="col-6">
              <span>{{ notification?.name }} </span>
            </div>
  
            <div class="col-4" style="margin-right: -38px; margin-left: 6px">
              <span
                class="smallIcon bi bi-eye me-2"
                role="button"
                (click)="openAlarmDetail(notification)"
              ></span>
              <span
                class="smallIcon bi bi-trash"
                role="button"
                (click)="deleteAlarm(notification.id)"
              ></span>
            </div>
            <div class="col-1" style="margin-left: 10px">
              <mat-slide-toggle
                style="float: left"
                [(checked)]="notification.active"
                (change)="toggleNotification(notification.id, $event.checked)"
              >
              </mat-slide-toggle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #alarmDetail>
  <mat-card *ngIf="!responsive" style="width: 400px">
    <mat-card-content class="marginBodyCard">
      <label class="titleN3 text-primary modalTitle">
        {{ 'Notification' | translate }}{{ ': ' + currentAlarm.name }}</label
      >
      <div>
        <div *ngIf="currentAlarm" class="row" style="margin-top: 10px">
          <div class="row col-12 py-1">
            <div class="titletd col-2 ml-3">{{ 'Zone' | translate }}</div>
            <div class="titletd1 col-8">{{ currentAlarm.zoneName }}</div>
          </div>
          <div class="row col-12 py-1">
            <div class="titletd col-2 ml-3">{{ 'Type' | translate }}</div>
            <div class="titletd1 col-8">{{ currentAlarm.subtype | translate }}</div>
          </div>
          <div class="row col-12 py-1">
            <div class="titletd col-2 ml-3">{{ 'Limit' | translate }}</div>
            <div class="titletd1 col-8">{{ currentAlarm.occupancyRate }}</div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
            <button mat-button class="modalCancel text-primary" (click)="cancel()">{{ 'Close' | translate }}</button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card *ngIf="responsive" style="width: 300px">
    <mat-card-content class="marginBodyCard">
      <label class="titleN3 text-primary modalTitle">
        {{ 'Notification' | translate }}{{ ': ' + currentAlarm.name }}</label
      >
      <div>
        <div *ngIf="currentAlarm" class="row" style="margin-top: 10px">
          <div class="row col-12 py-1">
            <div class="titletd col-2 ml-3">{{ 'Zone' | translate }}</div>
            <div class="titletd1 col-8" style="margin-left: 15px">{{ currentAlarm.zoneName }}</div>
          </div>
          <div class="row col-12 py-1">
            <div class="titletd col-2 ml-3">{{ 'Type' | translate }}</div>
            <div class="titletd1 col-8" style="margin-left: 15px">{{ currentAlarm.subtype | translate }}</div>
          </div>
          <div class="row col-12 py-1">
            <div class="titletd col-2 ml-3">{{ 'Limit' | translate }}</div>
            <div class="titletd1 col-8" style="margin-left: 15px">{{ currentAlarm.occupancyRate }}</div>
          </div>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
            <button mat-button class="modalCancel text-primary" (click)="cancel()">{{ 'Close' | translate }}</button>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
