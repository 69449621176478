<mat-card class="p-4">
  <mat-card-header>
    <div class="fs-4 text-primary mb-3">{{ 'Configure roles' | translate }}</div>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="loading" style="min-height: 350px; display: flex; align-items: center; justify-content: center">
      <app-loading></app-loading>
    </div>
    <div [hidden]="loading">
      <!-- matriz roles / permisos -->
      <div fxLayout="row wrap">
        <div fxFlex.gt-sm="100%">
          <mat-grid-list cols="{{ nColumnsTotal }}" rowHeight="100px">
            <mat-grid-tile
              *ngFor="let tile of tiles"
              [colspan]="tile.cols"
              [rowspan]="tile.rows"
              [style.background]="tile.color"
              style="overflow: auto; width: 100%"
              (scroll)="updateScroll($event)"
              name="{{ tile.text }}Scroll"
              id="{{ tile.text }}Scroll"
              #{{tile.text}}Scroll
            >
              <div
                style="overflow: auto; width: 100%"
                (scroll)="updateScrollExterno($event)"
                name="{{ tile.text }}ScrollExterno"
                id="{{ tile.text }}ScrollExterno"
                #{{tile.text}}ScrollExterno
              >
                <!-- SELECT ALL-->
                <div *ngIf="tile.text === 'todos'" style="display: inline-flex; flex-direction: column">
                  <div
                    [style]="{
                      width: '150px',
                      'margin-top': '240px',
                      'text-align': 'center',
                      padding: cssGrid,
                      'margin-bottom': '30px',
                      'border-radius': '5px',
                      color: 'rgb(255, 255, 255)',
                      'background-color': '#0075aa'
                    }"
                  >
                    <span>Roles</span>
                  </div>
                </div>
                <!-- PERMISSIONS COLUMNS-->
                <div
                  *ngIf="tile.text === 'nPermissions'"
                  [style]="{
                    'display': 'table',
                    'width': isFirefox ? '100%' : auto
                  }"
                >
                  <div
                    [style]="{
                      'text-align': 'left',
                      padding: cssGrid,
                      'margin-bottom': '30px',
                      'border-radius': '5px',
                      color: 'rgb(255, 255, 255)',
                      'background-color': '#0075aa'
                    }"
                  >
                    <span style="margin-left: 10px">{{ 'permissions' | translate }}</span>
                  </div>
                  <div
                    [style]="{
                      display: 'grid',
                      'grid-gap': '9px',
                      'writing-mode': 'vertical-rl',
                      transform: 'rotate(180deg)',
                      'grid-template-columns': 'fit-content(20%)'
                    }"
                  >
                    <div *ngFor="let element of nPermissions; let i = index" style="display: inline-flex">
                      <!-- MAT-CHECKBOX PERMISOS -->
                      <p-triStateCheckbox
                        [(ngModel)]="element.value"
                        [disabled]="!havePermissionWrite"
                        (onChange)="valueChange($event, element, 'permission')"
                        tabindex="{{ i }}-permission"
                        inputId="{{ i }}-permission"
                        name="{{ i }}-permission"
                        checkboxFalseIcon="pi pi-minus"
                        [style]="{
                          'writing-mode': 'vertical-rl',
                          transform: 'rotate(180deg)'
                        }"
                      ></p-triStateCheckbox>
                      <span
                        style="
                          margin-right: 0px;
                          margin-top: 5px;
                          display: block;
                          white-space: nowrap;
                          overflow: hidden;
                          text-overflow: ellipsis;
                        "
                      >
                        {{ element.name }}
                      </span>
                    </div>
                  </div>
                </div>
                <!-- ROLES ROWS -->
                <div *ngIf="tile.text === 'nRoles'" style="display: table">
                  <div
                    [style]="{
                      display: 'grid',
                      'grid-gap': '7px',
                      'grid-auto-flow': 'row',
                      'grid-template-columns': 'fit-content(20%)'
                    }"
                  >
                    <div *ngFor="let element of nRoles; let i = index" style="display: inline-flex">
                      <div style="width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden">
                        <span title="{{ element.name }}">
                          {{ element.name }}
                        </span>
                      </div>
                      <!-- MAT-CHECKBOX ROLES -->
                      <p-triStateCheckbox
                        [(ngModel)]="element.value"
                        [disabled]="!havePermissionWrite"
                        (onChange)="valueChange($event, element, 'role')"
                        tabindex="{{ i }}-role"
                        inputId="{{ i }}-role"
                        name="{{ i }}-role"
                        checkboxFalseIcon="pi pi-minus"
                      ></p-triStateCheckbox>
                      <span
                        *ngIf="havePermissionWrite"
                        name="{{ i }}-role"
                        class="smallIcon bi bi-trash ps-2"
                        role="button"
                        [title]="'Delete role' | translate"
                        (click)="deleteRole(element)"
                      ></span>
                    </div>
                  </div>
                </div>
                <!-- MATRIX -->
                <div *ngIf="tile.text === 'matrix'">
                  <div
                    [style]="{
                      'max-width': cssGridMatrix,
                      display: 'grid',
                      'grid-gap': cssGridMatrix,
                      'grid-template-columns': templateColumnsMatrix
                    }"
                  >
                    <div *ngFor="let element of nMatrix; let i = index" style="display: inline-flex">
                      <!-- MAT-CHECKBOX MATRIX -->
                      <p-triStateCheckbox
                        [(ngModel)]="element.value"
                        [disabled]="!havePermissionWrite"
                        (onChange)="valueChange($event, element, 'matrix')"
                        tabindex="{{ i }}-matrix"
                        inputId="{{ i }}-matrix"
                        name="{{ i }}-matrix"
                        checkboxFalseIcon="pi pi-minus"
                      ></p-triStateCheckbox>
                    </div>
                  </div>
                </div>
              </div>
            </mat-grid-tile>
          </mat-grid-list>
        </div>
      </div>

      <div class="d-flex gap-3">
        <p-tag
          styleClass="p-mr-2"
          icon="pi pi-stop"
          severity="info"
          value="{{ 'NONE' | translate }}"
        ></p-tag>
        <p-tag styleClass="p-mr-2" icon="pi pi-minus" severity="info" value="{{ 'READ' | translate }}"></p-tag>
        <p-tag
          styleClass="p-mr-2"
          icon="pi pi-check-square"
          severity="info"
          value="{{ 'WRITE' | translate }}"
        ></p-tag>
      </div>

      <!-- BOTON GUARDAR -->
      <div *ngIf="havePermissionWrite">
        <div mat-dialog-actions class="d-flex justify-content-center m-3">
          <button mat-button class="modalButton bg-primary" (click)="save()" [disabled]="!havePermissionWrite">
            {{ 'Save' | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
