<mat-card *ngIf="nodata">
  <mat-card-header class="marginLineHeaderCard">
    <div style="display: flex; justify-content: center; padding: 2rem">
      <label class="titleN2">{{ optionSelected.title | translate }}</label>
    </div>
    <div style="display: flex; justify-content: center">
      <label class="titleN3">{{ optionSelected.subtitle | translate }} </label>
    </div>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow" style="padding-bottom: 2rem"></div>
  </mat-card-header>
</mat-card>
