<mat-card class="notificationsCard">
  <mat-card-header class="border-0">
    <div class="row text-center">
      <div>
        <mat-icon fontSet="material-icons-outlined" [matBadge]="badgeNumber" matBadgeColor="warn" matBadgeSize="small">
          notifications
        </mat-icon>
        <span class="titleN2" style="margin-left: 20px; vertical-align: top">{{ 'Data' | translate }}</span>
      </div>
      <div>
        <a routerLink="/notifications" class="text-primary sidebarNotificationSeeAll">{{ 'Ver todo' | translate }}</a>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content class="body-notifications">
    <ul class="notifications-list" *ngIf="notificationsQueu?.length > 0">
      <li class="py-1" *ngFor="let notif of notificationsQueu">
        <div class="d-flex align-items-center px-1">
          <span class="notification-circle me-1"></span>
          <small class="fw-bold">{{ notif.name | translate }}</small>
        </div>
        <div class="ps-3">
          <small>{{ notif.subject }}</small><br />
          <small class="text-muted"><time>{{ notif.date }}</time></small>
        </div>
      </li>
    </ul>
  </mat-card-content>
</mat-card>
