import { AvailabilityType, getRealAvalabilityType } from './AvailabilityType.class';
import { Resource } from './Resource.class';
import { SpaceUseType } from './SpaceUseType.enum';

export class Space {
  id: number;
  name: string;
  description: string;
  type: number;
  typeName: string;
  useType: SpaceUseType;
  positionX: number;
  positionY: number;
  availabilityType: AvailabilityType;
  availabilityDate: string;
  capacity: number;
  code: string;
  floor: number;
  resources: Resource[] = [];

  constructor(msg) {
    this.id = msg.id ? msg.id : null;
    this.name = msg.name ? msg.name : '';
    this.description = msg.description ? msg.description : '';
    this.positionX = msg.x ? msg.x : null;
    this.positionY = msg.y ? msg.y : null;
    this.availabilityDate = msg.availabilityDate;
    this.type = msg.type;
    if (msg.availabilityType) {
      this.availabilityType = getRealAvalabilityType(msg.availabilityType);
    }
    this.capacity = msg.capacity ? msg.capacity : 1;
    this.code = msg.code ? msg.code : '';
    this.floor = msg.floor;
    this.resources = msg?.resources ?? [];
  }

  setExcelData(parsedRow) {
    this.id = Number(parsedRow['id']);
    this.code = parsedRow['Código'];
    this.name = parsedRow['Nombre'];
    this.description = parsedRow['Descripción'];
    let positionX = null;
    if (!isNaN(Number(parsedRow['Posición X']))) {
      positionX = Number(parsedRow['Posición X']);
    } else {
      positionX = Number(parsedRow['Posición X'].replace(',', '.'));
    }

    let positionY = null;
    if (!isNaN(Number(parsedRow['Posición Y']))) {
      positionY = Number(parsedRow['Posición Y']);
    } else {
      positionY = Number(parsedRow['Posición Y'].replace(',', '.'));
    }
    this.positionX = positionX;
    this.positionY = positionY;

    let capacity = null;
    if (!isNaN(Number(parsedRow['Capacidad']))) {
      capacity = Number(parsedRow['Capacidad']);
    } else {
      capacity = isNaN(Number(parsedRow['Capacidad']?.replace(',', '.')))
        ? 1
        : Number(parsedRow['Capacidad']?.replace(',', '.'));
    }

    this.capacity = capacity;
  }

  toBackendFormat(floor) {
    return {
      code: this.code,
      name: this.name,
      type: this.type,
      description: this.description,
      x: this.positionX,
      y: this.positionY,
      capacity: this.capacity,
      floor: floor,
    };
  }
}
