import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['confirm-modal.component.scss'],
})
export class ConfirmModalComponent {
  title = '';
  message = '';
  canConfirm = true;

  constructor(private dialogRef: MatDialogRef<ConfirmModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {
    this.title = data?.title;
    this.message = data?.message;
    this.canConfirm = data?.canConfirm === false ? false : true;
  }

  confirm(): void {
    this.dialogRef.close({ confirmed: true });
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
