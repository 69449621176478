import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { Group } from 'src/app/models/habitat/Group.class';
import { InfoModalData } from 'src/app/models/InfoModalData.class';
import { AgrupacionService } from 'src/app/services/backend/agrupacion.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { GroupService } from 'src/app/services/pages/group.service';

import { InfoModalComponent } from '../../../../shared/info-modal/info-modal.component';
import { User } from './../../../../../models/auth/User.class';
import { UserPermissions } from './../../../../../models/auth/UserPermissions.enum';
import { GlobalService } from './../../../../../services/common/global.service';
import { AppConstants } from '../../../../../shared/AppConstants';

@Component({
  selector: 'app-group-configurations-list',
  templateUrl: './group-configuration-list.component.html',
  styleUrls: ['./group-configuration-list.component.css'],
})
export class GroupConfigurationListComponent implements OnInit {
  @ViewChild('newOrEditGroup') newOrEditGroup;
  dataSource = new MatTableDataSource([]);
  loading = true;
  displayedColumnsTable = ['name', 'code', 'subgroupTitle', 'action'];
  dialogRef: MatDialogRef<any>;
  submitted = false;
  // MODAL new or modify
  modalTitle = 'New Group';
  itsEditing = false;
  groupForm: UntypedFormGroup;
  buildings: Building[];
  selectedBuildings: any[];
  selectedGroups: any[];
  radioSelection = 'Agrupaciones';
  preSelectedGroup: number[];
  preSelectedBuildings: number[];
  hideGroupsCheckboxs = false;
  hideBuildingsCheckboxs = false;

  // checkboxs modal
  buildingsCheckbox: any[];
  groupCheckbox: any[];
  auxBuildingsCheckbox: any[];
  auxGroupCheckbox: any[];

  duplicateName = false;
  disabledSave = false;

  userLogged: User = null;
  canWriteGroup = false;

  // pagination
  auxCopy = [];
  disabledPaginator = true;
  operationLength = 0;
  pageSize = AppConstants.SIZE_TABLE_PAGINATOR;
  pageEvent: PageEvent;
  lastSort: Sort;
  pageIndex = 0;
  @ViewChildren('row', { read: ElementRef }) rowElement: QueryList<ElementRef>;
  auxCompleta: any;
  @ViewChild('pag') paginator: MatPaginator;
  hidePageSize = true;

  constructor(
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private habitatService: HabitatService,
    private agrupacionBackService: AgrupacionService,
    private globalService: GlobalService,
    private groupService: GroupService
  ) {
    this.userLogged = this.globalService.getUser();
    this.canWriteGroup =
      this.userLogged.permissions?.find(
        (item) => item.name == UserPermissions.ReservableSpaces && item.type == 'WRITE'
      ) !== undefined;
    this.getGroupsAndBuildings();
  }

  getGroupsAndBuildings(): void {
    this.agrupacionBackService.getGroupList().subscribe({
      next: (response) => {
        this.dataSource.data = response;
        /** ----- pagination ----- */
        this.buildPagination();
        /** ------------------------ */
      },
      error: () => {
        this.dataSource.data = [];
      },
    });
    this.habitatService.getBuildings().subscribe((buildings) => {
      this.buildings = buildings;
    });
  }

  ngOnInit(): void {
    this.loading = false;
    // modal
    this.selectedBuildings = [];
    this.selectedGroups = [];
    this.preSelectedGroup = [];
    this.preSelectedBuildings = [];
    this.groupCheckbox = [];
    this.buildingsCheckbox = [];
    this.auxBuildingsCheckbox = [];
    this.auxGroupCheckbox = [];
  }

  editGroup(group: Group): void {
    this.disabledSave = false;
    let grupoFormulario: Group;
    this.agrupacionBackService.getGroupById(group.id).subscribe((response) => {
      grupoFormulario = response; // recuperamos el group completo
      this.modalTitle = 'Edit Group';
      this.itsEditing = true;
      if (grupoFormulario.final) {
        // grupo con edificios, deshabilitar los check de agrupaciones, marcar el radio de edificios, seleccionar los edicios que vienen ya.
        this.radioSelection = 'Edificios';
        this.buildingsCheckbox = [];
        this.auxBuildingsCheckbox = [];
        this.buildings.forEach((building) => {
          let marcado = false;
          if (grupoFormulario.children.includes(building.id)) {
            marcado = true;
          }
          this.buildingsCheckbox.push({ id: building.id, name: building.name, checked: marcado, data: building });
        });
        this.auxBuildingsCheckbox = this.buildingsCheckbox;
        this.groupCheckbox = [];
        this.dataSource.data.forEach((group) => {
          if (group.id != grupoFormulario.id) {
            this.groupCheckbox.push({ id: group.id, name: group.name, checked: false, data: group });
          }
        });
        this.auxGroupCheckbox = this.groupCheckbox;
        this.hideGroupsCheckboxs = true;
        this.hideBuildingsCheckboxs = false;
      } else {
        //grupo con agrupaciones, deshabilitar los check de edificos, marcar el radio de agrupaciones, seleccionar las que vienen ya y quitar las que sean hijas.
        this.radioSelection = 'Agrupaciones';
        this.buildingsCheckbox = this.buildings;
        this.auxBuildingsCheckbox = this.buildingsCheckbox;
        this.hideGroupsCheckboxs = false;
        this.hideBuildingsCheckboxs = true;

        this.groupCheckbox = [];
        this.dataSource.data.forEach((grupo) => {
          let marcado = false;
          if (grupoFormulario.children.includes(grupo.id)) {
            marcado = true;
          }
          this.groupCheckbox.push({ id: grupo.id, name: grupo.name, checked: marcado, data: grupo });
        });
        this.auxGroupCheckbox = this.groupCheckbox;
        this.setBuildingCheckbox(false);
      }
      this.groupForm = this.formBuilder.group(
        {
          id: [{ value: group.id, disabled: !this.canWriteGroup }],
          code: [
            { value: grupoFormulario.code, disabled: !this.canWriteGroup },
            [Validators.required, Validators.pattern(/^[^,]+$/)],
          ],
          groupName: [{ value: grupoFormulario.name, disabled: !this.canWriteGroup }, Validators.required],
          subgroupTitle: [{ value: grupoFormulario.subgroupTitle, disabled: !this.canWriteGroup }, Validators.required],
          radioOption: [{ value: this.radioSelection, disabled: !this.canWriteGroup }, Validators.required],
          final: [{ value: grupoFormulario.final, disabled: !this.canWriteGroup }],
          children: [{ value: grupoFormulario.children, disabled: !this.canWriteGroup }],
          parents: [{ value: grupoFormulario.parents, disabled: !this.canWriteGroup }],
        },
        { validators: [this.validarFormGroup] }
      );
      this.openModal();
    });
  }

  deleteGroup(group: Group): void {
    const modalData = new ConfirmModalData('DeleteGroup', 'InfoDeleteGroup');
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        // llamar a borrar grupo;
        this.agrupacionBackService.deleteGroup(group.id).subscribe(() => {
          this.updateTable();
          this.groupService.changeCurReloadGroupList(group.id);
        });
      }
    });
  }

  viewGroupJerarchy(group: Group): void {
    this.dialog.open(InfoModalComponent, {
      data: new InfoModalData('Jerarquía de agrupación:', null, true, group),
      panelClass: 'custom-dialog',
      disableClose: true,
    });
  }

  createNewGroup(): void {
    this.itsEditing = false;
    this.modalTitle = 'New Group';

    this.setBuildingCheckbox(false);

    this.setGroupCheckbox(false);

    this.hideGroupsCheckboxs = false;
    this.radioSelection = 'Agrupaciones';
    this.hideBuildingsCheckboxs = true;
    this.buildForm();
    this.openModal();
  }

  buildForm(): void {
    const namePattern = /^[\w\d\s-_ñáéíóúü]+$/i;
    this.groupForm = this.formBuilder.group(
      {
        id: [{ value: '', disabled: !this.canWriteGroup }],
        code: [{ value: '', disabled: false }, [Validators.required, Validators.pattern(namePattern)]],
        groupName: [
          { value: '', disabled: !this.canWriteGroup },
          [Validators.required, Validators.pattern(namePattern)],
        ],
        subgroupTitle: [
          { value: '', disabled: !this.canWriteGroup },
          [Validators.required, Validators.pattern(namePattern)],
        ],
        radioOption: [{ value: this.radioSelection, disabled: !this.canWriteGroup }],
        final: [{ value: '', disabled: !this.canWriteGroup }],
        children: [{ value: '', disabled: !this.canWriteGroup }],
        parents: [{ value: '', disabled: !this.canWriteGroup }],
      },
      { validators: [this.validarFormGroup] }
    );
  }

  openModal(): void {
    this.dialogRef = this.dialog.open(this.newOrEditGroup, { panelClass: 'custom-dialog', disableClose: true });
  }

  close(): void {
    this.submitted = false;
    this.loading = false;
    this.buildingsCheckbox = [];
    this.groupCheckbox = [];
    this.auxBuildingsCheckbox = [];
    this.auxGroupCheckbox = [];
    this.dialogRef.close();
  }

  updateTable(): void {
    this.agrupacionBackService.getGroupList().subscribe((response) => {
      this.dataSource.data = response;
    });
  }

  changeRadios(event: any): void {
    this.groupForm.controls.radioOption.setValue(this.radioSelection);

    if (this.radioSelection === 'Edificios') {
      this.groupCheckbox.forEach((item) => {
        item.checked = false;
      });
      this.hideGroupsCheckboxs = true;
      this.hideBuildingsCheckboxs = false;
    } else {
      this.buildingsCheckbox.forEach((item) => {
        item.checked = false;
      });
      this.hideBuildingsCheckboxs = true;
      this.hideGroupsCheckboxs = false;
    }
  }

  onChangeGroupCheckbox(event, item): void {
    this.disabledSave = false;

    this.clearValidation();
    this.setBuildingCheckbox(false);

    if (event.checked == true) {
      if (!item.data.final) {
        this.deleteGroupChildrens(item.data.childrens);
      }
    }
  }

  onChangeBuildingCheckbox(event, item): void {
    this.disabledSave = false;

    this.clearValidation();
    this.setGroupCheckbox(false);
  }

  onKeyUpEventCode(): void {
    if (this.groupForm.get('code').value.length > 0) {
      this.disabledSave = false;
    }
  }

  onKeyUpEventGroupName(): void {
    if (this.groupForm.get('groupName').value.length > 0) {
      this.disabledSave = false;
    }
  }

  onKeyUpEventSubGroupName(): void {
    if (this.groupForm.get('subgroupTitle').value.length > 0) {
      this.disabledSave = false;
    }
  }

  setGroupCheckbox(isChecked: boolean): void {
    this.groupCheckbox = [];
    this.auxGroupCheckbox = [];
    this.dataSource.data.forEach((group) => {
      this.groupCheckbox.push({ id: group.id, name: group.name, checked: isChecked, data: group });
    });
    this.auxGroupCheckbox = this.groupCheckbox;
  }

  setBuildingCheckbox(isChecked: boolean): void {
    this.buildingsCheckbox = [];
    this.auxBuildingsCheckbox = [];
    this.buildings.forEach((building) => {
      this.buildingsCheckbox.push({ id: building.id, name: building.name, checked: isChecked, data: building });
    });
    this.auxBuildingsCheckbox = this.buildingsCheckbox;
  }

  deleteGroupChildrens(childrens: number[]): void {
    this.groupCheckbox.forEach((item) => {
      if (childrens?.includes(item.id)) {
        this.groupCheckbox.splice(this.groupCheckbox.indexOf(item), 1);
        if (!item.data.final) {
          this.deleteGroupChildrens(item.data.childrens);
        }
      }
    });
  }

  buildGroup(): Group {
    const group: Group = new Group('');
    group.code = this.groupForm.get('code')?.value;
    group.name = this.groupForm.get('groupName')?.value;

    if (this.radioSelection == 'Edificios') {
      if (!this.groupForm.get('subgroupTitle')?.value) {
        this.groupForm.controls.subgroupTitle.setValue('Ciudad');
      }
      group.subgroupTitle = this.groupForm.get('subgroupTitle')?.value;

      group.final = true;
    } else {
      group.subgroupTitle = this.groupForm.get('subgroupTitle')?.value;
      group.final = false;
    }
    group.children = this.getGroupChildren();
    this.validateGroup(group);
    return group;
  }

  clearValidation(): void {
    if (this.groupForm.get('groupName').value) {
      this.groupForm.get('groupName')?.markAsPristine();
      this.groupForm.get('groupName')?.markAsUntouched();
      this.groupForm.get('groupName')?.updateValueAndValidity();
    }
    if (this.groupForm.get('code').value) {
      this.groupForm.get('code')?.markAsPristine();
      this.groupForm.get('code')?.markAsUntouched();
      this.groupForm.get('code')?.updateValueAndValidity();
    }
  }

  validateGroup(group: Group): void {
    if (this.groupForm.get('groupName').value && group.children.length <= 0) {
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
      this.groupForm.get('groupName')?.markAsDirty();
      this.groupForm.get('groupName')?.markAsTouched();
      this.groupForm.get('groupName')?.setErrors({ chooseOne: true });
    }
    if (this.groupForm.get('code').value && group.children.length <= 0) {
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
      this.groupForm.get('code')?.markAsDirty();
      this.groupForm.get('code')?.markAsTouched();
      this.groupForm.get('code')?.setErrors({ chooseOne: true });
    }
  }

  onSubmit(): void {
    this.duplicateName = false;
    this.disabledSave = true;
    this.submitted = true;

    const group = this.buildGroup(); // validateGroup into

    if (this.groupForm.valid) {
      if (!this.itsEditing) {
        this.loading = true;
        this.agrupacionBackService.createNewGroup(group).subscribe({
          next: () => {
            this.close();
            this.updateTable();
            this.groupService.changeCurReloadGroupList(group.id);
          },
          error: (error) => {
            this.managementErrors(error);
          },
        });
      } else {
        const id = this.groupForm.get('id')?.value;
        this.loading = true;
        this.agrupacionBackService.modifyGroup(group, id).subscribe({
          next: () => {
            this.close();
            this.updateTable();
            this.groupService.changeCurReloadGroupList(group.id);
          },
          error: (error) => {
            this.managementErrors(error);
          },
        });
      }
    } else {
      console.log('is not valid');
      this.disabledSave = false;
    }
  }

  managementErrors(error: HttpErrorResponse): void {
    this.duplicateName = true;
    this.loading = false;
    this.disabledSave = false;

    if (error?.toString().includes('Loops are not permited') || error?.toString().includes('Groups_name_unique')) {
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
      setTimeout(() => {
        this.groupForm.get('groupName')?.markAsDirty();
        this.groupForm.get('groupName')?.markAsTouched();
        this.groupForm.get('groupName')?.setErrors({ duplicate: true });
      }, 1);
    } else if (
      // Groups_code_unique
      error?.toString().includes('Groups_code_unique')
    ) {
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
      setTimeout(() => {
        this.groupForm.get('code')?.markAsDirty();
        this.groupForm.get('code')?.markAsTouched();
        this.groupForm.get('code')?.setErrors({ duplicate: true });
      }, 1);
    } else {
      const infoModalData = new InfoModalData(
        'Error genérico',
        'An error has occurred, please try again. If it persists contact the administrator.'
      );
      this.dialog.open(InfoModalComponent, {
        data: infoModalData,
        panelClass: 'custom-dialog',
        disableClose: true,
      });
    }
  }

  getGroupChildren(): number[] {
    const childrenArr: number[] = [];
    if (this.radioSelection == 'Edificios') {
      this.buildingsCheckbox.forEach((item) => {
        if (item.checked === true) {
          childrenArr.push(item.id);
        }
      });
    } else {
      this.groupCheckbox.forEach((item) => {
        if (item.checked === true) {
          childrenArr.push(item.id);
        }
      });
    }
    return childrenArr;
  }

  validarFormGroup: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    const { groupName, subgroupTitle, radioOption } = control.value;
    if (groupName === undefined || subgroupTitle === undefined || radioOption === undefined) {
      throw Error('Alguno de los campos es undefined');
    }
    if (radioOption === 'Edificios' || this.selectedBuildings.length > 0) {
      return null;
    } else if (radioOption === 'Agrupaciones' || this.selectedGroups.length > 0) {
      return null;
    } else {
      return { incorrectForm: true };
    }
  };

  get f() {
    return this.groupForm.controls;
  }

  applyFilterBuildings(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.buildingsCheckbox = this.auxBuildingsCheckbox.filter((building) =>
      building.name.toLowerCase().includes(filterValue.trim().toLowerCase())
    );
  }

  applyFilterGroups(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.groupCheckbox = this.auxGroupCheckbox.filter((group) =>
      group.name.toLowerCase().includes(filterValue.trim().toLowerCase())
    );
  }

  buildPagination(): void {
    // pagination
    this.auxCompleta = this.dataSource.data;
    this.disabledPaginator = false;
    this.paginationLoad(this.dataSource.data);
    if (this.lastSort !== undefined) {
      this.sortData(this.lastSort);
    }
    this.irInicio();
  }

  getPagination(event?: PageEvent): PageEvent {
    const posPartida = event.pageIndex * event.pageSize;
    let posFinal = event.pageSize + posPartida;
    const aux = [];

    if (posFinal > event.length) {
      posFinal = event.length;
    }

    for (let i = posPartida; i < posFinal; i++) {
      aux.push(this.auxCopy[i]);
    }
    this.dataSource.data = aux;
    return event;
  }

  irInicio(): void {
    const posPartida = 0 * this.pageSize;
    let posFinal = this.pageSize + posPartida;
    const aux = [];
    if (posFinal > this.operationLength) {
      posFinal = this.operationLength;
    }
    for (let i = posPartida; i < posFinal; i++) {
      aux.push(this.auxCompleta[i]);
    }

    this.dataSource.data = aux;
    this.paginator.pageIndex = 0;
  }

  paginationLoad(data: any): void {
    const aux = [];
    let size = AppConstants.SIZE_TABLE_PAGINATOR;
    let index = 0;
    if (this.pageEvent !== undefined) {
      size = this.pageEvent.pageSize;
      index = this.pageEvent.pageSize * this.pageEvent.pageIndex;
    }
    if (size > data.length) {
      size = data.length;
    }

    let length = index + size;
    if (length > data.length) {
      length = data.length;
    }

    for (let i = index; i < length; i++) {
      aux.push(data[i]);
    }

    this.dataSource.data = [...aux];
    this.auxCopy = [...data];
    this.operationLength = data.length;
  }

  sortData(sort: Sort): void {
    const data = this.auxCopy.slice();
    if (!sort.active || sort.direction === '') {
      this.auxCopy = data;
      return;
    }
    this.lastSort = sort;
    this.auxCopy = [...data].sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'code':
          return this.compare(a.code, b.code, isAsc);
        case 'subgroupTitle':
          return this.compare(a.subgroupTitle, b.subgroupTitle, isAsc);
        default:
          return 0;
      }
    });

    this.paginationLoad(this.auxCopy);
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
