import { ReservationOwner } from './ReservationOwner.class';
import { ReservationSpace } from './ReservationSpace.class';

export class Reservation {
  id: number;
  owner: ReservationOwner;
  spaces: ReservationSpace[];
  status: string;
  startDate: Date;
  endDate: Date;
  groupable: boolean;
  name: string;
  description: string;

  constructor(msg) {
    this.id = msg?.id;
    this.owner = new ReservationOwner(msg?.owner);
    this.spaces =
      msg?.spaces.map((space) => {
        return new ReservationSpace(space);
      }) || [];
    this.status = msg?.status;
    this.startDate = msg?.startDate;
    this.endDate = msg?.endDate;
    this.groupable = msg?.groupable;
    this.name = msg?.name;
    this.description = msg?.description;
  }
}
