import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { forkJoin, from, of, zip } from 'rxjs';
import { finalize, groupBy, mergeMap, toArray } from 'rxjs/operators';

import { Permission, Role } from './../../../../../models/auth/Role.class';
import { User } from './../../../../../models/auth/User.class';
import { ConfirmModalData } from './../../../../../models/ConfirmModalData.class';
import { UserService } from './../../../../../services/backend/user.service';
import { DeviceService } from './../../../../../services/common/device.service';
import { GlobalService } from './../../../../../services/common/global.service';
import { AppConstants } from '../../../../../shared/AppConstants';
import { ConfirmModalComponent } from './../../../../shared/confirm-modal/confirm-modal.component';

export class RolePermissionType {
  roleID?: number;
  permission: string; // to backend
  type: string; // to backend
}

export class CheckModel {
  roleID: number;
  permissionID: number;
  name: string;
  value: boolean; // TriStateCheckbox is used to select either "true", "false" or "null" as the value.
  roleReservationDefault: boolean; // Role default to reservations
}

export class RolePermissionID {
  roleID: number;
  permissionIDs: number[];
}

@Component({
  selector: 'app-roles-configuration-matrix',
  templateUrl: './roles-configuration-matrix.component.html',
  styleUrls: ['./roles-configuration-matrix.component.css'],
})
export class RolesConfigurationMatrixComponent implements OnInit, OnChanges {
  @Input() updateMatrixNewRole: number;
  @Input() havePermissionWrite: boolean;
  private CHECKBOX_PERMISSION = 'permission';
  private CHECKBOX_MATRIX = 'matrix';
  private CHECKBOX_ROLE = 'role';
  private WRITE = 'WRITE';
  private READ = 'READ';
  task = {
    name: 'Indeterminate',
    completed: false,
    color: 'primary',
    subtasks: [
      { name: 'Primary', completed: false, color: 'primary' },
      { name: 'Accent', completed: false, color: 'accent' },
      { name: 'Warn', completed: false, color: 'warn' },
    ],
  };
  allComplete = false;
  loading = false;
  cssGrid_1 = '1fr';
  cssGrid_2 = 'auto';
  cssGrid = '5px';
  cssGridMatrix = '10px';

  templateColumnsMatrix = this.cssGrid_2;
  nColumnsPermissions = 3;
  nRowsRoles = 3;
  nToNameRow = 3; // fijo
  nToNameColumn = 9; // fijo
  nColumnsTotal = this.nColumnsPermissions + this.nToNameColumn;
  tiles: any[] = [
    {
      text: 'todos',
      cols: this.nToNameColumn,
      rows: this.nToNameRow,
      color: '#ffffff',
    },
    {
      text: 'nPermissions',
      cols: this.nColumnsPermissions,
      rows: this.nToNameRow,
      color: '#ffffff',
    },
    {
      text: 'nRoles',
      cols: this.nToNameColumn,
      rows: 6,
      color: '#ffffff',
    },
    {
      text: this.CHECKBOX_MATRIX,
      cols: this.nColumnsPermissions,
      rows: 6,
      color: '#ffffff',
    },
  ];
  nRoles: CheckModel[] = [];
  nPermissions: CheckModel[] = [];
  nMatrix: CheckModel[] = [];
  roles: Role[] = [];
  permissions: Permission[] = [];
  rolePermissionIDs: RolePermissionID[] = [];
  private userLogged: User;
  isFirefox = false;

  constructor(
    private dialog: MatDialog,
    private translateService: TranslateService,
    private messageService: MessageService,
    private userService: UserService,
    private globalService: GlobalService,
    private deviceService: DeviceService
  ) {
    this.userLogged = this.globalService.getUser();
    this.isFirefox = this.deviceService.getDeviceInfo().browser === 'Firefox';
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'updateMatrixNewRole') {
        this.ngOnInit();
        if (this.userLogged) {
          this.globalService.buildLoggedUser(this.userLogged.id);
        }
      }
    }
  }

  ngOnInit(): void {
    this.loading = true;
    const observableRoles = this.userService.getRoles();
    const observablePermissions = this.userService.getPermissions();
    forkJoin({
      observableRoles,
      observablePermissions,
    }).subscribe({
      next: (res) => {
        this.roles = res.observableRoles;
        this.permissions = res.observablePermissions;
        this.nRowsRoles = res.observableRoles.length;
        this.nColumnsPermissions = res.observablePermissions.length;
        this.nColumnsTotal = this.nColumnsPermissions + this.nToNameColumn;
        this.setTiles();
        this.setTemplateColumnsMatrix();
        this.setCheckRoles(this.roles);
        this.setCheckPermissions(this.permissions);
        this.initMatrix();
      },
      error: () => {
        this.loading = false;
      },
    });
  }

  setTiles(): void {
    this.tiles = [
      {
        text: 'todos',
        cols: this.nToNameColumn,
        rows: this.nToNameRow,
        color: '#ffffff',
      },
      {
        text: 'nPermissions',
        cols: this.nColumnsPermissions,
        rows: this.nToNameRow,
        color: '#ffffff',
      },
      {
        text: 'nRoles',
        cols: this.nToNameColumn,
        rows: 6,
        color: '#ffffff',
      },
      {
        text: this.CHECKBOX_MATRIX,
        cols: this.nColumnsPermissions,
        rows: 6,
        color: '#ffffff',
      },
    ];
  }

  setTemplateColumnsMatrix(): void {
    const templateColumnsMatrixArray = [];
    let count = 0;
    this.permissions.forEach(() => {
      templateColumnsMatrixArray.push(this.cssGrid_2);
      count = count + 1;
      if (count === this.permissions.length) {
        this.templateColumnsMatrix = templateColumnsMatrixArray.join(' ');
      }
    });
  }

  setCheckRoles(roles: Role[]): void {
    this.nRoles = [];
    let checkModel: CheckModel = null;
    roles.forEach((role) => {
      checkModel = new CheckModel();
      checkModel.roleID = role.id;
      checkModel.permissionID = null;
      checkModel.name = role.name;
      checkModel.roleReservationDefault = role.reservationsDefault;
      checkModel.value = null;
      this.nRoles.push(checkModel);
    });
  }

  setCheckPermissions(permissions: Permission[]): void {
    this.nPermissions = [];
    let checkModel: CheckModel = null;
    permissions.forEach((permission) => {
      checkModel = new CheckModel();
      checkModel.permissionID = permission.id;
      checkModel.roleID = null;
      checkModel.name = permission.name;
      checkModel.value = null;
      this.nPermissions.push(checkModel);
    });
  }

  initMatrix(): void {
    this.nMatrix = [];

    if (this.nRoles && this.nPermissions) {
      let item: RolePermissionID = null;
      this.roles.forEach((role, indexRole) => {
        item = new RolePermissionID();
        item.roleID = role.id;
        item.permissionIDs = [...role.permissions.map((element) => element.id)];
        this.rolePermissionIDs.push(item);

        if (this.permissions?.length > 0) {
          this.permissions.forEach((permission, indexPerm) => {
            this.buildCheck(role, permission);
            if (indexRole === this.roles.length - 1 && indexPerm === this.permissions.length - 1) {
              this.loading = false;
            }
          });
        } else {
          this.loading = false;
        }
      });
    }
  }

  buildCheck(roleIn: Role, permissionIn: Permission): void {
    const checkModel = new CheckModel();
    checkModel.permissionID = permissionIn.id;
    checkModel.roleID = roleIn.id;
    checkModel.name = permissionIn.name;

    permissionIn.type = roleIn.permissions.find((permission) => permission.id === permissionIn.id)?.type;

    checkModel.value = null;
    if (permissionIn.type === this.WRITE) {
      checkModel.value = true;
    } else if (permissionIn.type === this.READ) {
      checkModel.value = false;
    }

    this.nMatrix.push(checkModel);
  }

  valueChange(evt: any, chk: CheckModel, tipo: string): void {
    let rolePermissionID: RolePermissionID = null;
    switch (tipo) {
      case this.CHECKBOX_MATRIX:
        rolePermissionID = new RolePermissionID();
        rolePermissionID.roleID = chk.roleID;
        rolePermissionID.permissionIDs = [];
        rolePermissionID.permissionIDs.push(chk.permissionID);
        this.updateNMatrix([rolePermissionID], evt);
        break;
      case this.CHECKBOX_ROLE:
        rolePermissionID = new RolePermissionID();
        rolePermissionID.roleID = chk.roleID;
        rolePermissionID.permissionIDs = [];
        this.permissions.forEach((permission) => {
          rolePermissionID.permissionIDs.push(permission.id);
        });
        this.updateNMatrix([rolePermissionID], evt);
        break;
      case this.CHECKBOX_PERMISSION: {
        const rolePermissionIDs = [];
        this.roles.forEach((role) => {
          rolePermissionID = new RolePermissionID();
          rolePermissionID.roleID = role.id;
          rolePermissionID.permissionIDs = [chk.permissionID];
          rolePermissionIDs.push(rolePermissionID);
        });
        this.updateNMatrix(rolePermissionIDs, evt);
        break;
      }
    }
  }

  updateNMatrix(rolePermissionIDsIn: RolePermissionID[], evt: any): void {
    rolePermissionIDsIn.forEach((rolePermissionID) => {
      this.nMatrix.forEach((n) => {
        if (rolePermissionID.roleID === n.roleID) {
          rolePermissionID.permissionIDs.forEach((permissionID) => {
            if (permissionID === n.permissionID) {
              n.value = evt.value;
            }
          });
        }
      });
    });
  }

  save(): void {
    this.loading = true;
    const rolePermissionTypes: RolePermissionType[] = [];
    const rolePermissionGroup = [];
    from(this.nMatrix)
      .pipe(
        groupBy(
          (roleChk) => roleChk.roleID,
          (permissionChk) => permissionChk.permissionID
        ),
        mergeMap((groupObs) => zip(of(groupObs.key), groupObs.pipe(toArray()))),
        finalize(() => {
          let roleE: RolePermissionType = null;
          let dupla = null;
          let findP = null;
          this.nMatrix = this.nMatrix.filter((matrixChk) => matrixChk.value === true || matrixChk.value === false);
          this.nMatrix.forEach((matrixChk) => {
            roleE = new RolePermissionType();
            dupla = rolePermissionGroup.find((role) => role[0] === matrixChk.roleID);
            roleE.roleID = dupla[0];
            findP = dupla[1].find((permissionID) => permissionID === matrixChk.permissionID);
            if (matrixChk.roleID === dupla[0] && matrixChk.permissionID === findP) {
              roleE.permission = matrixChk.name;
              roleE.type = matrixChk.value ? this.WRITE : this.READ;
              rolePermissionTypes.push(roleE);
            }
          });

          const permissionTypes = [];
          from(rolePermissionTypes)
            .pipe(
              groupBy((roleToEdit) => roleToEdit.roleID),
              mergeMap((permGroupObs) => zip(of(permGroupObs.key), permGroupObs.pipe(toArray()))),
              finalize(() => {
                from(permissionTypes)
                  .pipe(
                    mergeMap((itemPB) =>
                      this.userService.modifyRole(
                        itemPB[0], // roleID
                        itemPB[1].map((pm) => ({
                          // permissions
                          permission: pm.permission, // to backend
                          type: pm.type, // to backend
                        }))
                      )
                    ),
                    finalize(() => {
                      this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
                      this.messageService.add({
                        key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
                        severity: 'success',
                        summary: this.translateService.instant('Success'),
                        detail: this.translateService.instant('Permissions on roles changed successfully'),
                      });
                      this.ngOnInit();
                      if (this.userLogged) {
                        this.globalService.buildLoggedUser(this.userLogged.id);
                      }
                    }) // Execute when the observable completes
                  )
                  .subscribe((resp) => {
                    // console.log(resp);
                  });
              })
            )
            .subscribe((resp) => permissionTypes.push(resp));
        })
      )
      .subscribe((res) => {
        rolePermissionGroup.push(res);
      });
  }

  deleteRole(roleChk: CheckModel): void {
    const modalData = new ConfirmModalData('DeleteRole', 'InfoDeleteRole');
    const modalDataConfirmation = new ConfirmModalData('DeleteDefaultRole', 'InfoConfirmationDeleteDefaultRole');
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        if (roleChk.roleID) {
          if (roleChk.roleReservationDefault) {
            const dialogRefConfirmation = this.dialog.open(ConfirmModalComponent, {
              data: modalDataConfirmation,
              panelClass: 'custom-dialog',
              disableClose: true,
            });
            dialogRefConfirmation.afterClosed().subscribe((result) => {
              if (result?.confirmed) {
                this.deleteBack(roleChk);
              }
            });
          } else {
            this.deleteBack(roleChk);
          }
        }
      }
    });
  }

  deleteBack(roleChk: CheckModel): void {
    this.userService.deleteRole(roleChk.roleID).subscribe({
      next: () => {
        this.globalService.printMessage('Role deleted successfully');
        this.ngOnInit();
        if (this.userLogged) {
          this.globalService.buildLoggedUser(this.userLogged.id);
        }
      },
      error: () => {
        this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
        this.messageService.add({
          key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
          severity: 'error',
          summary: 'Error',
          detail: this.translateService.instant(
            'An error has occurred, please try again. If it persists contact the administrator'
          ),
        });
      },
    });
  }

  updateScroll(e): void {
    if (e.srcElement.name === 'matrixScroll') {
      document.querySelector('#nRolesScroll').scrollTop = e.target.scrollTop;
      document.querySelector('#nPermissionsScroll').scrollLeft = e.target.scrollLeft;
    } else if (e.srcElement.name === 'nRolesScroll') {
      document.querySelector('#matrixScroll').scrollTop = e.target.scrollTop;
    } else if (e.srcElement.name === 'nPermissionsScroll') {
      document.querySelector('#matrixScroll').scrollLeft = e.target.scrollLeft;
    }
  }

  updateScrollExterno(e): void {
    if (e.srcElement.name === 'matrixScrollExterno') {
      document.querySelector('#nRolesScrollExterno').scrollTop = e.target.scrollTop;
      document.querySelector('#nPermissionsScrollExterno').scrollLeft = e.target.scrollLeft;
    } else if (e.srcElement.name === 'nRolesScrollExterno') {
      document.querySelector('#matrixScrollExterno').scrollTop = e.target.scrollTop;
    } else if (e.srcElement.name === 'nPermissionsScrollExterno') {
      document.querySelector('#matrixScrollExterno').scrollLeft = e.target.scrollLeft;
    }
  }
}
