import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EMPTY, from, Observable, Subscription } from 'rxjs';
import { catchError, concatMap, finalize, map, mergeMap } from 'rxjs/operators';
import { ReservationConfiguration } from 'src/app/models/reservation/ReservationConfiguration.class';
import { ReservationService } from 'src/app/services/backend/reservation.service';

import { UserPermissions } from '../../../../../models/auth/UserPermissions.enum';
import { Building } from '../../../../../models/habitat/Building.class';
import { ReservationConfigurationByBuilding } from '../../../../../models/reservation/ReservationConfigurationByBuilding.class';
import { GlobalService } from '../../../../../services/common/global.service';

@Component({
  selector: 'app-group-config-form',
  templateUrl: './group-config-form.component.html',
  styleUrls: ['./group-config-form.component.css'],
})
export class GroupConfigFormComponent implements OnInit, OnDestroy {
  private selectedBuildingsSubscription: Subscription;

  @Input() disabled = false;
  @Input() selectedBuildings$: Observable<Building[]>;

  @ViewChild('saveConfiguration') saveConfiguration;

  configForm: UntypedFormGroup;
  submitted = false;
  loading = false;
  loading2 = false;
  disableSave = false;

  buildingConfigs = new Map<string, ReservationConfiguration>();

  maxTimeForMorningStart = '';
  minTimeForMorningEnd = '';
  minTimeForAfternoonStart = '';
  maxTimeForAfternoonStart = '';
  maxTimeForMorningEnd = '';
  minTimeForAfternoonEnd = '';
  selectedBuildings: Building[] = [];
  dialogRef: MatDialogRef<any>;
  errorList = [];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumnsTable = ['buildingName', 'error'];
  @ViewChild('errorConfiguration') errorConfiguration;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private reservationService: ReservationService,
    private dialog: MatDialog,
    private globalService: GlobalService
  ) {}

  ngOnInit(): void {
    this.buildForm();

    this.selectedBuildingsSubscription = this.selectedBuildings$?.subscribe((buildings) => {
      this.selectedBuildings = buildings;
      if (this.selectedBuildings.length > 0) {
        this.buildMultipleValues(this.selectedBuildings);
      }
    });
  }

  ngOnDestroy(): void {
    this.selectedBuildingsSubscription?.unsubscribe();
  }

  get canEdit() {
    return this.globalService
      .getUser()
      ?.permissions?.some((p) => p.name == UserPermissions.BuildingConfig && p.type == 'WRITE');
  }

  get buildingsNames(): string {
    return this.selectedBuildings.map((x) => x.name).join(', ');
  }

  buildMultipleValues(buildings: Building[]): void {
    from(buildings.map((building) => building.id))
      .pipe(mergeMap((id) => this.reservationService.getModuleConfigByBuilding(id.toString())))
      .subscribe((configBuilding) => {
        this.buildingConfigs.set(configBuilding.buildingId.toString(), configBuilding.config);
        this.loadFormConfig(configBuilding.config);
        this.modifyMorningAfternoonAvailableValues();
      });
  }

  loadFormConfig(config: ReservationConfiguration): void {
    this.configForm.patchValue({
      checkInWindowInMinutes: config.checkInWindowInMinutes,
      checkInReminderInMinutes: config.checkInReminderInMinutes,
      individualReservationsLimit: config.individualReservationsLimit,
      futureReservationLimitInDays: config.futureReservationLimitInDays,
      reservationReminderInMinutes: config.reservationReminderInMinutes,
      morningStart: config.morningStart.substr(-8, 5), // TODO: Check if this is the best way to do it
      morningEnd: config.morningEnd.substr(-8, 5),
      afternoonStart: config.afternoonStart.substr(-8, 5),
      afternoonEnd: config.afternoonEnd.substr(-8, 5),
      mailNotificationsEnabled: config.mailNotificationsEnabled,
      pushNotificationsEnabled: config.pushNotificationsEnabled,
      plusCheckInTimeInMinutes: config.plusCheckInTimeInMinutes,
    });
  }

  buildForm(): void {
    this.configForm = this.formBuilder.group({
      checkInWindowInMinutes: ['', [Validators.required, Validators.min(0), Validators.max(99999)]],
      checkInReminderInMinutes: ['', [Validators.required, Validators.min(0), Validators.max(99999)]],
      individualReservationsLimit: ['', [Validators.required, Validators.min(0), Validators.max(99999)]],
      futureReservationLimitInDays: ['', [Validators.required, Validators.min(0), Validators.max(99999)]],
      reservationReminderInMinutes: ['', [Validators.required, Validators.min(0), Validators.max(99999)]],
      morningStart: ['', [Validators.required]],
      morningEnd: ['', [Validators.required]],
      afternoonStart: ['', [Validators.required]],
      afternoonEnd: ['', [Validators.required]],
      mailNotificationsEnabled: true,
      pushNotificationsEnabled: true,
      plusCheckInTimeInMinutes: ['', [Validators.required, Validators.min(0), Validators.max(99999)]],
    });
  }

  /**
   * Sets the limits for the timepicker taking into account the rest of timepickers.
   * Ex: morning end cant have a higher value than afternoon start
   */
  modifyMorningAfternoonAvailableValues(): void {
    /**
     * Set the max time allowed for MORNING START control
     */
    let maxHoursForMorningStart = parseInt(this.configForm.get('morningEnd')?.value.split(':')[0] || 12);
    const maxSuffixForMorningStart = maxHoursForMorningStart >= 12 ? 'pm' : 'am';
    maxHoursForMorningStart = ((maxHoursForMorningStart + 11) % 12) + 1;
    const maxMinutesForMorningStart = parseInt(this.configForm.get('morningEnd')?.value.split(':')[1] || 0);

    this.maxTimeForMorningStart =
      ('0' + maxHoursForMorningStart).slice(-2) +
      ':' +
      ('0' + maxMinutesForMorningStart).slice(-2) +
      ' ' +
      maxSuffixForMorningStart;

    /**
     * Set the min time allowed for MORNING END control
     */
    let minHoursForMorningEnd = parseInt(this.configForm.get('morningStart')?.value.split(':')[0] || 0);
    const minSuffixForMorningEnd = maxHoursForMorningStart >= 12 ? 'pm' : 'am';
    minHoursForMorningEnd = ((minHoursForMorningEnd + 11) % 12) + 1;
    const minMinutesForMorningEnd = parseInt(this.configForm.get('morningStart')?.value.split(':')[1] || 0);

    this.minTimeForMorningEnd =
      ('0' + minHoursForMorningEnd).slice(-2) +
      ':' +
      ('0' + minMinutesForMorningEnd).slice(-2) +
      ' ' +
      minSuffixForMorningEnd;

    /**
     * Set the max time allowed for MORNING END control
     */
    let maxHoursForMorningEnd = parseInt(this.configForm.get('afternoonStart')?.value.split(':')[0] || 14);
    const maxSuffixForMorningEnd = maxHoursForMorningEnd >= 12 ? 'pm' : 'am';
    maxHoursForMorningEnd = ((maxHoursForMorningEnd + 11) % 12) + 1;
    const maxMinutesForMorningEnd = parseInt(this.configForm.get('afternoonStart')?.value.split(':')[1] || 0);

    this.maxTimeForMorningEnd =
      ('0' + maxHoursForMorningEnd).slice(-2) +
      ':' +
      ('0' + maxMinutesForMorningEnd).slice(-2) +
      ' ' +
      maxSuffixForMorningEnd;

    /**
     * Set the min time allowed for AFTERNOON START control
     */
    let minHoursForAfternoonStart = parseInt(this.configForm.get('morningEnd')?.value.split(':')[0] || 14);
    const minSuffixForAfternoonStart = minHoursForAfternoonStart >= 12 ? 'pm' : 'am';
    minHoursForAfternoonStart = ((minHoursForAfternoonStart + 11) % 12) + 1;
    const minMinutesForAfternoonStart = parseInt(this.configForm.get('morningEnd')?.value.split(':')[1] || 0);

    this.minTimeForAfternoonStart =
      ('0' + minHoursForAfternoonStart).slice(-2) +
      ':' +
      ('0' + minMinutesForAfternoonStart).slice(-2) +
      ' ' +
      minSuffixForAfternoonStart;

    /**
     * Set the max time allowed for AFTERNOON START control
     */
    let maxHoursForAfternoonStart = parseInt(this.configForm.get('afternoonEnd')?.value.split(':')[0] || 14);
    const maxSuffixForAfternoonStart = maxHoursForAfternoonStart >= 12 ? 'pm' : 'am';
    maxHoursForAfternoonStart = ((maxHoursForAfternoonStart + 11) % 12) + 1;
    const maxMinutesForAfternoonStart = parseInt(this.configForm.get('afternoonEnd')?.value.split(':')[1] || 0);

    this.maxTimeForAfternoonStart =
      ('0' + maxHoursForAfternoonStart).slice(-2) +
      ':' +
      ('0' + maxMinutesForAfternoonStart).slice(-2) +
      ' ' +
      maxSuffixForAfternoonStart;

    /**
     * Set the min time allowed for AFTERNOON END control
     */
    let minHoursForAfternoonEnd = parseInt(this.configForm.get('afternoonStart')?.value.split(':')[0] || 14);
    const minSuffixForAfternoonEnd = minHoursForAfternoonEnd >= 12 ? 'pm' : 'am';
    minHoursForAfternoonEnd = ((minHoursForAfternoonEnd + 11) % 12) + 1;
    const minMinutesForAfternoonEnd = parseInt(this.configForm.get('afternoonStart')?.value.split(':')[1] || 0);

    this.minTimeForAfternoonEnd =
      ('0' + minHoursForAfternoonEnd).slice(-2) +
      ':' +
      ('0' + minMinutesForAfternoonEnd).slice(-2) +
      ' ' +
      minSuffixForAfternoonEnd;
  }

  submitConfigForm(): void {
    if (this.configForm.invalid) {
      return;
    }

    this.disableSave = true;
    this.submitted = true;

    this.openModal(this.saveConfiguration);
  }

  modifyReservationConfigBuildings(buildings: Building[]): void {
    this.errorList = [];
    this.loading2 = true;
    const configToSave = {
      ...this.configForm.value,
      afternoonStart: this.setSeconds(this.configForm.value.afternoonStart),
      afternoonEnd: this.setSeconds(this.configForm.value.afternoonEnd),
      morningEnd: this.setSeconds(this.configForm.value.morningEnd),
      morningStart: this.setSeconds(this.configForm.value.morningStart),
    };
    from(
      buildings.map(
        (building) =>
          new ReservationConfigurationByBuilding({
            buildingId: building.id,
            config: configToSave,
          })
      )
    )
      .pipe(
        concatMap((reservaConfig) =>
          this.reservationService.modifyModuleConfigByBuilding(reservaConfig).pipe(
            catchError((err) => {
              this.errorList.push({
                buildingName: buildings.find((build) => build.id.toString() == reservaConfig.buildingId).name,
                error: err.error,
              });
              return EMPTY;
            }),
            finalize(() => {
              this.globalService.printMessage('Reservation settings modified successfully');
            })
          )
        ),
        finalize(() => {
          this.close();
          if (this.errorList.length > 0) {
            this.dataSource.data = this.errorList;
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.openModal(this.errorConfiguration);
          }
        })
      )
      .subscribe();
  }

  get f() {
    return this.configForm.controls;
  }

  setSeconds(time): string {
    return time + ':00';
  }

  getTooltipOf(value: string): string {
    return this.selectedBuildings
      .map((x) => [x.name, this.buildingConfigs.get(x.id.toString())])
      .map(([name, config]) => `${name}: ${config?.[value] ?? '-'}`)
      .join('  |  ');
  }

  openModal(componentToOpen): void {
    this.dialogRef = this.dialog.open(componentToOpen, { panelClass: 'custom-dialog', disableClose: true });
  }

  close(): void {
    this.dialogRef.close();
    this.loading = false;
    this.loading2 = false;
    this.disableSave = false;
  }

  save(): void {
    this.modifyReservationConfigBuildings(this.selectedBuildings);
  }
}
