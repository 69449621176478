import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Building } from 'src/app/models/habitat/Building.class';
import { AgrupacionService } from './../backend/agrupacion.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  private selectedBuildings = new BehaviorSubject<Building[]>([]);
  curSelectedBuildings = this.selectedBuildings.asObservable();

  private reloadGroupList = new BehaviorSubject<string>('');
  curReloadGroupList = this.reloadGroupList.asObservable();

  private selectedGroupUser = new BehaviorSubject<string>('');
  curSelectedGroupUser = this.selectedGroupUser.asObservable();

  constructor(private agrupacionBackService: AgrupacionService) {}

  changeCurSelectedBuildings(builds: Building[]): void {
    this.selectedBuildings.next(builds);
  }

  changeCurReloadGroupList(num: string): void {
    this.reloadGroupList.next(num);
  }

  changeCurSelectedGroupUser(selectedGroupUser: string): void {
    this.selectedGroupUser.next(selectedGroupUser);
  }

  getNameGroupSelected(mainGroup: string): Observable<any> {
    return this.agrupacionBackService.getGroupById(mainGroup);
  }
}
