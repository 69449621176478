export class ConfirmModalData {
  title: string;
  message: string;
  /**
   * If user can confirm dialog or just press the "cancel" button. Its default value is true
   */
  canConfirm: boolean;

  constructor(title: string, message: string, canConfirm?: boolean) {
    this.title = title;
    this.message = message;
    this.canConfirm = canConfirm === false ? false : true;
  }
}
