import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { Subsection } from 'src/app/models/esite/Subsection.class';
import { SubsectionType } from 'src/app/models/esite/SubsectionType.class';
import { EsiteService } from 'src/app/services/backend/esite.service';

import { Permission } from '../../../../../models/auth/Role.class';
import { UserPermissions } from '../../../../../models/auth/UserPermissions.enum';
import { GlobalService } from '../../../../../services/common/global.service';

class SubsectionTableItem {
  id: number;
  name: string;
  type: number;
  displayTypeName: string;

  constructor(subsection: Subsection, subsectionTypes: SubsectionType[]) {
    this.id = subsection.id;
    this.name = subsection.name;
    this.type = subsection.type.id;
    this.displayTypeName = subsectionTypes.find((item) => item.id == subsection.type.id).name;
  }
}

@Component({
  selector: 'app-subsection-configuration',
  templateUrl: './subsection-configuration.component.html',
  styleUrls: ['../subsections-configuration.component.css'],
})
export class SubsectionConfigurationComponent implements OnChanges, OnDestroy {
  @ViewChild('newOrEditType') newOrEditType;
  @Input() subsectionTypes: SubsectionType[] = [];
  subsectionTypesLength = 0;
  subsectionList: SubsectionTableItem[] = [];
  subsectionForm: UntypedFormGroup;
  loading = true;
  dataSource = new MatTableDataSource([]);
  displayedColumnsTable: string[] = ['name', 'displayTypeName', 'action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  modalTitle = 'New subsection';
  dialogRef: MatDialogRef<any>;
  submitted = false;
  subcriptions: Subscription[] = [];
  itsEditing = false;
  canWriteSubsection = false;
  userLogged: any;
  private permissions: Permission[] = [];

  constructor(
    private esiteService: EsiteService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private globalService: GlobalService
  ) {
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
    this.ckeckPermissionsUser(this.permissions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.subsectionTypes?.currentValue) {
      this.subsectionTypes = changes.subsectionTypes.currentValue;
      // In case a subsection type has been DELETED, refresh table data, as some subsections may have been deleted also
      if (this.subsectionTypes.length < this.subsectionTypesLength) {
        this.loading = true;
        this.getData();
      } else if (!changes.subsectionTypes.previousValue) {
        this.getData();
      }
      this.subsectionTypesLength = this.subsectionTypes.length;
    }
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  ckeckPermissionsUser(permissions: Permission[]): void {
    this.canWriteSubsection =
      permissions?.find((item) => item.name === UserPermissions.Subsections && item.type == 'WRITE') !== undefined;
  }

  getData(): void {
    this.subsectionList = [];
    this.subcriptions.push(
      this.esiteService.getSubsections().subscribe((subsections: Subsection[]) => {
        subsections.forEach((subsectionitem) => {
          const subsection = new SubsectionTableItem(subsectionitem, this.subsectionTypes);
          this.subsectionList.push(subsection);
        });
        this.dataSource.data = this.subsectionList;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      })
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createNewSubsection(): void {
    this.itsEditing = false;
    this.modalTitle = 'New subsection';
    this.buildForm();
    this.openModal();
  }

  openModal(): void {
    this.dialogRef = this.dialog.open(this.newOrEditType, { panelClass: 'custom-dialog', disableClose: true });
  }

  buildForm(): void {
    this.subsectionForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]],
      type: ['', [Validators.required]],
    });
  }

  cancel(): void {
    this.dialogRef.close();
    this.submitted = false;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.subsectionForm.invalid) {
      return;
    }

    if (
      this.subsectionList.find(
        (item) => item.name === this.subsectionForm.value.name && item.id !== this.subsectionForm.value.id
      )
    ) {
      setTimeout(() => {
        this.subsectionForm.get('name').markAsDirty();
        this.subsectionForm.get('name').markAsTouched();
        this.subsectionForm.get('name').setErrors({ duplicate: true });
      }, 1);
      return;
    }
    if (!this.itsEditing) {
      this.subcriptions.push(
        this.esiteService
          .insertSubsection(this.subsectionForm.value, AuditFunctionalities.CreateIndividualSubsection)
          .subscribe({
            next: (res) => {
              const subsection = {
                id: res.id,
                name: res.name,
                type: res.type,
                displayTypeName: this.subsectionTypes.find((item) => item.id == res.type).name,
              };
              this.subsectionList.push(subsection);
              this.dataSource.data = this.subsectionList;
              this.cancel();
            },
            error: (err: HttpErrorResponse) => {
              this.setFormErrors(err);
            },
          })
      );
    } else {
      this.subcriptions.push(
        this.esiteService.modifySubsection(this.subsectionForm.value).subscribe({
          next: () => {
            const modifiedElement = this.subsectionList.findIndex(
              (item) => item.id === this.subsectionForm.get('id').value
            );
            this.subsectionList[modifiedElement] = {
              id: this.subsectionForm.get('id').value,
              name: this.subsectionForm.get('name').value,
              type: this.subsectionForm.get('type').value,
              displayTypeName: this.subsectionTypes.find((item) => item.id == this.subsectionForm.get('type').value)
                .name,
            };
            this.dataSource.data = this.subsectionList;
            this.cancel();
          },
          error: (err: HttpErrorResponse) => {
            this.setFormErrors(err);
          },
        })
      );
    }
  }

  setFormErrors(err: HttpErrorResponse): void {
    if (err.status === 409) {
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
      setTimeout(() => {
        this.subsectionForm.get('type').markAsDirty();
        this.subsectionForm.get('type').markAsTouched();
        this.subsectionForm.get('type').setErrors({ typeConflict: true });
      }, 1);
    } else {
      setTimeout(() => {
        this.subsectionForm.get('name').markAsDirty();
        this.subsectionForm.get('name').markAsTouched();
        this.subsectionForm.get('name').setErrors({ unknown: true });
      }, 1);
    }
  }

  deleteSubsection(subsection: SubsectionTableItem): void {
    const modalData = new ConfirmModalData('DeleteSubsection', 'InfoDeleteSubsection');
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        this.subcriptions.push(
          this.esiteService.deleteSubsection(subsection.id).subscribe(() => {
            const deletedElement = this.subsectionList.findIndex(
              (subsectionItem) => subsectionItem.id === subsection.id
            );
            this.subsectionList.splice(deletedElement, 1);
            this.dataSource.data = this.subsectionList;
          })
        );
      }
    });
  }

  editSubsection(subsection: SubsectionTableItem): void {
    this.modalTitle = 'Edit subsection';
    this.itsEditing = true;

    this.subsectionForm = this.formBuilder.group({
      id: subsection.id,
      name: [subsection.name, [Validators.required, Validators.pattern(/^[^,]+$/)]],
      type: [subsection.type, [Validators.required]],
    });
    this.openModal();
  }
  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.subsectionForm.controls;
  }
}
