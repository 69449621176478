import { Space } from '../esite/Space.class';
import { SvgmapPoint } from './SvgmapPoint.class';

export class SvgmapPointReservation extends SvgmapPoint {
  rGroup: string;
  approval: string;
  usersData: any;

  constructor(space: Space) {
    super(space);
  }
}
