export const Constants = {
  totalSecondsInDay: 86400000,
  maxInt: 2147483647,
  httpStatusConflict: 409,
  httpStatusNotFound: 404,
  httpStatusBadRequest: 400,
  templateVersion: '1.7',
  WEBSOCKET_KEEPALIVE_TYPE: 'keepalive',
  WEBSOCKET_KEEPALIVE_MESSAGE: '',
};
