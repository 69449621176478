<mat-card class="p-4">
  <mat-card-header>
    <div class="fs-4 text-primary mb-3">{{ 'Auditoría de reservas' | translate }}</div>
  </mat-card-header>
  <mat-card-content>
    <form [formGroup]="filterFormGroup">
      <div class="row">
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Building' | translate }}</mat-label>
            <mat-select class="py-1" formControlName="reservationsbuilding" (selectionChange)="getBuildingFloors()">
              <mat-option *ngFor="let building of buildings" [value]="building.id">
                {{ building.name | translate }}
              </mat-option>
            </mat-select>
              <mat-icon matSuffix class="icon-button icon-position" *ngIf="filterFormGroup.value.reservationsbuilding" (click)="clearField('building')">highlight_off</mat-icon>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Floor' | translate }}</mat-label>
            <mat-select
              class="py-1"
              formControlName="reservationsfloor"
              [disabled]="!filterFormGroup.value.reservationsbuilding"
            >
              <mat-option *ngFor="let floor of floors" [value]="floor.id">
                {{ floor.name | translate }}
              </mat-option>
            </mat-select>             
            <mat-icon matSuffix class="icon-button icon-position" 
              *ngIf="filterFormGroup.value.reservationsfloor"
              [title]="'Clear selection' | translate"
              (click)="clearField('floor')">
              highlight_off
              </mat-icon>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Use type' | translate }}</mat-label>
            <mat-select class="py-1" formControlName="reservationsuseType">
              <mat-option *ngFor="let type of useTypes" [value]="type">
                {{ type | translate }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="icon-button icon-position" 
            *ngIf="filterFormGroup.value.reservationsuseType"
            [title]="'Clear selection' | translate"
            (click)="clearField('useType')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'User' | translate }}</mat-label>
            <mat-select
              id="user"
              formControlName="reservationsuser"
              class="input-form-special py-1"
              [ngClass]="{ 'is-invalid': f.reservationsuser.errors }"
              ngxMatSelect
              #ms="ngxMatSelect"
              [searcher]="searcher"
              [displayMember]="'nameComplete'"
              [valueMember]="'employeeNumber'"
              [useInfiniteScroll]="true"
              [source]="users"
              [lazyLoad]="true"
              searchBoxPlaceholder="{{ 'Search' | translate }}"
              emptyLabel="{{ 'No user found' | translate }}"
              noMoreResultLabel="{{ 'No more found' | translate }}"
              [hasSearchBox]="true"
            >
              <mat-option *ngFor="let user of ms.filteredSource" [value]="user.employeeNumber">
                {{ user.nameComplete | translate }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="icon-button icon-position" 
            *ngIf="filterFormGroup.value.reservationsuser"
            [title]="'Clear selection' | translate"
            (click)="clearField('user')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Status' | translate }}</mat-label>
            <mat-select class="py-1" formControlName="reservationsstatus">
              <mat-option *ngFor="let item of status" [value]="item">
                {{ item | translate }}
              </mat-option>
            </mat-select>
            <mat-icon matSuffix class="icon-button icon-position" 
            *ngIf="filterFormGroup.value.reservationsstatus"
            [title]="'Clear selection' | translate"
            (click)="clearField('status')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Date Range' | translate }}</mat-label>
            <input
              title="{{ 'Date Range' | translate }}"
              matInput
              autocomplete="off"
              formControlName="reservationsdateRange"
              type="text"
              class="py-1"
              spellcheck="false"
              (click)="op.toggle($event)"
              readonly
            />
            <mat-error *ngIf="!isValidDateForm">{{ 'Date range is not valid' | translate }}</mat-error>
            <mat-icon matSuffix class="icon-button" 
            *ngIf="filterFormGroup.value.reservationsdateRange"
            [title]="'Clear selection' | translate"
            (click)="clearField('dateRange')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Space code' | translate }}</mat-label>
            <input
              title="spaceCode"
              matInput
              autocomplete="off"
              formControlName="reservationsspace"
              type="text"
              class="py-1"
              spellcheck="false"
            />
            <mat-icon matSuffix class="icon-button" 
            *ngIf="filterFormGroup.value.reservationsspace"
            [title]="'Clear selection' | translate"
            (click)="clearField('space')">
              highlight_off
            </mat-icon>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="form-group col-12">
            <mat-label>{{ 'Hour' | translate }}</mat-label>
            <mat-select class="py-1" formControlName="reservationsLocalHour">
              <mat-option [value]="false">
                {{ 'UTC Time' | translate }}
              </mat-option>
              <mat-option [value]="true">
                {{ 'Local Time' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col"></div>
      </div>
      <div class="d-flex justify-content-center">
        <button mat-button color="primary" (click)="onSearch()" [disabled]="resultsTableLoading">
          {{ 'Search' | translate }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
<p-overlayPanel #op>
  <ng-template pTemplate>
    <div class="row" id="calendario" class="p-calendar-padding">
      <div class="p-calendar__header row">
        <div class="col text-center">
          <div class="fw-bold"> {{ 'Start' | translate }}</div>
          <small>{{ startTime | date : 'dd/MM/yyyy' }}</small>
        </div>

        <div class="col text-center">
          <div class="fw-bold"> {{ 'End' | translate }}</div>
          <small >{{ endTime | date : 'dd/MM/yyyy' }}</small>
        </div>
      </div>
      <div class="d-flex">
        <div class="px-1">
          <p-calendar
            [(ngModel)]="startTime"
            [showTime]="false"
            inputId="time"
            [inline]="true"
            [maxDate]="endTime"
            [showOtherMonths]="false"
          ></p-calendar>
        </div>

        <div class="px-1">
          <p-calendar
            [(ngModel)]="endTime"
            [showTime]="false"
            inputId="time"
            [inline]="true"
            [minDate]="startTime"
            [showOtherMonths]="false"
          ></p-calendar>
        </div>
      </div>
    </div>

    <div class="buttonsRow">
      <button mat-button class="modalCancel text-primary" (click)="op.hide($event)" (click)="cleanRangeField()">
        {{ 'Cancel' | translate }}
      </button>
      <button mat-button class="modalButton bg-primary" (click)="confirmRange()" (click)="op.hide($event)">
        {{ 'accept' | translate }}
      </button>
    </div>
  </ng-template>
</p-overlayPanel>
