import { Component, OnInit } from '@angular/core';
import { MenuItem, TreeNode } from 'primeng/api';
import { InformesService } from './../../../services/pages/informes.service';
import { ScreenSizeService } from './../../../services/pages/screensize.service';

@Component({
  selector: 'app-menu-informes',
  templateUrl: './menu-informes.component.html',
  styleUrls: ['./menu-informes.component.scss'],
})
export class MenuInformesComponent implements OnInit {
  informes: TreeNode[];
  informesMin: TreeNode[];
  selectedInforme: TreeNode;
  items: MenuItem[];
  screenSizeWidth: number;

  constructor(private informesService: InformesService, private screenSizeService: ScreenSizeService) {
    this.informes = [
      // {
      //   label: 'Puestos',
      //   data: 'Puestos',
      //   collapsedIcon: 'pi pi-arrow-right',
      // },
      // {
      //   label: 'Aforo',
      //   data: 'Aforo',
      //   collapsedIcon: 'pi pi-arrow-right',
      // },
      {
        label: 'Reservas',
        data: 'Reservas',
        collapsedIcon: 'pi pi-arrow-right',
      },
    ];

    this.informesMin = [
      // {
      //   label: 'Puestos',
      //   data: 'Puestos',
      // },
      // {
      //   label: 'Aforo',
      //   data: 'Aforo',
      // },
      {
        label: 'Reservas',
        data: 'Reservas',
      },
    ];

    this.selectedInforme = this.informesMin[0];
  }

  ngOnInit(): void {
    this.screenSizeService.widthObservable$.subscribe((value) => {
      this.screenSizeWidth = value;
    });
  }

  nodeSelect(event: any): void {
    this.informesService.setInformeType(event.node.data);
  }

  refresh(): void {
    this.informesService.setRefresh(true);
  }
}
