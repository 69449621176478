import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NotPermissionComponent } from '../../not-permission/not-permission.component';
import { Permission } from './../../../../models/auth/Role.class';
import { User } from './../../../../models/auth/User.class';
import { UserPermissions } from './../../../../models/auth/UserPermissions.enum';
import { GlobalService } from './../../../../services/common/global.service';

@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.css'],
})
export class AuditComponent implements OnInit {
  private userLogged: User;
  private permissions: Permission[] = [];
  havePermission = true;
  showResults = false;

  constructor(
    private globalService: GlobalService,
    private translateService: TranslateService,
    public dialog: MatDialog
  ) {
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
  }

  ngOnInit(): void {
    if (!this.permissions?.map((permission) => permission.name).includes(UserPermissions.Audit)) {
      this.havePermission = false;
      this.globalService.printMessage(
        this.translateService.instant('You do not have the Audit permission'),
        'warn',
        this.translateService.instant('Warning')
      );
      this.dialog?.open(NotPermissionComponent, { disableClose: true });
    }
  }

  handleSearch() {
    this.showResults = true;
  }
}
