import { Zone } from '../conteo/Zone.class';
import { GroupSpacesState } from '../realtime/GroupSpacesState.class';
import { City } from './City.class';
import { Floor } from './Floor.class';

export class Building {
  id: number;
  name: string;
  shownName: string;
  city: City; // timezone into
  address: string;
  dashboardUrl: string;
  postalCode?: number;
  confirmed: boolean;
  floorSpacesState: GroupSpacesState;
  currentFloor: Floor;
  floors: Floor[];
  floorZonesState: Map<number, Zone[]>;
  latitude?: number;
  longitude?: number;

  constructor(msg) {
    this.id = msg?.id;
    this.name = msg?.name;
    this.shownName = msg?.name;
    this.address = msg?.address;
    this.postalCode = msg?.postalCode;
    if (msg.city) {
      if (msg.city instanceof City) {
        this.city = msg.city;
      } else {
        this.city = new City('');
        this.city.id = msg.city;
      }
    }
    this.confirmed = msg?.confirmed;
    this.floors = [];
    this.latitude = msg?.latitude;
    this.longitude = msg?.longitude;
    this.floorSpacesState = new GroupSpacesState();
  }

  toBackenFormat?(): {
    name: string;
    address: string;
    postalCode: number;
    city: number;
    latitude?: number;
    longitude?: number;
  } {
    let backendObject = null;
    if (this.latitude && this.longitude) {
      backendObject = {
        name: this.name,
        address: this.address,
        postalCode: this.postalCode,
        city: this.city.id,
        latitude: this.latitude,
        longitude: this.longitude,
      };
    } else {
      backendObject = {
        name: this.name,
        address: this.address,
        postalCode: this.postalCode,
        city: this.city.id,
      };
    }
    return backendObject;
  }
}
