import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Zone } from 'src/app/models/conteo/Zone.class';
import { OccupationNotificationConfig } from 'src/app/models/notifications/OccupationNotificationConfig.class';
import { OccupationNotificationType } from 'src/app/models/notifications/OccupationNotificationType.enum';
import { GlobalService } from 'src/app/services/common/global.service';
import { NotificationsService } from 'src/app/services/pages/notifications.service';

@Component({
  selector: 'app-notification-form',
  templateUrl: './notification-form.component.html',
  styleUrls: ['../../profile.component.scss'],
})
export class NotificationFormComponent implements OnInit {
  createNotificationFormGroup: UntypedFormGroup;
  submitted = false;
  titleModal = '';
  zones: Zone[] = [];
  hours = [];
  alarmSubtypes = [OccupationNotificationType.HigherCapacity, OccupationNotificationType.LowerCapacity];
  edit = false;
  loading = true;

  constructor(
    private notificationsService: NotificationsService,
    private formBuilder: UntypedFormBuilder,
    private globalService: GlobalService,
    private dialogRef: MatDialogRef<NotificationFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.titleModal = 'NewNotification';
    this.getZones();
    this.createNotificationFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      type: ['', [Validators.required]],
      limit: ['', [Validators.required, Validators.min(0), Validators.max(100)]],
      zone: ['', [Validators.required]],
      startTime: ['', []],
      endTime: ['', []],
      selectAction: ['', []],
      delayMinutes: ['', [Validators.min(0)]],
    });
    this.submitted = false;
  }

  /**
   * Gets the error message going to be shown to the user in case the 'limit' form value is invalid
   * @returns string with the error message
   */
  getThresholdErrorMsg(): string {
    let errorMsg = '';
    if (this.createNotificationFormGroup.get('limit')?.hasError('required')) {
      errorMsg = 'Limit is required';
    } else if (this.createNotificationFormGroup.get('limit')?.hasError('min')) {
      errorMsg = 'Threshold should be higher or equal to 0%';
    } else if (this.createNotificationFormGroup.get('limit')?.hasError('max')) {
      errorMsg = 'Threshold should be lower or equal to 100%';
    }
    return errorMsg;
  }

  getZones() {
    this.notificationsService.getZones().subscribe((zones) => {
      zones.forEach((zoneitem) => {
        const zone = new Zone(zoneitem);
        if (zone.graphicalInfo) {
          this.zones.push(zone);
        }
      });
      this.zones.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
      this.loading = false;
    });
  }

  getalarmSubtypesAsString(type): string {
    return OccupationNotificationType[type];
  }

  createOrEditNotification() {
    this.submitted = true;
    if (this.createNotificationFormGroup.invalid) {
      return;
    }
    this.loading = true;
    const newConfig = new OccupationNotificationConfig('');
    newConfig.name = this.createNotificationFormGroup.value.name;
    newConfig.subtype = this.createNotificationFormGroup.value.type;
    newConfig.zone = this.createNotificationFormGroup.value.zone;
    newConfig.occupancyRate = this.createNotificationFormGroup.value.limit;
    const configInBackendFormat = newConfig.toBackenFormat();
    this.notificationsService.insertConteoAlarmConfig(configInBackendFormat).subscribe({
      next: (resp) => {
        const newConfigSaved = new OccupationNotificationConfig(resp);
        this.globalService.addOccupationNotification(resp);
        this.dialogRef.close({ data: newConfigSaved });
      },
      error: (err: HttpErrorResponse) => {
        this.loading = false;
        // Possibly a 'Duplicate alarm name' error
        if (err.status === 400) {
          /**
           * Timeout is necessary as errors are wiped out
           * (see https://github.com/angular/angular/issues/19170)
           */
          setTimeout(() => {
            this.createNotificationFormGroup.get('name').markAsDirty();
            this.createNotificationFormGroup.get('name').markAsTouched();
            this.createNotificationFormGroup.get('name').setErrors({ duplicate: true });
          }, 1);
        } else {
          setTimeout(() => {
            this.createNotificationFormGroup.get('name').markAsDirty();
            this.createNotificationFormGroup.get('name').markAsTouched();
            this.createNotificationFormGroup.get('name').setErrors({ unknown: true });
          }, 1);
        }
      },
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  get f() {
    return this.createNotificationFormGroup.controls;
  }
}
