import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import FileSaver from 'file-saver';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { InfoModalComponent } from 'src/app/components/shared/info-modal/info-modal.component';
import { Constants } from 'src/app/models/Constants.const';
import { Building } from 'src/app/models/habitat/Building.class';
import { City } from 'src/app/models/habitat/City.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { InfoModalData } from 'src/app/models/InfoModalData.class';
import { Reservation } from 'src/app/models/reservation/Reservation.class';
import { ReservationJobActions } from 'src/app/models/reservation/ReservationJobActions.enum';
import { ReservationRegistry } from 'src/app/models/reservation/ReservationRegistry.class';
import { ReservationUser } from 'src/app/models/reservation/ReservationUser.class';
import { ReservationUsersFiltered } from 'src/app/models/reservation/ReservationUsersFiltered.class';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { DateService } from 'src/app/services/common/date.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { LanguageService } from 'src/app/services/common/language.service';
import { AuditPagesService } from 'src/app/services/pages/audit.service';

import { UserBuilding } from './../../../../../models/auth/Role.class';
import { User } from './../../../../../models/auth/User.class';
import { StorageVariables } from './../../../../../models/StorageVariables.enum';
import { UserService } from './../../../../../services/backend/user.service';
@Component({
  selector: 'app-reservation-audit-results',
  templateUrl: './reservation-audit-results.component.html',
  styleUrls: ['./reservation-audit-results.component.scss'],
})
export class ReservationAuditResultsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;
  loading = true;
  displayedColumnsTable: string[] = [
    'id',
    'user',
    'space',
    'location',
    'reservationStartTime',
    'reservationEndTime',
    'reservationTimeZone',
    'reservationStatus',
    'reservationStatusDate',
    'reservationStatusResponsible',
  ];
  dataSource = new MatTableDataSource([]);
  subcriptions: Subscription[] = [];

  reservationData = [];
  INITIAL_PAGE = 1;
  INITIAL_PAGESIZE = 10;
  pageSize = this.INITIAL_PAGESIZE;
  pageIndex: number = this.INITIAL_PAGE;
  sortColumn: any;
  sortOrder: any;
  haveNextPage: boolean = null;
  initPaginatorText: number;
  endPaginatorText: number;
  totalReservationData: number;
  originFuntions = JSON.parse(sessionStorage.getItem(StorageVariables.FUNCTIONALITIES));
  filterparameters;
  users: any[] = [];
  fromDate: any;
  toDate: any;
  floors: Floor[];
  buildings: Building[];
  cities: any[];
  reservationJobActions = ReservationJobActions;
  noData = false;
  changingPage = false;
  downloadingExcel = false;
  lang: string;
  totalAuditData: number;

  constructor(
    private globalService: GlobalService,
    private habitatService: HabitatService,
    private reservationBackendService: ReservationService,
    private translateService: TranslateService,
    private auditService: AuditPagesService,
    private dateService: DateService,
    private languageService: LanguageService,
    private dialog: MatDialog,
    private userService: UserService
  ) {
    this.languageService.curLanguage.subscribe((lan: any) => {
      this.lang = lan;
    });
  }

  ngOnInit(): void {
    this.getUsers();
    const floors = this.habitatService.getFloors();
    const buildings = this.habitatService.getBuildings();
    const cities = this.habitatService.getCities();

    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
    });

    if (!this.floors || !this.buildings || !this.cities) {
      forkJoin([floors, buildings, cities]).subscribe((data) => {
        this.floors = data[0];
        this.buildings = data[1];
        this.cities = data[2];
        this.auditService.auditFilterParameters$.subscribe((filterparameters) => {
          this.pageIndex = this.INITIAL_PAGE;
          this.filterparameters = filterparameters;
          if (this.floors && !this.changingPage) {
            this.loadReservationsAudit();
          }
        });
      });
    }
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  getUsers(): void {
    this.userService.getUsers().subscribe((users: UserBuilding[]) => {
      users
        .map((user) => user.userInfo)
        .forEach((userItem: User) => {
          this.users.push({
            name: userItem.name?.concat(' ').concat(userItem?.surname),
            id: Number(userItem.employeeNumber),
          });
        });
    });

    this.reservationBackendService
      .getUsersFiltered(1, Constants.maxInt, null, null, null, null)
      .subscribe((reservationUsers: ReservationUsersFiltered) => {
        reservationUsers.users.forEach((userItem: ReservationUser) => {
          this.users.push({
            name: userItem?.surname ? userItem?.name?.concat(' ').concat(userItem?.surname) : userItem?.name,
            id: userItem?.employeeNumber,
          });
        });
      });
  }

  loadReservationsAudit(): void {
    this.noData = false;

    this.reservationData = [];
    this.dataSource.data = [];
    this.resultsLoading(true);
    this.fromDate = null;
    this.toDate = null;

    if (this.filterparameters.reservationsdateRange) {
      const timeZone = new City('').timezone;
      const startMoment = new Date(this.filterparameters.reservationsstartDateText).getTime();
      const endMoment = new Date(this.filterparameters.reservationsendDateText).getTime();
      this.fromDate = this.dateService.setTimeZone(startMoment, timeZone);
      this.toDate = this.dateService.setTimeZone(endMoment, timeZone);
    }
    this.reservationBackendService
      .getReservations(
        this.filterparameters.reservationsfloor,
        this.pageIndex,
        this.pageSize,
        this.filterparameters.reservationsstatus,
        this.filterparameters.reservationsuseType,
        this.filterparameters.reservationsuser,
        this.fromDate,
        this.toDate,
        this.filterparameters.reservationsbuilding,
        this.filterparameters.reservationsspace,
        this.filterparameters.reservationsLocalHour,
        true
      )
      .subscribe({
        next: (data) => {
          const reservationResults = data.reservations;
          const pagSizIn = this.pageIndex * this.pageSize;
          if (reservationResults.length == 0) {
            this.noData = true;
            this.reservationData = [];
            this.haveNextPage = false;
            this.updateTable();
          } else {
            // Check if there are more pages.
            if (reservationResults.length < this.pageSize + 1) {
              this.haveNextPage = true;
              this.reservationBackendService
                .getReservations(
                  this.filterparameters.reservationsfloor,
                  this.pageIndex + 1,
                  this.pageSize,
                  this.filterparameters.reservationsstatus,
                  this.filterparameters.reservationsuseType,
                  this.filterparameters.reservationsuser,
                  this.fromDate,
                  this.toDate,
                  this.filterparameters.reservationsbuilding,
                  this.filterparameters.reservationsspace,
                  this.filterparameters.reservationsLocalHour
                )
                .subscribe({
                  next: (data) => {
                    this.totalAuditData = data.total;
                    this.endPaginatorText = Math.min(pagSizIn, this.totalAuditData);
                    if (data.reservations.length !== 0) {
                      this.haveNextPage = true;
                    } else {
                      this.haveNextPage = false;
                    }
                  },
                  error: () => {
                    this.haveNextPage = false;
                  },
                });
            } else {
              this.haveNextPage = false;
            }
            this.buildPrintTable(reservationResults);
            this.initPaginatorText = 1 + pagSizIn - this.pageSize;
          }
        },
        error: () => {
          this.resultsLoading(false);
          this.noData = true;

          this.haveNextPage = false;
          this.changingPage = false;
        },
      });
  }

  buildPrintTable(reservationResults: Reservation[]): void {
    let reservationCount = 0;
    reservationResults.forEach((reservation: Reservation) => {
      let code = reservation.spaces ? '' : '--';
      const startDate = new Date(reservation.startDate);
      const endDate = new Date(reservation.endDate);

      if (reservation.spaces) {
        // Format code and entryDate strings (if the reservation is for more than one site, split using commas)
        for (let i = 0; i < reservation.spaces.length; i++) {
          if (i === reservation.spaces.length - 1) {
            code += reservation.spaces[i].code || '--';
          } else {
            if (reservation.spaces[i].code) {
              code += reservation.spaces[i].code;
              if (reservation.spaces[i + 1].code) {
                code += ', ';
              }
            }
          }
        }
      }

      // Format location string
      const rowId = reservation.id;
      const spaceFloor = reservation.spaces.find((space) => space.floor)?.floor || -1;
      const floor = this.floors.find((floor) => floor.id === spaceFloor);
      const building = this.buildings.find((building) => building.id === floor?.building);
      const status = this.translateService.instant(reservation.status);
      const city = this.cities.find((city) => city.id === building?.city);
      const location = city
        ? `${this.translateService.instant(city.name)} - ${building.name} - ${floor.name}`
        : this.translateService.instant('Reservable space not found');

      //Get registry for current reservation
      this.reservationBackendService
        .getReservationRegistry(reservation.id, this.filterparameters.reservationsLocalHour)
        .subscribe({
          next: (reservationRegistryResults: ReservationRegistry[]) => {
            reservationCount++;

            const registry = reservationRegistryResults.find((item) => item.status == reservation.status);
            const statusDate = new Date(registry.timestamp);

            let statusResponsible = '';
            if (registry.user > 0) {
              const auxUser = this.users.find((item) => item.id == registry.user);
              statusResponsible = auxUser?.name ? auxUser?.name : registry.user.toString();
            } else {
              statusResponsible = Object.keys(this.reservationJobActions).find((key) => {
                return this.reservationJobActions[key] === registry.user.toString();
              });
            }

            if (reservation.owner && reservation.owner instanceof Object) {
              this.reservationBackendService.getReservationUser(reservation.owner.employeeNumber).subscribe({
                next: (item) => {
                  this.reservationData.push({
                    id: rowId,
                    user: [item?.name, item?.surname].join(' ').trim(),
                    space: code,
                    location: location,
                    date: startDate,
                    reservationStartTime: startDate,
                    reservationEndTime: endDate,
                    reservationTimeZone: city?.timezone,
                    reservationStatus: status,
                    reservationStatusDate: statusDate,
                    reservationStatusResponsible: statusResponsible,
                  });
                  if (reservationResults.length == reservationCount) {
                    this.updateTable();
                  }
                },
                error: (e) => {
                  this.reservationData.push({
                    user: e,
                    space: code,
                    location: location,
                    date: startDate,
                    reservationStartTime: startDate,
                    reservationEndTime: endDate,
                    reservationTimeZone: city?.timezone,
                    reservationStatus: status,
                    reservationStatusDate: statusDate,
                    reservationStatusResponsible: statusResponsible,
                  });
                  if (reservationResults.length == reservationCount) {
                    this.updateTable();
                  }
                },
              });
            }
          },
          error: () => {
            reservationCount++;
            if (reservationResults.length == reservationCount) {
              this.updateTable();
            }
          },
        });
    });
  }

  updateTable(): void {
    this.dataSource.data = this.reservationData;
    this.resultsLoading(false);
    this.changingPage = false;
  }

  goNextPage(): void {
    if (this.haveNextPage) {
      this.changingPage = true;
      this.pageIndex = this.pageIndex + 1;
      this.loadReservationsAudit();
    }
  }

  goPreviousPage(): void {
    this.changingPage = true;
    this.pageIndex = this.pageIndex - 1;
    this.loadReservationsAudit();
  }

  resultsLoading(isLoading: boolean): void {
    this.loading = isLoading;
    this.auditService.isReservationTableLoading(isLoading);
  }

  sortData(event: any): void {
    this.sortColumn = event.active;
    this.sortOrder = event.direction;
    this.pageIndex = this.INITIAL_PAGE;
    this.loadReservationsAudit();
  }

  exportAsExcel(): void {
    this.downloadingExcel = true;
    const filesName = 'Habitat_Reservations_Audit' + '_' + this.dateService.getDateDownload(new Date());
    this.reservationBackendService
      .getReservationsAuditExcelExport(
        this.lang,
        this.filterparameters.reservationsfloor,
        this.filterparameters.reservationsuseType,
        this.filterparameters.reservationsuser,
        this.filterparameters.reservationsstatus,
        this.fromDate,
        this.toDate,
        this.filterparameters.reservationsspace,
        this.filterparameters.reservationsLocalHour
      )
      .subscribe({
        next: (excelFile: Blob) => {
          this.downloadingExcel = false;

          FileSaver.saveAs(excelFile, filesName);
        },
        error: (error) => {
          this.downloadingExcel = false;

          let infoModalData = null;
          this.downloadingExcel = false;
          if (error.status === 409) {
            infoModalData = new InfoModalData('Downloading error', 'Too many records. Please refine your search.');
          } else {
            infoModalData = new InfoModalData(
              'Downloading error',
              'An error has occurred, please try again. If it persists contact the administrator.'
            );
          }
          this.dialog.open(InfoModalComponent, {
            data: infoModalData,
            panelClass: 'custom-dialog',
            disableClose: true,
          });
        },
      });
  }
}
