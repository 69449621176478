import { Space } from '../esite/Space.class';
import { SpaceUseType } from '../esite/SpaceUseType.enum';

export class SpacesStructure {
  siteIds: Array<number> = [];
  roomIds: Array<number> = [];

  getSpaceType(spaceId: number): SpaceUseType {
    if (this.siteIds.includes(spaceId)) {
      return SpaceUseType.Desk;
    }
    if (this.roomIds.includes(spaceId)) {
      return SpaceUseType.Room;
    }
  }

  setSpaceToStructure(space: Space): void {
    if (space.useType === SpaceUseType.Desk) {
      this.siteIds.push(space.id);
    } else if (space.useType === SpaceUseType.Room) {
      this.roomIds.push(space.id);
    }
  }
}
