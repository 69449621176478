<collapse-card title="{{ 'New Role' | translate }}">
  <form class="row" [formGroup]="roleFormGroup" (ngSubmit)="insertRole()">
    <!-- NOMBRE NUEVO ROL -->
    <div class="col-md-5 col-9">
      <div class="mb-3">
        <mat-form-field class="form-group col-sm-6 paddingOdd firstDiv">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input
            title="{{ 'Name' | translate }}"
            matInput
            autocomplete="off"
            formControlName="name"
            type="text"
            class="p-1"
            maxlength="45"
            required
          />
          <mat-hint *ngIf="roleFormGroup.get('name')?.value.length" align="end"
            >{{ roleFormGroup.get('name')?.value.length }}/45</mat-hint
          >
          <mat-error *ngIf="f.name.errors?.required">{{ 'Name is required' | translate }}</mat-error>
          <mat-error *ngIf="f.name.errors?.duplicate">{{
            'There is already a role with that name.' | translate
          }}</mat-error>
          <mat-error *ngIf="f.name.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
        </mat-form-field>
        
      </div>
      <div class="mb-4">
        <p-toggleButton
          id="checkedWriteRead"
          name="checkedWriteRead"
          [ngModelOptions]="{standalone: true}"
          [(ngModel)]="checkedWriteRead"
          onLabel="{{ 'WRITE' | translate }}"
          offLabel="{{ 'READ' | translate }}"
          [onIcon]="'pi pi-check-square'"
          offIcon="pi pi-minus-circle"
        >
        </p-toggleButton>
      </div>
      <div class="mb-4">
        <p-toggleButton
          id="checkedDefault"
          name="checkedDefault"
          [ngModelOptions]="{standalone: true}"
          [(ngModel)]="checkedDefault"
          onLabel="{{ 'Default' | translate }}"
          offLabel="{{ 'No Default' | translate }}"
          [onIcon]="'pi pi-circle-on'"
          offIcon="pi pi-circle-off"
        >
        </p-toggleButton>
      </div>
    </div>

    <div class="col-md-1"></div>
    <!-- LISTA PERMISOS A SELECCIONAR -->
    <div class="col-md-6 col-12" style="border: solid 2px #0075aa">
      <div class="data-container p-2">
        <mat-checkbox
          name="all-perm"
          [(ngModel)]="allSelected"
          [ngModelOptions]="{ standalone: true }"
          (change)="checkAllSelected($event)"
        >
          {{ 'Seleccionar todos' | translate }}</mat-checkbox
        >
        <div style="width: 100%; margin-right: 20px" *ngFor="let permissionChk of permissionsChk">
          <mat-checkbox
            id="{{ permissionChk.id }}"
            name="{{ permissionChk.id }}"
            [(ngModel)]="permissionChk.checked"
            [ngModelOptions]="{ standalone: true }"
            (change)="changeAllSelected()"
            [disabled]="permissionChk.disabled"
            >{{ permissionChk.data.name }}</mat-checkbox
          >
        </div>
      </div>
    </div>

    <!-- boton guardar -->
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow" style="justify-content: flex-end">
      <button mat-button class="modalButton bg-primary" type="submit" [disabled]="disabledSave">
        {{ 'Save' | translate }}
      </button>
    </div>
  </form>
</collapse-card>
