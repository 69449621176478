<div class="confirmDialog">
  <div class="marginBodyCard">
    <div class="titleN3 text-primary modalTitle col-12" style="text-align-last: center; overflow-wrap: break-word">{{
      title | translate
    }}</div>
    <div style="text-align: center; white-space: pre-line">
      {{ message | translate }}
    </div>
    <div class="buttonsRow">
      <button mat-button class="modalCancel text-primary" (click)="cancel()">{{ 'Cancel' | translate }}</button>
      <button *ngIf="canConfirm" mat-button class="modalButton bg-primary" (click)="confirm()">
        {{ 'accept' | translate }}
      </button>
    </div>
  </div>
</div>
