import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';

// ngx
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// App
import { SharedModule } from '../shared/shared.module';
import { AuthComponent } from './auth.component';
import { BlankComponent } from './blank/blank.component';
import { PrivacyPolicyComponent } from './legal-terms/privacy-policy/privacy-policy.component';
import { LoginErrorComponent } from './login-error/login-error.component';
import { LoginHeaderComponent } from './login-header/login-header.component';
import { NotPermissionAuthComponent } from './not-permission-auth/not-permission-auth.component';
import { TokenExpirationAlertComponent } from './token-expiration-alert/token-expiration-alert.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    PrivacyPolicyComponent,
    TokenExpirationAlertComponent,
    LoginErrorComponent,
    NotPermissionAuthComponent,
    LoginHeaderComponent,
    BlankComponent,
    AuthComponent,
  ],
  exports: [LoginHeaderComponent, AuthComponent],
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    MatInputModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
})
export class AuthModule {}
