import {
  NGX_MAT_DATE_FORMATS,
  NgxMatDateFormats,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgxMatSelectModule } from 'ngx-mat-select';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DropdownModule } from 'primeng/dropdown';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeModule } from 'primeng/tree';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';

import { ChartBarsComponent } from './charts/chart-bars/chart-bars.component';
import { ChartCircleprogressComponent } from './charts/chart-circleprogress/chart-circleprogress.component';
import { ChartDonutComponent } from './charts/chart-donut/chart-donut.component';
import { ChartLineComponent } from './charts/chart-line/chart-line.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { CreateincidentComponent } from './createincident/createincident.component';
import { HeaderComponent } from './header/header.component';
import { InfoModalComponent } from './info-modal/info-modal.component';
import { LoadingComponent } from './loading/loading.component';
import { MenuInformesComponent } from './menu-informes/menu-informes.component';
import { NodataCardComponent } from './nodata-card/nodata-card.component';
import { NotificationbarComponent } from './notificationbar/notificationbar.component';
import { SelectDatesComponent } from './select-dates/select-dates.component';
import { SpaceFilterComponent } from './space-filter/space-filter.component';
import { SvgMapComponent } from './svg-map/svg-map.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { CollapseCardComponent } from './collapse-card/collapse-card.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const CUSTOM_DATE_FORMATS: NgxMatDateFormats = {
  parse: {
    dateInput: 'l, LTS',
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm:ss',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
    { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  ],
  declarations: [
    HeaderComponent,
    NotificationbarComponent,
    SpaceFilterComponent,
    CreateincidentComponent,
    ChartDonutComponent,
    ChartCircleprogressComponent,
    ChartBarsComponent,
    ChartLineComponent,
    SvgMapComponent,
    LoadingComponent,
    ConfirmModalComponent,
    InfoModalComponent,
    SelectDatesComponent,
    NodataCardComponent,
    MenuInformesComponent,
    CollapseCardComponent,
  ],
  imports: [
    NgxPopperjsModule,
    MatTableModule,
    CommonModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule,
    ScrollingModule,
    MatFormFieldModule,
    MatSelectModule,
    NgxMatSelectModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatCheckboxModule,
    MatListModule,
    MatPaginatorModule,
    MatInputModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatInputModule,
    NgxMatTimepickerModule,
    FormsModule,
    ReactiveFormsModule,
    MatGridListModule,
    MatCardModule,
    MatExpansionModule,
    MatTooltipModule,
    MatButtonModule,
    NgxMatDatetimePickerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 16,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
    NgxMatSelectModule,
    OverlayPanelModule,
    CalendarModule,
    SelectButtonModule,
    MatButtonToggleModule,

    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatDialogModule,
    /** PrimeNG */
    TableModule,
    TreeModule,
    ContextMenuModule,
    MessageModule,
    ToastModule,
    SlideMenuModule,
    ButtonModule,
    TagModule,
    ToggleButtonModule,
    TriStateCheckboxModule,
    DropdownModule,
    MultiSelectModule,
  ],
  exports: [
    NgxPopperjsModule,
    HeaderComponent,
    NotificationbarComponent,
    SpaceFilterComponent,
    CollapseCardComponent,
    CreateincidentComponent,
    ChartDonutComponent,
    ChartCircleprogressComponent,
    ChartBarsComponent,
    ChartLineComponent,
    SvgMapComponent,
    LoadingComponent,
    ConfirmModalComponent,
    SelectDatesComponent,
    NodataCardComponent,
    NgxMatSelectModule,
    MatSelectModule,
    NgxMatSelectModule,
    OverlayPanelModule,
    CalendarModule,
    SelectButtonModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatTooltipModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonToggleModule,
    MatSliderModule,
    MatDialogModule,
    MenuInformesComponent,
    TreeModule,
    ContextMenuModule,
    MessageModule,
    ToastModule,
    TagModule,
    ToggleButtonModule,
    TriStateCheckboxModule,
    DropdownModule,
    MultiSelectModule,
  ],
})
export class SharedModule {}
