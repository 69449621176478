export const colorPalette = [
  '#303496',
  '#960F68',
  '#174874',
  '#DDB322',
  '#798D44',
  '#1994A4',
  '#BC1323',
  '#D76734',
  '#3A679A',
  '#8F454E',
  '#FF3323',
  '#DB3343',
  '#239167',
  '#6D3C9F',
  '#280F3C',
  '#FFB923',
  '#2F1C86',
  '#295D8B',
  '#ECAD4D',
  '#1B4B0E',
  '#02606D',
];
