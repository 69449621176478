import { Group } from './habitat/Group.class';

export class InfoModalData {
  title: string;
  message: string;
  hierarchy?: boolean;
  group?: Group;

  constructor(title: string, message: string, hierarchy?: boolean, group?: Group) {
    this.title = title;
    this.message = message;
    this.hierarchy = hierarchy;
    this.group = group;
  }
}
