import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpService } from '../common/http.service';

/**
 * Service for making request to habitat general module (common processing shared between multiple habitat modules)
 */
@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private httpService: HttpService) {}

  private requestToHabitatExcel(method: string): Observable<Blob> {
    const url = `${environment.general_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequestExcel(protocol + url, method);
  }

  /**
   * Request for retrieving floor's data (sites information, building parameters...)
   * @param floorID ID of the floor to retrieve
   * @param imageFileName Name to use for floor's image name
   * @returns Blob with the floor's site data
   */
  getFloorMetadata(floorID: number, imageFileName: string): Observable<Blob> {
    const method = 'v2/floorMetadata?floorID=' + floorID + '&buildingImageName=' + imageFileName;
    return this.requestToHabitatExcel(method);
  }
}
