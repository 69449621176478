import { Injectable } from '@angular/core';
import { forkJoin, Observable, Subject } from 'rxjs';
import { SidebarNotification } from 'src/app/models/notifications/SidebarNotification.interface';
import { ConteoService } from '../backend/conteo.service';
import { EsiteService } from '../backend/esite.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  newNotification$: Subject<SidebarNotification> = new Subject();
  emptyNotifications$: Subject<string> = new Subject();

  constructor(private esiteService: EsiteService, private conteoService: ConteoService) {}

  emitNewNotification(notification: SidebarNotification): void {
    this.newNotification$.next(notification);
  }

  clearNotificatonBar(type: string): void {
    this.emptyNotifications$.next(type);
  }

  getConteoAlarmConfig(configAlarmId: string): Observable<any> {
    return this.conteoService.getConteoAlarm(configAlarmId);
  }

  getProximityAlarm(): Observable<any> {
    return this.esiteService.getProximityAlarm();
  }

  getEsiteOcupationMessages(): Observable<any> {
    return this.esiteService.connectToWebsocket();
  }

  getConteoMessages(): Observable<any> {
    return this.conteoService.connectToWebsocket();
  }

  getAlarmSpacesName(space1, space2): Observable<any> {
    const name1 = this.esiteService.getSpace(space1);
    const name2 = this.esiteService.getSpace(space2);
    return forkJoin([name1, name2]);
  }

  getSpace(spaceId): Observable<any> {
    return this.esiteService.getSpace(spaceId);
  }

  getZones(): Observable<any> {
    return this.conteoService.getZones();
  }

  getZone(zoneId): Observable<any> {
    return this.conteoService.getZone(zoneId);
  }

  insertConteoAlarmConfig(newConfig): Observable<any> {
    return this.conteoService.insertConfigAlarm(newConfig);
  }
}
