import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { from, Subject, Subscription } from 'rxjs';
import { finalize, mergeMap, takeUntil } from 'rxjs/operators';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { ReservationRoles } from 'src/app/models/reservation/ReservationRoles.const';
import { ReservationUsersFiltered } from 'src/app/models/reservation/ReservationUsersFiltered.class';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { LanguageService } from 'src/app/services/common/language.service';
import { environment } from 'src/environments/environment';

import { ConfirmModalComponent } from '../../shared/confirm-modal/confirm-modal.component';
import { NotPermissionComponent } from '../not-permission/not-permission.component';
import { Permission, Role, UserBuilding } from './../../../models/auth/Role.class';
import { User } from './../../../models/auth/User.class';
import { UserPermissions } from './../../../models/auth/UserPermissions.enum';
import { ReservationUser } from './../../../models/reservation/ReservationUser.class';
import { UserService } from './../../../services/backend/user.service';
import { AppConstants } from '../../../shared/AppConstants';
import { AddReservationUserFormComponent } from './add-reservation-user-form/add-reservation-user-form.component';
import { ContinuousReserveComponent } from './continuous-reserve/continuous-reserve.component';
import { ReserveTableComponent } from './reserve-table/reserve-table.component';
import { UserFormComponent } from './user-form/user-form.component';
import { UsersFiltered } from 'src/app/models/auth/UsersFiltered.class';
import { MatTabChangeEvent } from '@angular/material/tabs';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;
  IsChecked: boolean;
  public selectedRole: string;
  dataSource = new MatTableDataSource([]);
  dataTable = [];
  displayedColumnsTable: string[] = ['name', 'role', 'email', 'action'];
  subcriptions: Subscription[] = [];
  @ViewChildren('checkBox') checkBox: QueryList<any>;
  /** ----- pagination ----- */
  auxCopy = [];
  disabledPaginator = true;
  operationLength = 0;
  pageSize = AppConstants.SIZE_TABLE_PAGINATOR;
  pageEvent: PageEvent;
  lastSort: Sort;
  pageIndex = 1;
  @ViewChildren('row', { read: ElementRef }) rowElement: QueryList<ElementRef>;
  auxCompleta: any;
  @ViewChild('pag') paginator: MatPaginator;
  hidePageSize = true;
  /** ------------------------ */
  loading = true;
  reservationUsersTitle;
  habitatUsersTitle;
  habitatTab = true;
  habitatRoles: string[] = [];
  reservationRoles = ReservationRoles;
  canEditReservationRole = false;
  hasReservationsModule = false;
  nttdata = environment.nttdata;
  INITIAL_PAGE = AppConstants.INITIAL_TABLE_PAGE;
  INITIAL_PAGESIZE = AppConstants.SIZE_TABLE_PAGINATOR;
  haveNextPage: boolean = null;
  searchValue: string;
  pagesNumber: number;
  initPaginatorText: number;
  endPaginatorText: number;
  totalUsers: number;
  sortColumn: any = null;
  sortOrder: any = null;
  userLogged: User;
  users: User[] | ReservationUser[] = [];
  canEditUsers = false;
  loadingFull = true;
  firstTabChange = true;
  buildings: Building[] = [];
  private permissions: Permission[] = [];
  havePermission = true;
  canWriteUser = false;
  ownReservation: number[] = [];
  allowReservation = new Map<number, boolean>(); // HashMap que por cada clave (id de usuario de reservas) indica si puede reservar (tiene permiso OwnReservations? true : false)
  roles = null;
  rolesArray = [];

  constructor(
    private dialog: MatDialog,
    private globalService: GlobalService,
    private habitatService: HabitatService,
    private reservationService: ReservationService,
    private languageService: LanguageService,
    private userService: UserService,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {
    this.languageService.curLanguage.subscribe((lan) => {
      if (lan == 'es') {
        this.reservationUsersTitle = 'USUARIOS RESERVAS';
        this.habitatUsersTitle = 'USUARIOS HABITÄT';
      } else if (lan == 'en') {
        this.reservationUsersTitle = 'RESERVATION USERS';
        this.habitatUsersTitle = 'HABITÄT USERS';
      } else if (lan == 'it') {
        this.reservationUsersTitle = 'PRENOTAZIONE UTENTI';
        this.habitatUsersTitle = 'HABITÄT UTENTI';
      }
    });
    this.hasReservationsModule = this.globalService.getReservationModule();
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
    this.ckeckPermissionsUser(this.permissions);
  }

  ngOnInit(): void {
    if (!this.permissions?.map((permission) => permission.name).includes(UserPermissions.Users)) {
      this.havePermission = false;
      this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
      this.messageService.add({
        key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
        severity: 'warn',
        summary: this.translateService.instant('Warning'),
        detail: this.translateService.instant('You do not have the Users permission'),
      });
      this.dialog.open(NotPermissionComponent, { disableClose: true });
    } else {
      this.getAllRoles();
      this.getRolesWithPermission('OwnReservations');
      this.canEditUsers =
        this.permissions?.find(
          (permission) => permission.name == UserPermissions.Users && permission.type == 'WRITE'
        ) !== undefined;
      this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
        this.responsive = isResponsive;
      });

      this.loadingFull = false;
      this.getHabitatOrReservationUsers();
    }
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  ckeckPermissionsUser(permissions: Permission[]): void {
    this.canWriteUser =
      permissions?.find((item) => item.name === UserPermissions.Users && item.type === 'WRITE') !== undefined;
  }

  getRolesWithPermission(permission: string): void {
    this.userService.getRolesByPermission(permission).subscribe((role: Role[]) => {
      role.forEach((item) => {
        this.ownReservation.push(item.id);
      });
    });
  }

  getAllRoles(): void {
    this.userService.getRoles().subscribe((roles: Role[]) => {
      this.habitatRoles = roles?.map((role) => role.name);
    });
  }

  findUser(id: number): User {
    return (this.users as User[]).find((item) => item.id == id);
  }

  findReservationUser(id: number): ReservationUser {
    return (this.users as ReservationUser[]).find((item) => parseInt(item.employeeNumber) == id);
  }

  editUser(id: number): void {
    this.subcriptions.push(
      this.userService.getUser(id).subscribe((userBuilding: UserBuilding) => {
        this.buildings = [];
        from(userBuilding.roles.filter((userRole) => userRole.building > -1).map((userRole) => userRole.building))
          .pipe(
            mergeMap((idBuilding) => this.habitatService.getBuilding(idBuilding)),
            finalize(() => {
              const user = (this.users as User[]).find((item) => item.id == id);
              user.buildings = this.buildings;
              this.openModal(user);
            })
          )
          .subscribe((building) => {
            this.buildings.push(building);
          });
      })
    );
  }

  openModal(user): void {
    const dialogRef = this.dialog.open(UserFormComponent, { data: { user }, disableClose: true });
    this.subcriptions.push(
      dialogRef.afterClosed().subscribe((result: { data: any; messageToClosed: string }) => {
        if (result.data) {
          this.getHabitatOrReservationUsers();
        }
      })
    );
  }

  OpenAddNttdatauser(): void {
    const dialogRef = this.dialog.open(AddReservationUserFormComponent, { disableClose: true });
    this.subcriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.getReservationUsers();
        }
      })
    );
  }

  updateTable(): void {
    this.dataTable = this.users.map((user) => ({
      employeeNumber: user.employeeNumber,
      id: user.id,
      name: user.name,
      nameComplete: user.nameComplete,
      rolesName: user.rolesName, // User (Habitat)
      role: user.role, // ReservationUser (Reservation)
      email: user.email,
      office: user.office, // ReservationUser (Reservation)
      area: user.area, // ReservationUser (Reservation)
      ownReservation: this.allowReservation.get(user.id),
      hidden: user.hidden,
    }));
    this.dataSource.data = this.dataTable;
    /** ----- pagination ----- */
    this.buildPagination();
    /** ------------------------ */

    if (this.habitatTab && this.searchValue) {
      this.dataSource.filter = this.searchValue;
    }
    this.loading = false;
  }

  buildAllowReservationMap(): void {
    from(this.users.map((user) => user.id))
      .pipe(
        mergeMap((userID) => this.userService.getUser(userID)),
        finalize(() => {
          this.updateTable();
        })
      )
      .subscribe((userBuilding: UserBuilding) => {
        const arrayId: number[] = [];
        userBuilding.roles.forEach((role) => {
          arrayId.push(role.role.id);
        });

        //uniqueArraId contiene los elementos de arrayId sin repetidos
        const uniqueArrayId = arrayId.filter(function (elem, index, self) {
          return index === self.indexOf(elem);
        });
        const found = uniqueArrayId.some((result) => this.ownReservation.includes(result));
        this.allowReservation.set(userBuilding.userInfo.id, found);
      });
    this.loading = false;
  }

  filterHabitatOrReservationUsers(): void {
    this.pageIndex = 1;
    this.getHabitatOrReservationUsers();
  }

  filterUsers(): void {
    const succeededChecks = this.checkedRoles();
    const dataFiltered = [];

    succeededChecks.forEach((itemC) => {
      dataFiltered.push(itemC.name);
    });
    this.rolesArray = dataFiltered;
    this.roles = dataFiltered.toString();
    this.getHabitatOrReservationUsers();
  }

  checkedRoles() {
    let checkBoxs = null;
    if (this.checkBox) {
      checkBoxs = this.checkBox['_results'];
    }
    const checkBoxsProperties = [];
    for (let i = 0; i < checkBoxs?.length; i++) {
      const obj = {
        name: checkBoxs[i].name,
        checked: checkBoxs[i]['_checked'],
      };
      checkBoxsProperties.push(obj);
    }
    return checkBoxsProperties?.filter((check) => check.checked);
  }

  deleteUser(id: number): void {
    const modalData = new ConfirmModalData('DeleteUser', 'InfoDeleteUser');
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        if (id) {
          this.subcriptions.push(
            this.userService.deleteLogicalUser(id).subscribe({
              next: () => {
                this.getHabitatOrReservationUsers();
                this.globalService.printMessage('User deleted successfully');
              },
              error: () => {
                this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
                this.messageService.add({
                  key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
                  severity: 'error',
                  summary: 'Error',
                  detail: this.translateService.instant('The user could not be deleted'),
                });
              },
            })
          );
        }
      }
    });
  }

  reactivateUser(id: number): void {
    this.subcriptions.push(
      this.userService.reactivateUser(id).subscribe({
        next: () => {
          this.getHabitatOrReservationUsers();
          this.globalService.printMessage('GenericSuccess', 'success');
        },
        error: () => {
          this.globalService.printMessage('SendingError', 'error', 'Error');
        },
      })
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onTabChange(tabEvent: MatTabChangeEvent): void {
    this.loading = true;
    this.pageIndex = this.INITIAL_PAGE;
    this.sortColumn = null;
    this.sortOrder = null;
    this.roles = null;
    this.rolesArray = [];

    if (tabEvent.tab.bodyClass === 'js-reservation-users-tab') {
      this.habitatTab = false;
      this.displayedColumnsTable = ['name', 'ownReservation', 'role', 'email', 'office', 'area', 'actionReservation'];
      this.dataSource.data = [];
      this.getReservationUsers();
    } else {
      this.habitatTab = true;
      this.displayedColumnsTable = ['name', 'role', 'email', 'action'];
      this.dataSource.data = [];
      this.getHabitatOrReservationUsers();
    }
  }

  editUserReservation(id: number): void {
    const user = this.findReservationUser(id);
    const dialogRef = this.dialog.open(UserFormComponent, { data: { user, reservation: true }, disableClose: true });
    this.subcriptions.push(
      dialogRef.afterClosed().subscribe((result) => {
        if (result?.data) {
          this.getReservationUsers();
        }
      })
    );
  }

  getReservationUsers(): void {
    this.canEditReservationRole =
      this.permissions?.find((permission) => permission.name == UserPermissions.Users && permission.type == 'WRITE') !==
      undefined;
    this.getHabitatOrReservationUsers();
  }

  getUsersHabitat(): void {
    this.userService
      .getUsersFiltered(this.pageIndex, this.pageSize, this.searchValue, this.roles, this.sortColumn, this.sortOrder)
      .subscribe({
        next: (users: UsersFiltered) => {
          this.users = [];

          users.users.forEach((userItem) => {
            const userHabitat = new User(userItem.userInfo);
            userHabitat.roles = userItem.roles;
            (this.users as User[]).push(new User(userHabitat));
          });
          this.haveNextPage = this.pageSize * this.pageIndex < users.total;
          this.totalUsers = users.total;
          this.initPaginatorText = 1 + this.pageSize * this.pageIndex - this.pageSize;
          this.endPaginatorText = Math.min(this.pageIndex * this.pageSize, this.totalUsers);
          this.buildAllowReservationMap();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  getUsersReservation(): void {
    this.reservationService
      .getUsersFiltered(this.pageIndex, this.pageSize, this.searchValue, this.roles, this.sortColumn, this.sortOrder)
      .subscribe({
        next: (users: ReservationUsersFiltered) => {
          this.users = [];

          users.users.forEach((user) => {
            const userReservation = new ReservationUser(user);
            (this.users as ReservationUser[]).push(userReservation);
          });

          this.haveNextPage = this.pageSize * this.pageIndex < users.total;
          this.totalUsers = users.total;
          this.initPaginatorText = 1 + this.pageSize * this.pageIndex - this.pageSize;
          this.endPaginatorText = Math.min(this.pageIndex * this.pageSize, this.totalUsers);
          this.buildAllowReservationMap();
        },
        error: () => {
          this.loading = false;
        },
      });
  }

  getHabitatOrReservationUsers(): void {
    this.loading = true;
    if (this.habitatTab && this.canEditUsers) {
      this.getUsersHabitat();
    } else {
      this.getUsersReservation();
    }
  }

  goNextPage(): void {
    if (this.haveNextPage) {
      this.pageIndex = this.pageIndex + 1;
      this.getHabitatOrReservationUsers();
    }
  }

  goPreviousPage(): void {
    this.pageIndex = this.pageIndex - 1;
    this.getHabitatOrReservationUsers();
  }

  triggerSearch(): void {
    this.pageIndex = this.INITIAL_PAGE;
    this.getHabitatOrReservationUsers();
  }

  showReservations(id: number): void {
    this.dialog.open(ReserveTableComponent, { data: { user: id }, disableClose: true });
  }

  showContinuousReservations(id: number): void {
    this.dialog.open(ContinuousReserveComponent, { data: { user: id }, disableClose: true });
  }

  buildPagination(): void {
    // pagination
    this.auxCompleta = this.dataSource.data;
    this.disabledPaginator = false;
    this.paginationLoad(this.dataSource.data);
    if (this.lastSort !== undefined) {
      this.sortData(this.lastSort);
    }
    this.irInicio();
  }

  irInicio(): void {
    const posPartida = 0 * this.pageSize;
    let posFinal = this.pageSize + posPartida;
    const aux = [];
    if (posFinal > this.operationLength) {
      posFinal = this.operationLength;
    }
    for (let i = posPartida; i < posFinal; i++) {
      aux.push(this.auxCompleta[i]);
    }

    this.dataSource.data = aux;
    this.paginator.pageIndex = 0;
  }

  paginationLoad(data: any): void {
    const aux = [];
    let size = AppConstants.SIZE_TABLE_PAGINATOR;
    let index = 0;
    if (this.pageEvent !== undefined) {
      size = this.pageEvent.pageSize;
      index = this.pageEvent.pageSize * this.pageEvent.pageIndex;
    }
    if (size > data.length) {
      size = data.length;
    }

    let length = index + size;
    if (length > data.length) {
      length = data.length;
    }

    for (let i = index; i < length; i++) {
      aux.push(data[i]);
    }

    this.dataSource.data = [...aux];
    this.auxCopy = [...data];
    this.operationLength = data.length;
  }

  sortData(sort: Sort): void {
    const data = this.auxCopy.slice();
    if (!sort.active || sort.direction === '') {
      this.auxCopy = data;
      return;
    }
    this.lastSort = sort;
    this.auxCopy = [...data].sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'nameComplete':
          return this.compare(a.nameComplete, b.nameComplete, isAsc);
        case 'name':
          return this.compare(a.name, b.name, isAsc);
        case 'email':
          return this.compare(a.email, b.email, isAsc);
        case 'role':
          return this.compare(a.role, b.role, isAsc);
        case 'office':
          return this.compare(a.office, b.office, isAsc);
        case 'area':
          return this.compare(a.area, b.area, isAsc);
        default:
          return 0;
      }
    });

    this.paginationLoad(this.auxCopy);
  }

  compare(a: number | string, b: number | string, isAsc: boolean): number {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
}
