export class ContinuousReservation {
  id?: number;
  reservablespacesid: string;
  reservationusersid: number;
  reservationusersName: string;
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  spaceCode: string;
  location: string;
  iconCross: boolean;
  iconDelete: boolean;
  iconEdit: boolean;
  timezone: string;
  status: string;

  constructor(msg) {
    this.id = msg?.id;
    this.location = msg?.location;
    this.reservablespacesid = msg?.reservablespacesid;
    this.reservationusersid = msg?.reservationusersid;
    this.reservationusersName = msg?.reservationusersName;
    this.startDate = msg?.startDate;
    this.endDate = msg?.endDate;
    this.startTime = msg?.startTime;
    this.endTime = msg?.endTime;
    this.spaceCode = msg?.spaceCode;
    this.iconCross = msg?.iconCross;
    this.iconDelete = msg?.iconDelete;
    this.iconEdit = msg?.iconEdit;
    this.timezone = msg?.timezone;
    this.status = msg?.status;
  }

  toBackenFormat(): any {
    const backendObject = {
      reservablespacesid: this.reservablespacesid,
      reservationusersid: this.reservationusersid,
      startDate: this.startDate,
      endDate: this.endDate,
      startTime: this.startTime,
      endTime: this.endTime,
      timezone: this.timezone,
    };
    return backendObject;
  }
  toBackenModifyFormat(): any {
    const backendObject = {
      endDate: this.endDate,
    };
    return backendObject;
  }
}
