import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnDestroy, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';

import { Permission } from './../../../../../models/auth/Role.class';
import { UserPermissions } from './../../../../../models/auth/UserPermissions.enum';
import { Resource } from './../../../../../models/esite/Resource.class';
import { ResourceType } from './../../../../../models/esite/ResourceType.class';
import { GlobalService } from './../../../../../services/common/global.service';
import { ResourcesService } from '../../../../../services/backend/resources.service';
import { User } from 'src/app/models/auth/User.class';

class ResourceTableItem {
  id: number;
  code: string;
  name: string;
  typeId: number;
  codeType: string;
  nameType: string;
  iconType: string;
  constructor(resource: Resource, rscType: ResourceType) {
    this.id = resource.id;
    this.code = resource.code;
    this.name = resource.name;
    this.typeId = rscType?.id;
    this.codeType = rscType?.code;
    this.nameType = rscType?.name;
    this.iconType = rscType?.icon;
  }
}

@Component({
  selector: 'app-resource-configuration',
  templateUrl: './resource-configuration.component.html',
  styleUrls: ['../resources-configuration.component.css'],
})
export class ResourceConfigurationComponent implements OnChanges, OnDestroy {
  @ViewChild('newOrEditType') newOrEditType;
  @Input() resourceTypes: ResourceType[] | null;
  resourceList: ResourceTableItem[] = [];
  resourceForm: UntypedFormGroup;
  loading = true;
  dataSource = new MatTableDataSource([]);
  displayedColumnsTable: string[] = ['code', 'name', 'iconType', 'nameType', 'action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  modalTitle = '';
  dialogRef: MatDialogRef<any>;
  submitted = false;
  subcriptions: Subscription[] = [];
  itsEditing = false;
  canWriteResource = false;
  userLogged: User;
  messageErrorResource = null;

  constructor(
    private resourceService: ResourcesService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private globalService: GlobalService
  ) {
    this.userLogged = this.globalService.getUser();
    this.checkPermissionsUser(this.userLogged.permissions);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resourceTypes?.currentValue) {
      this.resourceTypes = changes.resourceTypes.currentValue;
      this.getData();
    }
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  checkPermissionsUser(permissions: Permission[]): void {
    this.canWriteResource =
      permissions?.find((item) => item.name === UserPermissions.Resources && item.type == 'WRITE') !== undefined;
  }

  getData(): void {
    this.loading = true;
    this.resourceList = [];
    this.subcriptions.push(
      this.resourceService.getResources().subscribe((resources: Resource[]) => {
        this.resourceList = resources.map(
          (rsc) =>
            new ResourceTableItem(
              rsc,
              this.resourceTypes.find((item) => item.id == rsc.typeId)
            )
        );
        this.dataSource.data = this.resourceList;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      })
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createNewResource(): void {
    this.itsEditing = false;
    this.buildForm();
    this.openModal('New resource');
  }

  openModal(title: string): void {
    this.modalTitle = title;
    this.dialogRef = this.dialog.open(this.newOrEditType, { panelClass: 'custom-dialog', disableClose: true });
  }

  buildForm(): void {
    this.resourceForm = this.formBuilder.group({
      code: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]],
      name: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]],
      type: [undefined, [Validators.required]],
    });
  }

  cancel(): void {
    this.dialogRef.close();
    this.submitted = false;
  }

  onSubmit(): void {
    this.submitted = true;

    if (this.resourceForm.invalid) {
      this.submitted = false;
      return;
    }

    if (
      this.resourceList.find(
        (item) => item.name === this.resourceForm.value.name && item.id !== this.resourceForm.value.id
      )
    ) {
      setTimeout(() => {
        this.resourceForm.get('name').markAsDirty();
        this.resourceForm.get('name').markAsTouched();
        this.resourceForm.get('name').setErrors({ duplicate: true });
      }, 1);
      this.submitted = false;

      return;
    }

    if (
      this.resourceList.find(
        (item) => item.code === this.resourceForm.value.code && item.id !== this.resourceForm.value.id
      )
    ) {
      setTimeout(() => {
        this.resourceForm.get('code').markAsDirty();
        this.resourceForm.get('code').markAsTouched();
        this.resourceForm.get('code').setErrors({ duplicate: true });
      }, 1);
      this.submitted = false;

      return;
    }

    const newEditResource = new Resource({
      id: this.resourceForm.value.id,
      code: this.resourceForm.value.code,
      name: this.resourceForm.value.name,
      typeId: this.resourceForm.value.type,
    });

    if (!this.itsEditing) {
      this.subcriptions.push(
        this.resourceService.saveResource(newEditResource).subscribe({
          next: (res) => {
            const resType = this.resourceTypes.find((item) => item.id == res.typeId);
            const rsc: ResourceTableItem = new ResourceTableItem(
              new Resource({
                id: res.id,
                code: res.code,
                name: res.name,
                typeId: resType.id,
              }),
              resType
            );
            this.resourceList.push(rsc);
            this.dataSource.data = this.resourceList;
            this.cancel();
            this.globalService.printMessage('Resource modified', 'success');
          },
          error: (err: HttpErrorResponse) => {
            this.setFormErrors(err);
            this.submitted = false;
            this.globalService.printMessage('Resource could not be modified', 'error');
          },
        })
      );
    } else {
      this.subcriptions.push(
        this.resourceService.saveResource(newEditResource).subscribe({
          next: (res) => {
            const modifiedElement = this.resourceList.findIndex((item) => item.id === res.id);
            const resType = this.resourceTypes.find((item) => item.id == res.typeId);
            this.resourceList[modifiedElement] = {
              id: res.id,
              code: res.code,
              name: res.name,
              typeId: resType.id,
              codeType: resType?.code ?? '-',
              nameType: resType?.name ?? '-',
              iconType: resType?.icon ?? '-',
            };
            this.dataSource.data = this.resourceList;
            this.cancel();
            this.globalService.printMessage('Resource saved', 'success');
          },
          error: (err: HttpErrorResponse) => {
            this.setFormErrors(err);
            this.submitted = false;
            this.globalService.printMessage('Resource could not be saved', 'error');
          },
        })
      );
    }
  }

  deleteResource(resource: ResourceTableItem): void {
    const modalData = new ConfirmModalData('DeleteResource', 'InfoDeleteResource');
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        this.subcriptions.push(
          this.resourceService.deleteResource(resource.id).subscribe({
            next: () => {
              const deletedElement = this.resourceList.findIndex((rsc) => rsc.id === resource.id);
              this.resourceList.splice(deletedElement, 1);
              this.dataSource.data = this.resourceList;
              this.globalService.printMessage('Resource deleted', 'success');
            },
            error: () => {
              this.globalService.printMessage('Could not delete resource, please try again', 'error');
            },
          })
        );
      }
    });
  }

  editResource(resource: ResourceTableItem): void {
    this.itsEditing = true;

    this.resourceForm = this.formBuilder.group({
      id: resource.id,
      code: [resource.code, [Validators.required, Validators.pattern(/^[^,]+$/)]],
      name: [resource.name, [Validators.required, Validators.pattern(/^[^,]+$/)]],
      type: [resource.typeId, [Validators.required]],
    });

    this.openModal('Edit resource');
  }

  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.resourceForm.controls;
  }

  private setFormErrors(err: HttpErrorResponse): void {
    console.log(err);
    if (err.status === 409) {
      this.messageErrorResource = err.error.message;
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
    } else {
      setTimeout(() => {
        this.resourceForm.get('name').markAsDirty();
        this.resourceForm.get('name').markAsTouched();
        this.resourceForm.get('name').setErrors({ unknown: true });

        this.resourceForm.get('code').markAsDirty();
        this.resourceForm.get('code').markAsTouched();
        this.resourceForm.get('code').setErrors({ unknown: true });
      }, 1);
    }
  }
}
