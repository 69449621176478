import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  // Default language == ENGLISH
  private language = new BehaviorSubject<string>('en');
  curLanguage = this.language.asObservable();

  constructor(
    private translate: TranslateService,
    private adapter: DateAdapter<Date>,
    private ngxAdapter: NgxMatDateAdapter<Date>,
    private primeConfig: PrimeNGConfig
  ) {
    this.translate.onLangChange.subscribe(() => {
      this.translate.get('primeng').subscribe((res) => {
        this.primeConfig.setTranslation(res);
      });
    });
  }

  changeCurLanguage(lan: string): void {
    this.language.next(lan);
    localStorage.setItem('lan', lan);
    this.translate.use(lan);
    this.adapter.setLocale(lan);
    this.ngxAdapter.setLocale(lan);
  }
}
