import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { forkJoin, Subject } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { User } from 'src/app/models/auth/User.class';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { ReservationRoles } from 'src/app/models/reservation/ReservationRoles.const';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { ReservationUser } from 'src/app/models/reservation/ReservationUser.class';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { environment } from 'src/environments/environment';

import { Permission } from '../../../../models/auth/Role.class';
import { UserPermissions } from '../../../../models/auth/UserPermissions.enum';
import { Role, RoleBuilding, RolePermissionBuilding, UserBuilding } from './../../../../models/auth/Role.class';
import { UserService } from './../../../../services/backend/user.service';
import { UsersService } from './../../../../services/pages/users.service';
import { AppConstants } from '../../../../shared/AppConstants';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['../users.component.scss', './user-form.component.css'],
})
export class UserFormComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  insertUserFormGroup: UntypedFormGroup;
  reservationUserFormGroup: UntypedFormGroup;
  submitted = false;
  edit = false;
  loading = false;
  userSelected: User | ReservationUser;
  userBuilding: UserBuilding;
  modalTitle = '';
  buildings: Building[] = [];
  selectedBuildings: Building[] = [];
  reservationRole: string;
  reservationRoles: string[] = [];
  isReservationUser = false;
  emailAlreadyExists = false;
  ssoAlreadyExists = false;
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  sso_identifier = environment.sso_identifier;
  private userLogged: User;
  private permissions: Permission[] = [];
  roles: Role[] = [];
  roleBuildings: RoleBuilding[] = [];
  canWriteUser = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private globalService: GlobalService,
    private userService: UserService,
    private usersService: UsersService,
    private habitatService: HabitatService,
    private reservationService: ReservationService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private dialogRef: MatDialogRef<UserFormComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.hasReservationsModule = this.globalService.getReservationModule();
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
    this.ckeckPermissionsUser(this.permissions);
    this.getHabitatUsers(data);
  }

  ngOnInit(): void {
    forkJoin({
      buildings: this.habitatService.getBuildings(),
      roles: this.userService.getRoles(),
    }).subscribe(({ buildings, roles }) => {
      buildings?.unshift(new Building({ id: -1, name: 'Todos los edificios' }));
      this.buildings = buildings;

      this.roles = roles;

      this.getPermission();
      if (this.isReservationUser) {
        this.editReservation();
      } else {
        if (this.edit) {
          this.modalTitle = 'EditUser';
          this.formEditUser();
        } else {
          this.modalTitle = 'New user';
          this.formNewUser();
        }
      }
    });
  }

  /**
   * On Destroy, emit a new subject for completing other subscriptions
   */
  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ckeckPermissionsUser(permissions: Permission[]): void {
    this.canWriteUser =
      permissions?.find((item) => item.name === UserPermissions.Users && item.type === 'WRITE') !== undefined;
  }

  formNewUser(): void {
    this.insertUserFormGroup = this.formBuilder.group({
      name: ['', [Validators.required]],
      surname: ['', [Validators.required]],
      sso: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      buildings: ['', [Validators.required]],
      roleByBuilding: ['', [Validators.required]],
    });
  }

  formEditUser(): void {
    this.insertUserFormGroup = this.formBuilder.group({
      name: [this.userSelected?.name, [Validators.required]],
      surname: [(this.userSelected as User)?.surname, [Validators.required]],
      sso: [(this.userSelected as User)?.sso, [Validators.required]],
      email: [this.userSelected?.email, [Validators.required, Validators.email]],
      buildings: [(this.userSelected as User)?.buildings, [Validators.required]],
      roleByBuilding: [(this.userSelected as User)?.roles, [Validators.required]],
    });
  }

  getHabitatUsers(data): void {
    this.isReservationUser = data.reservation ? true : false;

    if (data?.user && !this.isReservationUser) {
      this.edit = true;
      this.userSelected = data.user;
      (this.userSelected as User).buildings.forEach((building) => {
        this.selectedBuildings.push(building);
      });
    }
  }

  getPermission(): void {
    if (this.userSelected) {
      this.getUserBuilding(this.userSelected.id);
    }
  }

  buildRoles(rolePermissionBuilding: RolePermissionBuilding[]): void {
    rolePermissionBuilding.forEach((element) => {
      this.roleBuildings.push({
        role: element.role.id,
        roleName: element.role.name,
        building: element.building,
        buildName: element.buildingName,
      });
    });
  }

  /**
   * GET BUILDINGS ASOCIATIONS
   * @param userId
   */
  getUserBuilding(userId: number): void {
    this.userService.getUser(userId).subscribe((userBuilding: UserBuilding) => {
      this.duplaBuildingRole(userBuilding);
    });
  }

  editRolesBackend(): void {
    this.userService.modifyRolesToUser(this.userSelected.id, this.roleBuildings).subscribe({
      next: (userBuilding: UserBuilding) => {
        this.duplaBuildingRole(userBuilding);
      },
      error: (error) => {
        this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
        this.messageService.add({
          key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
          severity: 'error',
          summary: 'Error',
          detail: error.error.reason || error.error.second,
        });
      },
    });
  }

  duplaBuildingRole(userBuilding: UserBuilding): void {
    userBuilding.roles.forEach((item) => {
      item.buildingName = this.buildings.find((build) => build.id === item.building)?.name;

      if (item.building === -1) {
        item.buildingName = 'Todos los edificios';
      }
    });
    this.userBuilding = userBuilding;
    this.buildRoles(this.userBuilding.roles);
  }

  buildRolesUser(): void {
    const userBuilding = new UserBuilding();
    let userRole: RolePermissionBuilding = null;
    let role: Role = null;
    userBuilding.roles = [];

    this.roleBuildings.forEach((item) => {
      userRole = new RolePermissionBuilding();
      userRole.building = item.building;
      userRole.buildingName = this.buildings.find((build) => build.id === item.building)?.name;
      role = new Role();
      role.id = item.role;
      role.name = item.roleName;
      userRole.role = role;
      userBuilding.roles.push(userRole);
    });
    this.duplaBuildingRole(userBuilding);
  }

  onBuildingsChange(evt): void {
    this.insertUserFormGroup.controls['roleByBuilding'].setValue(null);
  }

  cleanRoleForm(): void {
    this.insertUserFormGroup.controls['roleByBuilding'].setValue(null);
    this.insertUserFormGroup.controls['buildings'].setValue(null);
  }

  deleteRoleClick(rolePermissionBuilding: RolePermissionBuilding): void {
    this.userBuilding.roles = this.userBuilding.roles.filter(
      (item: RolePermissionBuilding) => item !== rolePermissionBuilding
    );
    this.roleBuildings = this.roleBuildings.filter(
      (item) => item.building !== rolePermissionBuilding.building && item.role && rolePermissionBuilding.role.id
    );
    this.cleanRoleForm();
  }

  addRoleClick(): void {
    const arrA = this.userBuilding?.roles.map((role) => role.building) || [];
    const arrB = this.insertUserFormGroup.value.buildings?.map((build) => build.id);
    arrA?.filter((x) => !arrB?.includes(x)).concat(arrB?.filter((x) => !arrA?.includes(x)));
    if (
      this.insertUserFormGroup.value.roleByBuilding !== null &&
      this.insertUserFormGroup.value.roleByBuilding !== undefined &&
      this.insertUserFormGroup.value.roleByBuilding !== '' &&
      this.insertUserFormGroup.value.buildings !== null &&
      this.insertUserFormGroup.value.buildings !== undefined &&
      this.insertUserFormGroup.value.buildings?.length > 0 &&
      arrB?.length > 0
    ) {
      let count = 0;
      this.insertUserFormGroup.value.buildings?.forEach((formBuilding) => {
        this.roleBuildings.push({
          role: this.insertUserFormGroup.value.roleByBuilding.id,
          roleName: this.insertUserFormGroup.value.roleByBuilding.name,
          building: formBuilding.id,
          buildName: formBuilding.name,
        });

        count = count + 1;
        if (count === this.insertUserFormGroup.value.buildings.length) {
          const rolesFilter = this.roleBuildings.reduce((accumulator, current) => {
            if (!accumulator.some((item) => item.role === current.role && item.building === current.building)) {
              accumulator.push(current);
            }
            return accumulator;
          }, []);

          this.roleBuildings = rolesFilter;
          this.buildRolesUser();
          this.cleanRoleForm();
        }
      });
    } else {
      this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
      this.messageService.add({
        key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
        severity: 'warn',
        summary: this.translateService.instant('Warning'),
        detail: this.translateService.instant('You must choose a building and role from the list'),
      });
    }
  }

  editReservation(): void {
    this.reservationRoles = ReservationRoles;
    this.userSelected = this.data.user;
    this.modalTitle = 'EditUser';

    this.reservationRole = (this.userSelected as ReservationUser)?.role;
    this.reservationUserFormGroup = this.formBuilder.group({
      role: [(this.userSelected as ReservationUser).role, [Validators.required]],
      office: [(this.userSelected as ReservationUser).office, [Validators.required]],
      area: [(this.userSelected as ReservationUser).area, [Validators.required]],
    });
  }

  updateReservationUserClick(): void {
    if (this.data.user.role == 'Approver' && this.reservationRole != 'Approver') {
      const modalData = new ConfirmModalData('confirmChangeRole', 'infoChangeRole');

      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: modalData,
        panelClass: 'custom-dialog',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result?.confirmed) {
          this.okUpdateReservationUser();
        }
      });
    } else {
      this.reservationService
        .modifyReservationUser(this.data.user.employeeNumber, this.reservationRole)
        .subscribe((res) => {
          this.close(res, 'User modified successfully');
        });
    }
  }

  okUpdateReservationUser(): void {
    this.reservationService.getApproverResevationSpaces(this.data.user.employeeNumber).subscribe({
      next: (spaces: ReservationSpace[]) => {
        let spacesLenth = 0;
        if (spaces.length > 0) {
          spaces.forEach((space) => {
            spacesLenth += 1;
            let spacechanges = {};
            if (space.approvers.length == 1) {
              spacechanges = {
                role: space.role,
                needsApproval: false,
                approvers: [],
                groupable: false,
              };
            } else if (space.approvers.length > 1) {
              spacechanges = {
                role: space.role,
                needsApproval: space.needsApproval,
                approvers: space.approvers.filter((item) => item !== this.data.user.id),
                groupable: space.groupable,
              };
            }
            this.reservationService
              .modifyReservableSpace(spacechanges, space.id, AuditFunctionalities.ChangeApproverRole)
              .subscribe(() => {
                if (spacesLenth == spaces.length) {
                  this.reservationService
                    .modifyReservationUser(this.data.user.employeeNumber, this.reservationRole)
                    .subscribe((res) => {
                      this.close(res, 'User modified successfully');
                    });
                }
              });
          });
        } else {
          this.reservationService
            .modifyReservationUser(this.data.user.employeeNumber, this.reservationRole)
            .subscribe((res) => {
              this.close(res, 'User modified successfully');
            });
        }
      },
      error: () => {
        this.reservationService
          .modifyReservationUser(this.data.user.employeeNumber, this.reservationRole)
          .subscribe((res) => {
            this.close(res, 'User modified successfully');
          });
      },
    });
  }

  insertOrUpdateUser(): void {
    // SAVE FORMULARIO
    this.submitted = true;
    if (
      this.insertUserFormGroup.controls['name'].invalid ||
      this.insertUserFormGroup.controls['surname'].invalid ||
      this.insertUserFormGroup.controls['sso'].invalid ||
      this.insertUserFormGroup.controls['email'].invalid ||
      this.userBuilding?.roles === undefined ||
      this.userBuilding?.roles.length < 1
    ) {
      this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
      this.messageService.add({
        key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
        severity: 'info',
        summary: 'Info',
        detail: this.translateService.instant('Invalid form'),
      });
      return;
    }
    this.loading = true;
    const userToUpdteOrInsert = new User(this.insertUserFormGroup.value);
    if (this.edit) {
      userToUpdteOrInsert.id = this.userSelected.id;
      if (this.userLogged.id === userToUpdteOrInsert.id) {
        // reverse order -> First modulos -> Last General
        this.setUserBuildingAndPermits(
          new User(this.userSelected),
          userToUpdteOrInsert,
          'User modified successfully',
          true
        );
      } else {
        this.updateUser(userToUpdteOrInsert);
      }
    } else {
      this.addNewUser(userToUpdteOrInsert);
    }
  }

  /**
   *
   * @param userToUpdteOrInsert
   */
  updateUser(userToUpdteOrInsert: User): void {
    this.userService.modifyUser(userToUpdteOrInsert.id, userToUpdteOrInsert).subscribe({
      next: (user) => {
        const newUser = new User(user);
        this.setUserBuildingAndPermits(newUser, userToUpdteOrInsert, 'User modified successfully');
      },
      error: (error) => {
        this.loading = false;
        this.managementError(error);
      },
    });
  }

  /**
   *
   * @param userToUpdteOrInsert
   */
  addNewUser(userToUpdteOrInsert: User): void {
    this.userService.createNewUser(userToUpdteOrInsert).subscribe({
      next: (user: User) => {
        this.userService.createNewUserExtra(user.id, userToUpdteOrInsert).subscribe({
          next: (user) => {
            const newUser = new User(user);
            this.userSelected = newUser;
            this.setUserBuildingAndPermits(newUser, userToUpdteOrInsert, 'User created successfully');
          },
          error: (error) => {
            this.loading = false;
            this.managementError(error);
          },
        });
      },
      error: (error) => {
        this.loading = false;
        this.managementError(error);
      },
    });
  }

  managementError(error): void {
    // 400: conflict, already an user with that email.
    if (
      (error.status == '400' && error.error?.reason?.includes('Users_email_unique')) ||
      error.error?.includes('Email already exists') ||
      error?.includes('Email already exists') ||
      error.status == '409'
    ) {
      this.insertUserFormGroup.controls['email'].setErrors({ INVALID: true });
      this.emailAlreadyExists = true;
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
      setTimeout(() => {
        this.setFormError('email', { emailAlreadyExists: true });
      }, 1);
    }
    // 400: conflict, already an user with that SSO ( ID EMPLEADO ).
    else if (
      (error.status == '400' && error.error?.reason?.includes('Users_sso_unique')) ||
      error.error?.includes('EmployeeNumber already exists') ||
      error?.includes('EmployeeNumber already exists')
    ) {
      this.insertUserFormGroup.controls['sso'].setErrors({ INVALID: true });
      this.ssoAlreadyExists = true;
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
      setTimeout(() => {
        this.setFormError('sso', { ssoAlreadyExists: true });
      }, 1);
    }
  }

  setFormError(field: string, errorOject): void {
    this.insertUserFormGroup.get(field)?.markAsDirty();
    this.insertUserFormGroup.get(field)?.markAsTouched();
    this.insertUserFormGroup.get(field)?.setErrors(errorOject);
  }

  /**
   * Modifies the different modules permissions and building associations for an user after modification or creation of a new user
   * @param newUser User: Actual user on Habitat Database
   * @param userToUpdteOrInsert User: New values for user as taken from the modification/creation of user form
   * @param messageToClosed
   * @param changeMyselfRol
   */
  setUserBuildingAndPermits(
    newUser: User,
    userToUpdteOrInsert: User,
    messageToClosed: string,
    changeMyselfRol?: boolean
  ): void {
    this.usersService.updateUserBuildings(newUser.id, this.userBuilding?.roles);

    if (changeMyselfRol) {
      this.userService.modifyUser(userToUpdteOrInsert.id, userToUpdteOrInsert).subscribe({
        next: (user: User) => {
          const userHabitat = this.globalService.getCurrentUser();
          if (userHabitat) {
            userHabitat.email = user.email;
            userHabitat.employeeNumber = user.employeeNumber;
            userHabitat.id = user.id;
            userHabitat.name = user.name;
            userHabitat.surname = user.surname;

            this.globalService.setCurrentUser(userHabitat);
          }
          this.close(newUser, 'User modified successfully');
          setTimeout(() => {
            this.reloadCurrentPage();
          }, 500);
        },
        error: (error) => {
          this.loading = false;
          this.managementError(error);
        },
      });
    } else {
      this.close(newUser, messageToClosed);
    }
  }

  reloadCurrentPage(): void {
    window.location.reload();
  }

  close(data: User, messageToClosed?: string): void {
    this.submitted = false;
    this.edit = false;
    this.loading = false;
    this.selectedBuildings = [];
    this.dialogRef.close({ data, messageToClosed });
    if (messageToClosed) {
      this.globalService.printMessage(messageToClosed);
    }
  }

  get f() {
    return this.insertUserFormGroup.controls;
  }
}
