import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, defaultIfEmpty, map, retry } from 'rxjs/operators';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { Constants } from 'src/app/models/Constants.const';
import { Area } from 'src/app/models/esite/Area.class';
import { Space } from 'src/app/models/esite/Space.class';
import { SpaceType } from 'src/app/models/esite/SpaceType.class';
import { Subsection } from 'src/app/models/esite/Subsection.class';
import { SubsectionType } from 'src/app/models/esite/SubsectionType.class';
import { SubsectionTypeRelation } from 'src/app/models/esite/SubsectionTypeRelation.enum';
import { HttpMethods } from 'src/app/models/HttpMethods.enum';
import { EsiteSystemNotifications } from 'src/app/models/notifications/EsiteSystemNotifications.class';
import { ProximityNotification } from 'src/app/models/notifications/ProximityNotification.class';
import { ProximityNotificationConfig } from 'src/app/models/notifications/ProximityNotificationConfig.class';
import { WebsocketMsg } from 'src/app/models/websocket/WebsocketMsg.class';

import { environment } from '../../../environments/environment';
import { HttpService } from '../common/http.service';
import { AppConstants } from 'src/app/shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class EsiteService {
  private socket: WebSocketSubject<WebsocketMsg>;
  keepaliveTimerId: NodeJS.Timeout;

  constructor(private httpService: HttpService) {
    const url = `${environment.websocket_protocol}${environment.esite_backend_url}v1/realTime`;
    const websocketSubjectConfig = environment.websocket_keepalive
      ? {
          url: url,
          openObserver: {
            next: () => {
              this.keepaliveTimerId = setInterval(() => {
                this.socket.next({
                  type: Constants.WEBSOCKET_KEEPALIVE_TYPE,
                  data: Constants.WEBSOCKET_KEEPALIVE_MESSAGE,
                });
              }, environment.websocket_keepalive_interval);
            },
          },
          closeObserver: {
            next() {
              clearInterval(this.keepaliveTimerId);
            },
          },
        }
      : { url: url };

    this.socket = webSocket(websocketSubjectConfig);
  }

  isEsiteDeployed(): boolean {
    const url = `${environment.esite_backend_url}`;
    return url.length > 0;
  }

  requestToEsite<T = any>(type, method, body?, headers?): Observable<T> {
    const url = `${environment.esite_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequest<T>(protocol + url, type, method, body, headers);
  }

  connectToWebsocket() {
    return this.socket.pipe(retry(5));
  }

  getSpacesByFloor(idFloor: number, options: { resources?: boolean; subsections?: boolean } = {}): Observable<any> {
    const perPage = Constants.maxInt;
    const extraParams = [options.resources && 'resources', options.subsections && 'subsections']
      .filter(Boolean)
      .join(',');

    let method = 'v2/spaces?perPage=' + perPage + '&floor=' + idFloor; // + '&extras=resources,subsections';
    if (extraParams.length > 0) {
      method += '&extras=' + extraParams;
    }
    return this.requestToEsite(HttpMethods.GET, method);
  }

  getSpaceTypes(): Observable<SpaceType[]> {
    const method = 'v2/spaceTypes';
    return this.requestToEsite(HttpMethods.GET, method).pipe(
      map((res) => {
        const types = [];
        res.forEach((element) => {
          const type = new SpaceType(element);
          types.push(type);
        });
        return types;
      })
    );
  }

  getSpace(spaceId: number, options: { resources?: boolean; subsections?: boolean } = {}): Observable<any> {
    const { resources = false, subsections = false } = options;
    const method = 'v2/spaces/' + spaceId;

    return forkJoin({
      space: this.requestToEsite(HttpMethods.GET, method),
      subsections: subsections
        ? this.requestToEsite(HttpMethods.GET, method + '/subsections').pipe(catchError(() => []))
        : of([]),
      resources: resources
        ? this.requestToEsite(HttpMethods.GET, method + '/resources').pipe(catchError(() => []))
        : of([]),
    }).pipe(
      map(({ space, subsections, resources }) => {
        space.subsections = subsections ?? [];
        space.resources = resources ?? [];
        return space;
      })
    );
  }

  getAreasByFloor(floorId: number): Observable<any> {
    const method = 'v2/graphicalAreas?floor=' + floorId;
    return this.requestToEsite(HttpMethods.GET, method);
  }

  getSpacesByArea(areaId): Observable<any> {
    const method = 'v2/graphicalAreas/' + areaId + '/spaces';
    return this.requestToEsite(HttpMethods.GET, method);
  }

  getProximityAlarm(): Observable<any> {
    const method = 'v2/user/configuredSystemAlarms';
    return this.requestToEsite(HttpMethods.GET, method);
  }

  addProximityAlarm(): Observable<any> {
    const method = 'v2/user/configuredSystemAlarms';
    const body = { alarmType: 'Proximity' };
    return this.requestToEsite(HttpMethods.POST, method, body);
  }

  getTriggeredProximityAlarms(
    proximityAlarmsConfig: ProximityNotificationConfig,
    page: number,
    perPage: number
  ): Observable<EsiteSystemNotifications> {
    const method =
      'v2/alarms/system?start=' +
      proximityAlarmsConfig.creationDate +
      '&orderBy=date&page=' +
      page +
      '&perPage=' +
      perPage;

    return this.requestToEsite<EsiteSystemNotifications>(HttpMethods.GET, method).pipe(
      map((esiteNotifications: EsiteSystemNotifications) => {
        esiteNotifications.alarms = esiteNotifications.alarms.map((notification) => {
          return new ProximityNotification(proximityAlarmsConfig, notification);
        });
        return esiteNotifications;
      })
    );
  }

  deleteProximityAlarm(alarmId): Observable<any> {
    const method = 'v2/user/configuredSystemAlarms/' + alarmId;
    return this.requestToEsite(HttpMethods.DELETE, method);
  }

  getTriggeredEsiteSystemAlarms(): Observable<any> {
    const method = 'v2/alarms/system/triggered';
    return this.requestToEsite(HttpMethods.GET, method);
  }

  updateLastDisplayDate(configId, date): Observable<any> {
    const method = 'v2/user/configuredSystemAlarms/' + configId;
    const body = { lastReadDate: date };
    return this.requestToEsite(HttpMethods.PATCH, method, body);
  }

  getAvailabilityStatusOfSpacesGroupedByDay(startDate: string, endDate: string, spaces: number[]): Observable<any> {
    const method = 'v2/historic/availabilityStatusOfSpacesGroupedByDay';
    const body = { startDay: startDate, endDay: endDate, spaces };
    return this.requestToEsite(HttpMethods.POST, method, body);
  }

  insertGraphicalArea(
    area: Area,
    floorId: number,
    functionality: string = AuditFunctionalities.CreateGraphicalArea
  ): Observable<any> {
    const method = 'v2/graphicalAreas';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.POST, method, area.toBackenFormat(floorId), headers);
  }

  insertSpaceType(spaceType: SpaceType, functionality: string): Observable<any> {
    let method = 'v2/spaceTypes';
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.CreateMasiveSpaceType) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.POST, method, spaceType.toBackenFormat(), headers);
  }

  insertSpace(space: Space, floorId: number, functionality: string): Observable<any> {
    let method = 'v2/spaces';
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.CreateMasiveSpaces) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.POST, method, space.toBackendFormat(floorId), headers);
  }

  modifySpace(space: Space, floorId: number, functionality: string): Observable<any> {
    let method = 'v2/spaces/' + space.id;
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.ModifyMasiveSpaces) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.PATCH, method, space.toBackendFormat(floorId), headers);
  }

  /**
   * It replaces all resources of the given spaceId
   * @param spaceId the space id
   * @param resourceIds a list of resource ids
   * @returns
   */
  setSpaceResources(spaceId: number, resourceIds: number[]) {
    const url = `v2/spaces/${spaceId}/resources`;
    return this.requestToEsite<APIMessage>(HttpMethods.PUT, url, resourceIds);
  }

  /**
   * It sets one or many resources to the given spaceId
   * @param spaceId the space identifier
   * @param resourceIds a list of resource ids
   * @returns
   */
  addResourcesToSpace(spaceId: number, resourceIds: number[]) {
    const url = `v2/spaces/${spaceId}/resources`;
    return this.requestToEsite<APIMessage>(HttpMethods.PUT, url, resourceIds);
  }

  /**
   * It removes one of many resources from the given spaceId
   * @param spaceId the space identifier
   * @param resourceIds a list of resource ids to remove
   * @returns
   */
  deleteResourcesFromSpace(spaceId: number, resourceIds: number[]) {
    return forkJoin(
      resourceIds.map((resId) => {
        const url = `v2/spaces/${spaceId}/resources/${resId}`;
        return this.requestToEsite<APIMessage>(HttpMethods.DELETE, url);
      })
    ).pipe(defaultIfEmpty([]));
  }

  updateArea(area: Area, functionality: string = AuditFunctionalities.ModifyGraphicalArea): Observable<any> {
    const method = 'v2/graphicalAreas/' + area.id;
    const body = { name: area.name };
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.PATCH, method, body, headers);
  }

  deleteArea(areaID, functionality: string = AuditFunctionalities.DeleteGraphicalArea): Observable<any> {
    const method = 'v2/graphicalAreas/' + areaID;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.DELETE, method, {}, headers);
  }

  getSubsectionTypes(): Observable<SubsectionType[]> {
    const method = 'v2/subsectionTypes';

    return this.requestToEsite<SubsectionType[]>(HttpMethods.GET, method).pipe(
      map((res) => {
        const types = [];
        res.forEach((element) => {
          const type = new SubsectionType(element);
          types.push(type);
        });
        return types;
      })
    );
  }

  insertSubsectionType(
    subsectionType: {
      name: string;
      maxFloors: SubsectionTypeRelation;
    },
    functionality: string
  ): Observable<SubsectionType> {
    const method = 'v2/subsectionTypes';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite<SubsectionType>(HttpMethods.POST, method, subsectionType, headers);
  }

  getSubsections(floorID?: number, subsectionType?: number): Observable<Subsection[]> {
    let method = 'v2/subsections';
    if (floorID && subsectionType) {
      method = method + '?floor=' + floorID + '&type=' + subsectionType;
    } else if (floorID) {
      method = method + '?floor=' + floorID;
    } else if (subsectionType) {
      method = method + '?type=' + subsectionType;
    }
    return this.requestToEsite<Subsection[]>(HttpMethods.GET, method).pipe(
      map((res) => {
        const types = [];
        res.forEach((element) => {
          const type = new Subsection(element);
          types.push(type);
        });
        return types;
      })
    );
  }

  insertSubsection(subsection: Subsection, functionality: string): Observable<any> {
    let method = 'v2/subsections';
    const newSubsection = new Subsection(subsection);
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.CreateMasiveSubsection) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.POST, method, newSubsection.toBackenFormat(subsection), headers);
  }

  getSubsection(subsectionID: number): Observable<any> {
    const method = 'v2/subsections/' + subsectionID;
    return this.requestToEsite(HttpMethods.GET, method);
  }

  addSubsection(spaceID, subsectionID, functionality: string): Observable<any> {
    let method = 'v2/spaces/' + spaceID + '/subsections/' + subsectionID;
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.AddMasiveSubsectionToSpace) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.PUT, method, {}, headers);
  }

  deleteAllSubsectionsFromSpace(spaceID, functionality: string): Observable<any> {
    let method = 'v2/spaces/' + spaceID + '/subsections';
    let headers = new HttpHeaders();
    if (functionality == AuditFunctionalities.DeleteMasiveSubsectionFromSpace) {
      method = method + '?refresh=' + false;
    }
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.DELETE, method, {}, headers);
  }

  deleteSpace(spaceID, functionality: string = AuditFunctionalities.DeleteSpace): Observable<any> {
    const method = 'v2/spaces/' + spaceID;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite(HttpMethods.DELETE, method, {}, headers);
  }

  getSpaceSubsections(spaceID): Observable<Subsection[]> {
    const method = 'v2/spaces/' + spaceID + '/subsections';
    return this.requestToEsite(HttpMethods.GET, method).pipe(
      map((res) => {
        const types = [];
        res.forEach((element) => {
          const type = new Subsection(element);
          types.push(type);
        });
        return types;
      })
    );
  }

  deleteSubsectionType(
    subsectionTypeID: number,
    functionality: string = AuditFunctionalities.DeleteSubsectionType
  ): Observable<void> {
    const method = 'v2/subsectionTypes/' + subsectionTypeID;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite<void>(HttpMethods.DELETE, method, {}, headers);
  }

  deleteSubsection(
    subsectionID: number,
    functionality: string = AuditFunctionalities.DeleteSubsection
  ): Observable<void> {
    const method = 'v2/subsections/' + subsectionID;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite<void>(HttpMethods.DELETE, method, {}, headers);
  }

  modifySubsectionType(
    subsectionType: SubsectionType,
    functionality: string = AuditFunctionalities.ModifySubsectionType
  ): Observable<void> {
    const method = 'v2/subsectionTypes';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite<void>(HttpMethods.PUT, method, subsectionType, headers);
  }

  modifySubsection(
    subsection: Subsection,
    functionality: string = AuditFunctionalities.ModifySubsection
  ): Observable<void> {
    const method = 'v2/subsections';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToEsite<void>(HttpMethods.PUT, method, subsection, headers);
  }
}

export type APIMessage = {
  reason: string | number;
};
