import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'collapse-card',
  templateUrl: './collapse-card.component.html',
  styleUrls: ['./collapse-card.component.css'],
})
export class CollapseCardComponent {
  @Input() expanded = true;
  @Input() title = '';
  @Output() onCollapse = new EventEmitter<boolean>();

  toggleCollapse(status: boolean) {
    this.expanded = !status;
    this.onCollapse.emit(status);
  }
}
