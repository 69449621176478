import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Languages } from 'src/app/models/Languages.enum';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  utcToLocalString(dateIso: string, lan: Languages, zone: string): string {
    let format = 'DD-MM-YYYY HH:mm:ss';
    if (lan === Languages.en) {
      format = 'DD-MM-YYYY HH:mm:ss ';
    }
    const dateInTimeZone = moment.utc(dateIso).tz(zone);
    return moment.parseZone(dateInTimeZone).format(format);
  }

  getUTCNow() {
    return moment.utc().format();
  }

  getUTCDate(datetime) {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    return moment.utc(datetime).format(format);
  }

  localToUTC(datetime, zone) {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    return moment.tz(datetime, zone).utc().format(format);
  }

  getUTCDateFrom00(datetime) {
    const format = 'YYYY-MM-DDT00:00:00';
    return moment.utc(datetime).format(format);
  }

  getOnlyDateFromDateTime(datetime) {
    return moment.utc(datetime).format('YYYY-MM-DD');
  }

  getDateDiffDays(date, days) {
    return moment.utc(date).add(days, 'days').format('YYYY-MM-DD');
  }
  getDateDiffMonth(date, months) {
    return moment.utc(date).add(months, 'month').format('YYYY-MM-DD');
  }

  getDateDiffMinutes(date, minutes) {
    return moment.utc(date).add(minutes, 'minutes').format('HH:mm');
  }

  getInitLastYear(date) {
    return moment.utc(date).add(-1, 'years').format('YYYY-01-01');
  }

  /**
   * Format a datetime Moment to a string YYYYMMDD_HHmm
   * @param datetime Moment to format as YYYYMMDD_HHmm
   * @returns Formatted date as string
   */
  getDateDownload(datetime: moment.MomentInput): string {
    const format = 'YYYYMMDD_HHmm';
    return moment(datetime).format(format);
  }

  setTimeZone(timestamp: number, timeZone: string): string {
    const d = new Date(timestamp);
    const date = moment(d.toISOString());
    return timeZone === 'UTC'
      ? date.tz(timeZone).toISOString() + `[${timeZone}]`
      : date.tz(timeZone).format('YYYY-MM-DDTHH:mm:ss.SSSZ') + `[${timeZone}]`;
  }

  /**
   * Formats a Date
   * Returns LOCAL TIME
   * @param date Date to be formatted
   * @returns formatted date string
   */
  formatDateAslocal(date: any): string {
    return moment.utc(date).local().toString();
  }

  /**
   * Returns days difference
   * @param date1
   * @param date2
   * @returns
   */
  daysDifference(date1: string, date2: string): number {
    const time1 = moment(date1);
    const time2 = moment(date2);
    return time2.diff(time1, 'd'); // Diff in days
  }

  /**
   * Add minutes to time
   * @param time
   * @param minutes
   * @returns
   */
  addMinutes(time: string, minutes: number | null): string {
    return moment(time, 'HH:mm:ss').add(minutes, 'minutes').format('HH:mm'); // Diff in minutes
  }
  /**
   * Add days to date
   * @param date
   * @param days
   * @returns
   */
  addDays(date: Date, days: number | null): string {
    return moment(date).add(days, 'days').format('yyyy-MM-DD');
  }

  /**
   *
   */
  /* gettingZonesByCountry(): string[] {
    return moment.tz.zonesForCountry(String, Boolean);
  } */

  gettingAllZones(): string[] {
    return moment.tz.names();
  }

  /**
   *
   * @param startDate
   * @param endDate
   * @returns
   */
  getArrayDates(startDate: string, endDate: string): string[] {
    const daysDiff = this.daysDifference(startDate, endDate);
    const dates = [];
    for (let i = 0; i < daysDiff; i++) {
      const date = this.addDays(new Date(startDate), i);
      dates.push(date);
    }
    return dates;
  }
}
