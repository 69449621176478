import { Injectable } from '@angular/core';
import { DeviceInfo } from 'ngx-device-detector';

/**
 * Utilities for getting/setting user's device type (mobile or desktop)
 */
@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private isMobile = false;
  private isTablet = false;
  private isDesktop = false;
  private deviceInfo: DeviceInfo = null;

  isMobileDevice(): boolean {
    return this.isMobile || this.isTablet;
  }

  isDesktopDevice(): boolean {
    return this.isDesktop;
  }

  setMobile(isMobileDevice: boolean): void {
    this.isMobile = isMobileDevice;
  }

  setTablet(isTabletDevice: boolean): void {
    this.isTablet = isTabletDevice;
  }

  setDesktop(isDesktopDevice: boolean): void {
    this.isDesktop = isDesktopDevice;
  }

  getDeviceInfo(): DeviceInfo {
    return this.deviceInfo;
  }

  setDeviceInfo(deviceInfo: DeviceInfo): void {
    this.deviceInfo = deviceInfo;
  }
}
