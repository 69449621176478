import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { SubsectionType } from 'src/app/models/esite/SubsectionType.class';
import { EsiteService } from 'src/app/services/backend/esite.service';

import { NotPermissionComponent } from '../../not-permission/not-permission.component';
import { Permission } from './../../../../models/auth/Role.class';
import { User } from './../../../../models/auth/User.class';
import { UserPermissions } from './../../../../models/auth/UserPermissions.enum';
import { GlobalService } from './../../../../services/common/global.service';
import { AppConstants } from '../../../../shared/AppConstants';

@Component({
  selector: 'app-subsections-configuration',
  templateUrl: './subsections-configuration.component.html',
  styleUrls: ['./subsections-configuration.component.css'],
})
export class SubsectionsConfigurationComponent implements OnInit, OnDestroy {
  private userLogged: User;
  private permissions: Permission[] = [];
  subsectionTypes: SubsectionType[];
  subcriptions: Subscription[] = [];
  havePermissionSubsection = true;

  constructor(
    private esiteService: EsiteService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private globalService: GlobalService,
    public dialog: MatDialog
  ) {
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
  }

  ngOnInit(): void {
    if (
      !(
        this.permissions?.map((permission) => permission.name).includes(UserPermissions.Subsections) &&
        this.permissions?.map((permission) => permission.name).includes(UserPermissions.SubsectionTypes)
      )
    ) {
      this.havePermissionSubsection = false;
      this.messageService.clear(AppConstants.MESSAGE_POSITION_BOTTOM_CENTER);
      this.messageService.add({
        key: AppConstants.MESSAGE_POSITION_BOTTOM_CENTER,
        severity: 'warn',
        summary: this.translateService.instant('Warning'),
        detail: this.translateService.instant(
          'You do not have the necessary permissions in subsections: Subsections and SubsectionTypes'
        ),
      });
      this.dialog.open(NotPermissionComponent, { disableClose: true });
    } else {
      this.getSubsectionTypes();
    }
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  getSubsectionTypes(): void {
    this.subcriptions.push(
      this.esiteService.getSubsectionTypes().subscribe((subsectionTypes: SubsectionType[]) => {
        this.subsectionTypes = subsectionTypes;
      })
    );
  }

  newType(subsectionType: SubsectionType): void {
    this.subsectionTypes.push(subsectionType);
    this.subsectionTypes = this.subsectionTypes.slice();
  }

  modifiedType(subsectionType: SubsectionType): void {
    const modifiedElement = this.subsectionTypes.findIndex((type) => type.id === subsectionType.id);
    this.subsectionTypes[modifiedElement] = subsectionType;
    this.subsectionTypes = this.subsectionTypes.slice();
  }

  deletedType(deletedSubsection: number): void {
    const deletedElement = this.subsectionTypes.findIndex((type) => type.id === deletedSubsection);
    this.subsectionTypes.splice(deletedElement, 1);
    this.subsectionTypes = this.subsectionTypes.slice();
  }
}
