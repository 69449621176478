import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { Constants } from 'src/app/models/Constants.const';
import { SpaceType } from 'src/app/models/esite/SpaceType.class';
import { SpaceUseType } from 'src/app/models/esite/SpaceUseType.enum';
import { ReservationRoles } from 'src/app/models/reservation/ReservationRoles.const';
import { ReservationRolesEnum } from 'src/app/models/reservation/ReservationRoles.enum';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { ReservationUser } from 'src/app/models/reservation/ReservationUser.class';
import { ReservationUsersFiltered } from 'src/app/models/reservation/ReservationUsersFiltered.class';
import { EsiteService } from 'src/app/services/backend/esite.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { GlobalService } from 'src/app/services/common/global.service';

@Component({
  selector: 'app-space-form',
  templateUrl: './space-form.component.html',
  styleUrls: ['./space-form.component.css'],
})
export class SpaceFormComponent implements OnInit {
  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;
  space;
  allUsers;
  spaceFormGroup: UntypedFormGroup;
  submitted = false;
  userToAdd;
  reservationRoles = ReservationRoles;
  reservationRolesWithoutNone = [];
  userRole;
  needsApproval;
  approvalUsers = [];
  floor;
  approbalBoolean;
  groupBoolean;
  loading = true;
  showError = false;
  emailError = false;
  duplicateEmailError = false;
  reservable;
  id;
  roomEmail;
  istARoom = false;
  disabledSave = false;
  spaceCode = '';
  reatimeSpace = new ReservationSpace('');
  canWriteReservationsSpaces = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private reservationService: ReservationService,
    private dialogRef: MatDialogRef<SpaceFormComponent>,
    private globalService: GlobalService,
    private esiteService: EsiteService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.space = data?.space;
    if (data.users) {
      this.allUsers = data?.users.filter((item) => item.role === 'Approver');
    }
    this.canWriteReservationsSpaces = this.data.canWriteReservationsSpaces;

    this.reservationRoles.forEach((role) => {
      if (role != ReservationRolesEnum.None) {
        this.reservationRolesWithoutNone.push(role);
      }
    });
  }

  ngOnInit(): void {
    this.esiteService.getSpaceTypes().subscribe((spaceTypes: SpaceType[]) => {
      if (Array.isArray(this.space) && this.space.length > 1) {
        this.buildForm(true);
      } else if (Array.isArray(this.space) && this.space.length === 1) {
        // Transform data and do the same processing as a single selection
        this.space = this.space[0];
        this.data.space = this.space;
        if (this.data.point?.typeName) {
          this.istARoom =
            spaceTypes.find((item) => item.name === this.data.point.typeName)?.useType === SpaceUseType.Room;
        } else {
          this.istARoom = spaceTypes.find((item) => item.name === this.space.typeName)?.useType === SpaceUseType.Room;
        }
        this.buildForm();
      } else {
        // Single selection
        if (this.data.point?.typeName) {
          this.istARoom =
            spaceTypes.find((item) => item.name === this.data.point.typeName)?.useType === SpaceUseType.Room;
        } else {
          const type = this.space.typeName || this.space.type;
          this.istARoom = spaceTypes.find((item) => item.name === type)?.useType === SpaceUseType.Room;
        }
        this.buildForm();
      }
    });

    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
    });
  }

  changeReservable(event): void {
    this.reservable = event.value;
    if (this.reservable && this.id) {
      this.reservationService.getExchangeEmail(this.id).subscribe((reservationSpace) => {
        this.roomEmail = reservationSpace.emailAddress;
      });
    }
  }

  buildForm(isMultipleSelection = false): void {
    this.spaceFormGroup = this.formBuilder.group({
      role: [{ value: '', disabled: !this.canWriteReservationsSpaces }, [Validators.required]],
      needsApproval: [{ value: '', disabled: !this.canWriteReservationsSpaces }, [Validators.required]],
      approvers: [{ value: '', disabled: !this.canWriteReservationsSpaces }, [Validators.required]],
      groupable: [{ value: '', disabled: !this.canWriteReservationsSpaces }, [Validators.required]],
    });

    if (isMultipleSelection) {
      this.spaceCode = '';
      for (let i = 0; i < this.data.space.length; i++) {
        if (this.data.space.length <= 3) {
          if (i === this.data.space.length - 1) {
            this.spaceCode += this.space[i].code || this.data.space[i].name;
          } else {
            this.spaceCode += (this.space[i].code || this.data.space[i].name) + ', ';
          }
        } else {
          if (i === 0) {
            this.spaceCode += (this.space[i].code || this.data.space[i].name) + ', ';
          } else if (i === 1) {
            this.spaceCode += (this.space[i].code || this.data.space[i].name) + ' ';
          } else {
            this.spaceCode += this.translateService.instant('MultipleSelectionEdit', {
              numberOfSites: this.data.space.length - 2,
            });
            break;
          }
        }
      }

      this.reservable = false;
      this.groupBoolean = false;
      this.needsApproval = false;
      this.userRole = this.reservationRoles[0];
      this.spaceFormGroup.patchValue({
        role: this.userRole,
        needsApproval: this.needsApproval,
        approvers: [],
        groupable: this.groupBoolean,
        usersData: [],
      });
      this.groupSpaces();
    } else {
      this.spaceCode = this.data.point?.name || this.space?.code || this.data.space?.name;
      this.id = this.data.point?.code ? this.data.point.code : this.space.id;
      this.reservationService.getRservationSpaceInfo(this.id).subscribe({
        next: (space) => {
          this.reatimeSpace = space;
          if (space?.id) {
            this.reservationService.getExchangeEmail(space.id).subscribe({
              next: (reservationSpace) => {
                this.roomEmail = reservationSpace.emailAddress;
              },
              error: (error) => {
                console.log(error);
                this.roomEmail = null;
                this.patchValues();
              },
              complete: () => {
                this.patchValues();
              },
            });
          }
        },
        error: () => {
          this.reservable = false;
          this.groupBoolean = false;
          this.needsApproval = false;
          this.userRole = this.reservationRoles[0];
          this.spaceFormGroup.patchValue({
            role: this.userRole,
            needsApproval: this.needsApproval,
            approvers: [],
            groupable: this.groupBoolean,
            usersData: [],
          });
          if (!this.space) {
            this.oneSpace();
          }
          this.loading = false;
        },
      });
      this.approvalUsers = [];
    }
  }

  patchValues(): void {
    this.reservable = true;
    if (this.space?.length > 0) {
      this.groupSpaces();
    } else {
      this.oneSpace();
    }
  }

  groupSpaces(): void {
    this.space.usersData = [];
    this.space.usersData.forEach((user) => {
      const index = this.allUsers.findIndex((usr) => usr.id == user.id);
      this.allUsers.splice(index, 1);
    });
    this.loading = false;
  }

  oneSpace(): void {
    if (this.space?.usersData) {
      this.userRole = this.space.permission;
      const approvers = [];
      if (this.space.usersData.approvers) {
        this.space.usersData.approvers.forEach((user) => {
          if (user) {
            approvers.push(user);
            const reservationUser = this.allUsers.find((item) => item.employeeNumber == user) as ReservationUser;
            if (reservationUser) {
              reservationUser.nameComplete = reservationUser.surname
                ? reservationUser.name?.concat(' ').concat(reservationUser.surname)
                : reservationUser.name;
              this.approvalUsers.push(reservationUser);
            }
            const index = this.allUsers.findIndex((item) => item.employeeNumber == user);
            this.allUsers.splice(index, 1);
          }
        });
      } else {
        let arrayUndefined = false;
        this.space.usersData.forEach((user) => {
          if (user) {
            approvers.push(user.id);
            this.approvalUsers.push(this.allUsers.find((item) => item.id == user.id));
            const index = this.allUsers.findIndex((item) => item.id == user.id);
            this.allUsers.splice(index, 1);
          } else {
            arrayUndefined = true;
          }
        });
        if (arrayUndefined) {
          this.reatimeSpace?.approvers?.forEach((user) => {
            if (user) {
              approvers.push(user);
              const reservationUser = this.allUsers.find((item) => item.employeeNumber == user) as ReservationUser;
              if (reservationUser) {
                reservationUser.nameComplete = reservationUser.surname
                  ? reservationUser.name?.concat(' ').concat(reservationUser.surname)
                  : reservationUser.name;
                this.approvalUsers.push(reservationUser);
              }
              const index = this.allUsers.findIndex((item) => item.employeeNumber == user);
              this.allUsers.splice(index, 1);
            }
          });
        }
      }
      this.approbalBoolean = this.space.approval === 'Yes';
      this.groupBoolean = this.space.rGroup === 'Yes';
      this.needsApproval = this.approbalBoolean || this.groupBoolean;
      this.spaceFormGroup.patchValue({
        role: this.space.permission,
        needsApproval: this.approbalBoolean,
        approvers: approvers,
        groupable: this.groupBoolean,
        usersData: [],
      });
      this.loading = false;
    } else {
      const approvers = [];
      this.reservationService
        .getUsersFiltered(1, Constants.maxInt, null, ReservationRolesEnum.Approver, null, null)
        .subscribe({
          next: (users: ReservationUsersFiltered) => {
            this.allUsers = users.users;
            this.floor = this.reatimeSpace.floor;
            this.reatimeSpace?.approvers?.forEach((user) => {
              approvers.push(user);
              const foundUser = this.allUsers.find((usr) => usr.employeeNumber == user);
              this.approvalUsers.push(new ReservationUser(foundUser));
              const index = this.allUsers.findIndex((usr) => usr.employeeNumber == user);
              this.allUsers.splice(index, 1);
            });
            this.userRole = this.reatimeSpace.role || this.reservationRoles[0];
            this.approbalBoolean = this.reatimeSpace.needsApproval || false;
            this.needsApproval = this.reatimeSpace.needsApproval || false;
            this.groupBoolean = this.reatimeSpace.groupable || false;
            this.space = {
              id: this.reatimeSpace.id,
              approval: this.reatimeSpace.needsApproval,
              rGroup: this.reatimeSpace.groupable,
              usersData: [],
            };
            this.spaceFormGroup.patchValue({
              role: this.userRole,
              needsApproval: this.needsApproval,
              approvers: approvers,
              groupable: this.groupBoolean,
            });
            this.loading = false;
          },
          error: () => {
            this.loading = false;
          },
        });
    }
  }

  deleteUser(user): void {
    const index = this.approvalUsers.findIndex((usr) => usr.id == user.id);
    this.approvalUsers.splice(index, 1);
    this.allUsers.push(user);
  }

  addUser(): void {
    this.userToAdd.nameComplete = this.userToAdd.surname
      ? this.userToAdd.name?.concat(' ').concat(this.userToAdd.surname)
      : this.userToAdd.name;
    this.approvalUsers.push(this.userToAdd);
    const index = this.allUsers.findIndex((usr) => usr.id == this.userToAdd.id);
    this.allUsers.splice(index, 1);
    this.userToAdd = null;
    this.submitted = false;
    this.showError = false;
  }

  get f() {
    return this.spaceFormGroup.controls;
  }

  checkEmailError(): void {
    if (this.istARoom && this.roomEmail && !this.roomEmail.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$/)) {
      this.emailError = true;
    } else {
      this.emailError = false;
    }
  }

  insertRoomEmail(space: ReservationSpace): void {
    if (this.istARoom) {
      if (this.roomEmail) {
        this.reservationService
          .addExchangeEmail(space.id, this.roomEmail, AuditFunctionalities.AddindividualReservableSpaceExchangeEmail)
          .subscribe({
            next: () => {
              this.closeModal(false, space);
            },
            error: () => {
              this.duplicateEmailError = true;
            },
          });
      } else {
        this.reservationService
          .deleteExchangeEmail(space.id, AuditFunctionalities.DeleteIndividualReservableSpaceExchangeEmail)
          .subscribe(() => {
            this.closeModal(false, space);
          });
      }
    } else {
      this.closeModal(false, space);
    }
  }

  saveChanges(): void {
    if (this.reservable) {
      let usersToAdd = [];

      this.submitted = true;
      if (this.spaceFormGroup.value.needsApproval === true || this.spaceFormGroup.value.groupable) {
        this.approvalUsers.forEach((user) => {
          usersToAdd.push(user.employeeNumber);
        });
        if (usersToAdd.length == 0) {
          this.showError = true;
        }
      } else {
        usersToAdd = [];
        this.showError = false;
      }

      this.checkEmailError();
      if (this.emailError || this.showError) {
        return;
      }
      this.disabledSave = true;

      if (this.space?.length > 0) {
        this.space.forEach((space) => {
          this.floor = space.floor;
          const config = {
            role: this.userRole,
            needsApproval: this.spaceFormGroup.value.needsApproval,
            approvers: usersToAdd,
            groupable: this.spaceFormGroup.value.groupable,
          };
          this.reservationService
            .modifyReservableSpace(config, space.id, AuditFunctionalities.ModifyIndividualReservableSpace)
            .subscribe({
              next: (modifiedSpace) => {
                this.insertRoomEmail(modifiedSpace);
              },
              error: () => {
                const reservationSpace = {
                  id: space.id,
                  role: this.userRole,
                  needsApproval: this.spaceFormGroup.value.needsApproval,
                  approvers: usersToAdd,
                  groupable: this.spaceFormGroup.value.groupable,
                };
                this.reservationService
                  .createReservationSpace(reservationSpace, AuditFunctionalities.CreateIndividualReservableSpace)
                  .subscribe((createdSpace) => {
                    this.floor = createdSpace.floor;
                    this.insertRoomEmail(createdSpace);
                  });
              },
            });
        });
      } else {
        this.spaceFormGroup.patchValue({
          role: this.userRole,
          approvers: usersToAdd,
          groupable: this.spaceFormGroup.value.groupable,
          needsApproval: this.spaceFormGroup.value.needsApproval,
        });
        this.reservationService
          .modifyReservableSpace(
            this.spaceFormGroup.value,
            this.id,
            AuditFunctionalities.ModifyIndividualReservableSpace
          )
          .subscribe({
            next: (modifiedSpace) => {
              this.floor = modifiedSpace.floor;
              this.insertRoomEmail(modifiedSpace);
            },
            error: () => {
              const reservationSpace = {
                id: this.id,
                role: this.userRole,
                needsApproval: this.spaceFormGroup.value.needsApproval,
                approvers: usersToAdd,
                groupable: this.spaceFormGroup.value.groupable,
              };
              this.reservationService
                .createReservationSpace(reservationSpace, AuditFunctionalities.CreateIndividualReservableSpace)
                .subscribe((createdSpace) => {
                  this.floor = createdSpace.floor;
                  this.insertRoomEmail(createdSpace);
                });
            },
          });
      }
    } else {
      if (this.space?.length > 0) {
        this.space.forEach((space) => {
          this.floor = space.floor;
          this.reservationService
            .deleteReservationSpace(space.id, AuditFunctionalities.DeleteReservableSpace)
            .subscribe({
              next: () => {
                this.closeModal(false, null, space.id);
              },
              /* If the space is being set to "no reservable" for the first time, request returns 404
           as the space doesn't exist on the "reservable spaces" table */
              error: (error: HttpErrorResponse) => {
                if (error.status === 404) {
                  this.closeModal(false, null, space.id);
                }
              },
            });
        });
      } else {
        this.reservationService.deleteReservationSpace(this.id, AuditFunctionalities.DeleteReservableSpace).subscribe({
          next: () => {
            this.closeModal(false, null, this.id);
          },
          /* If the space is being set to "no reservable" for the first time, request returns 404
           as the space doesn't exist on the "reservable spaces" table */
          error: (error: HttpErrorResponse) => {
            if (error.status === 404) {
              this.closeModal(false, null, this.id);
            }
          },
        });
      }
    }
  }

  closeModal(cancel: boolean, space: ReservationSpace, spaceId?: number): void {
    if (spaceId || spaceId === 0) {
      this.dialogRef.close({ isCancelled: cancel, floor: this.floor, space: space });
    } else {
      this.dialogRef.close({ isCancelled: cancel, floor: this.floor, space: space, spaceId: spaceId });
    }
  }

  needsApprovalChange(event): void {
    this.showError = false;
    this.approbalBoolean = event.value;
    if (this.groupBoolean || this.approbalBoolean) {
      this.needsApproval = true;
    } else {
      this.needsApproval = false;
    }
  }

  groupableChange(event): void {
    this.showError = false;
    this.groupBoolean = event.value;
    if (this.groupBoolean || this.approbalBoolean) {
      this.needsApproval = true;
    } else {
      this.needsApproval = false;
    }
  }
}
