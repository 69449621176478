import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, map, Subject } from 'rxjs';
import { GlobalService } from 'src/app/services/common/global.service';
import { Co2Service } from 'src/app/services/backend/co2.service';
import * as moment from 'moment';
import { ZoneCo2 } from 'src/app/models/co2/ZoneCo2.class';
import { ZoneWSS } from 'src/app/models/co2/ZoneWSS.class';
import { Co2Measure, isMeasureType } from 'src/app/models/co2/Co2Measure.class';
import { Co2MeasureUnit } from 'src/app/models/co2/Co2MeasureUnit.enum';
import { InfoModalComponent } from 'src/app/components/shared/info-modal/info-modal.component';
import { InfoModalData } from 'src/app/models/InfoModalData.class';
import { DateService } from 'src/app/services/common/date.service';
import FileSaver from 'file-saver';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-co2',
  templateUrl: './co2.component.html',
  styleUrls: ['./co2.component.scss'],
})
export class Co2Component implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  now$ = interval(1000).pipe(map(() => new Date()));
  areas$ = this.co2Service.areas$;
  responsive$ = this.globalService.isResponsive$;

  measures = new Subject<Array<Co2Measure>>();

  selectedAreaId: number | null;
  startDate: string;
  endDate: string;
  downloadingExcel = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private globalService: GlobalService,
    private dateService: DateService,
    private co2Service: Co2Service
  ) {}

  ngOnInit(): void {
    this.co2Service.fetchAreas();

    this.route.queryParams.subscribe((params) => {
      if (params.id) {
        this.selectedAreaId = Number(params.id);
        this.co2Service.fetchSingleArea(this.selectedAreaId).subscribe((area) => {
          this.measures.next(this.toMeasureArray(area));
        });
        this.fetchMeasures(params.id);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  fetchMeasures(id: number) {
    this.co2Service.fetchMeasures(id).subscribe({
      next: (res) => {
        this.measures.next(this.toMeasureArray(res));
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  get currentArea() {
    return this.areas$.getValue().find((area) => area.id === this.selectedAreaId);
  }

  get canExportData() {
    return !this.downloadingExcel && this.selectedAreaId && this.startDate && this.endDate;
  }

  handleAreaSelected(newAreaId: string) {
    this.router.navigate([], {
      queryParams: {
        id: newAreaId,
      },
    });
  }

  handleExportButtonClick() {
    this.downloadingExcel = true;
    const filesName = `Habitat_Co2_${this.currentArea.name}_${this.dateService.getDateDownload(new Date())}.xlsx`;
    this.co2Service.downloadExcel(this.selectedAreaId, this.startDate, this.endDate).subscribe({
      next: (excelFile) => {
        this.downloadingExcel = false;

        FileSaver.saveAs(excelFile, filesName);
      },
      error: (error) => {
        let infoModalData = null;
        this.downloadingExcel = false;
        if (error.status === 409) {
          infoModalData = new InfoModalData('Downloading error', 'Too many records. Please refine your search.');
        } else {
          infoModalData = new InfoModalData(
            'Downloading error',
            'An error has occurred, please try again. If it persists contact the administrator.'
          );
        }
        this.dialog.open(InfoModalComponent, {
          data: infoModalData,
          panelClass: 'custom-dialog',
          disableClose: true,
        });
      },
    });
  }

  startDateSelected(eventValue: Date): void {
    this.startDate = moment(eventValue).toISOString();
  }

  endDateSelected(eventValue: Date): void {
    this.endDate = moment(eventValue).toISOString();
  }

  toMeasureArray(zone: ZoneWSS | ZoneCo2): Array<Co2Measure> {
    const arrayMeasure: Array<Co2Measure> = [];

    for (const key in zone) {
      if (isMeasureType(key)) {
        arrayMeasure.push(new Co2Measure(zone[key], Co2MeasureUnit[key], key));
      }
    }

    return arrayMeasure;
  }

  indicatorClass(measure: Co2Measure): string | null {
    const status = measure.getStatus(new Date());
    return (
      {
        warning: 'is-warning',
        alarm: 'is-alarm',
        ok: 'is-ok',
      }[status] ?? null
    );
  }
}
