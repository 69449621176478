/**
 * This service is an alternative implementation of the service provided in the
 * Community-provided sample implementation
 * of angular-oauth2-oidc implicit flow
 * https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/tree/implicit-flow
 */
import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { TokenExpirationAlertComponent } from 'src/app/components/auth/token-expiration-alert/token-expiration-alert.component';
import { UnregisteredUser } from 'src/app/models/auth/UnregisteredUser.class';
import { UnregisteredUserExtended } from 'src/app/models/auth/UnregisteredUserExtended.interface';
import { User } from 'src/app/models/auth/User.class';
import { HttpMethods } from 'src/app/models/HttpMethods.enum';
import { StorageVariables } from 'src/app/models/StorageVariables.enum';
import { environment } from 'src/environments/environment';

import { HabitatService } from '../backend/habitat.service';
import { GlobalService } from '../common/global.service';
import { HttpService } from '../common/http.service';

/**
 * Declares public functions to use for authentication flows, like setting the logged user email
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  /**
   * Observable for authentication. Tells wether the user is already authenticated
   * (there is a valid access token)
   */
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  /**
   * Observable for authentication. Tells if the initial login process has completed already
   */
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private isUnregisteredUserInfoAvailableSubject$ = new BehaviorSubject<boolean>(false);
  /**
   * Observable for authentication. Tells wether we have the UNREGISTERED user info available
   */
  public isUnregisteredUserInfoAvailable$ = this.isUnregisteredUserInfoAvailableSubject$.asObservable();

  private isRegisteredUserInfoAvailableSubject$ = new BehaviorSubject<boolean>(false);
  /**
   * Observable for authentication. Tells wether we have the REGISTERED user info available
   */
  public isRegisteredUserInfoAvailable$ = this.isRegisteredUserInfoAvailableSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated, and (c) we have successfully parsed access_token claims
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   * - There is information available about the unregistered user
   */
  canRecoverUnregisteredUserInfo$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
    this.isUnregisteredUserInfoAvailable$,
  ]).pipe(map((values) => values.every((b) => b)));

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated, and (c) we have successfully parsed access_token claims
   * and (d) the first request to recover User info has succeeded
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   * - There is information available about the unregistered user
   * - There is Reservation Information about the user
   */
  canRecoverRegisteredUserInfo$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$,
    this.isUnregisteredUserInfoAvailable$,
    this.isRegisteredUserInfoAvailable$,
  ]).pipe(map((values) => values.every((b) => b)));

  /**
   * Store the Registered user information (components needs to subscribe to canRecoverRegisteredUserInfo$)
   * in order to check if they can already access to this property
   */
  private loggedUser!: User;
  /**
   * Store the unregistered user information (components needs to subscribe to canRecoverUnregisteredUserInfo$)
   * in order to check if they can already access to this property
   */
  private unregisteredUserInfo!: UnregisteredUser;

  constructor(
    private oauthService: OAuthService,
    private habitatService: HabitatService,
    private httpService: HttpService,
    private router: Router,
    private globalService: GlobalService,
    public dialog: MatDialog
  ) {
    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    window.addEventListener('storage', (event) => {
      // If a log out from another tab
      if (event.key === StorageVariables.SESSION_CHANGE && event.newValue === 'logged_out') {
        this.logout();
      }

      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        /**
         * If there is not valid tokens, redirect to error page
         */
        this.redirectToAuthErrorPage();
      }
    });

    this.oauthService.events.subscribe(() => {
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
    });

    this.oauthService.events.pipe(filter((e) => ['token_received'].includes(e.type))).subscribe(() => {
      /**
       * Decode JWT and set unregistered user information
       */
      const tokenToDecode = environment.idTokenClaims
        ? this.oauthService.getIdToken()
        : this.oauthService.getAccessToken();
      const decodedJWT = jwt_decode(tokenToDecode);
      this.setUnregisteredUser(decodedJWT);
    });

    this.oauthService.events
      .pipe(filter((e) => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(() => {
        this.redirectToAuthErrorPage();
      });

    /**
     * Opens a dialog that allow the user to refresh his access_token when it expires
     */
    this.oauthService.events.pipe(filter((e) => e.type === 'token_expires')).subscribe({
      next: () => {
        this.dialog?.open(TokenExpirationAlertComponent, { disableClose: true });
      },
      error: (error) => {
        console.log(error);
      },
    });
  }

  /**
   * Run initial login sequence to:
   * 1.- Check if there is a valid ACCESS_TOKEN in the SESSION or there is an ACCESS_TOKEN in the REQUEST URL
   * 2.- If there is a valid token:
   * 2.1.- Decode it and set the UNREGISTERED_USER information
   * 2.2.- Make a call to the reservation backend in order to set REGISTERED_USER information
   * 2.3.- Redirect user back to their initial page navigation
   * 3.- Update isDoneLoadingSubject$ and isAuthenticatedSubject$ observables!
   */
  runInitialLoginSequence(): void {
    // 1. HASH LOGIN:
    // Try to log in via hash fragment after redirect back
    // from IdServer from initImplicitFlow:
    this.oauthService
      .loadDiscoveryDocumentAndTryLogin()
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        } else {
          return Promise.reject();
        }
      })
      .then(() => {
        /**
         * Decode JWT and set unregistered user information
         */
        const tokenToDecode = environment.idTokenClaims
          ? this.oauthService.getIdToken()
          : this.oauthService.getAccessToken();
        const decodedJWT = jwt_decode(tokenToDecode);
        this.setUnregisteredUser(decodedJWT);

        this.isUnregisteredUserInfoAvailable$
          .pipe(
            filter((isAvailable) => {
              return isAvailable;
            })
          )
          .subscribe(() => {
            /**
             * Send ID token instead of Access token
             */
            if (environment.sendIdToken) {
              sessionStorage.setItem('access_token', this.oauthService.getIdToken());
            }

            /**
             * Set registered user information
             */
            this.habitatService.getUser().subscribe({
              next: (user: User) => {
                this.setLoggedUser(new User(user));
                this.isAuthenticatedSubject$.next(true);
                this.isDoneLoadingSubject$.next(true);
              },
              error: (error: HttpErrorResponse) => {
                this.isAuthenticatedSubject$.next(true);
                this.isDoneLoadingSubject$.next(true);
                this.logout(true);
                // User is not yet registered
                if (error?.toString() === 'User not found') {
                  this.redirectToNotUserAllowed();
                } else {
                  // Redirect to auth error page after performing a no-redirect logout
                  this.redirectToAuthErrorPage();
                }
              },
            });
          });
      })
      .catch(() => {
        this.isAuthenticatedSubject$.next(false);
        this.isDoneLoadingSubject$.next(true);
      });
  }

  /**
   * Starts login process
   * @param loginUrl string. Optional state to passthrough the login process
   */
  login(loginUrl?: string): void {
    this.oauthService.initLoginFlow(loginUrl || this.router.url);
  }

  /**
   * Performs a logout
   * @param disableRedirectionToLogoutPage optional. Set to true to disable redirection to logout page
   */
  logout(disableRedirectionToLogoutPage = false): void {
    localStorage.setItem(StorageVariables.SESSION_CHANGE, 'logged_out');
    this.oauthService.logOut(disableRedirectionToLogoutPage);
    this.isAuthenticatedSubject$.next(false);
    this.isDoneLoadingSubject$.next(true);
    this.removeLocalStorage();
  }

  removeLocalStorage(): void {
    localStorage.removeItem(StorageVariables.LOGGED_USER);
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('id_token');
  }

  /**
   * Refresh access_token by login again
   */
  refresh(): void {
    this.login();
  }

  /**
   * Check if there is a valid access_token
   * @returns boolean, true if there is a valid access_token
   */
  hasValidToken(): boolean {
    return this.oauthService.hasValidAccessToken();
  }

  /**
   * Gets the logged user information
   * @returns logged user info
   */
  getLoggedUser(): User {
    return this.loggedUser;
  }

  /**
   * Sets the current logged user and emits its new value for all subscribers
   * @param User logged user information
   */
  setLoggedUser(loggedUser: User): void {
    this.loggedUser = loggedUser;
    this.isRegisteredUserInfoAvailableSubject$.next(true);
    if (this.globalService.getCurrentUser()?.roles) {
      this.globalService.setCurrentUser(loggedUser);
    }
    if (loggedUser) {
      this.globalService.buildLoggedUser(loggedUser.id);
    }
  }

  /**
   * Sets unregistered user information taken from AUTH ACCESS_TOKEN
   * @param tokenPayload JWT Decoded Payload
   */
  setUnregisteredUser(tokenPayload: unknown): void {
    localStorage.setItem(StorageVariables.SESSION_CHANGE, 'active');
    if (!this.getUnregisteredUser()) {
      this.unregisteredUserInfo = new UnregisteredUser(tokenPayload as UnregisteredUserExtended);
      if (!this.unregisteredUserInfo.ssoId) {
        // We need to call another endpoint to retrieve SSOId information
        let headers = new HttpHeaders();
        headers = headers.append('Authorization', `Bearer ${this.oauthService.getAccessToken()}`);
        this.httpService
          .httpRequest<{ employeeId: string }>(environment.userInfoEndpoint, HttpMethods.GET, '', null, headers)
          .subscribe((userprofile) => {
            this.unregisteredUserInfo.ssoId = userprofile.employeeId || userprofile['custom:employeeid'];
            this.isUnregisteredUserInfoAvailableSubject$.next(true);
          });
      } else {
        this.isUnregisteredUserInfoAvailableSubject$.next(true);
      }
    }
  }

  /**
   * Gets the unregistered user information
   * @returns unregistered user info, or null if no user can be found
   */
  getUnregisteredUser(): UnregisteredUser | null {
    return this.unregisteredUserInfo;
  }

  /**
   * Performs a redirection to authentication error page (/autherror)
   */
  redirectToAuthErrorPage(): void {
    this.router.navigate(['/autherror']);
  }

  /**
   * Performs a redirection to the forbidden page
   */
  redirectToNotUserAllowed(): void {
    this.router.navigate(['/forbidden']);
  }

  /**
   * Performs a redirection to not permission page
   */
  redirectToNotPermission(): void {
    this.router.navigate(['/not-permission']);
  }

  /**
   * Performs a redirection to the main page (/realtime)
   */
  redirectToRealtime(): void {
    this.router.navigate(['/realtime']);
  }

  getAccessToken(): string {
    return this.oauthService.getAccessToken();
  }
}
