import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Entity } from 'src/app/models/audit/Entity';
import { environment } from 'src/environments/environment';

import { HttpService } from '../common/http.service';
import { HttpMethods } from './../../models/HttpMethods.enum';
import { Functionality } from 'src/app/models/audit/Functionality';
import { AuditList } from 'src/app/models/audit/AuditList';
import { Audit } from 'src/app/models/audit/Audit';

@Injectable({
  providedIn: 'root',
})
export class AuditService {
  constructor(private httpService: HttpService) {}

  private requestToAudit<T>(type: HttpMethods, method: string, body?: unknown, headers?: HttpHeaders): Observable<T> {
    const url = `${environment.audit_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequest<T>(protocol + url, type, method, body, headers);
  }

  private requestToHabitatExcel(method: string): Observable<Blob> {
    const url = `${environment.audit_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequestExcel(protocol + url, method);
  }

  getFunctionalities(name?: string): Observable<Functionality[]> {
    let method = 'v2/functionalities';
    if (name) {
      method += '?name=' + name;
    }
    return this.requestToAudit<Functionality[]>(HttpMethods.GET, method);
  }

  getEntities(): Observable<Entity[]> {
    const method = 'v2/entities';
    return this.requestToAudit<Entity[]>(HttpMethods.GET, method);
  }

  getFunctionalitiesByEntity(entityID: number): Observable<Functionality[]> {
    const method = 'v2/functionalities/entity?id=' + entityID;
    return this.requestToAudit<Functionality[]>(HttpMethods.GET, method);
  }

  getAudit(
    page: number,
    perPage: number,
    user?: any,
    sortColumns = 'date',
    sortOrder = 'desc',
    functionality?: any,
    from?: any,
    to?: any,
    oldValue?: any,
    newValue?: any,
    identifierName?: any
  ): Observable<AuditList> {
    let method = 'v2/audit?page=' + page + '&perPage=' + perPage;

    if (user) {
      method += '&user=' + user;
    }
    if (sortColumns) {
      let sortColumnsAux = sortColumns;
      if (sortColumns === 'entityName') {
        sortColumnsAux = 'entity';
      } else if (sortColumns === 'functionalityName') {
        sortColumnsAux = 'functionality';
      }
      method += '&orderBy=' + sortColumnsAux;
    }
    if (sortOrder) {
      method += '&sortOrder=' + sortOrder;
    }
    if (functionality) {
      method += '&functionality=' + functionality;
    }
    if (from) {
      method += '&from=' + encodeURIComponent(from);
    }
    if (to) {
      method += '&to=' + encodeURIComponent(to);
    }
    if (oldValue) {
      method += '&oldValue=' + oldValue;
    }
    if (newValue) {
      method += '&newValue=' + newValue;
    }
    if (identifierName) {
      method += '&identifierName=' + identifierName;
    }

    return this.requestToAudit<AuditList>(HttpMethods.GET, method, {});
  }

  getAuditDetail(auditId: number): Observable<Audit> {
    const method = 'v2/audit/' + auditId;
    return this.requestToAudit<Audit>(HttpMethods.GET, method);
  }

  getAuditExcelExport(
    user?: any,
    functionality?: any,
    from?: any,
    to?: any,
    oldValue?: any,
    newValue?: any,
    lang = 'en',
    identifierName?: any
  ): Observable<Blob> {
    let method = 'v2/auditMetadata' + '?lenguage=' + lang;
    if (user) {
      method += '&userId=' + user;
    }
    if (functionality) {
      method += '&functionalityId=' + functionality;
    }
    if (from) {
      method += '&from=' + encodeURIComponent(from);
    }
    if (to) {
      method += '&to=' + encodeURIComponent(to);
    }
    if (oldValue) {
      method += '&oldValue=' + oldValue;
    }
    if (newValue) {
      method += '&newValue=' + newValue;
    }
    if (identifierName) {
      method += '&identifierName=' + identifierName;
    }
    return this.requestToHabitatExcel(method);
  }
}
