import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuditService } from 'src/app/services/backend/audit.service';
import { EsiteService } from 'src/app/services/backend/esite.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { GlobalService } from 'src/app/services/common/global.service';

import { StorageVariables } from './../../../../../../models/StorageVariables.enum';

@Component({
  selector: 'app-audit-results-detail',
  templateUrl: './audit-results-detail.component.html',
  styleUrls: ['./audit-results-detail.component.scss'],
})
export class AuditResultsDetailComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;
  loading = true;
  displayedColumnsTable: string[] = ['field', 'table', 'previousValue', 'actualValue'];
  dataSource = new MatTableDataSource([]);
  subcriptions: Subscription[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sortDataAuditoriaDetail', { static: false }) set content(sortData: MatSort) {
    // this.dataSource.sort = sortData;
  }

  auditData = [];
  INITIAL_PAGE = 1;
  INITIAL_PAGESIZE = 10;
  pageSize = this.INITIAL_PAGESIZE;
  pageIndex: number = this.INITIAL_PAGE;
  sortColumn: any;
  sortOrder: any;
  haveNextPage: boolean = null;
  initPaginatorText: number;
  endPaginatorText: number;
  totalAuditData: number;
  originFuntions = JSON.parse(sessionStorage.getItem(StorageVariables.FUNCTIONALITIES));
  filterparameters;
  auditId: number;
  auditObjet = '';
  constructor(
    private auditBackendService: AuditService,
    private globalService: GlobalService,
    private esiteService: EsiteService,
    private habitatService: HabitatService,
    private reservationService: ReservationService,

    private dialogRef: MatDialogRef<AuditResultsDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.auditId = data.auditId;
  }

  ngOnInit(): void {
    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
    });
    this.loadAuditoriaDetail();
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  loadAuditoriaDetail(): void {
    this.auditData = [];
    this.loading = true;
    this.auditBackendService.getAuditDetail(this.auditId).subscribe((auditDetail) => {
      this.auditObjet = auditDetail.identifierName;
      auditDetail.auditDetails.forEach((details) => {
        switch (details.table) {
          case 'Spaces': {
            switch (details.column) {
              case 'type': {
                this.esiteService.getSpaceTypes().subscribe({
                  next: (spaceTypes) => {
                    const oldValue = spaceTypes.find((item) => item.id == details.oldValue)?.name;
                    const newValue = spaceTypes.find((item) => item.id == details.newValue)?.name;

                    const tableItem = {
                      id: details.id,
                      idRegister: details.rowId,
                      field: details.column,
                      table: details.table,
                      previousValue: oldValue ? oldValue : details.oldValue,
                      actualValue: newValue ? newValue : details.newValue,
                    };
                    this.auditData.push(tableItem);
                    this.updateTable();
                  },
                  error: () => {
                    this.defaultTableItem(details);
                  },
                });

                break;
              }
              case 'maxFloors': {
                const tableItem = {
                  id: details.id,
                  idRegister: details.rowId,
                  field: details.column,
                  table: details.table,
                  previousValue:
                    details.oldValue == 0
                      ? 'NoPhysicalLocation'
                      : details.oldValue == 1
                      ? 'OnlyOneFloor'
                      : 'ManyFloors',
                  actualValue:
                    details.newValue == 0
                      ? 'NoPhysicalLocation'
                      : details.newValue == 1
                      ? 'OnlyOneFloor'
                      : 'ManyFloors',
                };
                this.auditData.push(tableItem);
                this.updateTable();
                break;
              }
              default: {
                this.defaultTableItem(details);

                break;
              }
            }
            break;
          }
          case 'RelationSpacesSubsections': {
            switch (details.column) {
              case 'space': {
                const space = details.newValue ? details.newValue : details.oldValue;
                this.esiteService.getSpace(space).subscribe({
                  next: (spaceInfo) => {
                    const tableItem = {
                      id: details.id,
                      idRegister: details.rowId,
                      field: details.column,
                      table: details.table,
                      previousValue: details.oldValue == spaceInfo.id ? spaceInfo?.code : details.oldValue,
                      actualValue: details.newValue == spaceInfo.id ? spaceInfo?.code : details.newValue,
                    };
                    this.auditData.push(tableItem);
                    this.updateTable();
                  },
                  error: () => {
                    this.defaultTableItem(details);
                  },
                });
                break;
              }
              case 'subsection': {
                const subsection = details.newValue ? details.newValue : details.oldValue;

                this.esiteService.getSubsection(subsection).subscribe({
                  next: (subsectionInfo) => {
                    subsectionInfo = subsectionInfo[0];
                    const tableItem = {
                      id: details.id,
                      idRegister: details.rowId,
                      field: details.column,
                      table: details.table,
                      previousValue: details.oldValue == subsectionInfo.id ? subsectionInfo?.name : details.oldValue,
                      actualValue: details.newValue == subsectionInfo.id ? subsectionInfo?.name : details.newValue,
                    };
                    this.auditData.push(tableItem);
                    this.updateTable();
                  },
                  error: () => {
                    this.defaultTableItem(details);
                  },
                });
                break;
              }
              default: {
                this.defaultTableItem(details);

                break;
              }
            }
            break;
          }
          case 'ContinuousReservation': {
            switch (details.column) {
              case 'reservationusersid': {
                const user = details.newValue ? details.newValue : details.oldValue;
                this.reservationService.getReservationUser(user).subscribe({
                  next: (userInfo) => {
                    const tableItem = {
                      id: details.id,
                      idRegister: details.rowId,
                      field: details.column,
                      table: details.table,
                      previousValue: details.oldValue == userInfo.id ? userInfo?.name : details.oldValue,
                      actualValue: details.newValue == userInfo.id ? userInfo?.name : details.newValue,
                    };
                    this.auditData.push(tableItem);
                    this.updateTable();
                  },
                  error: () => {
                    this.defaultTableItem(details);
                  },
                });
                break;
              }
              case 'reservablespacesid': {
                const space = details.newValue ? details.newValue : details.oldValue;

                this.reservationService.getRservationSpaceInfo(space).subscribe({
                  next: (spaceInfo) => {
                    const tableItem = {
                      id: details.id,
                      idRegister: details.rowId,
                      field: details.column,
                      table: details.table,
                      previousValue: details.oldValue == spaceInfo.id ? spaceInfo?.code : details.oldValue,
                      actualValue: details.newValue == spaceInfo.id ? spaceInfo?.code : details.newValue,
                    };
                    this.auditData.push(tableItem);
                    this.updateTable();
                  },
                  error: () => {
                    this.defaultTableItem(details);
                  },
                });
                break;
              }
              default: {
                this.defaultTableItem(details);

                break;
              }
            }
            break;
          }
          case 'Subsections': {
            switch (details.column) {
              case 'type': {
                this.esiteService.getSubsectionTypes().subscribe({
                  next: (subsectionTypes) => {
                    const tableItem = {
                      id: details.id,
                      idRegister: details.rowId,
                      field: details.column,
                      table: details.table,
                      previousValue: subsectionTypes.find((item) => item.id == details.oldValue)?.name
                        ? subsectionTypes.find((item) => item.id == details.oldValue)?.name
                        : details.oldValue,
                      actualValue: subsectionTypes.find((item) => item.id == details.oldValue)?.name
                        ? subsectionTypes.find((item) => item.id == details.newValue)?.name
                        : details.newValue,
                    };
                    this.auditData.push(tableItem);
                    this.updateTable();
                  },
                  error: () => {
                    this.defaultTableItem(details);
                  },
                });

                break;
              }
              default: {
                this.defaultTableItem(details);

                break;
              }
            }
            break;
          }
          case 'RelationUsersBuildings': {
            switch (details.column) {
              case 'building': {
                const building = details.newValue ? details.newValue : details.oldValue;
                this.habitatService.getBuilding(building).subscribe({
                  next: (building) => {
                    const tableItem = {
                      id: details.id,
                      idRegister: details.rowId,
                      field: details.column,
                      table: details.table,
                      previousValue: details.oldValue == building.id ? building?.name : details.oldValue,
                      actualValue: details.newValue == building.id ? building?.name : details.newValue,
                    };
                    this.auditData.push(tableItem);
                    this.updateTable();
                  },
                  error: () => {
                    this.defaultTableItem(details);
                  },
                });

                break;
              }
              case 'user': {
                const userID = details.newValue ? details.newValue : details.oldValue;
                this.habitatService.getUserbyId(userID).subscribe({
                  next: (user) => {
                    const tableItem = {
                      id: details.id,
                      idRegister: details.rowId,
                      field: details.column,
                      table: details.table,
                      previousValue: details.oldValue == user.id ? user?.name + ' ' + user?.surname : details.oldValue,
                      actualValue: details.newValue == user.id ? user?.name + ' ' + user?.surname : details.newValue,
                    };
                    this.auditData.push(tableItem);
                    this.updateTable();
                  },
                  error: () => {
                    this.defaultTableItem(details);
                  },
                });

                break;
              }
              default: {
                this.defaultTableItem(details);

                break;
              }
            }

            break;
          }
          default: {
            this.defaultTableItem(details);

            break;
          }
        }
      });
    });
  }

  updateTable(): void {
    this.dataSource.data = this.auditData;
    this.dataSource.paginator = this.paginator;
    this.loading = false;
  }

  sortData(event: any): void {
    this.sortColumn = event.active;
    this.sortOrder = event.direction.toUpperCase();
    this.pageIndex = this.INITIAL_PAGE;
    this.loadAuditoriaDetail();
  }

  close(): void {
    this.dialogRef.close();
  }

  defaultTableItem(details): void {
    const tableItem = {
      id: details.id,
      idRegister: details.rowId,
      field: details.column,
      table: details.table,
      previousValue: details.oldValue,
      actualValue: details.newValue,
    };
    this.auditData.push(tableItem);
    this.updateTable();
  }
}
