export class ReservationOwner {
  id: number;
  employeeNumber: string;
  email: string;
  name: string;
  role: string;
  office: string;
  area: string;
  pushId: string;

  constructor(msg) {
    this.id = msg?.id;
    this.employeeNumber = msg?.employeeNumber;
    this.email = msg?.email;
    this.name = msg?.name;
    this.role = msg?.role;
    this.office = msg?.office;
    this.area = msg?.area;
    this.pushId = msg?.pushId;
  }
}
