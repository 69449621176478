import { UnregisteredUserExtended } from './UnregisteredUserExtended.interface';

export class UnregisteredUser {
  email: string;
  ssoId: string;
  name: string;

  constructor(msg: UnregisteredUserExtended) {
    this.email = msg['E-Mail-Addresses'] || msg.email || msg.preferred_username || '';
    this.name = msg['Display-Name'] || msg['custom:full_name'] || msg.name || '';
    this.ssoId = msg['Employee-Number'] || msg['custom:sso_id'] || msg['employeeid'] || msg['custom:employeeid'] || '';
  }
}
