import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-not-permission-auth',
  templateUrl: './not-permission-auth.component.html',
  styleUrls: ['../auth.component.scss'],
})
export class NotPermissionAuthComponent {
  constructor(private authService: AuthService) {}

  /**
   * Forze logout
   */
  logout(): void {
    this.authService.logout();
  }
}
