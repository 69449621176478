import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { DateService } from 'src/app/services/common/date.service';

@Component({
  selector: 'app-select-dates',
  templateUrl: './select-dates.component.html',
  styleUrls: ['./select-dates.component.scss'],
})
export class SelectDatesComponent {
  @Input() index = 0;
  @Output() startDateSelected: EventEmitter<number>;
  @Output() endDateSelected: EventEmitter<number>;

  startDay = '';
  endDay = '';
  loading = true;

  @ViewChild('picker') picker: any;
  @ViewChild('picker2') picker2: any;

  public date: moment.Moment;
  public disabled = false;
  public showSpinners = true;
  public showSeconds = false;
  public touchUi = false;
  public enableMeridian = true;
  public minDate: moment.Moment = moment.utc('2020-01-01');
  public maxDate: moment.Moment = moment.utc().local();
  public maxDateForStartControl = this.maxDate;
  public minDateForEndControl = this.minDate;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new UntypedFormGroup({
    date: new UntypedFormControl(null, [Validators.required]),
    date2: new UntypedFormControl(null, [Validators.required]),
  });

  public dateControlStart;
  public dateControlEnd;
  public dateControlMinMax = new UntypedFormControl(new Date());

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' },
  ];

  public listColors = ['primary', 'accent', 'warn'];

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];

  constructor(private dialog: MatDialog, private dateService: DateService) {
    this.startDateSelected = new EventEmitter();
    this.endDateSelected = new EventEmitter();
    const now = this.dateService.getUTCNow();
    this.startDay = this.dateService.getDateDiffDays(now, -8);
    this.endDay = this.dateService.getDateDiffDays(now, -1);
    const startDayHour = new Date(this.startDay + ' ' + '00:00:00');
    this.dateControlStart = new UntypedFormControl({ value: startDayHour, disabled: this.disabled });
    const endDayHour = new Date(this.endDay + ' ' + '00:00:00');
    this.dateControlEnd = new UntypedFormControl({ value: endDayHour, disabled: this.disabled });
    this.maxDateForStartControl = this.dateControlEnd.value;
    this.minDateForEndControl = this.dateControlStart.value;
  }

  startDateChanged(evt): void {
    this.startDateSelected.emit(evt);
    this.minDateForEndControl = evt;
  }

  endDateChanged(evt): void {
    this.endDateSelected.emit(evt);
    this.maxDateForStartControl = evt;
  }
}
