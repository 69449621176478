import { ZoneType } from './ZoneType.enum';

export class Zone {
  id: number;
  name: string;
  alias: string;
  description: string;
  occupation: number;
  maxOccupation: number;
  graphicalInfo: string;
  type: ZoneType;

  constructor(msg) {
    this.id = msg.id ? msg.id : null;
    this.name = msg.name ? msg.name : '';
    this.alias = msg.alias ? msg.alias : '';
    this.description = msg.description ? msg.description : '';
    this.maxOccupation = msg.maxOccupation ? msg.maxOccupation : null;
    this.graphicalInfo = msg.path ? msg.path : null;
    this.type = msg.type ? ZoneType[msg.type] : ZoneType.Occupation;
    this.updateOccupation(msg.occupation);
  }

  updateOccupation(newOccupation: number): void {
    if (!newOccupation || newOccupation < 0) {
      this.occupation = 0;
    } else {
      this.occupation = newOccupation;
    }
  }

  getPercentage(): number {
    let percentage = 0;
    if (this.occupation && this.maxOccupation && this.maxOccupation !== 0) {
      percentage = (this.occupation / this.maxOccupation) * 100;
    }
    if (percentage < 0) {
      percentage = 0;
    }
    if (percentage > 100) {
      percentage = 100;
    }
    return Math.round((percentage + Number.EPSILON) * 100) / 100; // 2 decimal digits round.
  }

  toBackenFormat(floorId): any {
    const backendObject = {
      name: this.name,
      alias: this.name,
      description: this.description,
      floor: floorId,
      maxOccupation: this.maxOccupation,
      path: this.graphicalInfo,
      type: ZoneType[this.type],
    };
    return backendObject;
  }
}
