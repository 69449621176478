<div *ngIf="loading" class="d-flex align-items-center justify-content-center" style="min-height: 350px">
  <app-loading></app-loading>
</div>
<form *ngIf="!loading" class="row p-2" [formGroup]="configForm" (ngSubmit)="submitConfigForm()">
  <fieldset class="col-md-6 col-sm-12" [disabled]="disabled">
    <legend></legend>
    <mat-form-field class="form-group separateDiv" [title]="getTooltipOf('checkInWindowInMinutes')">
      <mat-label>{{ 'checkInWindowInMinutes' | translate }}</mat-label>
      <div class="contenedor-flexbox">
        <div class="input-flexbox">
          <input
            title="{{ 'checkInWindowInMinutes' | translate }}"
            placeholder="{{ 'checkInWindowInMinutes' | translate }}"
            matInput
            type="number"
            onkeydown="return event.keyCode !== 69"
            autocomplete="off"
            formControlName="checkInWindowInMinutes"
            class="p-1"
            spellcheck="false"
            min="0"
            max="99999"
            step="1"
            required
          />
        </div>
      </div>
      <mat-error *ngIf="f.checkInWindowInMinutes.errors?.required">{{
        'checkInWindowInMinutes is required' | translate
      }}</mat-error>
      <mat-error *ngIf="f.checkInWindowInMinutes.errors?.min">{{
        'Value should be higher or equal to 0' | translate
      }}</mat-error>
      <mat-error *ngIf="f.checkInWindowInMinutes.errors?.max">{{
        'Value is too high. Please use a lower number' | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field class="form-group separateDiv" [title]="getTooltipOf('checkInReminderInMinutes')">
      <mat-label>{{ 'checkInReminderInMinutes' | translate }}</mat-label>
      <div class="contenedor-flexbox">
        <div class="input-flexbox">
          <input
            title="{{ 'checkInReminderInMinutes' | translate }}"
            placeholder="{{ 'checkInReminderInMinutes' | translate }}"
            matInput
            type="number"
            onkeydown="return event.keyCode !== 69"
            autocomplete="off"
            formControlName="checkInReminderInMinutes"
            class="p-1"
            spellcheck="false"
            min="0"
            max="99999"
            step="1"
            required
          />
        </div>
      </div>
      <mat-error *ngIf="f.checkInReminderInMinutes.errors?.required">{{
        'checkInReminderInMinutes is required' | translate
      }}</mat-error>
      <mat-error *ngIf="f.checkInReminderInMinutes.errors?.min">{{
        'Value should be higher or equal to 0' | translate
      }}</mat-error>
      <mat-error *ngIf="f.checkInReminderInMinutes.errors?.max">{{
        'Value is too high. Please use a lower number' | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field class="form-group separateDiv" [title]="getTooltipOf('individualReservationsLimit')">
      <mat-label>{{ 'individualReservationsLimit' | translate }}</mat-label>
      <div class="contenedor-flexbox">
        <div class="input-flexbox">
          <input
            title="{{ 'individualReservationsLimit' | translate }}"
            placeholder="{{ 'individualReservationsLimit' | translate }}"
            matInput
            type="number"
            onkeydown="return event.keyCode !== 69"
            autocomplete="off"
            formControlName="individualReservationsLimit"
            class="p-1"
            spellcheck="false"
            min="0"
            max="99999"
            step="1"
            required
          />
        </div>
      </div>
      <mat-error *ngIf="f.individualReservationsLimit.errors?.required">{{
        'individualReservationsLimit is required' | translate
      }}</mat-error>
      <mat-error *ngIf="f.individualReservationsLimit.errors?.min">{{
        'Value should be higher or equal to 0' | translate
      }}</mat-error>
      <mat-error *ngIf="f.individualReservationsLimit.errors?.max">{{
        'Value is too high. Please use a lower number' | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field class="form-group separateDiv" [title]="getTooltipOf('futureReservationLimitInDays')">
      <mat-label>{{ 'futureReservationLimitInDays' | translate }}</mat-label>
      <div class="contenedor-flexbox">
        <div class="input-flexbox">
          <input
            title="{{ 'futureReservationLimitInDays' | translate }}"
            placeholder="{{ 'futureReservationLimitInDays' | translate }}"
            matInput
            type="number"
            onkeydown="return event.keyCode !== 69"
            autocomplete="off"
            formControlName="futureReservationLimitInDays"
            class="p-1"
            spellcheck="false"
            min="0"
            max="99999"
            step="1"
            required
          />
        </div>
      </div>
      <mat-error *ngIf="f.futureReservationLimitInDays.errors?.required">{{
        'futureReservationLimitInDays is required' | translate
      }}</mat-error>
      <mat-error *ngIf="f.futureReservationLimitInDays.errors?.min">{{
        'Value should be higher or equal to 0' | translate
      }}</mat-error>
      <mat-error *ngIf="f.futureReservationLimitInDays.errors?.max">{{
        'Value is too high. Please use a lower number' | translate
      }}</mat-error>
    </mat-form-field>
    <mat-form-field class="form-group separateDiv" [title]="getTooltipOf('plusCheckInTimeInMinutes')">
      <mat-label>{{ 'plusCheckInTimeInMinutes' | translate }}</mat-label>
      <div class="contenedor-flexbox">
        <div class="input-flexbox">
          <input
            title="{{ 'plusCheckInTimeInMinutes' | translate }}"
            placeholder="{{ 'plusCheckInTimeInMinutes' | translate }}"
            matInput
            type="number"
            onkeydown="return event.keyCode !== 69"
            autocomplete="off"
            formControlName="plusCheckInTimeInMinutes"
            class="p-1"
            spellcheck="false"
            min="0"
            max="99999"
            step="1"
            required
          />
        </div>
      </div>
      <mat-error *ngIf="f.plusCheckInTimeInMinutes.errors?.required">{{
        'plusCheckInTimeInMinutes is required' | translate
      }}</mat-error>
      <mat-error *ngIf="f.plusCheckInTimeInMinutes.errors?.min">{{
        'Value should be higher or equal to 0' | translate
      }}</mat-error>
      <mat-error *ngIf="f.plusCheckInTimeInMinutes.errors?.max">{{
        'Value is too high. Please use a lower number' | translate
      }}</mat-error>
    </mat-form-field>
  </fieldset>
  <fieldset class="col-md-6 col-sm-12" [disabled]="disabled">
    <legend></legend>

    <div class="row">
      <div class="col-12">
        <mat-form-field class="form-group w-100" [title]="getTooltipOf('reservationReminderInMinutes')">
          <mat-label>{{ 'reservationReminderInMinutes' | translate }}</mat-label>
          <div class="contenedor-flexbox">
            <div class="input-flexbox">
              <input
                title="{{ 'reservationReminderInMinutes' | translate }}"
                placeholder="{{ 'reservationReminderInMinutes' | translate }}"
                matInput
                type="number"
                onkeydown="return event.keyCode !== 69"
                autocomplete="off"
                formControlName="reservationReminderInMinutes"
                class="p-1"
                spellcheck="false"
                min="0"
                max="99999"
                step="1"
                required
              />
            </div>
          </div>
          <mat-error *ngIf="f.reservationReminderInMinutes.errors?.required">{{
            'reservationReminderInMinutes is required' | translate
          }}</mat-error>
          <mat-error *ngIf="f.reservationReminderInMinutes.errors?.min">{{
            'Value should be higher or equal to 0' | translate
          }}</mat-error>
          <mat-error *ngIf="f.reservationReminderInMinutes.errors?.max">{{
            'Value is too high. Please use a lower number' | translate
          }}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="" class="w-100" [title]="getTooltipOf('morningStart')">
          <mat-label class="hasValue">{{ 'morningStart' | translate }}</mat-label>
          <div class="minutes-gap-example">
            <input
              title="{{ 'morningStart' | translate }}"
              id="morningStart"
              placeholder="{{ 'morningStart' | translate }}"
              class="p-1"
              formControlName="morningStart"
              matInput
              [ngxTimepicker]="morningStart"
              [format]="24"
              [max]="maxTimeForMorningStart"
              [value]="configForm.value.morningStart"
              style="cursor: pointer"
              required
              readonly
            />
            <ngx-material-timepicker
              #morningStart
              [minutesGap]="5"
              [cancelBtnTmpl]="cancelBtn"
              [confirmBtnTmpl]="confirmBtn"
              [format]="24"
              (closed)="modifyMorningAfternoonAvailableValues()"
            >
            </ngx-material-timepicker>
            <span class="responsiveClockLeft bi bi-clock pb-1"></span>
            <div *ngIf="submitted && f.morningStart.errors" class="invalid-feedback">
              <div *ngIf="f.morningStart.errors.required" style="position: fixed">
                {{ 'morningStart is required' | translate }}
              </div>
            </div>
          </div>
        </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field appearance="" class="w-100" [title]="getTooltipOf('morningEnd')">
          <mat-label class="hasValue">{{ 'morningEnd' | translate }}</mat-label>
          <div class="minutes-gap-example">
            <input
              title="{{ 'morningEnd' | translate }}"
              id="morningEnd"
              placeholder="{{ 'morningEnd' | translate }}"
              class="p-1"
              formControlName="morningEnd"
              matInput
              [ngxTimepicker]="morningEnd"
              [format]="24"
              [min]="minTimeForMorningEnd"
              [max]="maxTimeForMorningEnd"
              [value]="configForm.value.morningEnd"
              style="cursor: pointer"
              required
              readonly
            />
            <ngx-material-timepicker
              #morningEnd
              [minutesGap]="5"
              [cancelBtnTmpl]="cancelBtn"
              [confirmBtnTmpl]="confirmBtn"
              [format]="24"
              (closed)="modifyMorningAfternoonAvailableValues()"
            >
            </ngx-material-timepicker>
            <span class="responsiveClockRight bi bi-clock pb-1"></span>
            <div *ngIf="submitted && f.morningEnd.errors" class="invalid-feedback">
              <div *ngIf="f.morningEnd.errors.required" class="position-fixed">
                {{ 'morningEnd is required' | translate }}
              </div>
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <mat-form-field appearance="" class="w-100" [title]="getTooltipOf('afternoonStart')">
          <mat-label class="hasValue">{{ 'afternoonStart' | translate }}*</mat-label>

          <div class="minutes-gap-example">
            <input
              title="{{ 'afternoonStart' | translate }}"
              id="afternoonStart"
              placeholder="{{ 'afternoonStart' | translate }}"
              class="p-1"
              formControlName="afternoonStart"
              matInput
              [ngxTimepicker]="afternoonStart"
              [format]="24"
              [min]="minTimeForAfternoonStart"
              [max]="maxTimeForAfternoonStart"
              [value]="configForm.value.afternoonStart"
              style="cursor: pointer"
              required
              readonly
            />
            <ngx-material-timepicker
              #afternoonStart
              [minutesGap]="5"
              [cancelBtnTmpl]="cancelBtn"
              [confirmBtnTmpl]="confirmBtn"
              [format]="24"
              (closed)="modifyMorningAfternoonAvailableValues()"
            >
            </ngx-material-timepicker>
            <span class="responsiveClockLeft bi bi-clock pb-1"></span>
            <div *ngIf="submitted && f.afternoonStart.errors" class="invalid-feedback">
              <div *ngIf="f.afternoonStart.errors.required" style="position: fixed">
                {{ 'afternoonStart is required' | translate }}
              </div>
            </div>
          </div>
        </mat-form-field>
      </div>

      <div class="col-6">
        <mat-form-field appearance="" class="w-100" [title]="getTooltipOf('afternoonEnd')">
          <mat-label class="hasValue">{{ 'afternoonEnd' | translate }}*</mat-label>

          <div class="minutes-gap-example">
            <input
              title="{{ 'afternoonEnd' | translate }}"
              id="afternoonEnd"
              placeholder="{{ 'afternoonEnd' | translate }}"
              class="p-1"
              formControlName="afternoonEnd"
              matInput
              [ngxTimepicker]="afternoonEnd"
              [format]="24"
              [min]="minTimeForAfternoonEnd"
              [value]="configForm.value.afternoonEnd"
              style="cursor: pointer"
              required
              readonly
            />
            <ngx-material-timepicker
              #afternoonEnd
              [minutesGap]="5"
              [cancelBtnTmpl]="cancelBtn"
              [confirmBtnTmpl]="confirmBtn"
              [format]="24"
              (closed)="modifyMorningAfternoonAvailableValues()"
            >
            </ngx-material-timepicker>
            <span class="responsiveClockRight bi bi-clock pb-1"></span>
            <div *ngIf="submitted && f.afternoonEnd.errors" class="invalid-feedback">
              <div *ngIf="f.afternoonEnd.errors.required" style="position: fixed">
                {{ 'afternoonEnd is required' | translate }}
              </div>
            </div>
          </div>
        </mat-form-field>
      </div>
    </div>

    <div class="row" style="width: 100%; margin-top: 0px">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 responsiveTime1">
        <div class="form-group separateDiv alignSlideToggle">
          <mat-label class="hasValue" [title]="getTooltipOf('mailNotificationsEnabled')">{{
            'EmailNotifications' | translate
          }}</mat-label>
          <div class="contenedor-flexbox">
            <div class="input-flexbox">
              <div class="minutes-gap-example responsiveTime11">
                <mat-slide-toggle formControlName="mailNotificationsEnabled"> </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 responsiveTime2">
        <div class="form-group separateDiv alignSlideToggle" style="margin-left: 5px">
          <mat-label class="hasValue" [title]="getTooltipOf('pushNotificationsEnabled')">{{
            'PushNotifications' | translate
          }}</mat-label>
          <div class="contenedor-flexbox">
            <div class="input-flexbox">
              <div class="minutes-gap-example">
                <mat-slide-toggle formControlName="pushNotificationsEnabled"> </mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="canEdit" class="col-sm-12 buttonsRow" style="place-content: center">
      <button
        title="{{ 'Save' | translate }}"
        type="submit"
        mat-button
        color="primary"
        [disabled]="configForm.invalid || disableSave"
      >
        <span>{{ 'Save' | translate }}</span>
      </button>
    </div>
  </fieldset>
</form>

<ng-template #cancelBtn>
  <button class="modalButton text-primary" style="width: 100px">{{ 'Cancel' | translate }}</button>
</ng-template>
<ng-template #confirmBtn>
  <button class="modalButton bg-primary" style="width: 100px; margin-left: 15px">
    {{ 'Confirm' | translate }}
  </button>
</ng-template>

<ng-template #saveConfiguration>
  <mat-card style="width: 400px">
    <mat-card-content class="marginBodyCard">
      <div *ngIf="loading2" style="min-height: 350px; display: flex; align-items: center; justify-content: center">
        <app-loading></app-loading>
      </div>

      <div *ngIf="!loading2">
        <label class="titleN3 text-primary modalTitle">{{ 'Confirm' | translate }}</label>
        <label class="titleN4">{{ '¿Estas seguro de modificar los siguientes edificios?' | translate }}</label>
        <ul class="data-container" *ngIf="selectedBuildings?.length > 0">
          <li *ngFor="let item of selectedBuildings" class="p-2">
            <span>{{ item.name }}</span>
          </li>
        </ul>

        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="close()" type="button">
            {{ 'Cancel' | translate }}
          </button>
          <button mat-button class="modalButton bg-primary" type="submit" (click)="save()">
            {{ 'Save' | translate }}
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>

<!-- table error building configuration -->
<ng-template #errorConfiguration>
  <mat-card style="width: 400px">
    <mat-card-content class="marginBodyCard">
      <label class="titleN3 text-primary modalTitle">{{ 'Error' | translate }}</label>
      <label class="titleN4" [ngStyle]="{ color: '#f44336' }">{{
        'An error has occurred while modifying the following buildings' | translate
      }}</label>

      <table mat-table [dataSource]="dataSource" style="width: 100%" matSort #sortData="matSort">
        <caption></caption>
        <th id="GroupsConfigErrorTable"></th>

        <ng-container matColumnDef="buildingName">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3"
            >{{ 'Building' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let group" style="flex: 1.3">
            <span *ngIf="group.buildingName">
              {{ group.buildingName }}
            </span>
            <span *ngIf="!group.buildingName"> -- </span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="error">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3"
            >{{ 'Reason' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let group" style="flex: 1.3">
            <span *ngIf="group.error">
              {{ group.error }}
            </span>
            <span *ngIf="!group.error"> -- </span>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
      </table>
      <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>

      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
        <button mat-button class="modalCancel text-primary" (click)="close()" type="button">
          {{ 'Aceptar' | translate }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
