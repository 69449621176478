<collapse-card title="{{ 'Data' | translate }} {{ building?.name }}">
  <div
    *ngIf="loading"
    style="text-align: -webkit-center; text-align: -moz-center; padding-top: 15%; min-height: 400px"
  >
    <app-loading></app-loading>
  </div>
  <div [ngClass]="{ 'd-block': !loading, 'd-none': loading }">
    <div
      style="
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        align-items: center;
      "
    >
      <div class="titleN3">{{ 'Data' | translate }}</div>
      <button
        mat-button
        *ngIf="!building.confirmed && canConfirmBuilding"
        (click)="open(confirmSpaceModal)"
      >
        <span class="smallIcon bi bi-check2-circle"></span>
        <span class="ps-1">{{ 'ConfirmSpace' | translate }}</span>
      </button>
    </div>
    <div>
      <div *ngIf="building" class="row" style="margin-top: 10px">
        <div class="row col-12 py-1">
          <div class="col-6 col-md-2 ms-0 ms-md-3">
            {{ 'Space' | translate }}
          </div>
          <div class="col-6 col-md-8">
            {{ building?.name }}
          </div>
        </div>
        <div class="row col-12 py-1">
          <div class="col-6 col-md-2 ms-0 ms-md-3">
            {{ 'City' | translate }}
          </div>
          <div class="col-6 col-md-8">
            {{ building?.city?.name }}
          </div>
        </div>
        <div class="row col-12 py-1">
          <div class="col-6 col-md-2 ms-0 ms-md-3">
            {{ 'Address' | translate }}
          </div>
          <div class="col-6 col-md-8">
            {{ building?.address }}
          </div>
        </div>
        <div class="row col-12 py-1">
          <div class="col-6 col-md-2 ms-0 ms-md-3">
            {{ 'CP' | translate }}
          </div>
          <div class="col-6 col-md-8">
            {{ building?.postalCode }}
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="hasEsiteModule"
      style="width: 100%; display: flex; justify-content: space-between; margin-top: 30px; align-items: center"
    >
      <div class="titleN3">{{ 'Sites' | translate }}</div>
    </div>
    <div *ngIf="noFloorSpaces && hasEsiteModule" style="text-align-last: center">
      {{ 'There are no sites' | translate }}
    </div>
    <div
      [ngClass]="{ visibleTable: hasEsiteModule, noVisibleTable: !hasEsiteModule }"
      [ngClass]="{ visibleTable: !noFloorSpaces, noVisibleTable: noFloorSpaces }"
    >
      <div class="row pb-3 p-md-1" [formGroup] = "tableFilterFormGroup">
        <div class="col-6">
          <div *ngIf="hasEsiteModule" style="padding-top: 15px; position: absolute">
            <mat-radio-group [(ngModel)]="selectedOption" formControlName="selectedOption"  (change)="checkTable(true)">
              <mat-radio-button [value]="optionSpaces" style="margin-right: 20px"
                >{{ 'Workstations' | translate }}
              </mat-radio-button>
              <mat-radio-button *ngIf="hasReservationsModule" [value]="optionReservation"
                >{{ 'Reservations' | translate }}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
                <div *ngIf="!isReservationTable" [ngClass]="!isReservationTable ? 'col-2' : 'col-3'">
                  <mat-form-field>
                    <mat-label>{{ 'Use type' | translate }}</mat-label>
                    <mat-select formControlName="useType" (selectionChange)="applyFilter($event,'useType')" formControlName="useType">
                      <mat-option *ngFor="let useType of useTypes" [value]="useType" >
                        {{ useType | translate }}
                      </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="icon-button" 
                    *ngIf="tableFilterFormGroup.value.useType"
                    [title]="'Clear selection' | translate"
                    (click)="clearField('useType')">
                      highlight_off
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div class="col-2" *ngIf="hasEsiteModule" >
                  <mat-form-field>
                    <mat-label>{{ 'Type' | translate }}</mat-label>
                    <mat-select formControlName="type" (selectionChange)="applyFilter($event,'type')" formControlName="type" >
                      <mat-option *ngFor="let type of typesForSelect" [value]="type" >
                        {{ type.name | translate }}
                      </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="icon-button" 
                    *ngIf="tableFilterFormGroup.value.type"
                    [title]="'Clear selection' | translate"
                    (click)="clearField('type')">
                      highlight_off
                    </mat-icon>
                  </mat-form-field>
                </div>
                <div [ngClass]="!isReservationTable ? 'col-2' : 'col-3'">
          <mat-form-field class="d-flex justify-content-end">
            <input class="py-1" matInput (keyup)="applyFilter($event, 'search')" placeholder="{{ 'Search' | translate }}" #input />
          </mat-form-field>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" style="width: 100%" matSort #sortData="matSort">
        <caption></caption>
        <th id="usersTable"></th>
        <!-- COMMON Columns for both 'Sites' and 'Reservations' Info -->
        <ng-container matColumnDef="code">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary"
            style="flex: 1.3; overflow: hidden"
          >
            {{ 'Site code' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 1.3; overflow: hidden">
            <span class="mobile-label">{{ 'Site code' | translate }}: </span> {{ space.code }}
            <span *ngIf="!space.code">--</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="type">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.9">
            {{ 'Type' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 0.9">
            <span class="mobile-label">{{ 'Type' | translate }}: </span> {{ space.typeName }}
            <span *ngIf="!space.typeName">--</span>
          </mat-cell> 
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary"
            style="flex: 0.5; overflow: hidden"
          >
            {{ 'Actions' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 0.5; justify-content: center">
            <span
              *ngIf="selectedOption == optionSpaces && canWriteEsiteSpaces"
              class="smallIcon me-2 bi bi-pencil"
              role="button"
              (click)="editEsiteSpace(space.id)"
            >
            </span>
            <span
              *ngIf="selectedOption == optionSpaces && !canWriteEsiteSpaces"
              class="smallIcon me-2 bi bi-eye"
              role="button"
              (click)="editEsiteSpace(space.id)"
            >
            </span>
            <span
              *ngIf="selectedOption == optionReservation && canWriteReservationsSpaces"
              class="smallIcon me-2 bi bi-pencil"
              role="button"
              (click)="editReservationSpace(space)"
            >
            </span>
            <span
              *ngIf="selectedOption == optionReservation && !canWriteReservationsSpaces"
              class="smallIcon me-2 bi bi-eye"
              role="button"
              (click)="editReservationSpace(space)"
            >
            </span>
            <span
              *ngIf="canWriteEsiteSpaces"
              class="smallIcon bi bi-trash"
              role="button"
              (click)="deleteSpace(space)"
            >
          </span>
          </mat-cell>
        </ng-container>
        <!-- Columns just displayed for 'Sites' Info -->
        <ng-container matColumnDef="name">
          <mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="text-primary"
            style="flex: 1.6; overflow: hidden"
          >
            {{ 'Name' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 1.6; overflow: hidden">
            <span class="mobile-label">{{ 'Name' | translate }}: </span> {{ space.name }}
            <span *ngIf="!space.name">--</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 2.2">
            {{ 'Description' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 2.2; overflow: hidden">
            <span class="mobile-label">{{ 'Description' | translate }}: </span> {{ space.description }}
            <span *ngIf="!space.description">--</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="resources">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.9">
            {{ 'Resources' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 1.9">
            <span class="mobile-label">{{ 'Resources' | translate }}: </span>
            <div class="w-100">
              <span *ngIf="!space.resources || space.resources?.length === 0">--</span>
              <p-dropdown
                *ngIf="space.resources?.length > 0"
                [name]="space.code"
                [inputId]="space.code"
                [options]="space.resources"
                optionLabel="name"
                optionValue="id"
                [filter]="true"
                filterBy="name"
                [autoWidth]="false"
              >
                <ng-template let-resource pTemplate="item">
                  <div class="d-flex align-items-center gap-2">
                    <div *ngIf="resource.type.icon">
                      <span class="bi-{{ resource.type.icon }}"></span>
                    </div>
                    <div>{{ resource.name }}</div>
                  </div>
                </ng-template>
              </p-dropdown>
            </div>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="useType">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.9" >
            {{ 'Use type' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 0.9">
            <span class="mobile-label">{{ 'Use type' | translate }}: </span> {{ space.useType }}
            <span *ngIf="!space.useType">--</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="capacity">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.5">
            {{ 'Capacity' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 0.5">
            <span class="mobile-label">{{ 'Capacity' | translate }}: </span> {{ space.capacity }}
            <span *ngIf="!space.capacity">--</span>
          </mat-cell>
        </ng-container>
        <!-- Columns just displayed for 'Reservation' Info -->
        <ng-container matColumnDef="reservable">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3">
            {{ 'Reservable' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 1.3">
            <span class="mobile-label">{{ 'Reservable' | translate }}: </span>
            {{ space.reservable | translate }}
            <span *ngIf="!space.reservable">--</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="permission">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3">
            {{ 'Permission' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 1.3">
            <span class="mobile-label">{{ 'Permission' | translate }}: </span>
            {{ space.permission | translate }}
            <span *ngIf="!space.permission">--</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="approval">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3">
            {{ 'Approval' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 1.3">
            <span class="mobile-label">{{ 'Approval' | translate }}: </span> {{ space.approval | translate }}
            <span *ngIf="!space.approval">--</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="approvalUser">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 2">
            {{ 'Approval user' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 2">
            <span class="mobile-label">{{ 'Approval user' | translate }}: </span> {{ space.approvalUser }}
            <span *ngIf="!space.approvalUser">--</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="rGroup">
          <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3">
            {{ 'R. Group' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let space" style="flex: 1.3">
            <span class="mobile-label">{{ 'R. Group' | translate }}: </span> {{ space.rGroup | translate }}
            <span *ngIf="!space.rGroup">--</span>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
      </table>
      <mat-paginator style="margin-top: 20px" [pageSize]="10" [hidePageSize]="true"> </mat-paginator>
    </div>
  </div>
</collapse-card>

<ng-template #confirmSpaceModal>
  <mat-card style="width: 625px; overflow-x: hidden">
    <mat-card-content class="row">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 title">
        {{ 'New space with pending confirmation' | translate }}
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 row background">
        <div class="offset-md-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 text-right">
          {{ 'Space' | translate }}:
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
          <span>{{ building.name }}</span>
        </div>
        <div class="offset-md-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 text-right pe-3">
          {{ 'Floors' | translate }}:
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3">
          <span>{{ building.floors.length }}</span>
        </div>
      </div>
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
        <button mat-button class="modalCancel text-primary" (click)="close()">{{ 'Cancel' | translate }}</button>
        <button mat-button class="modalButton bg-primary" (click)="confirmSpace()">
          {{ 'Save' | translate }}
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
