import { Reservation } from '../reservation/Reservation.class';

export class WebsocketReservation {
  previous: Reservation;
  current: Reservation;

  constructor(msg) {
    this.previous = new Reservation(msg.previous);
    this.current = new Reservation(msg.current);
  }
}
