<mat-card [ngStyle]="{ width: responsive ? '300px' : '500px' }">
  <mat-card-header>
    <div class="titleN3 text-primary mb-3">
      {{ 'Edit site' | translate }}:<span>
        {{ spaceCode }}
      </span>
    </div>
  </mat-card-header>
  <mat-card-content>
    <div *ngIf="loading" class="text-center p-5">
      <app-loading></app-loading>
    </div>
    <div *ngIf="!loading">
      <div class="mb-3">
        <mat-label style="font-size: 12px;">{{ 'Reservable' | translate }} </mat-label>
        <mat-radio-group [(ngModel)]="reservable" [disabled]="!canWriteReservationsSpaces">
          <div class="d-flex justify-content-center gap-4">
            <span>
              <mat-radio-button [value]="true" [checked]="reservable" (change)="changeReservable($event)">
                {{ 'Yes' | translate }}
              </mat-radio-button>
            </span>
            <span>
              <mat-radio-button [value]="false" [checked]="!reservable" (change)="changeReservable($event)">
                {{ 'No' | translate }}
              </mat-radio-button>
            </span>
          </div>
        </mat-radio-group>
      </div>
      <ng-container *ngIf="reservable">
        <div *ngIf="istARoom" class="mb-3">
          <div>
            <mat-label class="d-block" style="font-size: 12px">{{ 'Room e-mail' | translate }}</mat-label>
            <mat-form-field appearance="legacy" class="w-100">
              <input
                title="{{ 'Room e-mail' | translate }}"
                matInput
                class="py-1"
                [(ngModel)]="roomEmail"
                [disabled]="!canWriteReservationsSpaces"
              />
            </mat-form-field>
          </div>
          <div *ngIf="submitted && emailError" class="invalid-feedback ps-3">
            <div>{{ 'Must be a valid e-mail format' | translate }}</div>
          </div>
          <div
            *ngIf="submitted && duplicateEmailError"
            class="invalid-feedback ps-3"
          >
            <div>{{ 'EmailErrorDuplicated' | translate }}</div>
          </div>
        </div>
        <div class="mb-3">
          <mat-label style="font-size: 12px">{{
            'Minumun Role that can reserve individually' | translate
          }}</mat-label>
          <div>
            <mat-form-field class="w-100">
              <mat-select [(ngModel)]="userRole">
                <mat-option
                  *ngFor="let role of reservationRolesWithoutNone"
                  [value]="role"
                  [disabled]="!canWriteReservationsSpaces"
                >
                  {{ role | translate }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <form class="row" [formGroup]="spaceFormGroup">
          <div class="w-100 px-3 mb-3">
            <mat-label style="font-size: 12px">{{ 'For group reservation' | translate }}:</mat-label>
            <mat-radio-group formControlName="groupable">
              <div class="d-flex justify-content-center gap-4">
                <span>
                  <mat-radio-button [value]="true" [checked]="groupBoolean" (change)="groupableChange($event)"
                    >{{ 'Yes' | translate }}
                  </mat-radio-button>
                </span>
                <span>
                  <mat-radio-button [value]="false" [checked]="!groupBoolean" (change)="groupableChange($event)"
                    >{{ 'No' | translate }}
                  </mat-radio-button>
                </span>
              </div>
            </mat-radio-group>
          </div>
          <div class="w-100 px-3 mb-3">
            <mat-label style="font-size: 12px"
              >{{ 'Needs approval for individual reservation' | translate }}:</mat-label
            >
            <mat-radio-group formControlName="needsApproval">
              <div class="d-flex justify-content-center gap-4">
                <span>
                  <mat-radio-button [value]="true" [checked]="approbalBoolean" (change)="needsApprovalChange($event)"
                    >{{ 'Yes' | translate }}
                  </mat-radio-button>
                </span>
                <span>
                  <mat-radio-button [value]="false" [checked]="!approbalBoolean" (change)="needsApprovalChange($event)"
                    >{{ 'No' | translate }}
                  </mat-radio-button>
                </span>
              </div>
            </mat-radio-group>
          </div>
          <div *ngIf="needsApproval" class="scroll w-100 px-3">
            <mat-label style="font-size: 12px">{{ 'Users' | translate }}</mat-label>
            <mat-form-field class="col-12" *ngFor="let item of approvalUsers">
              <div class="row">
                <div [ngClass]="{ 'col-11': !responsive, 'col-10': responsive }">
                  <input
                    title="{{ 'User' | translate }}"
                    matInput
                    autocomplete="off"
                    type="text"
                    [value]="item?.nameComplete"
                    [disabled]="true"
                    class="py-1"
                  />
                </div>
                <div [ngClass]="{ 'col-1': !responsive, 'col-2': responsive }">
                  <span
                    class="smallIcon me-2 bi bi-x-circle"
                    role="button"
                    title="{{ 'Remove user' | translate }}"
                    (click)="deleteUser(item)"
                  ></span>
                </div>
              </div>
            </mat-form-field>
          </div>
        </form>

        <div *ngIf="needsApproval">
          <div class="d-flex align-items-center">
            <div class="flex-grow-1">
              <mat-form-field class="w-100">
                <mat-select [(ngModel)]="userToAdd" [disabled]="!allUsers?.length || !canWriteReservationsSpaces">
                  <mat-option *ngFor="let user of allUsers" [value]="user">
                    {{ user.name }} {{ user.surname }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <button
                mat-button
                [disabled]="!(allUsers.length && userToAdd)"
                style="margin-top: -8px; scale: 0.9"
                (click)="addUser()"
                title="{{ 'Add user' | translate }}"
              >
                <span class="smallIcon bi bi-plus-circle"></span>
                <span class="ps-1">{{ 'Add' | translate }}</span>
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="submitted && showError" class="invalid-feedback">
          <div>{{ 'Must have at least one user' | translate }}</div>
        </div>
      </ng-container>
      <div class="d-flex justify-content-center gap-4">
        <button
          mat-button
          class="modalCancel text-primary"
          style="text-decoration: none !important"
          (click)="closeModal(true)"
        >
          {{ 'Cancel' | translate }}
        </button>
        <button
          *ngIf="canWriteReservationsSpaces"
          mat-button
          class="modalButton bg-primary"
          (click)="saveChanges()"
          [disabled]="disabledSave"
        >
          {{ 'Save' | translate }}
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
