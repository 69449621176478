import { ReservationConfiguration } from './ReservationConfiguration.class';

/**
 * Reservation Configuration By Building
 */
export class ReservationConfigurationByBuilding {
  buildingId: string;
  config: ReservationConfiguration;

  constructor(msg) {
    this.buildingId = msg?.buildingId;
    this.config = msg?.config;
  }
}
