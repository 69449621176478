<mat-card class="incidentCard">
  <mat-card-header>
    <div class="row" style="text-align: center">
      <div>
        <button
          title="{{ 'New incident' | translate }}"
          (click)="open(createIncident)"
          class="buttonSvg"
          style="box-shadow: unset"
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style="cursor: pointer">
            <g fill="none" fill-rule="evenodd">
              <circle cx="24" cy="24" r="24" class="svgFill" />
              <g stroke="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4">
                <path
                  d="M21 12.342h0V20c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1h8.19M17.5 2.786L17.5 12.214M22.214 7.5L12.786 7.5"
                  transform="translate(12 12)"
                />
              </g>
            </g>
          </svg>
        </button>
      </div>
      <div>
        <label class="titleN3 d-none d-md-block" style="margin-top: 10px">{{ 'New incident' | translate }}</label>
        <label class="titleN5 d-flex d-md-none" style="flex-direction: column">{{ 'New incident' | translate }}</label>
      </div>
    </div>
  </mat-card-header>
  <mat-card-content style="display: none">
    <!-- empty -->
  </mat-card-content>
</mat-card>
<ng-template #createIncident>
  <mat-card class="modalCard">
    <mat-card-content class="marginBodyCard">
      <div
        style="min-height: 400px; display: flex; flex-direction: column; align-items: center; justify-content: center"
        *ngIf="loading && !sendingError"
      >
        <mat-label>{{ 'SendingData' | translate }}</mat-label>
        <app-loading></app-loading>
      </div>
      <div
        style="min-height: 400px; display: flex; flex-direction: column; align-items: center; justify-content: center"
        *ngIf="loading && sendingError"
      >
        <mat-label style="padding-top: 33%; padding-bottom: 33%">{{ 'SendingError' | translate }}</mat-label>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="close()">{{ 'Close' | translate }}</button>
        </div>
      </div>
      <div *ngIf="!loading" class="form" style="text-align: left">
        <label class="titleN3 text-primary modalTitle">{{ 'New incident' | translate }} </label>
        <form [formGroup]="incidentFormGroup" (ngSubmit)="confirmRequest()">
          <mat-form-field class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 paddingOdd">
            <mat-label>{{ 'Title' | translate }}</mat-label>
            <input
              placeholder="{{ 'Title' | translate }}"
              matInput
              autocomplete="off"
              formControlName="title"
              type="text"
              class="p-1"
              maxlength="50"
              required
              spellcheck="false"
            />
            <mat-hint *ngIf="incidentFormGroup.get('title')?.value.length" align="end"
              >{{ incidentFormGroup.get('title').value.length }}/50</mat-hint
            >
            <mat-error *ngIf="f.title.errors?.required">{{ 'Title is required' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 paddingOdd">
            <mat-label>{{ 'Category' | translate }}</mat-label>
            <mat-select class="p-1" formControlName="category" required>
              <mat-option *ngFor="let cat of categories" [value]="cat.id">{{ cat.name | translate }} </mat-option>
            </mat-select>
            <mat-error *ngIf="f.category.errors?.required">{{ 'Category is required' | translate }}</mat-error>
          </mat-form-field>
          <mat-form-field class="form-group col-xl-12 col-lg-12 col-md-12 col-sm-12 paddingOdd">
            <mat-label>{{ 'Description' | translate }}</mat-label>
            <textarea
              placeholder="{{ 'Description' | translate }}"
              name="text"
              maxlength="500"
              matInput
              style="height: 100px; max-height: 200px"
              formControlName="description"
              required
              spellcheck="false"
            ></textarea>
            <mat-hint *ngIf="incidentFormGroup.get('description')?.value.length" align="end"
              >{{ incidentFormGroup.get('description').value.length }}/500</mat-hint
            >
            <mat-error *ngIf="f.description.errors?.required">{{ 'Description is required' | translate }}</mat-error>
          </mat-form-field>
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
            <button mat-button class="modalCancel text-primary" (click)="close()" type="button">
              {{ 'Cancel' | translate }}
            </button>
            <button mat-button class="modalButton bg-primary" type="submit">
              {{ 'Send' | translate }}
            </button>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
