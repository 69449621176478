<div class="modalCard">
  <div class="marginBodyCard" style="max-height: 550px">
    <div class="titleN3 text-primary modalTitle"> {{ 'Add user' | translate }}</div>
    <div
      *ngIf="loading"
      style="min-height: 350px; display: flex; align-items: center; justify-content: center; min-width: 500px"
      [ngStyle]="{ property: expression }"
    >
      <app-loading></app-loading>
    </div>
    <div [hidden]="loading" class="responsive">
      <div class="row pb-4">
        <div class="col-10">
          <mat-form-field style="width: 100%">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input
              class="p-1"
              matInput
              (keyup.enter)="triggeSearch()"
              [(ngModel)]="inputSearchName"
              placeholder="{{ 'Enter name' | translate }}"
              type="text"
              maxlength="45"
              spellcheck="false"
            />
            <mat-hint *ngIf="inputSearchName.length" align="end">{{ inputSearchName.length }}/45</mat-hint>
            <mat-hint align="start">{{ 'If the user is not showing, try refining the search' | translate }}</mat-hint>
          </mat-form-field>
        </div>
        <div class="col d-flex align-items-center">
          <span
            class="bi bi-search text-primary"
            role="button"
            (click)="triggeSearch()"
          ></span>
        </div>
      </div>
      <div style="text-align: -webkit-center; background-color: #8f9bb312">
        <div class="scroll" style="width: 95%; margin-bottom: 10px">
          <table mat-table [dataSource]="dataSource" style="width: 100%">
            <caption></caption>
            <th id="reservationUserFormTable1"></th>
            <ng-container matColumnDef="action" [sticky]="true">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.3">
                <span
                  class="bi bi-plus-circle"
                  role="button"
                  style="font-size: 20px"
                  (click)="addAllUsers()"
                ></span>

              </mat-header-cell>
              <mat-cell *matCellDef="let user" style="flex: 0.3">
                <span
                  class="bi bi-plus-circle"
                  role="button"
                  style="font-size: 20px"
                  (click)="addUser(user)"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name" [sticky]="true">
              <mat-header-cell
                *matHeaderCellDef
                class="text-primary"
                style="font-size: 12px !important; justify-content: center"
              >
                {{ 'Name' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 1.7">
                <span style="font-size: 12px"> {{ user.name }} {{ user.surname }} {{ user.surname2 }} </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="employeeNumber" [sticky]="true">
              <mat-header-cell
                *matHeaderCellDef
                class="text-primary"
                style="font-size: 12px !important; justify-content: center"
              >
                {{ 'Employee number' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user">
                <span style="font-size: 12px">
                  {{ user.number }}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email" [sticky]="true">
              <mat-header-cell
                *matHeaderCellDef
                class="text-primary"
                style="flex: 1.2; font-size: 12px !important; justify-content: center"
              >
                {{ 'Email' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 1.1">
                <span style="font-size: 12px; width: 100%"> {{ user.login }}@nttdata.com </span>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
          </table>
          <div
            *ngIf="loadingSearch"
            style="display: flex; align-items: center; justify-content: space-around; margin-left: -63px"
          >
            <app-loading></app-loading>
          </div>
          <div style="text-align-last: center">
            <span *ngIf="noResults">{{ 'There are no results' | translate }} </span>
          </div>
        </div>
      </div>
      <span style="margin-top: 10px; font-size: 12px">{{ 'Users to add' | translate }}:</span>
      <div style="text-align: -webkit-center; background-color: #8f9bb312; margin-top: 5px">
        <div class="scroll" style="width: 95%">
          <table mat-table [dataSource]="dataSourceAdded" style="width: 100%">
            <caption></caption>
            <th id="reservationUserFormTable2"></th>
            <ng-container matColumnDef="action" [sticky]="true">
              <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.3">
                <span
                  class="bi bi-x-circle"
                  style="font-size: 20px"
                  role="button"
                  (click)="deleteAllUsers()"
                ></span>
              </mat-header-cell>
              <mat-cell *matCellDef="let user" style="flex: 0.3">
                <span
                  class="bi bi-x-circle"
                  style="font-size: 20px"
                  role="button"
                  (click)="deleteUser(user)"
                ></span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="name" [sticky]="true">
              <mat-header-cell
                *matHeaderCellDef
                class="text-primary"
                style="font-size: 12px !important; justify-content: center"
              >
                {{ 'Name' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 1.7">
                <span style="font-size: 12px"> {{ user.name }} {{ user.surname }} {{ user.surname2 }} </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="employeeNumber" [sticky]="true">
              <mat-header-cell
                *matHeaderCellDef
                class="text-primary"
                style="font-size: 12px !important; justify-content: center"
              >
                {{ 'Employee number' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 0.8">
                <span style="font-size: 12px">
                  {{ user.number }}
                </span>
              </mat-cell>
            </ng-container>
            <ng-container matColumnDef="email" [sticky]="true">
              <mat-header-cell
                *matHeaderCellDef
                class="text-primary"
                style="flex: 1.2; font-size: 12px !important; justify-content: center"
              >
                {{ 'Email' | translate }}</mat-header-cell
              >
              <mat-cell *matCellDef="let user" style="flex: 1.1">
                <span style="font-size: 12px; width: 100%"> {{ user.login }}@nttdata.com </span>
              </mat-cell>
            </ng-container>
            <mat-header-row *matHeaderRowDef="displayedAddedColumnsTable"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedAddedColumnsTable"></mat-row>
          </table>
          <div style="text-align-last: center">
            <span *ngIf="noUsersToAdd" class="invalid-feedback-custom"
              >{{ 'Must add at least an user' | translate }}
            </span>
          </div>
        </div>
      </div>
      <form class="row" style="padding: 1rem 1.5rem" [formGroup]="reservationUserFormGroup" (ngSubmit)="createUsers()">
        <mat-form-field style="width: 100%">
          <mat-label>{{ 'Role' | translate }}</mat-label>
          <mat-select formControlName="role" required>
            <mat-option [value]="role" *ngFor="let role of reservationRoles">{{ role | translate }} </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="width: 100%">
          <mat-label>{{ 'Office' | translate }}</mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="office"
            type="text"
            class="p-1"
            maxlength="50"
            spellcheck="false"
            required
          />
          <mat-hint *ngIf="reservationUserFormGroup.get('office')?.value.length" align="end"
            >{{ reservationUserFormGroup.get('office').value.length }}/50</mat-hint
          >
          <mat-error *ngIf="f.office.errors?.required">{{ 'Office is required' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field style="width: 100%">
          <mat-label>{{ 'Area' | translate }}</mat-label>
          <input
            matInput
            autocomplete="off"
            formControlName="area"
            type="text"
            class="p-1"
            maxlength="50"
            spellcheck="false"
            required
          />
          <mat-hint *ngIf="reservationUserFormGroup.get('area')?.value.length" align="end"
            >{{ reservationUserFormGroup.get('area').value.length }}/50</mat-hint
          >
          <mat-error *ngIf="f.area.errors?.required">{{ 'Area is required' | translate }}</mat-error>
        </mat-form-field>
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="close(false)" type="button">
            {{ 'Cancel' | translate }}
          </button>
          <button mat-button class="modalButton bg-primary" type="submit">
            {{ 'Save' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
