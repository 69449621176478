<div class="header-container">
  <div *ngIf="responsive" class="me-1 pe-1">
    <button
      title="{{ 'Menu' | translate }}"
      #btn
      type="button"
      pButton
      icon="pi pi-bars"
      (click)="menu.toggle($event)"
    ></button>
    <p-slideMenu
      #menu
      [model]="webMenuResponsive"
      [popup]="true"
      [viewportHeight]="280"
      [style]="{ 'min-width': '185px' }"
      backLabel="{{ 'Back' | translate }}"
    ></p-slideMenu>
  </div>
  <a routerLink="/">
    <img
      src="./assets/images/Habitat_Logo_reducido_200px.png"
      alt="habität"
      [ngStyle]="{ width: responsive ? '175px' : 'auto' }"
    />
  </a>
  <div class="d-flex justify-content-end align-items-center">
    <div *ngIf="!responsive">
      <button
        mat-button
        color="primary"
        title="Toggle Sidebar"
        type="button"
        class="p-0"
        (click)="leftPanelClick()"
      >
        <span class="bi {{ imgWindow }}"></span>
      </button>
    </div>
    <div class="d-flex align-items-center">
      <div *ngFor="let item of webMenu"
        class="nav-item"
        [hidden]="item.hidden"
        (mouseenter)="item.open = true"
        (mouseleave)="item.open = false"
      >
        <a
          *ngIf="!responsive"
          [routerLink]="item.link ? [item.link] : null"
          class="titleN4 nav-link"
          [role]="hasSubMenu(item) ? 'button': undefined"
          [ngClass]="{'active': item.active, 'dropdown-toggle': hasSubMenu(item)}"
        >
          <div>{{ item.title }}</div>
        </a>
        <ul *ngIf="item.configMenuItems.length > 0" class="dropdown-menu" [ngClass]="item.open ? 'd-block' : 'd-none'">
          <li *ngFor="let subitem of item.configMenuItems">
            <a class="dropdown-item"
              [hidden]="subitem.hidden"
              [routerLink]="subitem.link"
              role="button"
              [ngClass]="{'active': matchesRoute(subitem.link)}"
            >
              {{subitem.title}}
            </a>
          </li>
        </ul>
      </div>
      <a class="user-action pe-0" routerLink="/profile">
        <div class="d-flex">
          <div 
            class="profile-image"
            [style.backgroundImage]="'url(\'' + (userImage ?? '') + '\')'"
          >{{ userImage ? '' : userInitials }}</div>

          <div class="d-none d-sm-flex flex-column justify-content-center">
            <div>{{nameUser}}</div>
            <small>{{ grouping }}</small>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
