import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScreenSizeService {
  private screenSizeHeight$: Subject<number> = new Subject();
  private screenSizeWidth$: Subject<number> = new Subject();
  heightObservable$ = this.screenSizeHeight$.asObservable();
  widthObservable$ = this.screenSizeWidth$.asObservable();

  setHeight(height: number): void {
    this.screenSizeHeight$.next(height);
  }

  getHeight(): Observable<number> {
    return this.heightObservable$;
  }

  setWidth(width: number): void {
    this.screenSizeWidth$.next(width);
  }

  getWidth(): Observable<number> {
    return this.widthObservable$;
  }
}
