export class ReservationUser {
  id: number;
  name: string;
  surname?: string;
  nameComplete?: string;
  email: string;
  office: string;
  area: string;
  pushId: string;
  role: string;
  employeeNumber?: string;

  constructor(msg) {
    this.id = msg.id;
    this.name = msg.name;
    this.surname = msg.surname;
    this.nameComplete = msg.surname ? msg.name?.concat(' ').concat(msg.surname) : msg.name;
    this.email = msg.email;
    this.office = msg.office;
    this.area = msg.area;
    this.pushId = msg.pushId;
    this.role = msg.role;
    this.employeeNumber = msg.employeeNumber;
  }
}
