import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['../legal-terms.scss'],
})
export class PrivacyPolicyComponent {
  lang: string;
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.lang = this.router.url.split('/')[1];
  }
}
