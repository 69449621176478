import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { Constants } from 'src/app/models/Constants.const';
import { Space } from 'src/app/models/esite/Space.class';
import { SpaceType } from 'src/app/models/esite/SpaceType.class';
import { Building } from 'src/app/models/habitat/Building.class';
import { City } from 'src/app/models/habitat/City.class';
import { Floor } from 'src/app/models/habitat/Floor.class';
import { FilterOptionType } from 'src/app/models/historic/FilterOptionType.enum';
import { ReservationRolesEnum } from 'src/app/models/reservation/ReservationRoles.enum';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';
import { ReservationUser } from 'src/app/models/reservation/ReservationUser.class';
import { ReservationUsersFiltered } from 'src/app/models/reservation/ReservationUsersFiltered.class';
import { EsiteService } from 'src/app/services/backend/esite.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { ReservationService } from 'src/app/services/backend/reservation.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { SpaceConfigurationService } from 'src/app/services/pages/space-configuration.service';

import { SpaceFormComponent } from '../space-form/space-form.component';
import { SpaceNewSpaceComponent } from '../space-new-space/space-new-space.component';
import { Permission } from './../../../../../models/auth/Role.class';
import { UserPermissions } from './../../../../../models/auth/UserPermissions.enum';
import { Resource } from './../../../../../models/esite/Resource.class';
import { ResourcesService } from '../../../../../services/backend/resources.service';
import { User } from 'src/app/models/auth/User.class';
import { uniqueBy } from 'src/app/shared/utils';
import { SpaceUseType } from 'src/app/models/esite/SpaceUseType.enum';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'app-space-configuration-space',
  templateUrl: './space-configuration-space.component.html',
  styleUrls: ['./space-configuration-space.component.scss'],
})
export class SpaceConfigurationSpaceComponent implements OnInit, OnDestroy {
  private readonly WRITE = 'WRITE';

  userLogged: User;
  private permissions: Permission[] = [];
  loading = true;
  users: ReservationUser[] = [];
  initalSpaceInfoState = true;
  spaceDataExpanded = true;
  building: Building = new Building('');
  subcriptions: Subscription[] = [];
  dialogRef: MatDialogRef<any>;
  spaceTypes: SpaceType[] = [];
  dataSource = new MatTableDataSource([]);
  floorSpaces: Space[] = [];
  floorReservations = [];
  noFloorSpaces = true;
  sitesColumns = ['code', 'name', 'description', 'resources', 'useType', 'type', 'capacity', 'actions'];
  reservationColumns = ['code', 'type', 'reservable', 'permission', 'approval', 'approvalUser', 'rGroup', 'actions'];
  displayedColumnsTable = this.sitesColumns;
  // , 'location',
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  hasEsiteModule = false;
  hasConteoModule = false;
  hasReservationsModule = false;
  selectedOption = FilterOptionType.spaces;
  optionSpaces = FilterOptionType.spaces;
  optionReservation = FilterOptionType.reservation;
  isReservationTable = false;
  disabledDelete = false;
  canConfirmBuilding = false;
  canWriteEsiteSpaces = false;
  canWriteReservationsSpaces = false;
  canReadResources = false;
  resources: Resource[] = [];
  useTypes = Object.entries(SpaceUseType).map(([type]) => type);
  tableFilterFormGroup: UntypedFormGroup;
  typesForSelect;

  filterValues = {
    typeName: '',
    useType: '',
    searchText: '',
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private resourceService: ResourcesService,
    private habitatService: HabitatService,
    private globalService: GlobalService,
    private spaceConfigurationService: SpaceConfigurationService,
    private dialog: MatDialog,
    private reservationService: ReservationService,
    private esiteService: EsiteService,
    private translateService: TranslateService
  ) {
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.hasReservationsModule = this.globalService.getReservationModule();

    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
    this.checkPermissionsUser(this.permissions);

    if (this.hasEsiteModule && this.canReadResources) {
      this.subcriptions.push(
        this.resourceService.getResources({ full: true }).subscribe((resources) => {
          this.resources = resources;
        })
      );
    }

    this.dataSource.filterPredicate = this.createFilter();
  }

  ngOnInit(): void {
    this.initForm();
    this.subcriptions.push(
      this.spaceConfigurationService.buildingChanged.subscribe((building: Building) => {
        if (!building) return;

        this.checkPermissionsUser(
          this.globalService.getPermissionsBuilding(building.id, this.userLogged?.permissionBuilding, this.permissions)
        );
        this.loading = true;
        this.floorSpaces = [];
        this.floorReservations = [];
        this.dataSource.data = [];
        if (building.floors.length == 0) {
          this.noFloorSpaces = true;
          this.loading = false;
        }
        this.building = building;

        const buildingCity = this.building.city.id ? this.building.city.id : this.building.city;

        if (
          (this.building.city instanceof Object && this.building.city.id) ||
          this.building.city instanceof Number ||
          this.building.city instanceof String
        ) {
          this.subcriptions.push(
            this.habitatService.getCity(buildingCity).subscribe((city) => {
              if (this.building) {
                this.building.city = new City(city);
              }
            })
          );
        }

        if (this.hasEsiteModule) {
          let floorCount = 0;
          this.esiteService.getSpaceTypes().subscribe((types) => {
            this.spaceTypes = types;
            this.typesForSelect = types;
            building.floors.forEach((floor) => {
              this.subcriptions.push(
                this.esiteService.getSpacesByFloor(floor.id, { resources: true }).subscribe((spaces) => {
                  this.floorSpaces = this.floorSpaces.concat(spaces.map((x) => new Space(x)));
                  this.floorSpaces.forEach((space) => {
                    const foundType = types.find((item) => item.id === space.type);
                    space.typeName = foundType.name;
                    space.useType = foundType.useType;

                    space.resources = space.resources?.map((res) => this.resources.find((x) => x.id === res.id)) || [];
                  });
                  if (this.hasReservationsModule) {
                    this.reservationService
                      .getReservableSpaces(floor.id)
                      .subscribe((reservations: ReservationSpace[]) => {
                        floorCount += 1;

                        this.floorReservations = this.floorReservations.concat(
                          reservations.map((reservation) => {
                            reservation.typeName = types.find((item) => item.id == reservation.type)?.name;
                            return reservation;
                          })
                        );
                        if (floorCount == building.floors.length) {
                          this.noFloorSpaces = this.floorSpaces?.length <= 0;
                          this.loading = !this.noFloorSpaces;
                          this.checkTable();
                        }
                      });
                  } else {
                    floorCount += 1;

                    if (floorCount == building.floors.length) {
                      this.noFloorSpaces = this.floorSpaces?.length <= 0;
                      this.loading = !this.noFloorSpaces;
                      this.checkTable();
                    }
                  }
                })
              );
            });
          });
        }
      })
    );

    this.subcriptions.push(
      this.spaceConfigurationService.editedSpace.subscribe((space: Space) => {
        if (space) {
          const index = this.floorSpaces.findIndex((item) => item.code == space.code);
          if (index > -1) {
            this.floorSpaces[index] = space;
            this.checkTable();
          } else {
            this.floorSpaces.push(space);
            this.checkTable();
          }
        }
      })
    );

    this.subcriptions.push(
      this.spaceConfigurationService.deletedSpace.subscribe((space: Space) => {
        if (space) {
          const id = Number(space.code);
          const index = this.floorSpaces.findIndex((item) => item.id == id);
          if (index > -1) {
            this.floorSpaces.splice(index, 1);
            this.checkTable();
          }
        }
      })
    );
  }

  initForm(): void {
    this.tableFilterFormGroup = this.formBuilder.group({
      selectedOption: [this.selectedOption, []],
      type: ['', []],
      useType: ['', []],
    });
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  checkPermissionsUser(permissions: Permission[]): void {
    this.canConfirmBuilding =
      permissions?.some((item) => item.name === UserPermissions.Buildings && item.type === this.WRITE) ||
      permissions?.some((item) => item.name === UserPermissions.HiddenBuildings && item.type === this.WRITE);

    this.canWriteEsiteSpaces = permissions?.some(
      (item) => item.name === UserPermissions.Spaces && item.type === this.WRITE
    );

    this.canReadResources = permissions?.some((item) => item.name === UserPermissions.Resources);

    this.canWriteReservationsSpaces = permissions?.some(
      (item) => item.name === UserPermissions.ReservableSpaces && item.type === this.WRITE
    );
  }

  buildReservationTable(): void {
    const tableData = [];
    let filledUsers = [];
    let countSpaces = 0;
    if (this.hasReservationsModule) {
      this.subcriptions.push(
        this.reservationService
          .getUsersFiltered(1, Constants.maxInt, null, ReservationRolesEnum.Approver, null, null)
          .subscribe({
            next: (users: ReservationUsersFiltered) => {
              this.users = users.users;
              this.floorSpaces.forEach((habitatSpace) => {
                countSpaces += 1;
                const isReservableSpace = this.floorReservations.find((item) => item.code == habitatSpace.code);
                if (isReservableSpace) {
                  isReservableSpace.type = habitatSpace.type;
                  isReservableSpace.typeName = habitatSpace.typeName;
                  isReservableSpace.approvers.forEach((user) => {
                    user = new ReservationUser({ employeeNumber: user });
                    user = users.users.find((item) => item.employeeNumber == user.employeeNumber);
                    filledUsers.push(user);
                  });

                  const groupText = isReservableSpace.groupable ? 'Yes' : 'No';
                  const approvalText = isReservableSpace.needsApproval ? 'Yes' : 'No';
                  const tableSpace = {
                    id: isReservableSpace.id,
                    code: isReservableSpace.code,
                    reservable: 'Yes',
                    typeName: isReservableSpace.typeName,
                    rGroup: groupText,
                    permission: isReservableSpace.role,
                    approval: approvalText,
                    approvalUser: filledUsers.map((x) => x?.name).join(', '),
                    usersData: isReservableSpace,
                    floor: isReservableSpace.floor,
                  };
                  tableData.push(tableSpace);
                  filledUsers = [];
                } else {
                  const tableSpace = {
                    id: habitatSpace.id,
                    code: habitatSpace.code,
                    reservable: 'No',
                    typeName: habitatSpace.typeName,
                    floor: habitatSpace.floor,
                  };
                  tableData.push(tableSpace);
                }
                this.loading = countSpaces !== this.floorSpaces.length;
              });
              this.dataSource.data = tableData;
            },
            error: () => {
              this.loading = false;
            },
          })
      );
    } else {
      this.floorSpaces.forEach((habitatSpace) => {
        countSpaces += 1;
        const tableSpace = {
          id: habitatSpace.id,
          code: habitatSpace.code,
          reservable: 'No',
          type: habitatSpace.typeName,
        };
        tableData.push(tableSpace);
        this.loading = countSpaces !== this.floorSpaces.length;
      });
      this.dataSource.data = tableData;
    }
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  open(template: TemplateRef<any>): void {
    this.dialogRef = this.dialog.open(template, { disableClose: true });
  }

  close(): void {
    this.dialogRef.close();
  }

  confirmSpace(): void {
    this.habitatService.confirmBuilding(this.building.id).subscribe(() => {
      this.globalService.emitUserBuildingsChanged();
      this.building.confirmed = true;
      if (this.dialogRef) this.dialogRef.close();
    });
  }

  collapseAction(state: boolean): void {
    this.spaceDataExpanded = state;
  }

  editEsiteSpace(spaceId: number): void {
    const space = this.floorSpaces.find((s) => s.id === spaceId);
    this.dialogRef = this.dialog.open(SpaceNewSpaceComponent, {
      data: { space: space, canWriteEsiteSpaces: this.canWriteEsiteSpaces },
      disableClose: true,
    });
    this.afterCloseModal(space);
  }

  editReservationSpace(space): void {
    this.dialogRef = this.dialog.open(SpaceFormComponent, {
      data: { space: space, users: this.users, canWriteReservationsSpaces: this.canWriteReservationsSpaces },
      disableClose: true,
    });
    this.afterCloseModal(space);
  }

  afterCloseModal(space): void {
    this.dialogRef.afterClosed().subscribe((result: { isCancelled: boolean; floor?: any; space: any }) => {
      if (result.isCancelled) return;

      this.loading = true;
      this.habitatService.getFloor(space.floor || result.floor).subscribe((floor: Floor) => {
        if (this.selectedOption == FilterOptionType.spaces) {
          const foundIndex = this.floorSpaces.findIndex((el) => el.id === result.space.id);
          const reservableSpaceIndex = this.floorReservations.findIndex((el) => el.id === result.space.id);
          if (foundIndex !== -1) {
            this.esiteService.getSpaceTypes().subscribe((types) => {
              if (reservableSpaceIndex !== -1) {
                this.reservationService
                  .getRservationSpaceInfo(space.id)
                  .subscribe((reservationSpace: ReservationSpace) => {
                    this.floorReservations[reservableSpaceIndex] = reservationSpace;
                  });
              }
              const foundType = types.find((item) => item.id === result.space.type);
              result.space.typeName = foundType.name;
              result.space.resources =
                result.space.resources?.map((id) => this.resources.find((r) => r.id === id)) || [];

              this.floorSpaces[foundIndex] = result.space;

              this.checkTable();
              if (this.spaceConfigurationService.getCurrentFloor().id === space.floor) {
                this.spaceConfigurationService.setCurrentFloor(floor);
              }
            });
          }
        } else {
          if (result.space) {
            this.esiteService.getSpaceTypes().subscribe((types) => {
              const foundType = types.find((item) => item.id === result.space.type);
              result.space.typeName = foundType.name;
              if (result.space instanceof Space) {
                result.space.useType = foundType.useType;
              }

              const foundIndex = this.floorReservations.findIndex((el) => el.id === result.space.id);
              // Space has been modified, maintaining the 'Reservable=Yes' option
              if (foundIndex !== -1) {
                this.floorReservations[foundIndex] = result.space;
                // Space has set from 'NoReservable' to 'Reservable'. Add it to the this.floorReservation Array
              } else {
                this.floorReservations.push(result.space);
              }
              this.checkTable();
              if (this.spaceConfigurationService.getCurrentFloor().id === space.floor) {
                this.spaceConfigurationService.setCurrentFloor(floor);
              }
            });
            // Space has been set as 'NoReservable'. Delete it from this.floorReservation Array
          } else {
            const foundIndex = this.floorReservations.findIndex((el) => el.id === space.id);
            if (foundIndex !== -1) {
              this.floorReservations.splice(foundIndex, 1);
            }
            this.checkTable();
            if (this.spaceConfigurationService.getCurrentFloor().id === space.floor) {
              this.spaceConfigurationService.setCurrentFloor(floor);
            }
          }
        }
      });
    });
  }

  applyFilter(event: Event | any, filterType: string): void {
    if (filterType === 'search') {
      this.filterValues.searchText = (event.target as HTMLInputElement).value;
      this.dataSource.filter = JSON.stringify(this.filterValues);
    } else if (filterType === 'useType') {
      this.filterValues.useType = event.value;
      this.dataSource.filter = JSON.stringify(this.filterValues);
      this.typesForSelect = this.spaceTypes.filter((type) => type.useType === this.filterValues.useType);
    } else {
      this.filterValues.typeName = event.value.name;
      const selectedTypeObj = this.spaceTypes.find((type) => type.name === this.filterValues.typeName);
      this.filterValues.useType = selectedTypeObj.useType;
      this.tableFilterFormGroup.controls['useType'].setValue(selectedTypeObj.useType);
      this.dataSource.filter = JSON.stringify(this.filterValues);
      this.typesForSelect = this.spaceTypes.filter((type) => type.useType === this.filterValues.useType);
    }
  }

  clearField(event: string): void {
    switch (event) {
      case 'useType': {
        this.tableFilterFormGroup.controls['useType'].setValue(null);
        this.tableFilterFormGroup.controls['type'].setValue(null);
        this.dataSource.filter = JSON.stringify({
          typeName: '',
          useType: '',
          searchText: this.filterValues.searchText,
        });
        this.filterValues = {
          typeName: '',
          useType: '',
          searchText: this.filterValues.searchText,
        };
        this.typesForSelect = this.spaceTypes;
        break;
      }
      case 'type': {
        this.tableFilterFormGroup.controls['type'].setValue(null);
        this.dataSource.filter = JSON.stringify({
          typeName: '',
          useType: this.filterValues.useType,
          searchText: this.filterValues.searchText,
        });
        this.filterValues = {
          typeName: '',
          useType: this.filterValues.useType,
          searchText: this.filterValues.searchText,
        };
        break;
      }
      case 'all': {
        this.tableFilterFormGroup.controls['useType'].setValue(null);
        this.tableFilterFormGroup.controls['type'].setValue(null);
        this.dataSource.filter = JSON.stringify({
          typeName: '',
          useType: '',
          searchText: this.filterValues.searchText,
        });
        this.filterValues = { typeName: '', useType: '', searchText: this.filterValues.searchText };
      }
    }
  }

  deleteSpace(tableSpace): void {
    if (this.disabledDelete) return;

    this.disabledDelete = true;
    this.esiteService.getSpace(tableSpace.id).subscribe((space) => {
      const title = this.translateService.instant('confirmDeleteSpace') + ': ' + space.name;
      const modalData = new ConfirmModalData(title, 'infoDeleteSpace');
      const dialogRef = this.dialog.open(ConfirmModalComponent, {
        data: modalData,
        panelClass: 'custom-dialog',
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        this.disabledDelete = false;
        if (result?.confirmed) {
          this.loading = true;
          this.esiteService.deleteSpace(space.id).subscribe(() => {
            let findedIndex = this.floorReservations.findIndex((el) => el.id === space.id);
            if (findedIndex !== -1) {
              this.floorReservations.splice(findedIndex, 1);
            }
            findedIndex = this.floorSpaces.findIndex((el) => el.id === space.id);
            if (findedIndex !== -1) {
              this.floorSpaces.splice(findedIndex, 1);
            }
            this.noFloorSpaces = this.floorSpaces?.length <= 0;
            this.checkTable();
            if (this.spaceConfigurationService.getCurrentFloor().id === space.floor) {
              this.habitatService.getFloor(space.floor).subscribe((floor: Floor) => {
                this.spaceConfigurationService.setCurrentFloor(floor);
              });
            }
          });
        }
      });
    });
  }

  checkTable(): void {
    this.clearField('all');
    if (this.tableFilterFormGroup.controls['selectedOption'].value === this.optionSpaces) {
      this.typesForSelect = this.spaceTypes;
      this.isReservationTable = false;
      this.displayedColumnsTable = this.sitesColumns;
      this.dataSource.paginator = this.paginator;
      this.dataSource.data = uniqueBy(this.floorSpaces, (x) => x.id); // make sure that we remove all duplicate items, if any
      this.dataSource.sort = this.sort;
      this.loading = false;
    } else {
      this.isReservationTable = true;
      this.displayedColumnsTable = this.reservationColumns;
      this.typesForSelect = this.spaceTypes;
      this.buildReservationTable();
    }

    if (!this.canReadResources) {
      const i = this.displayedColumnsTable.indexOf('resources');
      if (i >= 0) {
        this.displayedColumnsTable.splice(i, 1);
      }
    }
  }

  createFilter(): (data: any, filter: string) => boolean {
    const filterFunction = function (data, filter): boolean {
      const searchTerms = JSON.parse(filter);
      if ('useType' in data) {
        if (searchTerms.searchText != '') {
          return (
            data.typeName.indexOf(searchTerms.typeName) !== -1 &&
            data.useType.indexOf(searchTerms.useType) !== -1 &&
            Object.values(data).some((value) => typeof value === 'string' && value.includes(searchTerms.searchText))
          );
        } else {
          return data.typeName.indexOf(searchTerms.typeName) !== -1 && data.useType.indexOf(searchTerms.useType) !== -1;
        }
      } else {
        if (searchTerms.searchText != '') {
          return (
            data.typeName.indexOf(searchTerms.typeName) !== -1 &&
            Object.values(data).some((value) => typeof value === 'string' && value.includes(searchTerms.searchText))
          );
        } else {
          return data.typeName.indexOf(searchTerms.typeName) !== -1;
        }
      }
    };
    return filterFunction;
  }
}
