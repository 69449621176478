import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { SubsectionType } from 'src/app/models/esite/SubsectionType.class';
import { SubsectionTypeRelation } from 'src/app/models/esite/SubsectionTypeRelation.enum';
import { EsiteService } from 'src/app/services/backend/esite.service';

import { Permission } from '../../../../../models/auth/Role.class';
import { UserPermissions } from '../../../../../models/auth/UserPermissions.enum';
import { GlobalService } from '../../../../../services/common/global.service';

@Component({
  selector: 'app-subsection-type-configuration',
  templateUrl: './subsection-type-configuration.component.html',
  styleUrls: ['../subsections-configuration.component.css'],
})
export class SubsectionTypeConfigurationComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('newOrEditType') newOrEditType;
  @Input() subsectionTypes: SubsectionType[] = [];
  @Output() newType: EventEmitter<SubsectionType> = new EventEmitter();
  @Output() modifiedType: EventEmitter<SubsectionType> = new EventEmitter();
  @Output() deletedType: EventEmitter<number> = new EventEmitter();
  typeForm: UntypedFormGroup;
  loading = true;
  dataSource = new MatTableDataSource([]);
  displayedColumnsTable = ['name', 'maxFloors', 'action'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  modalTitle = 'New Type';
  dialogRef: MatDialogRef<any>;
  submitted = false;
  subcriptions: Subscription[] = [];
  itsEditing = false;
  subsectionTypeRelation = SubsectionTypeRelation;
  canWriteSubsectionType = false;
  userLogged: any;
  private permissions: Permission[] = [];

  constructor(
    private esiteService: EsiteService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private globalService: GlobalService
  ) {
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
    this.ckeckPermissionsUser(this.permissions);
  }

  ngOnInit(): void {
    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.subsectionTypes.currentValue) {
      this.dataSource.data = changes.subsectionTypes.currentValue;
      this.loading = false;
    }
  }

  ckeckPermissionsUser(permissions: Permission[]): void {
    this.canWriteSubsectionType =
      permissions?.find((item) => item.name === UserPermissions.SubsectionTypes && item.type == 'WRITE') !== undefined;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  createNewType(): void {
    this.itsEditing = false;
    this.modalTitle = 'New Type';
    this.buildForm();
    this.openModal();
  }

  openModal(): void {
    this.dialogRef = this.dialog.open(this.newOrEditType, { panelClass: 'custom-dialog', disableClose: true });
  }

  buildForm(): void {
    this.typeForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern(/^[^,]+$/)]],
      maxFloors: ['', [Validators.required]],
    });
  }

  cancel(): void {
    this.dialogRef.close();
    this.submitted = false;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.typeForm.invalid) {
      return;
    }

    if (
      this.subsectionTypes.find((item) => item.name === this.typeForm.value.name && item.id !== this.typeForm.value.id)
    ) {
      setTimeout(() => {
        this.typeForm.get('name').markAsDirty();
        this.typeForm.get('name').markAsTouched();
        this.typeForm.get('name').setErrors({ duplicate: true });
      }, 1);
      return;
    }
    if (!this.itsEditing) {
      this.subcriptions.push(
        this.esiteService
          .insertSubsectionType(this.typeForm.value, AuditFunctionalities.CreateSubsectionType)
          .subscribe({
            next: (res) => {
              const subsectionType = {
                id: res.id,
                name: res.name,
                maxFloors: res.maxFloors || SubsectionTypeRelation.NO_PHYSICAL_LOCATION,
              };
              this.newType.emit(subsectionType);
              this.cancel();
            },
            error: (err: HttpErrorResponse) => {
              this.setFormErrors(err);
            },
          })
      );
    } else {
      this.subcriptions.push(
        this.esiteService.modifySubsectionType(this.typeForm.value).subscribe({
          next: () => {
            this.modifiedType.emit(this.typeForm.value);
            this.cancel();
          },
          error: (err: HttpErrorResponse) => {
            this.setFormErrors(err);
          },
        })
      );
    }
  }

  setFormErrors(err: HttpErrorResponse): void {
    if (err.status === 409) {
      /**
       * Timeout is necessary as errors are wiped out
       * (see https://github.com/angular/angular/issues/19170)
       */
      setTimeout(() => {
        this.typeForm.get('maxFloors').markAsDirty();
        this.typeForm.get('maxFloors').markAsTouched();
        this.typeForm.get('maxFloors').setErrors({ typeConflict: true });
      }, 1);
    } else {
      setTimeout(() => {
        this.typeForm.get('name').markAsDirty();
        this.typeForm.get('name').markAsTouched();
        this.typeForm.get('name').setErrors({ unknown: true });
      }, 1);
    }
  }

  deleteSubsectionType(type: SubsectionType): void {
    const modalData = new ConfirmModalData('DeleteSubsectionType', 'InfoDeleteSubsectionType');
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        this.subcriptions.push(
          this.esiteService.deleteSubsectionType(type.id).subscribe(() => {
            this.deletedType.emit(type.id);
          })
        );
      }
    });
  }

  editSubsectionType(type: SubsectionType): void {
    this.modalTitle = 'Edit Type';
    this.itsEditing = true;

    this.typeForm = this.formBuilder.group({
      id: type.id,
      name: [type.name, [Validators.required, Validators.pattern(/^[^,]+$/)]],
      maxFloors: [type.maxFloors, [Validators.required]],
    });
    this.openModal();
  }
  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.typeForm.controls;
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }
}
