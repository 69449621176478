import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from './../../../environments/environment';
import { AuditFunctionalities } from './../../models/AuditFunctionalities.enum';
import { Group } from './../../models/habitat/Group.class';
import { HttpMethods } from './../../models/HttpMethods.enum';
import { HttpService } from './../common/http.service';
import { AppConstants } from 'src/app/shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class AgrupacionService {
  private url = `${environment.habitat_backend_url}`;
  private protocol = `${environment.backend_protocol}`;

  constructor(private httpService: HttpService, private http: HttpClient) {}

  requestToGroups(type, method, body?, headers?): Observable<any> {
    return this.httpService.httpRequest(this.protocol + this.url, type, method, body, headers);
  }

  getGroupList(final?: boolean): Observable<any> {
    let method = 'v3/groups';

    if (final) {
      method = method + '?final=' + final;
    }

    return this.requestToGroups(HttpMethods.GET, method, {});
  }

  getGroupById(idGroup: string): Observable<any> {
    const method = 'v3/groups/' + idGroup;
    return this.requestToGroups(HttpMethods.GET, method, {});
  }

  createNewGroup(group: Group, functionality: string = AuditFunctionalities.CreateGroup): Observable<any> {
    const method = 'v3/groups';
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.requestToGroups(HttpMethods.POST, method, group, headers);
  }

  modifyGroup(group: Group, id: string, functionality: string = AuditFunctionalities.ModifyGroup): Observable<any> {
    const method = 'v3/groups/' + id;
    let headers = new HttpHeaders();
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.http.patch(this.protocol + this.url + method, group, { headers, responseType: 'text' });
  }

  deleteGroup(idGroup: string, functionality: string = AuditFunctionalities.DeleteGroup): Observable<any> {
    const method = 'v3/groups/' + idGroup;
    let headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    headers = headers.set(AppConstants.AUDIT_FUNCTION_HEADER, functionality);
    return this.http.delete(this.protocol + this.url + method, { headers, responseType: 'text' });
  }
}
