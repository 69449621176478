<div class="modalCard" [ngStyle]="{ width: loading ? '600px' : '800px' }">
  <div class="marginBodyCard" style="padding-right: 4px">
    <div class="table-container">
      <div
        *ngIf="loading"
        style="
          text-align: -webkit-center;
          text-align: -moz-center;
          min-height: 400px;
          display: flex;
          align-items: center;
          justify-content: space-around;
        "
      >
        <app-loading style="width: 100%"></app-loading>
      </div>
      <label *ngIf="!loading" class="titleN3 text-primary modalTitle"> {{ 'Reservations' | translate }}</label>
      <div *ngIf="!loading" style="display: flex; justify-content: space-evenly; flex-wrap: wrap; padding-top: 20px">
        <span *ngFor="let status of reservationStatus">
          <mat-checkbox [(ngModel)]="status.checked" (ngModelChange)="ngOnInit()">
            {{ status.name | translate }}
          </mat-checkbox>
        </span>
      </div>
      <div
        *ngIf="!loading && noData"
        style="
          text-align: -webkit-center;
          text-align: -moz-center;
          min-height: 400px;
          display: flex;
          align-items: center;
          justify-content: space-around;
        "
      >
        {{ 'There are no data for the indicated options' | translate }}
      </div>
      <div
        [ngStyle]="{ display: !loading && !noData ? 'flow-root' : 'none' }"
        style="padding-top: 15px; min-height: 400px"
      >
        <table
          mat-table
          [dataSource]="dataSource"
          aria-label="table of reservations"
          style="width: 100%"
          matSort
          #sortData="matSort"
          matSortActive="date"
          matSortDirection="desc"
        >
          <th id="reservationsTable"></th>
          <!-- CODE column -->
          <ng-container matColumnDef="code">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              scope="col"
              class="pl-0 text-primary"
              style="flex: 2"
            >
              {{ 'Site' | translate }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let reservation" class="pl-0" style="flex: 2">
              <span *ngIf="reservation.code">
                {{ reservation.code }}
              </span>
              <span *ngIf="!reservation.code"> -- </span>
            </mat-cell>
          </ng-container>
          <!-- LOCATION column -->
          <ng-container matColumnDef="location">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              scope="col"
              class="text-primary"
              style="flex: 2"
            >
              {{ 'Location' | translate }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let reservation" style="flex: 2">
              {{ reservation.location }}
            </mat-cell>
          </ng-container>

          <!-- DATE column -->
          <ng-container matColumnDef="date">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              scope="col"
              class="text-primary"
              style="flex: 1"
            >
              {{ 'Date' | translate }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let reservation" style="flex: 1">
              {{ reservation.date | date : 'd/M/yyyy' }}
            </mat-cell>
          </ng-container>
          <!-- START_HOUR column -->
          <ng-container matColumnDef="startHour">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              scope="col"
              class="text-primary"
              style="flex: 1"
            >
              {{ 'Initial time' | translate }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let reservation" style="flex: 1">
              {{ reservation.startHour | date : 'HH:mm' }}
            </mat-cell>
          </ng-container>
          <!-- END_HOUR column -->
          <ng-container matColumnDef="endHour">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              scope="col"
              class="text-primary"
              style="flex: 1"
            >
              {{ 'End time' | translate }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let reservation" style="flex: 1">
              {{ reservation.endHour | date : 'HH:mm' }}
            </mat-cell>
          </ng-container>
          <!-- STATUS column -->
          <ng-container matColumnDef="status">
            <mat-header-cell
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              scope="col"
              class="text-primary"
              style="flex: 0.5"
            >
              {{ 'Status' | translate }}
            </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let reservation" style="flex: 0.5">
              {{ reservation.untranslatedstatus | translate }}
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
          <mat-row *matRowDef="let myRowData; columns: columnsToDisplay"></mat-row>
        </table>
        <mat-paginator style="margin-top: 20px" [pageSize]="10" [hidePageSize]="true"> </mat-paginator>
        <span style="margin-top: -30px; position: absolute">
          {{ '*Shows a maximum of 100 reservations in the last month and next week.' | translate }}
        </span>
      </div>

      <div class="text-center">
        <button mat-button class="modalCancel text-primary" (click)="close()" type="button">
          {{ 'Close' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
