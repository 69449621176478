<collapse-card title="{{ 'Data' | translate }} {{ currentFloor.name }}">
  <div class="row">
    <div *ngIf="hasEsiteModule" class="col-6 col-md">
      <mat-checkbox [(ngModel)]="sitesCheck" (change)="updateLayers()">
        {{ 'Workstations' | translate }}</mat-checkbox
      >
    </div>
    <div *ngIf="hasEsiteModule" class="col-6 col-md">
      <mat-checkbox [(ngModel)]="areasCheck" (change)="updateLayers()">
        {{ 'Areas' | translate }}
      </mat-checkbox>
    </div>
    <div *ngIf="hasConteoModule" class="col-6 col-md">
      <mat-checkbox [(ngModel)]="zonesCheck" (change)="updateLayers()">
        {{ 'Zones' | translate }}
      </mat-checkbox>
    </div>
    <div *ngIf="hasReservationsModule" class="col-6 col-md">
      <mat-checkbox [(ngModel)]="reservationsCheck" (change)="updateLayers()" [disabled]="!sitesCheck">
        {{ 'Reservations' | translate }}
      </mat-checkbox>
    </div>
    <div *ngIf="hasEsiteModule" class="col-12 col-lg-4 py-2 py-lg-0 text-center d-flex">
      <input
        matInput
        [(ngModel)]="spaceToFind"
        #ctrl="ngModel"
        (keyup.enter)="applyFilter()"
        class="input-form"
        placeholder="{{ 'Search' | translate }}"
        maxlength="45"
        type="text"
        [disabled]="!sitesCheck"
      />
      <span
        (click)="sitesCheck ? applyFilter() : ''"
        [title]="'Search' | translate"
        class="bi bi-search flex-grow-1"
        [ngStyle]="{
          color: sitesCheck ? '#0075aa' : '#525252',
          cursor: sitesCheck ? 'pointer' : 'default'
        }"
      ></span>
      <span
        [title]="'Refresh' | translate"
        class="bi bi-arrow-clockwise flex-grow-1"
        (click)="sitesCheck ? clearSearch() : ''"
        [ngStyle]="{
          color: sitesCheck ? '#0075aa' : '#525252',
          cursor: sitesCheck ? 'pointer' : 'default'
        }"
      ></span>
    </div>
  </div>
  <div class="row">
    <app-svg-map
      style="width: 100%; height: 100%"
      [inputData]="svgDataToSend"
      [svgMapProperties]="svgProperties"
      [legendItems]="legendItems"
      [realtimeReservation]="reservationsCheck"
      [floor]="currentFloor"
      [svgPointEsiteInfoModified]="svgPointEsiteInfoModified"
      [svgPointReservationInfoModified]="svgPointReservationInfoModified"
      [svgPolygonModified]="svgPolygonModified"
    >
    </app-svg-map>
  </div>
  <div *ngIf="hasEsiteDevicesModule">
    <mat-divider
      style="margin-bottom: 10px; margin-left: -12px; margin-right: -12px"
      [hidden]="!sitesCheck || !hasEsiteModule"
    ></mat-divider>
    <div class="row justify-content-center" [hidden]="!sitesCheck || !hasEsiteModule">
      <div class="col-12 col-sm-8 col-md-5 col-lg-4 my-2">
        <app-chart-donut [chartIdentifier]="roomsChart" [inputChartData]="roomsChart.data"> </app-chart-donut>
      </div>
      <div class="col-0 col-md-1 col-xl-2"></div>
      <div class="col-12 col-sm-8 col-md-5 col-lg-4 my-2">
        <app-chart-donut
          [chartIdentifier]="sitesChart"
          [inputChartData]="sitesChart.data"
          style="margin-top: 10px"
        ></app-chart-donut>
      </div>
    </div>
    <mat-divider
      style="margin-bottom: 10px; margin-top: 10px"
      [hidden]="!areasCheck || !hasEsiteModule"
    ></mat-divider>
    <div class="row" [hidden]="!areasCheck || !hasEsiteModule">
      <app-chart-bars
        [chartIdentifier]="areasChart"
        [inputChartData]="areasChart.data"
        style="margin-top: 10px; width: 100%"
      ></app-chart-bars>
    </div>
  </div>
  <mat-divider
    style="margin-bottom: 10px; margin-top: 10px"
    [hidden]="!zonesCheck || !hasConteoModule"
  ></mat-divider>
  <div class="row" [hidden]="!zonesCheck || !hasConteoModule">
    <app-chart-bars
      [chartIdentifier]="zonesChart"
      [inputChartData]="zonesChart.data"
      style="margin-top: 10px; width: 100%"
    ></app-chart-bars>
  </div>
</collapse-card>
