import { AfterViewInit, Component, Input, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
//FIXME: Avoid registering of every single element of Chart.js. Change npm-integration to angular integration ng2-charts library
import { Chart, ChartItem, registerables } from 'chart.js';
import { ChartService } from 'src/app/services/common/chart.service';
Chart.register(...registerables);

@Component({
  selector: 'app-chart-bars',
  templateUrl: './chart-bars.component.html',
  styles: [],
})
export class ChartBarsComponent implements AfterViewInit {
  @Input() chartIdentifier = { id: 0, name: '', msgEmpty: '', title: '' };
  @Input() inputChartData;
  loading = true;
  sizeData = 0;
  chart: Chart;
  legendItems = [];
  chartData = { datasets: [], labels: [] };

  constructor(private translateService: TranslateService, private chartService: ChartService) {}

  ngAfterViewInit(): void {
    if (!this.chart) {
      this.chart = this.createChart();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputChartData) {
      const data = changes.inputChartData.currentValue;
      if (data) {
        this.sizeData = data.length;
        const creataDataEstructure = this.chartService.creataDataEstructure(data);
        this.chartData.datasets = creataDataEstructure.datasets;
        this.chartData.labels = creataDataEstructure.labels;
        this.legendItems = creataDataEstructure.legend;
        if (this.chart) {
          this.chart.update();
        } else {
          this.chart = this.createChart();
        }
        this.loading = false;
      } else {
        this.loading = true;
      }
    }
  }

  createChart(): any {
    const ctx = document.getElementById('chartCanvas' + this.chartIdentifier.id) as ChartItem;
    if (ctx) {
      return new Chart(ctx, {
        type: 'bar',
        data: this.chartData,
        options: {
          animation: { duration: 0 },
          hover: { mode: null },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              min: 0,
              max: 100,
              title: {
                display: true,
                text: this.translateService.instant('Occupation%'),
              },
              ticks: {
                stepSize: 50,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                label: (context) => {
                  const occupacy = this.inputChartData[context.dataIndex].value;
                  const label = this.translateService.instant(this.chartIdentifier.title) + ': ' + occupacy;
                  return label.toString();
                },
                afterLabel: (context) => {
                  const occupacyPerc = context.raw;

                  const label = this.translateService.instant('Occupation level') + ': ' + occupacyPerc + ' %';

                  return label;
                },
              },
            },
          },
        },
      });
    } else {
      return null;
    }
  }
}
