import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmModalComponent } from 'src/app/components/shared/confirm-modal/confirm-modal.component';
import { InfoModalComponent } from 'src/app/components/shared/info-modal/info-modal.component';
import { ConfirmModalData } from 'src/app/models/ConfirmModalData.class';
import { InfoModalData } from 'src/app/models/InfoModalData.class';
import { ContinuousReservation } from 'src/app/models/reservation/ContinuousReservation';
import { ReservationSpace } from 'src/app/models/reservation/ReservationSpace.class';

import { GlobalService } from '../../../../../services/common/global.service';
import { NewContinuousReserveComponent } from '../new-continuous-reserve/new-continuous-reserve.component';
import { Building } from './../../../../../models/habitat/Building.class';
import { City } from './../../../../../models/habitat/City.class';
import { Floor } from './../../../../../models/habitat/Floor.class';
import { HabitatService } from './../../../../../services/backend/habitat.service';
import { ReservationService } from './../../../../../services/backend/reservation.service';
import { DateService } from './../../../../../services/common/date.service';

@Component({
  selector: 'app-continuous-reserve-table',
  templateUrl: './continuous-reserve-table.component.html',
  styleUrls: ['./continuous-reserve-table.component.scss'],
})
export class ContinuousReserveTableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() reloadTable = false;
  @Input() canWriteReservations = false;
  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;
  loading = true;
  displayedColumnsTable: string[] = [
    'spaceCode',
    'status',
    'location',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'actions',
  ];
  dataSource = new MatTableDataSource([]);
  subcriptions: Subscription[] = [];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  reservasData = [];
  searchValue: any;
  INITIAL_PAGE = 1;
  INITIAL_PAGESIZE = 10;
  pageSize = this.INITIAL_PAGESIZE;
  pageIndex: number = this.INITIAL_PAGE;
  sortColumn: any;
  sortOrder: any;
  haveNextPage: boolean = null;
  userID: number;
  initPaginatorText: number;
  endPaginatorText: number;
  totalReservasData: number;
  floors: Floor[];
  buildings: Building[];
  cities: City[];
  noData = false;

  constructor(
    private dialog: MatDialog,
    private reservationService: ReservationService,
    private globalService: GlobalService,
    private dateService: DateService,
    private habitatService: HabitatService,
    private dialogRef: MatDialogRef<ContinuousReserveTableComponent>,
    public datepipe: DatePipe,
    public translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.userID = data.user;
  }

  ngOnInit(): void {
    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
    });

    const floors = this.habitatService.getFloors();
    const buildings = this.habitatService.getBuildings();
    const cities = this.habitatService.getCities();

    if (!this.floors || !this.buildings || !this.cities) {
      forkJoin([floors, buildings, cities]).subscribe((data) => {
        this.floors = data[0];
        this.buildings = data[1];
        this.cities = data[2];
        this.loadContinuousReservation();
      });
    } else {
      this.loadContinuousReservation();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.reloadTable.currentValue == true) {
      this.loadContinuousReservation();
    }
  }

  ngOnDestroy(): void {
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  loadContinuousReservation(): void {
    this.reservasData = [];
    this.noData = false;

    this.loading = true;
    this.reservationService
      .getContinuousReservation(this.pageIndex, this.pageSize, this.sortOrder, this.sortColumn, this.userID)
      .subscribe((reservas: any) => {
        if (reservas.continuousReservations.length) {
          reservas.continuousReservations.forEach((reservaItem: any) => {
            // Format Dates
            const startDate = this.buildDateUTC(reservaItem.startDate, reservaItem.startTime);
            const endDate = this.buildDateUTC(reservaItem.endDate, reservaItem.endTime);

            this.reservationService.getRservationSpaceInfo(reservaItem.reservablespacesid).subscribe(
              (space: ReservationSpace) => {
                let location = '-';
                const floor = this.floors.find((item) => item.id == space.floor);
                const building = this.buildings.find((item) => item.id == floor?.building);

                const city = this.cities.find((item) => item.id == Number(building?.city));
                if (city && building && floor) {
                  location = `${this.translateService.instant(city.name)} - ${building.name} - ${floor.name}`;
                }

                const diffStartDays = this.dateService.daysDifference(new Date().toDateString(), startDate);
                const diffEndDays = this.dateService.daysDifference(
                  new Date().toDateString(),
                  endDate?.substring(0, 10)
                );
                let iconCross = false;
                if (diffStartDays > 0) {
                  iconCross = true;
                } else {
                  iconCross = diffEndDays <= 0 ? (endDate ? false : true) : true;
                }
                const iconEdit = diffEndDays <= 0 ? (endDate ? false : true) : true;

                const tableItem = new ContinuousReservation({
                  id: reservaItem.id,
                  spaceCode: space.code,
                  status: reservaItem.status,
                  location: location,
                  reservablespacesid: reservaItem.reservablespacesid,
                  reservationusersid: reservaItem.reservationusersid,
                  startDate: startDate,
                  endDate: endDate,
                  startTime: reservaItem.startTime.substring(0, 5),
                  endTime: reservaItem.endTime.substring(0, 5),
                  iconCross: iconCross,
                  iconEdit: iconEdit,
                });

                this.reservasData.push(tableItem);
                if (this.reservasData.length == reservas.continuousReservations.length) {
                  this.paginateTable(reservas.total);
                }
              },
              () => {
                const tableItem = new ContinuousReservation({
                  id: reservaItem.id,
                  spaceCode: '-',
                  status: reservaItem.status,
                  location: this.translateService.instant('Reservable space not found'),
                  reservablespacesid: reservaItem.reservablespacesid,
                  reservationusersid: reservaItem.reservationusersid,
                  startDate: reservaItem.startDate,
                  endDate: reservaItem.endDate,
                  startTime: reservaItem.startTime.substring(0, 5),
                  endTime: reservaItem.endTime.substring(0, 5),
                  iconCross: false,
                  iconDelete: false,
                  iconEdit: false,
                });
                this.reservasData.push(tableItem);

                if (this.reservasData.length == reservas.continuousReservations.length) {
                  this.paginateTable(reservas.total);
                }
              }
            );
          });
        } else {
          this.updateTable();
        }
      });
  }

  buildDateUTC(itemDate: string, itemTime: string): string {
    if (itemDate) {
      return itemDate + 'T' + itemTime;
    }
    return null;
  }

  formatDate(date: number): string {
    const timezone = new City(this.cities[0]).timezone;
    return this.dateService.setTimeZone(date, timezone);
  }

  paginateTable(reservasTotal: number): void {
    this.haveNextPage = this.pageSize * this.pageIndex < reservasTotal;
    this.totalReservasData = reservasTotal;
    this.initPaginatorText = 1 + this.pageSize * this.pageIndex - this.pageSize;
    this.endPaginatorText = Math.min(this.pageSize * this.pageIndex, this.totalReservasData);
    this.updateTable();
  }

  updateTable(): void {
    this.dataSource.data = this.reservasData;
    if (this.searchValue) {
      this.dataSource.filter = this.searchValue;
    }
    this.loading = false;
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  goNextPage(): void {
    if (this.haveNextPage) {
      this.pageIndex = this.pageIndex + 1;
      this.loadContinuousReservation();
    }
  }

  goPreviousPage(): void {
    this.pageIndex = this.pageIndex - 1;
    this.loadContinuousReservation();
  }

  triggeSearch(): void {
    this.pageIndex = this.INITIAL_PAGE;
    this.loadContinuousReservation();
  }

  editContinuousReservation(continuousReservation): void {
    const dialogRef = this.dialog.open(NewContinuousReserveComponent, {
      data: {
        user: this.userID,
        continuousReservationInfo: continuousReservation,
        canWriteReservations: this.canWriteReservations,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((cancel) => {
      if (!cancel) {
        this.loadContinuousReservation();
      }
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  finishContinuousReservation(reservation): void {
    const modalData = new ConfirmModalData(
      reservation.iconDelete ? 'Delete continuous reservation' : 'Cancel continuous reservation',
      reservation.iconDelete
        ? 'Are you sure that you want to delete this continuous reservation?'
        : 'Are you sure that you want to cancel this continuous reservation?'
    );
    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: modalData,
      panelClass: 'custom-dialog',
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result?.confirmed) {
        this.loading = true;
        const diffDays = this.dateService.daysDifference(new Date().toDateString(), reservation.startDate);

        if (diffDays > 0) {
          this.reservationService.deleteContinuousReservation(reservation.id).subscribe(
            () => {
              const infoModalData = new InfoModalData(
                '',
                reservation.iconDelete
                  ? 'Continuous reservation deleted successfully'
                  : 'Continuous reservation cancel successfully'
              );

              const dialogRef = this.dialog.open(InfoModalComponent, {
                data: infoModalData,
                panelClass: 'custom-dialog',
                disableClose: true,
              });

              dialogRef.afterClosed().subscribe(() => {
                this.loadContinuousReservation();
              });
            },
            (error: HttpErrorResponse) => {
              let infoModalData = new InfoModalData('', '');
              if (error.status == 409) {
                infoModalData = new InfoModalData('Canceling error', error.error.reason);
              } else {
                infoModalData = new InfoModalData(
                  'Canceling error',
                  'An error has occurred, please try again. If it persists contact the administrator.'
                );
              }

              this.dialog.open(InfoModalComponent, {
                data: infoModalData,
                panelClass: 'custom-dialog',
                disableClose: true,
              });
              this.loading = false;
            }
          );
        } else {
          const continuousReservation = new ContinuousReservation({
            id: reservation.id,
            endDate: this.datepipe.transform(new Date(), 'yyyy-MM-dd'),
          });
          this.reservationService.deleteContinuousReservation(continuousReservation.id).subscribe(
            () => {
              const infoModalData = new InfoModalData('', 'Continuous reservation cancel successfully');

              const dialogRef = this.dialog.open(InfoModalComponent, {
                data: infoModalData,
                panelClass: 'custom-dialog',
                disableClose: true,
              });

              dialogRef.afterClosed().subscribe(() => {
                this.loadContinuousReservation();
              });
            },
            (error: HttpErrorResponse) => {
              let infoModalData = new InfoModalData('', '');
              if (error.status == 409) {
                infoModalData = new InfoModalData('Canceling error', error.error.reason);
              } else {
                infoModalData = new InfoModalData(
                  'Canceling error',
                  'An error has occurred, please try again. If it persists contact the administrator.'
                );
              }

              this.dialog.open(InfoModalComponent, {
                data: infoModalData,
                panelClass: 'custom-dialog',
                disableClose: true,
              });

              this.loading = false;
            }
          );
        }
      }
    });
  }
  sortData(event: Sort) {
    this.sortOrder = event.direction === 'desc' ? 'DESC' : 'ASC';
    this.sortColumn = event.active;
    this.pageIndex = this.INITIAL_PAGE;
    this.loadContinuousReservation();
  }
}
