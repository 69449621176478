export enum AuditFunctionalities {
  CreateIndividualSpace = 'CreateIndividualSpace',
  CreateMasiveSpaces = 'CreateMasiveSpaces',
  ModifyIndividualSpace = 'ModifyIndividualSpace',
  ModifyMasiveSpaces = 'ModifyMasiveSpaces',
  DeleteSpace = 'DeleteSpace',
  CreateIndividualReservableSpace = 'CreateIndividualReservableSpace',
  CreateMasiveReservableSpaces = 'CreateMasiveReservableSpaces',
  ModifyIndividualReservableSpace = 'ModifyIndividualReservableSpace',
  ModifyMasiveReservableSpace = 'ModifyMasiveReservableSpace',
  DeleteReservableSpace = 'DeleteReservableSpace',
  DeleteMasiveReservableSpace = 'DeleteMasiveReservableSpace',
  ChangeApproverRole = 'ChangeApproverRole',
  CreateSubsectionType = 'CreateSubsectionType',
  CreateResourceType = 'CreateResourceType',
  CreateIndividualSubsection = 'CreateIndividualSubsection',
  CreateMasiveSubsection = 'CreateMasiveSubsection',
  AddMasiveSubsectionToSpace = 'AddMasiveSubsectionToSpace',
  AddIndividualSubsectionToSpace = 'AddIndividualSubsectionToSpace',
  DeleteMasiveSubsectionFromSpace = 'DeleteMasiveSubsectionFromSpace',
  DeleteIndividualSubsectionFromSpace = 'DeleteIndividualSubsectionFromSpace',
  DeleteSubsectionType = 'DeleteSubsectionType',
  DeleteSubsection = 'DeleteSubsection',
  ModifySubsectionType = 'ModifySubsectionType',
  ModifySubsection = 'ModifySubsection',
  CreateMasiveSpaceType = 'CreateMasiveSpaceType',
  CreateFloor = 'CreateFloor',
  CreateBuilding = 'CreateBuilding',
  ModifyFloor = 'ModifyFloor',
  ConfirmBuilding = 'ConfirmBuilding',
  CreateUser = 'CreateUser',
  CreateGraphicalArea = 'CreateGraphicalArea',
  ModifyGraphicalArea = 'ModifyGraphicalArea',
  DeleteGraphicalArea = 'DeleteGraphicalArea',
  ModifyUser = 'ModifyUser',
  DeleteUser = 'DeleteUser',
  ModifyUserEsitePermits = 'ModifyUserEsitePermits',
  ModifyUserConteoPermits = 'ModifyUserConteoPermits',
  ModifyUserReservationsPermits = 'ModifyUserReservationsPermits',
  CreateReservationUser = 'CreateReservationUser',
  ModifyReservationUser = 'ModifyReservationUser',
  DeleteReservationUser = 'DeleteReservationUser',
  AddMasiveReservableSpaceExchangeEmail = 'AddMasiveReservableSpaceExchangeEmail',
  AddindividualReservableSpaceExchangeEmail = 'AddindividualReservableSpaceExchangeEmail',
  DeleteMasiveReservableSpaceExchangeEmail = 'DeleteMasiveReservableSpaceExchangeEmail',
  DeleteIndividualReservableSpaceExchangeEmail = 'DeleteIndividualReservableSpaceExchangeEmail',
  CreateZone = 'CreateZone',
  ModifyZone = 'ModifyZone',
  DeleteZone = 'DeleteZone',
  CreateOccupancyAlarm = 'CreateOccupancyAlarm',
  DeleteOccupancyAlarm = 'DeleteOccupancyAlarm',
  AddContinuousReservation = 'AddContinuousReservation',
  ModifyContinuousReservation = 'ModifyContinuousReservation',
  DeleteContinuousReservation = 'DeleteContinuousReservation',
  ModifyReservationsModuleConfig = 'ModifyReservationsModuleConfig',
  ModifyIosVersionInternalConfig = 'ModifyIosVersionInternalConfig',
  CreateGroup = 'CreateGroup',
  ModifyGroup = 'ModifyGroup',
  DeleteGroup = 'DeleteGroup',
  DeleteRole = 'DeleteRole',
  ModifyRole = 'ModifyRole',
  ModifyMasiveRoles = 'ModifyMasiveRoles',
  CreateRole = 'CreateRole',
  DeleteLogicUser = 'DeleteLogicUser',
  ModifyUserCore = 'ModifyUserCore',
  ModifyUserExtra = 'ModifyUserExtra',
  ModifyUserRoles = 'ModifyUserRoles',
  CreateUserCore = 'CreateUserCore',
  CreateUserExtra = 'CreateUserExtra',
  CreateIndividualResource = 'CreateIndividualResource',
  CreateMasiveResources = 'CreateMasiveResources',
  ModifyIndividualResource = 'ModifyIndividualResource',
  ModifyMasiveResources = 'ModifyMasiveResources',
  DeleteResource = 'DeleteResource',
  CreateIndividualResourceType = 'CreateIndividualResourceType',
  CreateMasiveResourceTypes = 'CreateMasiveResourceTypes',
  ModifyIndividualResourceType = 'ModifyIndividualResourceType',
  ModifyMasiveResourceTypes = 'ModifyMasiveResourceTypes',
  DeleteResourceType = 'DeleteResourceType',
}
