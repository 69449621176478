import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { APIResource, Resource } from '../../models/esite/Resource.class';
import { HttpService } from '../common/http.service';
import { HttpMethods } from 'src/app/models/HttpMethods.enum';
import { APIMessage } from './esite.service';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { AppConstants } from 'src/app/shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class ResourcesService {
  private readonly API_PATH = 'v2/resources';

  private _resources$ = new BehaviorSubject<Array<Resource>>([]);

  constructor(private httpService: HttpService) {}

  private requestToResources<T>(
    type: HttpMethods,
    method: string,
    body?: unknown,
    headers?: HttpHeaders
  ): Observable<T> {
    const protocol = environment.backend_protocol;
    const url = environment.esite_backend_url;

    return this.httpService.httpRequest<T>(protocol + url, type, method, body, headers);
  }

  get resources$() {
    return this._resources$.asObservable();
  }

  getResource(id: number): Observable<Resource> {
    const method = this.API_PATH + '/' + id;

    return this.requestToResources<APIResource>(HttpMethods.GET, method, {}).pipe(map(Resource.fromBackendFormat));
  }

  getResources(options: { full?: boolean } = {}): Observable<Array<Resource>> {
    const { full = false } = options;
    const url = full ? this.API_PATH + '?format=full' : this.API_PATH;
    return this.requestToResources<Array<APIResource>>(HttpMethods.GET, url).pipe(
      map((res) => res.map(Resource.fromBackendFormat)),
      tap((res) => {
        this._resources$.next(res);
      })
    );
  }

  // this method updates a resource if it already exists, else it creates a new one
  saveResource(resource: Resource): Observable<Resource> {
    const method = this.API_PATH;
    const headers = new HttpHeaders({
      [AppConstants.AUDIT_FUNCTION_HEADER]: AuditFunctionalities.ModifyIndividualResource,
    });
    return this.requestToResources<APIMessage>(HttpMethods.PUT, method, resource.toBackendFormat(), headers).pipe(
      map((msg) => {
        const res = new Resource(resource);
        if (typeof msg.reason === 'number') {
          res.id = msg.reason;
        }
        return res;
      }),
      tap((res) => {
        const all = this._resources$.getValue().slice();
        const index = all.findIndex((x) => x.id === res.id);
        if (index < 0) {
          all.push(res);
        } else {
          all[index] = res;
        }
        this._resources$.next(all);
        return res;
      })
    );
  }

  deleteResource(id: number): Observable<APIMessage> {
    const method = this.API_PATH + '/' + id;
    const headers = new HttpHeaders({
      [AppConstants.AUDIT_FUNCTION_HEADER]: AuditFunctionalities.DeleteResource,
    });
    return this.requestToResources<APIMessage>(HttpMethods.DELETE, method, {}, headers).pipe(
      tap(() => {
        this._resources$.next(this._resources$.getValue().filter((res) => res.id !== id));
      })
    );
  }
}
