<collapse-card title="{{ 'Data' | translate }} {{ currentArea.name }}">
  <div class="row">
    <app-svg-map
      style="width: 100%"
      [inputData]="svgDataToSend"
      [svgMapProperties]="svgProperties"
      [legendItems]="legendItems"
      [realtimeReservation]="hasReservationsModule"
      [area]="currentArea"
      [svgPointEsiteInfoModified]="svgPointEsiteInfoModified"
      [svgPointReservationInfoModified]="svgPointReservationInfoModified"
    ></app-svg-map>
  </div>
  <div *ngIf="hasEsiteDevicesModule">
    <mat-divider style="margin-bottom: 10px"></mat-divider>
    <div class="row justify-content-center">
      <div class="col-12 col-sm-8 col-md-5 col-lg-4 my-2">
        <app-chart-donut [chartIdentifier]="roomsChart" [inputChartData]="roomsChartData"></app-chart-donut>
      </div>
      <div class="col-0 col-md-1 col-xl-2"></div>
      <div class="col-12 col-sm-8 col-md-5 col-lg-4 my-2">
        <app-chart-donut
          [chartIdentifier]="sitesChart"
          [inputChartData]="sitesChartData"
          style="margin-top: 10px"
        ></app-chart-donut>
      </div>
    </div>
  </div>
</collapse-card>
