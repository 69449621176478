import { SpaceUseType, spaceUseTypeFromExcelValue } from './SpaceUseType.enum';

export class SpaceType {
  id: number;
  code: string;
  name: string;
  useType: SpaceUseType;

  constructor(msg) {
    this.id = msg.id ? msg.id : null;
    this.code = msg.code ? msg.code : '';
    this.name = msg.name ? msg.name : '';
    this.useType = msg.name ? SpaceUseType[msg.useType] || SpaceUseType[msg.spaceUseType] : null;
  }

  setExcelData(parsedRow) {
    this.code = parsedRow.Código || parsedRow.code;
    this.name = parsedRow.Nombre || parsedRow.name;
    this.useType = spaceUseTypeFromExcelValue(parsedRow['Tipo de Uso'] || parsedRow.useType);
  }

  toBackenFormat(): any {
    const backendObject = {
      code: this.code,
      name: this.name,
      sensorNumber: 1,
      useType: this.useType,
    };
    return backendObject;
  }
}
