export enum AvailabilityType {
  Available = 'Available',
  Absent = 'Absent',
  NotAvailable = 'NotAvailable',
  NoSensor = 'NoSensor',
  AvailablePna = 'AvailablePna',
  AbsentPna = 'AbsentPna',
}

function getAvalabilityType(availabilityType: AvailabilityType): AvailabilityType {
  let receivedType = AvailabilityType[availabilityType];
  if (receivedType === AvailabilityType.AvailablePna || receivedType === AvailabilityType.AbsentPna) {
    receivedType = AvailabilityType.NotAvailable;
  }
  return receivedType;
}

export { getAvalabilityType as getRealAvalabilityType };
