import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, map, tap } from 'rxjs';

import { environment } from '../../../environments/environment';
import { HttpService } from '../common/http.service';
import { APIResourceType, ResourceType } from '../../models/esite/ResourceType.class';
import { HttpMethods } from 'src/app/models/HttpMethods.enum';
import { APIMessage } from './esite.service';
import { AuditFunctionalities } from 'src/app/models/AuditFunctionalities.enum';
import { AppConstants } from 'src/app/shared/AppConstants';

@Injectable({
  providedIn: 'root',
})
export class ResourceTypesService {
  private METHOD_API_RESOURCE = 'v2/resources/types';

  private _resourceType$ = new BehaviorSubject<Array<ResourceType>>([]);

  constructor(private httpService: HttpService) {}

  private requestToResources<T>(
    type: HttpMethods,
    method: string,
    body?: unknown,
    headers?: HttpHeaders
  ): Observable<T> {
    const protocol = environment.backend_protocol;
    const url = environment.esite_backend_url;

    return this.httpService.httpRequest<T>(protocol + url, type, method, body, headers);
  }

  get resourceTypes$() {
    return this._resourceType$.asObservable();
  }

  getResourceType(id: number): Observable<ResourceType> {
    const method = this.METHOD_API_RESOURCE + '/' + id;

    return this.requestToResources<APIResourceType>(HttpMethods.GET, method, {}).pipe(
      map(ResourceType.fromBackendFormat)
    );
  }

  fetchResourceTypes(): Observable<ResourceType[]> {
    const url = this.METHOD_API_RESOURCE;

    return this.requestToResources<Array<APIResourceType>>(HttpMethods.GET, url, {}).pipe(
      map((data) => data.map((it) => new ResourceType(it))),
      tap((types) => {
        this._resourceType$.next(types);
      })
    );
  }

  saveResourceType(resource: ResourceType): Observable<ResourceType> {
    const method = this.METHOD_API_RESOURCE;
    const headers = new HttpHeaders({
      [AppConstants.AUDIT_FUNCTION_HEADER]: AuditFunctionalities.ModifyIndividualResourceType,
    });
    return this.requestToResources<APIMessage>(HttpMethods.PUT, method, resource.toBackendFormat(), headers).pipe(
      map((res) => {
        const t = new ResourceType({ ...resource });
        if (typeof res.reason === 'number') {
          t.id = res.reason;
        }
        return t;
      }),
      tap((res) => {
        const types = this._resourceType$.getValue().slice();
        const i = types.findIndex((it) => it.id === resource.id);
        if (i < 0) {
          types.push(res);
        } else {
          types[i] = res;
        }
        this._resourceType$.next(types);
      })
    );
  }

  deleteResourceType(id: number): Observable<APIMessage> {
    const method = this.METHOD_API_RESOURCE + '/' + id;
    const headers = new HttpHeaders({
      [AppConstants.AUDIT_FUNCTION_HEADER]: AuditFunctionalities.DeleteResourceType,
    });
    return this.requestToResources<APIMessage>(HttpMethods.DELETE, method, {}, headers).pipe(
      tap(() => {
        this._resourceType$.next(this._resourceType$.getValue().filter((it) => it.id !== id));
      })
    );
  }
}
