import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Zone } from 'src/app/models/conteo/Zone.class';
import { FilterOptionType } from 'src/app/models/historic/FilterOptionType.enum';
import { Languages } from 'src/app/models/Languages.enum';
import { OccupationNotification } from 'src/app/models/notifications/OccupationNotification.class';
import { OccupationNotificationConfig } from 'src/app/models/notifications/OccupationNotificationConfig.class';
import { ProximityNotificationConfig } from 'src/app/models/notifications/ProximityNotificationConfig.class';
import { ConteoService } from 'src/app/services/backend/conteo.service';
import { EsiteService } from 'src/app/services/backend/esite.service';
import { HabitatService } from 'src/app/services/backend/habitat.service';
import { DateService } from 'src/app/services/common/date.service';
import { GlobalService } from 'src/app/services/common/global.service';
import { NotificationsService } from 'src/app/services/pages/notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  responsive = false;

  dataSource = new MatTableDataSource([]);
  displayedColumnsTable: string[] = ['name', 'subject', 'date'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('sortData', { static: false }) set content(sortData: MatSort) {
    this.dataSource.sort = sortData;
  }
  hasEsiteModule = false;
  hasConteoModule = false;
  hasEsiteDevicesModule = false;
  subcriptions: Subscription[] = [];
  buildingSubscription: Subscription;
  INITIAL_PAGE = 0;
  INITIAL_PAGESIZE = 10;
  length = 0;
  buildings;
  pageSize = this.INITIAL_PAGESIZE;
  pageIndex: number = this.INITIAL_PAGE;
  selected = FilterOptionType.spaces;
  optionSpaces = FilterOptionType.spaces;
  optionZones = FilterOptionType.zones;
  configured = { proximity: null, occupancy: [] };
  startConteoAlarms; // Buscamos la menor fecha de configuración, para pasarla como entrada a la llamada del backemd.
  susbcriptions: Subscription[] = [];
  esiteAlarms = [];
  conteoAlarms = [];
  msgEmpty = '';
  nodata = false;
  loading = true;
  timeZonesBySpace: Map<number, string> = new Map(); //Map to store timezone and avoiding asking again to server.
  timeZonesByZone: Map<number, string> = new Map(); //Map to store timezone and avoiding asking again to server.
  loadedNameSpaces: Map<number, string> = new Map(); //Map to store space name and avoiding asking again to server.
  loadedZone: Zone[] = [];
  totalAlarms: number;
  loadedAlarms;

  constructor(
    private notificationsService: NotificationsService,
    private dateService: DateService,
    private translateService: TranslateService,
    private globalService: GlobalService,
    private conteoService: ConteoService,
    private habitatService: HabitatService,
    private esiteService: EsiteService
  ) {
    const now = this.dateService.getUTCNow();
    this.startConteoAlarms = this.dateService.getUTCDate(now);
    this.hasEsiteModule = this.globalService.getEsiteModule();
    this.hasConteoModule = this.globalService.getConteoModule();
    this.hasEsiteDevicesModule = this.globalService.getEsiteDevice();
  }

  ngOnInit(): void {
    this.globalService.isResponsive$.pipe(takeUntil(this.destroy$)).subscribe((isResponsive: boolean) => {
      this.responsive = isResponsive;
    });

    if (!this.hasEsiteDevicesModule && this.hasConteoModule) {
      this.selected = FilterOptionType.zones;
    }
    this.buildingSubscription = this.globalService.userBuildings$.subscribe((buildings) => {
      this.buildings = buildings;
      if (buildings.length) {
        let proximityConfig = null;
        let occupancyConfig = null;

        if (this.hasEsiteDevicesModule) {
          proximityConfig = this.esiteService.getProximityAlarm();
        }
        if (this.hasConteoModule) {
          occupancyConfig = this.conteoService.getConfigAlarms();
        }
        if (this.hasEsiteDevicesModule && this.hasConteoModule) {
          this.susbcriptions.push(
            forkJoin([proximityConfig, occupancyConfig]).subscribe((data) => {
              if (data[0][0]) {
                this.configured.proximity = new ProximityNotificationConfig(data[0][0]);
              }
              if (data[0][1]) {
                // TODO: check if this is correct
                (data[0][1] as any[]).forEach((config) => {
                  const configAlarm = new OccupationNotificationConfig(config);
                  this.setStartConteoAlarms(configAlarm.creationDate);
                  this.configured.occupancy.push(configAlarm);
                });
              }
              //When building and configuration data is loaded, Load the Table
              this.loadTable();
            })
          );
        }
        if (this.hasEsiteDevicesModule && !this.hasConteoModule) {
          this.susbcriptions.push(
            proximityConfig.subscribe((resConteo) => {
              if (resConteo[0]) {
                this.configured.proximity = new ProximityNotificationConfig(resConteo[0]);
                this.loadTable();
              }
            })
          );
        }
        if (!this.hasEsiteDevicesModule && this.hasConteoModule) {
          this.susbcriptions.push(
            occupancyConfig.subscribe((resEsite) => {
              resEsite.forEach((config) => {
                const configAlarm = new OccupationNotificationConfig(config);
                this.setStartConteoAlarms(configAlarm.creationDate);
                this.configured.occupancy.push(configAlarm);
              });
              this.loadTable();
            })
          );
        }

        if (!this.hasEsiteDevicesModule && !this.hasConteoModule) {
          this.endLoadingNoData('NoAlarms');
        }
      } else {
        this.endLoadingNoData('NoAlarms');
      }
    });
  }

  ngOnDestroy(): void {
    this.buildingSubscription.unsubscribe();
    this.subcriptions.forEach((subs) => subs.unsubscribe());
  }

  setStartConteoAlarms(creationDate): void {
    const date = this.dateService.getUTCDate(creationDate);
    if (this.startConteoAlarms > date) {
      this.startConteoAlarms = date;
    }
  }

  changeOption(): void {
    this.loading = true;
    this.length = 0;
    this.subcriptions.forEach((subs) => subs.unsubscribe());
    this.pageIndex = this.INITIAL_PAGE;
    this.dataSource.data = [];
    this.loadTable();
  }

  loadTable(): void {
    this.loading = true;
    this.pageIndex = this.INITIAL_PAGE;
    this.dataSource.data = [];
    if (this.selected === FilterOptionType.spaces && this.hasEsiteDevicesModule) {
      this.getEsiteAlarms(this.pageIndex + 1);
    } else if (this.selected === FilterOptionType.zones && this.hasConteoModule) {
      if (this.hasConteoModule) {
        this.getConteoAlarms(this.pageIndex + 1);
      }
    }
  }

  endLoadingNoData(msgEmpty): void {
    this.loading = false;
    this.nodata = true;
    this.msgEmpty = msgEmpty;
  }

  updateEsiteLastRead(): void {
    const now = this.dateService.getUTCNow();
    this.subcriptions.push(
      this.esiteService.updateLastDisplayDate(this.configured.proximity.id, now).subscribe(() => {
        this.configured.proximity.lastReadDate = now;
      })
    );
  }

  updateConteoLastRead(): void {
    const now = this.dateService.getUTCNow();
    this.configured.occupancy.forEach((element: OccupationNotificationConfig) => {
      this.subcriptions.push(
        this.conteoService.updateLastDisplayDate(element.id, now).subscribe(() => {
          element.lastReadDate = now;
        })
      );
    });
  }

  getEsiteAlarms(page): void {
    if (this.configured.proximity) {
      this.notificationsService.clearNotificatonBar('ProximityNotification');
      this.nodata = false;
      this.subcriptions.push(
        this.esiteService
          .getTriggeredProximityAlarms(this.configured.proximity, page, this.pageSize)
          .subscribe((alarms) => {
            this.length = alarms.total;
            this.totalAlarms = alarms.alarms.length;
            this.loadedAlarms = 0;
            if (this.totalAlarms > 0) {
              this.subcriptions.push(
                this.habitatService.getFloors().subscribe((floors) => {
                  alarms.alarms.forEach((notif) => {
                    if (notif.space1 && notif.space2) {
                      const timezoneSpace1 = this.timeZonesBySpace.get(notif.space1);
                      const timezoneSpace2 = this.timeZonesBySpace.get(notif.space2);
                      if (timezoneSpace1 || timezoneSpace2) {
                        const timezone = timezoneSpace1 || timezoneSpace2;
                        this.setProximityNotificationData(notif, timezone);
                      } else {
                        this.subcriptions.push(
                          this.notificationsService.getSpace(notif.space1).subscribe({
                            next: (space) => {
                              const floor = floors.find((item) => item.id == space.floor);
                              const floorBuilding = this.buildings.find((item) => item.id === floor.building);
                              const timezone = floorBuilding?.city.timeZone;
                              this.timeZonesBySpace.set(notif.space1, timezone);
                              this.timeZonesBySpace.set(notif.space2, timezone);
                              this.setProximityNotificationData(notif, timezone);
                            },
                            error: () => {
                              this.addNotificationToTable(notif, false);
                            },
                          })
                        );
                      }
                    }
                  });
                })
              );
            } else {
              this.updateEsiteLastRead();
              this.endLoadingNoData('NoAlarms');
            }
          })
      );
    } else {
      if (this.buildings.length) {
        this.endLoadingNoData('NoConfigurations');
      } else {
        this.endLoadingNoData('NoAlarms');
      }
    }
  }

  setProximityNotificationData(notif, timezone): void {
    notif.date = notif.date ? this.dateService.utcToLocalString(notif.date, Languages.es, timezone) : '';
    const siteLabel = this.translateService.instant('Site');
    const name1 = this.loadedNameSpaces.get(notif.space1);
    const name2 = this.loadedNameSpaces.get(notif.space2);
    if (name1 && name2) {
      notif.subject = siteLabel + ' 1: ' + name1 + ' ' + siteLabel + ' 2: ' + name2;
      this.addNotificationToTable(notif);
    } else {
      this.susbcriptions.push(
        this.notificationsService.getAlarmSpacesName(notif.space1, notif.space2).subscribe({
          next: (names) => {
            if (names) {
              notif.subject = siteLabel + ' 1: ' + names[0]?.code + ' ' + siteLabel + ' 2: ' + names[1]?.code;
              this.loadedNameSpaces.set(notif.space1, names[0]?.code);
              this.loadedNameSpaces.set(notif.space2, names[1]?.code);
            }
            this.addNotificationToTable(notif);
          },
          error: () => {
            this.addNotificationToTable(notif, false);
          },
        })
      );
    }
  }

  addNotificationToTable(notif, addAlarm = true): void {
    if (addAlarm) {
      this.dataSource.data.push(notif);
    }
    this.loadedAlarms += 1;

    if (this.loadedAlarms === this.totalAlarms) {
      if (!this.dataSource.data.length) {
        this.endLoadingNoData('NoAlarms');
      }
      if (this.selected === FilterOptionType.spaces) {
        this.updateEsiteLastRead();
      } else if (this.selected === FilterOptionType.zones) {
        this.updateConteoLastRead();
      }
      // Necessary to "recreate" datasource, as push events to this.dataSource.data are not propagated to the UI
      this.dataSource = new MatTableDataSource(this.dataSource.data);
      this.loading = false;
    }
  }

  getConteoAlarms(page): void {
    if (this.configured.occupancy.length > 0) {
      this.notificationsService.clearNotificatonBar('OccupationNotification');
      this.nodata = false;
      this.subcriptions.push(
        this.conteoService.getTriggeredAlarms(this.startConteoAlarms, page, this.pageSize).subscribe((alarms) => {
          this.length = alarms.total;
          this.totalAlarms = alarms.occupationAlarmsList.length;
          this.loadedAlarms = 0;
          if (this.totalAlarms > 0) {
            this.subcriptions.push(
              this.notificationsService.getZones().subscribe((zones) => {
                this.subcriptions.push(
                  this.habitatService.getFloors().subscribe((floors) => {
                    alarms.occupationAlarmsList.forEach((element) => {
                      const configured = this.configured.occupancy.find((item) => item.id === element.configuredAlarm);
                      if (configured) {
                        const notif = new OccupationNotification(configured, element);
                        const zoneLoaded = this.loadedZone.find((item) => item.id === configured.zone);
                        if (zoneLoaded) {
                          const timezoneZone = this.timeZonesByZone.get(configured.zone);
                          zoneLoaded.occupation = element.occupation;
                          const occupationRate = zoneLoaded.getPercentage();
                          this.setOccupationNotificationData(
                            configured,
                            notif,
                            timezoneZone,
                            zoneLoaded.name,
                            occupationRate
                          );
                        } else {
                          const zoneitem = zones.find((item) => item.id == configured.zone);
                          const floor = floors.find((item) => item.id == zoneitem.floor);
                          const zone = new Zone(zoneitem);
                          zone.occupation = element.occupation;
                          const occupationRate = zone.getPercentage();
                          const floorBuilding = this.buildings.find((item) => item.id === floor.building);
                          const timezone = floorBuilding.city.timeZone;
                          this.timeZonesByZone.set(configured.zone, timezone);
                          this.loadedZone.push(zone);
                          this.setOccupationNotificationData(configured, notif, timezone, zone.name, occupationRate);
                        }
                      }
                    });
                  })
                );
              })
            );
          } else {
            this.updateConteoLastRead();
            this.endLoadingNoData('NoAlarms');
          }
        })
      );
    } else {
      if (this.buildings.length) {
        this.endLoadingNoData('NoConfigurations');
      } else {
        this.endLoadingNoData('NoAlarms');
      }
    }
  }

  setOccupationNotificationData(configured, notif, timezone, zoneName, occupation): void {
    const type = this.translateService.instant(configured.subtype);
    notif.subject =
      type +
      '. ' +
      zoneName +
      '. Limite establecido: ' +
      configured.occupancyRate +
      '%' +
      '. Ocupación: ' +
      occupation +
      '%';
    notif.date = notif.date ? this.dateService.utcToLocalString(notif.date, Languages.es, timezone) : '';
    this.addNotificationToTable(notif);
  }

  onPageChange(event: PageEvent): void {
    this.pageIndex = event.pageIndex;
    this.dataSource.data = [];
    this.loadTable();
  }

  refreshTableOnClick(): void {
    this.loading = true;
    this.pageIndex = this.INITIAL_PAGE;
    this.dataSource.data = [];
    this.loadTable();
  }
}
