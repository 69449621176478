import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { User } from 'src/app/models/auth/User.class';

import { environment } from '../../../environments/environment';
import { HttpService } from '../common/http.service';
import { HttpMethods } from './../../models/HttpMethods.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userInfoChanged = new Subject<User>();
  userPermissions;
  user;
  constructor(private httpService: HttpService) {}

  emitUserInfoChange(user): void {
    this.userInfoChanged.next(user);
  }

  requestToHabitat(type, method, body?): Observable<any> {
    const url = `${environment.habitat_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequest(protocol + url, type, method, body);
  }

  requestToReservations(type, method, body?): Observable<any> {
    const url = `${environment.reservations_backend_url}`;
    const protocol = `${environment.backend_protocol}`;
    return this.httpService.httpRequest(protocol + url, type, method, body);
  }

  getUserInfo(): Observable<any> {
    const method = 'v2/user';
    return this.requestToHabitat(HttpMethods.GET, method);
  }
}
