<collapse-card title="{{ 'Resources' | translate }}">
  <div *ngIf="loading" class="d-flex justify-content-center align-items-center" style="min-height: 350px">
    <app-loading></app-loading>
  </div>
  <div [hidden]="loading">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <mat-form-field class="flex-grow-1">
        <mat-label class="labelSearch">{{ 'Search' | translate }}</mat-label>
        <div class="d-flex">
          <input
            title="{{ 'Search' | translate }}"
            matInput
            class="filter-input py-1"
            (keyup)="applyFilter($event)"
            #input
            type="text"
          />
          <div class="searchIcon">
            <span class="bi bi-search"></span>
          </div>
        </div>
      </mat-form-field>
      <div *ngIf="canWriteResource" class="ps-3">
        <button mat-button (click)="createNewResource()">
          <span class="smallIcon bi bi-plus-circle"></span>
          <span class="ps-1">{{ 'New resource' | translate }}</span>
        </button>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" style="width: 100%" matSort #sortData="matSort">
      <caption></caption>
      <th id="resourcesTable"></th>
      <ng-container matColumnDef="code">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1"
          >{{ 'Code' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let resource" style="flex: 1">
          <span *ngIf="resource.code">
            {{ resource.code }}
          </span>
          <span *ngIf="!resource.code"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.3"
          >{{ 'Resource' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let resource" style="flex: 1.3">
          <span *ngIf="resource.name">
            {{ resource.name }}
          </span>
          <span *ngIf="!resource.name"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="iconType">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.6"
          >{{ 'Icon' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let resource" style="flex: 0.6">
          <span *ngIf="resource.iconType">
            <i class="bi-{{ resource.iconType }}"></i>
          </span>
          <span *ngIf="!resource.iconType"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="nameType">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 1.2">
          {{ 'Resource type' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let resource" style="flex: 1.2">
          <span *ngIf="resource.nameType">
            {{ resource.nameType }}
          </span>
          <span *ngIf="!resource.nameType"> -- </span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.6">
          {{ 'Actions' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let resource" style="flex: 0.6" class="d-flex gap-2">
          <span
            *ngIf="canWriteResource"
            class="smallIcon bi bi-pencil"
            role="button"
            (click)="editResource(resource)"
          ></span>
          <span
            *ngIf="!canWriteResource"
            class="smallIcon bi bi-eye"
            role="button"
            (click)="editResource(resource)"
          ></span>
          <span
            *ngIf="canWriteResource"
            class="smallIcon bi bi-trash"
            role="button"
            (click)="deleteResource(resource)"
          ></span>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
    </table>
    <mat-paginator [pageSize]="10" [hidePageSize]="true"></mat-paginator>
  </div>
</collapse-card>

<ng-template #newOrEditType>
  <mat-card style="width: 400px">
    <mat-card-content class="marginBodyCard">
      <label class="titleN3 text-primary modalTitle"> {{ modalTitle | translate }}</label>
      <form [formGroup]="resourceForm" (ngSubmit)="onSubmit(resourceForm)" class="d-flex flex-column gap-3">
        <mat-form-field>
          <mat-label>{{ 'Code' | translate }}</mat-label>
          <input
            title="{{ 'Code' | translate }}"
            matInput
            autocomplete="off"
            formControlName="code"
            type="text"
            class="py-1"
            maxlength="45"
            required
          />
          <mat-hint *ngIf="resourceForm.get('code')?.value.length" align="end"
            >{{ resourceForm.get('code').value.length }}/45</mat-hint
          >
          <mat-error *ngIf="submitted && f.code.errors?.required">{{ 'You must enter a code' | translate }}</mat-error>
          <mat-error *ngIf="submitted && f.code.errors?.duplicate">{{
            'There is already another resource with this code' | translate
          }}</mat-error>
          <mat-error *ngIf="submitted && f.code.errors?.unknown">{{
            'An error happened when creating the resource' | translate
          }}</mat-error>
          <mat-error *ngIf="f.code.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input
            title="{{ 'Name' | translate }}"
            matInput
            autocomplete="off"
            formControlName="name"
            type="text"
            class="py-1"
            maxlength="45"
            required
          />
          <mat-hint *ngIf="resourceForm.get('name')?.value.length" align="end"
            >{{ resourceForm.get('name').value.length }}/45</mat-hint
          >
          <mat-error *ngIf="submitted && f.name.errors?.required">{{ 'You must enter a name' | translate }}</mat-error>
          <mat-error *ngIf="submitted && f.name.errors?.duplicate">{{
            'There is already another resource with this name' | translate
          }}</mat-error>
          <mat-error *ngIf="submitted && f.name.errors?.unknown">{{
            'An error happened when creating the resource' | translate
          }}</mat-error>
          <mat-error *ngIf="f.name.errors?.pattern">{{ 'Commas not allowed' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field>
          <mat-label> {{ 'Resource type' | translate }}</mat-label>
          <mat-select class="py-1" formControlName="type" required>
            <mat-option *ngFor="let rscType of resourceTypes" [value]="rscType.id">
              <i class="bi bi-{{ rscType.icon }} me-2"></i>
              <span>{{ rscType.name }} </span>
            </mat-option>
          </mat-select>
          <mat-error *ngIf="submitted && f.type.errors?.required">{{
            'You must enter a resource type' | translate
          }}</mat-error>
          <mat-error *ngIf="submitted && f.type.errors?.typeConflict">{{ messageErrorResource }}</mat-error>
        </mat-form-field>

        <div class="col-sm-12 buttonsRow">
          <button mat-button class="modalCancel text-primary" (click)="cancel()" type="button">
            {{ 'Cancel' | translate }}
          </button>
          <button
            mat-button
            class="modalButton bg-primary"
            type="submit"
            *ngIf="canWriteResource"
            [disabled]="!resourceForm.valid || submitted"
          >
            <span>{{ 'Save' | translate }}</span>
            <span class="loader ms-1" [hidden]="!submitted"></span>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</ng-template>
