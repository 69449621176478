import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ReservationRoles } from 'src/app/models/reservation/ReservationRoles.const';
import { ReservationService } from 'src/app/services/backend/reservation.service';

@Component({
  selector: 'app-add-reservation-user-form',
  templateUrl: './add-reservation-user-form.component.html',
  styleUrls: ['./add-reservation-user-form.component.css'],
})
export class AddReservationUserFormComponent implements OnInit {
  loading = true;
  loadingSearch = false;
  noUsersToAdd = false;
  submitted = false;
  reservationUserFormGroup: UntypedFormGroup;
  inputSearchName = '';
  allUsersList = [];
  usersToAdd = [];
  userReservationRole;
  noResults = false;
  reservationRoles = ReservationRoles;
  dataSource = new MatTableDataSource([]);
  dataSourceAdded = new MatTableDataSource([]);
  displayedColumnsTable: string[] = ['action', 'name', 'employeeNumber', 'email'];
  displayedAddedColumnsTable: string[] = ['action', 'name', 'employeeNumber', 'email'];

  constructor(
    private reservationService: ReservationService,
    private formBuilder: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AddReservationUserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.userReservationRole = this.reservationRoles[0];
    this.reservationUserFormGroup = this.formBuilder.group({
      role: [this.userReservationRole, [Validators.required]],
      office: ['', [Validators.required]],
      area: ['', [Validators.required]],
    });
    this.loading = false;
  }

  triggeSearch(): void {
    this.loadingSearch = true;
    this.noResults = false;
    this.allUsersList = [];
    this.dataSource = new MatTableDataSource([]);
    this.reservationService.getNttdataUsers(this.inputSearchName).subscribe({
      next: (usersList) => {
        this.allUsersList = usersList;
        this.dataSource = usersList;
        this.loadingSearch = false;
      },
      error: () => {
        this.loadingSearch = false;
        this.noResults = true;
      },
    });
  }

  addAllUsers(): void {
    this.noUsersToAdd = false;
    this.usersToAdd = this.allUsersList;
    this.dataSourceAdded.data = this.usersToAdd;
  }

  addUser(user): void {
    this.noUsersToAdd = false;
    const index = this.usersToAdd.findIndex((item) => item.login == user.login);
    if (index == -1) {
      this.usersToAdd.push(user);
      this.dataSourceAdded.data = this.usersToAdd;
    }
  }

  deleteAllUsers(): void {
    this.usersToAdd = [];
    this.dataSourceAdded.data = [];
  }

  deleteUser(user): void {
    const index = this.usersToAdd.findIndex((item) => item.login == user.login);
    this.usersToAdd.splice(index, 1);
    this.dataSourceAdded.data = this.usersToAdd;
  }

  createUsers(): void {
    this.loading = true;
    let usersCount = 0;
    this.submitted = true;
    if (this.reservationUserFormGroup.invalid || !this.usersToAdd.length) {
      this.noUsersToAdd = !this.usersToAdd.length;
      this.loading = false;
      return;
    }

    this.usersToAdd.forEach((user) => {
      const reservationUser = {
        id: user.number,
        email: user.login + '@nttdata.com',
        name: user.name + ' ' + user.surname + ' ' + user.surname2,
        role: this.reservationUserFormGroup.value.role,
        office: this.reservationUserFormGroup.value.office,
        area: this.reservationUserFormGroup.value.area,
        pushId: '',
      };

      this.reservationService.createReservationUser(reservationUser).subscribe({
        next: () => {
          usersCount += 1;
          if (usersCount == this.usersToAdd.length) {
            this.close(true);
          }
        },
        error: () => {
          usersCount += 1;
          if (usersCount == this.usersToAdd.length) {
            this.close(true);
          }
        },
      });
    });
  }

  close(reload): void {
    this.dialogRef.close(reload);
  }

  get f() {
    return this.reservationUserFormGroup.controls;
  }
}
