<div class="row">
  <div [ngClass]="{ 'col-11': newFloor, 'col-12': !newFloor }">
    <div class="c-svg-map">
      <div class="mapBody">
        <popper-content #tooltip id="tooltip" style="background-color: white">
          <div *ngFor="let item of tooltipData; let i = index">
            <div *ngIf="i === 0">
              <span
                id="{{ item.code }}-close"
                (click)="tooltip.hide()"
                style="float: right; margin-top: -12px; font-weight: bold; margin-right: -4px; cursor: pointer"
                >x</span
              >
            </div>

            <div *ngIf="i !== 0">
              <br />
              <hr
                id="hr1"
                class="solid"
                style="margin-top: 2px; margin-bottom: 10px; border: 0; border-top: 2px solid rgba(0, 0, 0, 0.1)"
              />
            </div>

            <p style="display: flex; margin-bottom: 0">
              <span
                id="{{ item.code }}-color"
                class="circle"
                [ngStyle]="{
                  background: isPolygon
                    ? item.color
                    : realtimeReservation
                    ? hasEsiteDevicesModule
                      ? item.color
                      : item.reservationStatus === 'Reserved'
                      ? '#ffa900'
                      : '#8f9bb3'
                    : !hasEsiteDevicesModule
                    ? '#0075aa'
                    : item.color,
                  border:
                    !isPolygon && realtimeReservation
                      ? item.reservationStatus === 'Reserved'
                        ? '3px solid #ffa900'
                        : '3px solid #8f9bb3'
                      : '0px'
                }"
              ></span>
              <span
                id="{{ item.code }}-name"
                class="col-11"
                style="padding-left: 10px; font-weight: bold; overflow-wrap: break-word"
                >{{ item.name }}</span
              >
            </p>
            <p
              id="{{ item.code }}-type"
              style="padding-left: 23px; font-weight: bold; margin-bottom: 0; color: #525252"
            >
              {{ item.typeName }}
            </p>
            <div>
              <div *ngIf="isPolygon && item.occupation" style="margin-bottom: 0" class="row">
                <span class="col-4 smallText"> {{ 'Occupation' | translate }}: </span>
                <span class="col-8 smallText">
                  {{ item.occupation }}
                </span>
              </div>
              <div *ngIf="!isPolygon && item.label3" style="margin-bottom: 0" class="row">
                <span class="col-4 smallText"> {{ 'Date' | translate }}: </span>
                <span class="col-8 smallText">
                  {{ item.label3 }}
                </span>
              </div>
            </div>
            <div
              *ngIf="!isPolygon && item.label3 && !realtimeReservation && filterselected === 'permission'"
              style="margin-bottom: 0"
              class="row"
            >
              <span class="col-8 smallText">
                {{ item.label3 }}
              </span>
            </div>
            <div style="margin-bottom: 0" class="row">
              <span class="col-12 smallText">
                {{ item.reservationStatus | translate }}
              </span>
            </div>
            <div
              *ngIf="!isPolygon && item.label5 && hasReservationsModule && realtimeReservation"
              style="margin-bottom: 0"
              class="row"
            >
              <span class="col-4 smallText"> {{ 'Init' | translate }}: </span>
              <span class="col-8 smallText">
                {{ item.label5 }}
              </span>
            </div>
            <div
              *ngIf="!isPolygon && item.label4 && canReadReservation && hasReservationsModule && realtimeReservation"
              style="margin-bottom: 0"
              class="row"
            >
              <span class="col-4 smallText"> {{ 'By' | translate }}: </span>
              <span class="col-8 smallText">
                {{ item.label4 }}
              </span>
            </div>

            <div style="margin-top: 5px; margin-bottom: 10px">
              <a
                *ngIf="!reservationMap && isSitesMap && !isPolygon"
                href="javascript:void(0);"
                (click)="editSpace(item)"
              >
                <div *ngIf="canWriteEsiteSpaces" style="float: right; text-decoration: inherit; cursor: pointer">
                  <span>
                    {{ 'Edit' | translate }}
                  </span>
                  <div *ngIf="!canWriteEsiteSpaces" style="float: right; text-decoration: inherit; cursor: pointer">
                    <span>
                      {{ 'See' | translate }}
                    </span>
                  </div>
                </div>
              </a>
              <a
                *ngIf="
                  !newFloor &&
                  !isSitesMap &&
                  !isPolygon &&
                  item.reservationStatus !== 'Reserved' &&
                  canWriteReservationsSpaces &&
                  hasReservationsModule
                "
                (click)="openConfig(item)"
              >
                <span style="float: right; text-decoration: inherit; cursor: pointer">
                  {{ 'Config reservation' | translate }}
                </span>
              </a>

              <a
                *ngIf="!newFloor && item.reservationStatus === 'Reserved' && canReadReservation"
                href="javascript:void(0);"
                (click)="openInfo(item)"
              >
                <span style="float: right; text-decoration: inherit; cursor: pointer">
                  {{ '+info' | translate }}
                </span>
              </a>
            </div>
            <br />
            <details *ngIf="item?.resources?.length > 0">
              <summary>
                <strong class="me-1">{{ 'Resources' | translate }}:</strong>
                <span>{{ item.resources.length }}</span>
              </summary>
              <ul class="ps-4 m-0">
                <li *ngFor="let resource of item.resources" title="{{ resource.code }}">{{ resource.name }}</li>
              </ul>
            </details>
          </div>
        </popper-content>

        <div *ngIf="loading" style="display: flex; align-items: center; justify-content: center; min-height: 400px">
          <app-loading></app-loading>
        </div>

        <svg
          [id]="'svg' + svgId"
          class="svg"
          [style.cursor]="cursor"
          class="safari_only"
          style="height: 100%"
          width="100%"
          height="100%"
          [attr.viewBox]="viewbox"
        >
          <defs>
            <radialGradient id="fadeGrad" spreadMethod="reflect" r="100%" fr="50%">
              <stop offset="0" stop-color="white" stop-opacity="0.05" />
              <stop offset="1" stop-color="white" stop-opacity="1" />
            </radialGradient>
            <mask id="fade" maskContentUnits="objectBoundingBox">
              <rect width="1" height="1" fill="url(#fadeGrad)" />
            </mask>
          </defs>

          <g class="svg-pan-zoom_viewport" id="svgElements">
            <g>
              <image
                *ngIf="!isDrawingMode"
                [attr.x]="svgMapProperties.offsetX"
                [attr.y]="-(svgMapProperties.height + svgMapProperties.offsetY)"
                [attr.width]="svgMapProperties.width"
                [attr.height]="svgMapProperties.height"
                [attr.xlink:href]="mapImage"
              />
              <image
                *ngIf="isDrawingMode || isClickMode"
                [attr.x]="svgMapProperties.offsetX"
                style="cursor: crosshair"
                [attr.y]="-(svgMapProperties.height + svgMapProperties.offsetY)"
                [attr.width]="svgMapProperties.width"
                [attr.height]="svgMapProperties.height"
                [attr.xlink:href]="mapImage"
                (click)="clicked($event)"
              />
            </g>
            <g id="svgElements">
              <!-- Polygons received to be painted on the map -->
              <polygon
                [id]="polygon.code"
                *ngFor="let polygon of scalatedPolygons"
                [popper]="tooltip"
                [popperTrigger]="'click'"
                [popperDisabled]="openedTooltip && openedTooltip !== 'polygon'"
                [popperHideOnClickOutside]="true"
                [popperHideOnMouseLeave]="true"
                [popperHideOnScroll]="false"
                [popperPlacement]="'auto'"
                [popperPreventOverflow]="true"
                [popperApplyClass]="'popperStyle'"
                (popperOnShown)="onShownTooltip([polygon], 'polygon')"
                (popperOnHidden)="onHiddenPopper()"
                class="tooltip-trigger-polygon click-trigger"
                [attr.points]="polygon.position"
                [ngStyle]="{ fill: polygon.color, stroke: polygon.color }"
                fill-opacity="0.25"
                [attr.stroke-width]="polygonSize / zoomActual"
                (click)="polygonclick(polygon)"
              />

              <!-- Points received to be painted on the map -->
              <g *ngIf="(hasEsiteDevicesModule || reservationMap) && data?.points && data?.pointsXY">
                <g *ngIf="realtimeReservation">
                  <circle
                    *ngFor="let points of data?.pointsXY | keyvalue; trackBy: myTrackByFunction"
                    [popper]="tooltip"
                    popperDelay="50"
                    [popperTrigger]="'click'"
                    [popperDisabled]="openedTooltip && openedTooltip !== 'point1'"
                    [popperHideOnClickOutside]="true"
                    [popperHideOnScroll]="false"
                    [popperPlacement]="'auto'"
                    [popperPreventOverflow]="true"
                    [popperApplyClass]="'popperStyle'"
                    (popperOnShown)="onShownTooltip(points.value, 'point1')"
                    (popperOnHidden)="onHiddenPopper()"
                    class="tooltip-trigger"
                    [attr.cx]="points.value[0].positionX * svgMapProperties.scale"
                    [attr.cy]="-points.value[0].positionY * svgMapProperties.scale"
                    [attr.r]="pointRingSize / zoomActual"
                    [ngStyle]="getTooltipRealtimeReservationStyles(points.value)"
                    (click)="pointClick(points.value)"
                  />
                </g>

                <g>
                  <circle
                    *ngFor="let points of data?.pointsXY | keyvalue; trackBy: myTrackByFunction"
                    [popper]="tooltip"
                    [popperTrigger]="'click'"
                    popperDelay="50"
                    [popperDisabled]="openedTooltip && openedTooltip !== 'point2'"
                    [popperHideOnClickOutside]="true"
                    [popperHideOnScroll]="false"
                    [popperPlacement]="'auto'"
                    [popperPreventOverflow]="true"
                    [popperApplyClass]="'popperStyle'"
                    (popperOnShown)="onShownTooltip(points.value, 'point2')"
                    (popperOnHidden)="onHiddenPopper()"
                    class="tooltip-trigger"
                    [attr.cx]="points.value[0].positionX * svgMapProperties.scale"
                    [attr.cy]="-points.value[0].positionY * svgMapProperties.scale"
                    [attr.r]="pointSize / zoomActual"
                    [ngStyle]="getTooltipStyles(points.value)"
                    (click)="pointClick(points.value)"
                  />
                </g>
              </g>
              <g *ngIf="!hasEsiteDevicesModule && !reservationMap && data?.points && data?.pointsXY">
                <g>
                  <circle
                    *ngFor="let points of data?.pointsXY | keyvalue; trackBy: myTrackByFunction"
                    class="tooltip-trigger"
                    [popper]="tooltip"
                    [popperTrigger]="'click'"
                    popperDelay="50"
                    [popperDisabled]="openedTooltip && openedTooltip !== 'point3'"
                    [popperHideOnClickOutside]="true"
                    [popperHideOnScroll]="false"
                    [popperPlacement]="'auto'"
                    [popperPreventOverflow]="true"
                    [popperApplyClass]="'popperStyle'"
                    (popperOnShown)="onShownTooltip(points.value, 'point3')"
                    (popperOnHidden)="onHiddenPopper()"
                    [attr.cx]="points.value[0].positionX * svgMapProperties.scale"
                    [attr.cy]="-points.value[0].positionY * svgMapProperties.scale"
                    [attr.r]="(pointRingSize - 1) / zoomActual"
                    [ngStyle]="getTooltipPoint3Styles(points.value)"
                    (click)="pointClick(points.value)"
                  />
                </g>
              </g>

              <!-- New Polygons drawn by the user if isDrawing is enabled -->
              <line
                *ngFor="let line of newLines"
                [attr.x1]="line.x1"
                [attr.y1]="line.y1"
                [attr.x2]="line.x2"
                [attr.y2]="line.y2"
                [attr.stroke-width]="lineSize / zoomActual"
                [ngStyle]="{ stroke: colorDrawing }"
              />
              <line
                *ngIf="newPoints.length > 0"
                [attr.x1]="newPoints[newPoints.length - 1].x"
                [attr.y1]="newPoints[newPoints.length - 1].y"
                [attr.x2]="mouseX"
                [attr.y2]="mouseY"
                [ngStyle]="{ stroke: colorDrawing }"
                [attr.stroke-width]="lineSize / zoomActual"
                (click)="clicked($event)"
              />
              <g *ngFor="let point of newPoints; let i = index">
                <circle
                  *ngIf="i == 0 && newPoints.length > 2"
                  [attr.cx]="point.x"
                  [attr.cy]="point.y"
                  [attr.r]="(pointSize / zoomActual) * 2"
                  [ngStyle]="{ fill: colorDrawing }"
                  (click)="endDraw()"
                />
                <circle
                  *ngIf="i != 0"
                  [attr.cx]="point.x"
                  [attr.cy]="point.y"
                  [attr.r]="pointSize / zoomActual"
                  [ngStyle]="{ fill: colorDrawing }"
                />
                <circle
                  *ngIf="i == 0 && newPoints.length <= 2"
                  [attr.cx]="point.x"
                  [attr.cy]="point.y"
                  [attr.r]="pointSize / zoomActual"
                  [ngStyle]="{ fill: colorDrawing }"
                />
              </g>
            </g>
          </g>
          <!-- Error message in case the image can't be loaded -->
          <text *ngIf="!mapImage" x="50%" y="20%" dominant-baseline="middle" text-anchor="middle" class="error">
            {{ 'Image could not be loaded' | translate }}
          </text>
        </svg>
      </div>
    </div>
  </div>
  <div class="col-1" *ngIf="newFloor" style="padding: 0">
    <form class="row" [formGroup]="offsetFormGroup" style="margin-left: 15px; margin-right: -40px">
      <mat-form-field class="col-md-9 col-12" style="padding: 0">
        <mat-label>{{ 'OffsetX (px)' | translate }}</mat-label>
        <input
          title="{{ 'OffsetX (px)' | translate }}"
          matInput
          autocomplete="off"
          formControlName="offsetX"
          type="number"
          onkeydown="return event.keyCode !== 69"
          class="p-1 form-control font-weight-normal"
          (change)="setOffsetX()"
          step="1"
        />
      </mat-form-field>
      <mat-form-field class="col-md-9 col-12" style="padding: 0">
        <mat-label>{{ 'OffsetY (px)' | translate }}</mat-label>
        <input
          title="{{ 'OffsetY (px)' | translate }}"
          matInput
          autocomplete="off"
          formControlName="offsetY"
          type="number"
          onkeydown="return event.keyCode !== 69"
          class="p-1 form-control font-weight-normal"
          (change)="setOffsetY()"
          step="1"
        />
      </mat-form-field>
      <mat-form-field class="col-md-9 col-12" style="padding: 0">
        <mat-label>{{ 'Scale' | translate }}</mat-label>
        <input
          title="{{ 'Scale' | translate }}"
          matInput
          autocomplete="off"
          formControlName="scale"
          type="number"
          onkeydown="return event.keyCode !== 69"
          class="p-1 form-control font-weight-normal"
          (change)="setScale()"
          step="0.1"
        />
      </mat-form-field>
    </form>
    <div class="row" style="width: 223%">
      <div class="row" style="width: 100%; justify-content: center; margin-bottom: -5px">
        <span class="smallIcon bi bi-arrow-up" role="button" (click)="offsetUp()"></span>
      </div>
      <div class="row" style="width: 100%">
        <div class="col-6" style="padding-right: 5px; text-align-last: right">
          <span class="smallIcon bi bi-arrow-left" role="button" (click)="offsetLeft()"></span>
        </div>
        <div class="col-6" style="padding-left: 5px">
          <span class="smallIcon bi bi-arrow-right" role="button" (click)="offsetRight()"></span>
        </div>
      </div>
      <div
        class="row"
        style="width: 100%; justify-content: center; margin-top: -10px; position: relative; z-index: 10000"
      >
        <span class="smallIcon bi bi-arrow-down" role="button" (click)="offsetDown()"></span>
      </div>
    </div>
    <div style="margin-left: 23.5px; margin-top: 5px">
      <span class="smallIcon bi bi-plus-circle" role="button" (click)="mapScaleUp()"></span>
      <span class="smallIcon bi bi-dash-circle" role="button" (click)="mapScaleDown()"></span>
    </div>
  </div>
</div>

<!-- Legend -->
<div class="row my-2" style="display: flex; padding-left: 20px">
  <div class="col-10 col-md-11">
    <div class="row">
      <div
        *ngFor="let item of legendItems"
        class="col-6 col-md"
        style="padding-right: 0px; font-size: 14px; margin-left: -3px"
      >
        <svg width="20" height="20" viewBox="0 0 100 100" [ngStyle]="{ fill: item.color }">
          <ellipse cx="50" cy="50" rx="40" ry="40"></ellipse>
        </svg>
        <svg
          *ngIf="item.ring && hasEsiteDevicesModule"
          width="20"
          height="20"
          viewBox="0 0 100 100"
          [ngStyle]="{ fill: '#ffffff', position: 'absolute', left: '15px', top: '3px' }"
        >
          <ellipse cx="50" cy="50" rx="30" ry="30"></ellipse>
        </svg>
        {{ item.name | translate }}
      </div>
    </div>
  </div>
  <div
    *ngIf="!reservationMap && hasEsiteDevicesModule && !newFloor"
    class="col-2 col-md-1"
    style="text-align-last: right; align-self: center; color: #0075aa"
  >
    <span
      *ngIf="legendItems.length"
      class="smallIcon bi bi-question-circle"
      role="button"
      [popper]="legendTooltip"
      [popperTrigger]="'click'"
      [popperHideOnClickOutside]="true"
      [popperHideOnScroll]="false"
      [popperPlacement]="'top'"
      [popperPreventOverflow]="true"
      [popperApplyClass]="'popperStyle'"
    ></span>
  </div>
</div>
<popper-content #legendTooltip id="legendTooltip" style="background-color: white; max-width: 335px">
  <div style="font-weight: bold; color: #0075aa">{{ 'Rooms' | translate }}*</div>
  <div>
    <img width="315px;" src="./assets/images/Diagrama_Salas.svg" alt="Description of rooms status flow" />
  </div>
  <hr class="solid" style="margin-top: 2px; margin-bottom: 10px; border: 0; border-top: 2px solid rgba(0, 0, 0, 0.1)" />
  <div style="font-weight: bold; color: #0075aa">{{ 'Sites' | translate }}*</div>

  <div style="position: relative">
    <img width="315px;" src="./assets/images/Diagrama_Puestos.svg" alt="Description of sites status flow" />
    <span style="position: absolute; bottom: 66px; left: 57px; background: white; font-size: 12px; font-weight: 700">{{
      'Occupy' | translate
    }}</span>
    <span style="position: absolute; bottom: 65px; left: 126px; background: white; font-size: 12px; font-weight: 700">{{
      'Release' | translate
    }}</span>
  </div>
  <hr class="solid" style="margin-top: 2px; margin-bottom: 10px; border: 0; border-top: 2px solid rgba(0, 0, 0, 0.1)" />
  <div style="line-height: 15px; text-align: justify">
    *{{ 'Any occupancy event triggers this process regardless of previous status' | translate }}
  </div>
</popper-content>
