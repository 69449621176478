import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ResourceTypesService } from '../../../../services/backend/resourceTypes.service';
import { NotPermissionComponent } from '../../not-permission/not-permission.component';
import { Permission } from './../../../../models/auth/Role.class';
import { User } from './../../../../models/auth/User.class';
import { UserPermissions } from './../../../../models/auth/UserPermissions.enum';
import { GlobalService } from './../../../../services/common/global.service';

@Component({
  selector: 'app-resources-configuration',
  templateUrl: './resources-configuration.component.html',
  styleUrls: ['./resources-configuration.component.css'],
})
export class ResourcesConfigurationComponent implements OnInit {
  private userLogged: User;
  private permissions: Permission[] = [];
  havePermissionResource = true;

  constructor(
    private globalService: GlobalService,
    private resourceTypeService: ResourceTypesService,
    public dialog: MatDialog
  ) {
    this.userLogged = this.globalService.getUser();
    this.permissions = this.userLogged?.permissions;
  }

  ngOnInit(): void {
    if (!this.permissions?.map((permission) => permission.name).includes(UserPermissions.Resources)) {
      this.havePermissionResource = false;
      this.globalService.printMessage(
        'You do not have the necessary permissions in resources: Resources and ResourcesTypes',
        'warn'
      );
      this.dialog.open(NotPermissionComponent, { disableClose: true });
      return;
    }

    this.getResourceTypes();
  }

  getResourceTypes(): void {
    this.resourceTypeService.fetchResourceTypes().subscribe();
  }
}
