<div class="row m-3" *ngIf="userLogged">
  <div class="col-xl-5 col-lg-5 col-md-5 col-sm-12">
    <div class="titleN3" style="display: flex; align-items: center; height: 45px">
      {{ 'Profile' | translate }}
    </div>
    <div class="line"></div>
    <div style="padding-top: 16px" #profile>
      <form [formGroup]="editUserFormGroup" class="pt-3" (ngSubmit)="modifyDataUser()">
        <mat-form-field class="form-group separateDiv">
          <mat-label>{{ 'Email' | translate }}</mat-label>
          <input
            title="{{ 'Email' | translate }}"
            matInput
            autocomplete="off"
            formControlName="email"
            type="email"
            class="p-1"
            style="cursor: default"
            readonly
            spellcheck="false"
            required
          />
          <mat-error *ngIf="f.email.errors?.required">{{ 'Email is required' | translate }}</mat-error>
          <mat-error *ngIf="f.email.errors?.email">{{ 'Email must be a valid email address' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="form-group separateDiv">
          <mat-label>{{ 'Name' | translate }}</mat-label>
          <input
            title="{{ 'Name' | translate }}"
            matInput
            autocomplete="off"
            formControlName="name"
            type="text"
            class="p-1"
            maxlength="45"
            spellcheck="false"
            required
          />
          <mat-hint
            *ngIf="editUserFormGroup.get('name')?.value?.length && editUserFormGroup.get('name')?.dirty"
            align="end"
            >{{ editUserFormGroup.get('name').value.length }}/45</mat-hint
          >
          <mat-error *ngIf="f.name.errors?.required">{{ 'Name is required' | translate }}</mat-error>
        </mat-form-field>
        <mat-form-field class="form-group separateDiv">
          <mat-label>{{ 'Surname' | translate }}</mat-label>
          <input
            title="{{ 'Surname' | translate }}"
            matInput
            autocomplete="off"
            formControlName="surname"
            type="text"
            class="p-1"
            maxlength="45"
            spellcheck="false"
            required
          />
          <mat-hint
            *ngIf="editUserFormGroup.get('surname')?.value.length && editUserFormGroup.get('surname')?.dirty"
            align="end"
            >{{ editUserFormGroup.get('surname').value.length }}/45</mat-hint
          >
          <mat-error *ngIf="f.surname.errors?.required">{{ 'Surname is required' | translate }}</mat-error>
        </mat-form-field>

        <mat-form-field class="form-group separateDiv">
          <mat-label>{{ 'Department' | translate }}</mat-label>
          <input
            title="{{ 'Department' | translate }}"
            matInput
            autocomplete="off"
            formControlName="department"
            type="text"
            class="p-1"
            maxlength="50"
            spellcheck="false"
          />
          <mat-hint
            *ngIf="editUserFormGroup.get('department')?.value.length && editUserFormGroup.get('department')?.dirty"
            align="end"
            >{{ editUserFormGroup.get('department').value.length }}/50</mat-hint
          >
        </mat-form-field>
        <div
          *ngIf="loading"
          class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow"
          style="display: flex; align-items: center; justify-content: center"
        >
          <app-loading></app-loading>
        </div>
        <div *ngIf="!loading" class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow" style="place-content: center">
          <button type="button" mat-button class="modalButton bg-primary" type="submit" [disabled]="disableSave">
            {{ 'Save' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
  <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
    <div
      class="oval"
      style="background-size: cover"
      [ngStyle]="{ 'background-image': this.userLogged.image ? 'url(' + this.userLogged.image + ')' : '' }"
    >
      <div class="Combined-Shape text-primary">    
        <label role="button" style="margin-top: 10px; height: 35px" (click)="openImageModal(changeImage)">
          <span class="bi bi-pencil text-primary"></span>
        </label><br />
      </div>
    </div>
  </div>
</div>
<ng-template #changeImage>
  <mat-card class="editResponsive modalCard">
    <mat-card-content class="marginBodyCard">
      <div *ngIf="loadingModal" class="p-5 text-center">
        <app-loading></app-loading>
      </div>
      <div [hidden]="loadingModal" class="text-left">
        <div class="titleN3 text-primary mb-3">
          {{ 'ProfileImage' | translate }}
        </div>
        <form [formGroup]="changeImageFormGroup" (ngSubmit)="saveImage()" class="d-grid">
          <mat-label>{{ 'SelectProfileImage' | translate }}*</mat-label>
          <div class="form-group custom-file">
            <input
              type="file"
              id="imageFile"
              style="visibility: visible"
              required
              ngModel
              accept=".png,.jpg,.jpeg"
              (change)="handleImageInput($event.target.files)"
              class="form-control custom-file-input"
              [ngModelOptions]="{ standalone: true }"
            />
            <input type="hidden" name="img" id="imageFileValidator" formControlName="img" />
            <small
              class="custom-file-label d-flex"
              for="imageFile"
              data-browse=""
            >
              <span class="flex-grow-1 text-truncate"> {{ imageName }} &nbsp;</span>
              <span class="bi bi-cloud-upload"></span>
          </small>
          </div>
          <div
            class="invalid-feedback-custome"
            *ngIf="submitted && q.img.errors?.required"
            [ngClass]="{ 'd-block': submitted && q.img.errors }"
          >
            {{ 'Required field' | translate }}
          </div>
          <div *ngIf="errorImageSize || errorImageType" class="invalid-feedback-custome">
            <div *ngIf="errorImageSize">
              {{ 'Image size must be less than 2MB' | translate }}
            </div>
            <div *ngIf="errorImageType">
              {{ 'Image type must be .PNG or .JPG' | translate }}
            </div>
          </div>
          
          <div class="d-grid" *ngIf="imgURL">
            <mat-label>{{ 'Preview' | translate }}</mat-label>
            <img alt="profileImg" [src]="imgURL" height="200" />
          </div>

          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 buttonsRow">
            <button type="button" mat-button class="modalCancel text-primary" (click)="close()">
              {{ 'Cancel' | translate }}
            </button>
            <button type="submit" mat-button class="modalButton bg-primary">
              {{ 'Save' | translate }}
            </button>
          </div>
        </form>
      </div>
    </mat-card-content>
  </mat-card>
</ng-template>
