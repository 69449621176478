<mat-card>
  <mat-card-header class="p-3 border-bottom">
    <div class="titleN2 text-primary">{{ 'Notifications' | translate }}</div>
  </mat-card-header>
  <mat-card-content class="p-3">
    <div class="d-flex">
      <div class="inputRadios">
        <mat-radio-group class="radioOp" [(ngModel)]="selected" (change)="changeOption()">
          <mat-radio-button *ngIf="hasEsiteDevicesModule" [value]="optionSpaces" checked class="me-3">
            {{ 'Workstations' | translate }}
          </mat-radio-button>
          <mat-radio-button *ngIf="hasConteoModule" [value]="optionZones">{{ 'Zones' | translate }} </mat-radio-button>
        </mat-radio-group>
      </div>
      <button mat-button type="button" (click)="refreshTableOnClick()" class="text-primary">
        <mat-icon
          fontIcon="refresh"
          title="{{ 'Refresh' | translate }}"
        >
        </mat-icon>
      </button>
    </div>
    <div *ngIf="loading" style="display: flex; align-items: center; justify-content: center; min-height: 100px">
      <app-loading></app-loading>
    </div>
    <div *ngIf="nodata && !loading" class="row" style="padding-left: 40px; padding-top: 1.25rem; min-height: 100px">
      {{ msgEmpty | translate }}
    </div>
    <table
      *ngIf="!nodata && !loading && !responsive"
      mat-table
      [dataSource]="dataSource"
      matSort
      #sortData="matSort"
      style="width: 100%"
      matSort
    >
      <caption></caption>
      <th id="notifTable"></th>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.7"
          >{{ 'Name' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let elem" style="flex: 0.7" [ngStyle]="{ 'font-weight': elem.read ? '400' : '800' }">
          {{ elem.name | translate }}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="subject">
        <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 2"
          >{{ 'Description' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let elem" style="flex: 2" [ngStyle]="{ 'font-weight': elem.read ? '400' : '800' }">
          {{ elem.subject | translate }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 0.5">
          {{ 'Date' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let elem" style="flex: 0.5" [ngStyle]="{ 'font-weight': elem.read ? '400' : '800' }">
          {{ elem.date }}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
    </table>

    <table *ngIf="!nodata && !loading && responsive" mat-table [dataSource]="dataSource" matSort>
      <caption></caption>
      <th id="notifTableResponsive"></th>
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.7; visibility: hidden">
          {{ 'Name' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let elem" style="flex: 0.7" [ngStyle]="{ 'font-weight': elem.read ? '400' : '800' }">
          {{ 'Name' | translate }}: <span style="font-weight: bold"> {{ elem.name | translate }}</span>
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="subject">
        <mat-header-cell *matHeaderCellDef class="text-primary" style="flex: 2; visibility: hidden"
          >{{ 'Description' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let elem" style="flex: 2" [ngStyle]="{ 'font-weight': elem.read ? '400' : '800' }">
          {{ 'Description' | translate }}: {{ elem.subject | translate }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef mat-sort-header class="text-primary" style="flex: 0.5; visibility: hidden">
          {{ 'Date' | translate }}</mat-header-cell
        >
        <mat-cell *matCellDef="let elem" style="flex: 0.5" [ngStyle]="{ 'font-weight': elem.read ? '400' : '800' }">
          {{ 'Date' | translate }}: {{ elem.date }}
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumnsTable"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumnsTable"></mat-row>
    </table>

    <mat-paginator
      *ngIf="!nodata && !loading"
      [hidePageSize]="true"
      [length]="length"
      [pageIndex]="pageIndex"
      [pageSize]="10"
      (page)="onPageChange($event)"
    ></mat-paginator>
  </mat-card-content>
</mat-card>
